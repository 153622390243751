/**
 * JTB側-自社ユーザー検索入力フォーム
 * メモ：入力フォームを表示する
 */
import React from "react";

import clsx from "clsx";
import {
  FormControl,
  FormLabel,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";

import { formStyles } from "../../../../common/components/styles";

import { UserSearchCondition } from "../../types";

import { ADMIN_COLOR, FONT } from "../../../../../constants/common";
import { USER_TYPE_SEARCH_MAP_FOR_JTB } from "../../../../../constants/user";

import { isCardCompanyUser } from "../../../../../utils/auth";

import CustomTextField from "../../../../common/components/atoms/CustomTextField";
import PatternMatchChip from "../../../../common/components/atoms/PatternMatchChip";
import CustomSelect from "../../../../common/components/molecules/CustomSelect";

const useStyles = makeStyles(() => ({
  label: {
    width: 800,
    textAlign: "left",
    padding: "0!important",
    height: 36,
  },
  table: {
    width: 800,
    minWidth: 800,
  },
  td: {
    padding: 1,
    backgroundColor: "#fff",
    color: "#000!important",
    borderTop: "1px solid #d2d5d8",
    borderBottom: "1px solid #d2d5d8",
    borderRight: "1px solid #d2d5d8",
    width: 400,
  },
  th: {
    padding: 0,
    fontFamily: FONT,
    backgroundColor: "#fff",
    color: "#000!important",
    width: 200,
    border: "1px solid #d2d5d8",
    position: "relative",
    paddingLeft: 20,
  },
}));

// 検索条件フォーム設定
const userSearchFormData = [
  {
    name: "ユーザー名",
    formData: "name",
    matchType: "前方一致",
  },
  {
    name: "ユーザーID",
    formData: "userName",
    matchType: "前方一致",
  },
  {
    name: "メールアドレス",
    formData: "email",
    matchType: "前方一致",
  },
  {
    name: "ユーザー種別",
    formData: "userType",
    matchType: "完全一致",
  },
];

interface Props {
  searchCondition: UserSearchCondition;
  setSearchCondition: React.Dispatch<React.SetStateAction<UserSearchCondition>>;
}

const UserSearchConditionForm: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const formClasses = formStyles({
    color: ADMIN_COLOR.base,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const { searchCondition, setSearchCondition } = props;

  // JTB社ユーザーでない場合はユーザー種別を非表示
  const userSearchForm = isCardCompanyUser()
    ? userSearchFormData.filter((item) => item.formData !== "userType")
    : userSearchFormData;

  // ユーザー種別のプルダウン用リスト
  const userTypeList = Object.keys(USER_TYPE_SEARCH_MAP_FOR_JTB);

  return (
    <FormControl className={formClasses.form}>
      <FormLabel className={clsx(formClasses.formLabel, classes.label)}>
        自社ユーザー検索
      </FormLabel>
      <TableContainer>
        <Table
          className={clsx(formClasses.table, classes.table)}
          aria-label="simple table"
        >
          <TableBody>
            {userSearchForm.map((formItem) => (
              <TableRow key={formItem.name}>
                <TableCell
                  align="left"
                  className={clsx(formClasses.th, classes.th)}
                >
                  {formItem.name}
                  <PatternMatchChip
                    left={153}
                    top="24%"
                    text={formItem.matchType}
                  />
                </TableCell>
                <TableCell align="left" className={classes.td}>
                  {/* ユーザー種別以外の場合 */}
                  {formItem.formData !== "userType" && (
                    <CustomTextField
                      value={
                        searchCondition[
                          formItem.formData as keyof UserSearchCondition
                        ]
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSearchCondition({
                          ...searchCondition,
                          [formItem.formData]: e.target.value,
                        })
                      }
                    />
                  )}
                  {/* ユーザー種別の場合 */}
                  {formItem.formData === "userType" && (
                    <CustomSelect
                      value={searchCondition[formItem.formData] || "指定なし"}
                      pulldownArray={userTypeList}
                      width="100%"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSearchCondition({
                          ...searchCondition,
                          [formItem.formData]: e.target.value,
                        })
                      }
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FormControl>
  );
};

export default UserSearchConditionForm;
