/**
 * 共通-確認モーダル
 */
import React, { useState } from "react";

import clsx from "clsx";
import { Modal, Grid, Button, makeStyles, Theme } from "@material-ui/core";

import { buttonStyles } from "../styles";

import { confirmModalProps } from "../types";

import BreakElementForLineText from "./BreakElementForLineText";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: "absolute",
    width: 334,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
  },
  confirmMessage: {
    marginBottom: 20,
    color: "#777",
  },
  modalTitle: {
    fontSize: "1.1em",
  },
  commonButton: {
    paddingTop: 11,
    paddingBottom: 11,
    textAlign: "center",
  },
  cancelButton: (props: ColorProps) => ({
    color: `${props.color}!important`,
    backgroundColor: `${props.backgroundColor}!important`,
    border: `1px solid ${props.color}`,
    paddingBottom: 6,
    "&:hover": {
      backgroundColor: `${props.btnHoverColor}!important`,
    },
  }),
  rightGrid: {
    marginLeft: 10,
  },
}));

type ColorProps = {
  color: string;
  backgroundColor: string;
  btnHoverColor: string;
};

const ConfirmModal: React.FC<confirmModalProps> = (
  props: confirmModalProps
) => {
  const {
    modalTitle,
    actionMethod,
    open,
    setOpen,
    message,
    okText,
    cancelText,
    colors,
  } = props;

  const classes = useStyles({
    color: colors.primary,
    backgroundColor: colors.base,
    btnHoverColor: colors.btnHover,
  });
  const modalButtonClasses = buttonStyles({
    width: 130,
    marginTop: 0,
    backgroundColor: colors.primary,
  });
  // TODO：他のモーダルの処理と共通化する。
  const getModalStyle = () => ({
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  });

  const [modalStyle] = useState(getModalStyle);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        {/* タイトル */}
        <h2 id="simple-modal-title" className={classes.modalTitle}>
          <BreakElementForLineText text={modalTitle} />
        </h2>
        {/* メッセージ */}
        <p id="simple-modal-description" className={classes.confirmMessage}>
          {message}
        </p>
        <Grid container justify="flex-start">
          {/* OKボタン */}
          <Grid item>
            <Button
              onClick={actionMethod}
              className={clsx(modalButtonClasses.button, classes.commonButton)}
              variant="contained"
            >
              {okText}
            </Button>
          </Grid>
          {/* キャンセルボタン */}
          <Grid item className={classes.rightGrid}>
            <Button
              onClick={handleClose}
              className={clsx(
                modalButtonClasses.button,
                classes.commonButton,
                classes.cancelButton
              )}
              variant="contained"
            >
              {cancelText}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  message: " 本当に実行しますか？",
  okText: "はい",
  cancelText: "いいえ",
};

export default ConfirmModal;
