import React, { useState, useEffect, useMemo, useCallback } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Amplify, Auth } from "aws-amplify";
import { Alert } from "@material-ui/lab";
import { IDecodedJWTToken } from "./AuthState";
import {
  AuthContext,
  IChangeEmail,
  IChangePassword,
  IConfirmChangeEmail,
  IResetPasswordParams,
  ISignInResponse,
  IBaseResponse,
  IRenewDefaultPasswordParams,
  ISendUserList,
  IConfirmMfaCodeParams,
  INewPasswordResponse,
  IChangeUserPhoneNum,
  IConfirmChangeUserPhoneNum,
} from "./AuthContext";
import cognitoUser from "../api/cognitoUser";
import cognitoListener from "./CognitoListener";
import { getErrorMessage } from "../utils/error";
import {
  EMAIL_MAX_LENGTH,
  PASSWORD_MAX_LENGTH,
  PHONE_NUMBER_MAX_LENGTH,
} from "../constants/common";
import STORAGE_KEYS from "../constants/localStorage";
import { reloadAfterFewSeconds } from "../api/utils/common";
import AlertMessage from "../views/common/components/atoms/AuthAlertMsg";

export type LoginOption = {
  username: string;
  password: string;
};
interface ICognitoAuthProviderParams {
  amplifyConfig: {
    Auth: {
      region: string;
      userPoolId: string;
      userPoolWebClientId: string;
      authenticationFlowType: string;
    };
  };
  children: React.ReactNode;
}

interface ICognitoUser extends CognitoUser {
  challengeParam?: { message: string };
}

// 注意：本プロバイダーに実装しているメソッドを使用する時は、ボタン押下時などを条件にするなどして用いること。
// でないと初期レンダー時にメソッド使用→useStateのsetによる値変化発生=リレンダー→リレンダーによってメソッド使用→リレンダーとループする。
export default function CognitoAuthProvider(
  props: ICognitoAuthProviderParams
  // eslint-disable-next-line
): any {
  const { amplifyConfig, children } = props;
  Amplify.configure(amplifyConfig);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isCustomer, setIsCustomer] = useState<boolean>();
  const [user, setUser] = useState<ICognitoUser>();
  const [userAttributes, setUserAttributes] = useState<IDecodedJWTToken>();
  const [authHeader, setAuthHeader] = useState<string>();
  const [challengeName, setChallengeName] = useState<string>();
  const [loginError, setLoginError] = useState(<div />);
  const [isInitialized, setIsInitialized] = useState(false);

  const signOut = async () => {
    await Auth.signOut()
      // eslint-disable-next-line
      .catch((err: any) => {
        // メンテ時。(CognitoはWAFで設定したカスタムレスポンスを無視して独自のエラー形式で返してくる。)
        if (
          err.code === "ForbiddenException" &&
          err.message ===
            "メンテナンス中です。5秒後にメンテナンスページへ遷移します"
        ) {
          reloadAfterFewSeconds();
          setLoginError(<AlertMessage errorMessage={err.message} />);
        }
      })
      .finally(() => {
        setIsAuthenticated(false);
        setIsCustomer(undefined);
        setUserAttributes(undefined);
        setAuthHeader(undefined);
        localStorage.removeItem(STORAGE_KEYS.EXPIRE_TIME);
      });
  };

  // 全環境で現在のSession取得と、ローカル環境の場合にトークン期限切れ更新を試みる
  const initAuthenticated = async () => {
    // Auth.currentSession: 現在のSession取得とトークン期限切れの場合に更新を試みるメソッド
    await Auth.currentSession()
      .then(async (data) => {
        if (data) {
          const idtoken = data.getIdToken().getJwtToken();
          setAuthHeader(`Bearer ${idtoken}`);
          setIsAuthenticated(true);
          setUser(await Auth.currentAuthenticatedUser());
          const parsedPayload = data.getIdToken().decodePayload();
          setUserAttributes(parsedPayload);
          if (
            parsedPayload["cognito:groups"].includes("Customer") ||
            parsedPayload["cognito:groups"].includes("SysAdCustomer")
          ) {
            setIsCustomer(true);
          } else {
            setIsCustomer(false);
          }
        }
        setIsInitialized(true);
      })
      // eslint-disable-next-line
      .catch((err: any) => {
        // メンテ時。(CognitoはWAFで設定したカスタムレスポンスを無視して独自のエラー形式で返してくる。)
        if (
          err.code === "ForbiddenException" &&
          err.message ===
            "メンテナンス中です。5秒後にメンテナンスページへ遷移します"
        ) {
          reloadAfterFewSeconds();
          setLoginError(<AlertMessage errorMessage={err.message} />);
        }
      });
  };

  const reloadCurrentSession = async () => {
    await Auth.currentSession()
      .then(async (data) => {
        const idtoken = data.getIdToken().getJwtToken();
        setAuthHeader(`Bearer ${idtoken}`);
        setUser(await Auth.currentAuthenticatedUser());
        const parsedPayload = data.getIdToken().decodePayload();
        setUserAttributes(parsedPayload);
      })
      // eslint-disable-next-line
      .catch((err: any) => {
        // メンテ時。(CognitoはWAFで設定したカスタムレスポンスを無視して独自のエラー形式で返してくる。)
        if (
          err.code === "ForbiddenException" &&
          err.message ===
            "メンテナンス中です。5秒後にメンテナンスページへ遷移します"
        ) {
          reloadAfterFewSeconds();
          setLoginError(<AlertMessage errorMessage={err.message} />);
        }
      });
  };

  const checkAuthenticated = async () => {
    // Amplify APIを使ってセッション情報を取得すると、期限切れの場合に更新されてしまう模様
    // ローカルストレージに保存した有効期限を使う
    const tokenExpireTimeStr = localStorage.getItem(STORAGE_KEYS.EXPIRE_TIME); // トークン期限（秒）
    if (!tokenExpireTimeStr) {
      // 保存されてなければログアウトさせる
      signOut();
      setIsInitialized(true);
      return;
    }
    const tokenExpireTime = parseInt(atob(tokenExpireTimeStr), 10); // 保存していた有効期限をデコード（とNumberへキャスト）（秒）
    const currentTimeSecond = Math.round(+new Date() / 1000); // 現在時刻（秒）

    // ローカル環境かどうか（buildされてるかどうか）
    const isLocal = process.env.NODE_ENV !== "production";
    // 現在時刻がトークンの期限切れていたらsignOut
    // ローカル環境は適用しない（開発時の邪魔にならないように）
    if (!isLocal && tokenExpireTime < currentTimeSecond) {
      signOut();
      setIsInitialized(true);
      return;
    }
    // セッション初期化
    initAuthenticated();
  };

  // レスポンスデータから加盟店ユーザーか商談担当ユーザーか取得する
  // Amplifyメソッドの戻り値を引数に入れて利用するつもりだが、その型がanyなので、anyとする。
  // eslint-disable-next-line
  const getIsCustomer = (data: any) => {
    if (!data || !data.signInUserSession) {
      return null;
    }
    const parsedPayload = data.signInUserSession.getIdToken().decodePayload();
    return (
      parsedPayload["cognito:groups"].includes("Customer") ||
      parsedPayload["cognito:groups"].includes("SysAdCustomer")
    );
  };

  // Amplifyメソッドの戻り値を引数に入れて利用するつもりだが、その型がanyなので、anyとする。
  // eslint-disable-next-line
  const setParamsAfterSignInSucceeded = (data: any) => {
    const idtoken = data.signInUserSession.getIdToken().getJwtToken();
    setAuthHeader(`Bearer ${idtoken}`);
    cognitoUser.updateLastLoginDate(`Bearer ${idtoken}`);
    const parsedPayload = data.signInUserSession.getIdToken().decodePayload();
    setUserAttributes(parsedPayload);
    setIsCustomer(getIsCustomer(data));
    setIsAuthenticated(true);
    setUser(data);
    setLoginError(<div />);
  };

  /**
   * ログイン処理
   * @description 本メソッド内でAmplifyメソッドを実行（signIn）
   * @param username ユーザーID
   * @param password パスワード
   */
  const signIn = useCallback(
    async ({ username, password }: LoginOption): Promise<ISignInResponse> => {
      // 入力チェック
      if (username === "") {
        setLoginError(
          <AlertMessage errorMessage="ユーザーIDを入力してください。" />
        );
        return {
          result: false,
          newPasswordRequired: false,
          mfaRequired: false,
        };
      }
      if (password === "") {
        setLoginError(
          <AlertMessage errorMessage="パスワードを入力してください。" />
        );
        return {
          result: false,
          newPasswordRequired: false,
          mfaRequired: false,
        };
      }

      // 返ってくるuserのchallengeNameを見て返す値を変える。あと、戻り値をオブジェクトにして、認証成功かどうかと追加のアクションが必要かを入れたオブジェクトを返すようにする。
      try {
        // Amplifyメソッドを実行（signIn）
        const data = await Auth.signIn(username, password);

        // 2段階認証が必要な場合
        if (data.challengeName === "SMS_MFA") {
          setUser(data);
          setChallengeName(data.challengeName);
          setLoginError(<div />);
          // このメソッドを利用したページで2段階認証の入力画面へ誘導するようにハンドリングすること。
          return {
            result: true,
            // stateのisCustomerだと更新ラグがあるので、レスポンスから取得して返す
            isCustomer: getIsCustomer(data),
            newPasswordRequired: false,
            mfaRequired: true,
          };
        }

        // パスワード変更が必要な場合
        if (data.challengeName === "NEW_PASSWORD_REQUIRED") {
          setUser(data);
          setChallengeName(data.challengeName);
          setLoginError(<div />);
          // このメソッドを利用したページで新しいパスワードの入力画面へ誘導するようにハンドリングすること。
          return {
            result: true,
            // stateのisCustomerだと更新ラグがあるので、レスポンスから取得して返す
            isCustomer: getIsCustomer(data),
            newPasswordRequired: true,
            mfaRequired: false,
          };
        }

        // ログインに成功した場合
        setParamsAfterSignInSucceeded(data);
        setLoginError(<div />);
        return {
          result: true,
          // stateのisCustomerだと更新ラグがあるので、レスポンスから取得して返す
          isCustomer: getIsCustomer(data),
          newPasswordRequired: false,
          mfaRequired: false,
        };
        // eslint-disable-next-line
      } catch (err: any) {
        setIsAuthenticated(false);
        setIsCustomer(undefined);
        // メンテ時。(CognitoはWAFで設定したカスタムレスポンスを無視して独自のエラー形式で返してくる。)
        if (
          err.code === "ForbiddenException" &&
          err.message ===
            "メンテナンス中です。5秒後にメンテナンスページへ遷移します"
        ) {
          reloadAfterFewSeconds();
          setLoginError(<AlertMessage errorMessage={err.message} />);

          return {
            result: false,
            newPasswordRequired: false,
            mfaRequired: false,
          };
        }

        if (err.message === "User is disabled.") {
          setLoginError(
            <AlertMessage errorMessage="最終ログインから90日以上経過したため、アカウントがロックされています。管理者以上の権限を持つユーザーでログインし、ユーザー管理画面よりユーザーのロックを解除してください。" />
          );
          return {
            result: false,
            newPasswordRequired: false,
            mfaRequired: false,
          };
        }

        if (
          window.location.pathname === "/dealer/login" &&
          err.message ===
            "Temporary password has expired and must be reset by an administrator."
        ) {
          setLoginError(
            <AlertMessage errorMessage="初回パスワードの有効期限が切れています。管理者権限を持つユーザーでログインし、ユーザー管理画面よりユーザーを作成し直してください。" />
          );
          return {
            result: false,
            newPasswordRequired: false,
            mfaRequired: false,
          };
        }

        if (
          window.location.pathname === "/guest/login" &&
          err.message ===
            "Temporary password has expired and must be reset by an administrator."
        ) {
          setLoginError(
            // TODO 「AlertMessage」のコンポーネントを利用したいが改行ができなさそうだったので一旦このままにしている
            <div style={{ marginBottom: "10px", width: 399, margin: "0 auto" }}>
              <Alert variant="outlined" severity="error">
                初回パスワードの有効期限が切れています。以下のケースに沿って対応を行なってください。
                <br />
                初回ユーザー：担当者にご連絡ください。
                <br />
                初回ユーザー以外：管理者以上の権限を持つユーザーでログインし、ユーザー管理画面よりユーザーを作成し直してください。
              </Alert>
            </div>
          );
          return {
            result: false,
            newPasswordRequired: false,
            mfaRequired: false,
          };
        }

        if (err.message === "Incorrect username or password.") {
          setLoginError(
            <AlertMessage errorMessage="ログインに失敗しました。ユーザー名もしくはパスワードが間違っています。" />
          );
          return {
            result: false,
            newPasswordRequired: false,
            mfaRequired: false,
          };
        }

        setLoginError(
          <AlertMessage errorMessage="ログインに失敗しました。ユーザー名もしくはパスワードが間違っています。" />
        );
        return {
          result: false,
          newPasswordRequired: false,
          mfaRequired: false,
        };
      }
    },
    [setParamsAfterSignInSucceeded]
  );

  /**
   * パスワードリセット確認コード送信処理
   * @description 本メソッド内でAmplifyメソッドを実行（forgotPassword）
   * @param username ユーザーID
   */
  const sendResetCode = async (username: string): Promise<IBaseResponse> => {
    // 入力チェック
    if (username === "") {
      return {
        result: false,
        message: "ユーザーIDを入力してください。",
      };
    }

    try {
      // Amplifyメソッドを実行（forgotPassword）
      await Auth.forgotPassword(username);
      return { result: true, message: "" };
      // eslint-disable-next-line
    } catch (err: any) {
      // メンテ時。(CognitoはWAFで設定したカスタムレスポンスを無視して独自のエラー形式で返してくる。)
      if (
        err.code === "ForbiddenException" &&
        err.message ===
          "メンテナンス中です。5秒後にメンテナンスページへ遷移します"
      ) {
        reloadAfterFewSeconds();

        return {
          result: false,
          message: err.message,
        };
      }
      return { result: false, message: "入力されたユーザーは存在しません。" };
    }
  };

  /**
   * パスワードリセット処理
   * @description 本メソッド内でAmplifyメソッドを実行（forgotPasswordSubmit）
   * @param username ユーザーID
   * @param code 確認コード
   * @param newPassword 新パスワード
   */
  const resetPassword = async ({
    username,
    code,
    newPassword,
  }: IResetPasswordParams): Promise<IBaseResponse> => {
    // 入力チェック
    if (username === "" || code === "" || newPassword === "") {
      return {
        result: false,
        message: "未入力項目を入力してください。",
      };
    }

    try {
      // Amplifyメソッドを実行（forgotPasswordSubmit）
      await Auth.forgotPasswordSubmit(username, code, newPassword);
      // eslint-disable-next-line
    } catch (err: any) {
      // メンテ時。(CognitoはWAFで設定したカスタムレスポンスを無視して独自のエラー形式で返してくる。)
      if (
        err.code === "ForbiddenException" &&
        err.message ===
          "メンテナンス中です。5秒後にメンテナンスページへ遷移します"
      ) {
        reloadAfterFewSeconds();

        return {
          result: false,
          message: err.message,
        };
      }

      if (err.message === "Username/client id combination not found.") {
        return {
          result: false,
          message: "入力されたユーザーは存在しません。",
        };
      }
      return {
        result: false,
        // 不正なコードを入力した場合のerr.messageと、期限切れのコードを入力した場合のerr.messageが同じの為、ユーザーに対して表示するエラーメッセージは汎用的なものとする。
        message:
          "入力されたコードが不正、もしくは確認コードの有効期限が切れています。正しい確認コードを入力するか、再度ログイン画面よりパスワードリセット 確認コード送信画面へ進み、確認コードを発行し直してください。",
      };
    }

    return {
      result: true,
      message: "",
    };
  };

  /**
   * 新パスワード登録処理
   * @description 本メソッド内でAmplifyメソッドを実行（completeNewPassword）
   * @param newPassword 新パスワード
   * @param newPasswordConfirm 新パスワード（確認）
   */
  const renewDefaultPassword = useCallback(
    async ({
      newPassword,
      newPasswordConfirm,
    }: IRenewDefaultPasswordParams): Promise<INewPasswordResponse> => {
      // 入力チェック
      if (newPassword === "" || newPasswordConfirm === "") {
        return {
          result: false,
          message: "パスワードを入力してください。",
        };
      }
      if (newPassword !== newPasswordConfirm) {
        return {
          result: false,
          message: "新しいパスワードが一致していません。",
        };
      }

      try {
        // Cognitoパスワードポリシー違反時(InvalidPasswordException)、その他Cognitoエラーは、catch側のロジックへ飛ぶ。

        // Amplifyメソッドを実行（completeNewPassword）
        const data = await Auth.completeNewPassword(
          user, // the Cognito User Object
          newPassword // the new password
        );

        // 2段階認証が必要な場合
        if (data.challengeName === "SMS_MFA") {
          setUser(data);
          setChallengeName(data.challengeName);
          setLoginError(<div />);
          // このメソッドを利用したページで2段階認証の入力画面へ誘導するようにハンドリングすること。
          return {
            result: true,
            message: "",
            // stateのisCustomerだと更新ラグがあるので、レスポンスから取得して返す
            isCustomer: getIsCustomer(data),
            mfaRequired: true,
          };
        }

        // ログインに成功した場合
        setParamsAfterSignInSucceeded(data);
        return { result: true, message: "", isCustomer: getIsCustomer(data) };
        // eslint-disable-next-line
      } catch (err: any) {
        // メンテ時。(CognitoはWAFで設定したカスタムレスポンスを無視して独自のエラー形式で返してくる。)
        if (
          err.code === "ForbiddenException" &&
          err.message ===
            "メンテナンス中です。5秒後にメンテナンスページへ遷移します"
        ) {
          reloadAfterFewSeconds();

          return {
            result: false,
            message: err.message,
          };
        }

        if (
          err.name === "InvalidPasswordException" ||
          err.name === "InvalidParameterException"
        ) {
          return {
            result: false,
            message:
              "パスワードは大小アルファベット、数字を組み合わせた12文字以上で入力してください。",
          };
        }

        return {
          result: false,
          message: getErrorMessage(err),
        };
      }
    },
    [setParamsAfterSignInSucceeded, user]
  );

  /**
   * 2段階認証処理
   * @description 本メソッド内でAmplifyメソッドを実行（confirmSignIn）
   * @param code 2段階認証コード
   */
  const confirmMfaCode = useCallback(
    async ({ code }: IConfirmMfaCodeParams): Promise<IBaseResponse> => {
      // 入力チェック
      if (code === "") {
        return {
          result: false,
          message: "認証コードを入力してください。",
        };
      }

      try {
        // Amplifyメソッドを実行（confirmSignIn）
        const data = await Auth.confirmSignIn(
          user, // Return object from Auth.signIn()
          code, // Confirmation code
          "SMS_MFA" // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
        );

        // ログインに成功した場合
        setParamsAfterSignInSucceeded(data);
        return { result: true, message: "", isCustomer: getIsCustomer(data) };
        // eslint-disable-next-line
      } catch (err: any) {
        // メンテ時。(CognitoはWAFで設定したカスタムレスポンスを無視して独自のエラー形式で返してくる。)
        if (
          err.code === "ForbiddenException" &&
          err.message ===
            "メンテナンス中です。5秒後にメンテナンスページへ遷移します"
        ) {
          reloadAfterFewSeconds();

          return {
            result: false,
            message: err.message,
          };
        }

        return {
          result: false,
          message: "ログインに失敗しました。認証コードが間違っています。",
        };
      }
    },
    [setParamsAfterSignInSucceeded, user]
  );

  /**
   * メールアドレス変更処理（確認コード送信）
   * @description 本メソッド内でAmplifyメソッドを実行（updateUserAttributes）
   * @param newEmail 新しいメールアドレス
   * @param newEmailConfirm 新しいメールアドレス（確認）
   */
  const changeEmail = useCallback(
    async ({ newEmail, newEmailConfirm }: IChangeEmail) => {
      // 入力チェック
      if (newEmail === "") {
        return {
          result: false,
          message: "メールアドレスを入力してください。",
        };
      }
      if (newEmail.length > EMAIL_MAX_LENGTH) {
        return {
          result: false,
          message: `メールアドレスは最大${EMAIL_MAX_LENGTH}文字です。`,
        };
      }
      if (newEmail !== newEmailConfirm) {
        return {
          result: false,
          message: "メールアドレスが一致していません。",
        };
      }

      try {
        // Amplifyメソッドを実行（updateUserAttributes）
        const result = await Auth.updateUserAttributes(user, {
          email: newEmail,
        });
        if (result === "SUCCESS") {
          return {
            result: true,
            message: "",
          };
        }
        // eslint-disable-next-line
      } catch (err: any) {
        // メンテ時。(CognitoはWAFで設定したカスタムレスポンスを無視して独自のエラー形式で返してくる。)
        if (
          err.code === "ForbiddenException" &&
          err.message ===
            "メンテナンス中です。5秒後にメンテナンスページへ遷移します"
        ) {
          reloadAfterFewSeconds();

          return {
            result: false,
            message: err.message,
          };
        }

        return {
          result: false,
          message: getErrorMessage(err),
        };
      }
      return {
        result: false,
        message: "エラーが発生しました。管理者へお問い合わせください。",
      };
    },
    [user]
  );

  /**
   * メールアドレス変更確認処理
   * @description 本メソッド内でメールアドレス変更APIを実行
   * @param newEmail 新しいメールアドレス
   * @param code 確認コード
   */
  const confirmChangeEmail = useCallback(
    async ({ newEmail, code }: IConfirmChangeEmail) => {
      // 入力チェック
      if (code === "") {
        return {
          result: false,
          message: "確認コードを入力してください。",
        };
      }

      try {
        // メールアドレス変更API
        const updateResult = await cognitoUser.updateCognitoUserEmail(
          authHeader as string,
          user
            ?.getSignInUserSession()
            ?.getAccessToken()
            .getJwtToken() as string,
          newEmail,
          code
        );
        if (updateResult) {
          setLoginError(
            <AlertMessage errorMessage="メールアドレスの変更が完了しました。再度ログインしてください。" />
          );
          // ユーザー情報が含まれたcognitoトークンを更新するために強制的にログアウトしてもらう必要がある。
          await signOut();
          return { result: true, message: "" };
        }
        // eslint-disable-next-line
      } catch (err: any) {
        // メンテ時。(CognitoはWAFで設定したカスタムレスポンスを無視して独自のエラー形式で返してくる。)
        if (
          err.code === "ForbiddenException" &&
          err.message ===
            "メンテナンス中です。5秒後にメンテナンスページへ遷移します"
        ) {
          reloadAfterFewSeconds();

          return {
            result: false,
            message: err.message,
          };
        }

        return {
          result: false,
          message: getErrorMessage(err),
        };
      }
      return {
        result: false,
        message: "エラーが発生しました。管理者へお問い合わせください。",
      };
    },
    [authHeader, user]
  );

  /**
   * パスワード変更処理
   * @description 本メソッド内でAmplifyメソッドを実行（changePassword）
   * @param currentPassword 現在のパスワード
   * @param newPassword 新パスワード
   * @param newPasswordConfirm 新パスワード（確認）
   */
  const changePassword = useCallback(
    async ({
      currentPassword,
      newPassword,
      newPasswordConfirm,
    }: IChangePassword) => {
      // 入力チェック
      if (
        currentPassword === "" ||
        newPassword === "" ||
        newPasswordConfirm === ""
      ) {
        return {
          result: false,
          message: "パスワードを入力してください。",
        };
      }
      if (newPassword.length > PASSWORD_MAX_LENGTH) {
        return {
          result: false,
          message: `パスワードは最大${PASSWORD_MAX_LENGTH}文字です。`,
        };
      }
      if (newPassword !== newPasswordConfirm) {
        return {
          result: false,
          message: "新しいパスワードが一致していません。",
        };
      }

      try {
        // Amplifyメソッドを実行（changePassword）
        const result = await Auth.changePassword(
          user,
          currentPassword,
          newPassword
        );
        if (result === "SUCCESS") {
          return {
            result: true,
            message: "",
          };
        }
        // eslint-disable-next-line
      } catch (err: any) {
        // メンテ時。(CognitoはWAFで設定したカスタムレスポンスを無視して独自のエラー形式で返してくる。)
        if (
          err.code === "ForbiddenException" &&
          err.message ===
            "メンテナンス中です。5秒後にメンテナンスページへ遷移します"
        ) {
          reloadAfterFewSeconds();

          return {
            result: false,
            message: err.message,
          };
        }

        if (err.message === "Incorrect username or password.") {
          return {
            result: false,
            message: "現在のパスワードが正しくありません。",
          };
        }

        if (
          err.name === "InvalidPasswordException" ||
          err.name === "InvalidParameterException"
        ) {
          return {
            result: false,
            message:
              "パスワードは大小アルファベット、数字を組み合わせた12文字以上で入力してください。",
          };
        }

        return {
          result: false,
          message: getErrorMessage(err),
        };
      }
      return {
        result: false,
        message: "エラーが発生しました。管理者へお問い合わせください。",
      };
    },
    [user]
  );

  /**
   * 電話番号変更処理（確認コード送信）
   * @description 本メソッド内でAmplifyメソッドを実行（updateUserAttributes）
   * @param newUserPhoneNum 新しい電話番号
   */
  const changeUserPhoneNum = useCallback(
    async ({ newUserPhoneNum }: IChangeUserPhoneNum) => {
      // 入力チェック
      if (newUserPhoneNum === "") {
        return {
          result: false,
          message: "電話番号を入力してください。",
        };
      }
      if (newUserPhoneNum.length > PHONE_NUMBER_MAX_LENGTH) {
        return {
          result: false,
          message: `電話番号は最大${PHONE_NUMBER_MAX_LENGTH}文字です。`,
        };
      }

      try {
        // Amplifyメソッドを実行（updateUserAttributes）
        const result = await Auth.updateUserAttributes(user, {
          // バリデーションエラーになるため、国際形式に変換
          phone_number: `+81${newUserPhoneNum.slice(1)}`,
        });
        if (result === "SUCCESS") {
          return {
            result: true,
            message: "",
          };
        }
        // eslint-disable-next-line
      } catch (err: any) {
        // メンテ時。(CognitoはWAFで設定したカスタムレスポンスを無視して独自のエラー形式で返してくる。)
        if (
          err.code === "ForbiddenException" &&
          err.message ===
            "メンテナンス中です。5秒後にメンテナンスページへ遷移します"
        ) {
          reloadAfterFewSeconds();

          return {
            result: false,
            message: err.message,
          };
        }

        return {
          result: false,
          message: getErrorMessage(err),
        };
      }
      return {
        result: false,
        message: "エラーが発生しました。管理者へお問い合わせください。",
      };
    },
    [user]
  );

  /**
   * 電話番号変更確認処理
   * @description 本メソッド内で電話番号変更APIを実行
   * @param newUserPhoneNum 新しい電話番号
   * @param code 確認コード
   */
  const confirmChangeUserPhoneNum = useCallback(
    async ({ newUserPhoneNum, code }: IConfirmChangeUserPhoneNum) => {
      // 入力チェック
      if (code === "") {
        return {
          result: false,
          message: "確認コードを入力してください。",
        };
      }

      try {
        // 電話番号変更API
        const updateResult = await cognitoUser.updateCognitoUserPhoneNum(
          authHeader as string,
          user
            ?.getSignInUserSession()
            ?.getAccessToken()
            .getJwtToken() as string,
          newUserPhoneNum,
          code
        );
        if (updateResult) {
          setLoginError(
            <AlertMessage errorMessage="電話番号の変更が完了しました。再度ログインしてください。" />
          );
          // ユーザー情報が含まれたcognitoトークンを更新するために強制的にログアウトしてもらう必要がある。
          await signOut();
          return { result: true, message: "" };
        }
        // eslint-disable-next-line
      } catch (err: any) {
        // メンテ時。(CognitoはWAFで設定したカスタムレスポンスを無視して独自のエラー形式で返してくる。)
        if (
          err.code === "ForbiddenException" &&
          err.message ===
            "メンテナンス中です。5秒後にメンテナンスページへ遷移します"
        ) {
          reloadAfterFewSeconds();

          return {
            result: false,
            message: err.message,
          };
        }

        return {
          result: false,
          message: getErrorMessage(err),
        };
      }
      return {
        result: false,
        message: "エラーが発生しました。管理者へお問い合わせください。",
      };
    },
    [authHeader, user]
  );

  /**
   * ユーザーリスト送信処理
   * @description 本メソッド内でユーザーリスト送付APIを実行
   * @param email メールアドレス
   * @param tid 端末識別番号
   */
  const sendUserList = async ({
    email,
    tid,
  }: ISendUserList): Promise<IBaseResponse> => {
    // 入力チェック
    if (email === "" || tid === "") {
      return {
        result: false,
        message: "未入力項目を入力してください。",
      };
    }

    try {
      await cognitoUser.sendUsers(email, tid);
      return { result: true, message: "" };
      // eslint-disable-next-line
    } catch (err: any) {
      // CognitoではなくAPI Gatewayへのリクエストなので、メンテ用のハンドリングはAPI呼び出し時のラッパーメソッド側に書かれてるので、ここでは書かない。
      return {
        result: false,
        message: getErrorMessage(err),
      };
    }
  };

  // トークン期限更新
  const updateToken = async (): Promise<IBaseResponse> => {
    try {
      // Auth.currentSession()だとすでにトークン期限切れの場合しかトークン期限の更新は行わないので、refreshSessionを使う
      const currentUser = await Auth.currentAuthenticatedUser();
      const { refreshToken } = currentUser.getSignInUserSession();

      // eslint-disable-next-line
      currentUser.refreshSession(refreshToken, (err: any, data: any) => {
        // authHeaderを更新する
        const newIdToken = data.getIdToken();
        setAuthHeader(`Bearer ${newIdToken.getJwtToken()}`);
        const expireTime = newIdToken.getExpiration(); // トークン有効期限（秒）
        // 有効期限保存（一応エンコードしておく）
        localStorage.setItem(STORAGE_KEYS.EXPIRE_TIME, btoa(expireTime));
      });
      return { result: true, message: "" };
      // eslint-disable-next-line
    } catch (err: any) {
      // メンテ時。(CognitoはWAFで設定したカスタムレスポンスを無視して独自のエラー形式で返してくる。)
      if (
        err.code === "ForbiddenException" &&
        err.message ===
          "メンテナンス中です。5秒後にメンテナンスページへ遷移します"
      ) {
        reloadAfterFewSeconds();

        return {
          result: false,
          message: err.message,
        };
      }

      return {
        result: false,
        message: getErrorMessage(err),
      };
    }
  };

  // 初回のマウント時(アプリを開いた瞬間)のみ、現在のログイン状況を確認するメソッドを流したいので第2引数を[]として空配列を渡すため、LintOff.
  useEffect(() => {
    checkAuthenticated();

    // JSX外（utils関数など）から呼ぶものを登録
    // 呼び出し側は cognitoListener.emit(key) で呼び出し可能
    cognitoListener.addListener("updateToken", updateToken);
    cognitoListener.addListener("signOut", signOut);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={useMemo(
        () => ({
          isAuthenticated,
          signIn,
          sendResetCode,
          resetPassword,
          renewDefaultPassword,
          confirmMfaCode,
          changeEmail,
          confirmChangeEmail,
          changePassword,
          changeUserPhoneNum,
          confirmChangeUserPhoneNum,
          signOut,
          sendUserList,
          setLoginError,
          reloadCurrentSession,
          user,
          userAttributes,
          authHeader,
          isCustomer,
          challengeName,
          loginError,
          isInitialized,
        }),
        [
          authHeader,
          challengeName,
          changeEmail,
          changePassword,
          confirmChangeEmail,
          changeUserPhoneNum,
          confirmChangeUserPhoneNum,
          isAuthenticated,
          isCustomer,
          isInitialized,
          loginError,
          renewDefaultPassword,
          confirmMfaCode,
          signIn,
          user,
          userAttributes,
        ]
      )}
    >
      {children}
    </AuthContext.Provider>
  );
}
