/**
 * フォーム関係-JSONのフォーム用のDatePicker（カレンダー表示）
 */
import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { getMonth, getYear } from "date-fns";
import ja from "date-fns/locale/ja";
import moment from "moment";
import range from "lodash/range";
import clsx from "clsx";
import { makeStyles, IconButton } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { TFormColumn, TRequiredKey } from "../../../common/components/types";

import { FONT } from "../../../../constants/common";

import formStore from "../../../../store/formStore";

import ValidationMsg from "./ValidateMsg";
import FormRemarks from "./FormRemarks";

registerLocale("ja", ja);

const useStyles = makeStyles(() => ({
  datepickerWrap: {
    backgroundColor: "#f2f7fb",
  },
  datepicker: {
    padding: "12px",
    width: "100%",
    backgroundColor: "#f2f7fb",
    fontSize: "1rem",
    borderWidth: 0,
    outlineWidth: 1,
  },
  error: {
    border: "2px solid #f44336",
  },
  disabled: {
    backgroundColor: "rgba(0, 0, 0, 0.09)",
  },
  url: {
    fontFamily: FONT,
  },
  errorMsg: {
    color: "#f44336",
    fontSize: "0.75rem",
  },
  datepickerSelect: {
    position: "relative",
    fontFamily: "inherit",
    fontSize: "1.02rem",
    backgroundColor: "transparent",
    margin: 4,
    padding: 4,
    borderRadius: 0,
    border: "none",
    borderBottom: "1px solid rgba(0,0,0, 0.3)",
    "&:focus": {
      outline: "none",
    },
  },
}));

type Props = {
  columnName: TFormColumn;
  requiredKey: TRequiredKey;
  terminalGridId: number | undefined;
  gridId: number | undefined;
  isChangeDeal?: boolean;
};

const CustomDatePicker: React.FC<Props> = (props: Props) => {
  const { columnName, requiredKey, terminalGridId, gridId, isChangeDeal } =
    props;

  const [row, setRow] = useState(
    formStore.getFormRow(columnName, terminalGridId, gridId)
  );
  const selected = useRef<Date | null>(null);

  const disabled = useMemo(
    () => !row?.json.updateEnabled[requiredKey] || row.disabled,
    [row, requiredKey]
  );

  const dateFormat = useMemo(() => row?.json.dateFormat || "", [row]);
  const years = useMemo(
    () => range(getYear(new Date()) - 120, getYear(new Date()) + 20, 1),
    []
  );
  const months = useMemo(() => Array.from(Array(12).keys()), []);

  const classes = useStyles();

  useEffect(() => {
    const fn = () => {
      const r = formStore.getFormRow(columnName, terminalGridId, gridId);
      setRow(r && { ...r });
    };
    formStore.addListener(columnName, fn);

    return () => formStore.removeListener(columnName, fn);
  }, [columnName, terminalGridId, gridId]);

  const handleChange = useCallback(
    (date: Date) => {
      const momentFormat = dateFormat || "YYYY/MM/DD";
      const value = date === null ? "" : moment(date).format(momentFormat);
      selected.current = date;
      formStore.updateContent(
        columnName,
        value,
        requiredKey,
        terminalGridId,
        gridId
      );
    },
    [columnName, requiredKey, dateFormat, terminalGridId, gridId]
  );

  // rowがなければ表示しない
  if (!row) return null;

  return (
    row && (
      <div
        onPaste={(e) => e.preventDefault()} // ペースト禁止
      >
        {row.json.fixedValue !== null ? (
          // 値がなければ単位等も表示しない
          <>{row.content ? row.content : ""}</>
        ) : (
          <>
            {/* DatePicker（カレンダー表示） */}
            <div className={classes.datepickerWrap}>
              <ReactDatePicker
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      margin: 5,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <IconButton
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      <ChevronLeftIcon />
                    </IconButton>
                    <select
                      className={classes.datepickerSelect}
                      value={getYear(date)}
                      onChange={({ target: { value } }) =>
                        changeYear(Number(value as unknown))
                      }
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}年
                        </option>
                      ))}
                    </select>

                    {dateFormat.includes("DD") && (
                      <select
                        className={classes.datepickerSelect}
                        value={months[getMonth(date)]}
                        onChange={({ target: { value } }) =>
                          changeMonth(Number(value as unknown))
                        }
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option + 1}月
                          </option>
                        ))}
                      </select>
                    )}

                    <IconButton
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  </div>
                )}
                selected={selected.current}
                onChange={(date) => handleChange(date as Date)}
                showPopperArrow={false}
                className={clsx(
                  classes.datepicker,
                  disabled ? classes.disabled : "",
                  row.errors.length > 0 ? classes.error : ""
                )}
                locale="ja"
                value={row.content.toString()}
                disabled={disabled}
                // 年月のみ
                showMonthYearPicker={
                  dateFormat.includes("MM") && !dateFormat.includes("DD")
                }
                // 時分あり（間に:などある可能性も想定）
                showTimeSelect={
                  dateFormat.includes("HH") && dateFormat.includes("mm")
                }
                // 時分のみ（間に/などある可能性も想定）
                showTimeSelectOnly={
                  !dateFormat.includes("MM") &&
                  !dateFormat.includes("DD") &&
                  dateFormat.includes("HH") &&
                  dateFormat.includes("mm")
                }
              />
            </div>
            {/* 項目の説明 */}
            <FormRemarks
              remarks={
                isChangeDeal ? row.json.changeFormRemarks : row.json.formRemarks
              }
            />
            {/* 項目の説明（URL） */}
            <a
              className={classes.url}
              href={row.json.url}
              target="_blank"
              rel="noopener noreferrer"
              tabIndex={row.json.url ? 0 : -1}
            >
              {row.json.url}
            </a>
            {/* バリデーションエラーメッセージ */}
            <div className={classes.errorMsg}>
              <ValidationMsg errors={row.errors} />
            </div>
          </>
        )}
      </div>
    )
  );
};

export default CustomDatePicker;
