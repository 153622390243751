import formStore from "../../store/formStore";
import { TFormColumn } from "../../views/common/components/types";

/**
 * 契約者会社名の自動チェック
 * @param columnName: 更新した項目のカラム名
 * @param checkboxStates: 更新した項目のカラム名群
 * @param checked: チェックボックス有無
 * @returns checkedがtrueの場合は追加するTFormColumn[]、falseの場合は除外するTFormColumn[]
 */
export const autoCheckForCompanyName = (
  columnName: TFormColumn,
  checkboxStates: TFormColumn[]
): TFormColumn[] => {
  const targetColumnsOfCompany: TFormColumn[] = [
    "companyNameNonLP",
    "legalPersonality",
    "legalPersonalityPos",
  ];
  const targetColumnsOfPersonal: TFormColumn[] = [
    "representFirstName",
    "representLastName",
  ];
  const applicantType = formStore.getFormRow("applicantType");
  const isPersonal =
    !!applicantType &&
    applicantType.content === JSON.stringify({ "2": "個人" });
  const targetColumns = isPersonal
    ? targetColumnsOfPersonal
    : targetColumnsOfCompany;

  // checkboxStatesに対象カラム名が含まれているかどうか
  const includeChecked = targetColumns.some(
    (item) => item !== columnName && checkboxStates.includes(item)
  );

  if (targetColumns.includes(columnName) && !includeChecked) {
    if (isPersonal) {
      return ["representName", "companyName"];
    }
    return ["companyName"];
  }

  return [];
};

export default autoCheckForCompanyName;
