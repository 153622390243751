/**
 * 加盟店側-登録申請情報入力完了画面
 */
import React, { useEffect, useMemo } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";

import { useContainerStyles } from "../../../../templates/styles";
import {
  buttonStyles,
  completeStyles,
} from "../../../../common/components/styles";

import { GUEST_COLOR } from "../../../../../constants/common";

import formStore from "../../../../../store/formStore";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";

const dealType = "端末増設申請";
// 事前にどのJSONを使用するか選択する形になったので、登録画面にパンクズに遷移させない。
const breadCrumbs = [
  { name: "加盟店管理・検索", link: "/guest/customer" },
  { name: "完了", link: "" },
];

const useStyles = makeStyles(() => ({
  Icon: {
    position: "absolute",
    top: 8,
    left: 20,
  },
  buttonBlock: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  returnButton: {
    width: 250,
    marginLeft: 20,
    "& .MuiButton-label": {
      marginLeft: 34,
    },
  },
}));

const GuestDealNewCompletePage: React.FC<
  RouteComponentProps<{ id: string }>
> = (props: RouteComponentProps<{ id: string }>) => {
  const classes = useStyles();
  const completeClasses = completeStyles();
  const containerClasses = useContainerStyles();
  const buttonClasses = buttonStyles({
    width: "100%",
    marginTop: 0,
    backgroundColor: GUEST_COLOR.primary,
  });

  const { match } = props;

  const dealId = useMemo(() => match.params.id, [match]);

  useEffect(() => {
    // 下書き削除
    formStore.deleteDraft(
      `deal-${formStore.getContractorId()}-${formStore.getCustomerId()}`
    );
  }, []);

  return (
    <Dashboard
      title={dealType}
      color={GUEST_COLOR.base}
      backgroundColor={GUEST_COLOR.primary}
      iconName="deal"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {/* 文言 */}
        <div className={completeClasses.message}>
          登録が完了しました。
          <br />
          <br />
          （申請番号：{dealId}）
        </div>
        <div className={classes.buttonBlock}>
          {/* ダッシュボードに戻るボタン */}
          <Button
            type="button"
            component={Link}
            to="/guest"
            className={clsx(buttonClasses.button, classes.returnButton)}
            variant="contained"
          >
            <KeyboardReturnIcon className={classes.Icon} />
            ダッシュボードに戻る
          </Button>
        </div>
      </Container>
    </Dashboard>
  );
};

export default withRouter(GuestDealNewCompletePage);
