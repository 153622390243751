/**
 * 共通-アコーディオン
 */
import React, { useEffect, useRef, useState } from "react";

import { Accordion, AccordionProps } from "@material-ui/core";

import useObserver from "../../../helpers/form/useObserver";

type ExpandData = { [key: string]: boolean };
// アコーディオン開閉情報（アコーディオンが入れ子の場合に子や孫などはunmountされるためコンポーネント外に保持する）
const EXPAND_DATA: ExpandData = {};

type Props = AccordionProps & { expandKey: string };

const CustomAccordion: React.FC<Props> = (props: Props) => {
  const { children, defaultExpanded, className, expandKey } = props;

  const [init, setInit] = useState(false);
  const [expanded, setExpanded] = useState(defaultExpanded);
  const target = useRef<HTMLDivElement>(null);
  const intersect = useObserver(target, {
    root: document.getElementById("scroll-parent"), // TabPanel
    rootMargin: "300px 0px 300px 0px", // 上下300px広く判定
  });

  useEffect(() => {
    if (EXPAND_DATA[expandKey] === undefined) {
      // データがなければ初期値としてdefaultExpandedの値を保存する
      EXPAND_DATA[expandKey] = !!defaultExpanded;
    }
    setExpanded(EXPAND_DATA[expandKey]);
    // タブ切り替え時に"scroll-parent"がDOMについたあとuseObserverするため
    setInit(true);
    // eslint-disable-next-line
  }, []);

  const handleChange = (expand: boolean) => {
    EXPAND_DATA[expandKey] = expand;
    setExpanded(expand);
  };

  return init ? (
    <Accordion
      defaultExpanded={defaultExpanded}
      className={className}
      TransitionProps={{ unmountOnExit: true }}
      expanded={intersect && expanded} // 描画版以外は閉じて、範囲内の場合は保存したデータを使う
      ref={target}
      onChange={(_, expand) => handleChange(expand)}
    >
      {children}
    </Accordion>
  ) : null;
};

export default CustomAccordion;
