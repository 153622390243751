import { TableRowProps } from "@material-ui/core";
import React, { useMemo } from "react";
import {
  TFormColumn,
  TFormType,
  TRequiredKey,
} from "../../../common/components/types";
import CustomCheckbox from "./CustomCheckbox";
import CustomDatePicker from "./CustomDatePicker";
import CustomFileUploader from "./CustomFileUploader";
import CustomRadioGroup from "./CustomRadioGroup";
import CustomSearchSelect from "./CustomSearchSelect";
import CustomSelect from "./CustomSelect";
import CustomTextArea from "./CustomTextArea";
import CustomTextField from "./CustomTextField";

type Props = TableRowProps & {
  columnName: TFormColumn;
  requiredKey: TRequiredKey;
  formType: TFormType;
  backgroundColor: string;
  terminalGridId: number | undefined;
  gridId: number | undefined;
};
const ChangeDealFormValue: React.FC<Props> = (props: Props) => {
  const {
    columnName,
    requiredKey,
    formType,
    backgroundColor,
    terminalGridId,
    gridId,
  } = props;

  const child = useMemo(() => {
    switch (formType) {
      case "text":
      case "number": {
        return (
          <CustomTextField
            columnName={columnName}
            requiredKey={requiredKey}
            terminalGridId={terminalGridId}
            gridId={gridId}
            isChangeDeal
          />
        );
      }
      case "date": {
        return (
          <CustomDatePicker
            columnName={columnName}
            requiredKey={requiredKey}
            terminalGridId={terminalGridId}
            gridId={gridId}
            isChangeDeal
          />
        );
      }
      case "textArea": {
        return (
          <CustomTextArea
            columnName={columnName}
            requiredKey={requiredKey}
            terminalGridId={terminalGridId}
            gridId={gridId}
            isChangeDeal
          />
        );
      }
      case "select": {
        return (
          <CustomSelect
            columnName={columnName}
            requiredKey={requiredKey}
            terminalGridId={terminalGridId}
            gridId={gridId}
            isChangeDeal
          />
        );
      }
      case "search-select": {
        return (
          <CustomSearchSelect
            columnName={columnName}
            requiredKey={requiredKey}
            terminalGridId={terminalGridId}
            gridId={gridId}
            isChangeDeal
          />
        );
      }
      case "radio": {
        return (
          <CustomRadioGroup
            columnName={columnName}
            requiredKey={requiredKey}
            backgroundColor={backgroundColor}
            terminalGridId={terminalGridId}
            gridId={gridId}
            isChangeDeal
          />
        );
      }
      case "checkbox": {
        return (
          <CustomCheckbox
            columnName={columnName}
            requiredKey={requiredKey}
            backgroundColor={backgroundColor}
            terminalGridId={terminalGridId}
            gridId={gridId}
            isChangeDeal
          />
        );
      }
      case "file": {
        return (
          <CustomFileUploader
            columnName={columnName}
            requiredKey={requiredKey}
            terminalGridId={terminalGridId}
            gridId={gridId}
            isChangeDeal
          />
        );
      }
      default:
        return null;
    }
  }, [
    formType,
    columnName,
    requiredKey,
    backgroundColor,
    terminalGridId,
    gridId,
  ]);

  return child;
};

export default ChangeDealFormValue;
