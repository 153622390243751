/**
 * 加盟店側-パスワード変更完了画面
 */
import React from "react";

import { Container } from "@material-ui/core";

import { useContainerStyles } from "../../../../templates/styles";
import { completeStyles } from "../../../../common/components/styles";

import { GUEST_COLOR } from "../../../../../constants/common";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";

const GuestSettingPasswordEditCompletePage: React.FC = () => {
  const classes = completeStyles();
  const containerClasses = useContainerStyles();
  const breadCrumbs = [
    { name: "設定", link: "/guest/setting" },
    { name: "パスワード変更", link: "/guest/setting/password/edit" },
    { name: "完了", link: "" },
  ];

  return (
    <Dashboard
      title="設定"
      color={GUEST_COLOR.base}
      backgroundColor={GUEST_COLOR.primary}
      iconName="setting"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        <div className={classes.message}>パスワードの変更が完了しました。</div>
      </Container>
    </Dashboard>
  );
};

export default GuestSettingPasswordEditCompletePage;
