import { errorObject } from "../views/common/components/types";
import { SJIS_WHITE_LIST } from "../constants/common";

// 正規表現チェック
export const checkPattern = (
  str: string,
  charType: string,
  ChartypeFormat: string,
  errArray: Array<errorObject>
) => {
  let ErrArrayProp = errArray;
  const regexp = new RegExp(charType);
  if (!regexp.test(str as string) && str.length !== 0) {
    if (!ErrArrayProp.some((error) => error.type === "pattern")) {
      ErrArrayProp.push({
        type: "pattern",
        message: `正しい形式(${ChartypeFormat})で入力してください。`,
      });
    }
  } else {
    ErrArrayProp = ErrArrayProp.filter(
      (error: errorObject) => error.type !== "pattern"
    );
  }
  return ErrArrayProp;
};

// Shift_JIS(JIS X 0208)チェック
export const compareSJISWhiteList: (texts: string[]) => string = (
  texts: string[]
) => {
  let errChar = "";
  texts.forEach((str) => {
    for (let i = 0; i < str.length; i += 1) {
      const char = str.charAt(i);
      // 改行とtab入力も許可する
      if (!SJIS_WHITE_LIST.includes(char) && char !== "\n" && char !== "\t") {
        if (errChar) {
          errChar += ",";
        }
        errChar += char;
      }
    }
  });

  return errChar;
};
