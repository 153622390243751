import { PREFECTURE_LIST, Prefecture } from "../constants/common";

/**
 * 都道府県の名称からローマ字表記（大文字）を取得
 */
export const getPrefectureRoman = (prefecture: Prefecture) =>
  (Object.keys(PREFECTURE_LIST) as Array<keyof typeof PREFECTURE_LIST>).find(
    (key) => PREFECTURE_LIST[key] === prefecture
  );

export default getPrefectureRoman;
