/**
 * ユーザー管理関連の設定値
 * メモ：JTB、加盟店ともに設定値はこちら
 */

// 加盟店側ユーザー名パターン
export const CUSTOMER_USER_NAME_PATTERN = "mch";

/**
 * ユーザー分類
 */
export const USER_TYPE_LIST = {
  /** JTB管理者 */
  MANAGER_JTB: "80",
  /** JTB一般 */
  STAFF_JTB: "70",
  /** JTBシステム担当者 */
  SYSAD_JTB: "90",
  /** 本社スーパーユーザー */
  SUPER_REPRESENTATIVE_MANAGER: "10",
  /** 本社管理者 */
  REPRESENTATIVE_MANAGER: "20",
  /** 加盟店管理者 */
  MANAGER: "30",
  /** 加盟店一般(事務担当) */
  OFFICE_STAFF: "40",
  /** 加盟店一般(経理) */
  ACCOUNTING: "50",
  /** 加盟店システム担当者 */
  SYSAD_CUSTOMER: "60",
  /** カード会社ユーザー */
  CARD_COMPANY: "100",
  /** カード会社システム担当者 */
  SYSAD_CARD_COMPANY: "110",
} as const;

/**
 * ユーザー分類名称
 */
export const USER_TYPE_NAME_LIST = {
  /** JTBユーザー: 管理者 */
  MANAGER_JTB: "管理者",
  /** JTBユーザー: 一般 */
  STAFF_JTB: "一般",
  /** JTBユーザー: JTBシステム担当者 */
  SYSAD_JTB: "JTBシステム担当者",
  /** 加盟店ユーザー: 本社スーパーユーザー */
  SUPER_REPRESENTATIVE_MANAGER: "本社スーパーユーザー",
  /** 加盟店ユーザー: 本社管理者 */
  REPRESENTATIVE_MANAGER: "本社管理者",
  /** 加盟店ユーザー: 加盟店管理者 */
  MANAGER: "加盟店管理者",
  /** 加盟店ユーザー: 加盟店一般(事務担当) */
  OFFICE_STAFF: "加盟店一般(事務担当)",
  /** 加盟店ユーザー: 加盟店一般(経理) */
  ACCOUNTING: "加盟店一般(経理)",
  /** 加盟店ユーザー: 加盟店システム担当者 */
  SYSAD_CUSTOMER: "加盟店システム担当者",
  /** カード会社ユーザー: カード会社ユーザー */
  CARD_COMPANY: "カード会社ユーザー",
  /** カード会社ユーザー: カード会社システム担当者 */
  SYSAD_CARD_COMPANY: "カード会社システム担当者",
} as const;

// JTB用 ユーザー分類名称リスト
export const JTB_USER_TYPE_NAME_LIST = [
  USER_TYPE_NAME_LIST.MANAGER_JTB,
  USER_TYPE_NAME_LIST.STAFF_JTB,
] as const;

/**
 * JTB社ユーザー検索用のユーザー種別マッピング
 * @description 名称: 番号（文字列）
 * @member 指定なし: ""
 * @member 管理者: "80"
 * @member 一般: "70"
 */
export const USER_TYPE_SEARCH_MAP_FOR_JTB: { [key: string]: string } = {
  指定なし: "",
  [USER_TYPE_NAME_LIST.MANAGER_JTB]: USER_TYPE_LIST.MANAGER_JTB,
  [USER_TYPE_NAME_LIST.STAFF_JTB]: USER_TYPE_LIST.STAFF_JTB,
};

// 加盟店ユーザー検索用 ユーザー分類配列
export const USER_TYPE_SEARCH_MAP_FOR_CUSTOMER: { [key: string]: string } = {
  指定なし: "",
  [USER_TYPE_NAME_LIST.SUPER_REPRESENTATIVE_MANAGER]:
    USER_TYPE_LIST.SUPER_REPRESENTATIVE_MANAGER,
  [USER_TYPE_NAME_LIST.REPRESENTATIVE_MANAGER]:
    USER_TYPE_LIST.REPRESENTATIVE_MANAGER,
  [USER_TYPE_NAME_LIST.MANAGER]: USER_TYPE_LIST.MANAGER,
  [USER_TYPE_NAME_LIST.OFFICE_STAFF]: USER_TYPE_LIST.OFFICE_STAFF,
  [USER_TYPE_NAME_LIST.ACCOUNTING]: USER_TYPE_LIST.ACCOUNTING,
};

/**
 * 自社ユーザー追加用のユーザー種別マッピング
 * @description 名称: 番号（文字列）
 * @member 管理者: "80"
 * @member 一般: "70"
 */
export const USER_TYPE_NEW_MAP: { [key: string]: string } = {
  [USER_TYPE_NAME_LIST.MANAGER_JTB]: USER_TYPE_LIST.MANAGER_JTB,
  [USER_TYPE_NAME_LIST.STAFF_JTB]: USER_TYPE_LIST.STAFF_JTB,
};

/**
 * 自社ユーザー編集用のユーザー種別マッピング
 * @description 名称: 番号（文字列）
 * @member 管理者: "80"
 * @member 一般: "70"
 * @member カード会社ユーザー: "100"
 */
export const USER_TYPE_EDIT_MAP: { [key: string]: string } = {
  [USER_TYPE_NAME_LIST.MANAGER_JTB]: USER_TYPE_LIST.MANAGER_JTB,
  [USER_TYPE_NAME_LIST.STAFF_JTB]: USER_TYPE_LIST.STAFF_JTB,
  [USER_TYPE_NAME_LIST.CARD_COMPANY]: USER_TYPE_LIST.CARD_COMPANY,
};

// ユーザー分類の名称変換表
export const USER_TYPE_CONVERT_MAP = new Map<string, string>([
  [
    USER_TYPE_LIST.SUPER_REPRESENTATIVE_MANAGER,
    USER_TYPE_NAME_LIST.SUPER_REPRESENTATIVE_MANAGER,
  ],
  [
    USER_TYPE_LIST.REPRESENTATIVE_MANAGER,
    USER_TYPE_NAME_LIST.REPRESENTATIVE_MANAGER,
  ],
  [USER_TYPE_LIST.MANAGER, USER_TYPE_NAME_LIST.MANAGER],
  [USER_TYPE_LIST.OFFICE_STAFF, USER_TYPE_NAME_LIST.OFFICE_STAFF],
  [USER_TYPE_LIST.ACCOUNTING, USER_TYPE_NAME_LIST.ACCOUNTING],
  [USER_TYPE_LIST.SYSAD_CUSTOMER, USER_TYPE_NAME_LIST.SYSAD_CUSTOMER],
  [USER_TYPE_LIST.STAFF_JTB, USER_TYPE_NAME_LIST.STAFF_JTB],
  [USER_TYPE_LIST.MANAGER_JTB, USER_TYPE_NAME_LIST.MANAGER_JTB],
  [USER_TYPE_LIST.SYSAD_JTB, USER_TYPE_NAME_LIST.SYSAD_JTB],
  [USER_TYPE_LIST.CARD_COMPANY, USER_TYPE_NAME_LIST.CARD_COMPANY],
  [USER_TYPE_LIST.SYSAD_CARD_COMPANY, USER_TYPE_NAME_LIST.SYSAD_CARD_COMPANY],
]);
