/**
 * JTB側-加盟店ユーザー編集入力フォーム
 * メモ：入力フォームを表示する
 */
import React from "react";

import moment from "moment";
import clsx from "clsx";
import {
  FormControl,
  FormControlLabel,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  RadioGroup,
  Radio,
} from "@material-ui/core";

import {
  radioStyles,
  checkboxStyles,
} from "../../../../common/components/styles";

import { GuestUserEditItemObject } from "../../types";

import {
  DISPLAY_NAME_MAX_LENGTH,
  ADMIN_COLOR,
  FONT,
} from "../../../../../constants/common";
import { USER_TYPE_NAME_LIST } from "../../../../../constants/user";

import { extractCustomerTypeFromObject } from "../../../../../utils/string";

import CustomTextField from "../../../../common/components/atoms/CustomTextField";
import VariableCustomCheckbox from "../../../../common/components/atoms/VariableCustomCheckbox";

const useStyles = makeStyles(() => ({
  table: {
    width: "100%",
    minWidth: 800,
  },
  td: {
    padding: 1,
    backgroundColor: ADMIN_COLOR.base,
    fontFamily: FONT,
    color: "#000!important",
    borderTop: "1px solid #d2d5d8",
    borderBottom: "1px solid #d2d5d8",
    borderRight: "1px solid #d2d5d8",
    width: 400,
  },
  text: {
    padding: "18.5px 14px",
    font: "inherit",
    backgroundColor: "rgba(0, 0, 0, 0.09)",
  },
  checkbox: {
    margin: "10px 0",
  },
  th: {
    padding: 0,
    fontFamily: FONT,
    backgroundColor: "#fff",
    color: "#000!important",
    width: 200,
    border: "1px solid #d2d5d8",
    position: "relative",
    paddingLeft: 20,
  },
  formControl: {
    marginTop: "0px!important",
  },
  pageLabel: {
    marginBottom: 10,
  },
  lengthText: {
    color: "#777",
    fontFamily: FONT,
    fontSize: "0.7rem",
    fontWeight: 400,
    paddingLeft: 7,
  },
  infoText: {
    color: "#777",
    fontFamily: FONT,
    fontSize: "0.7rem",
    fontWeight: 400,
  },
  radioTd: {
    paddingLeft: 11,
  },
  emptyMessage: {
    margin: 7,
  },
}));

// 加盟店ユーザー編集フォームのインターフェース
interface Props {
  userEditState: GuestUserEditItemObject;
  setUserEditState: (value: GuestUserEditItemObject) => void;
}

const GuestSettingUserEditForm: React.FC<Props> = (props: Props) => {
  const checkboxClasses = checkboxStyles({
    backgroundColor: ADMIN_COLOR.primary,
  });
  const classes = useStyles();
  const backgroundColor = "#005bac";
  const radioClasses = radioStyles({ backgroundColor });

  const { userEditState, setUserEditState } = props;

  return (
    <FormControl className={classes.formControl}>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            {/* ユーザーID */}
            <TableRow key="ユーザーID">
              <TableCell align="left" className={classes.th}>
                ユーザーID
              </TableCell>
              <TableCell
                align="left"
                className={clsx(classes.td, classes.text)}
              >
                {userEditState.userName}
              </TableCell>
            </TableRow>
            {/* ユーザー名 */}
            <TableRow key="ユーザー名">
              <TableCell align="left" className={classes.th}>
                ユーザー名
                <span
                  className={classes.lengthText}
                >{`(最大${DISPLAY_NAME_MAX_LENGTH}文字)`}</span>
              </TableCell>
              <TableCell align="left" className={clsx(classes.td)}>
                <CustomTextField
                  type="text"
                  value={userEditState.name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUserEditState({ ...userEditState, name: e.target.value })
                  }
                />
              </TableCell>
            </TableRow>
            {/* ユーザー種別 */}
            <TableRow key="ユーザー種別">
              <TableCell align="left" className={classes.th}>
                ユーザー種別
              </TableCell>
              <TableCell
                align="left"
                className={clsx(classes.td, classes.text)}
              >
                {userEditState.userType}
              </TableCell>
            </TableRow>
            {/* 所属加盟店  本社スーパーユーザー、本社管理者の場合は所属加盟店を登録しない */}
            {userEditState.customers.length > 0 &&
              userEditState.userType !==
                USER_TYPE_NAME_LIST.SUPER_REPRESENTATIVE_MANAGER &&
              userEditState.userType !==
                USER_TYPE_NAME_LIST.REPRESENTATIVE_MANAGER && (
                <TableRow key="所属加盟店">
                  <TableCell align="left" className={classes.th}>
                    所属加盟店
                  </TableCell>
                  <TableCell
                    align="left"
                    className={clsx(classes.td, classes.radioTd)}
                  >
                    <div>
                      <RadioGroup>
                        {userEditState.customers.map((radio) => (
                          <FormControlLabel
                            key={radio.masterCustomerId}
                            className={radioClasses.radio}
                            value={radio.masterCustomerId}
                            control={
                              <Radio
                                onClick={(
                                  e: React.MouseEvent<HTMLElement, MouseEvent>
                                ) =>
                                  setUserEditState({
                                    ...userEditState,
                                    masterCustomerId: (
                                      e.target as HTMLInputElement
                                    ).value,
                                  })
                                }
                                checked={
                                  userEditState.masterCustomerId !== null &&
                                  radio.masterCustomerId ===
                                    Number(userEditState.masterCustomerId)
                                }
                                disabled={false}
                              />
                            }
                            label={`${
                              radio.customerName
                            }[${extractCustomerTypeFromObject(
                              radio.customerType
                            )}] (運用開始日:${
                              radio.customerStartDate
                                ? moment(radio.customerStartDate).format(
                                    "YYYY-MM-DD"
                                  )
                                : "-"
                            })`}
                          />
                        ))}
                      </RadioGroup>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            {/* ロックされているユーザーのみ表示 */}
            {userEditState.isLocked && (
              <TableRow key="ロック解除">
                <TableCell align="left" className={classes.th}>
                  ロック解除
                  <div className={classes.infoText}>
                    ロックされているユーザーのみ表示されます。
                  </div>
                </TableCell>
                <TableCell align="left" className={classes.td}>
                  <FormControlLabel
                    className={clsx(checkboxClasses.checkbox, classes.checkbox)}
                    control={
                      <VariableCustomCheckbox
                        name=""
                        value=""
                        checked={userEditState.releaseLock as boolean}
                        onChange={() => {
                          setUserEditState({
                            ...userEditState,
                            releaseLock: !userEditState.releaseLock,
                          });
                        }}
                      />
                    }
                    label=""
                  />
                </TableCell>
              </TableRow>
            )}
            {/* ロックされていないユーザーのみ表示 */}
            {!userEditState.isLocked && (
              <TableRow key="ロック">
                <TableCell align="left" className={classes.th}>
                  ロック
                </TableCell>
                <TableCell align="left" className={classes.td}>
                  <FormControlLabel
                    className={clsx(checkboxClasses.checkbox, classes.checkbox)}
                    control={
                      <VariableCustomCheckbox
                        name=""
                        value=""
                        checked={userEditState.isLock as boolean}
                        onChange={() => {
                          setUserEditState({
                            ...userEditState,
                            isLock: !userEditState.isLock,
                          });
                        }}
                      />
                    }
                    label=""
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </FormControl>
  );
};

export default GuestSettingUserEditForm;
