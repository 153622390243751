import React from "react";
import { Table, TableBody, TableContainer } from "@material-ui/core";
import { TRequiredKey } from "../../../common/components/types";
import { ThemeColorProps } from "../../../../constants/common";
import { formStyles } from "../../../common/components/styles";
import { convertToChangeDealContents } from "../../../../utils/changeDeal";
import { TWorkflowStatus } from "../../../../constants/workflowStatus";
import ChangeDealShowFormValueRow from "../molecules/ChangeDealShowFormValueRow";
import ChangeDealShowItemHeading from "../../../common/components/atoms/ChangeDealShowItemHeading";

type Props = {
  changeDealContents: ReturnType<typeof convertToChangeDealContents>;
  colors: ThemeColorProps;
  requiredKey: TRequiredKey;
  workflowStatus: TWorkflowStatus;
};
const ChangeDealShowGroup: React.FC<Props> = (props: Props) => {
  const { changeDealContents, colors, requiredKey, workflowStatus } = props;

  const formClasses = formStyles({
    color: colors.base,
    backgroundColor: colors.primary,
  });

  return (
    <div>
      <ChangeDealShowItemHeading
        colors={colors}
        workflowStatus={workflowStatus}
      />
      <TableContainer className={formClasses.tableContainer}>
        <Table>
          <TableBody>
            {changeDealContents.map((row) => (
              <ChangeDealShowFormValueRow
                key={row.columnName}
                row={row}
                requiredKey={requiredKey}
                workflowStatus={workflowStatus}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ChangeDealShowGroup;
