/**
 * 加盟店側-端末増設申請画面
 * メモ：契約者情報、加盟店情報は入力不可
 */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { withRouter, Link, RouteComponentProps } from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles, Tabs, Tab } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CheckIcon from "@material-ui/icons/Check";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import StoreIcon from "@material-ui/icons/Store";

import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { TJsonRowObject } from "../../../../common/components/types";

import {
  DEAL_FORM_TAB_NAMES,
  FORM_CATEGORY,
} from "../../../../../constants/form";
import { GUEST_COLOR, FONT } from "../../../../../constants/common";

import { GlobalPopupContext, PathContext } from "../../../../../App";
import formStore from "../../../../../store/formStore";
import deal from "../../../../../api/deal";
import contractors from "../../../../../api/contractors";
import customer from "../../../../../api/customer";
import preloadFormContent from "../../../../../api/utils/preUtils";
import {
  getContractorContents,
  getCustomerContents,
  json2FormData,
} from "../../../../../utils/formData";
import { getErrorMessage } from "../../../../../utils/error";
import { AuthContext } from "../../../../../cognito/AuthContext";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import TabPanel from "../../../../common/components/atoms/TabPanel";
import NewForm from "../../../../form/components/organisms/NewForm";
import ConfirmForm from "../../../../form/components/organisms/ConfirmForm";
import TerminalForm from "../../../../form/components/organisms/TerminalForm";

const dealType = "端末増設申請";
const breadCrumbs = [
  { name: "加盟店管理・検索", link: "/guest/customer" },
  { name: dealType, link: "" },
];

const useStyles = makeStyles(() => ({
  attention: {
    fontSize: "0.7rem",
    textAlign: "right",
    fontFamily: FONT,
    marginTop: 3,
  },
  returnButton: {
    margin: "0 auto",
    paddingTop: 8,
    paddingBottom: 10,
    paddingLeft: 42,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 24,
    },
  },
  checkButton: {
    margin: "0 auto",
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 8,
    color: `${GUEST_COLOR.primary}!important`,
    border: `1px solid ${GUEST_COLOR.primary}`,
    backgroundColor: `${GUEST_COLOR.base}!important`,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 16,
    },
    "&:hover": {
      backgroundColor: `${GUEST_COLOR.btnHover}!important`,
    },
  },
  returnIcon: {
    position: "absolute",
    top: 10,
    left: 31,
  },
  checkIcon: {
    position: "absolute",
    top: 8,
    left: 20,
  },
  messageContainer: {
    position: "relative",
    minHeight: 80,
  },
  settingIcon: {
    verticalAlign: "top",
    left: -2,
    top: 1,
    position: "relative",
  },
  Icon: {
    verticalAlign: "top",
    left: -2,
    top: 1,
    position: "relative",
  },
  tabs: {
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: GUEST_COLOR.primary,
      color: GUEST_COLOR.base,
    },
  },
  tab: {
    color: GUEST_COLOR.primary,
    border: `3px solid ${GUEST_COLOR.primary}`,
    marginRight: 4,
    minHeight: 35,
    borderBottom: "none",
    fontFamily: FONT,
    opacity: 1,
    borderRadius: "8px 8px 0px 0px",
    "& .MuiTab-wrapper": {
      display: "inline!important",
    },
    "& .MuiTab-labelIcon": {
      minHeight: 35,
    },
  },
  indicator: {
    display: "none",
  },
  message: {
    fontSize: "0.9em",
    fontWeight: "bold",
    fontFamily: FONT,
    color: GUEST_COLOR.primary,
    marginBottom: 15,
  },
  buttonContainer: {
    margin: "0 auto",
    clear: "both",
    height: 100,
    width: 284,
    marginTop: 20,
  },
  gridButtons: {
    float: "left",
  },
}));

const GuestDealNewPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const { history } = props;

  const { authHeader } = useContext(AuthContext);
  const { setLoading, setSnackbar } = useContext(GlobalPopupContext);
  const { prevPath } = useContext(PathContext);
  const [init, setInit] = useState(false);
  const [errorMessage, setError] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [isShowCheckSheet, setShowCheckSheet] = useState(() =>
    formStore.isShowChackSheet()
  );

  const getJson = useCallback(async () => {
    if (!authHeader) return;
    setLoading(true);
    try {
      if (prevPath.includes("/guest/deal/confirm")) {
        // 確認画面からの遷移時はAPIで取り直さない
        setError("");
        setLoading(false);
        setInit(true);
      } else {
        // Json取得
        const ret = await deal.getFormMaster(authHeader, "latest");
        await preloadFormContent(authHeader, "latest");
        const data = json2FormData(ret.data.result as TJsonRowObject[]);
        formStore.setFormData(
          data.formData,
          data.formGroupData,
          data.columnToCategory
        );

        const contractorId = formStore.getContractorId();
        const customerId = formStore.getCustomerId();

        if (contractorId !== null) {
          // 契約者情報取得API
          const contractorData = await contractors.show(
            authHeader,
            contractorId.toString()
          );
          formStore.updateContents(
            getContractorContents(
              contractorData.data.result.contractors[0].masterContractorForms
            ).updateData,
            "customer"
          );
        }
        if (customerId !== null) {
          // 加盟店情報取得API
          const customerData = await customer.show(
            authHeader,
            customerId.toString()
          );
          formStore.updateContents(
            getCustomerContents(
              customerData.data.result.masterCustomers[0].masterCustomerForms
            ).updateData,
            "customer"
          );
        }

        setError("");
        setLoading(false);
        setInit(true);
      }
      // eslint-disable-next-line
    } catch (error: any) {
      setError(getErrorMessage(error));
      setLoading(false);
      setInit(true);
    }
  }, [setLoading, authHeader, prevPath]);

  const classes = useStyles();
  const moveButtonClasses = buttonStyles({
    width: 130,
    marginTop: 20,
    backgroundColor: GUEST_COLOR.primary,
  });
  const containerClasses = useContainerStyles();

  // Jsonデータ取得
  useEffect(() => {
    const fn = () => {
      setShowCheckSheet(formStore.isShowChackSheet());
    };

    const key = `deal-${formStore.getContractorId()}-${formStore.getCustomerId()}`;
    const save = () => {
      formStore.saveDraft(key);
    };

    getJson();

    // 加盟店分類で表示タブが変わるので
    formStore.addListener("customerType", fn);
    formStore.addListener("updatedContent", save);
    return () => {
      formStore.removeListener("customerType", fn);
      formStore.removeListener("updatedContent", save);
    };
    // eslint-disable-next-line
  }, []);

  const handleChange = useCallback((_, newValue: number) => {
    setTabValue(newValue);
  }, []);

  const handleToConfirmPage = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    // ボタンクリック以外（エンターキー押下など）の場合は何もしない
    if (e.detail !== 1) return;

    // バリデーション実行
    if (formStore.validationAll("customer")) {
      history.push("/guest/deal/confirm");
    } else {
      // エラーのあるタブに移動
      const category = formStore.getFocusErrorCategory();
      if (category > 0) {
        setTabValue(category - 1);
      }
      setSnackbar({
        openProps: true,
        message: "入力不備のフィールドがあります。",
      });
    }
  };

  return (
    <Dashboard
      title={dealType}
      color={GUEST_COLOR.base}
      backgroundColor={GUEST_COLOR.primary}
      iconName="deal"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        {init && !errorMessage && (
          <>
            <div className={classes.messageContainer}>
              {/* 文言 */}
              <div className={classes.message}>
                {`タブをクリックすることで「${DEAL_FORM_TAB_NAMES.CONTRACTOR_INFO}」、「${DEAL_FORM_TAB_NAMES.CUSTOMER_INFO}」、「${DEAL_FORM_TAB_NAMES.TERMINAL_INFO}」`}
                {isShowCheckSheet &&
                  `、「${DEAL_FORM_TAB_NAMES.IMPORTANT_INFO}」`}
                の各フォームが表示されます。
              </div>
            </div>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              indicatorColor="primary"
              className={classes.tabs}
              classes={{ indicator: classes.indicator }}
            >
              {/* 契約者情報タブ */}
              <Tab
                label={` ${DEAL_FORM_TAB_NAMES.CONTRACTOR_INFO}`}
                id="simple-tab-0"
                aria-labelledby="simple-tab-0"
                icon={<BusinessCenterIcon className={classes.Icon} />}
                className={classes.tab}
              />
              {/* 加盟店情報タブ */}
              <Tab
                label={` ${DEAL_FORM_TAB_NAMES.CUSTOMER_INFO}`}
                id="simple-tab-1"
                aria-labelledby="simple-tab-1"
                icon={<StoreIcon className={classes.Icon} />}
                className={classes.tab}
              />
              {/* 端末情報タブ */}
              <Tab
                label={` ${DEAL_FORM_TAB_NAMES.TERMINAL_INFO}`}
                id="simple-tab-2"
                aria-labelledby="simple-tab-2"
                icon={<PhonelinkSetupIcon className={classes.Icon} />}
                className={classes.tab}
              />
              {/* 重要事項タブ */}
              {isShowCheckSheet && (
                <Tab
                  label={` ${DEAL_FORM_TAB_NAMES.IMPORTANT_INFO}`}
                  id="simple-tab-3"
                  aria-labelledby="simple-tab-3"
                  icon={<CheckBoxIcon className={classes.settingIcon} />}
                  className={classes.tab}
                />
              )}
            </Tabs>
            <form>
              {/* 契約者情報 入力不可 */}
              <TabPanel
                value={tabValue}
                index={0}
                borderColor={GUEST_COLOR.primary}
              >
                <ConfirmForm
                  formCategory={FORM_CATEGORY.CONTRACTOR} // 契約者情報
                  requiredKey="customer"
                  colors={GUEST_COLOR}
                  terminalGridId={undefined}
                />
              </TabPanel>
              {/* 加盟店情報 入力不可 */}
              <TabPanel
                value={tabValue}
                index={1}
                borderColor={GUEST_COLOR.primary}
              >
                <ConfirmForm
                  formCategory={FORM_CATEGORY.CUSTOMER} // 加盟店情報
                  requiredKey="customer"
                  colors={GUEST_COLOR}
                  terminalGridId={undefined}
                />
              </TabPanel>
              {/* 端末情報 入力可能 */}
              <TabPanel
                value={tabValue}
                index={2}
                borderColor={GUEST_COLOR.primary}
              >
                <TerminalForm
                  type="new"
                  requiredKey="customer"
                  colors={GUEST_COLOR}
                />
              </TabPanel>
              {/* 重要事項 入力可能 */}
              {isShowCheckSheet && (
                <TabPanel
                  value={tabValue}
                  index={3}
                  borderColor={GUEST_COLOR.primary}
                >
                  <NewForm
                    formCategory={FORM_CATEGORY.CHECK_SHEET} // 重要事項チェックシート
                    requiredKey="customer"
                    colors={GUEST_COLOR}
                    terminalGridId={undefined}
                  />
                </TabPanel>
              )}
              <div className={classes.buttonContainer}>
                {/* 戻るボタン */}
                <Button
                  className={clsx(
                    moveButtonClasses.button,
                    classes.returnButton,
                    classes.gridButtons
                  )}
                  type="button"
                  component={Link}
                  to="/guest/customer"
                  variant="contained"
                >
                  <KeyboardReturnIcon className={classes.returnIcon} />
                  戻る
                </Button>
                {/* 確認するボタン */}
                <Button
                  type="submit"
                  onClick={handleToConfirmPage}
                  className={clsx(
                    moveButtonClasses.button,
                    classes.checkButton
                  )}
                  variant="contained"
                >
                  <CheckIcon className={classes.checkIcon} />
                  確認する
                </Button>
              </div>
            </form>
          </>
        )}
      </Container>
    </Dashboard>
  );
};

export default withRouter(GuestDealNewPage);
