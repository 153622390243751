/**
 * 加盟店側-設定画面
 * メモ：設定系画面の親コンポーネント
 */
import React from "react";
import { Route, Switch } from "react-router-dom";

import GuestSettingUserNewPage from "./pages/GuestSettingUserNewPage";
import GuestSettingPasswordEditPage from "./pages/GuestSettingPasswordEditPage";
import GuestSettingMailEditPage from "./pages/GuestSettingMailEditPage";
import GuestSettingMailEditConfirmPage from "./pages/GuestSettingMailEditConfirmPage";
import GuestSettingUserNewCompletePage from "./pages/GuestSettingUserNewCompletePage";
import GuestSettingPage from "./pages/GuestSettingPage";
import GuestSettingPasswordEditCompletePage from "./pages/GuestSettingPasswordEditCompletePage";
import GuestSettingUserManagementPage from "./pages/GuestSettingUserManagementPage";
import GuestSettingUserEditPage from "./pages/GuestSettingUserEditPage";
import GuestSettingDisplayNameEditPage from "./pages/GuestSettingDisplayNameEditPage";

const GuestSettingHooks: React.FC = () => {
  return (
    <Switch>
      {/* ユーザー追加画面 */}
      <Route
        exact
        path="/guest/setting/user/new"
        render={() => <GuestSettingUserNewPage />}
      />
      {/* ユーザー追加完了画面 */}
      <Route
        exact
        path="/guest/setting/user/new/complete"
        render={() => <GuestSettingUserNewCompletePage />}
      />
      {/* ユーザー編集画面 */}
      <Route
        path="/guest/setting/user/:id/edit"
        render={() => <GuestSettingUserEditPage />}
      />
      {/* ユーザー管理・検索画面 */}
      <Route
        exact
        path="/guest/setting/user"
        render={() => <GuestSettingUserManagementPage />}
      />
      {/* メールアドレス変更画面 */}
      <Route
        exact
        path="/guest/setting/mail/edit"
        render={() => <GuestSettingMailEditPage />}
      />
      {/* メールアドレス変更確認コード入力画面 */}
      <Route
        exact
        path="/guest/setting/mail/edit/confirm"
        render={() => <GuestSettingMailEditConfirmPage />}
      />
      {/* パスワード変更画面 */}
      <Route
        exact
        path="/guest/setting/password/edit"
        render={() => <GuestSettingPasswordEditPage />}
      />
      {/* パスワード変更完了画面 */}
      <Route
        exact
        path="/guest/setting/password/edit/complete"
        render={() => <GuestSettingPasswordEditCompletePage />}
      />
      {/* ユーザー名変更画面 */}
      <Route
        exact
        path="/guest/setting/name/edit"
        render={() => <GuestSettingDisplayNameEditPage />}
      />
      {/* 設定画面 */}
      <Route exact path="/guest/setting" render={() => <GuestSettingPage />} />
    </Switch>
  );
};

export default GuestSettingHooks;
