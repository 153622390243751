/**
 * 加盟店側-申請管理・検索画面
 * メモ：申請管理画面を表示する
 */
import React, { useContext, useState } from "react";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";

import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { DealSearchCondition } from "../../types";
import { IDealListResponse } from "../../../../../api/types";
import { TDealTypeStr } from "../../../../common/components/types";

import { GUEST_COLOR } from "../../../../../constants/common";
import { WORKFLOW_STATUS_OPTIONS_CUSTOMER } from "../../../../../constants/workflowStatus";
import { DEAL_TYPE_STR_TO_NUM } from "../../../../../constants/deal";

import deal from "../../../../../api/deal";
import { AuthContext } from "../../../../../cognito/AuthContext";
import { getErrorMessage } from "../../../../../utils/error";

import Loading from "../../../../common/components/molecules/Loading";
import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import DealSearchConditionForm from "../organisms/DealSearchConditionForm";
import DealSearchResult from "../organisms/DealSearchResult";

const breadCrumbs = [{ name: "申請管理・検索", link: "" }];

const useStyles = makeStyles(() => ({
  form: {
    width: 800,
  },
  searchButton: {
    paddingBottom: 8,
    margin: "0 auto",
    marginTop: 20,
  },
  searchIcon: {
    position: "absolute",
    top: 8,
    left: 50,
  },
  alert: {
    marginTop: "30px",
  },
}));

const DealPage: React.FC = () => {
  const classes = useStyles();
  const searchButtonClasses = buttonStyles({
    width: 180,
    marginTop: 15,
    textLeft: 10,
    backgroundColor: GUEST_COLOR.primary,
  });
  const containerClasses = useContainerStyles();

  const { authHeader } = useContext(AuthContext);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [searchApiErrorMsg, setSearchApiErrorMsg] = useState<string>("");
  const [searchCondition, setSearchCondition] = useState<DealSearchCondition>({
    // 検索条件
    customerName: "", // 加盟店名
    dealId: "", // 申請ID
    customerCode: "", // 加盟店コード
    termiIdentNum: "", // 端末識別番号
    applicationDateFrom: "", // 申請日（From）
    applicationDateTo: "", // 申請日（To）
    dealType: "", // 申請種別
    workflowStatus: "", // ワークフローステータス
    pageNumber: "1", // ページ番号
  });
  const [searchResult, setSearchResult] = useState<IDealListResponse>({});

  const getDeals = (pageNumber: string) => {
    setLoadingOpen(true);
    // 検索処理
    deal
      .searchForCustomer(
        authHeader as string,
        searchCondition.customerName,
        searchCondition.dealId,
        searchCondition.customerCode,
        searchCondition.termiIdentNum,
        searchCondition.applicationDateFrom,
        searchCondition.applicationDateTo,
        DEAL_TYPE_STR_TO_NUM[searchCondition.dealType as TDealTypeStr] || "",
        WORKFLOW_STATUS_OPTIONS_CUSTOMER.get(searchCondition.workflowStatus) ||
          "",
        pageNumber
      )
      .then((res) => {
        // 申請検索の取得結果と検索条件をセット
        setSearchResult(res.data as IDealListResponse);
        setSearchCondition({ ...searchCondition, pageNumber });
        setSearchApiErrorMsg("");
      })
      .catch((error) => {
        setSearchApiErrorMsg(getErrorMessage(error));
        throw error;
      })
      .finally(() => {
        setLoadingOpen(false);
      });
  };

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Dashboard
        title="申請管理"
        color={GUEST_COLOR.base}
        backgroundColor={GUEST_COLOR.primary}
        iconName="deal"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          <>
            {/* 検索フォーム */}
            <form className={classes.form}>
              <DealSearchConditionForm
                searchCondition={searchCondition}
                setSearchCondition={setSearchCondition}
              />
              <Button
                className={clsx(
                  searchButtonClasses.button,
                  classes.searchButton
                )}
                variant="contained"
                onClick={() => getDeals("1")}
                disabled={loadingOpen} // 二重送信対策
              >
                <SearchIcon className={classes.searchIcon} />
                検索
              </Button>
            </form>
            {/* 申請検索結果 */}
            {searchApiErrorMsg === "" && (
              <DealSearchResult
                resultsRows={searchResult}
                conditionState={searchCondition}
                getDeals={getDeals}
              />
            )}
          </>

          {searchApiErrorMsg !== "" && (
            <Alert className={classes.alert} severity="error">
              {searchApiErrorMsg}
            </Alert>
          )}
        </Container>
      </Dashboard>
    </>
  );
};

export default DealPage;
