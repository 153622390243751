/**
 * 加盟店側-パスワード変更フォーム
 * メモ：入力フォームを表示する
 */
import React from "react";

import {
  FormControl,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";

import { formStyles } from "../../../../common/components/styles";

import { PasswordEditItemObject } from "../../types";

import {
  GUEST_COLOR,
  FONT,
  PASSWORD_MAX_LENGTH,
} from "../../../../../constants/common";

import CustomTextField from "../../../../common/components/atoms/CustomTextField";

const useStyles = makeStyles({
  lengthText: {
    color: GUEST_COLOR.base,
    fontFamily: FONT,
    fontSize: "0.7rem",
    fontWeight: 400,
    paddingLeft: 7,
  },
});

interface Props {
  passwordEditState: PasswordEditItemObject;
  setPasswordEditState: React.Dispatch<
    React.SetStateAction<PasswordEditItemObject>
  >;
}

const GuestSettingPasswordEditForm: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const formClasses = formStyles({
    color: GUEST_COLOR.base,
    backgroundColor: GUEST_COLOR.primary,
  });

  const { passwordEditState, setPasswordEditState } = props;

  return (
    <FormControl className={formClasses.form}>
      {/* ブラウザコンソールのCreate Amazing Password Formsログ消し用ダミー */}
      <input id="username" autoComplete="off" style={{ display: "none" }} />
      <TableContainer component={Paper}>
        <Table className={formClasses.table} aria-label="simple table">
          <TableBody>
            {/* 現在のパスワード */}
            <TableRow key="現在のパスワード">
              <TableCell align="left" className={formClasses.th}>
                現在のパスワード
              </TableCell>
              <TableCell align="left">
                <CustomTextField
                  type="password"
                  value={passwordEditState.currentPassword}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPasswordEditState({
                      ...passwordEditState,
                      currentPassword: e.target.value,
                    })
                  }
                  autoComplete="current-password"
                />
              </TableCell>
            </TableRow>
            {/* 新しいパスワード */}
            <TableRow key="新しいパスワード">
              <TableCell align="left" className={formClasses.th}>
                新しいパスワード
                <br />
                <span className={classes.lengthText}>
                  {`(12文字以上最大${PASSWORD_MAX_LENGTH}文字`}
                  <br />
                  最低1文字の大文字・小文字・数字を含む)
                </span>
              </TableCell>
              <TableCell align="left">
                <CustomTextField
                  type="password"
                  value={passwordEditState.newPassword}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPasswordEditState({
                      ...passwordEditState,
                      newPassword: e.target.value,
                    })
                  }
                  autoComplete="new-password"
                />
              </TableCell>
            </TableRow>
            {/* 新しいパスワード(確認) */}
            <TableRow key="新しいパスワード(確認)">
              <TableCell align="left" className={formClasses.th}>
                新しいパスワード(確認)
                <br />
                <span className={classes.lengthText}>
                  {`(12文字以上最大${PASSWORD_MAX_LENGTH}文字`}
                  <br />
                  最低1文字の大文字・小文字・数字を含む)
                </span>
              </TableCell>
              <TableCell align="left">
                <CustomTextField
                  type="password"
                  value={passwordEditState.newPasswordConfirm}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPasswordEditState({
                      ...passwordEditState,
                      newPasswordConfirm: e.target.value,
                    })
                  }
                  autoComplete="new-password"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </FormControl>
  );
};

export default GuestSettingPasswordEditForm;
