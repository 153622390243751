// APIで返ってくる型は業務外の付与情報がついているのでフロントでは型を明確に定義しない。
import axios from "axios";
import {
  IRegisterTerminalTerminateDealInput,
  IRegisterCustomerTerminateDealInput,
  IUpdateTerminalTerminateDealInput,
  IUpdateCustomerTermiDealInput,
  IUpdateChangeOrTerminateDealWorkflowInput,
} from "./types";
import {
  createAPIPromise,
  createLoadingPromise,
  loadingUrls,
} from "./utils/common";

/**
 * formMaster情報取得API
 * 共通なのでdealにあるgetFormMasterを使用する
 * 解約用JSONが欲しい場合は解約フラグにtrueを渡す
 */

/**
 * 端末解約申請登録API
 * @param authHeader: 認証トークン
 * @param dealPostData: 解約申請POSTデータ
 */
const storeTerminal = async (
  authHeader: string,
  dealPostData: IRegisterTerminalTerminateDealInput
  // eslint-disable-next-line
): Promise<any> => {
  const path = `/terminalTermiDeals`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);

  return createAPIPromise(
    axios.post(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      {
        ...dealPostData,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

/**
 * 端末解約申請詳細情報取得API
 * 端末解約申請詳細画面にて表示するためのデータ取得に使用
 * @param authHeader: 認証トークン
 * @param id: 申請ID
 */
const showTerminalTerminateDeal = async (
  authHeader: string,
  id: string
  // eslint-disable-next-line
): Promise<any> => {
  const path = `/terminalTermiDeals/info/${id}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
    }),
    path
  );
};

/**
 * 端末解約申請入力情報取得API
 * @description 端末解約申請編集画面で表示するためのデータを取得
 * @param authHeader: 認証トークン
 * @param id: 申請ID
 */
const getTerminal = async (
  authHeader: string,
  id: string
  // eslint-disable-next-line
): Promise<any> => {
  const path = `/terminalTermiDeals/${id}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);

  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
    }),
    path
  );
};

/**
 * 端末解約申請更新(編集)API
 * @param authHeader 認証トークン
 * @param id 申請id
 * @param terminateObject:解約申請オブジェクト
 * @returns
 */
const updateTerminalTermiDeal = async (
  authHeader: string,
  id: string,
  terminateObject: IUpdateTerminalTerminateDealInput
) => {
  const path = `/terminalTermiDeals/${id}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.put(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      { ...terminateObject },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

/**
 * 端末解約申請キャンセルAPI
 * @param authHeader 認証トークン
 * @param id 申請id
 * @returns
 */
const cancelTerminalTerminateDeal = (authHeader: string, id: string) => {
  const path = `/terminalTermiDeals/${id}/cancel`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.put(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

/**
 * 端末解約申請ワークフローステータス進行API
 * @param authHeader 認証トークン
 * @param id 申請id
 * @param workflowStatusPutData 変更・解約申請ワークフローステータス進行PUTデータ
 * @returns
 */
const progressTerminalTermiDealWorkflow = async (
  authHeader: string,
  id: string,
  workflowStatusPutData: IUpdateChangeOrTerminateDealWorkflowInput
) => {
  const path = `/terminalTermiDeals/workflow/${id}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.put(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      { ...workflowStatusPutData },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

/**
 * 加盟店解約申請登録API
 * @param authHeader: 認証トークン
 * @param dealPostData: 解約申請POSTデータ
 */
const storeCustomer = async (
  authHeader: string,
  dealPostData: IRegisterCustomerTerminateDealInput
  // eslint-disable-next-line
): Promise<any> => {
  const path = `/customerTermiDeals`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);

  return createAPIPromise(
    axios.post(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      {
        ...dealPostData,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

/**
 * 加盟店解約申請詳細情報取得API
 * 加盟店解約申請詳細画面にて表示するためのデータ取得に使用
 * @param authHeader: 認証トークン
 * @param id: 申請ID
 */
const showCustomerTerminateDeal = async (
  authHeader: string,
  id: string
  // eslint-disable-next-line
): Promise<any> => {
  const path = `/customerTermiDeals/info/${id}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
    }),
    path
  );
};

/**
 * 加盟店解約申請入力情報取得API
 * @description 加盟店解約申請編集画面で表示するためのデータを取得
 * @param authHeader: 認証トークン
 * @param id: 申請ID
 */
const getCustomer = async (
  authHeader: string,
  id: string
  // eslint-disable-next-line
): Promise<any> => {
  const path = `/customerTermiDeals/${id}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);

  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
    }),
    path
  );
};

/**
 * 加盟店解約申請更新(編集)API
 * @param authHeader 認証トークン
 * @param id 申請id
 * @param terminateObject:解約申請オブジェクト
 * @returns
 */
const updateCustomerTermiDeal = async (
  authHeader: string,
  id: string,
  terminateObject: IUpdateCustomerTermiDealInput
) => {
  const path = `/customerTermiDeals/${id}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.put(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      { ...terminateObject },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

/**
 * 加盟店解約申請キャンセルAPI
 * @param authHeader 認証トークン
 * @param id 申請id
 * @returns
 */
const cancelCustomerTerminateDeal = (authHeader: string, id: string) => {
  const path = `/customerTermiDeals/${id}/cancel`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.put(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

/**
 * 加盟店解約申請ワークフローステータス進行API
 * @param authHeader 認証トークン
 * @param id 申請id
 * @param workflowStatusPutData 変更・解約申請ワークフローステータス進行PUTデータ
 * @returns
 */
const progressCustomerTermiDealWorkflow = async (
  authHeader: string,
  id: string,
  workflowStatusPutData: IUpdateChangeOrTerminateDealWorkflowInput
) => {
  const path = `/customerTermiDeals/workflow/${id}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.put(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      { ...workflowStatusPutData },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

/**
 * 追加資料リスト取得API
 * 共通なのでdealにあるgetExtraUploadFilesを使用する
 */

/**
 * コメント登録API
 * 共通なのでdealにあるupdateFormCommentを使用する
 */

/**
 * 追加資料ダウンロードAPI
 * 共通なのでfileUtilsにあるgetDownloadFileApiを使用する
 */

/**
 * メッセージ送信API
 * 共通なのでdealにあるsendUserMessageを使用する
 */

/**
 * 申請情報pdf保管API
 * 共通なのでdealにあるstoreDealPdfを使用する
 */

export default {
  storeTerminal,
  showTerminalTerminateDeal,
  getTerminal,
  progressTerminalTermiDealWorkflow,
  updateTerminalTermiDeal,
  cancelTerminalTerminateDeal,
  storeCustomer,
  showCustomerTerminateDeal,
  getCustomer,
  updateCustomerTermiDeal,
  progressCustomerTermiDealWorkflow,
  cancelCustomerTerminateDeal,
};
