/**
 * フォーム関係-JSONのフォーム用のテキストフィールド
 */
import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useContext,
  useRef,
} from "react";

import clsx from "clsx";
import { Button, TextField, makeStyles } from "@material-ui/core/";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";

import { buttonStyles } from "../../../common/components/styles";

import { TFormColumn, TRequiredKey } from "../../../common/components/types";

import { ADMIN_COLOR, FONT, GUEST_COLOR } from "../../../../constants/common";

import postcodeJp from "../../../../api/postcodeJp";
import { AuthContext } from "../../../../cognito/AuthContext";
import formStore from "../../../../store/formStore";
import { separateWithComma } from "../../../../utils/string";
import getPrefectureRoman from "../../../../utils/prefectureRoman";

import HoujinBangoResultPopup from "../organisms/HoujinBangoResultPopup";
import FormRemarks from "./FormRemarks";
import ValidationMsg from "./ValidateMsg";

const useStyles = makeStyles(() => ({
  text: {
    width: "100%",
    backgroundColor: "#f2f7fb",
    "& .MuiInputBase-input": {
      height: 0,
      paddingTop: 22,
    },
    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.09)",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: 0,
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderWidth: 2,
    },
  },
  houjinBango: {
    width: "30%",
  },
  houjinBangoButton: {
    display: "inline",
  },
  searchIcon: {
    position: "absolute",
    top: 8,
    left: 20,
  },
  editIcon: {
    position: "absolute",
    top: 6,
    left: 20,
  },
  url: {
    fontFamily: FONT,
  },
  errorMsg: {
    color: "#f44336",
    fontSize: "0.75rem",
  },
  numberInputForChangeDeal: {
    width: "100%",
  },
}));

// 郵便番号での入力補完対象マップ
const RELATION_MAP = new Map<
  "contractorPostcode" | "customerPostalCode",
  {
    pref: string;
    city: string;
    town: string;
    prefK: string;
    cityK: string;
    townK: string;
    prefE?: string;
  }
>([
  [
    "contractorPostcode",
    {
      pref: "contractorPrefecture",
      city: "contractorCity",
      town: "contractorTown",
      prefK: "contractorPrefectureK",
      cityK: "contractorCityK",
      townK: "contractorTownK",
    },
  ],
  [
    "customerPostalCode",
    {
      pref: "customerPrefecture",
      city: "customerCity",
      town: "customerTown",
      prefK: "customerPrefectureK",
      cityK: "customerCityK",
      townK: "customerTownK",
      prefE: "customerPrefectureE",
    },
  ],
]);

type Props = {
  columnName: TFormColumn;
  requiredKey: TRequiredKey;
  terminalGridId: number | undefined;
  gridId: number | undefined;
  placeholder?: string;
  isChangeDeal?: boolean;
};

const CustomTextField: React.FC<Props> = (props: Props) => {
  const {
    columnName,
    requiredKey,
    terminalGridId,
    gridId,
    placeholder,
    isChangeDeal,
  } = props;

  const { authHeader } = useContext(AuthContext);
  const [row, setRow] = useState(
    formStore.getFormRow(columnName, terminalGridId, gridId)
  );
  const [openHoujinPopup, setOpenHoujinPopup] = useState(false);

  const isHoujinBango = useMemo(
    () => columnName === "corporateNum",
    [columnName]
  );
  const isPostCode = useMemo(
    () =>
      columnName === "contractorPostcode" ||
      columnName === "customerPostalCode",
    [columnName]
  );

  const disabled = useMemo(
    () => !row?.json.updateEnabled[requiredKey] || row.disabled,
    [row, requiredKey]
  );

  const classes = useStyles();
  const buttonClasses = buttonStyles({
    width: 200,
    marginTop: 3,
    marginLeft: 20,
    backgroundColor:
      requiredKey === "dealer" ? ADMIN_COLOR.primary : GUEST_COLOR.primary,
  });

  // カナ自動入力用バインドに用いるフォームに指定するidのための文字列
  const idNumStr = useMemo(
    () =>
      row
        ? `-${row.json.formNameId || 0}-${row.json.formGroupNameId || 0}-${
            row.gridId || 0
          }`
        : "",
    [row]
  );
  const isImeOn = useRef(false);

  useEffect(() => {
    const fn = () => {
      const r = formStore.getFormRow(columnName, terminalGridId, gridId);
      setRow(r && { ...r });
    };
    formStore.addListener(columnName, fn);

    return () => formStore.removeListener(columnName, fn);
  }, [columnName, terminalGridId, gridId]);

  // 郵便番号での住所自動入力
  const autoInputByPostcode = useCallback(() => {
    if (
      !row ||
      !row.content ||
      (columnName !== "contractorPostcode" &&
        columnName !== "customerPostalCode")
    )
      return;

    const relColumns = RELATION_MAP.get(columnName);
    if (!relColumns) return;

    postcodeJp.getAddress(row.content, authHeader || "").then((res) => {
      const updateData: { [key in TFormColumn]?: string } = {};
      // 存在しない郵便番号の場合は関連項目リセット
      if (res.data.result) {
        const { pref, city, town, halfWidthKana } = res.data.result;
        updateData[relColumns.pref as TFormColumn] = pref;
        updateData[relColumns.city as TFormColumn] = city;
        updateData[relColumns.town as TFormColumn] = town;
        updateData[relColumns.prefK as TFormColumn] = halfWidthKana.pref;
        updateData[relColumns.cityK as TFormColumn] = halfWidthKana.city;
        updateData[relColumns.townK as TFormColumn] = halfWidthKana.town;
        if (columnName === "customerPostalCode") {
          updateData[relColumns.prefE as TFormColumn] =
            getPrefectureRoman(pref) || "";
        }
      } else {
        updateData[relColumns.pref as TFormColumn] = "";
        updateData[relColumns.city as TFormColumn] = "";
        updateData[relColumns.town as TFormColumn] = "";
        updateData[relColumns.prefK as TFormColumn] = "";
        updateData[relColumns.cityK as TFormColumn] = "";
        updateData[relColumns.townK as TFormColumn] = "";
        if (columnName === "customerPostalCode") {
          updateData[relColumns.prefE as TFormColumn] = "";
        }
      }
      formStore.updateContents(updateData, requiredKey);
    });
  }, [authHeader, requiredKey, row, columnName]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      formStore.updateContent(
        columnName,
        value,
        requiredKey,
        terminalGridId,
        gridId
      );
    },
    [columnName, requiredKey, terminalGridId, gridId]
  );

  // rowがなければ表示しない
  if (!row) return null;

  return (
    row && (
      <div>
        {row.json.fixedValue !== null ? (
          // 値がなければ単位等も表示しない
          <>{row.content ? row.content : ""}</>
        ) : (
          <>
            {/* テキストフィールド */}
            <TextField
              type="text"
              placeholder={placeholder}
              onChange={handleChange}
              onCompositionStart={() => {
                isImeOn.current = true; // IME 入力中フラグを ON
              }}
              onCompositionEnd={() => {
                isImeOn.current = false; // IME 入力中フラグを OFF
                // eslint-disable-next-line no-unused-expressions
                handleChange; // 入力確定したとき
              }}
              variant="outlined"
              name={row.json.formName}
              className={clsx(
                classes.text,
                isHoujinBango || isPostCode ? classes.houjinBango : "",
                isChangeDeal && classes.numberInputForChangeDeal
              )}
              value={
                row.json.formType === "number"
                  ? separateWithComma(row.content as string)
                  : row.content
              }
              error={row.errors.length > 0}
              disabled={disabled}
              id={
                row.json.kanaAutoInputInfo
                  ? `AutokanaSource${idNumStr}`
                  : undefined
              }
            />
            {/* （法人番号の場合）登録情報を取得ボタン */}
            {isHoujinBango && (
              <Button
                className={clsx(
                  buttonClasses.button,
                  classes.houjinBangoButton
                )}
                variant="contained"
                type="button"
                disabled={
                  disabled ||
                  !isHoujinBango ||
                  row.content === "" ||
                  openHoujinPopup
                }
                onClick={() => setOpenHoujinPopup(true)}
              >
                <SearchIcon className={classes.searchIcon} />
                登録情報を取得
              </Button>
            )}
            {/* （郵便番号の場合）住所情報を更新ボタン */}
            {isPostCode && (
              <Button
                className={clsx(
                  buttonClasses.button,
                  classes.houjinBangoButton
                )}
                variant="contained"
                type="button"
                disabled={disabled || !isPostCode || row.content === ""}
                onMouseDown={() => autoInputByPostcode()}
              >
                <EditIcon className={classes.editIcon} />
                住所情報を更新
              </Button>
            )}
            {/* 項目の説明 */}
            <FormRemarks
              remarks={
                isChangeDeal ? row.json.changeFormRemarks : row.json.formRemarks
              }
            />
            {/* カナ自動入力関連  NOTE: 現在使用されているか、正常に実装されているか、必要なものなのか不明 */}
            {row.json.kanaAutoInputInfo && (
              <>
                <input
                  type="text"
                  id={`AutokanaDummy${idNumStr}`}
                  style={{ display: "none" }}
                />
              </>
            )}
            {/* 項目の説明（URL） */}
            <a
              className={classes.url}
              href={row.json.url}
              target="_blank"
              rel="noopener noreferrer"
              tabIndex={row.json.url ? 0 : -1}
            >
              {row.json.url}
            </a>
            {/* バリデーションエラーメッセージ */}
            <div className={classes.errorMsg}>
              <ValidationMsg errors={row.errors} />
            </div>
            {/* 法人番号検索結果表示ポップアップ */}
            {openHoujinPopup && row.errors.length <= 0 && (
              <HoujinBangoResultPopup
                houjinBango={row.content}
                requiredKey={requiredKey}
                onCancel={() => setOpenHoujinPopup(false)}
                error={row.errors.length > 0}
              />
            )}
          </>
        )}
      </div>
    )
  );
};

CustomTextField.defaultProps = {
  placeholder: "",
};

export default CustomTextField;
