/**
 * 加盟店側-パスワードリセット確認コード送信用ユーザーID入力フォーム
 * メモ：入力フォームを表示する
 */
import React from "react";

import { FormControl } from "@material-ui/core";

import { loginFormStyles } from "../../../../common/components/styles";

import CustomTextField from "../../../../common/components/atoms/CustomTextField";

interface Props {
  username: string;
  setUsername: React.Dispatch<React.SetStateAction<string>>;
}

const GuestSendConfirmForm: React.FC<Props> = (props: Props) => {
  const loginFormClasses = loginFormStyles();

  const { username, setUsername } = props;

  return (
    <>
      <FormControl className={loginFormClasses.form}>
        <CustomTextField
          value={username}
          placeholder="ユーザーIDを入力"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setUsername(e.target.value)
          }
        />
      </FormControl>
    </>
  );
};

export default GuestSendConfirmForm;
