/**
 * フォーム関係-端末情報用のフォーム
 * メモ：propsのtypeによってどのフォームコンポーネントを利用するか決まる
 */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { TRequiredKey } from "../../../common/components/types";

import { ThemeColorProps } from "../../../../constants/common";
import { FORM_CATEGORY } from "../../../../constants/form";

import formStore from "../../../../store/formStore";

import ConfirmForm from "./ConfirmForm";
import FormAccordion from "./FormAccordion";
import NewForm from "./NewForm";
import ShowForm from "./ShowForm";

type Props = {
  requiredKey: TRequiredKey;
  colors: ThemeColorProps;
  type: "new" | "confirm" | "show";
};

const TerminalForm: React.FC<Props> = (props: Props) => {
  const { requiredKey, colors, type } = props;

  const [terminalGridCount, setTerminalGridCount] = useState(
    formStore.getTerminalGridCount()
  );

  useEffect(() => {
    const fn = () => {
      setTerminalGridCount(formStore.getTerminalGridCount());
    };

    formStore.addListener("terminal", fn);

    return () => formStore.removeListener("terminal", fn);
  }, []);

  // typeに応じたフォームコンポーネント取得
  const getForm = useCallback(
    (i: number) => {
      switch (type) {
        case "new":
          return (
            <NewForm
              formCategory={FORM_CATEGORY.TERMINAL}
              requiredKey={requiredKey}
              colors={colors}
              terminalGridId={i}
            />
          );
        case "confirm":
          return (
            <ConfirmForm
              formCategory={FORM_CATEGORY.TERMINAL}
              requiredKey={requiredKey}
              colors={colors}
              terminalGridId={i}
            />
          );
        case "show":
          return (
            <ShowForm
              formCategory={FORM_CATEGORY.TERMINAL}
              requiredKey={requiredKey}
              colors={colors}
              terminalGridId={i}
            />
          );
        default:
          return null;
      }
    },
    [colors, requiredKey, type]
  );

  // 端末数に応じたアコーディオンコンポーネント作成
  const children = useMemo(() => {
    const ret: ReactElement[] = [];
    for (let i = 0; i < terminalGridCount; i += 1) {
      const form = getForm(i);

      if (form) {
        ret.push(
          <FormAccordion
            title="端末情報"
            key={`端末情報_${i}`}
            index={i}
            colors={colors}
            type={type}
          >
            {form}
          </FormAccordion>
        );
      }
    }
    return ret;
  }, [colors, getForm, terminalGridCount, type]);

  return <>{children}</>;
};

export default TerminalForm;
