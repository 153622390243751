/**
 * JTB側-カード会社連携ファイル検索・ダウンロード画面
 * メモ：連携ファイルの検索・ダウンロード画面を表示する
 */
import React, { useContext, useState } from "react";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";

import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { CardFileSearchCondition } from "../../types";
import { ICardFileSearchResponse } from "../../../../../api/types";

import { ADMIN_COLOR } from "../../../../../constants/common";
import {
  ALIGNMENT_DATE_PLACEHOLDER,
  ALIGNMENT_DATE_CHAR_TYPE,
  CARD_COMPANY_SEARCH_MAP,
  DEAL_GROUP_SEARCH_MAP,
  CUSTOMER_GROUP_SEARCH_MAP,
} from "../../../../../constants/cardFile";

import { checkPattern } from "../../../../../form/validation";
import cardCompanyFile from "../../../../../api/cardCompanyFile";
import { AuthContext } from "../../../../../cognito/AuthContext";
import { getErrorMessage } from "../../../../../utils/error";

import Dashboard from "../../../../templates/Dashboard";
import Loading from "../../../../common/components/molecules/Loading";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import CardFileSearchConditionForm from "../organisms/CardFileSearchConditionForm";
import CardFileSearchResult from "../organisms/CardFileSearchResult";

const breadCrumbs = [
  { name: "帳票・データ出力", link: "/dealer/data" },
  { name: "カード会社連携ファイル", link: "/dealer/data/card-file" },
  { name: "ダウンロード", link: "" },
];

const useStyles = makeStyles(() => ({
  form: {
    width: 800,
  },
  searchButton: {
    paddingBottom: 8,
    margin: "0 auto",
    marginTop: 20,
  },
  searchIcon: {
    position: "absolute",
    top: 8,
    left: 50,
  },
  alert: {
    marginTop: "30px",
  },
}));

const DataCardFileDownloadPage: React.FC = () => {
  const containerClasses = useContainerStyles();
  const classes = useStyles();
  const searchButtonClasses = buttonStyles({
    width: 180,
    marginTop: 15,
    textLeft: 10,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const { authHeader } = useContext(AuthContext);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [searchApiErrorMsg, setSearchApiErrorMsg] = useState<string>("");
  const [searchCondition, setSearchCondition] =
    useState<CardFileSearchCondition>({
      cardCompany: "", // カード会社
      alignmentDateFrom: "", // from連携日
      alignmentDateTo: "", // to連携日
      dealGroup: "", // 業務
      customerGroup: "", // 加盟店種別区分
      fileName: "", // ファイル名
      pageNumber: "1", // ページ番号
    });
  const [searchResult, setSearchResult] = useState<ICardFileSearchResponse>({});

  const dateFormatCheck = (string: string) => {
    const errorArray = checkPattern(
      string,
      ALIGNMENT_DATE_CHAR_TYPE,
      ALIGNMENT_DATE_PLACEHOLDER,
      []
    );
    return errorArray[0];
  };

  // 連携ファイル検索&取得
  const getCardFiles = (pageNumber: string) => {
    // 連携日のフォーマットチェック
    const fromError = dateFormatCheck(searchCondition.alignmentDateFrom);
    if (fromError?.message) {
      setSearchApiErrorMsg(`連携日(from)は、${fromError.message}`);
      return;
    }
    const toError = dateFormatCheck(searchCondition.alignmentDateTo);
    if (toError?.message) {
      setSearchApiErrorMsg(`連携日(to)は、${toError.message}`);
      return;
    }

    setLoadingOpen(true);

    // カード会社を名称から番号に変換
    const cardCompany = CARD_COMPANY_SEARCH_MAP[searchCondition.cardCompany]
      ? CARD_COMPANY_SEARCH_MAP[searchCondition.cardCompany]
      : "";
    // 業務を名称から番号に変換
    const dealGroup = DEAL_GROUP_SEARCH_MAP[searchCondition.dealGroup]
      ? DEAL_GROUP_SEARCH_MAP[searchCondition.dealGroup]
      : "";
    // 加盟店種別区分を名称から番号に変換
    const customerGroup = CUSTOMER_GROUP_SEARCH_MAP[
      searchCondition.customerGroup
    ]
      ? CUSTOMER_GROUP_SEARCH_MAP[searchCondition.customerGroup]
      : "";

    // 検索
    cardCompanyFile
      .search(
        pageNumber, // ページ番号、検索時は1固定
        authHeader as string,
        cardCompany,
        dealGroup,
        searchCondition.alignmentDateFrom,
        searchCondition.alignmentDateTo,
        customerGroup,
        searchCondition.fileName
      )
      .then((res) => {
        // 連携ファイルリストの取得結果と検索条件をセット
        setSearchResult(res.data);
        setSearchCondition({ ...searchCondition, pageNumber });
        setSearchApiErrorMsg("");
      })
      .catch((error) => {
        setSearchApiErrorMsg(getErrorMessage(error));
        throw error;
      })
      .finally(() => {
        setLoadingOpen(false);
      });
  };

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Dashboard
        title="帳票・データ出力"
        color={ADMIN_COLOR.base}
        backgroundColor={ADMIN_COLOR.primary}
        iconName="data"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {/* 検索フォーム */}
          <form className={classes.form}>
            <CardFileSearchConditionForm
              searchCondition={searchCondition}
              setSearchCondition={setSearchCondition}
            />
            <Button
              className={clsx(searchButtonClasses.button, classes.searchButton)}
              variant="contained"
              // type="submit"
              onClick={() => getCardFiles("1")}
              disabled={loadingOpen} // 二重送信対策
            >
              <SearchIcon className={classes.searchIcon} />
              検索
            </Button>
          </form>
          {searchApiErrorMsg === "" && (
            <>
              {/* 検索結果 */}
              {searchResult && (
                <CardFileSearchResult
                  resultsRows={searchResult}
                  conditionState={searchCondition}
                  setApiErrorMsg={setSearchApiErrorMsg}
                  setLoadingOpen={setLoadingOpen}
                  loadingOpen={loadingOpen}
                  getCardFiles={getCardFiles}
                />
              )}
            </>
          )}
          {searchApiErrorMsg !== "" && (
            <Alert className={classes.alert} severity="error">
              {searchApiErrorMsg}
            </Alert>
          )}
        </Container>
      </Dashboard>
    </>
  );
};

export default DataCardFileDownloadPage;
