/**
 * 加盟店側-契約者編集確認画面
 * メモ：編集した契約者情報の確認を行う画面
 */
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { withRouter, Link, RouteComponentProps } from "react-router-dom";

import clsx from "clsx";
import { Button, Container, makeStyles } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";

import { buttonStyles } from "../../../../common/components/styles";
import useContainerStyles from "../../../../templates/styles";

import { GUEST_COLOR } from "../../../../../constants/common";
import { FORM_CATEGORY } from "../../../../../constants/form";
import { UPLOAD_FILE_TYPE } from "../../../../../constants/deal";

import { GlobalPopupContext } from "../../../../../App";
import getErrorMessage from "../../../../../utils/error";
import { AuthContext } from "../../../../../cognito/AuthContext";
import contractors from "../../../../../api/contractors";
import formStore from "../../../../../store/formStore";

import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import Dashboard from "../../../../templates/Dashboard";
import TabPanel from "../../../../common/components/atoms/TabPanel";
import ConfirmForm from "../../../../form/components/organisms/ConfirmForm";

const useStyles = makeStyles(() => ({
  returnButton: {
    margin: "0 auto",
    paddingTop: 6,
    paddingBottom: 8,
    paddingLeft: 42,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 24,
    },
  },
  addButton: {
    color: `${GUEST_COLOR.primary}!important`,
    border: `1px solid ${GUEST_COLOR.primary}`,
    backgroundColor: `${GUEST_COLOR.base}!important`,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 24,
    },
    margin: "0 auto",
    marginLeft: 20,
    paddingTop: 6,
    paddingBottom: 8,
    paddingLeft: 0,
    "&:hover": {
      backgroundColor: `${GUEST_COLOR.btnHover}!important`,
    },
  },
  returnIcon: {
    position: "absolute",
    top: 6,
    left: 31,
  },
  addIcon: {
    position: "absolute",
    top: 6,
    left: 17,
  },
  buttonContainer: {
    margin: "0 auto",
    clear: "both",
    height: 100,
    width: 284,
    marginTop: 20,
  },
  gridButtons: {
    float: "left",
  },
}));

const ContractorEditConfirmPage: React.FC<
  RouteComponentProps<{ id: string }>
> = (props: RouteComponentProps<{ id: string }>) => {
  const { history, match } = props;

  const { authHeader } = useContext(AuthContext);
  const { setLoading } = useContext(GlobalPopupContext);
  const [apiErrorMsg, setApiErrorMsg] = useState("");
  const id = useMemo(() => match.params.id, [match.params.id]);

  const breadCrumbs = useMemo(
    () => [
      { name: "契約者管理・検索", link: "/guest/contractor" },
      { name: "詳細", link: `/guest/contractor/${id}` },
      { name: "編集", link: `/guest/contractor/${id}/edit` },
      { name: "確認", link: "" },
    ],
    [id]
  );

  const loading = useRef(false);

  const classes = useStyles();
  const buttonClasses = buttonStyles({
    width: 130,
    marginTop: 20,
    backgroundColor: GUEST_COLOR.primary,
  });
  const containerClasses = useContainerStyles();

  const handleRegister = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      setLoading(true);
      loading.current = true;

      try {
        // ファイルアップロードAPI
        const fileIds = await formStore.uploadFormFiles(
          authHeader || "",
          "customer",
          UPLOAD_FILE_TYPE.CONTRACTOR
        );

        // POST用データを用意
        const formData = formStore.getApiRequestFormData(
          FORM_CATEGORY.CONTRACTOR,
          "customer"
        );
        const contractorId = parseInt(id, 10);
        if (!formData) {
          setApiErrorMsg(
            "入力内容に問題が発生しました。入力画面に戻り確認してください。"
          );
          setLoading(false);
          return;
        }

        // 契約者情報更新API
        await contractors.update(
          authHeader || "",
          contractorId,
          formData,
          fileIds
        );

        setLoading(false);
        loading.current = false;
        history.push(`/guest/contractor/${id}/edit/complete`);
      } catch (err) {
        setApiErrorMsg(getErrorMessage(err));
        setLoading(false);
        loading.current = false;
      }
    },
    [history, setLoading, id, authHeader]
  );

  return (
    <>
      <Dashboard
        title="契約者管理"
        color={GUEST_COLOR.base}
        backgroundColor={GUEST_COLOR.primary}
        iconName="deal"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {apiErrorMsg !== "" && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {apiErrorMsg}
            </Alert>
          )}
          {/* 確認フォーム */}
          <TabPanel value={0} index={0} borderColor={GUEST_COLOR.primary}>
            <ConfirmForm
              formCategory={1}
              requiredKey="customer"
              colors={GUEST_COLOR}
              terminalGridId={0}
            />
          </TabPanel>
          <div className={classes.buttonContainer}>
            {/* 戻るボタン */}
            <Button
              className={clsx(
                buttonClasses.button,
                classes.returnButton,
                classes.gridButtons
              )}
              type="button"
              component={Link}
              to={`/guest/contractor/${match.params.id}/edit`}
              variant="contained"
            >
              <KeyboardReturnIcon className={classes.returnIcon} />
              戻る
            </Button>
            {/* 登録するボタン */}
            <Button
              type="submit"
              onClick={handleRegister}
              className={clsx(
                buttonClasses.button,
                classes.addButton,
                classes.gridButtons
              )}
              variant="contained"
              disabled={loading.current} // 非同期処理が行われている間はボタンを押せないようにする。(二重送信対策)
            >
              <AddIcon className={classes.addIcon} />
              登録する
            </Button>
          </div>
        </Container>
      </Dashboard>
    </>
  );
};

export default withRouter(ContractorEditConfirmPage);
