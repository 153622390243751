/**
 * 加盟店側-変更申請情報確認画面
 * メモ：
 */
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import clsx from "clsx";
import { withRouter, Link, RouteComponentProps } from "react-router-dom";

import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles, Container, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";

import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { GUEST_COLOR } from "../../../../../constants/common";

import { getErrorMessage } from "../../../../../utils/error";
import { GlobalPopupContext } from "../../../../../App";
import formStore from "../../../../../store/formStore";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import ShowItemHeading from "../../../../common/components/atoms/ShowItemHeading";
import useChangeDealInfoByDealType from "../../../../../utils/changeDeal";
import { AuthContext } from "../../../../../cognito/AuthContext";
import changeDeal from "../../../../../api/changeDeal";
import { UPLOAD_FILE_TYPE } from "../../../../../constants/deal";
import ChangeDealConfirmForm from "../../../../form/components/organisms/ChangeDealConfirmForm";

const useStyles = makeStyles(() => ({
  returnButton: {
    margin: "0 auto",
    paddingTop: 8,
    paddingBottom: 10,
    paddingLeft: 42,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 24,
    },
  },
  checkButton: {
    margin: "0 auto",
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 8,
    color: `${GUEST_COLOR.primary}!important`,
    border: `1px solid ${GUEST_COLOR.primary}`,
    backgroundColor: `${GUEST_COLOR.base}!important`,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 16,
    },
    "&:hover": {
      backgroundColor: `${GUEST_COLOR.btnHover}!important`,
    },
  },
  returnIcon: {
    position: "absolute",
    top: 10,
    left: 31,
  },
  addIcon: {
    position: "absolute",
    top: 6,
    left: 17,
  },
  buttonContainer: {
    margin: "0 auto",
    clear: "both",
    height: 100,
    width: 284,
    marginTop: 20,
  },
  gridButtons: {
    float: "left",
  },
  tableContainer: {
    border: `3px solid ${GUEST_COLOR.primary}`,
    maxHeight: 663,
    overflowY: "auto",
    padding: "20px 10px",
  },
}));

const ChangeDealNewConfirmPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const { authHeader } = useContext(AuthContext);
  const { history } = props;
  const { setLoading } = useContext(GlobalPopupContext);
  const [apiErrorMsg, setApiErrorMsg] = React.useState("");

  const loading = useRef(false);
  const [displaySettings] = useState({
    dateReflected: "", // 反映日
    dateToBeReflected: "", // 反映予定日
  });
  const { dealType, dealTypeInfo } = useChangeDealInfoByDealType("customer");
  const breadCrumbs = useMemo(
    () => [
      { name: dealTypeInfo.name, link: dealTypeInfo.url },
      { name: `詳細`, link: `${dealTypeInfo.url}/${dealTypeInfo.detailId}` },
      { name: `変更申請入力`, link: "/guest/deal/change/new" },
      { name: `確認`, link: "" },
    ],
    [dealTypeInfo]
  );
  const fileTypeByDealType = useMemo(() => {
    switch (dealType) {
      case "加盟店変更":
      case "端末変更":
        return UPLOAD_FILE_TYPE.CUSTOMER;
      case "契約者変更":
      default:
        return UPLOAD_FILE_TYPE.CONTRACTOR;
    }
  }, [dealType]);

  const classes = useStyles();
  const moveButtonClasses = buttonStyles({
    width: 130,
    marginTop: 20,
    backgroundColor: GUEST_COLOR.primary,
  });
  const containerClasses = useContainerStyles();

  const handleRegister = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      setLoading(true);
      loading.current = true;

      try {
        // ファイルアップロードAPI
        const fileIds = await formStore.uploadFormFiles(
          authHeader || "",
          "customer",
          fileTypeByDealType
        );

        // POST用データを用意
        const request = formStore.getApiRequestDataForNewChangeDeal("customer");
        if (!request) {
          setApiErrorMsg(
            "入力内容に問題が発生しました。入力画面に戻り確認してください。"
          );
          return;
        }
        // 変更申請登録API
        const ret = await changeDeal.storeChangeDeal(authHeader || "", {
          ...request,
          fileIds,
        });
        setLoading(false);
        loading.current = false;
        history.push(`/guest/deal/change/complete/${ret.data.result.dealId}`);
      } catch (err) {
        setApiErrorMsg(getErrorMessage(err));
        setLoading(false);
        loading.current = false;
      }
    },
    [history, setLoading, authHeader, fileTypeByDealType]
  );

  return (
    <Dashboard
      title={`${dealType}申請`}
      color={GUEST_COLOR.base}
      backgroundColor={GUEST_COLOR.primary}
      iconName="deal"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {apiErrorMsg && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {apiErrorMsg}
          </Alert>
        )}
        <>
          {/* 表示見出し */}
          <ShowItemHeading
            dateReflected={displaySettings.dateReflected}
            dateToBeReflected={displaySettings.dateToBeReflected}
            colors={GUEST_COLOR}
          />
          <form>
            <div className={classes.tableContainer}>
              {/* 詳細表示 */}
              <ChangeDealConfirmForm
                formCategory={dealTypeInfo.formCategory}
                requiredKey="customer"
                colors={GUEST_COLOR}
                terminalGridId={0}
              />
            </div>
            <div className={classes.buttonContainer}>
              <Button
                className={clsx(
                  moveButtonClasses.button,
                  classes.returnButton,
                  classes.gridButtons
                )}
                type="button"
                component={Link}
                to="/guest/deal/change/new"
                variant="contained"
              >
                <KeyboardReturnIcon className={classes.returnIcon} />
                戻る
              </Button>
              <Button
                type="submit"
                onClick={handleRegister}
                className={clsx(moveButtonClasses.button, classes.checkButton)}
                variant="contained"
                disabled={loading.current} // 非同期処理が行われている間はボタンを押せないようにする。(二重送信対策)
              >
                <AddIcon className={classes.addIcon} />
                登録する
              </Button>
            </div>
          </form>
        </>
      </Container>
    </Dashboard>
  );
};

export default withRouter(ChangeDealNewConfirmPage);
