/**
 * JTB側-加盟店管理 検索結果
 * メモ：加盟店管理の検索結果リストを表示する
 */
import React from "react";
import { Link } from "react-router-dom";

import clsx from "clsx";
import {
  Button,
  FormControl,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import {
  buttonStyles,
  formStyles,
  paginationStyles,
} from "../../../../common/components/styles";

import { CustomerSearchCondition } from "../../types";
import { ICustomerListResponse } from "../../../../../api/types";

import {
  ADMIN_COLOR,
  FONT,
  SEARCH_PER_PAGE,
} from "../../../../../constants/common";
import { CUSTOMER_OPERATING_STATUS } from "../../../../../constants/customer";

import SearchCountText from "../../../../common/components/atoms/SearchCountText";

// スタイル設定
const useStyles = makeStyles(() => ({
  cell: {
    fontFamily: FONT,
    padding: 1,
    color: "#000!important",
    border: `1px solid ${ADMIN_COLOR.primary}`,
    width: 200,
    paddingTop: 5,
    paddingBottom: 5,
  },
  thCell: {
    fontFamily: FONT,
    color: ADMIN_COLOR.primary,
    border: `1px solid ${ADMIN_COLOR.primary}!important`,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: ADMIN_COLOR.base,
    width: 200,
    position: "relative",
  },
  messageHeader: {
    fontFamily: FONT,
    textAlign: "center",
    fontSize: "1.1rem",
    marginTop: "40px",
    marginBottom: "10px",
  },
  detail: {
    width: "100px!important",
  },
  detailButton: {
    width: 100,
    padding: "4px 10px",
    margin: "10px 20px",
    backgroundColor: ADMIN_COLOR.primary,
    color: ADMIN_COLOR.base,
    borderRadius: 0,
  },
  grayOut: {
    backgroundColor: "gray",
  },
}));

// 加盟店リスト表示配列
type CustomerListDisplayData = {
  id: number; // マスター加盟店ID
  companyName: string; // 会社名
  customerCode: string; // 加盟店コード
  customerName: string; // 加盟店名
  customerPhoneNum: string; // 加盟店電話番号
  customerType: string; // 加盟店分類
  businessCode: string; // 事業者コード
  customerStartDate: string; // 加盟店分用開始日
  customerTerminationDate: string; // 加盟店解約日
  operatingStatus: string; // 加盟店稼働状況
};

// 加盟店検索結果
interface Props {
  resultsRows: ICustomerListResponse;
  conditionState: CustomerSearchCondition;
  getCustomers: (currentPage: string) => void;
}

const CustomerSearchResult: React.FC<Props> = (props: Props) => {
  const formClasses = formStyles({
    color: ADMIN_COLOR.base,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const buttonClasses = buttonStyles({
    width: 213,
    marginTop: 15,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const paginationClasses = paginationStyles({
    color: ADMIN_COLOR.base,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const classes = useStyles();
  const { resultsRows, conditionState, getCustomers } = props;

  // 表示用カラム名
  const columnNames = [
    "契約者",
    "加盟店コード",
    "加盟店名",
    "加盟店電話番号",
    "加盟店分類",
    "事業者コード",
    "運用開始日",
    "解約予定日",
  ];

  // 表示用データ作成
  const displayDataList: CustomerListDisplayData[] = [];
  if (resultsRows.result) {
    resultsRows.result.forEach((resultData) => {
      // 加盟店分類と事業者コードはオブジェクトの文字列になっているので名称を取り出す
      const customerType =
        resultData.customerType !== ""
          ? JSON.parse(resultData.customerType)
          : "";
      const businessCode =
        resultData.businessCode !== ""
          ? JSON.parse(resultData.businessCode)
          : "";
      const displayData = {
        id: resultData.id,
        companyName: resultData.companyName,
        customerCode: resultData.customerCode,
        customerName: resultData.customerName,
        customerPhoneNum: resultData.customerPhoneNum,
        customerType:
          typeof customerType === "object"
            ? String(Object.values(customerType))
            : customerType,
        businessCode:
          typeof businessCode === "object"
            ? String(Object.values(businessCode))
            : businessCode,
        customerStartDate: resultData.customerStartDate,
        customerTerminationDate: resultData.customerTerminationDate,
        operatingStatus: resultData.operatingStatus,
      };
      displayDataList.push(displayData);
    });
  }

  return (
    <>
      {resultsRows.result !== undefined && resultsRows.totalCount === 0 && (
        <>
          <div className={classes.messageHeader}>
            {` ${"該当する加盟店はありません。"}`}
          </div>
        </>
      )}
      {resultsRows.result !== undefined && resultsRows.totalCount !== 0 && (
        <>
          {/* 検索結果件数 */}
          <SearchCountText count={resultsRows.totalCount as number} />
          {/* 検索結果 */}
          <FormControl className={formClasses.form}>
            <TableContainer>
              <Table className={formClasses.table} aria-label="simple table">
                <TableHead className={formClasses.th}>
                  <TableRow>
                    {/* タイトル */}
                    {columnNames.map((column) => (
                      <TableCell
                        key={column}
                        align="center"
                        className={classes.thCell}
                      >
                        {column}
                      </TableCell>
                    ))}
                    <TableCell
                      align="center"
                      className={clsx(classes.thCell, classes.detail)}
                    >
                      操作
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* 検索結果のリスト(解約済ステータスはグレーアウトする) */}
                  {displayDataList.map((rowData) => {
                    return (
                      <TableRow
                        key={rowData.id}
                        className={
                          rowData.operatingStatus ===
                          CUSTOMER_OPERATING_STATUS.TERMINATED
                            ? classes.grayOut
                            : ""
                        }
                      >
                        {/* 契約者 */}
                        <TableCell align="center" className={classes.cell}>
                          {rowData.companyName || "-"}
                        </TableCell>
                        {/* 加盟店コード */}
                        <TableCell align="center" className={classes.cell}>
                          {rowData.customerCode || "-"}
                        </TableCell>
                        {/* 加盟店名 */}
                        <TableCell align="center" className={classes.cell}>
                          {rowData.customerName || "-"}
                        </TableCell>
                        {/* 加盟店電話番号 */}
                        <TableCell align="center" className={classes.cell}>
                          {rowData.customerPhoneNum || "-"}
                        </TableCell>
                        {/* 加盟店分類 */}
                        <TableCell align="center" className={classes.cell}>
                          {rowData.customerType || "-"}
                        </TableCell>
                        {/* 事業者コード */}
                        <TableCell align="center" className={classes.cell}>
                          {rowData.businessCode || "-"}
                        </TableCell>
                        {/* 加盟店分用開始日 */}
                        <TableCell align="center" className={classes.cell}>
                          {rowData.customerStartDate || "-"}
                        </TableCell>
                        {/* 加盟店解約日 */}
                        <TableCell align="center" className={classes.cell}>
                          {rowData.customerTerminationDate || "-"}
                        </TableCell>
                        {/* 詳細ボタン */}
                        <TableCell
                          align="center"
                          className={clsx(classes.cell, classes.detail)}
                        >
                          <Button
                            className={clsx(
                              buttonClasses.button,
                              classes.detailButton
                            )}
                            variant="contained"
                            component={Link}
                            to={{
                              pathname: `/dealer/customer/${rowData.id}`,
                              state: conditionState,
                            }}
                          >
                            詳細
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {/* ページネーション */}
            <div className={paginationClasses.parent}>
              <Pagination
                count={Math.ceil(
                  (resultsRows.totalCount as number) / SEARCH_PER_PAGE
                )}
                className={clsx(
                  paginationClasses.pagination,
                  paginationClasses.pageCurrent
                )}
                onChange={(e: React.ChangeEvent<unknown>, num) =>
                  getCustomers(String(num))
                }
                page={Number(conditionState.pageNumber)}
              />
            </div>
          </FormControl>
        </>
      )}
    </>
  );
};

export default CustomerSearchResult;
