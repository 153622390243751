/**
 * JTB側-電話番号変更確認コード入力画面
 * メモ：確認コードを入力して自身の電話番号を変更する画面
 */
import React, { useContext, useState } from "react";
import { withRouter, RouteComponentProps, useLocation } from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import EditIcon from "@material-ui/icons/Edit";

import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { UserPhoneNumEditConfirmItemObject } from "../../types";

import { ADMIN_COLOR } from "../../../../../constants/common";

import { AuthContext } from "../../../../../cognito/AuthContext";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import AlertMessage from "../../../../common/components/atoms/AuthAlertMsg";
import Loading from "../../../../common/components/molecules/Loading";
import SettingUserPhoneNumEditConfirmForm from "../organisms/SettingUserPhoneNumEditConfirmForm";

const breadCrumbs = [
  { name: "設定", link: "/dealer/setting" },
  { name: "電話番号変更", link: "/dealer/setting/userPhoneNum/edit" },
  { name: "確認", link: "" },
];

const useStyles = makeStyles(() => ({
  editButton: {
    margin: "0 auto",
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 10,
    paddingLeft: 68,
  },
  editIcon: {
    position: "absolute",
    top: 8,
    left: 30,
  },
  alertInfo: {
    marginBottom: 20,
  },
}));

const SettingUserPhoneNumEditConfirmPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const containerClasses = useContainerStyles();
  const buttonClasses = buttonStyles({
    width: 180,
    marginTop: 20,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const classes = useStyles();

  const { history } = props;
  const { state } = useLocation(); // 電話番号変更画面から値を受け取る

  const [loadingOpen, setLoadingOpen] = useState(false);
  const [changeUserPhoneNumError, setChangeUserPhoneNumError] = useState("");
  const { confirmChangeUserPhoneNum } = useContext(AuthContext);

  const [userPhoneNumEditConfirmState, setUserPhoneNumEditConfirmState] =
    useState<UserPhoneNumEditConfirmItemObject>({
      newUserPhoneNum: state.newUserPhoneNum, // newUserPhoneNum
      code: "",
    });
  const { userPhoneNumEditSkip } = state; // 送られてきた値を設定（userPhoneNumEditSkip）

  const handleToCompletePage = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    setLoadingOpen(true);

    // CognitoAuthProviderの電話番号変更確認メソッドを実行
    const { result, message } = await confirmChangeUserPhoneNum(
      userPhoneNumEditConfirmState
    );

    setLoadingOpen(false);
    if (result) {
      // 成功した場合、ログイン画面に遷移（ログアウトされるため）
      setChangeUserPhoneNumError("");
      history.push("/dealer/login");
      return;
    }
    setChangeUserPhoneNumError(message);
  };

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Dashboard
        title="設定"
        color={ADMIN_COLOR.base}
        backgroundColor={ADMIN_COLOR.primary}
        iconName="setting"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {/* 注意文言 */}
          {!userPhoneNumEditSkip && (
            <Alert severity="info" className={classes.alertInfo}>
              電話番号を確認するためのコードが、入力された電話番号にSMS送信されました。確認コードを入力してください。
            </Alert>
          )}
          {/* エラーメッセージ */}
          {changeUserPhoneNumError && (
            <AlertMessage errorMessage={changeUserPhoneNumError} />
          )}
          {/* 入力フォーム */}
          <SettingUserPhoneNumEditConfirmForm
            userPhoneNumEditConfirmState={userPhoneNumEditConfirmState}
            setUserPhoneNumEditConfirmState={setUserPhoneNumEditConfirmState}
          />
          {/* 変更するボタン */}
          <Button
            type="submit"
            onClick={handleToCompletePage}
            className={clsx(buttonClasses.button, classes.editButton)}
            variant="contained"
            disabled={loadingOpen} // 二重送信対策
          >
            <EditIcon className={classes.editIcon} />
            変更する
          </Button>
        </Container>
      </Dashboard>
    </>
  );
};

export default withRouter(SettingUserPhoneNumEditConfirmPage);
