/**
 * 加盟店側-トップページ
 * メモ：トップページの親コンポーネント
 */
import React from "react";
import { Route, Switch } from "react-router-dom";

import GuestTopPage from "./pages/GuestTopPage";
import CustomerHooks from "../../customer/components/CustomerHooks";
import TerminalHooks from "../../terminal/components/TerminalHooks";
import GuestSettingHooks from "../../setting/components/GuestSettingHooks";
import ContractorHooks from "../../contractor/components/ContractorHooks";
import GuestDataHooks from "../../data/components/GuestDataHooks";
import GuestDealHooks from "../../deal/components/GuestDealHooks";
import GuestTermHooks from "../../term/components/GuestTermHooks";
import GuestDocumentHooks from "../../document/components/GuestDocumentHooks";
import GuestGuidePage from "./pages/GuestGuidePage";

const GuestTopHooks: React.FC = () => {
  return (
    <Switch>
      {/* ダッシュボード */}
      <Route path="/guest" render={() => <GuestTopPage />} exact />
      {/* 契約者管理 */}
      <Route path="/guest/contractor" render={() => <ContractorHooks />} />
      {/* 加盟店管理 */}
      <Route path="/guest/customer" render={() => <CustomerHooks />} />
      {/* 端末管理 */}
      <Route path="/guest/terminal" render={() => <TerminalHooks />} />
      {/* 設定 */}
      <Route path="/guest/setting" render={() => <GuestSettingHooks />} />
      {/* 帳票・データ出力 */}
      <Route path="/guest/data" render={() => <GuestDataHooks />} />
      {/* 申請管理 */}
      <Route path="/guest/deal" render={() => <GuestDealHooks />} />
      {/* 規約確認 */}
      <Route path="/guest/latest-terms" render={() => <GuestTermHooks />} />
      {/* マニュアル確認 */}
      <Route path="/guest/documents" render={() => <GuestDocumentHooks />} />
      {/* 案内板（各種申請） */}
      <Route path="/guest/guide" render={() => <GuestGuidePage />} exact />
    </Switch>
  );
};

export default GuestTopHooks;
