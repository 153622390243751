/**
 * フォーム関係-JSONのフォーム用のセレクトボックス（絞り込み付き）
 */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Select, {
  components,
  DropdownIndicatorProps,
  InputProps,
  MultiValue,
  SingleValue,
} from "react-select";

import clsx from "clsx";
import { FormControl, FormHelperText, makeStyles } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import {
  TFormColumn,
  TPulldownArray,
  TRequiredKey,
} from "../../../common/components/types";

import { FONT } from "../../../../constants/common";

import formStore from "../../../../store/formStore";
import { jsonParseSafety } from "../../../../utils/formData";

import FormRemarks from "./FormRemarks";
import ValidationMsg from "./ValidateMsg";

const useStyles = makeStyles((theme) => ({
  select: {
    "& .MuiFilledInput-root": {
      backgroundColor: "#f2f7fb",
      borderRadius: 4,
      width: 300,
      fontFamily: FONT,
      "&:before": {
        borderBottom: "none",
      },
    },
    "& .MuiFilledInput-root.Mui-focused": {
      borderBottom: "none",
      backgroundColor: "#fff",
      transition: "none",
    },
    "& .MuiFilledInput-underline": {
      "&:after": {
        content: "none",
      },
    },
    "& .MuiFilledInput-root.Mui-error": {
      border: "2px solid #f44336",
    },
    "& .MuiFilledInput-input": {
      padding: "12px 12px 10px",
      borderRadius: 4,
    },
    "& .MuiSelect-select.Mui-disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.09)",
    },
  },
  selectInput: {
    backgroundColor: "#f2f7fb",
    borderRadius: 4,
    width: 300,
    fontFamily: FONT,
    "&:before": {
      borderBottom: "none",
    },
    "& .react-select__control": {
      backgroundColor: "#f2f7fb",
      border: "none",
      boxShadow: "none",
      margin: 0,
      "&:hover": {
        backgroundColor: "#f2f7fb",
        border: "none",
        boxShadow: "none",
      },
    },
    "& .react-select__menu": {
      margin: 0,
    },
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
  remark: {
    color: "#777",
    fontFamily: FONT,
  },
  error: {
    marginLeft: 0,
  },
  errorBorder: {
    border: "2px solid #f44336",
  },
  url: {
    fontFamily: FONT,
  },
}));

type OptionType = {
  value: string;
  label: string;
};

// Selectの▼アイコン
const DropdownIndicator = (props: DropdownIndicatorProps<OptionType, true>) => (
  // eslint-disable-next-line
  <components.DropdownIndicator {...props}>
    <ArrowDropDownIcon style={{ color: "rgba(0,0,0,0.54)" }} />
  </components.DropdownIndicator>
);

const IndicatorSeparator = () => null;

const Input = (props: InputProps<OptionType, true>) => (
  // eslint-disable-next-line
  <components.Input {...props} />
);

const convToSelectOption = (src: TPulldownArray) =>
  ({
    value: Object.keys(src)[0],
    label: Object.values(src)[0],
  } as OptionType);

type Props = {
  columnName: TFormColumn;
  requiredKey: TRequiredKey;
  terminalGridId: number | undefined;
  gridId: number | undefined;
  isChangeDeal?: boolean;
};

const CustomSearchSelect: React.FC<Props> = (props: Props) => {
  const { columnName, requiredKey, terminalGridId, gridId, isChangeDeal } =
    props;

  const [row, setRow] = useState(
    formStore.getFormRow(columnName, terminalGridId, gridId)
  );
  const contentObj = useRef<TPulldownArray | undefined>(
    (row?.content && (jsonParseSafety(row.content) as TPulldownArray)) ||
      undefined
  );
  const select = useRef<OptionType | undefined>(
    contentObj.current ? convToSelectOption(contentObj.current) : undefined
  );
  const [openSelect, setOpenSelect] = React.useState(false);

  const contentForView = useMemo(
    () => jsonParseSafety(row?.content || ""),
    [row]
  );

  const pulldownArray = useMemo(() => {
    const ret: OptionType[] = [];
    row?.json.formTypeOptions.forEach((pulldown) => {
      ret.push(convToSelectOption(pulldown));
    });
    return ret;
  }, [row]);

  const disabled = useMemo(
    () => !row?.json.updateEnabled[requiredKey] || row.disabled,
    [row, requiredKey]
  );

  const classes = useStyles();

  useEffect(() => {
    const fn = () => {
      const r = formStore.getFormRow(columnName, terminalGridId, gridId);
      setRow(r && { ...r });
    };
    formStore.addListener(columnName, fn);

    return () => formStore.removeListener(columnName, fn);
  }, [columnName, terminalGridId, gridId]);

  const handleChange = useCallback(
    (selectedValue: SingleValue<OptionType> | MultiValue<OptionType>) => {
      const sVal = (
        Array.isArray(selectedValue)
          ? (selectedValue as OptionType[])
          : ([selectedValue] as OptionType[])
      ).filter((v) => v.label !== "");

      const value = sVal.map((s) => {
        const ret: { [key: string]: string } = {};
        ret[`${s.value}`] = s.label;
        return ret;
      })[0];

      select.current = sVal.length > 0 ? sVal[0] : undefined;
      formStore.updateContent(
        columnName,
        value,
        requiredKey,
        terminalGridId,
        gridId
      );
    },
    [columnName, gridId, requiredKey, terminalGridId]
  );

  // rowがなければ表示しない
  if (!row) return null;

  return (
    row && (
      <div>
        {row.json.fixedValue !== null ? (
          // 値がなければ単位等も表示しない
          <>
            {contentForView && typeof contentForView === "object"
              ? Object.values(contentForView)[0]
              : contentForView || ""}
          </>
        ) : (
          <FormControl
            variant="filled"
            className={clsx(classes.formControl, classes.select)}
            error={row.errors.length > 0}
          >
            {/* セレクトボックス（絞り込み付き） */}
            <Select
              id="demo-simple-select-outlined"
              value={select.current}
              options={pulldownArray}
              className={clsx(
                classes.selectInput,
                row.errors.length > 0 ? classes.errorBorder : ""
              )}
              classNamePrefix="react-select"
              onChange={(newValue) => handleChange(newValue)}
              isMulti={undefined}
              isSearchable
              isDisabled={disabled}
              components={{ DropdownIndicator, IndicatorSeparator, Input }}
              onMenuOpen={() => setOpenSelect(true)}
              onMenuClose={() => setOpenSelect(false)}
              placeholder={openSelect ? "絞り込み可" : ""}
            />
            {/* 項目の説明 */}
            <FormRemarks
              remarks={
                isChangeDeal ? row.json.changeFormRemarks : row.json.formRemarks
              }
            />
            {/* 項目の説明（URL） */}
            <a
              className={classes.url}
              href={row.json.url}
              target="_blank"
              rel="noopener noreferrer"
              tabIndex={row.json.url ? 0 : -1}
            >
              {row.json.url}
            </a>
            {/* バリデーションエラーメッセージ */}
            {row.errors.length > 0 && (
              <FormHelperText className={classes.error}>
                <ValidationMsg errors={row.errors} />
              </FormHelperText>
            )}
          </FormControl>
        )}
      </div>
    )
  );
};

export default CustomSearchSelect;
