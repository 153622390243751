import { makeStyles } from "@material-ui/core/styles";
import { ADMIN_COLOR, FONT, GUEST_COLOR } from "../../../constants/common";
import {
  ButtonInt,
  BackColorInt,
  ColorBackColorInt,
  BorderColorInt,
  CustomersHeadingColors,
  TRequiredKey,
} from "./types";

export const fontStyles = makeStyles(() => ({
  family: {
    fontFamily: FONT,
  },
}));

export const formStyles = makeStyles(() => ({
  form: {
    width: "100%",
    display: "block!important",
    marginTop: "20px!important",
  },
  formLabel: (props: ColorBackColorInt) => ({
    color: `${props.backgroundColor}!important`,
    display: "block",
    textAlign: "center",
    padding: "10px!important",
    fontFamily: FONT,

    marginBttom: "15px!important",
    fontSize: "1.3rem!important",
    height: 50,
  }),
  tableContainer: {
    marginTop: 9,
  },
  tableSpacing: (props: ColorBackColorInt) => ({
    height: props.height,
  }),
  table: {
    minWidth: 650,
  },
  tr: {
    // Tableの再下行の点線をなくす用（TableRowにセット）
    "&:last-child td": {
      borderBottom: "0px",
    },
  },
  th: (props: ColorBackColorInt) => ({
    backgroundColor: props.backgroundColor,
    color: `${props.color}!important`,
    width: 263,
    fontFamily: FONT,
  }),
  thGroup: (props: ColorBackColorInt) => ({
    width: 50,
    writingMode: "vertical-lr",
    fontFamily: FONT,

    backgroundColor: props.backgroundColor,
    color: `${props.color}!important`,
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  }),
  thNonGroup: (props: ColorBackColorInt) => ({
    backgroundColor: props.backgroundColor,

    fontFamily: FONT,
    color: `${props.color}!important`,
    width: 313,
  }),
}));

export const Current = {
  background: "rgba(0, 0, 0, 0.04)",
  width: "100%",
  display: "inline-block",
};

export const completeStyles = makeStyles(() => ({
  message: {
    margin: "0 auto",
    marginTop: 140,
    textAlign: "center",
    fontFamily: FONT,
    fontWeight: 500,
    fontSize: "1.1rem",
  },
}));

export const buttonStyles = makeStyles(() => ({
  button: (props: ButtonInt) => ({
    margin: "10",
    display: "block",
    marginTop: props.marginTop,
    width: props.width,
    marginLeft: props.marginLeft,
    "& .MuiButton-label": {
      marginLeft: props.textLeft,
    },
    fontFamily: FONT,
    backgroundColor: `${props.backgroundColor}!important`,
    "&.Mui-disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.12)!important",
      color: "rgba(0, 0, 0, 0.26)!important",
    },
    boxShadow: "none",
    color: "#fff!important",
    fontWeight: 600,
    paddingBottom: 5,
    "&:hover": {
      backgroundColor: "rgb(59, 59, 59)!important",
    },
  }),
}));

export const workflowButtonStyles = makeStyles(() => ({
  button: (props: ButtonInt) => ({
    margin: "10",
    display: "block",
    marginTop: props.marginTop,
    width: props.width,
    marginLeft: props.marginLeft,
    "& .MuiButton-label": {
      marginLeft: props.textLeft,
    },
    border: `1px solid ${props.backgroundColor}!important`,
    fontFamily: FONT,
    backgroundColor: "#fff!important",
    boxShadow: "none",
    color: `${props.backgroundColor}!important`,
    fontWeight: 600,
    paddingBottom: 5,
    "&:hover": {
      backgroundColor: `${props.btnHoverColor}!important`,
    },
  }),
}));

export const settingStyles = makeStyles(() => ({
  margin: {
    marginTop: 60,
  },
  buttonSection: {
    minHeight: 60,
  },
  buttonDescription: {
    fontFamily: FONT,
    marginTop: 10,
    textAlign: "center",
  },
}));

export const checkboxStyles = makeStyles(() => ({
  checkbox: (props: BackColorInt) => ({
    "& .MuiCheckbox-root": {
      color: "transparent",
      backgroundColor: "#f2f7fb",
      borderRadius: 0,
      width: 25,
      height: 25,
      margin: 5,
    },
    "& .MuiTypography-body1": {
      fontFamily: FONT,
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: props.backgroundColor,
    },
    "& .MuiCheckbox-colorSecondary": {
      "&:hover": {
        backgroundColor: "rgba(100, 0, 87, 0.04)",
      },
    },
  }),
  errorCheckbox: () => ({
    "& .MuiCheckbox-root": {
      color: "transparent",
      backgroundColor: "#f2f7fb",
      borderRadius: 0,
      width: 25,
      height: 25,
      margin: 5,
    },
    "& .MuiTypography-body1": {
      color: "#f44336",
      fontFamily: FONT,
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#f44336",
    },
    "& .MuiCheckbox-colorSecondary": {
      "&:hover": {
        backgroundColor: "rgba(100, 0, 87, 0.04)",
      },
    },
  }),
}));

export const radioStyles = makeStyles(() => ({
  radio: (props: BackColorInt) => ({
    "& .MuiRadio-root": {
      color: "transparent",
      backgroundColor: "#f2f7fb",
      boxShadow:
        "rgb(0 0 0 / 20%) 0px 1px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px",
      borderRadius: "50%",
      width: 25,
      height: 25,
      margin: 5,
    },
    "& .MuiTypography-body1": {
      fontFamily: FONT,
    },
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: props.backgroundColor,
    },
    "& .MuiIconButton-colorSecondary": {
      "&:hover": {
        backgroundColor: "rgba(100, 0, 87, 0.04)",
      },
    },
    "& .PrivateSwitchBase-root-32": {
      padding: 6,
    },
  }),
  errorRadio: () => ({
    "& .MuiRadio-root": {
      color: "transparent",
      backgroundColor: "#f2f7fb",
      boxShadow:
        "rgb(0 0 0 / 20%) 0px 1px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px",
      borderRadius: "50%",
      width: 25,
      height: 25,
      margin: 5,
    },
    "& .MuiTypography-body1": {
      color: "#f44336",
      fontFamily: FONT,
    },
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: "#f44336",
    },
    "& .MuiIconButton-colorSecondary": {
      "&:hover": {
        backgroundColor: "rgba(100, 0, 87, 0.04)",
      },
    },
    "& .PrivateSwitchBase-root-32": {
      padding: 6,
    },
  }),
}));

export const paginationStyles = makeStyles(() => ({
  pagination: {
    display: "inline-block",
    marginTop: 6,
    fontFamily: FONT,
    "& .MuiPaginationItem-page": {
      height: 21,
      minWidth: 20,
      borderRadius: 0,
    },
  },
  pageCurrent: (props: ColorBackColorInt) => ({
    "& .MuiPaginationItem-page.Mui-selected": {
      color: props.color,
      backgroundColor: props.backgroundColor,
      fontFamily: FONT,
    },
    "& .MuiPaginationItem-page": {
      fontFamily: FONT,
    },
  }),
  parent: {
    textAlign: "center",
  },
}));

export const loginStyles = makeStyles(() => ({
  container: {
    marginTop: 150,
  },
  innerForm: {
    width: 400,
    margin: "0 auto",
  },
  complete: {
    width: 400,
    margin: "0 auto",
  },
  paper: {
    margin: "0 auto",
    padding: 10,
    paddingBottom: 30,
    maxWidth: 500,
  },
  button: (props: ColorBackColorInt) => ({
    backgroundColor: props.backgroundColor,
    color: props.color,
    marginLeft: 0,
    "&:hover": {
      backgroundColor: props.backgroundColor,
      color: props.color,
    },
  }),
  alert: {
    width: 399,
    margin: "0 auto",
    marginTop: 25,
    fontFamily: FONT,
  },
  environmentLink: {
    fontSize: "0.9rem",
    fontFamily: FONT,

    width: 510,
    marginTop: 5,
    margin: "0 auto",
  },
}));

export const loginFormStyles = makeStyles(() => ({
  inputLabel: {
    top: "-47px!important",
    color: "#000!important",
    fontFamily: FONT,
    fontWeight: 500,
  },
  form: {
    marginTop: "16px!important",
    display: "block!important",
  },
  link: {
    textDecoration: "none!important",
    fontFamily: FONT,
    fontWight: 900,
    fontSize: "0.9rem",
    color: "rgb(90, 88, 88)",
    display: "inline-block",
  },
}));

export const imageStyle = makeStyles(() => ({
  thumbnail: {
    width: 300,
    height: 300,
    objectFit: "scale-down",
  },
}));

export const accordionStyle = makeStyles(() => ({
  accordion: (props: BorderColorInt) => ({
    backgroundColor: "#fff",
    boxShadow: "none!important",
    border: `3px solid ${props.borderColor}!important`,
    padding: "0px!important",
    marginTop: "16px!important",
    zIndex: 1,
  }),
  accordionSummary: (props: BorderColorInt) => ({
    borderBottom: `3px solid ${props.borderColor}!important`,
    margin: "0px!important",
    marginBottom: "-3px!important",
    height: "47px!important",
    minHeight: "48px!important",
    "& .Mui-expanded": {
      margin: "0px!important",
    },
    "& .MuiIconButton-edgeEnd": {
      marginRight: "0px!important",
    },
    "& .MuiAccordionSummary-content": {
      margin: "0px!important",
    },
  }),
  accordionDetail: {
    display: "block!important",
    paddingTop: "16px!important",
  },
  expandIcon: (props: BorderColorInt) => ({
    color: props.borderColor,
    fontSize: "1.6em!important",
  }),
}));

export const innerAccordionStyle = makeStyles(() => ({
  accordion: {
    boxShadow: "none",
    marginTop: 16,
    "& .Mui-expanded": {
      minHeight: "54px!important",
      paddingTop: 0,
    },
    "&:before": {
      backgroundColor: "#fff!important",
    },
  },
  accordionSummary: (props: CustomersHeadingColors) => ({
    backgroundColor: props.backgroundColor,
    color: props.color,
    width: "100%",
    border: `3px solid ${props.borderColor}`,
    height: 54,
    paddingBottom: 5,
    marginTop: 10,
    marginBottom: 15,
    "& .MuiAccordionSummary-content": {
      height: "54px!important",
    },
  }),
  accordionDetail: {
    display: "block!important",
  },
  h3: (props: CustomersHeadingColors) => ({
    textIndent: "1em!important",
    fontSize: "1.2em!important",
    paddingTop: 18,
    fontFamily: FONT,
    fontWeight: "bold",
    "&::before": {
      position: "absolute",
      top: 3,
      left: 3,
      width: 5,
      height: 42,
      content: '""',
      opacity: "1",
      backgroundColor: props.color,
    },
  }),
  expandIcon: (props: CustomersHeadingColors) => ({
    color: props.color,
    fontSize: "1.6em!important",
    marginTop: 6,
  }),
  accordionWrap: {
    position: "relative",
  },
}));

// アコーディオン階層の色取得関数
type Colors = {
  color: string;
  backgroundColor: string;
  bandColor: string;
  borderColor: string;
};
export const getClusterAccordionColor: (
  index: number,
  bgColor: string
) => Colors = (index: number, bgColor: string) => {
  switch (index) {
    case 0:
      return {
        color: "#fff",
        backgroundColor: bgColor,
        bandColor: "#fff",
        borderColor: "#fff",
      };
    case 1:
      return {
        color: bgColor,
        backgroundColor: "#fff",
        bandColor: bgColor,
        borderColor: bgColor,
      };
    case 2:
      return {
        color: "rgb(90, 88, 88)",
        backgroundColor: "#fff",
        bandColor: "rgb(90, 88, 88)",
        borderColor: "rgb(90, 88, 88)",
      };
    default:
      return {
        color: "#fff",
        backgroundColor: bgColor,
        bandColor: "#fff",
        borderColor: "#fff",
      };
  }
};

/**
 * requiredKeyによってADMIN_COLORかGUEST_COLORを取得
 */
export const colorByRequiredKey = (requiredKey: TRequiredKey) =>
  requiredKey === "dealer" ? ADMIN_COLOR : GUEST_COLOR;
