import axios from "axios";
import {
  createAPIPromise,
  createLoadingPromise,
  loadingUrls,
} from "./utils/common";

/**
 * フォーム事前定義JSON取得API
 * @param authHeader: 認証トークン
 * @param jsonType: 取得対象Json
 * @param version: 取得対象バージョン
 */
const getPresetFormJson: (
  authHeader: string,
  jsonType: string,
  version: string
  // eslint-disable-next-line
) => Promise<any> = async (
  authHeader: string,
  jsonType: string,
  version: string
) => {
  const path = `/presetFormJson/${jsonType}/${version}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    path
  );
};

export default { getPresetFormJson };
