/**
 * 加盟店側-ユーザー追加フォームの会社選択モーダルの会社検索フォーム
 * メモ：モーダル内で検索フォームを表示する
 */
import React from "react";

import clsx from "clsx";
import {
  FormControl,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import { formStyles, buttonStyles } from "../../../../common/components/styles";

import { ContractorSearchCondition } from "../../types";

import { FONT, GUEST_COLOR } from "../../../../../constants/common";

import CustomTextField from "../../../../common/components/atoms/CustomTextField";
import PatternMatchChip from "../../../../common/components/atoms/PatternMatchChip";

const useStyles = makeStyles(() => ({
  formControl: {
    clear: "both",
    height: 180,
  },
  container: {
    width: 650,
    float: "left",
    overflow: "hidden",
  },
  table: {
    width: 650,
    minWidth: 650,
  },
  td: {
    padding: 1,
    backgroundColor: "#fff",
    color: "#000!important",
    borderTop: "1px solid #d2d5d8",
    borderBottom: "1px solid #d2d5d8",
    borderRight: "1px solid #d2d5d8",
    width: 400,
  },
  th: {
    padding: 0,
    fontFamily: FONT,
    backgroundColor: "#fff",
    color: "#000!important",
    width: 250,
    border: "1px solid #d2d5d8",
    position: "relative",
    paddingLeft: 20,
  },
  searchIcon: {
    position: "absolute",
    top: 55,
    left: 15,
  },
  searchButton: {
    paddingBottom: 8,
    margin: "0 auto",
    float: "right",
    height: 132,
  },
}));

// 検索条件フォーム設定
const contractorSearchFormData = [
  {
    name: "会社名",
    formData: "companyName",
    matchType: "部分一致",
  },
  {
    name: "所属加盟店コード",
    formData: "customerCode",
    matchType: "前方一致",
  },
  {
    name: "加盟店名",
    formData: "customerName",
    matchType: "部分一致",
  },
  {
    name: "加盟店電話番号",
    formData: "customerPhoneNum",
    matchType: "完全一致",
  },
];

// 会社検索モーダルフォームのインターフェース
interface Props {
  searchCondition: ContractorSearchCondition;
  setSearchCondition: (value: ContractorSearchCondition) => void;
  getContractors: (arg: string) => void;
}

// フォーム表示処理
const ContractorSearchConditionForm: React.FC<Props> = (props: Props) => {
  const formClasses = formStyles({
    color: GUEST_COLOR.base,
    backgroundColor: GUEST_COLOR.primary,
  });
  const classes = useStyles();
  const searchButtonClasses = buttonStyles({
    width: 120,
    marginTop: 15,
    textLeft: 10,
    backgroundColor: GUEST_COLOR.primary,
  });

  const { searchCondition, setSearchCondition, getContractors } = props;

  return (
    <>
      <FormControl className={clsx(formClasses.form, classes.formControl)}>
        <TableContainer className={classes.container}>
          <Table
            className={clsx(formClasses.table, classes.table)}
            aria-label="simple table"
          >
            <TableBody>
              {contractorSearchFormData.map((formItem) => (
                <TableRow key={formItem.name}>
                  {/* フォームタイトル */}
                  <TableCell
                    align="left"
                    className={clsx(formClasses.th, classes.th)}
                  >
                    {formItem.name}
                    <PatternMatchChip
                      left={140}
                      top="24%"
                      text={formItem.matchType}
                    />
                  </TableCell>
                  {/* フォーム表示 */}
                  <TableCell align="left" className={classes.td}>
                    <CustomTextField
                      id={formItem.formData}
                      value={
                        searchCondition[
                          formItem.formData as keyof ContractorSearchCondition
                        ]
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSearchCondition({
                          ...searchCondition,
                          [formItem.formData]: e.target.value,
                        })
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* 検索するボタン */}
        <Button
          className={clsx(searchButtonClasses.button, classes.searchButton)}
          variant="contained"
          onClick={() => getContractors("1")}
        >
          <SearchIcon className={classes.searchIcon} />
          検索する
        </Button>
      </FormControl>
    </>
  );
};

export default ContractorSearchConditionForm;
