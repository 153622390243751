/**
 * エクセル関連の設定値
 */

/**
 * インポートを許可する拡張子（xls,xlsmはマクロが含まれる可能性があるため許可しない）
 */
export const ACCEPT_EXTENSIONS = ["xlsx"];

/**
 * Excel判定する拡張子
 */
export const EXCEL_EXTENSIONS = [...ACCEPT_EXTENSIONS, "xls", "xlsm"];
