/**
 * JTB側-契約者登録確認画面
 * メモ：入力した契約者情報の確認を行う画面
 */
import React, { useCallback, useContext, useRef, useState } from "react";
import { withRouter, Link, RouteComponentProps } from "react-router-dom";

import clsx from "clsx";
import { Button, Container, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import { Alert, AlertTitle } from "@material-ui/lab";

import { buttonStyles } from "../../../../common/components/styles";
import useContainerStyles from "../../../../templates/styles";

import { ADMIN_COLOR } from "../../../../../constants/common";
import { FORM_CATEGORY } from "../../../../../constants/form";
import { UPLOAD_FILE_TYPE } from "../../../../../constants/deal";

import { GlobalPopupContext } from "../../../../../App";
import getErrorMessage from "../../../../../utils/error";
import contractors from "../../../../../api/contractors";
import { AuthContext } from "../../../../../cognito/AuthContext";
import formStore from "../../../../../store/formStore";

import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import Dashboard from "../../../../templates/Dashboard";
import ConfirmForm from "../../../../form/components/organisms/ConfirmForm";
import TabPanel from "../../../../common/components/atoms/TabPanel";

const useStyles = makeStyles(() => ({
  returnButton: {
    margin: "0 auto",
    paddingTop: 6,
    paddingBottom: 8,
    paddingLeft: 42,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 24,
    },
  },
  addButton: {
    color: `${ADMIN_COLOR.primary}!important`,
    border: `1px solid ${ADMIN_COLOR.primary}`,
    backgroundColor: `${ADMIN_COLOR.base}!important`,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 24,
    },
    margin: "0 auto",
    marginLeft: 20,
    paddingTop: 6,
    paddingBottom: 8,
    paddingLeft: 0,
    "&:hover": {
      backgroundColor: `${ADMIN_COLOR.btnHover}!important`,
    },
  },
  returnIcon: {
    position: "absolute",
    top: 6,
    left: 31,
  },
  addIcon: {
    position: "absolute",
    top: 6,
    left: 17,
  },
  buttonContainer: {
    margin: "0 auto",
    clear: "both",
    height: 100,
    width: 284,
    marginTop: 20,
  },
  gridButtons: {
    float: "left",
  },
}));

const breadCrumbs = [
  { name: "契約者管理・検索", link: "/dealer/contractor" },
  { name: "登録", link: "/dealer/contractor/new" },
  { name: "確認", link: "" },
];

const ContractorNewConfirmPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const { history } = props;

  const { authHeader } = useContext(AuthContext);
  const { setLoading } = useContext(GlobalPopupContext);
  const [apiErrorMsg, setApiErrorMsg] = useState("");

  const loading = useRef(false);

  const classes = useStyles();
  const buttonClasses = buttonStyles({
    width: 130,
    marginTop: 20,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const containerClasses = useContainerStyles();

  const handleRegister = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      setLoading(true);
      loading.current = true;

      try {
        // ファイルアップロードAPI
        const fileIds = await formStore.uploadFormFiles(
          authHeader || "",
          "dealer",
          UPLOAD_FILE_TYPE.CONTRACTOR
        );

        // POST用データを用意
        const formData = formStore.getApiRequestFormData(
          FORM_CATEGORY.CONTRACTOR,
          "dealer"
        );
        if (!formData) {
          setApiErrorMsg(
            "入力内容に問題が発生しました。入力画面に戻り確認してください。"
          );
          setLoading(false);
          return;
        }

        // 契約者登録API
        await contractors.register(authHeader || "", formData, fileIds);

        setLoading(false);
        loading.current = false;
        history.push("/dealer/contractor/complete");
      } catch (err) {
        setApiErrorMsg(getErrorMessage(err));
        setLoading(false);
        loading.current = false;
      }
    },
    [authHeader, history, setLoading]
  );

  return (
    <>
      <Dashboard
        title="契約者管理"
        color={ADMIN_COLOR.base}
        backgroundColor={ADMIN_COLOR.primary}
        iconName="deal"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {apiErrorMsg !== "" && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {apiErrorMsg}
            </Alert>
          )}
          {/* 確認フォーム */}
          <TabPanel value={0} index={0} borderColor={ADMIN_COLOR.primary}>
            <ConfirmForm
              formCategory={1}
              requiredKey="dealer"
              colors={ADMIN_COLOR}
              terminalGridId={0}
            />
          </TabPanel>
          <div className={classes.buttonContainer}>
            {/* 戻るボタン */}
            <Button
              className={clsx(
                buttonClasses.button,
                classes.returnButton,
                classes.gridButtons
              )}
              type="button"
              component={Link}
              to="/dealer/contractor/new"
              variant="contained"
            >
              <KeyboardReturnIcon className={classes.returnIcon} />
              戻る
            </Button>
            {/* 登録するボタン */}
            <Button
              type="submit"
              onClick={handleRegister}
              className={clsx(
                buttonClasses.button,
                classes.addButton,
                classes.gridButtons
              )}
              variant="contained"
              disabled={loading.current} // 非同期処理が行われている間はボタンを押せないようにする。(二重送信対策)
            >
              <AddIcon className={classes.addIcon} />
              登録する
            </Button>
          </div>
        </Container>
      </Dashboard>
    </>
  );
};

export default withRouter(ContractorNewConfirmPage);
