/**
 * JTB側-電話番号変更確認コード入力フォーム
 * メモ：入力フォームを表示する
 */
import React from "react";

import {
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";

import { formStyles } from "../../../../common/components/styles";

import { UserPhoneNumEditConfirmItemObject } from "../../types";

import { ADMIN_COLOR } from "../../../../../constants/common";

import CustomTextField from "../../../../common/components/atoms/CustomTextField";

interface Props {
  userPhoneNumEditConfirmState: UserPhoneNumEditConfirmItemObject;
  setUserPhoneNumEditConfirmState: React.Dispatch<
    React.SetStateAction<UserPhoneNumEditConfirmItemObject>
  >;
}

const SettingUserPhoneNumEditConfirmForm: React.FC<Props> = (props: Props) => {
  const formClasses = formStyles({
    color: ADMIN_COLOR.base,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const { userPhoneNumEditConfirmState, setUserPhoneNumEditConfirmState } =
    props;

  return (
    <FormControl className={formClasses.form}>
      <TableContainer component={Paper}>
        <Table className={formClasses.table} aria-label="simple table">
          <TableBody>
            {/* 確認コード */}
            <TableRow key="確認コード">
              <TableCell align="center" className={formClasses.th}>
                確認コード
              </TableCell>
              <TableCell align="left">
                <CustomTextField
                  value={userPhoneNumEditConfirmState.code}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUserPhoneNumEditConfirmState({
                      ...userPhoneNumEditConfirmState,
                      code: e.target.value,
                    })
                  }
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </FormControl>
  );
};

export default SettingUserPhoneNumEditConfirmForm;
