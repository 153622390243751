/**
 * JTB側-申請管理 検索フォーム
 */
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ja from "date-fns/locale/ja";
import moment from "moment";
import clsx from "clsx";
import {
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";

import { formStyles } from "../../../../common/components/styles";

import { DealSearchCondition } from "../../types";

import { DEAL_TYPE_STR_TO_NUM } from "../../../../../constants/deal";
import { WORKFLOW_STATUS_OPTIONS_JTB } from "../../../../../constants/workflowStatus";
import { ADMIN_COLOR, FONT } from "../../../../../constants/common";

import CustomTextField from "../../../../common/components/atoms/CustomTextField";
import CustomSelect from "../../../../common/components/molecules/CustomSelect";
import PatternMatchChip from "../../../../common/components/atoms/PatternMatchChip";

registerLocale("ja", ja);

const useStyles = makeStyles(() => ({
  datepicker: {
    padding: "12px",
    width: "100%",
    backgroundColor: "#f2f7fb",
    fontSize: "1rem",
    borderWidth: 0,
    outlineWidth: 1,
  },
  label: {
    width: 800,
    textAlign: "left",
    padding: "0!important",
    height: 36,
    marginTop: 20,
  },
  table: {
    width: 800,
    minWidth: 800,
  },
  td: {
    padding: 1,
    backgroundColor: "#fff",
    color: "#000!important",
    borderTop: "1px solid #d2d5d8",
    borderBottom: "1px solid #d2d5d8",
    borderRight: "1px solid #d2d5d8",
    width: 400,
  },
  th: {
    padding: 0,
    fontFamily: FONT,
    backgroundColor: "#fff",
    color: "#000!important",
    width: 200,
    border: "1px solid #d2d5d8",
    position: "relative",
    paddingLeft: 20,
  },
  formControl: {
    marginTop: "0px!important",
  },
}));

// 検索条件フォーム設定
const dealSearchConditionMap = [
  {
    name: "申請番号",
    formData: "dealId",
    matchType: "前方一致",
  },
  {
    name: "法人番号",
    formData: "corporateNum",
    matchType: "完全一致",
  },
  {
    name: "加盟店コード",
    formData: "customerCode",
    matchType: "前方一致",
  },
  {
    name: "会社名",
    formData: "companyName",
    matchType: "部分一致",
  },
  {
    name: "加盟店名",
    formData: "customerName",
    matchType: "部分一致",
  },
  {
    name: "端末識別番号",
    formData: "termiIdentNum",
    matchType: "前方一致",
  },
  {
    name: "申請日（From）",
    formData: "applicationDateFrom",
    matchType: "範囲一致",
  },
  {
    name: "申請日（To）",
    formData: "applicationDateTo",
    matchType: "範囲一致",
  },
  {
    name: "申請種別",
    formData: "dealType",
    matchType: "完全一致",
  },
  {
    name: "ステータス",
    formData: "workflowStatus",
    matchType: "完全一致",
  },
];

// 検索条件フォームのインターフェース
interface Props {
  searchCondition: DealSearchCondition;
  setSearchCondition: React.Dispatch<React.SetStateAction<DealSearchCondition>>;
}

const DealSearchConditionForm: React.FC<Props> = (props: Props) => {
  const formClasses = formStyles({
    color: ADMIN_COLOR.base,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const classes = useStyles();

  const { searchCondition, setSearchCondition } = props;

  // 申請種別のプルダウン選択肢作成
  const dealTypeList = Object.keys(DEAL_TYPE_STR_TO_NUM);
  dealTypeList.unshift("指定なし");

  // ステータスのプルダウン選択肢作成
  const workflowList = Array.from(WORKFLOW_STATUS_OPTIONS_JTB.keys());
  workflowList.unshift("指定なし");

  return (
    <>
      <Typography className={clsx(formClasses.formLabel, classes.label)}>
        申請検索
      </Typography>
      <FormControl className={clsx(formClasses.form, classes.formControl)}>
        <TableContainer>
          <Table
            className={clsx(formClasses.table, classes.table)}
            aria-label="simple table"
          >
            <TableBody>
              {dealSearchConditionMap.map((formItem) => (
                <TableRow key={formItem.name}>
                  <TableCell
                    align="left"
                    className={clsx(formClasses.th, classes.th)}
                  >
                    {formItem.name}
                    <PatternMatchChip
                      left={153}
                      top="24%"
                      text={formItem.matchType}
                    />
                  </TableCell>
                  <TableCell align="left" className={classes.td}>
                    {/* 申請種別 */}
                    {formItem.formData === "dealType" && (
                      <CustomSelect
                        value={searchCondition[formItem.formData] || "指定なし"}
                        pulldownArray={dealTypeList}
                        width="100%"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setSearchCondition({
                            ...searchCondition,
                            [formItem.formData]: e.target.value,
                          })
                        }
                      />
                    )}
                    {/* ワークフローステータス */}
                    {formItem.formData === "workflowStatus" && (
                      <CustomSelect
                        value={searchCondition[formItem.formData] || "指定なし"}
                        pulldownArray={workflowList}
                        width="100%"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setSearchCondition({
                            ...searchCondition,
                            [formItem.formData]: e.target.value,
                          })
                        }
                      />
                    )}
                    {/* 申請日の場合 */}
                    {(formItem.formData === "applicationDateFrom" ||
                      formItem.formData === "applicationDateTo") && (
                      <DatePicker
                        selected={
                          searchCondition[
                            formItem.formData as keyof DealSearchCondition
                          ]
                            ? new Date(
                                searchCondition[
                                  formItem.formData as keyof DealSearchCondition
                                ]
                              )
                            : null
                        }
                        onChange={(date: Date | null) =>
                          setSearchCondition({
                            ...searchCondition,
                            [formItem.formData]: date
                              ? moment(date).format("YYYY-MM-DD")
                              : "",
                          })
                        }
                        dateFormat="yyyy-MM-dd"
                        locale="ja"
                        className={classes.datepicker}
                        placeholderText="YYYY-MM-DD"
                      />
                    )}
                    {/* その他の項目 */}
                    {formItem.formData !== "dealType" &&
                      formItem.formData !== "workflowStatus" &&
                      formItem.formData !== "applicationDateFrom" &&
                      formItem.formData !== "applicationDateTo" && (
                        <CustomTextField
                          value={
                            searchCondition[
                              formItem.formData as keyof DealSearchCondition
                            ]
                          }
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setSearchCondition({
                              ...searchCondition,
                              [formItem.formData]: e.target.value,
                            })
                          }
                        />
                      )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </FormControl>
    </>
  );
};

export default DealSearchConditionForm;
