/**
 * 加盟店側-ログイン
 * メモ：ログイン系画面の親コンポーネント
 */
import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { AuthContext } from "../../../../cognito/AuthContext";

import GuestLoginPage from "./pages/GuestLoginPage";
import GuestSendConfirmCdPage from "./pages/GuestSendConfirmCdPage";
import GuestPasswordResetPage from "./pages/GuestPasswordResetPage";
import GuestSendUserPage from "./pages/GuestSendUserPage";
import GuestSendUserCompletePage from "./pages/GuestSendUserCompletePage";
import GuestRequireNewPasswordPage from "./pages/GuestRequireNewPasswordPage";

const GuestLoginHooks: React.FC = () => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Switch>
      {/* パスワードリセット確認コード送信画面 */}
      <Route
        path="/guest/login/confirm-cd"
        render={() => <GuestSendConfirmCdPage />}
        exact
      />
      {/* パスワードリセット画面 */}
      <Route
        path="/guest/login/reset"
        render={() => <GuestPasswordResetPage />}
        exact
      />
      {/* ユーザーID確認画面 */}
      <Route
        path="/guest/login/send-user"
        render={() => <GuestSendUserPage />}
        exact
      />
      {/* ユーザーID送信完了画面 */}
      <Route
        path="/guest/login/send-user/complete"
        render={() => <GuestSendUserCompletePage />}
        exact
      />
      {/* 新パスワード入力画面 */}
      <Route
        path="/guest/login/require-new-password"
        render={() => <GuestRequireNewPasswordPage />}
        exact
      />
      <Route
        path="/guest/login"
        render={() => {
          if (isAuthenticated) {
            return <Redirect to="/guest" />;
          }
          // ログイン画面
          return <GuestLoginPage />;
        }}
        exact
      />
    </Switch>
  );
};

export default GuestLoginHooks;
