import { TFormColumn, TFormRowData } from "../../views/common/components/types";
import { TFixedNumberProcessResult } from "./types";
import { isJtbUser } from "../../utils/auth";
import formStore from "../../store/formStore";

/**
 * 法人区分項目のカラム名
 */
const COMPANY_FORM_COLUMNS: TFormColumn[] = [
  "companyNameNonLP",
  "companyNameKNonLP",
  "corporateNum",
  "legalPersonality",
  "legalPersonalityPos",
];

/**
 * 個人区分項目のカラム名
 */
const PERSONAL_FORM_COLUMNS: TFormColumn[] = [
  "contractorIdentityVerificationDocumentsFront",
  "contractorIdentityVerificationDocumentsBack",
];

/**
 * 申込者区分による表示制御
 * @param data: TFormRowData
 * @returns TFixedNumberProcessResult
 */
export const displayFormByApplicantType: (
  data: TFormRowData
) => TFixedNumberProcessResult = (data: TFormRowData) => {
  // 申込者区分項目取得
  const applicationType = data.applicantType[0];
  // 法人を選択か
  const isCompany =
    !!applicationType &&
    applicationType.content === JSON.stringify({ "1": "法人" });
  // 個人を選択か
  const isPersonal =
    !!applicationType &&
    applicationType.content === JSON.stringify({ "2": "個人" });

  // 法人区分項目をピックアップ
  const companyForms = COMPANY_FORM_COLUMNS.map((c) => data[c][0]);
  // 個人区分項目をピックアップ
  const personalForms = PERSONAL_FORM_COLUMNS.map((c) => data[c][0]);

  // 法人区分項目の表示/非表示制御
  companyForms.forEach((f) => {
    const form = f;
    form.isHide = !isCompany;
    // 法人区分変わったら入力値リセット
    form.content = "";
    if (!isCompany) {
      // 法人選んでなければ固定で下記を入力
      // 法人格は「なし」固定 TODO：緊急なので応急処置
      if (form.column === "legalPersonality") {
        form.content = '{"なし":"なし"}';
      }
      // 法人格位置は「なし」固定 TODO：緊急なので応急処置
      if (form.column === "legalPersonalityPos") {
        form.content = '{"3":"なし"}';
      }
    }
  });

  // 個人区分項目の表示/非表示、必須制御
  personalForms.forEach((f) => {
    const form = f;
    form.isHide = !isPersonal;
    form.isRequired = isPersonal && !isJtbUser();
    // 申込者区分変わったら入力値リセット
    form.content = "";
    if (form.json.formType === "file") {
      // formTypeがfileの場合はアップロード用のファイル情報もリセット
      formStore.removeNewFile(form.column);
    }
  });

  // 更新したものを返却
  return companyForms.concat(personalForms).map((f) => ({
    column: f.column,
    content: f.content,
    isHide: f.isHide,
    isRequired: f.isRequired,
  }));
};

export default displayFormByApplicantType;
