import axios from "axios";
import { guestUserCreateParameters, guestUserUpdateParameters } from "./types";
import {
  createAPIPromise,
  createAPIPromiseWithoutAuth,
  createLoadingPromise,
  createParamStr,
  loadingUrls,
} from "./utils/common";

// ユーザー再作成
const recreate: (
  authHeader: string,
  contractorId: number,
  dealId: number,
  email: string
  // eslint-disable-next-line
) => Promise<any> = async (
  authHeader: string,
  contractorId: number,
  dealId: number,
  email: string
) => {
  const path = `/customerUserAccounts/${contractorId}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.put(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      { dealId, email },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

/**
 * メールアドレス変更API
 * @param authHeader: 認証トークン
 * @param accessToken: cognitoのアクセストークン
 * @param email: 変更後のメールアドレス
 * @param code: cognitoの確認コード
 * */
const updateCognitoUserEmail: (
  authHeader: string,
  accessToken: string,
  email: string,
  code: string
  // eslint-disable-next-line
) => Promise<any> = async (
  authHeader: string,
  accessToken: string,
  email: string,
  code: string
) => {
  const path = `/email`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromiseWithoutAuth(
    axios.put(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      { accessToken, email, code },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

/**
 * 電話番号変更API
 * @param authHeader: 認証トークン
 * @param accessToken: cognitoのアクセストークン
 * @param phoneNumber: 変更後の電話番号
 * @param code: cognitoの確認コード
 * */
const updateCognitoUserPhoneNum: (
  authHeader: string,
  accessToken: string,
  phoneNumber: string,
  code: string
  // eslint-disable-next-line
) => Promise<any> = async (
  authHeader: string,
  accessToken: string,
  phoneNumber: string,
  code: string
) => {
  const path = `/dealerusers/phonenumber`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromiseWithoutAuth(
    axios.put(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      { accessToken, phoneNumber, code },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

/**
 * ユーザーリスト送付API
 * @param email: メールアドレス
 * @param tid: 端末識別番号
 * */
const sendUsers = async (
  email: string,
  tid: string
  // eslint-disable-next-line
): Promise<any> => {
  // 優先度が低くバックエンド側の開発が行われていない
  // TODO: 必要になったらAPI繋ぎ込み&MOCKを削除
  // MOCK
  return {
    statusCode: 200,
    message: "success",
    result: true,
  };

  const path = `/userAccounts/email/${email}/tid/${tid}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromiseWithoutAuth(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      headers: {
        "Content-Type": "application/json",
      },
      data: {},
    }),
    path
  );
};

/**
 * ユーザー名変更API
 * @param authHeader: 認証トークン
 * @param name: 変更後のユーザー名
 * @param accessToken: cognitoのアクセストークン
 * */
const updateDisplayName = async (
  authHeader: string,
  name: string,
  accessToken: string
  // eslint-disable-next-line
): Promise<any> => {
  const path = `/userName`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.put(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      { name, accessToken },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

interface UserSearchParameters {
  name?: string;
  userName?: string;
  email?: string;
  userType?: string;
  pageNumber: string;
}

/**
 * 自社ユーザー検索・一覧API
 * @param pageNumber: ページ番号
 * @param authHeader: 認証トークン
 * @param name: ユーザー名
 * @param userName: ユーザーID
 * @param email: メールアドレス
 * @param userType: ユーザー種別
 * @returns 自社ユーザーの検索結果リスト
 * */
const searchUsers = async (
  pageNumber: string,
  authHeader: string,
  name: string,
  userName: string,
  email: string,
  userType: string
  // eslint-disable-next-line
): Promise<any> => {
  const params: UserSearchParameters = { pageNumber };
  params.name = name === "" ? undefined : name;
  params.userName = userName === "" ? undefined : userName;
  params.email = email === "" ? undefined : email;
  params.userType = userType === "" ? undefined : userType;

  const path = `/dealerusers`;
  const paramStr = createParamStr(params);
  const loadingKey = `${path}?${paramStr}`;
  if (loadingUrls.includes(loadingKey)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(loadingKey);
  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    loadingKey
  );
};

// 加盟店ユーザー検索&一覧取得APIのインターフェース
interface CustomerUserSearchParameters {
  contractorName?: string;
  customerName?: string;
  name?: string;
  userName?: string;
  email?: string;
  userType?: string;
  pageNumber: string;
}

/**
 * 加盟店ユーザー検索&一覧取得API
 * @param pageNumber: ページ番号
 * @param authHeader: 認証トークン
 * @param contractorName: 契約者会社名
 * @param customerName: 加盟店名
 * @param name: ユーザー名
 * @param userName: ユーザーID
 * @param email: メールアドレス
 * @param userType: ユーザー種別
 * @returns 加盟店ユーザーの検索結果リスト
 * */
const searchCustomerUsers = async (
  pageNumber: string,
  authHeader: string,
  contractorName: string,
  customerName: string,
  name: string,
  userName: string,
  email: string,
  userType: string
  // eslint-disable-next-line
): Promise<any> => {
  const params: CustomerUserSearchParameters = { pageNumber };
  params.contractorName = contractorName === "" ? undefined : contractorName;
  params.customerName = customerName === "" ? undefined : customerName;
  params.name = name === "" ? undefined : name;
  params.userName = userName === "" ? undefined : userName;
  params.email = email === "" ? undefined : email;
  params.userType = userType === "" ? undefined : userType;

  const path = `/customerusers`;
  const paramStr = createParamStr(params);
  const loadingKey = `${path}?${paramStr}`;
  if (loadingUrls.includes(loadingKey)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(loadingKey);
  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    loadingKey
  );
};

/**
 * 加盟店ユーザー作成API
 * @param authHeader 認証トークン
 * @param contractorId 契約者ID
 * @param userType ユーザー種別
 * @param email メールアドレス
 * @param name 名前
 * @param masterCustomerId 加盟店ID
 */
const guestUserCreate = async (
  authHeader: string,
  contractorId: string,
  userType: string,
  email: string,
  name: string,
  masterCustomerId?: string
  // eslint-disable-next-line
): Promise<any> => {
  const params: guestUserCreateParameters = {
    userType,
    email,
    name,
  };
  // 0は使わないと思うが、空と設定を判別するためあえて文字列で受け取って判定
  params.contractorId = contractorId === "" ? undefined : Number(contractorId);
  params.masterCustomerId =
    masterCustomerId === "" ? undefined : Number(masterCustomerId);

  const path = `/customerusers`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.post(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      { ...params },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

/**
 * 自社ユーザー追加API
 * @param authHeader: 認証トークン
 * @param userType: ユーザー種別
 * @param email: メールアドレス
 * @param name: ユーザー名
 * @param phoneNumber: 電話番号
 * @param memo: メモ欄
 * @returns
 * */
const createUser: (
  authHeader: string,
  userType: string,
  email: string,
  name: string,
  phoneNumber: string,
  memo: string
  // eslint-disable-next-line
) => Promise<any> = async (
  authHeader: string,
  userType: string,
  email: string,
  name: string,
  phoneNumber: string,
  memo: string
) => {
  const path = `/dealerusers`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.post(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      { userType, email, name, phoneNumber, memo },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

/**
 * 加盟店ユーザー編集API
 * @param authHeader 認証トークン
 * @param id ユーザーのID
 * @param name ユーザー名
 * @param masterCustomerId 所属加盟店ID
 * @param releaseLock アカウントロックを解除するか
 * @param isLock アカウントをロックするか（ロックはJTBユーザーのみ使用可能）
 */
const guestUserUpdate = async (
  authHeader: string,
  id: number,
  name: string,
  masterCustomerId: string,
  releaseLock: boolean,
  isLock: boolean
  // eslint-disable-next-line
): Promise<any> => {
  const params: guestUserUpdateParameters = {
    name,
  };
  params.masterCustomerId =
    masterCustomerId === "" ? undefined : Number(masterCustomerId);
  params.releaseLock = releaseLock === false ? undefined : releaseLock;
  params.isLocked = isLock === false ? undefined : isLock;

  const path = `/customerusers/${id}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.put(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      { ...params },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

/**
 * 自社ユーザー編集API
 * @param authHeader: 認証トークン
 * @param id: ID
 * @param name: ユーザー名
 * @param userType: ユーザー種別
 * @param memo: メモ欄
 * @param releaseLock: ロック解除する/しない
 * @returns
 * */
const updateUser: (
  authHeader: string,
  id: string,
  name: string,
  userType: string,
  memo: string,
  releaseLock: boolean
  // eslint-disable-next-line
) => Promise<any> = async (
  authHeader: string,
  id: string,
  name: string,
  userType: string,
  memo: string,
  releaseLock: boolean
) => {
  const path = `/dealerusers/${id}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.put(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      {
        name,
        userType,
        memo,
        releaseLock,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

// ユーザー個別情報取得API
const getUserInfo = async (
  userName: string,
  authHeader: string
  // eslint-disable-next-line
): Promise<any> => {
  const path = `/userAccounts/${userName}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    path
  );
};

// 加盟店ユーザー削除API
const deleteCustomerUsers = async (
  ids: number[],
  authHeader: string
  // eslint-disable-next-line
): Promise<any> => {
  const path = `/customerusers`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.delete(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: { ids },
    }),
    path
  );
};

// 自社ユーザー削除API
const deleteUsers = async (
  ids: number[],
  authHeader: string
  // eslint-disable-next-line
): Promise<any> => {
  const path = `/dealerusers`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.delete(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: { ids },
    }),
    path
  );
};

// 最終ログイン日時更新API
// eslint-disable-next-line
const updateLastLoginDate = async (authHeader: string): Promise<any> => {
  const path = `/userAccounts/lastLoginDate`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.put(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

export default {
  recreate,
  updateCognitoUserEmail,
  updateCognitoUserPhoneNum,
  sendUsers,
  updateDisplayName,
  searchUsers,
  searchCustomerUsers,
  guestUserCreate,
  createUser,
  guestUserUpdate,
  updateUser,
  getUserInfo,
  deleteCustomerUsers,
  deleteUsers,
  updateLastLoginDate,
};
