/**
 * JTB側-metabase情報確認画面
 * メモ：Metabaseのquestionを埋め込んで表示する
 * クレジット決済機能情報、電子マネー・QR決済機能情報
 */
import React, { useState, useEffect, useContext } from "react";
import {
  Link,
  RouteComponentProps,
  withRouter,
  useLocation,
} from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";

import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { ADMIN_COLOR } from "../../../../../constants/common";
import { METABASE_TYPE } from "../../../../../constants/metabase";

import { AuthContext } from "../../../../../cognito/AuthContext";
import metabase from "../../../../../api/metabase";
import getErrorMessage from "../../../../../utils/error";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import Loading from "../../../../common/components/molecules/Loading";

const useStyles = makeStyles(() => ({
  iframe: {
    border: `2px solid ${ADMIN_COLOR.primary}`,
    width: "100%",
    height: "70vh",
  },
  returnButton: {
    margin: "0 auto",
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 89,
  },
  returnIcon: {
    position: "absolute",
    top: 10,
    left: 31,
  },
}));

// 画面タイプ別の設定
type PageTypeNum = 1 | 2;
const pageType = {
  1: {
    name: "クレジット決済機能情報",
    type: METABASE_TYPE.CREDIT_SETTLEMENT_DATA,
  },
  2: {
    name: "電子マネー・QR決済機能情報",
    type: METABASE_TYPE.E_MONEY_AND_CODE_PAYMENT_DATA,
  },
};

const TerminalInfoPage: React.FC<RouteComponentProps<{ id: string }>> = (
  props: RouteComponentProps<{ id: string }>
) => {
  const classes = useStyles();
  const containerClasses = useContainerStyles();
  const buttonClasses = buttonStyles({
    width: 180,
    marginTop: 20,
    marginLeft: 135,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const { match } = props;
  const { state } = useLocation(); // 画面タイプと端末識別番号を受け取る

  const pageName = pageType[state.pageType as PageTypeNum].name;
  const metabaseType = pageType[state.pageType as PageTypeNum].type;
  const termiIdentNum = state.termiIdentNum as string;

  const { authHeader } = useContext(AuthContext);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState("");
  const [iframeUrl, setIframeUrl] = useState("");

  // 初期表示時にAPIを実行してMetabaseのURLを取得する。
  useEffect(() => {
    const getUrl = async () => {
      setLoadingOpen(true);
      await metabase
        .getUrl(authHeader as string, metabaseType, termiIdentNum)
        .then((res) => {
          // URLをセット
          setIframeUrl(res.data.result.url);
          setApiErrorMsg("");
        })
        .catch((error) => {
          setApiErrorMsg(getErrorMessage(error));
          setLoadingOpen(false);
          throw error;
        });
    };

    getUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // iframeのコンテンツ読み込みが終わったらロード解除する
  const FrameLoad = () => {
    setLoadingOpen(false);
  };

  const breadCrumbs = [
    { name: "端末管理・検索", link: "/dealer/terminal" },
    { name: "詳細", link: `/dealer/terminal/${match.params.id}` },
    { name: pageName, link: "" },
  ];

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Dashboard
        title="端末管理"
        color={ADMIN_COLOR.base}
        backgroundColor={ADMIN_COLOR.primary}
        iconName="terminal"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {apiErrorMsg !== "" && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {apiErrorMsg}
            </Alert>
          )}
          {/* iframe埋め込み */}
          {iframeUrl !== "" && (
            <>
              ※Metabaseの表示まで少しお待ちください。
              <iframe
                className={classes.iframe}
                src={iframeUrl}
                onLoad={FrameLoad}
                title={pageName}
              />
            </>
          )}
          {/* 戻るボタン */}
          <Button
            className={clsx(buttonClasses.button, classes.returnButton)}
            type="button"
            component={Link}
            to={`/dealer/terminal/${match.params.id}`}
            variant="contained"
          >
            <KeyboardReturnIcon className={classes.returnIcon} />
            戻る
          </Button>
        </Container>
      </Dashboard>
    </>
  );
};

export default withRouter(TerminalInfoPage);
