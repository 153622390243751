/**
 * JTB側-マスタ管理metabase情報確認画面
 */
import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter, useLocation } from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";

import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { ADMIN_COLOR } from "../../../../../constants/common";

import { AuthContext } from "../../../../../cognito/AuthContext";
import metabase from "../../../../../api/metabase";
import getErrorMessage from "../../../../../utils/error";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import Loading from "../../../../common/components/molecules/Loading";

const useStyles = makeStyles(() => ({
  iframe: {
    border: `2px solid ${ADMIN_COLOR.primary}`,
    width: "100%",
    height: "70vh",
  },
  returnButton: {
    margin: "0 auto",
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 89,
  },
  returnIcon: {
    position: "absolute",
    top: 10,
    left: 31,
  },
}));

const MasterInfoPage: React.FC = () => {
  const classes = useStyles();
  const containerClasses = useContainerStyles();
  const buttonClasses = buttonStyles({
    width: 180,
    marginTop: 20,
    marginLeft: 135,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const { state } = useLocation(); // Metabaseタイプと画面名を受け取る

  const pageName = state.name;
  const metabaseType = state.type;

  const { authHeader } = useContext(AuthContext);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState("");
  const [iframeUrl, setIframeUrl] = useState("");

  // 初期表示時にAPIを実行してMetabaseのURLを取得する。
  useEffect(() => {
    const getUrl = async () => {
      setLoadingOpen(true);
      await metabase
        .getUrl(authHeader as string, metabaseType, "")
        .then((res) => {
          // URLをセット
          setIframeUrl(res.data.result.url);
          setApiErrorMsg("");
        })
        .catch((error) => {
          setApiErrorMsg(getErrorMessage(error));
          setLoadingOpen(false);
          throw error;
        });
    };
    getUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // iframeのコンテンツ読み込みが終わったらロード解除する
  const FrameLoad = () => {
    setLoadingOpen(false);
  };

  const breadCrumbs = [
    { name: "マスタ管理", link: "/dealer/master" },
    { name: pageName, link: "" },
  ];

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Dashboard
        title="マスタ管理"
        color={ADMIN_COLOR.base}
        backgroundColor={ADMIN_COLOR.primary}
        iconName="master"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {apiErrorMsg !== "" && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {apiErrorMsg}
            </Alert>
          )}
          {/* iframe埋め込み */}
          {iframeUrl !== "" && (
            <>
              ※Metabaseの表示まで少しお待ちください。
              <iframe
                className={classes.iframe}
                src={iframeUrl}
                onLoad={FrameLoad}
                title={pageName}
              />
            </>
          )}
          {/* 戻るボタン */}
          <Button
            className={clsx(buttonClasses.button, classes.returnButton)}
            type="button"
            component={Link}
            to="/dealer/master"
            variant="contained"
          >
            <KeyboardReturnIcon className={classes.returnIcon} />
            戻る
          </Button>
        </Container>
      </Dashboard>
    </>
  );
};

export default withRouter(MasterInfoPage);
