import { TDealTypeNum, TDealTypeStr } from "../views/common/components/types";

/**
 * 申請種別
 */
export const DEAL_TYPE = {
  /** 端末新規 */
  NEW_TERMINAL: "10",
  /** 端末増設 */
  ADD_TERMINAL: "20",
  /** 契約者変更 */
  CHANGE_CONTRACTOR: "30",
  /** 加盟店変更 */
  CHANGE_CUSTOMER: "40",
  /** 端末変更 */
  CHANGE_TERMINAL: "50",
  /** 端末解約 */
  TERMINATE_TERMINAL: "90",
  /** 加盟店解約 */
  TERMINATE_CUSTOMER: "100",
} as const;

/**
 * 申請種別を番号から名称に変換
 */
export const DEAL_TYPE_NUM_TO_STR: { [key in TDealTypeNum]: TDealTypeStr } = {
  [DEAL_TYPE.NEW_TERMINAL]: "端末新規",
  [DEAL_TYPE.ADD_TERMINAL]: "端末増設",
  [DEAL_TYPE.CHANGE_CONTRACTOR]: "契約者変更",
  [DEAL_TYPE.CHANGE_CUSTOMER]: "加盟店変更",
  [DEAL_TYPE.CHANGE_TERMINAL]: "端末変更",
  [DEAL_TYPE.TERMINATE_TERMINAL]: "端末解約",
  [DEAL_TYPE.TERMINATE_CUSTOMER]: "加盟店解約",
} as const;

/**
 * 申請種別を名称から番号に変換
 */
export const DEAL_TYPE_STR_TO_NUM: { [key in TDealTypeStr]: TDealTypeNum } = {
  端末新規: DEAL_TYPE.NEW_TERMINAL,
  端末増設: DEAL_TYPE.ADD_TERMINAL,
  契約者変更: DEAL_TYPE.CHANGE_CONTRACTOR,
  加盟店変更: DEAL_TYPE.CHANGE_CUSTOMER,
  端末変更: DEAL_TYPE.CHANGE_TERMINAL,
  端末解約: DEAL_TYPE.TERMINATE_TERMINAL,
  加盟店解約: DEAL_TYPE.TERMINATE_CUSTOMER,
} as const;

/**
 * JTBユーザー用：種別毎の申請詳細画面URL（最後に申請id付けて使用する）
 */
export const DEAL_SHOW_PAGE_URL_FOR_DEALER: { [key in TDealTypeNum]: string } =
  {
    [DEAL_TYPE.NEW_TERMINAL]: "/dealer/deal/",
    [DEAL_TYPE.ADD_TERMINAL]: "/dealer/deal/",
    [DEAL_TYPE.CHANGE_CONTRACTOR]: "/dealer/deal/change/",
    [DEAL_TYPE.CHANGE_CUSTOMER]: "/dealer/deal/change/",
    [DEAL_TYPE.CHANGE_TERMINAL]: "/dealer/deal/change/",
    [DEAL_TYPE.TERMINATE_TERMINAL]: "/dealer/deal/termination/",
    [DEAL_TYPE.TERMINATE_CUSTOMER]: "/dealer/deal/termination/",
  } as const;

/**
 * 加盟店ユーザー用：種別毎の申請詳細画面URL（最後に申請id付けて使用する）
 */
export const DEAL_SHOW_PAGE_URL_FOR_GUEST: { [key in TDealTypeNum]: string } = {
  [DEAL_TYPE.NEW_TERMINAL]: "/guest/deal/",
  [DEAL_TYPE.ADD_TERMINAL]: "/guest/deal/",
  [DEAL_TYPE.CHANGE_CONTRACTOR]: "/guest/deal/change/",
  [DEAL_TYPE.CHANGE_CUSTOMER]: "/guest/deal/change/",
  [DEAL_TYPE.CHANGE_TERMINAL]: "/guest/deal/change/",
  [DEAL_TYPE.TERMINATE_TERMINAL]: "/guest/deal/termination/",
  [DEAL_TYPE.TERMINATE_CUSTOMER]: "/guest/deal/termination/",
} as const;

/**
 * 画像ファイルで許可される拡張子
 */
export const IMAGE_ACCEPT_TYPE = [
  "image/jpeg" as string,
  "image/png" as string,
] as const;

/**
 * .pdf
 */
export const PDF_TYPE = "application/pdf";

/**
 * .xlsx
 */
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

/**
 * ファイルアップロードで許可される拡張子
 */
export const UPLOAD_ACCEPT_TYPE = [PDF_TYPE] as const;

/**
 * 追加資料アップロードで許可される拡張子
 */
export const ADDITIONAL_UPLOAD_ACCEPT_TYPE = [PDF_TYPE, EXCEL_TYPE] as const;

/**
 * ファイルアップロードAPIで使用するファイル種別
 */
export const UPLOAD_FILE_TYPE = {
  /** 通常画面アップロード */
  FORM: "1",
  /** 追加資料 */
  ADDITIONAL: "2",
  /** 契約者書類 */
  CONTRACTOR: "3",
  /** 加盟店書類 */
  CUSTOMER: "4",
} as const;

// 住所の町名、番地、建物名
export const ADDRESS_SPLIT_INDEX = {
  TOWN_NAME: 0,
  BUILDING: 1,
  ADDRESS_NUMBER: 2,
} as const;
