/**
 * 加盟店側-マニュアル確認
 * メモ：マニュアル系画面の親コンポーネント
 */
import React from "react";
import { Route, Switch } from "react-router-dom";

import GuestDocumentListPage from "./pages/GuestDocumentListPage";
import GuestDocumentPdfPage from "./pages/GuestDocumentPdfPage";

const GuestDocumentHooks: React.FC = () => {
  return (
    <>
      <Switch>
        {/* マニュアル一覧 */}
        <Route
          path="/guest/documents"
          render={() => <GuestDocumentListPage />}
          exact
        />
        {/* マニュアル詳細 */}
        <Route
          path="/guest/documents/pdf"
          render={() => <GuestDocumentPdfPage />}
          exact
        />
      </Switch>
    </>
  );
};

export default GuestDocumentHooks;
