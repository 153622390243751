import { convRawCodeToSmallCode } from "../form/fixedNumberProcess/industryClass";
import {
  TContractorFormColumn,
  TContractorFormData,
  TContractorShowData,
  TContractorShowColumnData,
  TCustomerFormColumn,
  TCustomerFormData,
  TCustomerShowData,
  TCustomerShowColumnData,
  TFormColumn,
  TFormData,
  TFormGroupData,
  TJsonRowObject,
  TTerminalFormColumn,
  TTerminalFormData,
  TTerminalShowData,
  TTerminalShowColumnData,
  TExternalDBData,
  TFormShowData,
  TFormRowData,
  TColumnToCategory,
  TFormCategory,
  TFormObject,
  formTypeOptions,
} from "../views/common/components/types";
import { cloneObject } from "./object";

/**
 * FixedValue取得
 * @param row: TJsonRowObject
 * @returns: string
 */
export const getFixedValue = (row: TJsonRowObject) =>
  row.fixedValue && typeof row.fixedValue === "object"
    ? JSON.stringify(row.fixedValue) // Object -> Json文字列化
    : row.fixedValue?.toString() || "";

/**
 * DefaultValue取得
 * @param row: TJsonRowObject
 * @returns: string
 */
export const getDefaultValue = (row: TJsonRowObject) =>
  row.defaultValue && typeof row.defaultValue === "object"
    ? JSON.stringify(row.defaultValue) // Object -> Json文字列化
    : row.defaultValue?.toString() || "";

/**
 * JSON.parseの安全化
 * @param str: パース対象のJson文字列
 * @returns
 */
export const jsonParseSafety = (str: string) => {
  if (typeof str !== "string") return str;
  try {
    return JSON.parse(str);
  } catch (err) {
    return str;
  }
};

/**
 * ObjectのValue（string）取得
 * @param obj
 * @returns
 */
// eslint-disable-next-line
export const getObjectValue = (obj: any) => {
  if (typeof obj !== "object") return [""];
  return Object.values<string>(obj);
};

/**
 * Json To FormData
 * @param json: formMasterJsonのデータ
 * @returns: { formData, formGroupData, columnToCategory }
 */
export const json2FormData = (json: TJsonRowObject[]) => {
  const formData: Partial<TFormData> = {};
  const formGroupData: TFormGroupData[] = [];
  const columnToCategory: Partial<TColumnToCategory> = {};
  // columnNameが入ってない項目は除外
  const jsonData = json
    .filter((j) => !!j.columnName)
    .sort((a, b) => a.formNumber - b.formNumber); // formNumberでソート
  jsonData.forEach((row) => {
    const columnName: TFormColumn = row.columnName as TFormColumn;
    const category: TFormCategory = row.formCategory;

    if (!formData.formVersion) {
      formData.formVersion = row.formVersion.toString();
    }

    // すでに追加済みか
    const isExist = !!formData[category];

    // formInfoオブジェクト
    const formInfo = (formData[category] || [
      {
        formCategory: category,
        form: {} as TFormRowData,
      },
    ])[0];

    // fixedValue,defaultValue対応
    const fixedValue = getFixedValue(row) || getDefaultValue(row);
    // formRowDate設定
    formInfo.form[columnName] = [
      {
        column: columnName,
        content: fixedValue,
        json: row,
        errors: [],
      },
    ];

    // 未追加の場合は追加
    if (!isExist) {
      formData[category] = [formInfo];
    }

    // カラム名 - カテゴリー情報追加
    columnToCategory[columnName] = category;

    // formGroupDataの対応
    const find = formGroupData.find(
      (d) => d.formGroupNameId === row.formGroupNameId
    );
    if (find) {
      find.forms.push({ columnName, formType: row.formType });
      find.enableGrid.customer =
        find.enableGrid.customer || row.updateEnabled.customer;
      find.enableGrid.dealer =
        find.enableGrid.dealer || row.updateEnabled.dealer;
    } else {
      formGroupData.push({
        formGroupNameId: row.formGroupNameId,
        formGroupName: row.formGroupName,
        formGroupNumber: row.formGroupNumber,
        formCategory: row.formCategory,
        forms: [{ columnName, formType: row.formType }],
        gridFlag: row.gridFlag,
        // find時の処理で元のjsonデータが上書きされてしまうためコピーにする
        enableGrid: { ...row.updateEnabled } || {},
        gridCount: row.gridFlag ? 1 : 0,
      });
    }
  });

  // formGroupNumberで並び替え（昇順）
  formGroupData.sort((a, b) => a.formGroupNumber - b.formGroupNumber);

  return {
    formData: formData as TFormData,
    formGroupData,
    columnToCategory: columnToCategory as TColumnToCategory,
  };
};

export const convIndustrySmallCode = (data: {
  [key in TCustomerFormColumn]: string;
}) => {
  const smallClass = data.industrySmallClass;
  const largeClass = data.industryLargeClass;
  if (!smallClass && !largeClass) return smallClass;
  const rawCode = smallClass ? Object.keys(jsonParseSafety(smallClass))[0] : "";
  const rawValue = smallClass
    ? (Object.values(jsonParseSafety(smallClass))[0] as string)
    : "";
  const largeCode = largeClass
    ? Object.keys(jsonParseSafety(largeClass))[0]
    : "";

  const smallCode = convRawCodeToSmallCode(rawCode, largeCode);
  const sOpt: formTypeOptions = {};
  sOpt[smallCode] = rawValue;
  return JSON.stringify(sOpt);
};

const DB_NAMES = ["jtbApproved", "centerReflected", "centerToBeReflected"];

/**
 * 全フォームの情報を整形
 * @param data: TFormShowData[]
 * @returns: {updateData: TFormData, externalDBData: TExternalDBData}
 */
export const getAllFormContents = (data: TFormShowData[]) => {
  // eslint-disable-next-line
  const updateData: { [key in TFormColumn]?: any } = {};
  const externalDBData: { [key in TFormColumn]?: TExternalDBData } = {};

  data.forEach((d) => {
    const keys = Object.keys(d);
    const columnNames = keys.filter((k) => !DB_NAMES.includes(k));

    columnNames.forEach((k) => {
      const key = k as TFormColumn;
      // 申込Webのデータ
      updateData[key] = d[k as keyof TFormRowData];

      // 外部DBのデータ
      const dbData = cloneObject(d);
      delete dbData[key]; // 申込Webのデータは除外
      externalDBData[key] = { ...dbData };
    });
  });

  return { updateData: updateData as TFormData, externalDBData };
};

/**
 * 契約者情報整形
 * @param data: TContractorShowData[]
 * @returns: {updateData: TContractorFormData, externalDBData: TExternalDBData}
 */
export const getContractorContents = (data: TContractorShowData[]) => {
  // eslint-disable-next-line
  const updateData: { [key in TContractorFormColumn]?: any } = {};
  const externalDBData: { [key in TContractorFormColumn]?: TExternalDBData } =
    {};

  data.forEach((d) => {
    const keys = Object.keys(d.column);
    const columnNames = keys.filter((k) => !DB_NAMES.includes(k));

    columnNames.forEach((k) => {
      const key = k as TContractorFormColumn;
      // 申込Webのデータ
      updateData[key] = d.column[k as keyof TContractorShowColumnData];

      // 外部DBのデータ
      const dbData = cloneObject(d.column);
      delete dbData[key]; // 申込Webのデータは除外
      externalDBData[key] = { ...dbData };
    });
  });
  return { updateData: updateData as TContractorFormData, externalDBData };
};

/**
 * 加盟店情報整形
 * @param data: TCustomerShowData[]
 * @returns: {updateData: TCustomerFormData, externalDBData: TExternalDBData}
 */
export const getCustomerContents = (data: TCustomerShowData[]) => {
  // eslint-disable-next-line
  const updateData: { [key in TCustomerFormColumn]?: any } = {};
  const externalDBData: { [key in TCustomerFormColumn]?: TExternalDBData } = {};

  data.forEach((d) => {
    const keys = Object.keys(d.column);
    const columnNames = keys.filter((k) => !DB_NAMES.includes(k));

    columnNames.forEach((k) => {
      const key = k as TCustomerFormColumn;
      // 申込Webのデータ
      updateData[key] = d.column[k as keyof TCustomerShowColumnData];

      // 小業種はコードを加工する
      if (key === "industrySmallClass") {
        // 小業種コード
        const rawCode = updateData[key]
          ? Object.keys(jsonParseSafety(updateData[key]))[0]
          : "";
        // 小業種の値
        const rawValue: string = updateData[key]
          ? (Object.values(jsonParseSafety(updateData[key]))[0] as string)
          : "";
        // 大業種コード
        const largeCode = d.column.industryLargeClass
          ? Object.keys(jsonParseSafety(d.column.industryLargeClass))[0]
          : "";
        const smallCode = convRawCodeToSmallCode(rawCode, largeCode);
        const sOpt: formTypeOptions = {};
        sOpt[smallCode] = rawValue;
        updateData[key] = JSON.stringify(sOpt);
      }

      // 外部DBのデータ
      const dbData = cloneObject(d.column);
      delete dbData[key]; // 申込Webのデータは除外
      externalDBData[key] = { ...dbData };
    });
  });

  return { updateData: updateData as TCustomerFormData, externalDBData };
};

/**
 * 端末情報整形
 * @param data: TTerminalShowData[]
 * @returns: {updateData: TTerminalFormData, externalDBData: TExternalDBData}
 */
export const getTerminalContents = (data: TTerminalShowData[]) => {
  // eslint-disable-next-line
  const updateData: { [key in TTerminalFormColumn]?: any } = {};
  const externalDBData: { [key in TTerminalFormColumn]?: TExternalDBData } = {};

  data.forEach((d) => {
    const keys = Object.keys(d.column);
    const columnNames = keys.filter((k) => !DB_NAMES.includes(k));

    columnNames.forEach((k) => {
      const key = k as TTerminalFormColumn;
      // 申込Webのデータ
      updateData[key] = d.column[k as keyof TTerminalShowColumnData];

      // 外部DBのデータ
      const dbData = cloneObject(d.column);
      delete dbData[key]; // 申込Webのデータは除外
      externalDBData[key] = { ...dbData };
    });
  });

  return { updateData: updateData as TTerminalFormData, externalDBData };
};

/**
 * formNameIdからcolumnNameに置き換え
 * @param data: TFormObject[]
 * @param formNameIds: number[] 変換対象のformNameId（負の値はそのまま負の値で返す）
 * @returns (TFormColumn | number)[]
 */
export const formNameIdToColumnName = (
  data: TFormObject[],
  formNameIds: number[]
) => {
  const ret: (TFormColumn | number)[] = [];
  formNameIds.forEach((id) => {
    // 負の値の場合はそのまま
    if (id < 0) {
      ret.push(id);
    }
    // formNameIdがヒットしたらカラム名格納
    const find = data.find((d) => d.json.formNameId === id);
    if (find) {
      ret.push(find.column);
    }
  });
  return ret;
};
