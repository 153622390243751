import { TCallback, TListener } from "../views/common/components/types";

export default class BaseStore {
  private listener: TListener;

  constructor() {
    this.listener = new Map<string, TCallback[]>();
  }

  /**
   * 更新通知
   */
  protected emit = (key: string) => {
    const callbacks = this.listener.get(key);
    if (callbacks) {
      callbacks.forEach((cb) => {
        if (cb) cb();
      });
    }
  };

  /**
   * 更新通知のリスナー登録
   * @param key: 基本的にはcolumnName
   */
  public addListener = (key: string, cb: TCallback) => {
    const callbacks = this.listener.get(key);
    if (callbacks) {
      callbacks.push(cb);
    } else {
      this.listener.set(key, [cb]);
    }
  };

  /**
   * リスナーの削除
   * @param key: 基本的にはcolumnName
   */
  public removeListener = (key: string, cb: TCallback) => {
    const callbacks = this.listener.get(key);
    if (callbacks) {
      this.listener.set(
        key,
        callbacks.filter((c) => c !== cb)
      );
    }
  };
}
