/**
 * フォーム関係-グリッドの追加ボタン
 */
import React, { useCallback } from "react";

import { makeStyles, IconButton } from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";

import { TRequiredKey } from "../../../common/components/types";

import formStore from "../../../../store/formStore";

const useStyles = makeStyles({
  plusIcon: {
    marginLeft: 3,
    marginTop: 4,
    fontSize: "1.5em",
  },
  iconButton: {
    padding: 0,
    float: "left",
  },
});

type Props = {
  formGroupNameId: number;
  terminalGridId: number;
  gridId: number;
  requiredKey: TRequiredKey;
};

const GridPlusButton: React.FC<Props> = (props: Props) => {
  const { formGroupNameId, terminalGridId, gridId, requiredKey } = props;

  const classes = useStyles();

  const handleClick = useCallback(() => {
    formStore.gridPlus(formGroupNameId, terminalGridId, gridId, requiredKey);
  }, [formGroupNameId, terminalGridId, gridId, requiredKey]);

  return (
    <IconButton
      color="primary"
      className={classes.iconButton}
      onClick={handleClick}
    >
      <AddBoxIcon className={classes.plusIcon} />
    </IconButton>
  );
};

export default GridPlusButton;
