/**
 * 共通-ダウンロード中表示
 */
import React, { useEffect, useState } from "react";

import { makeStyles, Backdrop, CircularProgress } from "@material-ui/core";

import { FONT } from "../../../../constants/common";

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 1400,
    color: "#fff",
  },
  loadingDiv: {
    textAlign: "center",
  },
  loadingIcon: {
    display: "inline",
  },
  loading: {
    fontSize: "10.0rem",
  },
  loadingMessage: {
    fontSize: "1.0rem",
    fontFamily: FONT,
    display: "block",
  },
}));

interface Props {
  loadingOpen: boolean;
}

// 本コンポーネントは非同期関数(Promiseなど)の処理に組み込むこと。
const DownLoading: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const { loadingOpen } = props;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(loadingOpen);
  }, [loadingOpen]);

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <div className={classes.loadingDiv}>
        <div className={classes.loadingIcon}>
          <CircularProgress className={classes.loading} color="inherit" />
        </div>
        <div className={classes.loadingMessage}>Now Downloading...</div>
      </div>
    </Backdrop>
  );
};

export default DownLoading;
