/**
 * 加盟店側-パスワードリセット確認コード送信画面
 * メモ：パスワードリセット用の確認コードをメールで送信する。確認コード送信後、パスワードリセット画面に遷移する。
 */
import React, { useContext, useState } from "react";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";

import clsx from "clsx";
import { Paper, Button, makeStyles } from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import {
  buttonStyles,
  loginFormStyles,
  loginStyles,
} from "../../../../common/components/styles";

import { GUEST_COLOR } from "../../../../../constants/common";

import { AuthContext } from "../../../../../cognito/AuthContext";

import AlertMessage from "../../../../common/components/atoms/AuthAlertMsg";
import Loading from "../../../../common/components/molecules/Loading";
import LogoForLogin from "../../../../common/components/atoms/LogoForLogin";
import GuestSendConfirmForm from "../organisms/GuestSendConfirmForm";

const useStyles = makeStyles(() => ({
  div: {
    backgroundColor: "#f1f1f1",
    height: "100%",
  },
  // 開発環境(develop)の場合のみ
  divImage: {
    background: `url(${window.location.origin}/bgtestForGree.png) repeat`,
    backgroundColor: "#f1f1f1",
    height: "100%",
  },
  mailButton: {
    marginTop: 40,
    paddingLeft: 52,
    paddingBottom: 10,
  },
  mailIcon: {
    position: "absolute",
    top: 7,
    left: 30,
  },
  linkIcon: {
    position: "relative",
    top: 8,
    left: 5,
    fontSize: "1.8em",
    color: GUEST_COLOR.primary,
  },
}));

const GuestSendConfirmCdPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const classes = useStyles();
  const loginClasses = loginStyles({
    backgroundColor: GUEST_COLOR.primary,
    color: GUEST_COLOR.base,
  });
  const buttonClasses = buttonStyles({
    width: 240,
    marginTop: 40,
    backgroundColor: GUEST_COLOR.primary,
  });
  const loginFormClasses = loginFormStyles();

  const { history } = props;

  const { sendResetCode } = useContext(AuthContext);
  const [error, setError] = useState(<div />);
  const [loadingOpen, setLoadingOpen] = useState(false);

  const [username, setUsername] = useState("");

  const handleToResetPage = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    setLoadingOpen(true);

    // CognitoAuthProviderのパスワードリセット確認コード送信メソッドを実行
    // try-catchはメソッド内でハンドリングしてるので不要。
    const { result, message } = await sendResetCode(username);

    setLoadingOpen(false);
    // 成功した場合、パスワードリセット画面へ遷移
    if (result) {
      setError(<div />);
      history.push({ pathname: "/guest/login/reset", state: { username } });
    }
    setError(<AlertMessage errorMessage={message} />);
  };

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      {/* TODO:検証環境でJTB様がマニュアル用の画面画像取るため一時的に開発環境のTESTの背景を外す */}
      {/* <div
        className={
          process.env.REACT_APP_ENV === "development"
            ? classes.divImage
            : classes.div
        }
      > */}
      <div className={classes.div}>
        <Paper className={loginClasses.paper} elevation={0}>
          {/* ロゴ */}
          <LogoForLogin />
          {/* エラーメッセージ */}
          {error}
          <form>
            <div className={loginClasses.innerForm}>
              {/* 入力フォーム */}
              <GuestSendConfirmForm
                username={username}
                setUsername={setUsername}
              />
              {/* 確認コード送信ボタン */}
              <Button
                type="submit"
                onClick={handleToResetPage}
                className={clsx(
                  buttonClasses.button,
                  loginClasses.button,
                  classes.mailButton
                )}
                variant="contained"
                disabled={loadingOpen} // 二重送信対策
              >
                <MailIcon className={classes.mailIcon} />
                確認コードを送信する
              </Button>
              {/* 確認コードをお持ちの方はこちらリンク */}
              <Link to="/guest/login/reset" className={loginFormClasses.link}>
                既に確認コードをお持ちの方はこちら
                <KeyboardArrowRightIcon className={classes.linkIcon} />
              </Link>
            </div>
          </form>
        </Paper>
      </div>
    </>
  );
};

export default withRouter(GuestSendConfirmCdPage);
