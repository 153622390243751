/**
 * JTB側-自社ユーザー検索・一覧画面
 * メモ：自社ユーザー管理の検索・一覧画面を表示する
 */
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import clsx from "clsx";
import { Container, makeStyles, Button } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SearchIcon from "@material-ui/icons/Search";
import { Alert } from "@material-ui/lab";

import useContainerStyles from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { UserSearchCondition } from "../../types";
import { IUserSearchResponse } from "../../../../../api/types";

import { ADMIN_COLOR } from "../../../../../constants/common";
import { USER_TYPE_SEARCH_MAP_FOR_JTB } from "../../../../../constants/user";

import cognitoUser from "../../../../../api/cognitoUser";
import { AuthContext } from "../../../../../cognito/AuthContext";
import { getErrorMessage } from "../../../../../utils/error";

import Loading from "../../../../common/components/molecules/Loading";
import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import UserSearchConditionForm from "../organisms/UserSearchConditionForm";
import UserSearchResult from "../organisms/UserSearchResult";

const breadCrumbs = [
  { name: "設定", link: "/dealer/setting" },
  { name: "自社ユーザー管理・検索", link: "" },
];

const useStyles = makeStyles(() => ({
  alert: {
    marginTop: "30px",
  },
  button: {
    marginLeft: 0,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 34,
    backgroundColor: `${ADMIN_COLOR.base}!important`,
    color: `${ADMIN_COLOR.primary}!important`,
    border: `1px solid ${ADMIN_COLOR.primary}`,
    borderRadius: 0,
    textAlign: "center",
    "&:hover": {
      backgroundColor: `${ADMIN_COLOR.btnHover}!important`,
    },
  },
  addButton: {
    marginBottom: 20,
  },
  icon: {
    position: "absolute",
    top: 7,
    left: 15,
    fontSize: "1.3rem",
  },
  form: {
    width: 800,
  },
  searchButton: {
    paddingBottom: 8,
    margin: "0 auto",
    marginTop: 20,
  },
  searchIcon: {
    position: "absolute",
    top: 8,
    left: 50,
  },
}));

const SettingUserManagementPage: React.FC = () => {
  const containerClasses = useContainerStyles();
  const classes = useStyles();
  const buttonClasses = buttonStyles({
    width: 213,
    marginTop: 15,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const searchButtonClasses = buttonStyles({
    width: 180,
    marginTop: 15,
    textLeft: 10,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const { authHeader } = useContext(AuthContext);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [searchApiErrorMsg, setSearchApiErrorMsg] = useState<string>("");
  const [searchCondition, setSearchCondition] = useState<UserSearchCondition>({
    name: "",
    userName: "",
    email: "",
    userType: "",
    pageNumber: "1",
  });
  const [searchResult, setSearchResult] = useState<IUserSearchResponse>({});

  // 自社ユーザー検索&取得
  const getUsers = (pageNumber: string) => {
    setLoadingOpen(true);

    // ユーザー種別を名称から番号に変換
    const userType = USER_TYPE_SEARCH_MAP_FOR_JTB[searchCondition.userType]
      ? USER_TYPE_SEARCH_MAP_FOR_JTB[searchCondition.userType]
      : "";

    cognitoUser
      .searchUsers(
        pageNumber, // ページ番号、検索時は1固定
        authHeader as string,
        searchCondition.name,
        searchCondition.userName,
        searchCondition.email,
        userType
      )
      .then((res) => {
        // ユーザーリストの取得結果と検索条件をセット
        setSearchResult(res.data);
        setSearchCondition({ ...searchCondition, pageNumber });
        setSearchApiErrorMsg("");
      })
      .catch((error) => {
        setSearchApiErrorMsg(getErrorMessage(error));
        throw error;
      })
      .finally(() => {
        setLoadingOpen(false);
      });
  };

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Dashboard
        title="設定"
        color={ADMIN_COLOR.base}
        backgroundColor={ADMIN_COLOR.primary}
        iconName="setting"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {searchApiErrorMsg !== "" && (
            <Alert className={classes.alert} severity="error">
              {searchApiErrorMsg}
            </Alert>
          )}
          {/* ユーザー追加ボタン */}
          <Button
            className={clsx(
              buttonClasses.button,
              classes.button,
              classes.addButton
            )}
            variant="contained"
            component={Link}
            to="/dealer/setting/user/new"
          >
            <PersonAddIcon className={classes.icon} />
            ユーザー追加
          </Button>
          {/* ユーザー検索フォーム */}
          <form className={classes.form}>
            <UserSearchConditionForm
              searchCondition={searchCondition}
              setSearchCondition={setSearchCondition}
            />
            <Button
              className={clsx(searchButtonClasses.button, classes.searchButton)}
              variant="contained"
              type="submit"
              onClick={() => getUsers("1")}
              disabled={loadingOpen} // 二重送信対策
            >
              <SearchIcon className={classes.searchIcon} />
              検索
            </Button>
          </form>
          {searchApiErrorMsg === "" && (
            <>
              {/* ユーザー検索結果 */}
              {searchResult && (
                <UserSearchResult
                  resultsRows={searchResult}
                  conditionState={searchCondition}
                  setApiErrorMsg={setSearchApiErrorMsg}
                  setLoadingOpen={setLoadingOpen}
                  loadingOpen={loadingOpen}
                  getUsers={getUsers}
                />
              )}
            </>
          )}
        </Container>
      </Dashboard>
    </>
  );
};

export default SettingUserManagementPage;
