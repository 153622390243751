/**
 * JTB側-加盟店ユーザー再作成
 */
import React, { useContext, useState } from "react";

import { Button, Typography, Box, makeStyles } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

import { buttonStyles } from "../../../../common/components/styles";

import { ADMIN_COLOR, FONT } from "../../../../../constants/common";

import { GlobalPopupContext } from "../../../../../App";
import { AuthContext } from "../../../../../cognito/AuthContext";
import cognitoUser from "../../../../../api/cognitoUser";
import { getErrorMessage } from "../../../../../utils/error";

import CustomTextField from "../../../../common/components/atoms/CustomTextField";

const useStyles = makeStyles(() => ({
  box: {
    border: `3px solid ${ADMIN_COLOR.primaryDark}`,
    padding: 30,
    paddingBottom: 20,
    marginTop: -24,
    zIndex: 1,
  },
  h2: {
    fontSize: "1.2em",
    color: ADMIN_COLOR.primary,
    paddingTop: 17,
    fontWeight: "bold",
    height: 50,
    fontFamily: FONT,
    zIndex: 2,
    backgroundColor: ADMIN_COLOR.base,
    position: "relative",
    width: 250,
    marginLeft: 28,
    paddingLeft: 11,
  },
  userAddIcon: {
    verticalAlign: "top",
    left: -2,
    top: -3,
    position: "relative",
  },
  message: {
    fontSize: "0.9em",
    fontWeight: "bold",
    fontFamily: FONT,
    color: ADMIN_COLOR.primary,
    marginBottom: 15,
  },
  alertMessage: {
    marginBottom: 10,
  },
}));

type Props = {
  dealId: number;
  contractorId: number;
};

const UserRecreateBox: React.FC<Props> = (props: Props) => {
  const { dealId, contractorId } = props;

  const { authHeader } = useContext(AuthContext);
  const { setLoading, setSnackbar, setConfirmModal } =
    useContext(GlobalPopupContext);
  const [mail, setMail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const classes = useStyles();
  const buttonClasses = buttonStyles({
    width: 100,
    marginTop: 20,
    marginLeft: 400,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const handleUserRecreate = async () => {
    setConfirmModal({
      open: false,
      modalTitle: "",
      actionMethod: () => {
        /* 何もしない */
      },
      colors: ADMIN_COLOR,
    });
    setLoading(true);
    try {
      // 顧客更新処理
      await cognitoUser.recreate(
        authHeader as string,
        contractorId,
        dealId,
        mail
      );
      setSnackbar({
        openProps: true,
        severity: "info",
        message: "ユーザーの再作成が完了しました。",
      });

      setErrorMessage("");
      setLoading(false);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
      setLoading(false);
      throw error;
    }
  };

  const handleConfirm = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if (!mail) {
      setErrorMessage("メールアドレスを入力してください。");
      return;
    }
    setConfirmModal({
      open: true,
      modalTitle: `対象の加盟店側の既に存在する本社スーパーユーザーが削除され、新しく本社スーパーユーザーが作成されます。\n事前に加盟店への連絡を忘れないようにしてください。`,
      actionMethod: handleUserRecreate,
      colors: ADMIN_COLOR,
    });
  };

  return (
    <>
      <Typography variant="h2" className={classes.h2}>
        <PersonAddIcon className={classes.userAddIcon} />
        {" 加盟店ユーザー再作成"}
      </Typography>
      <Box className={classes.box} borderRadius={16}>
        <div className={classes.message}>
          加盟店ユーザーのメールアドレスを入力してください。ユーザーを再作成し再度通知が行われます。
        </div>
        {errorMessage && (
          <div className={classes.alertMessage}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorMessage}
            </Alert>
          </div>
        )}
        <CustomTextField
          value={mail}
          onChange={(e) => setMail(e.target.value)}
          placeholder="メールアドレス"
        />
        <Button
          type="submit"
          onClick={handleConfirm}
          className={buttonClasses.button}
          variant="contained"
        >
          再作成
        </Button>
      </Box>
    </>
  );
};

export default UserRecreateBox;
