/**
 * JTB側-追加アップロードファイル一覧
 */
import React, { useCallback, useContext } from "react";

import { Button, makeStyles } from "@material-ui/core";

import { ADMIN_COLOR, FONT } from "../../../../../constants/common";

import { GlobalPopupContext } from "../../../../../App";
import { AuthContext } from "../../../../../cognito/AuthContext";
import fileUtils from "../../../../../api/utils/fileUtils";
import getErrorMessage from "../../../../../utils/error";
import { convBase64toBlob, downloadBlobFile } from "../../../../../utils/file";

const useStyles = makeStyles({
  title: {
    fontSize: "1.1em",
    color: ADMIN_COLOR.primary,
    fontFamily: FONT,
    marginTop: 20,
    marginBottom: 15,
  },
  link: {
    border: "none",
    color: ADMIN_COLOR.primary,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#fff",
      textDecoration: "underline",
    },
  },
});

type Props = {
  fileData: { fileName: string; fileId: number }[];
};

const UploadedAdditionalFiles: React.FC<Props> = (props: Props) => {
  const { fileData } = props;

  const { authHeader } = useContext(AuthContext);
  const { setLoading, setSnackbar } = useContext(GlobalPopupContext);

  const classes = useStyles();

  const handleClick = useCallback(
    async (fileId: number, fileName: string) => {
      setLoading(true);
      // ダウンロードAPI作成
      const api = fileUtils.getDownloadFileApi(authHeader || "", [
        { fileId: fileId.toString() },
      ]);

      // API実行
      Promise.all(api)
        .then((res) => {
          // 返ってきたbase64からファイル作成してDL
          const f = res[0].file;
          if (f.startsWith("data:image/png")) {
            // png
            const blobPng = convBase64toBlob(f, "image/png");

            downloadBlobFile(blobPng, fileName);
          } else if (
            f.startsWith("data:image/jpg") ||
            f.startsWith("data:image/jpeg")
          ) {
            // jpg or jpeg
            const blobJpg = convBase64toBlob(f, "image/jpg");

            downloadBlobFile(blobJpg, fileName);
          } else if (f.startsWith("data:application/pdf")) {
            // pdf
            const blobPdf = convBase64toBlob(f, "application/pdf");

            downloadBlobFile(blobPdf, fileName);
          } else if (
            f.startsWith(
              "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            )
          ) {
            // xlsx
            const blobPdf = convBase64toBlob(
              f,
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            );

            downloadBlobFile(blobPdf, fileName);
          }

          setLoading(false);
        })
        .catch((err) => {
          setSnackbar({
            openProps: true,
            severity: "error",
            message: getErrorMessage(err),
          });
          setLoading(false);
        });
    },
    [authHeader, setLoading, setSnackbar]
  );

  return (
    <div>
      <div className={classes.title}>追加アップロードファイル</div>

      {fileData.map((data) => (
        <li key={data.fileId}>
          <Button
            onClick={() => handleClick(data.fileId, data.fileName)}
            type="button"
            className={classes.link}
          >
            {data.fileName}
          </Button>
        </li>
      ))}
    </div>
  );
};

export default UploadedAdditionalFiles;
