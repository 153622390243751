/**
 * 加盟店側-申請管理
 * メモ：申請管理系画面の親コンポーネント
 */
import React from "react";
import { Route, Switch } from "react-router-dom";

import DealPage from "./pages/DealPage";
import GuestDealNewPage from "./pages/GuestDealNewPage";
import GuestDealNewConfirmPage from "./pages/GuestDealNewConfirmPage";
import GuestDealNewCompletePage from "./pages/GuestDealNewCompletePage";
import DealShowPage from "./pages/DealShowPage";
import GuestDealEditPage from "./pages/GuestDealEditPage";
import GuestDealEditConfirmPage from "./pages/GuestDealEditConfirmPage";
import GuestDealEditCompletePage from "./pages/GuestDealEditCompletePage";
import TermsPdfPage from "./pages/TermsPdfPage";
import GuestTerminateDealHooks from "../../terminate-deal/components/GuestTerminateDealHooks";
import ChangeDealHooks from "../../change-deal/components/ChangeDealHooks";

const GuestDealHooks: React.FC = () => {
  return (
    <Switch>
      {/* 申請検索画面 */}
      <Route path="/guest/deal" render={() => <DealPage />} exact />
      {/* 端末増設申請画面 */}
      <Route path="/guest/deal/new" render={() => <GuestDealNewPage />} exact />
      {/* 登録申請情報入力内容確認画面 */}
      <Route
        path="/guest/deal/confirm"
        render={() => <GuestDealNewConfirmPage />}
        exact
      />
      {/* 登録申請情報入力完了画面 */}
      <Route
        exact
        path="/guest/deal/complete/:id"
        render={() => <GuestDealNewCompletePage />}
      />
      {/* 申請詳細 */}
      <Route path="/guest/deal/:id" render={() => <DealShowPage />} exact />
      {/* 申請編集画面 */}
      <Route
        path="/guest/deal/:id/edit"
        render={() => <GuestDealEditPage />}
        exact
      />
      {/* 申請編集確認画面 */}
      <Route
        path="/guest/deal/:id/edit/confirm"
        render={() => <GuestDealEditConfirmPage />}
        exact
      />
      {/* 申請編集完了画面 */}
      <Route
        path="/guest/deal/:id/edit/complete"
        render={() => <GuestDealEditCompletePage />}
        exact
      />
      {/* 規約確認画面 */}
      <Route
        exact
        path="/guest/deal/:id/terms-pdf"
        render={() => <TermsPdfPage />}
      />
      {/* 変更申請関連 */}
      <Route path="/guest/deal/change" render={() => <ChangeDealHooks />} />
      {/* 解約申請関連 */}
      <Route
        path="/guest/deal/termination"
        render={() => <GuestTerminateDealHooks />}
      />
    </Switch>
  );
};

export default GuestDealHooks;
