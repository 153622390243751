/**
 * フォーム関係-解約申請確認用フォーム
 */
import React from "react";

import { Table, TableBody, TableContainer } from "@material-ui/core";

import { formStyles } from "../../../common/components/styles";

import {
  TRequiredKey,
  TFormColumn,
  TFormRowData,
} from "../../../common/components/types";

import { ThemeColorProps } from "../../../../constants/common";

import ConfirmFormValueRowForTerminateDeal from "../atoms/ConfirmFormValueRowForTerminateDeal";

type Props = {
  columnNames: TFormColumn[];
  terminateFormRow: TFormRowData;
  requiredKey: TRequiredKey;
  colors: ThemeColorProps;
};

const ConfirmFormForTerminateDeal: React.FC<Props> = (props: Props) => {
  const { columnNames, terminateFormRow, requiredKey, colors } = props;

  const formClasses = formStyles({
    color: colors.base,
    backgroundColor: colors.primary,
  });

  if (!columnNames) return null;

  return (
    <TableContainer className={formClasses.tableContainer}>
      <Table>
        <TableBody>
          {columnNames.map(
            (columnName) =>
              Object.keys(terminateFormRow).length > 0 &&
              Array.isArray(terminateFormRow[columnName]) &&
              terminateFormRow[columnName].length > 0 && (
                <ConfirmFormValueRowForTerminateDeal
                  key={columnName}
                  columnName={columnName}
                  terminateFormRowObj={terminateFormRow[columnName][0]}
                  requiredKey={requiredKey}
                />
              )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ConfirmFormForTerminateDeal;
