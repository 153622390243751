import axios from "axios";
import { UPLOAD_FILE_TYPE } from "../constants/deal";
import {
  createAPIPromise,
  createLoadingPromise,
  loadingUrls,
} from "./utils/common";

/**
 * ファイルアップロードAPIのレスポンス
 */
export type TUploadResponse = {
  data: {
    statusCode: number;
    message: string;
    result: {
      fileId: number;
    };
  };
};

/**
 * ファイルダウンロードAPIのレスポンス
 */
export type TDownloadResponse = {
  data: {
    statusCode: number;
    message: string;
    result: {
      file: string;
    };
  };
};

/**
 * ファイルアップロードAPI
 * @param authHeader: 認証トークン
 * @param file: ファイルデータ(base64)
 * @param fileName: ファイル名
 * @param fileType: ファイル種別
 * @param dealId?: 申請ID
 * @param masterCustomerId?: マスター加盟店ID
 * @param fileId?: 登録済みファイルの上書きに必要
 */
const upload = async (
  authHeader: string,
  file: string,
  fileName: string,
  fileType: string,
  dealId?: number,
  masterCustomerId?: number,
  fileId?: number
): Promise<TUploadResponse> => {
  const path = `/uploadfile`;
  // 複数ファイルでも全てパスが同じなので通信中用に別keyを使う
  const key = `${path}/${fileName}`;
  if (loadingUrls.includes(key)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(key);
  return createAPIPromise(
    axios.post(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      { file, fileName, fileType, dealId, masterCustomerId, fileId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    key
  );
};

/**
 * 追加資料アップロードAPI
 * @param authHeader: 認証トークン
 * @param file: ファイルデータ(base64)
 * @param fileName: ファイル名
 * @param dealId: 申請ID
 * @param masterCustomerId: マスター加盟店ID
 * @param fileId?: 登録済みファイルの上書きに必要
 */
const uploadAdditional = async (
  authHeader: string,
  file: string,
  fileName: string,
  dealId: number,
  masterCustomerId: number,
  fileId?: number
): Promise<TUploadResponse> => {
  const path = `/uploadfile`;
  // 複数ファイルでも全てパスが同じなので通信中用に別keyを使う
  const key = `${path}/${fileName}`;
  if (loadingUrls.includes(key)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(key);
  return createAPIPromise(
    axios.post(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      {
        file,
        fileName,
        fileType: UPLOAD_FILE_TYPE.ADDITIONAL,
        dealId,
        masterCustomerId,
        fileId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    key
  );
};

/**
 * 追加資料アップロードAPI 契約者変更用
 * @param authHeader: 認証トークン
 * @param file: ファイルデータ(base64)
 * @param fileName: ファイル名
 * @param dealId: 申請ID
 * @param contractorId: 契約者ID
 * @param fileId?: 登録済みファイルの上書きに必要
 */
const uploadAdditionalForContractor = async (
  authHeader: string,
  file: string,
  fileName: string,
  dealId: number,
  contractorId: number,
  fileId?: number
): Promise<TUploadResponse> => {
  const path = `/uploadfile`;
  // 複数ファイルでも全てパスが同じなので通信中用に別keyを使う
  const key = `${path}/${fileName}`;
  if (loadingUrls.includes(key)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(key);
  return createAPIPromise(
    axios.post(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      {
        file,
        fileName,
        fileType: UPLOAD_FILE_TYPE.ADDITIONAL,
        dealId,
        contractorId,
        fileId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    key
  );
};

/**
 * ファイルダウンロードAPI
 * @param authHeader: 認証トークン
 * @param fileId: ファイルID
 */
const download = async (
  authHeader: string,
  fileId: string
): Promise<TDownloadResponse> => {
  const path = `/downloadFile/${fileId}`;

  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }

  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
    }),
    path
  );
};

export default {
  upload,
  uploadAdditional,
  uploadAdditionalForContractor,
  download,
};
