/**
 * 共通-必須ラベル
 */
import React from "react";

import { Chip, makeStyles } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";

interface Props {
  left?: number;
  top: string; // %で指定(例：37%%など)
}

const RequiredChip: React.FC<Props> = (props: Props) => {
  const { left, top } = props;

  const useStyles = makeStyles(() => ({
    chip: {
      borderRadius: 0,
      marginLeft: 10,
      position: "absolute",
      backgroundColor: "#ed1c24",
      color: "#fff",
      height: 24,
      left,
      top,
    },
  }));
  const classes = useStyles();

  return (
    <>
      <Chip className={classes.chip} label="必須" deleteIcon={<DoneIcon />} />
    </>
  );
};

export default React.memo(RequiredChip);
