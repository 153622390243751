/**
 * 共通-TabPanel
 */
import React, { useEffect, useMemo, useRef, useState } from "react";

import { makeStyles, Box } from "@material-ui/core";

import { TAB_PANEL_MIN_HEIGHT } from "../../../../constants/common";

const useStyles = makeStyles({
  box: (props: TabPanelStyle) => ({
    border: `3px solid ${props.borderColor}`,
    padding: 40,
    paddingTop: 20,
    paddingBottom: 20,
    zIndex: 1,
    maxHeight: props.elmHeight,
    overflowY: "auto",
  }),
  tabpanel: {
    display: "none",
  },
});

type TabPanelStyle = {
  borderColor: string;
  elmHeight: number;
};

interface Props {
  children: React.ReactNode;
  index: number;
  value: number;
  borderColor: string;
  height?: number;
}

const TabPanel: React.FC<Props> = (props: Props) => {
  const { children, value, index, borderColor, height } = props;

  const compRef = useRef<HTMLDivElement>(null); // スクロール時のフォーカス移動対象用

  // TabPanel表示領域の高さを計算
  const calcHeight = useMemo(() => {
    // 上下の要素の高さ（暫定で固定値）
    const elmHeightTop = 245;
    const elmHeightTBottom = 132;
    const maxHeight = window.innerHeight - elmHeightTop - elmHeightTBottom;

    return maxHeight < TAB_PANEL_MIN_HEIGHT ? TAB_PANEL_MIN_HEIGHT : maxHeight;
    // windowサイズに変更があれば再計算する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerHeight]);

  // height指定がある場合はその値、ない場合は自動計算した値を設定
  // 登録編集系：自動計算（ボタンを見せたいため）, 詳細画面系：固定値
  const [elmHeight, setElmHeight] = useState(height || calcHeight);

  useEffect(() => {
    if (!height) setElmHeight(calcHeight);
  }, [calcHeight, height]);

  const classes = useStyles({
    borderColor,
    elmHeight,
  });

  // 選択されたタブでないなら何もDOMにのせない
  return value !== index ? null : (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      tabIndex={index} // focusメソッドを動作させるために設定する
      ref={compRef}
    >
      <Box id="scroll-parent" className={classes.box} p={3}>
        {children}
      </Box>
    </div>
  );
};

export default TabPanel;
