/**
 * フォーム関係-入力用のフォームでのグリッドのフォームグループのコンポーネント
 */
import React, { ReactElement, useEffect, useMemo, useState } from "react";

import {
  Table,
  TableBody,
  TableContainer,
  makeStyles,
} from "@material-ui/core";

import { formStyles } from "../../../common/components/styles";

import { TRequiredKey } from "../../../common/components/types";

import { FONT, ThemeColorProps } from "../../../../constants/common";

import formStore from "../../../../store/formStore";

import GridHeading from "../../../common/components/atoms/GridHeading";
import CustomTableRow from "../atoms/CustomTableRow";
import GridClearButton from "../atoms/GridClearButton";
import GridMinusButton from "../atoms/GridMinusButton";
import GridPlusButton from "../atoms/GridPlusButton";

const useStyles = makeStyles(() => ({
  gridButton: {
    clear: "both",
    height: 35,
  },
  th: {
    fontFamily: FONT,
    fontWeight: 900,
    backgroundColor: "#fff",
    color: "#000!important",
    width: 263,
    borderBottom: "2px dotted #ddd",
    position: "relative",
  },
  td: {
    backgroundColor: "#fff",
    color: "#000!important",
    borderBottom: "2px dotted #ddd",
  },
  gridHeadingContainer: {
    position: "relative",
  },
}));

type Props = {
  formGroupNameId: number;
  requiredKey: TRequiredKey;
  colors: ThemeColorProps;
  terminalGridId: number | undefined;
};

const GridGroup: React.FC<Props> = (props: Props) => {
  const { formGroupNameId, requiredKey, colors, terminalGridId } = props;

  const [groupData, setGroupData] = useState(
    formStore.getFormGroupData(formGroupNameId)
  );

  const formClasses = formStyles({
    color: colors.base,
    backgroundColor: colors.primary,
  });
  const classes = useStyles();

  useEffect(() => {
    const fn = () => {
      const data = formStore.getFormGroupData(formGroupNameId);
      setGroupData(data ? { ...data } : data);
    };
    formStore.addListener("grid", fn);

    return () => formStore.removeListener("grid", fn);
  }, [formGroupNameId]);

  const forms = useMemo(() => {
    const ret: ReactElement[] = [];
    if (!groupData) return ret;
    for (let i = 0; i < groupData.gridCount; i += 1) {
      ret.push(
        <>
          <div className={classes.gridHeadingContainer}>
            <GridHeading
              title={`${groupData.formGroupName}${String(i + 1)}`}
              key={`${groupData.formGroupName}${String(i + 1)}`}
            />
            <GridClearButton
              formGroupNameId={formGroupNameId}
              terminalGridId={terminalGridId || 0}
              gridId={i}
              requiredKey={requiredKey}
              colors={{
                color: colors.base,
                backgroundColor: colors.primary,
                gridColor: colors.grid,
                btnHoverColor: colors.btnHover,
              }}
            />
          </div>
          <TableContainer className={formClasses.tableContainer}>
            <Table>
              <TableBody>
                {groupData.forms.map((form) => (
                  <CustomTableRow
                    key={form.columnName}
                    columnName={form.columnName}
                    requiredKey={requiredKey}
                    formType={form.formType}
                    backgroundColor={colors.primary}
                    terminalGridId={terminalGridId}
                    gridId={i}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {groupData.enableGrid[requiredKey] && (
            <div className={classes.gridButton}>
              <GridPlusButton
                formGroupNameId={formGroupNameId}
                terminalGridId={terminalGridId || 0}
                gridId={i}
                requiredKey={requiredKey}
              />
              <GridMinusButton
                formGroupNameId={formGroupNameId}
                terminalGridId={terminalGridId || 0}
                gridId={i}
                requiredKey={requiredKey}
              />
            </div>
          )}
        </>
      );
    }
    return ret;
    // classesは入れない
    // eslint-disable-next-line
  }, [formGroupNameId, groupData, requiredKey]);

  if (!groupData) return null;

  return <>{forms}</>;
};

export default GridGroup;
