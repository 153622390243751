/**
 * JTB側-新パスワード入力フォーム
 * メモ：入力フォームを表示する
 */
import React from "react";

import { FormControl } from "@material-ui/core";

import { loginFormStyles } from "../../../../common/components/styles";

import { NewPasswordItemObject } from "../../types";

import CustomTextField from "../../../../common/components/atoms/CustomTextField";

interface Props {
  newPasswordState: NewPasswordItemObject;
  setNewPasswordState: React.Dispatch<
    React.SetStateAction<NewPasswordItemObject>
  >;
}

const DealerRequireNewPasswordForm: React.FC<Props> = (props: Props) => {
  const loginFormClasses = loginFormStyles();
  const { newPasswordState, setNewPasswordState } = props;

  return (
    <>
      <FormControl className={loginFormClasses.form}>
        <CustomTextField
          type="password"
          value=""
          placeholder="パスワードを入力"
          autoComplete="new-password"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNewPasswordState({
              ...newPasswordState,
              newPassword: e.target.value,
            })
          }
        />
      </FormControl>
      <FormControl className={loginFormClasses.form}>
        <CustomTextField
          type="password"
          value=""
          placeholder="パスワードを入力(確認)"
          autoComplete="new-password"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNewPasswordState({
              ...newPasswordState,
              newPasswordConfirm: e.target.value,
            })
          }
        />
      </FormControl>
    </>
  );
};

export default DealerRequireNewPasswordForm;
