// APIで返ってくる型は業務外の付与情報がついているのでフロントでは型を明確に定義しない。
import axios from "axios";
import { TFormColumn } from "../views/common/components/types";
import {
  createAPIPromise,
  createLoadingPromise,
  createParamStr,
  loadingUrls,
} from "./utils/common";
import { contractorSearchParameters } from "./types";

/**
 * 契約者情報検索API
 * @param authHeader 認証トークン
 * @param companyName 会社名
 * @param corporateNum 法人番号
 * @param customerCode 加盟店コード
 * @param customerName 加盟店名
 * @param customerPhoneNum 加盟店電話番号
 * @param contractorTerminationFlag 解約済みを含むか
 * @param pageNumber ページ番号
 */
const search = async (
  authHeader: string,
  companyName: string,
  corporateNum: string,
  customerCode: string,
  customerName: string,
  customerPhoneNum: string,
  contractorTerminationFlag: string,
  pageNumber: string
  // eslint-disable-next-line
): Promise<any> => {
  const params: contractorSearchParameters = {
    contractorTerminationFlag,
    pageNumber,
  };
  params.companyName = companyName === "" ? undefined : companyName;
  params.corporateNum = corporateNum === "" ? undefined : corporateNum;
  params.customerCode = customerCode === "" ? undefined : customerCode;
  params.customerName = customerName === "" ? undefined : customerName;
  params.customerPhoneNum =
    customerPhoneNum === "" ? undefined : customerPhoneNum;

  const path = `/contractors`;
  const paramStr = createParamStr(params);
  const loadingKey = `${path}?${paramStr}`;
  if (loadingUrls.includes(loadingKey)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(loadingKey);

  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    loadingKey
  );
};

/**
 * 契約者登録API
 * @param authHeader 認証トークン
 * @param contractorForm 契約者情報
 * @param fileIds 申請ファイルID群
 */
const register = (
  authHeader: string,
  contractorForm: Partial<{ [key in TFormColumn]: string }>,
  fileIds: number[]
) => {
  const path = `/contractors`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  loadingUrls.push(path);
  return createAPIPromise(
    axios.post(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      {
        contractorForm,
        fileIds,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

/**
 * 契約者情報更新API
 * @param authHeader 認証トークン
 * @param contractorId 契約者ID
 * @param data 契約者情報
 * @param fileIds 申請ファイルID群
 */
const update = (
  authHeader: string,
  contractorId: number,
  data: Partial<{ [key in TFormColumn]: string }>,
  fileIds: number[]
) => {
  const path = `/contractors/${contractorId}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  loadingUrls.push(path);
  return createAPIPromise(
    axios.put(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      {
        contractorForm: { contractorId, ...data },
        fileIds,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

/**
 * 個別契約者情報取得API（JTB版用）
 * @param authHeader: 認証トークン
 * @param contractorId: 契約者id
 * @returns
 */
const show = async (authHeader: string, contractorId: string) => {
  const path = `/contractorDetails/${contractorId}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    path
  );
};

export default {
  search,
  register,
  update,
  show,
};
