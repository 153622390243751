/**
 * 加盟店側-ユーザー編集画面
 * メモ：編集画面を表示する
 */
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps, withRouter, useLocation } from "react-router-dom";

import clsx from "clsx";
import { Button, Container, makeStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

import { buttonStyles } from "../../../../common/components/styles";
import useContainerStyles from "../../../../templates/styles";

import { GuestUserEditItemObject } from "../../types";

import {
  DISPLAY_NAME_MAX_LENGTH,
  GUEST_COLOR,
} from "../../../../../constants/common";
import { USER_TYPE_CONVERT_MAP } from "../../../../../constants/user";

import cognitoUser from "../../../../../api/cognitoUser";
import { GlobalPopupContext } from "../../../../../App";
import { AuthContext } from "../../../../../cognito/AuthContext";
import { compareSJISWhiteList } from "../../../../../form/validation";
import getErrorMessage from "../../../../../utils/error";

import AlertMessage from "../../../../common/components/atoms/AuthAlertMsg";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import Loading from "../../../../common/components/molecules/Loading";
import Dashboard from "../../../../templates/Dashboard";
import GuestSettingUserEditForm from "../organisms/GuestSettingUserEditForm";

// パンくずリストの設定
const breadCrumbs = [
  { name: "設定", link: "/guest/setting" },
  { name: "ユーザー管理・検索", link: "/guest/setting/user" },
  { name: "ユーザー編集", link: "" },
];

// レイアウト設定
const useStyles = makeStyles(() => ({
  addButton: {
    margin: "0 auto",
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 27,
  },
  checkIcon: {
    position: "absolute",
    top: 8,
    left: 31,
  },
  errMessage: {
    marginTop: 20,
  },
  form: {
    marginTop: 20,
  },
}));

type Props = RouteComponentProps<{ id: string }>;
const GuestSettingUserEditPage: React.FC<Props> = (props: Props) => {
  const buttonClasses = buttonStyles({
    width: 180,
    marginTop: 20,
    backgroundColor: GUEST_COLOR.primary,
  });
  const containerClasses = useContainerStyles();
  const classes = useStyles();

  const { history } = props;
  // TODO: 個別ユーザー取得APIではユーザーテーブルのIDが取れないため検索画面からデータを受け取る
  // 個別ユーザー取得APIの返却にユーザーテーブルのIDを入れてもらえたら修正（余裕ができたら）
  const { state } = useLocation(); // ユーザーの情報を受け取る

  const { authHeader, reloadCurrentSession, userAttributes } =
    useContext(AuthContext);
  const { setConfirmModal, setSnackbar } = useContext(GlobalPopupContext);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [updateUserError, setUpdateUserError] = useState("");
  const [fetchError, setFetchError] = useState("");

  // ユーザー編集情報
  const [userEditState, setUserEditState] = useState<GuestUserEditItemObject>({
    userName: "",
    name: "",
    userType: "",
    masterCustomerId: "",
    customers: [],
    releaseLock: false,
    isLocked: false,
  });

  // 編集対象ユーザー情報取得（個別ユーザー取得APIで取得できる情報が必要なため）
  const getTargetUser = async () => {
    setLoadingOpen(true);
    await cognitoUser
      .getUserInfo(state.userName, authHeader as string)
      .then(async (res) => {
        const data = res.data.result as GuestUserEditItemObject;
        setUserEditState({
          userName: data.userName,
          name: data.name,
          userType: USER_TYPE_CONVERT_MAP.get(data.userType) as string,
          masterCustomerId: data.masterCustomerId,
          customers: data.customers,
          releaseLock: false,
          isLocked: data.isLocked,
        });
      })
      .catch(() => {
        setFetchError("ユーザーの情報が取得できませんでした。");
      })
      .finally(() => {
        setLoadingOpen(false);
      });
  };

  // 遷移時に対象ユーザー取得する
  useEffect(() => {
    if (state && state.userName && state.id) {
      getTargetUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ユーザー編集API実行
  const handleToCompletePage = async () => {
    setLoadingOpen(true);
    await cognitoUser
      .guestUserUpdate(
        authHeader as string,
        state.id as number,
        userEditState.name as string,
        userEditState.masterCustomerId,
        userEditState.releaseLock,
        false // ロックはJTB側でしかできないためfalse固定
      )
      .then(async (res) => {
        if (res.data.result) {
          if (
            userAttributes &&
            userEditState.userName === userAttributes["cognito:username"]
          ) {
            // ログインユーザーのアカウントの場合cognitoのSession情報を最新に
            // 更新された情報を取得するため少し遅らせる（500ms）
            await setTimeout(() => reloadCurrentSession(), 500);
          }
          history.push(`/guest/setting/user`);
          setSnackbar({
            openProps: true,
            message: "ユーザー情報の更新が完了しました。",
            severity: "info",
          });
        }
      })
      .catch((error) => {
        setUpdateUserError(getErrorMessage(error));
      })
      .finally(() => {
        setConfirmModal({
          open: false,
          modalTitle: "",
          message: ``,
          colors: GUEST_COLOR,
          actionMethod: () => {
            /* 何もしない */
          },
        });
        setLoadingOpen(false);
      });
  };

  // バリデーション
  const handleConfirm = () => {
    if (userEditState.name.toString().length === 0) {
      setUpdateUserError(`ユーザー名を入力してください。`);
      return;
    }
    if (userEditState.name.toString().length > DISPLAY_NAME_MAX_LENGTH) {
      setUpdateUserError(
        `ユーザー名は最大${DISPLAY_NAME_MAX_LENGTH}文字です。`
      );
      return;
    }
    const errChar = compareSJISWhiteList([userEditState.name.toString()]);
    if (errChar) {
      setUpdateUserError(
        `当システムで使用できない文字が含まれています。（${errChar}）`
      );
      return;
    }
    setConfirmModal({
      open: true,
      modalTitle: "確認",
      message: `ユーザー情報を更新しますか？`,
      colors: GUEST_COLOR,
      actionMethod: handleToCompletePage,
    });
  };

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Dashboard
        title="設定"
        color={GUEST_COLOR.base}
        backgroundColor={GUEST_COLOR.primary}
        iconName="setting"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {updateUserError && <AlertMessage errorMessage={updateUserError} />}
          {fetchError && <AlertMessage errorMessage={fetchError} />}
          {!fetchError && userEditState.userName && (
            <form className={classes.form}>
              {/* ユーザー編集フォーム */}
              <GuestSettingUserEditForm
                userEditState={userEditState}
                setUserEditState={setUserEditState}
              />
              {/* 更新するボタン */}
              <Button
                type="button"
                onClick={handleConfirm}
                className={clsx(buttonClasses.button, classes.addButton)}
                variant="contained"
                disabled={loadingOpen} // 二重送信対策
              >
                <CheckIcon className={classes.checkIcon} />
                更新する
              </Button>
            </form>
          )}
        </Container>
      </Dashboard>
    </>
  );
};

export default withRouter(GuestSettingUserEditPage);
