/**
 * JTB側-解約申請編集確認画面
 * メモ：端末解約申請、加盟店解約申請の編集内容の確認を行う画面
 */
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { withRouter, Link, RouteComponentProps } from "react-router-dom";

import clsx from "clsx";
import { Button, Container, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import { Alert, AlertTitle } from "@material-ui/lab";

import { buttonStyles } from "../../../../common/components/styles";
import useContainerStyles from "../../../../templates/styles";

import {
  IUpdateTerminalTerminateDealInput,
  IUpdateCustomerTermiDealInput,
} from "../../../../../api/types";

import { ADMIN_COLOR } from "../../../../../constants/common";

import { GlobalPopupContext } from "../../../../../App";
import formStore from "../../../../../store/formStore";
import getErrorMessage from "../../../../../utils/error";
import { AuthContext } from "../../../../../cognito/AuthContext";
import terminateDeal from "../../../../../api/terminateDeal";

import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import Dashboard from "../../../../templates/Dashboard";
import ConfirmForm from "../../../../form/components/organisms/ConfirmForm";
import TabPanel from "../../../../common/components/atoms/TabPanel";

const useStyles = makeStyles(() => ({
  returnButton: {
    margin: "0 auto",
    paddingTop: 8,
    paddingBottom: 10,
    paddingLeft: 42,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 24,
    },
  },
  addButton: {
    margin: "0 auto",
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 8,
    color: `${ADMIN_COLOR.primary}!important`,
    border: `1px solid ${ADMIN_COLOR.primary}`,
    backgroundColor: `${ADMIN_COLOR.base}!important`,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 16,
    },
    "&:hover": {
      backgroundColor: `${ADMIN_COLOR.btnHover}!important`,
    },
  },
  returnIcon: {
    position: "absolute",
    top: 10,
    left: 31,
  },
  addIcon: {
    position: "absolute",
    top: 8,
    left: 20,
  },
  buttonContainer: {
    margin: "0 auto",
    clear: "both",
    height: 100,
    width: 284,
    marginTop: 20,
  },
  gridButtons: {
    float: "left",
  },
}));

const TerminateDealEditConfirmPage: React.FC<
  RouteComponentProps<{ id: string }>
> = (props: RouteComponentProps<{ id: string }>) => {
  const classes = useStyles();
  const containerClasses = useContainerStyles();
  const moveButtonClasses = buttonStyles({
    width: 130,
    marginTop: 20,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const { history, match } = props;

  const dealId = match.params.id;

  // パンくず
  const breadCrumbs = [
    { name: "TOP", link: "/dealer" },
    { name: "申請詳細", link: `/dealer/deal/termination/${dealId}` },
    { name: "編集", link: `/dealer/deal/termination/${dealId}/edit` },
    { name: "確認", link: "" },
  ];

  // 申請種別に応じた情報を取得（編集用）
  const dealTypeInfo = useMemo(
    () => formStore.getTerminateDealInfoByDealType(true),
    []
  );

  const { setLoading } = useContext(GlobalPopupContext);
  const { authHeader } = useContext(AuthContext);
  const loading = useRef(false);
  const [apiErrorMsg, setApiErrorMsg] = useState("");

  const handleRegister = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      setLoading(true);
      loading.current = true;

      try {
        // POST用データを用意
        const request =
          formStore.getApiRequestDataForEditTerminateDeal("dealer");
        if (!request) {
          setApiErrorMsg(
            "入力内容に問題が発生しました。入力画面に戻り確認してください。"
          );
          setLoading(false);
          return;
        }

        // 解約申請更新（編集）APIを実行
        if (dealTypeInfo.dealType === "端末解約") {
          await terminateDeal.updateTerminalTermiDeal(
            authHeader || "",
            dealId,
            request as IUpdateTerminalTerminateDealInput
          );
        } else if (dealTypeInfo.dealType === "加盟店解約") {
          await terminateDeal.updateCustomerTermiDeal(
            authHeader || "",
            dealId,
            request as IUpdateCustomerTermiDealInput
          );
        }

        setLoading(false);
        loading.current = false;
        history.push(`/dealer/deal/termination/${dealId}/edit/complete`);
      } catch (err) {
        setApiErrorMsg(getErrorMessage(err));
        setLoading(false);
        loading.current = false;
      }
    },
    [authHeader, setLoading, history, dealId, dealTypeInfo]
  );

  return (
    <Dashboard
      title={`${dealTypeInfo.dealType}申請`}
      color={ADMIN_COLOR.base}
      backgroundColor={ADMIN_COLOR.primary}
      iconName="deal"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {apiErrorMsg !== "" && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {apiErrorMsg}
          </Alert>
        )}
        {/* 確認フォーム */}
        <TabPanel value={0} index={0} borderColor={ADMIN_COLOR.primary}>
          <ConfirmForm
            formCategory={dealTypeInfo.formCategory}
            requiredKey="dealer"
            colors={ADMIN_COLOR}
            terminalGridId={0}
          />
        </TabPanel>
        <div className={classes.buttonContainer}>
          {/* 戻るボタン */}
          <Button
            className={clsx(
              moveButtonClasses.button,
              classes.returnButton,
              classes.gridButtons
            )}
            type="button"
            component={Link}
            to={`/dealer/deal/termination/${dealId}/edit`}
            variant="contained"
          >
            <KeyboardReturnIcon className={classes.returnIcon} />
            戻る
          </Button>
          {/* 登録するボタン */}
          <Button
            type="submit"
            onClick={handleRegister}
            className={clsx(moveButtonClasses.button, classes.addButton)}
            variant="contained"
            disabled={loading.current} // 非同期処理が行われている間はボタンを押せないようにする。(二重送信対策)
          >
            <AddIcon className={classes.addIcon} />
            登録する
          </Button>
        </div>
      </Container>
    </Dashboard>
  );
};

export default withRouter(TerminateDealEditConfirmPage);
