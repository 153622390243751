/**
 * 共通-画面のテンプレート
 */
import React from "react";

import clsx from "clsx";
import {
  makeStyles,
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
} from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonIcon from "@material-ui/icons/PersonOutline";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import StoreIcon from "@material-ui/icons/Store";
import PlaylistAddCheck from "@material-ui/icons/PlaylistAddCheck";
import SettingsIcon from "@material-ui/icons/Settings";
import TermsIcon from "@material-ui/icons/LibraryBooksOutlined";
import ReportIcon from "@material-ui/icons/AssessmentOutlined";
import MasterIcon from "@material-ui/icons/PageviewOutlined";
import InfoIcon from "@material-ui/icons/InfoOutlined";

import { FONT } from "../../constants/common";

import MainListItems from "./listItems";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: (props: ColorProps) => ({
    color: props.color,
    backgroundColor: props.backgroundColor,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    margin: "0 auto",
    fontFamily: FONT,
    fontWeight: 600,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    backgroundColor: "#fff",
  },
  // 開発環境(develop)の場合のみ
  contentImage: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    backgroundColor: "#fff",
    background: `url(${window.location.origin}/bgtestForWhite.png) repeat`,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  icon: {
    verticalAlign: "sub",
  },
}));

type ColorProps = {
  color: string;
  backgroundColor: string;
};

interface Props {
  children: React.ReactNode;
  title: string;
  color: string;
  backgroundColor: string;
  iconName?: string;
}

const Dashboard: React.FC<Props> = (props: Props) => {
  const { children, title, color, backgroundColor, iconName } = props;

  const classes = useStyles({ color, backgroundColor });

  const iconView = {
    dashboard: <DashboardIcon className={classes.icon} />, // ダッシュボード
    contractor: <PersonIcon className={classes.icon} />, // 契約者管理
    customer: <StoreIcon className={classes.icon} />, // 加盟店管理
    terminal: <PhonelinkSetupIcon className={classes.icon} />, // 端末管理
    deal: <PlaylistAddCheck className={classes.icon} />, // 申請管理
    setting: <SettingsIcon className={classes.icon} />, // 設定
    terms: <TermsIcon className={classes.icon} />, // 規約確認
    data: <ReportIcon className={classes.icon} />, // 帳票・データ出力
    master: <MasterIcon className={classes.icon} />, // マスタ管理
    guide: <InfoIcon className={classes.icon} />, // 案内板（各種申請）
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {/* アイコン */}
            {iconView[iconName as keyof typeof iconView]}
            {/* 画面タイトル */}
            {` ${title}`}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper),
        }}
      >
        <List>
          {/* サイドバー */}
          <MainListItems />
        </List>
      </Drawer>
      {/* TODO:検証環境でJTB様がマニュアル用の画面画像取るため一時的に開発環境のTESTの背景を外す */}
      {/* <main
        className={
          process.env.REACT_APP_ENV === "development"
            ? classes.contentImage
            : classes.content
        }
      > */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {/* 各画面の中身 */}
        {children}
      </main>
    </div>
  );
};

export default Dashboard;
