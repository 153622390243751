// オブジェクト配列をディープコピーしてキーの配列を返す。
export const cloneArray: <T>(objArray: T[]) => T[] = <T>(objArray: T[]) => {
  const propObjArray = JSON.parse(JSON.stringify(objArray));
  const array: T[] = Object.keys(propObjArray).map((key) => propObjArray[key]);
  return array;
};

// オブジェクトをディープコピー してそのままオブジェクトを返す。
export const cloneObject: <T>(obj: T) => T = <T>(obj: T) => {
  const propObj: T = JSON.parse(JSON.stringify(obj));
  return propObj;
};
