import {
  formTypeOptions,
  TFormRowData,
} from "../../views/common/components/types";
import { TFixedNumberProcessResult } from "./types";

/**
 * steraターミナルの回線種別選択肢
 */
const STATIONARY_OPTIONS: formTypeOptions[] = [
  { "1": "有線LAN" },
  { "2": "無線LAN" },
];
/**
 * steraモバイルの回線種別選択肢
 */
const MOBILE_OPTIONS: formTypeOptions[] = [{ "3": "LTE" }];

/**
 * 回線種別の選択肢取得
 * @param isStationary
 * @param isMobile
 */
const getCircuitOpt = (isStationary: boolean, isMobile: boolean) => {
  if (isStationary) return STATIONARY_OPTIONS;
  if (isMobile) return MOBILE_OPTIONS;
  return [];
};

/**
 * 端末タイプによる制御
 * @param data: TFormRowData 端末情報フォーム
 * @returns TFixedNumberProcessResult
 */
export const autoCalcByTerminalType: (
  data: TFormRowData
) => TFixedNumberProcessResult = (data: TFormRowData) => {
  // 端末タイプ取得
  const terminalType = data.terminalType[0];
  const isStationary =
    terminalType.content === JSON.stringify({ "01": "steraターミナル" }) ||
    terminalType.content ===
      JSON.stringify({ "01": "stera terminal（据置型）" });
  const isMobile =
    terminalType.content === JSON.stringify({ "02": "steraモバイル" }) ||
    terminalType.content ===
      JSON.stringify({ "02": "stera mobile（ハンディ型）" });

  const ret: TFixedNumberProcessResult = [];

  // 回線種別設定
  const circuitType = data.circuitType[0];
  ret.push({
    column: circuitType.column,
    content: "",
    formTypeOptions: getCircuitOpt(isStationary, isMobile),
  });

  // オプション品（スタイラスペン、POSケーブル）項目設定
  ret.push({
    column: "useStylusPen",
    content: isMobile ? JSON.stringify({ "0": "不要" }) : "",
    disabled: isMobile,
  });
  ret.push({
    column: "POSCable",
    content: isMobile ? JSON.stringify({ "0": "不要" }) : "",
    disabled: isMobile,
  });

  // 設置情報（設置方式、セルフ設置端末送付先）項目設定
  ret.push({
    column: "installationMethod",
    content: isMobile ? JSON.stringify({ "2": "セルフ" }) : "",
    disabled: isMobile,
  });
  ret.push({
    column: "selfInstallationTerminalDestinationAddress",
    content: isMobile ? JSON.stringify({ "1": "加盟店店舗" }) : "",
    disabled: isMobile,
  });

  return ret;
};

export default autoCalcByTerminalType;
