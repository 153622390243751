/**
 * 共通-ログインの見出し
 */
import React from "react";

import { makeStyles, Typography } from "@material-ui/core";

import { ColorBackColorInt } from "../types";

import { FONT } from "../../../../constants/common";

const useStyles = makeStyles({
  heading: (props: ColorBackColorInt) => ({
    color: props.color,
    textAlign: "center",
    fontSize: "1.2em",
    paddingBottom: 10,
    marginBottom: 20,
    fontFamily: FONT,
  }),
});

interface Props {
  title: string;
  colors: ColorBackColorInt;
}

const LoginHeading: React.FC<Props> = (props: Props) => {
  const { title, colors } = props;

  const classes = useStyles({
    color: colors.color,
    backgroundColor: colors.backgroundColor,
  });

  return (
    <Typography variant="h1" className={classes.heading}>
      {title}
    </Typography>
  );
};

export default LoginHeading;
