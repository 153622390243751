import React, { useMemo } from "react";
import formStore from "../../../../store/formStore";
import { TFormCategory, TRequiredKey } from "../../../common/components/types";
import { ThemeColorProps } from "../../../../constants/common";
import ChangeDealConfirmFormGroup from "../molecules/ChangeDealConfirmFormGroup";

type Props = {
  formCategory: TFormCategory;
  requiredKey: TRequiredKey;
  colors: ThemeColorProps;
  terminalGridId: number | undefined;
};
const ChangeDealConfirmForm: React.FC<Props> = (props: Props) => {
  const { formCategory, requiredKey, colors, terminalGridId } = props;

  const formGroupIds = useMemo(
    () => formStore.getFilteredFormGroupNameIds(formCategory),
    [formCategory]
  );

  return (
    <div>
      {formGroupIds.map((id, index) => (
        <ChangeDealConfirmFormGroup
          key={id}
          formGroupNameId={id}
          requiredKey={requiredKey}
          terminalGridId={terminalGridId}
          idx={index}
          colors={colors}
        />
      ))}
    </div>
  );
};

export default ChangeDealConfirmForm;
