/**
 * 加盟店側-申請詳細画面
 * メモ：申請詳細画面を表示する。ワークフローステータスの進行操作もここから行う
 */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Container, makeStyles, Tab, Tabs } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import StoreIcon from "@material-ui/icons/Store";
import TouchAppIcon from "@material-ui/icons/TouchApp";

import { useContainerStyles } from "../../../../templates/styles";

import {
  TDealTypeStr,
  TTerminalFormData,
  TDealTypeNum,
} from "../../../../common/components/types";

import { FONT, GUEST_COLOR } from "../../../../../constants/common";
import { TWorkflowStatus } from "../../../../../constants/workflowStatus";
import DEAL_FORM_TAB_NAMES, {
  FORM_CATEGORY,
} from "../../../../../constants/form";
import { DEAL_TYPE_NUM_TO_STR } from "../../../../../constants/deal";

import { GlobalPopupContext } from "../../../../../App";
import { AuthContext } from "../../../../../cognito/AuthContext";
import deal from "../../../../../api/deal";
import preloadFormContent from "../../../../../api/utils/preUtils";
import { getErrorMessage } from "../../../../../utils/error";
import {
  convIndustrySmallCode,
  json2FormData,
} from "../../../../../utils/formData";
import formStore from "../../../../../store/formStore";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import TabPanel from "../../../../common/components/atoms/TabPanel";
import ConfirmForm from "../../../../form/components/organisms/ConfirmForm";
import NewForm from "../../../../form/components/organisms/NewForm";
import TerminalForm from "../../../../form/components/organisms/TerminalForm";
import DealShowWorkflow from "../../../../common/components/organisms/DealShowWorkflow";
import DealShowButtonList from "../organisms/DealShowButtonList";

const breadCrumbs = [
  { name: "TOP", link: "/guest" },
  { name: "申請詳細", link: "" },
];

const useStyles = makeStyles(() => ({
  settingIcon: {
    verticalAlign: "top",
    left: -2,
    top: 1,
    position: "relative",
  },
  Icon: {
    verticalAlign: "top",
    left: -2,
    top: 1,
    position: "relative",
  },
  tabs: {
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: GUEST_COLOR.primary,
      color: GUEST_COLOR.base,
    },
  },
  tab: {
    color: GUEST_COLOR.primary,
    border: `3px solid ${GUEST_COLOR.primary}`,
    marginRight: 4,
    minHeight: 35,
    borderBottom: "none",
    fontFamily: FONT,
    opacity: 1,
    borderRadius: "8px 8px 0px 0px",
    "& .MuiTab-wrapper": {
      display: "inline!important",
    },
    "& .MuiTab-labelIcon": {
      minHeight: 35,
    },
  },
  indicator: {
    display: "none",
  },
  message: {
    fontSize: "0.9em",
    fontWeight: "bold",
    fontFamily: FONT,
    color: GUEST_COLOR.primary,
    marginTop: 20,
    marginBottom: 10,
  },
}));

const DealShowPage: React.FC<RouteComponentProps<{ id: string }>> = (
  props: RouteComponentProps<{ id: string }>
) => {
  const { match } = props;

  const { authHeader } = useContext(AuthContext);
  const { setLoading } = useContext(GlobalPopupContext);
  const [dealType, setDealType] = useState<TDealTypeStr | undefined>();
  const [workflowStatus, setWorkflowStatus] = useState<TWorkflowStatus>(10);
  const [tabValue, setTabValue] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  // ID類は初回API取得以降はページ遷移なしに変化しないのでRefを使う
  const dealId = useRef(match.params.id);
  const contractorId = useRef(0);

  const [isShowCheckSheet, setIsShowCheckSheet] = useState(false);

  const classes = useStyles();
  const containerClasses = useContainerStyles();

  useEffect(() => {
    const api = async () => {
      try {
        // 申請詳細情報取得API
        const res = await deal.show(authHeader || "", dealId.current);
        // formMaster取得API
        const jsonRes = await deal.getFormMaster(
          authHeader || "",
          res.data.result.formVersion
        );
        await preloadFormContent(authHeader || "", res.data.result.formVersion);
        // formMasterを整形して保存
        const formData = json2FormData(jsonRes.data.result);
        formStore.setFormData(
          formData.formData,
          formData.formGroupData,
          formData.columnToCategory
        );
        // 契約者情報を反映
        formStore.updateContents(
          res.data.result.contractor.contractorForm,
          "customer"
        );
        // 加盟店情報を反映
        const smallClass = convIndustrySmallCode(
          res.data.result.customer.customerForm
        );
        formStore.updateContents(
          {
            ...res.data.result.customer.customerForm,
            industrySmallClass: smallClass,
          },
          "customer"
        );
        // 端末情報を反映
        // グリッドがある場合は先に足りない分の枠を作成する
        const terminalDataNum = res.data.result.customer.terminals.length;
        const terminalJsonNum = formData.formData[3].length;
        for (let i = terminalJsonNum; i < terminalDataNum; i += 1) {
          formStore.terminalGridCopy(0);
        }
        res.data.result.customer.terminals.forEach(
          (terminal: { dealTerminalForm: TTerminalFormData }, idx: number) => {
            formStore.updateContents(
              terminal.dealTerminalForm,
              "customer",
              idx
            );
          }
        );

        // 申請基本情報を反映
        formStore.updateContents(
          res.data.result.dealOtherForm || {},
          "customer"
        );

        // フォームの情報を利用して表示制御しているようなのでフォームの情報が保存された後に実行されるようにする
        setIsShowCheckSheet(formStore.isShowChackSheet());

        // その他を反映
        contractorId.current = res.data.result.contractorId;
        setDealType(
          DEAL_TYPE_NUM_TO_STR[res.data.result.dealType as TDealTypeNum]
        );
        setWorkflowStatus(
          Number(res.data.result.workflowStatus) as TWorkflowStatus
        );

        // 以下は申請編集用にセット
        formStore.setContractorId(res.data.result.contractorId);
        formStore.setEditDealType(
          DEAL_TYPE_NUM_TO_STR[res.data.result.dealType as TDealTypeNum]
        );
        formStore.setEditFormVersion(res.data.result.formVersion);
        formStore.setMasterCustomerId(
          res.data.result.customer.masterCustomerId
        );
        formStore.setMasterCustomerFormId(
          res.data.result.customer.customerForm.id
        );
        formStore.setDealOtherFormId(res.data.result.dealOtherForm.id);
        const terminalIds = res.data.result.customer.terminals.map(
          (t: { id: number; dealTerminalForm: TTerminalFormData }) => {
            return { id: t.id, formId: t.dealTerminalForm.id };
          }
        );
        formStore.setTerminalIds(terminalIds);

        setLoading(false);
      } catch (err) {
        setErrorMessage(getErrorMessage(err));
        setLoading(false);
      }
    };
    setLoading(true);
    api();
    // eslint-disable-next-line
  }, [setLoading]);

  const updateDataApi = useCallback(async () => {
    // 加盟店側は承認ないはずなので必要ないはず
    // 必要なら処理追加
  }, []);

  const handleChange = useCallback((_, newValue: number) => {
    setTabValue(newValue);
  }, []);

  if (!dealType) return null;

  return (
    <Dashboard
      title="申請管理"
      color={GUEST_COLOR.base}
      backgroundColor={GUEST_COLOR.primary}
      iconName="deal"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        {/* ワークフローステータス表示欄 */}
        <DealShowWorkflow
          dealId={dealId.current}
          dealType={dealType}
          workflowStatus={workflowStatus}
          colors={GUEST_COLOR}
          urlPrefix="guest"
          updateDeal={updateDataApi}
        />
        {/* 文言 */}
        <div className={classes.message}>
          {`タブをクリックすることで「${DEAL_FORM_TAB_NAMES.CONTRACTOR_INFO}」、「${DEAL_FORM_TAB_NAMES.CUSTOMER_INFO}」、「${DEAL_FORM_TAB_NAMES.TERMINAL_INFO}」`}
          {isShowCheckSheet && `、「${DEAL_FORM_TAB_NAMES.IMPORTANT_INFO}」`}
          {`、「${DEAL_FORM_TAB_NAMES.VARIOUS_OPERATION}」が表示されます。`}
        </div>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          className={classes.tabs}
          classes={{ indicator: classes.indicator }}
        >
          {/* 契約者情報タブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.CONTRACTOR_INFO}`}
            id="simple-tab-0"
            aria-labelledby="simple-tab-0"
            icon={<BusinessCenterIcon className={classes.Icon} />}
            className={classes.tab}
          />
          {/* 加盟店情報タブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.CUSTOMER_INFO}`}
            id="simple-tab-1"
            aria-labelledby="simple-tab-1"
            icon={<StoreIcon className={classes.Icon} />}
            className={classes.tab}
          />
          {/* 端末情報タブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.TERMINAL_INFO}`}
            id="simple-tab-2"
            aria-labelledby="simple-tab-2"
            icon={<PhonelinkSetupIcon className={classes.Icon} />}
            className={classes.tab}
          />
          {/* 重要事項タブ */}
          {isShowCheckSheet && (
            <Tab
              label={` ${DEAL_FORM_TAB_NAMES.IMPORTANT_INFO}`}
              id="simple-tab-3"
              aria-labelledby="simple-tab-3"
              icon={<CheckBoxIcon className={classes.settingIcon} />}
              className={classes.tab}
            />
          )}
          {/* 各種操作タブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.VARIOUS_OPERATION}`}
            id="simple-tab-6"
            aria-labelledby="simple-tab-6"
            icon={<TouchAppIcon className={classes.settingIcon} />}
            className={classes.tab}
          />
        </Tabs>
        <form>
          {/* 契約者情報 */}
          <TabPanel
            value={tabValue}
            index={0}
            borderColor={GUEST_COLOR.primary}
          >
            <ConfirmForm
              formCategory={FORM_CATEGORY.CONTRACTOR} // 契約者情報
              requiredKey="customer"
              colors={GUEST_COLOR}
              terminalGridId={undefined}
            />
          </TabPanel>
          {/* 加盟店情報 */}
          <TabPanel
            value={tabValue}
            index={1}
            borderColor={GUEST_COLOR.primary}
          >
            <ConfirmForm
              formCategory={FORM_CATEGORY.CUSTOMER} // 加盟店情報
              requiredKey="customer"
              colors={GUEST_COLOR}
              terminalGridId={undefined}
            />
          </TabPanel>
          {/* 端末情報 */}
          <TabPanel
            value={tabValue}
            index={2}
            borderColor={GUEST_COLOR.primary}
          >
            <TerminalForm
              type="confirm"
              requiredKey="customer"
              colors={GUEST_COLOR}
            />
          </TabPanel>
          {/* 重要事項 */}
          {isShowCheckSheet && (
            <TabPanel
              value={tabValue}
              index={3}
              borderColor={GUEST_COLOR.primary}
            >
              {/* 重要事項は表示形式が異なるためNewFormを使用、入力画面ではないのでrequiredKeyをdealerにして非アクティブにしておく */}
              <NewForm
                formCategory={FORM_CATEGORY.CHECK_SHEET} // 重要事項チェックシート
                requiredKey="dealer"
                colors={GUEST_COLOR}
                terminalGridId={undefined}
              />
            </TabPanel>
          )}
          {/* 各種操作 */}
          <TabPanel
            value={tabValue}
            index={3 + (isShowCheckSheet ? 1 : 0)}
            borderColor={GUEST_COLOR.primary}
          >
            {/* 各操作ボタン一覧 */}
            <DealShowButtonList dealId={parseInt(dealId.current, 10)} />
          </TabPanel>
        </form>
      </Container>
    </Dashboard>
  );
};

export default withRouter(DealShowPage);
