/**
 * 共通-精算明細書確認タグ
 * メモ：「済」か「未」か
 */
import React from "react";

import { Chip, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  chip: {
    marginRight: "10px",
  },
}));

interface Props {
  isDone: boolean;
}

const BillStatusChip: React.FC<Props> = (props: Props) => {
  const { isDone } = props;

  const classes = useStyles();

  return (
    <>
      {isDone ? (
        <Chip
          label="済"
          color="primary"
          variant="outlined"
          className={classes.chip}
        />
      ) : (
        <Chip
          label="未"
          color="secondary"
          variant="outlined"
          className={classes.chip}
        />
      )}
    </>
  );
};

export default BillStatusChip;
