/**
 * 端末管理関連の設定値
 */

/**
 * 端末種別のプルダウンリスト
 */
export const TERMINAL_TYPE_LIST = new Map<string, string>([
  ["stera terminal（据置型）", '{"01":"stera terminal（据置型）"}'],
  ["stera mobile（ハンディ型）", '{"02":"stera mobile（ハンディ型）"}'],
]);

/**
 * 端末稼働状態
 */
export const TERMINAL_OPERATING_STATUS = {
  /** 審査前 */
  DEFAULT: "0",
  /** ファイル出力済 */
  FILE_OUTPUT: "1",
  /** 変更申請中 */
  CHANGING: "2",
  /** 解約申請中 */
  TERMINATING: "3",
  /** 端末解約済 */
  TERMINATED: "4",
} as const;

/**
 * 端末稼働状態の名称
 */
export const TERMINAL_OPERATING_STATUS_NAME: { [key: string]: string } = {
  [TERMINAL_OPERATING_STATUS.DEFAULT]: "審査前", // 0: 審査前
  [TERMINAL_OPERATING_STATUS.FILE_OUTPUT]: "ファイル出力済", // 1: ファイル出力済
  [TERMINAL_OPERATING_STATUS.CHANGING]: "変更申請中", // 2: 変更申請中
  [TERMINAL_OPERATING_STATUS.TERMINATING]: "解約申請中", // 3: 解約申請中
  [TERMINAL_OPERATING_STATUS.TERMINATED]: "端末解約済", // 4: 端末解約済
};

/**
 * 画面に表示する端末稼働状態のステータス
 * @member 2: 変更申請中
 * @member 3: 解約申請中
 * @member 4: 端末解約済
 */
export const DISPLAY_TERMINAL_OPERATING_STATUS: string[] = [
  TERMINAL_OPERATING_STATUS.CHANGING,
  TERMINAL_OPERATING_STATUS.TERMINATING,
  TERMINAL_OPERATING_STATUS.TERMINATED,
];
