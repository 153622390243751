/**
 * JTB側-パスワードリセット画面
 * メモ：パスワードをリセットする。完了後はログイン画面へ遷移する。
 */
import React, { useContext, useState } from "react";
import { withRouter, RouteComponentProps, useLocation } from "react-router-dom";

import clsx from "clsx";
import { Paper, Button, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import TouchAppIcon from "@material-ui/icons/TouchApp";

import {
  buttonStyles,
  loginStyles,
} from "../../../../common/components/styles";

import { PasswordResetItemObject } from "../../types";

import { ADMIN_COLOR } from "../../../../../constants/common";

import { AuthContext } from "../../../../../cognito/AuthContext";

import AlertMessage from "../../../../common/components/atoms/AuthAlertMsg";
import Loading from "../../../../common/components/molecules/Loading";
import LogoForLogin from "../../../../common/components/atoms/LogoForLogin";
import DealerPasswordResetForm from "../organisms/DealerPasswordResetForm";

const useStyles = makeStyles(() => ({
  div: {
    backgroundColor: "#f1f1f1",
    height: "100%",
  },
  // 開発環境(develop)の場合のみ
  divImage: {
    background: `url(${window.location.origin}/bgtestForGree.png) repeat`,
    backgroundColor: "#f1f1f1",
    height: "100%",
  },
  touchAppButton: {
    marginTop: 40,
    paddingLeft: 45,
    paddingBottom: 10,
  },
  touchAppIcon: {
    position: "absolute",
    top: 6,
    left: 30,
  },
}));

const DealerPasswordResetPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const classes = useStyles();
  const loginClasses = loginStyles({
    backgroundColor: ADMIN_COLOR.primary,
    color: ADMIN_COLOR.base,
  });
  const buttonClasses = buttonStyles({
    width: 240,
    marginTop: 40,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const { history } = props;
  const { state } = useLocation(); // ユーザーIDを受け取る

  const { resetPassword } = useContext(AuthContext);
  const [error, setError] = useState(<div />);
  const [loadingOpen, setLoadingOpen] = useState(false);

  const passwordResetItem: PasswordResetItemObject = {
    username: state?.username || "", // 確認コードを送信するボタンから遷移してきた場合はユーザーIDを初期値として設定
    code: "",
    newPassword: "",
  };
  const [passwordResetState, setPasswordResetState] =
    useState(passwordResetItem);

  const handleToTopPage = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    setLoadingOpen(true);

    // CognitoAuthProviderのパスワードリセットメソッドを実行
    // try-catchはメソッド内でハンドリングしてるので不要。
    const { result, message } = await resetPassword(passwordResetState);

    setLoadingOpen(false);
    // 成功した場合、ログイン画面へ遷移
    if (result) {
      setError(<div />);
      history.push("/dealer");
    }
    setError(<AlertMessage errorMessage={message} />);
  };

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      {/* TODO:検証環境でJTB様がマニュアル用の画面画像取るため一時的に開発環境のTESTの背景を外す */}
      {/* <div
        className={
          process.env.REACT_APP_ENV === "development"
            ? classes.divImage
            : classes.div
        }
      > */}
      <div className={classes.div}>
        <Paper className={loginClasses.paper} elevation={0}>
          {/* ロゴ */}
          <LogoForLogin />
          {/* エラーメッセージ */}
          {error}
          <form>
            <div className={loginClasses.innerForm}>
              {/* 文言 */}
              <div className={loginClasses.alert}>
                <Alert severity="info">
                  パスワードは大小アルファベット、数字を組み合わせた12文字以上で入力してください。
                </Alert>
              </div>
              {/* 入力フォーム */}
              <DealerPasswordResetForm
                passwordResetState={passwordResetState}
                setPasswordResetState={setPasswordResetState}
              />
              {/* パスワード決定ボタン */}
              <Button
                type="submit"
                onClick={handleToTopPage}
                className={clsx(
                  buttonClasses.button,
                  loginClasses.button,
                  classes.touchAppButton
                )}
                variant="contained"
                disabled={loadingOpen} // 二重送信対策
              >
                <TouchAppIcon className={classes.touchAppIcon} />
                パスワードを決定する
              </Button>
            </div>
          </form>
        </Paper>
      </div>
    </>
  );
};

export default withRouter(DealerPasswordResetPage);
