/**
 * 加盟店側-新パスワード入力画面
 * メモ：ログイン成功後ダッシュボードへ遷移する。
 */
import React, { useContext, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import clsx from "clsx";
import { Paper, Button, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import TouchAppIcon from "@material-ui/icons/TouchApp";

import {
  buttonStyles,
  loginStyles,
} from "../../../../common/components/styles";

import { NewPasswordItemObject } from "../../types";

import { GUEST_COLOR } from "../../../../../constants/common";

import { AuthContext } from "../../../../../cognito/AuthContext";

import AlertMessage from "../../../../common/components/atoms/AuthAlertMsg";
import Loading from "../../../../common/components/molecules/Loading";
import LogoForLogin from "../../../../common/components/atoms/LogoForLogin";
import GuestRequireNewPasswordForm from "../organisms/GuestRequireNewPasswordForm";

const useStyles = makeStyles(() => ({
  div: { height: "100%" },
  // 開発環境(develop)の場合のみ
  divImage: {
    background: `url(${window.location.origin}/bgtestForGree.png) repeat`,
    height: "100%",
  },
  touchAppButton: {
    marginTop: 40,
    paddingLeft: 45,
    paddingBottom: 10,
  },
  touchAppIcon: {
    position: "absolute",
    top: 6,
    left: 30,
  },
}));

const GuestRequireNewPasswordPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const classes = useStyles();
  const loginClasses = loginStyles({
    backgroundColor: GUEST_COLOR.primary,
    color: GUEST_COLOR.base,
  });
  const buttonClasses = buttonStyles({
    width: 240,
    marginTop: 40,
    backgroundColor: GUEST_COLOR.primary,
  });

  const { history } = props;

  const { renewDefaultPassword, challengeName } = useContext(AuthContext);
  const [error, setError] = useState(<div />);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const passwordNotification = (() => {
    return "初回パスワード変更のため";
  })();

  const newPasswordItem: NewPasswordItemObject = {
    newPassword: "",
    newPasswordConfirm: "",
  };
  const [newPasswordState, setNewPasswordState] = useState(newPasswordItem);

  const handleRenewPassword = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    setLoadingOpen(true);

    // CognitoAuthProviderの新パスワード登録メソッドを実行
    // try-catchはメソッド内でハンドリングしてるので不要。
    const { result, message, isCustomer } = await (async () => {
      return renewDefaultPassword({
        newPassword: newPasswordState.newPassword,
        newPasswordConfirm: newPasswordState.newPasswordConfirm,
      });
    })();

    setLoadingOpen(false);
    if (result) {
      setError(<div />);
      if (isCustomer === null) {
        // 商談担当/加盟店情報が取得できなかった場合（想定外のエラーなど）
        setError(
          <AlertMessage errorMessage="想定外のエラーが発生しました。しばらくしてから再度お試しください。" />
        );
        return;
      }
      // 成功した場合、ダッシュボードへ遷移
      const path = isCustomer ? "/guest" : "/dealer";
      history.push(path);
      return;
    }
    setError(<AlertMessage errorMessage={message} />);
  };

  // リロードなどでchallengeNameがundefinedになったらログインページへ戻す
  if (!challengeName) {
    history.push("/guest/login");
  }

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      {/* TODO:検証環境でJTB様がマニュアル用の画面画像取るため一時的に開発環境のTESTの背景を外す */}
      {/* <div
        className={
          process.env.REACT_APP_ENV === "development"
            ? classes.divImage
            : classes.div
        }
      > */}
      <div className={classes.div}>
        <Paper className={loginClasses.paper} elevation={0}>
          {/* ロゴ */}
          <LogoForLogin />
          {/* エラーメッセージ */}
          {error}
          <form>
            <div className={loginClasses.innerForm}>
              {/* 文言 */}
              <div className={loginClasses.alert}>
                <Alert severity="info">
                  新しいパスワードを入力してください。({passwordNotification})
                </Alert>
              </div>
              {/* 入力フォーム */}
              <GuestRequireNewPasswordForm
                newPasswordState={newPasswordState}
                setNewPasswordState={setNewPasswordState}
              />
              {/* パスワード決定ボタン */}
              <Button
                type="submit"
                onClick={handleRenewPassword}
                className={clsx(
                  buttonClasses.button,
                  loginClasses.button,
                  classes.touchAppButton
                )}
                variant="contained"
                disabled={loadingOpen} // 二重送信対策
              >
                <TouchAppIcon className={classes.touchAppIcon} />
                パスワードを決定する
              </Button>
            </div>
          </form>
        </Paper>
      </div>
    </>
  );
};

export default withRouter(GuestRequireNewPasswordPage);
