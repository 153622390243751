/**
 * 加盟店側-加盟店管理画面
 * メモ：加盟店管理系画面の親コンポーネント
 */
import React from "react";
import { Route, Switch } from "react-router-dom";

import CustomerPage from "./pages/CustomerPage";
import CustomerShowPage from "./pages/CustomerShowPage";

const CustomerHooks: React.FC = () => {
  return (
    <>
      <Switch>
        {/* 加盟店検索 */}
        <Route path="/guest/customer" render={() => <CustomerPage />} exact />
        {/* 加盟店詳細 */}
        <Route
          path="/guest/customer/:id"
          render={() => <CustomerShowPage />}
          exact
        />
      </Switch>
    </>
  );
};

export default CustomerHooks;
