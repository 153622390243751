import { TFormRowData } from "../../views/common/components/types";
import { TFixedNumberProcessResult } from "./types";

/**
 * 設置方式による制御
 * @param data: TFormRowData 端末情報フォーム
 * @returns TFixedNumberProcessResult
 */
export const autoCalcByInstallationMethod: (
  data: TFormRowData
) => TFixedNumberProcessResult = (data: TFormRowData) => {
  const installationMethod = data.installationMethod[0];
  const ret: TFixedNumberProcessResult = [];
  // 未選択の場合は空文字
  if (!installationMethod.content) return ret;

  const isVisit =
    installationMethod.content === JSON.stringify({ "1": "訪問" });
  // 設置情報（セルフ設置端末送付先）項目設定
  ret.push({
    column: "selfInstallationTerminalDestinationAddress",
    content: isVisit ? "" : JSON.stringify({ "1": "加盟店店舗" }),
    disabled: !!installationMethod.content,
  });

  return ret;
};

export default autoCalcByInstallationMethod;
