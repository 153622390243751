/**
 * JTB側-新規申請登録画面
 * メモ：端末新規申請の場合、契約者情報は入力不可。端末増設申請の場合、契約者情報、加盟店情報は入力不可。
 */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { withRouter, Link, RouteComponentProps } from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles, Tabs, Tab } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CheckIcon from "@material-ui/icons/Check";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
// 2023 / 7/13 下書き機能を一時廃止
// import DeleteIcon from "@material-ui/icons/Delete";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import StoreIcon from "@material-ui/icons/Store";

import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import {
  TDealTypeStr,
  TJsonRowObject,
} from "../../../../common/components/types";

import {
  DEAL_FORM_TAB_NAMES,
  FORM_CATEGORY,
} from "../../../../../constants/form";
import { ADMIN_COLOR, FONT } from "../../../../../constants/common";

import { GlobalPopupContext, PathContext } from "../../../../../App";
import contractors from "../../../../../api/contractors";
import customer from "../../../../../api/customer";
import deal from "../../../../../api/deal";
import preloadFormContent from "../../../../../api/utils/preUtils";
import { getErrorMessage } from "../../../../../utils/error";
import {
  getContractorContents,
  getCustomerContents,
  json2FormData,
} from "../../../../../utils/formData";
import formStore from "../../../../../store/formStore";
import { AuthContext } from "../../../../../cognito/AuthContext";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import TabPanel from "../../../../common/components/atoms/TabPanel";
import NewForm from "../../../../form/components/organisms/NewForm";
import ConfirmForm from "../../../../form/components/organisms/ConfirmForm";
import TerminalForm from "../../../../form/components/organisms/TerminalForm";

const useStyles = makeStyles(() => ({
  attention: {
    fontSize: "0.7rem",
    textAlign: "right",
    fontFamily: FONT,
    marginTop: 3,
  },
  returnButton: {
    margin: "0 auto",
    paddingTop: 8,
    paddingBottom: 10,
    paddingLeft: 42,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 24,
    },
  },
  checkButton: {
    margin: "0 auto",
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 8,
    color: `${ADMIN_COLOR.primary}!important`,
    border: `1px solid ${ADMIN_COLOR.primary}`,
    backgroundColor: `${ADMIN_COLOR.base}!important`,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 16,
    },
    "&:hover": {
      backgroundColor: `${ADMIN_COLOR.btnHover}!important`,
    },
  },
  returnIcon: {
    position: "absolute",
    top: 10,
    left: 31,
  },
  checkIcon: {
    position: "absolute",
    top: 8,
    left: 20,
  },
  deleteIcon: {
    position: "absolute",
    top: 8,
    left: 20,
  },
  deleteButton: {
    margin: "0 0 0 auto",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 28,
  },
  deleteButtonContainer: {
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 1,
  },
  messageContainer: {
    position: "relative",
    minHeight: 80,
  },
  settingIcon: {
    verticalAlign: "top",
    left: -2,
    top: 1,
    position: "relative",
  },
  Icon: {
    verticalAlign: "top",
    left: -2,
    top: 1,
    position: "relative",
  },
  tabs: {
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: ADMIN_COLOR.primary,
      color: ADMIN_COLOR.base,
    },
  },
  tab: {
    color: ADMIN_COLOR.primary,
    border: `3px solid ${ADMIN_COLOR.primary}`,
    marginRight: 4,
    minHeight: 35,
    borderBottom: "none",
    fontFamily: FONT,
    opacity: 1,
    borderRadius: "8px 8px 0px 0px",
    "& .MuiTab-wrapper": {
      display: "inline!important",
    },
    "& .MuiTab-labelIcon": {
      minHeight: 35,
    },
  },
  indicator: {
    display: "none",
  },
  message: {
    fontSize: "0.9em",
    fontWeight: "bold",
    fontFamily: FONT,
    color: ADMIN_COLOR.primary,
    marginBottom: 15,
  },
  buttonContainer: {
    margin: "0 auto",
    clear: "both",
    height: 100,
    width: 284,
    marginTop: 20,
  },
  gridButtons: {
    float: "left",
  },
}));

const DealNewPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const { history } = props;

  const { authHeader } = useContext(AuthContext);
  // 2023 / 7/13 下書き機能を一時廃止
  const { setLoading, setSnackbar /* , setConfirmModal */ } =
    useContext(GlobalPopupContext);
  const { prevPath } = useContext(PathContext);
  const [init, setInit] = useState(false);
  const [errorMessage, setError] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [dealType] = useState<TDealTypeStr>(
    formStore.getDealType() || "端末新規"
  );
  const [isShowCheckSheet, setShowCheckSheet] = useState(() =>
    formStore.isShowChackSheet()
  );

  const returnPage = useMemo(() => {
    switch (dealType) {
      case "端末新規":
        return { url: "/dealer/contractor", name: "契約者管理・検索" };
      case "端末増設":
        return { url: "/dealer/customer", name: "加盟店管理・検索" };
      default:
        return { url: "/dealer", name: "TOP" };
    }
  }, [dealType]);
  const breadCrumbs = useMemo(
    () => [
      { name: returnPage.name, link: returnPage.url },
      { name: `${dealType}申請`, link: "" },
    ],
    [dealType, returnPage]
  );

  const getJson = useCallback(async () => {
    if (!authHeader) return;
    setLoading(true);
    try {
      if (prevPath.includes("/dealer/deal/confirm")) {
        // 確認画面からの遷移時はAPIで取り直さない
        setError("");
        setLoading(false);
        setInit(true);
      } else {
        // Json取得
        const ret = await deal.getFormMaster(authHeader, "latest");
        // presetForm.json取得&マッピングデータ化
        await preloadFormContent(authHeader, "latest");

        const data = json2FormData(ret.data.result as TJsonRowObject[]);
        formStore.setFormData(
          data.formData,
          data.formGroupData,
          data.columnToCategory
        );

        const contractorId = formStore.getContractorId();
        const customerId = formStore.getCustomerId();

        if (contractorId !== null) {
          // 契約者情報取得API
          const contractorData = await contractors.show(
            authHeader,
            contractorId.toString()
          );
          formStore.updateContents(
            getContractorContents(
              contractorData.data.result.contractors[0].masterContractorForms
            ).updateData,
            "dealer"
          );
        }
        if (customerId !== null && dealType === "端末増設") {
          // 加盟店情報取得API
          const customerData = await customer.show(
            authHeader,
            customerId.toString()
          );
          formStore.updateContents(
            getCustomerContents(
              customerData.data.result.masterCustomers[0].masterCustomerForms
            ).updateData,
            "dealer"
          );
        }

        setError("");
        setLoading(false);
        setInit(true);
      }
      // eslint-disable-next-line
    } catch (error: any) {
      setError(getErrorMessage(error));
      setLoading(false);
      setInit(true);
    }
  }, [setLoading, authHeader, dealType, prevPath]);

  const classes = useStyles();
  // 2023 / 7/13 下書き機能を一時廃止
  // const deleteDraftButtonClasses = buttonStyles({
  //   width: 180,
  //   marginTop: 20,
  //   backgroundColor: ADMIN_COLOR.primary,
  // });
  const moveButtonClasses = buttonStyles({
    width: 130,
    marginTop: 20,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const containerClasses = useContainerStyles();

  // Jsonデータ取得
  useEffect(() => {
    const fn = () => {
      setShowCheckSheet(formStore.isShowChackSheet());
    };

    // 端末増設の場合は加盟店IDも含める
    const key = `deal-${formStore.getContractorId()}${
      formStore.getCustomerId() ? `-${formStore.getCustomerId()}` : ""
    }`;
    const save = () => {
      formStore.saveDraft(key);
    };

    getJson();

    // 加盟店分類で表示タブが変わるので
    formStore.addListener("customerType", fn);
    formStore.addListener("updatedContent", save);
    return () => {
      formStore.removeListener("customerType", fn);
      formStore.removeListener("updatedContent", save);
    };
    // eslint-disable-next-line
  }, []);

  const handleChange = useCallback((_, newValue: number) => {
    setTabValue(newValue);
  }, []);

  // 2023 / 7/13 下書き機能を一時廃止
  // const handleDeleteDraft = useCallback(() => {
  //   setConfirmModal({
  //     open: true,
  //     modalTitle: "下書きが全て削除されますがよろしいですか？",
  //     colors: ADMIN_COLOR,
  //     actionMethod: () => {
  //       formStore.deleteDraft(`deal-${formStore.getContractorId()}`);
  //       setConfirmModal({
  //         open: false,
  //         modalTitle: "",
  //         colors: ADMIN_COLOR,
  //         actionMethod: () => ({}),
  //       });
  //       history.push(returnPage.url);
  //     },
  //   });
  // }, [history, setConfirmModal, returnPage]);

  const handleToConfirmPage = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    // ボタンクリック以外（エンターキー押下など）の場合は何もしない
    if (e.detail !== 1) return;

    // バリデーション実行
    if (formStore.validationAll("dealer")) {
      history.push("/dealer/deal/confirm");
    } else {
      // エラーのあるタブに移動
      const category = formStore.getFocusErrorCategory();
      if (category > 0) {
        setTabValue(category - 1);
      }
      setSnackbar({
        openProps: true,
        message: "入力不備のフィールドがあります。",
      });
    }
  };

  return (
    <Dashboard
      title={`${dealType}申請`}
      color={ADMIN_COLOR.base}
      backgroundColor={ADMIN_COLOR.primary}
      iconName="deal"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        {init && (
          <>
            <div className={classes.messageContainer}>
              {/* 文言 */}
              <div className={classes.message}>
                {`タブをクリックすることで「${DEAL_FORM_TAB_NAMES.CONTRACTOR_INFO}」、「${DEAL_FORM_TAB_NAMES.CUSTOMER_INFO}」、「${DEAL_FORM_TAB_NAMES.TERMINAL_INFO}」`}
                {isShowCheckSheet &&
                  `、「${DEAL_FORM_TAB_NAMES.IMPORTANT_INFO}」`}
                の各フォームが表示されます。
              </div>
              {/* 2023 / 7/13 下書き機能を一時廃止 */}
              {/* 下書き削除ボタン */}
              {/* <div className={classes.deleteButtonContainer}>
                <Button
                  type="button"
                  onClick={handleDeleteDraft}
                  className={clsx(
                    deleteDraftButtonClasses.button,
                    classes.deleteButton
                  )}
                  variant="contained"
                >
                  <DeleteIcon className={classes.deleteIcon} />
                  下書き削除
                </Button>
                <div className={classes.attention}>
                  下書きが全て削除されます。
                  <br />
                  ファイル添付項目は下書き保存されませんので再添付をお願いします。
                  <br />
                  長期間保存されたデータは、お申込みができない場合があります。
                  <br />
                  その場合は下書きを削除の上、再入力をお願いします。
                </div>
              </div> */}
            </div>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              indicatorColor="primary"
              className={classes.tabs}
              classes={{ indicator: classes.indicator }}
            >
              {/* 契約者情報タブ */}
              <Tab
                label={` ${DEAL_FORM_TAB_NAMES.CONTRACTOR_INFO}`}
                id="simple-tab-0"
                aria-labelledby="simple-tab-0"
                icon={<BusinessCenterIcon className={classes.Icon} />}
                className={classes.tab}
              />
              {/* 加盟店情報タブ */}
              <Tab
                label={` ${DEAL_FORM_TAB_NAMES.CUSTOMER_INFO}`}
                id="simple-tab-1"
                aria-labelledby="simple-tab-1"
                icon={<StoreIcon className={classes.Icon} />}
                className={classes.tab}
              />
              {/* 端末情報タブ */}
              <Tab
                label={` ${DEAL_FORM_TAB_NAMES.TERMINAL_INFO}`}
                id="simple-tab-2"
                aria-labelledby="simple-tab-2"
                icon={<PhonelinkSetupIcon className={classes.Icon} />}
                className={classes.tab}
              />
              {/* 重要事項タブ */}
              {isShowCheckSheet && (
                <Tab
                  label={` ${DEAL_FORM_TAB_NAMES.IMPORTANT_INFO}`}
                  id="simple-tab-3"
                  aria-labelledby="simple-tab-3"
                  icon={<CheckBoxIcon className={classes.settingIcon} />}
                  className={classes.tab}
                />
              )}
            </Tabs>
            <form>
              {/* 契約者情報 */}
              <TabPanel
                value={tabValue}
                index={0}
                borderColor={ADMIN_COLOR.primary}
              >
                <ConfirmForm
                  formCategory={FORM_CATEGORY.CONTRACTOR} // 契約者情報
                  requiredKey="dealer"
                  colors={ADMIN_COLOR}
                  terminalGridId={undefined}
                />
              </TabPanel>
              {/* 加盟店情報 */}
              <TabPanel
                value={tabValue}
                index={1}
                borderColor={ADMIN_COLOR.primary}
              >
                {/* 端末増設申請の場合は既存加盟店情報があるので、編集不可 */}
                {dealType === "端末新規" ? (
                  <NewForm
                    formCategory={FORM_CATEGORY.CUSTOMER} // 加盟店情報
                    requiredKey="dealer"
                    colors={ADMIN_COLOR}
                    terminalGridId={undefined}
                  />
                ) : (
                  <ConfirmForm
                    formCategory={FORM_CATEGORY.CUSTOMER} // 加盟店情報
                    requiredKey="dealer"
                    colors={ADMIN_COLOR}
                    terminalGridId={undefined}
                  />
                )}
              </TabPanel>
              {/* 端末情報 */}
              <TabPanel
                value={tabValue}
                index={2}
                borderColor={ADMIN_COLOR.primary}
              >
                <TerminalForm
                  type="new"
                  requiredKey="dealer"
                  colors={ADMIN_COLOR}
                />
              </TabPanel>
              {/* 重要事項 */}
              {isShowCheckSheet && (
                <TabPanel
                  value={tabValue}
                  index={3}
                  borderColor={ADMIN_COLOR.primary}
                >
                  <NewForm
                    formCategory={FORM_CATEGORY.CHECK_SHEET} // 重要事項チェックシート
                    requiredKey="dealer"
                    colors={ADMIN_COLOR}
                    terminalGridId={undefined}
                  />
                </TabPanel>
              )}
              <div className={classes.buttonContainer}>
                {/* 戻るボタン */}
                <Button
                  className={clsx(
                    moveButtonClasses.button,
                    classes.returnButton,
                    classes.gridButtons
                  )}
                  type="button"
                  component={Link}
                  to={returnPage.url}
                  variant="contained"
                >
                  <KeyboardReturnIcon className={classes.returnIcon} />
                  戻る
                </Button>
                {/* 確認するボタン */}
                <Button
                  type="submit"
                  onClick={handleToConfirmPage}
                  className={clsx(
                    moveButtonClasses.button,
                    classes.checkButton
                  )}
                  variant="contained"
                >
                  <CheckIcon className={classes.checkIcon} />
                  確認する
                </Button>
              </div>
            </form>
          </>
        )}
      </Container>
    </Dashboard>
  );
};

export default withRouter(DealNewPage);
