/**
 * JTB側-ログイン画面
 * メモ：2段階認証コード入力画面へ遷移する。（初回ログインの場合は新パスワード入力画面へ遷移）
 */
import React, { useContext, useEffect, useState } from "react";
import { withRouter, Link, RouteComponentProps } from "react-router-dom";

import clsx from "clsx";
import { Paper, Button, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import {
  buttonStyles,
  loginStyles,
  loginFormStyles,
} from "../../../../common/components/styles";

import { LoginItemObject } from "../../types";

import { ADMIN_COLOR } from "../../../../../constants/common";

import { AuthContext } from "../../../../../cognito/AuthContext";
import cognitoListener from "../../../../../cognito/CognitoListener";

import LoginHeading from "../../../../common/components/atoms/LoginHeading";
import InformationAlert from "../../../../common/components/organisms/InformationAlert";
import LogoForLogin from "../../../../common/components/atoms/LogoForLogin";
import AlertMessage from "../../../../common/components/atoms/AuthAlertMsg";
import DealerLoginForm from "../organisms/DealerLoginForm";
import { GlobalPopupContext } from "../../../../../App";
import { CUSTOMER_USER_NAME_PATTERN } from "../../../../../constants/user";

const useStyles = makeStyles(() => ({
  div: {
    backgroundColor: "#f1f1f1",
    height: "100%",
  },
  // 開発環境(develop)の場合のみ
  divImage: {
    background: `url(${window.location.origin}/bgtestForGree.png) repeat`,
    backgroundColor: "#f1f1f1",
    height: "100%",
  },
  exitToAppButton: {
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 10,
    paddingLeft: 30,
  },
  exitToAppIcon: {
    position: "absolute",
    top: 8,
    left: 30,
  },
  linkIcon: {
    position: "relative",
    top: 8,
    left: 5,
    fontSize: "1.8em",
    color: ADMIN_COLOR.primary,
  },
}));

const DealerLoginPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const classes = useStyles();
  const loginClasses = loginStyles({
    backgroundColor: ADMIN_COLOR.primary,
    color: ADMIN_COLOR.base,
  });
  const buttonClasses = buttonStyles({
    width: 180,
    marginTop: 20,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const loginFormClasses = loginFormStyles();

  const { history } = props;

  const { signIn, loginError, setLoginError } = useContext(AuthContext);
  const { setLoading } = useContext(GlobalPopupContext);
  const [expiredError, setExpiredError] = useState(<div />);

  const loginItem: LoginItemObject = {
    username: "",
    password: "",
  };
  const [loginState, setLoginState] = useState(loginItem);

  const handleSignIn = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    // Material-UIのボタンは、e.preventDefault入れないと、強制画面遷移(同じ画面でも同じ画面に遷移)で、画面全体がDOMマウントから再レンダーされてしまう。
    e.preventDefault();

    // ユーザー名でJTB側ユーザーかチェック
    if (loginState.username.includes(CUSTOMER_USER_NAME_PATTERN)) {
      // 加盟店側ユーザーの場合はエラー
      setLoginError(
        <AlertMessage errorMessage="間違った画面でのログインです。正しい画面よりログインしてください。" />
      );
      return;
    }

    setLoading(true);
    try {
      // CognitoAuthProviderのログインメソッドを実行
      const { result, isCustomer, newPasswordRequired, mfaRequired } =
        await signIn(loginState);

      // コンソールの赤ログ対応
      // 遷移するためsetLoadingOpen(false)しない（遷移後に呼ばれメモリリークしてるっぽい）

      if (result) {
        if (mfaRequired) {
          // 2段階認証が必要な場合、2段階認証コード入力画面に遷移
          setLoading(false);
          history.push("/dealer/login/require-mfa");
          return;
        }
        if (newPasswordRequired) {
          // パスワード変更が必要な場合、新パスワード入力画面に遷移
          setLoading(false);
          history.push("/dealer/login/require-new-password");
          return;
        }
        if (isCustomer === null) {
          // 上記画面へ遷移以外で明示的にnullが帰ってきた場合は想定外のエラーが発生している
          setLoading(false);
          setLoginError(
            <AlertMessage errorMessage="想定外のエラーが発生しました。しばらくしてから再度お試しください。" />
          );
          return;
        }

        // 成功した場合、ダッシュボードに遷移
        // JTB側ユーザーは2段階認証が必要なためここで遷移することはなさそうだが念の為残しておく
        const path = "/dealer";
        setLoading(false);
        history.push(path);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    // トークン期限切れの場合
    if (cognitoListener.getExpiredTokenFlag()) {
      setExpiredError(
        <AlertMessage errorMessage="一定時間操作がありませんでしたのでログアウトしました。恐れ入りますが再度ログインください。" />
      );
      setLoading(false, true);
      cognitoListener.setExpiredTokenFlag(false); // フラグをfalseにする。
    }
  }, [setLoading]);

  const clearError = () => {
    if (loginError) {
      setLoginError(<div />);
    }
    if (expiredError) {
      setExpiredError(<div />);
    }
  };

  return (
    <>
      {/* TODO:検証環境でJTB様がマニュアル用の画面画像取るため一時的に開発環境のTESTの背景を外す */}
      {/* <div
        className={
          process.env.REACT_APP_ENV === "development"
            ? classes.divImage
            : classes.div
        }
      > */}
      <div className={classes.div}>
        <Paper className={loginClasses.paper} elevation={0}>
          {/* ロゴ */}
          <LogoForLogin />
          {/* タイトル */}
          <LoginHeading
            title="申込Webログイン(担当者用)"
            colors={{
              color: ADMIN_COLOR.grid,
              backgroundColor: ADMIN_COLOR.primary,
            }}
          />
          {/* お知らせ */}
          <div className={loginClasses.alert}>
            <InformationAlert delay={0} />
          </div>
          {/* TODO:エラーの出力方法はあとでちゃんと考える */}
          {Object.keys(expiredError.props).length !== 0
            ? expiredError
            : loginError}
          <form>
            <div className={loginClasses.innerForm}>
              {/* 入力フォーム */}
              <DealerLoginForm
                loginState={loginState}
                setLoginState={setLoginState}
              />
              {/* ログインボタン */}
              <Button
                type="submit"
                onClick={handleSignIn}
                className={clsx(
                  buttonClasses.button,
                  loginClasses.button,
                  classes.exitToAppButton
                )}
                variant="contained"
              >
                <ExitToAppIcon className={classes.exitToAppIcon} />
                ログイン
              </Button>
              {/* パスワードリセットリンク */}
              <Link
                to="/dealer/login/confirm-cd"
                onClick={clearError}
                className={loginFormClasses.link}
              >
                パスワードをリセットする
                <KeyboardArrowRightIcon className={classes.linkIcon} />
              </Link>
            </div>
          </form>
          {/* 注意文言 */}
          <div className={loginClasses.alert}>
            <Alert variant="outlined" severity="info">
              ユーザーの新規作成は、ログイン後の設定画面にて、各会社様の管理者ユーザーによって追加が可能です。
            </Alert>
          </div>
        </Paper>
        {/* 動作環境確認リンク */}
        <div className={loginClasses.environmentLink}>
          <Link
            to="/environment"
            className={loginFormClasses.link}
            onClick={clearError}
          >
            動作環境については こちらをご確認ください。
          </Link>
        </div>
      </div>
    </>
  );
};

export default withRouter(DealerLoginPage);
