// 指定したファイルをbase64化する。
// readAsDataURLは非同期関数なのでPromiseを戻り値として返す。
export const getBase64: (file: File) => Promise<string | ArrayBuffer | null> = (
  file: File
) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });

// ファイルのフルパスから拡張子なしのファイル名を取得する。
export const getFileName: (fullPath: string) => string = (fullPath: string) =>
  fullPath.split("/").reverse()[0].split(".")[0];

// ファイルのフルパスから拡張子を取得する。
export const getExtension: (fullPath: string) => string = (fullPath: string) =>
  fullPath.split("/").reverse()[0].split(".")[1];

// ファイルのフルパスから拡張子付きのファイル名(xxx.jpgなど)を取得する。
// 現状使用することがない為、一旦コメントアウト
// export const getExtFileName: (fullPath: string) => string = (
//   fullPath: string
// ) => `${getFileName(fullPath)}.${getExtension(fullPath)}`;

// base64 -> blob
export const convBase64toBlob: (base64: string, fileType: string) => Blob = (
  base64: string,
  fileType: string
) => {
  const bin = atob(base64.replace(/^.*,/, ""));
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i += 1) {
    buffer[i] = bin.charCodeAt(i);
  }
  const blob = new Blob([buffer.buffer], {
    type: fileType,
  });
  return blob;
};

export const downloadBlobFile: (blob: Blob, fileName: string) => void = (
  blob: Blob,
  fileName: string
) => {
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = fileName;
  a.click();
};

/**
 * URLからファイルをダウンロードする
 * @param url: URL
 * @param fileName: ファイル名
 */
export const downloadFile: (url: string, fileName: string) => void = (
  url: string,
  fileName: string
) => {
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
};

export default getBase64;
