import file from "../file";

// ファイルデータ
type TFileDataObj = {
  columnNames?: string[];
  data: string;
  name: string;
  fileId?: number;
};

/**
 * ファイルアップロードAPI呼び出し（columnNameとfileIdのマッピングで返却）
 * @param authHeader: 認証トークン
 * @param files: {data: ファイルデータ(base64), name: ファイル名}[]
 * @param fileType: ファイル種別
 * @param dealId?: 申請ID
 * @param masterCustomerId?: マスター加盟店ID
 * @param fileId?: 登録済みファイルの上書きに必要
 */
const getUploadFileApi = (
  authHeader: string,
  files: TFileDataObj[],
  fileType: string,
  dealId?: number,
  masterCustomerId?: number
) => {
  return files.map((f) => {
    // レスポンスのfileIdとcolumnNameのマッピングで返す
    return new Promise<{ columnNames?: string[]; fileId: number }>(
      (resolve, reject) => {
        file
          .upload(
            authHeader,
            f.data,
            f.name,
            fileType,
            dealId,
            masterCustomerId,
            f.fileId
          )
          .then((res) => {
            resolve({
              columnNames: f.columnNames,
              fileId: res.data.result.fileId,
            });
          })
          .catch((err) => {
            reject(err);
          });
      }
    );
  });
};

/**
 * 複数の追加資料アップロードAPI呼び出し
 * @param authHeader: 認証トークン
 * @param files: {data: ファイルデータ(base64), name: ファイル名}[]
 * @param dealId: 申請ID
 * @param masterCustomerId: マスター加盟店ID
 * @param fileId?: 登録済みファイルの上書きに必要
 */
const getUploadAdditionalFileApi = (
  authHeader: string,
  files: TFileDataObj[],
  dealId: number,
  masterCustomerId: number
) => {
  return files.map((f) =>
    file.uploadAdditional(
      authHeader,
      f.data,
      f.name,
      dealId,
      masterCustomerId,
      f.fileId
    )
  );
};

/**
 * 複数の追加資料アップロードAPI呼び出し 契約者変更用
 * @param authHeader: 認証トークン
 * @param files: {data: ファイルデータ(base64), name: ファイル名}[]
 * @param dealId: 申請ID
 * @param contractorId: 契約者ID
 * @param fileId?: 登録済みファイルの上書きに必要
 */
const getUploadAdditionalForContractorFileApi = (
  authHeader: string,
  files: TFileDataObj[],
  dealId: number,
  contractorId: number
) => {
  return files.map((f) =>
    file.uploadAdditionalForContractor(
      authHeader,
      f.data,
      f.name,
      dealId,
      contractorId,
      f.fileId
    )
  );
};

/**
 * ファイルダウンロードAPI呼び出し（formNameIdとファイルのbase64データのマッピングで返却）
 * @param authHeader: 認証トークン
 * @param fileIds: ダウンロードファイルのデータ{formNameId?: number, fileId: number}[]
 */
const getDownloadFileApi = (
  authHeader: string,
  fileIds: { columnName?: string; fileId: string }[]
) => {
  return fileIds.map((id) => {
    return new Promise<{ columnName?: string; file: string }>(
      (resolve, reject) => {
        file
          .download(authHeader, id.fileId)
          .then((res) => {
            resolve({ columnName: id.columnName, file: res.data.result.file });
          })
          .catch((err) => {
            reject(err);
          });
      }
    );
  });
};

export default {
  getUploadFileApi,
  getUploadAdditionalFileApi,
  getUploadAdditionalForContractorFileApi,
  getDownloadFileApi,
};
