import React from "react";
import { IAuthState, initialContext } from "./AuthState";
import { LoginOption } from "./CognitoAuthProvider";

export interface IResetPasswordParams {
  username: string;
  code: string;
  newPassword: string;
}

export interface IRenewDefaultPasswordParams {
  newPassword: string;
  newPasswordConfirm: string;
}

export interface IConfirmMfaCodeParams {
  code: string;
}

export interface ISignInResponse {
  result: boolean;
  isCustomer?: boolean | null;
  newPasswordRequired: boolean;
  mfaRequired: boolean;
}

export interface IChangeEmail {
  newEmail: string;
  newEmailConfirm: string;
}

export interface IConfirmChangeEmail {
  newEmail: string;
  code: string;
}

export interface IChangePassword {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export interface IChangeUserPhoneNum {
  newUserPhoneNum: string;
}

export interface IConfirmChangeUserPhoneNum {
  newUserPhoneNum: string;
  code: string;
}

export interface ISendUserList {
  email: string;
  tid: string;
}

export interface IBaseResponse {
  result: boolean;
  message: string;
  isCustomer?: boolean | null;
}

export interface INewPasswordResponse {
  result: boolean;
  message: string;
  isCustomer?: boolean | null;
  mfaRequired?: boolean;
}

interface IAuthContext extends IAuthState {
  signIn: (signInOption: LoginOption) => Promise<ISignInResponse>;
  sendResetCode: (params: string) => Promise<IBaseResponse>;
  resetPassword: (params: IResetPasswordParams) => Promise<IBaseResponse>;
  renewDefaultPassword: (
    params: IRenewDefaultPasswordParams
  ) => Promise<INewPasswordResponse>;
  confirmMfaCode: (params: IConfirmMfaCodeParams) => Promise<IBaseResponse>;
  changeEmail: (params: IChangeEmail) => Promise<IBaseResponse>;
  confirmChangeEmail: (params: IConfirmChangeEmail) => Promise<IBaseResponse>;
  changePassword: (params: IChangePassword) => Promise<IBaseResponse>;
  changeUserPhoneNum: (params: IChangeUserPhoneNum) => Promise<IBaseResponse>;
  confirmChangeUserPhoneNum: (
    params: IConfirmChangeUserPhoneNum
  ) => Promise<IBaseResponse>;
  signOut: () => void;
  sendUserList: (params: ISendUserList) => Promise<IBaseResponse>;
  setLoginError: React.Dispatch<React.SetStateAction<JSX.Element>>;
  reloadCurrentSession: () => Promise<void>;
}

export const AuthContext = React.createContext<IAuthContext>(initialContext);
