/**
 * 加盟店側-メールアドレス変更フォーム
 * メモ：入力フォームを表示する
 */
import React, { useContext } from "react";

import clsx from "clsx";
import {
  FormControl,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";

import { formStyles } from "../../../../common/components/styles";

import { EmailEditItemObject } from "../../types";

import {
  GUEST_COLOR,
  EMAIL_MAX_LENGTH,
  FONT,
} from "../../../../../constants/common";

import { AuthContext } from "../../../../../cognito/AuthContext";

import CustomTextField from "../../../../common/components/atoms/CustomTextField";

const useStyles = makeStyles(() => ({
  cell: {
    fontFamily: FONT,
  },
  th: {
    width: 300,
  },
  lengthText: {
    color: GUEST_COLOR.base,
    fontFamily: FONT,
    fontSize: "0.7rem",
    fontWeight: 400,
    paddingLeft: 7,
  },
}));

interface Props {
  emailEditState: EmailEditItemObject;
  setEmailEditState: React.Dispatch<React.SetStateAction<EmailEditItemObject>>;
}

const GuestSettingMailEditForm: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const formClasses = formStyles({
    color: GUEST_COLOR.base,
    backgroundColor: GUEST_COLOR.primary,
  });

  const { emailEditState, setEmailEditState } = props;

  const { userAttributes } = useContext(AuthContext);
  const currentEmailAddress = userAttributes?.email;

  return (
    <FormControl className={formClasses.form}>
      <TableContainer component={Paper}>
        <Table className={formClasses.table} aria-label="simple table">
          <TableBody>
            {/* 現在のメールアドレス */}
            <TableRow>
              <TableCell
                align="left"
                className={clsx(formClasses.th, classes.th)}
              >
                現在のメールアドレス
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                {currentEmailAddress}
              </TableCell>
            </TableRow>
            {/* 新しいメールアドレス */}
            <TableRow key="新しいメールアドレス">
              <TableCell
                align="left"
                className={clsx(formClasses.th, classes.th)}
              >
                新しいメールアドレス
                <span
                  className={classes.lengthText}
                >{`(最大${EMAIL_MAX_LENGTH}文字)`}</span>
              </TableCell>
              <TableCell align="left">
                <CustomTextField
                  type="email"
                  value={emailEditState.newEmail}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEmailEditState({
                      ...emailEditState,
                      newEmail: e.target.value,
                    })
                  }
                />
              </TableCell>
            </TableRow>
            {/* 新しいメールアドレス(確認) */}
            <TableRow key="新しいメールアドレス(確認)">
              <TableCell
                align="left"
                className={clsx(formClasses.th, classes.th)}
              >
                新しいメールアドレス(確認)
                <span
                  className={classes.lengthText}
                >{`(最大${EMAIL_MAX_LENGTH}文字)`}</span>
              </TableCell>
              <TableCell align="left">
                <CustomTextField
                  type="email"
                  value={emailEditState.newEmailConfirm}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEmailEditState({
                      ...emailEditState,
                      newEmailConfirm: e.target.value,
                    })
                  }
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </FormControl>
  );
};

export default GuestSettingMailEditForm;
