import axios from "axios";
import {
  createAPIPromise,
  createLoadingPromise,
  createParamStr,
  loadingUrls,
} from "./utils/common";

interface SearchParameters {
  customerCode?: string;
  customerName?: string;
  pageNumber: string;
}

/**
 * 精算明細書検索API
 * @param pageNumber: ページ番号
 * @param authHeader: 認証トークン
 * @param customerCode: 加盟店コード
 * @param customerName: 加盟店名
 * @returns 精算明細書の検索結果リスト
 * */
const search = async (
  pageNumber: string,
  authHeader: string,
  customerCode: string,
  customerName: string
  // eslint-disable-next-line
): Promise<any> => {
  const params: SearchParameters = { pageNumber };
  params.customerCode = customerCode === "" ? undefined : customerCode;
  params.customerName = customerName === "" ? undefined : customerName;

  const path = `/bills`;
  const paramStr = createParamStr(params);
  const loadingKey = `${path}?${paramStr}`;
  if (loadingUrls.includes(loadingKey)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(loadingKey);
  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    loadingKey
  );
};

/**
 * 精算明細書詳細取得API
 * @param authHeader: 認証トークン
 * @param masterCustomerId: マスタ加盟店ID
 * @returns 精算明細書のリスト
 * */
const show = async (
  authHeader: string,
  masterCustomerId: string
  // eslint-disable-next-line
): Promise<any> => {
  const path = `/bills/${masterCustomerId}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    path
  );
};

/**
 * 精算明細書ダウンロードAPI
 * @param authHeader: 認証トークン
 * @param id: 精算明細書ダウンロード情報ID
 * @param fileType: ダウンロードするファイルの種類
 * @returns base64文字列となったファイル
 * */
const download = async (
  authHeader: string,
  id: string,
  fileType: string
  // eslint-disable-next-line
): Promise<any> => {
  const params = { id, fileType };

  const path = `/file/bills`;
  const paramStr = createParamStr(params);
  const loadingKey = `${path}?${paramStr}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(loadingKey);
  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    loadingKey
  );
};

export default {
  search,
  show,
  download,
};
