/**
 * 共通-エラーメッセージ表示
 */
import React from "react";

import { Alert } from "@material-ui/lab";

const AlertMessage: React.FC<{ errorMessage: string }> = (props: {
  errorMessage: string;
}) => {
  const { errorMessage } = props;

  return (
    <>
      <div style={{ marginBottom: "10px", width: 399, margin: "0 auto" }}>
        <Alert variant="outlined" severity="error">
          {errorMessage}
        </Alert>
      </div>
    </>
  );
};
export default AlertMessage;
