import preval from "preval.macro";

export const toTimeStamp: (date: Date) => string = (date: Date) => {
  const y = `${date.getFullYear()}`;
  const M = `00${date.getMonth() + 1}`.slice(-2);
  const d = `00${date.getDate()}`.slice(-2);
  const h = `00${date.getHours()}`.slice(-2);
  const m = `00${date.getMinutes()}`.slice(-2);
  const s = `00${date.getSeconds()}`.slice(-2);

  return `${y}${M}${d}_${h}${m}${s}`;
};

// ビルド日時を取得する。
// 関数の仕様上anyが返るため。
// eslint-disable-next-line
export const getBuildDate: () => any = () =>
  preval`module.exports = new Date().toLocaleString();`;
