// 複数値を省略する
// ... 他x件 を追加
export const toOmittedString: (
  str: string | string[] | undefined,
  length: number
) => string = (str: string | string[] | undefined, length: number) => {
  if (!str) return "";
  const ret = Array.isArray(str) ? str[0] : str;
  // 複数なければ追加文言は付けない
  if (length - 1 <= 0) return ret;

  return `${ret}\n...他${length - 1}件`;
};

// 数字文字列をカンマ区切りにする
export const separateWithComma: (str: string) => string = (str: string) => {
  let numStr = str;

  // 数字文字列がマイナスの場合を考慮
  const minusRegexp = /^-/;
  const isMinus = minusRegexp.test(str);
  // 「−」始まりの場合は取り除いておく
  if (isMinus) {
    numStr = numStr.slice(1);
  }

  // 数字以外の文字列が含まれる場合は空を返す
  const onlyNumRegexp = /^\d*$/;
  if (!onlyNumRegexp.test(numStr)) return "";

  // カンマ挿入
  numStr = numStr.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

  // マイナスの場合は「−」をつけ直す
  if (isMinus) {
    numStr = `-${numStr}`;
  }

  return numStr;
};

/**
 * 加盟店分類の文字列を取得
 * @param {string} customerType - 加盟店分類（オブジェクトの文字列）
 * @returns {string}
 */
export const extractCustomerTypeFromObject = (customerType: string): string =>
  customerType ? String(Object.values(JSON.parse(customerType))) : "-";
