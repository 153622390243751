/**
 * JTB側-自社ユーザー編集画面
 * メモ：ユーザー編集画面を表示する
 */
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps, withRouter, useLocation } from "react-router-dom";

import clsx from "clsx";
import { Button, Container, makeStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

import { buttonStyles } from "../../../../common/components/styles";
import useContainerStyles from "../../../../templates/styles";

import { UserEditItemObject } from "../../types";

import {
  ADMIN_COLOR,
  DISPLAY_NAME_MAX_LENGTH,
  MEMO_MAX_LENGTH,
} from "../../../../../constants/common";
import { USER_TYPE_EDIT_MAP } from "../../../../../constants/user";

import cognitoUser from "../../../../../api/cognitoUser";
import { GlobalPopupContext } from "../../../../../App";
import { AuthContext } from "../../../../../cognito/AuthContext";
import { compareSJISWhiteList } from "../../../../../form/validation";
import getErrorMessage from "../../../../../utils/error";

import AlertMessage from "../../../../common/components/atoms/AuthAlertMsg";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import Loading from "../../../../common/components/molecules/Loading";
import Dashboard from "../../../../templates/Dashboard";
import SettingUserEditForm from "../organisms/SettingUserEditForm";

const breadCrumbs = [
  { name: "設定", link: "/dealer/setting" },
  { name: "自社ユーザー管理・検索", link: "/dealer/setting/user" },
  { name: "自社ユーザー編集", link: "" },
];

const useStyles = makeStyles(() => ({
  addButton: {
    margin: "0 auto",
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 27,
  },
  checkIcon: {
    position: "absolute",
    top: 8,
    left: 31,
  },
  form: {
    width: 800,
    marginTop: 20,
  },
}));

type Props = RouteComponentProps<{ id: string }>;
const SettingUserEditPage: React.FC<Props> = (props: Props) => {
  const buttonClasses = buttonStyles({
    width: 180,
    marginTop: 20,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const containerClasses = useContainerStyles();
  const classes = useStyles();

  const { history, match } = props;

  const { state } = useLocation(); // ユーザーの情報を受け取る
  const { authHeader, reloadCurrentSession, userAttributes } =
    useContext(AuthContext);
  const { setConfirmModal, setSnackbar } = useContext(GlobalPopupContext);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [updateUserError, setUpdateUserError] = useState("");
  const [fetchError, setFetchError] = useState("");

  // ユーザー一覧画面の検索結果から送られてきた値があればそれを初期値とする
  const [userEditState, setUserEditState] = useState<UserEditItemObject>({
    userName: state?.userName || "", // ユーザーID
    name: state?.name || "", // ユーザー名
    userType: state?.userType || "", // ユーザー種別
    memo: state?.memo || "", // メモ欄
    releaseLock: false, // ロック解除
    isLocked: state?.isLocked || false, // ロックされているか
  });

  const handleToCompletePage = async () => {
    setLoadingOpen(true);

    const { id } = match.params;
    // ユーザー種別を名称から番号に変換
    const userType = USER_TYPE_EDIT_MAP[userEditState.userType]
      ? USER_TYPE_EDIT_MAP[userEditState.userType]
      : "";

    await cognitoUser
      .updateUser(
        authHeader as string,
        id,
        userEditState.name,
        userType,
        userEditState.memo,
        userEditState.releaseLock
      )
      .then(async (res) => {
        if (res.data.result) {
          if (
            userAttributes &&
            userEditState.userName === userAttributes["cognito:username"]
          ) {
            // ログインユーザーのアカウントの場合cognitoのSession情報を最新に
            // 更新された情報を取得するため少し遅らせる（500ms）
            await setTimeout(() => reloadCurrentSession(), 500);
          }
          history.push(`/dealer/setting/user`);
          setSnackbar({
            openProps: true,
            message: "ユーザー情報の更新が完了しました。",
            severity: "info",
          });
        }
      })
      .catch((error) => {
        setUpdateUserError(getErrorMessage(error));
      })
      .finally(() => {
        setConfirmModal({
          open: false,
          modalTitle: "",
          message: ``,
          colors: ADMIN_COLOR,
          actionMethod: () => {
            /* 何もしない */
          },
        });
        setLoadingOpen(false);
      });
  };

  const handleConfirm = () => {
    // 必須チェック
    if (userEditState.name === "") {
      setUpdateUserError("ユーザー名を入力してください。");
      return;
    }
    if (userEditState.userType === "") {
      setUpdateUserError("ユーザー種別を入力してください。");
      return;
    }

    // 桁数チェック
    if (userEditState.name.toString().length > DISPLAY_NAME_MAX_LENGTH) {
      setUpdateUserError(
        `ユーザー名は最大${DISPLAY_NAME_MAX_LENGTH}文字です。`
      );
      return;
    }
    if (userEditState.memo.toString().length > MEMO_MAX_LENGTH) {
      setUpdateUserError(`メモ欄は最大${MEMO_MAX_LENGTH}文字です。`);
      return;
    }

    // 使用可能文字チェック
    const errChar = compareSJISWhiteList([
      userEditState.name.toString(),
      userEditState.memo.toString(),
    ]);
    if (errChar) {
      setUpdateUserError(
        `当システムで使用できない文字が含まれています。（${errChar}）`
      );
      return;
    }

    setConfirmModal({
      open: true,
      modalTitle: "確認",
      message: `ユーザー情報を更新しますか？`,
      colors: ADMIN_COLOR,
      actionMethod: handleToCompletePage,
    });
  };

  // state（useLocation）がうまく取得できなかった場合はエラー表示
  useEffect(() => {
    if (!state) {
      setFetchError("ユーザーの情報が取得できませんでした。");
    }
  }, [state]);

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Dashboard
        title="設定"
        color={ADMIN_COLOR.base}
        backgroundColor={ADMIN_COLOR.primary}
        iconName="setting"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {updateUserError && <AlertMessage errorMessage={updateUserError} />}
          {fetchError && <AlertMessage errorMessage={fetchError} />}
          {!fetchError && (
            <form className={classes.form}>
              {/* ユーザー編集フォーム */}
              <SettingUserEditForm
                userEditState={userEditState}
                setUserEditState={setUserEditState}
              />
              {/* 更新するボタン */}
              <Button
                type="button"
                onClick={handleConfirm}
                className={clsx(buttonClasses.button, classes.addButton)}
                variant="contained"
                disabled={loadingOpen} // 二重送信対策
              >
                <CheckIcon className={classes.checkIcon} />
                更新する
              </Button>
            </form>
          )}
        </Container>
      </Dashboard>
    </>
  );
};

export default withRouter(SettingUserEditPage);
