/**
 * 共通-確認タグ
 * メモ：確認済か未確認か
 */
import React, { useEffect, useState } from "react";

import { Chip } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";

interface Props {
  isDone: boolean;
}

const SwitchStatus: React.FC<Props> = (props: Props) => {
  const { isDone } = props;

  const [done, setDone] = useState(false);

  useEffect(() => {
    setDone(isDone);
  }, [isDone]);

  return (
    <>
      {done ? (
        <Chip
          label="確認済"
          clickable
          color="primary"
          variant="outlined"
          deleteIcon={<DoneIcon />}
        />
      ) : (
        <Chip
          label="未確認"
          clickable
          color="secondary"
          variant="outlined"
          deleteIcon={<DoneIcon />}
        />
      )}
    </>
  );
};

export default SwitchStatus;
