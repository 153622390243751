/**
 * 加盟店側-変更申請詳細画面
 * メモ：変更申請詳細画面を表示する。ワークフローステータスの進行操作もここから行う
 */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Container, makeStyles, Tab, Tabs } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import StoreIcon from "@material-ui/icons/Store";
import TouchAppIcon from "@material-ui/icons/TouchApp";

import { useContainerStyles } from "../../../../templates/styles";

import { TDealTypeNum } from "../../../../common/components/types";

import { GUEST_COLOR, FONT } from "../../../../../constants/common";
import DEAL_FORM_TAB_NAMES, {
  FORM_CATEGORY,
} from "../../../../../constants/form";
import {
  TWorkflowStatus,
  WORKFLOW_STATUS,
} from "../../../../../constants/workflowStatus";
import { DEAL_TYPE_NUM_TO_STR, DEAL_TYPE } from "../../../../../constants/deal";

import { GlobalPopupContext } from "../../../../../App";
import formStore from "../../../../../store/formStore";
import deal from "../../../../../api/deal";
import changeDeal from "../../../../../api/changeDeal";
import preloadFormContent from "../../../../../api/utils/preUtils";
import { getErrorMessage } from "../../../../../utils/error";
import {
  convIndustrySmallCode,
  json2FormData,
} from "../../../../../utils/formData";
import { convertToChangeDealContents } from "../../../../../utils/changeDeal";
import { AuthContext } from "../../../../../cognito/AuthContext";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import DealShowWorkflow from "../../../../common/components/organisms/DealShowWorkflow";
import TabPanel from "../../../../common/components/atoms/TabPanel";
import ConfirmForm from "../../../../form/components/organisms/ConfirmForm";
import ChangeOrTerminateDealShowButtonList from "../../../../common/components/organisms/GuestChangeOrTerminateDealShowButtonList";
import DealInfoData from "../../../../common/components/organisms/DealInfoData";
import ChangeDealShowGroup from "../../../../form/components/organisms/ChangeDealShowGroup";

const breadCrumbs = [
  { name: "TOP", link: "/guest" },
  { name: "申請詳細", link: "" },
];

const useStyles = makeStyles(() => ({
  settingIcon: {
    verticalAlign: "top",
    left: -2,
    top: 1,
    position: "relative",
  },
  Icon: {
    verticalAlign: "top",
    left: -2,
    top: 1,
    position: "relative",
  },
  tabs: {
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: GUEST_COLOR.primary,
      color: GUEST_COLOR.base,
    },
  },
  tab: {
    color: GUEST_COLOR.primary,
    border: `3px solid ${GUEST_COLOR.primary}`,
    marginRight: 4,
    minHeight: 35,
    borderBottom: "none",
    fontFamily: FONT,
    opacity: 1,
    borderRadius: "8px 8px 0px 0px",
    "& .MuiTab-wrapper": {
      display: "inline!important",
    },
    "& .MuiTab-labelIcon": {
      minHeight: 35,
    },
  },
  indicator: {
    display: "none",
  },
  message: {
    fontSize: "0.9em",
    fontWeight: "bold",
    fontFamily: FONT,
    color: GUEST_COLOR.primary,
    marginTop: 20,
    marginBottom: 10,
  },
}));

// 申請情報のコンポーネント用
type DealInfoType = {
  applicationDate: string;
  lastApprovalDate: string;
  cancelDateAndTime: string;
  cancelComment: string;
};

const ChangeDealShowPage: React.FC<RouteComponentProps<{ id: string }>> = (
  props: RouteComponentProps<{ id: string }>
) => {
  const { match } = props;

  const { authHeader } = useContext(AuthContext);
  const { setLoading } = useContext(GlobalPopupContext);
  const [workflowStatus, setWorkflowStatus] = useState<TWorkflowStatus>();
  const [firstWorkflowStatus, setFirstWorkflowStatus] =
    useState<TWorkflowStatus>();
  const [dealData, setDealData] = useState<DealInfoType>({} as DealInfoType);
  const [tabValue, setTabValue] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [changeDealContents, setChangeDealContents] = useState<
    ReturnType<typeof convertToChangeDealContents>
  >([]);

  const dealId = useRef(match.params.id);
  const isCancel = workflowStatus === WORKFLOW_STATUS.CANCEL;
  const dealType = formStore.getDealType();

  // 変更申請の種類 & ワークフローステータスによりタブのindexをずらす
  const plusIndex = useMemo(() => {
    if (isCancel) return -2;
    if (dealType === "契約者変更") return 0;
    if (dealType === "加盟店変更") return 1;
    if (dealType === "端末変更") return 2;
    return 0;
  }, [dealType, isCancel]);

  // タブ上説明文
  const tabDescription = useMemo(() => {
    const firstText = "タブをクリックすることで";
    const lastText = "が表示されます。";
    const filteredText = (() => {
      const filteredList = [] as string[];

      if (!isCancel) {
        filteredList.push(
          `${DEAL_FORM_TAB_NAMES.DEAL_CONTENT}`,
          `${DEAL_FORM_TAB_NAMES.CONTRACTOR_INFO}`
        );
        switch (dealType) {
          case "加盟店変更":
            filteredList.push(`${DEAL_FORM_TAB_NAMES.CUSTOMER_INFO}`);
            break;
          case "端末変更":
            filteredList.push(
              `${DEAL_FORM_TAB_NAMES.CUSTOMER_INFO}`,
              `${DEAL_FORM_TAB_NAMES.TERMINAL_INFO}`
            );
            break;
          default:
            break;
        }
      }

      const lastList = [`${DEAL_FORM_TAB_NAMES.VARIOUS_OPERATION}`];

      return [...filteredList, ...lastList]
        .map((item) => item && `「${item}」`)
        .join("、");
    })();

    return firstText + filteredText + lastText;
  }, [dealType, isCancel]);

  const classes = useStyles();
  const containerClasses = useContainerStyles();

  const getDataApi = useCallback(async () => {
    try {
      // 申請詳細情報取得API
      const res = await changeDeal.showChangeDeal(
        authHeader || "",
        dealId.current
      );

      // formMaster取得API
      const jsonRes = await deal.getFormMaster(
        authHeader || "",
        res.data.result.formVersion
      );

      await preloadFormContent(authHeader || "", res.data.result.formVersion);

      // formMasterを整形して保存
      const formData = json2FormData(jsonRes.data.result);
      formStore.setFormData(
        formData.formData,
        formData.formGroupData,
        formData.columnToCategory
      );
      // 契約者情報を反映
      formStore.updateContents(
        res.data.result.contractor.contractorForm.tabFormContent || {},
        "customer"
      );
      // 契約者の変更申請情報を反映
      if (res.data.result.dealType === DEAL_TYPE.CHANGE_CONTRACTOR) {
        setChangeDealContents(
          convertToChangeDealContents(
            res.data.result.contractor.contractorForm.dealFormContent,
            res.data.result.contractor.contractorForm.tabFormContent
          )
        );
        formStore.setCustomerId(null);
      }
      // 加盟店の変更申請情報を反映
      if (res.data.result.dealType === DEAL_TYPE.CHANGE_CUSTOMER) {
        setChangeDealContents(
          convertToChangeDealContents(
            res.data.result.customer.customerForm.dealFormContent,
            res.data.result.customer.customerForm.tabFormContent
          )
        );
      }
      // 加盟店情報を反映 (加盟店変更、端末変更の場合)
      if (
        res.data.result.dealType === DEAL_TYPE.CHANGE_CUSTOMER ||
        res.data.result.dealType === DEAL_TYPE.CHANGE_TERMINAL
      ) {
        const smallClass = convIndustrySmallCode(
          res.data.result.customer.customerForm.tabFormContent
        );
        formStore.updateContents(
          {
            ...res.data.result.customer.customerForm.tabFormContent,
            industrySmallClass: smallClass,
          } || {},
          "customer"
        );
        formStore.setCustomerId(res.data.result.customer.masterCustomerId);
      }
      // 端末情報を反映 (端末変更の場合) マスタデータのため1端末1データなのでグリッドはなし
      if (res.data.result.dealType === DEAL_TYPE.CHANGE_TERMINAL) {
        // 端末識別番号は表示不要のため削除（申請の必須項目のため値が入ってきてしまうため）
        // 端末識別番号は性質上変更不可なので変更対象となることは無い
        delete res.data.result.terminal.terminalForm.dealFormContent
          .termiIdentNum;

        formStore.updateContents(
          res.data.result.terminal.terminalForm.tabFormContent || {},
          "customer"
        );
        // 端末情報の変更申請情報を反映
        setChangeDealContents(
          convertToChangeDealContents(
            res.data.result.terminal.terminalForm.dealFormContent,
            res.data.result.terminal.terminalForm.tabFormContent
          )
        );
        formStore.setCustomerId(null);
        formStore.setMasterTerminalId(
          res.data.result.terminal.masterTerminalId
        );
        formStore.setTermiIdentNum(
          res.data.result.terminal.terminalForm.tabFormContent.termiIdentNum
        );
      }

      // 申請基本情報を反映
      formStore.updateContents(res.data.result.dealOtherForm || {}, "customer");

      // その他を反映
      formStore.setDealType(
        DEAL_TYPE_NUM_TO_STR[res.data.result.dealType as TDealTypeNum]
      );
      formStore.setFormComment(res.data.result.comment);
      setWorkflowStatus(
        Number(res.data.result.workflowStatus) as TWorkflowStatus
      );
      setFirstWorkflowStatus(
        Number(res.data.result.firstWorkflowStatus) as TWorkflowStatus
      );

      // 申請情報反映
      setDealData({
        applicationDate: res.data.result.dealOtherForm.applicationDate,
        lastApprovalDate: res.data.result.lastApprovalDate,
        cancelDateAndTime: res.data.result.dealOtherForm.cancelDateAndTime,
        cancelComment: res.data.result.dealOtherForm.cancelComment,
      });

      // 以下は申請編集用にセット
      formStore.setContractorId(res.data.result.contractorId);
      formStore.setEditDealType(
        DEAL_TYPE_NUM_TO_STR[res.data.result.dealType as TDealTypeNum]
      );
      formStore.resetChangeableColumnNames();
      setLoading(false);
    } catch (err) {
      setErrorMessage(getErrorMessage(err));
      setLoading(false);
    }
  }, [authHeader, setLoading]);

  // 申請を更新する処理
  const updateDataApi = useCallback(async () => {
    // 加盟店側は承認ないはずなので必要ないはず
    // 必要なら処理追加
  }, []);

  useEffect(() => {
    setLoading(true);
    getDataApi();
    // eslint-disable-next-line
  }, [setLoading]);

  const handleChange = useCallback((_, newValue: number) => {
    setTabValue(newValue);
  }, []);

  if (!dealType) return null;

  return (
    <Dashboard
      title="申請管理"
      color={GUEST_COLOR.base}
      backgroundColor={GUEST_COLOR.primary}
      iconName="deal"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        {/* ワークフローステータス表示欄 */}
        {workflowStatus && (
          <DealShowWorkflow
            dealId={dealId.current}
            dealType={dealType}
            workflowStatus={workflowStatus}
            colors={GUEST_COLOR}
            urlPrefix="guest"
            updateDeal={updateDataApi}
            firstWorkflowStatus={firstWorkflowStatus}
          />
        )}
        {/* 申請情報 */}
        <DealInfoData
          applicationDate={dealData.applicationDate}
          lastApprovalDate={dealData.lastApprovalDate}
          cancelDateAndTime={dealData.cancelDateAndTime}
          cancelComment={dealData.cancelComment}
        />
        {/* 文言 */}
        <div className={classes.message}>{tabDescription}</div>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          className={classes.tabs}
          classes={{ indicator: classes.indicator }}
        >
          {/* 申請内容タブ */}
          {!isCancel && (
            <Tab
              label={` ${DEAL_FORM_TAB_NAMES.DEAL_CONTENT}`}
              id="simple-tab-0"
              aria-labelledby="simple-tab-0"
              icon={<BusinessCenterIcon className={classes.Icon} />}
              className={classes.tab}
            />
          )}
          {/* 契約者情報タブ */}
          {!isCancel && (
            <Tab
              label={` ${DEAL_FORM_TAB_NAMES.CONTRACTOR_INFO}`}
              id="simple-tab-1"
              aria-labelledby="simple-tab-1"
              icon={<BusinessCenterIcon className={classes.Icon} />}
              className={classes.tab}
            />
          )}
          {/* 加盟店情報タブ */}
          {!isCancel &&
            (dealType === "加盟店変更" || dealType === "端末変更") && (
              <Tab
                label={` ${DEAL_FORM_TAB_NAMES.CUSTOMER_INFO}`}
                id="simple-tab-2"
                aria-labelledby="simple-tab-2"
                icon={<StoreIcon className={classes.Icon} />}
                className={classes.tab}
              />
            )}
          {/* 端末情報タブ */}
          {!isCancel && dealType === "端末変更" && (
            <Tab
              label={` ${DEAL_FORM_TAB_NAMES.TERMINAL_INFO}`}
              id="simple-tab-3"
              aria-labelledby="simple-tab-3"
              icon={<PhonelinkSetupIcon className={classes.Icon} />}
              className={classes.tab}
            />
          )}
          {/* 各種操作タブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.VARIOUS_OPERATION}`}
            id="simple-tab-5"
            aria-labelledby="simple-tab-5"
            icon={<TouchAppIcon className={classes.settingIcon} />}
            className={classes.tab}
          />
        </Tabs>
        <form>
          {/* 申請内容 */}
          {!isCancel && (
            <TabPanel
              value={tabValue}
              index={0}
              borderColor={GUEST_COLOR.primary}
            >
              {workflowStatus && (
                <ChangeDealShowGroup
                  changeDealContents={changeDealContents}
                  colors={GUEST_COLOR}
                  requiredKey="customer"
                  workflowStatus={workflowStatus}
                />
              )}
            </TabPanel>
          )}
          {/* 契約者情報 */}
          {!isCancel && (
            <TabPanel
              value={tabValue}
              index={1}
              borderColor={GUEST_COLOR.primary}
            >
              <ConfirmForm
                formCategory={FORM_CATEGORY.CONTRACTOR} // 契約者情報
                requiredKey="customer"
                colors={GUEST_COLOR}
                terminalGridId={undefined}
              />
            </TabPanel>
          )}
          {/* 加盟店情報 */}
          {(dealType === "加盟店変更" || dealType === "端末変更") && (
            <TabPanel
              value={tabValue}
              index={2}
              borderColor={GUEST_COLOR.primary}
            >
              <ConfirmForm
                formCategory={FORM_CATEGORY.CUSTOMER} // 加盟店情報
                requiredKey="customer"
                colors={GUEST_COLOR}
                terminalGridId={undefined}
              />
            </TabPanel>
          )}
          {/* 端末情報  */}
          {dealType === "端末変更" && (
            <TabPanel
              value={tabValue}
              index={3}
              borderColor={GUEST_COLOR.primary}
            >
              <ConfirmForm
                formCategory={FORM_CATEGORY.TERMINAL} // 端末情報
                requiredKey="customer"
                colors={GUEST_COLOR}
                terminalGridId={undefined}
              />
            </TabPanel>
          )}
          {/* 各種操作 */}
          <TabPanel
            value={tabValue}
            index={2 + plusIndex}
            borderColor={GUEST_COLOR.primary}
          >
            {/* 各操作ボタン一覧 */}
            <ChangeOrTerminateDealShowButtonList
              dealId={parseInt(dealId.current, 10)}
            />
          </TabPanel>
        </form>
      </Container>
    </Dashboard>
  );
};

export default withRouter(ChangeDealShowPage);
