/**
 * 加盟店側-解約申請入力画面
 * メモ：端末解約申請、加盟店解約申請を行う画面
 */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  withRouter,
  RouteComponentProps,
  Link,
  useLocation,
} from "react-router-dom";

import clsx from "clsx";
import { Container, makeStyles, Button } from "@material-ui/core";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import CheckIcon from "@material-ui/icons/Check";
import { Alert, AlertTitle } from "@material-ui/lab";

import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import {
  TJsonRowObject,
  TFormColumn,
} from "../../../../common/components/types";

import { GUEST_COLOR } from "../../../../../constants/common";

import { GlobalPopupContext, PathContext } from "../../../../../App";
import formStore from "../../../../../store/formStore";
import deal from "../../../../../api/deal";
import { AuthContext } from "../../../../../cognito/AuthContext";
import { json2FormData } from "../../../../../utils/formData";
import getErrorMessage from "../../../../../utils/error";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import TabPanel from "../../../../common/components/atoms/TabPanel";
import NewForm from "../../../../form/components/organisms/NewForm";

const useStyles = makeStyles(() => ({
  returnButton: {
    margin: "0 auto",
    paddingTop: 8,
    paddingBottom: 10,
    paddingLeft: 42,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 24,
    },
  },
  checkButton: {
    margin: "0 auto",
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 8,
    color: `${GUEST_COLOR.primary}!important`,
    border: `1px solid ${GUEST_COLOR.primary}`,
    backgroundColor: `${GUEST_COLOR.base}!important`,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 16,
    },
    "&:hover": {
      backgroundColor: `${GUEST_COLOR.btnHover}!important`,
    },
  },
  returnIcon: {
    position: "absolute",
    top: 10,
    left: 31,
  },
  checkIcon: {
    position: "absolute",
    top: 8,
    left: 20,
  },
  buttonContainer: {
    margin: "0 auto",
    clear: "both",
    height: 100,
    width: 284,
    marginTop: 20,
  },
  gridButtons: {
    float: "left",
  },
}));

const GuestTerminateDealNewPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const classes = useStyles();
  const containerClasses = useContainerStyles();
  const moveButtonClasses = buttonStyles({
    width: 130,
    marginTop: 20,
    backgroundColor: GUEST_COLOR.primary,
  });

  const { history } = props;
  const { state } = useLocation(); // 詳細画面から自動入力する値を受け取る

  // 申請種別に応じた情報を取得
  const dealTypeInfo = useMemo(
    () => formStore.getTerminateDealInfoByDealType(),
    []
  );
  const breadCrumbs = useMemo(
    () => [
      { name: dealTypeInfo.pageName, link: `/guest${dealTypeInfo.pageUrl}` },
      {
        name: "詳細",
        link: `/guest${dealTypeInfo.showPageUrl}`,
      },
      { name: "解約申請入力", link: "" },
    ],
    [dealTypeInfo]
  );

  const { setSnackbar, setLoading } = useContext(GlobalPopupContext);
  const { authHeader } = useContext(AuthContext);
  const { prevPath } = useContext(PathContext);
  const [init, setInit] = useState(false);
  const [error, setError] = useState("");

  const getJson = useCallback(async () => {
    if (!authHeader) return;
    setLoading(true);

    try {
      if (prevPath.includes("/guest/deal/termination/confirm")) {
        // 確認画面からの遷移時はAPIで取り直さない
        setError("");
        setLoading(false);
        setInit(true);
      } else {
        // 解約用のJSONを取得
        const ret = await deal.getFormMaster(authHeader, "latest", true);
        // 端末、加盟店それぞれの解約申請に必要なもののみ抽出して変換
        const data = json2FormData(
          ret.data.result.filter(
            (r: TJsonRowObject) =>
              r.formCategory === dealTypeInfo.formCategory && r.columnName
          ) as TJsonRowObject[]
        );
        // formStoreに保存
        formStore.setFormData(
          data.formData,
          data.formGroupData,
          data.columnToCategory,
          true
        );
        // 詳細画面から受け取った値をセット
        Object.keys(state || {}).forEach((columnName) => {
          formStore.updateContent(
            columnName as TFormColumn,
            state[columnName] || "",
            "customer"
          );
        });
        setError("");
        setLoading(false);
        setInit(true);
      }
      // eslint-disable-next-line
    } catch (err: any) {
      setError(getErrorMessage(err));
      setLoading(false);
      setInit(true);
    }
  }, [authHeader, setLoading, prevPath, state, dealTypeInfo]);

  // Jsonデータ取得
  useEffect(() => {
    getJson();
    // eslint-disable-next-line
  }, []);

  const handleToConfirmPage = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    // ボタンクリック以外（エンターキー押下など）の場合は何もしない
    if (e.detail !== 1) return;

    // バリデーション実行
    if (
      formStore.validationFormCategory("customer", dealTypeInfo.formCategory)
    ) {
      history.push("/guest/deal/termination/confirm");
    } else {
      setSnackbar({
        openProps: true,
        message: "入力不備のフィールドがあります。",
      });
    }
  };

  return (
    <Dashboard
      title={`${dealTypeInfo.dealType}申請`}
      color={GUEST_COLOR.base}
      backgroundColor={GUEST_COLOR.primary}
      iconName="deal"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {error && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        )}
        {!error && init && (
          <form>
            {/* 入力フォーム */}
            <TabPanel value={0} index={0} borderColor={GUEST_COLOR.primary}>
              <NewForm
                formCategory={dealTypeInfo.formCategory}
                requiredKey="customer"
                colors={GUEST_COLOR}
                terminalGridId={0}
              />
            </TabPanel>
            <div className={classes.buttonContainer}>
              {/* 戻るボタン */}
              <Button
                className={clsx(
                  moveButtonClasses.button,
                  classes.returnButton,
                  classes.gridButtons
                )}
                type="button"
                component={Link}
                to={`/guest${dealTypeInfo.showPageUrl}`}
                variant="contained"
              >
                <KeyboardReturnIcon className={classes.returnIcon} />
                戻る
              </Button>
              {/* 確認するボタン */}
              <Button
                type="submit"
                onClick={handleToConfirmPage}
                className={clsx(moveButtonClasses.button, classes.checkButton)}
                variant="contained"
              >
                <CheckIcon className={classes.checkIcon} />
                確認する
              </Button>
            </div>
          </form>
        )}
      </Container>
    </Dashboard>
  );
};

export default withRouter(GuestTerminateDealNewPage);
