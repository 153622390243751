/**
 * JTB側-精算明細書検索・一覧画面
 * メモ：各加盟店の直近1ヶ月の精算明細書を表示する。精算明細書のダウンロードも可能
 */
import React, { useContext, useState } from "react";

import clsx from "clsx";
import { Container, makeStyles, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";

import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { BillSearchCondition } from "../../types";
import { IBillSearchResponse } from "../../../../../api/types";

import { ADMIN_COLOR } from "../../../../../constants/common";

import bill from "../../../../../api/bill";
import { AuthContext } from "../../../../../cognito/AuthContext";
import { getErrorMessage } from "../../../../../utils/error";

import Loading from "../../../../common/components/molecules/Loading";
import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import BillSearchConditionForm from "../organisms/BillSearchConditionForm";
import BillSearchResult from "../organisms/BillSearchResult";

const breadCrumbs = [
  { name: "帳票・データ出力", link: "/dealer/data" },
  { name: "精算明細書検索・一覧", link: "" },
];

const useStyles = makeStyles(() => ({
  alert: {
    marginTop: "30px",
  },
  form: {
    width: 800,
  },
  searchButton: {
    paddingBottom: 8,
    margin: "0 auto",
    marginTop: 20,
  },
  searchIcon: {
    position: "absolute",
    top: 8,
    left: 50,
  },
}));

const DataBillPage: React.FC = () => {
  const classes = useStyles();
  const containerClasses = useContainerStyles();
  const searchButtonClasses = buttonStyles({
    width: 180,
    marginTop: 15,
    textLeft: 10,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const { authHeader } = useContext(AuthContext);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [searchApiErrorMsg, setSearchApiErrorMsg] = useState<string>("");
  const [searchCondition, setSearchCondition] = useState<BillSearchCondition>({
    customerCode: "",
    customerName: "",
    pageNumber: "1",
  });
  const [searchResult, setSearchResult] = useState<IBillSearchResponse>({});

  // 精算明細書検索&取得
  const getBills = (pageNumber: string) => {
    setLoadingOpen(true);

    bill
      .search(
        pageNumber, // ページ番号、検索時は1固定
        authHeader as string,
        searchCondition.customerCode,
        searchCondition.customerName
      )
      .then((res) => {
        // 精算明細書リストの取得結果と検索条件をセット
        setSearchResult(res.data);
        setSearchCondition({ ...searchCondition, pageNumber });
        setSearchApiErrorMsg("");
      })
      .catch((error) => {
        setSearchApiErrorMsg(getErrorMessage(error));
        throw error;
      })
      .finally(() => {
        setLoadingOpen(false);
      });
  };

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Dashboard
        title="帳票・データ出力"
        color={ADMIN_COLOR.base}
        backgroundColor={ADMIN_COLOR.primary}
        iconName="data"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {searchApiErrorMsg !== "" && (
            <Alert className={classes.alert} severity="error">
              {searchApiErrorMsg}
            </Alert>
          )}
          {/* 精算明細書検索フォーム */}
          <form className={classes.form}>
            <BillSearchConditionForm
              searchCondition={searchCondition}
              setSearchCondition={setSearchCondition}
            />
            <Button
              className={clsx(searchButtonClasses.button, classes.searchButton)}
              variant="contained"
              type="submit"
              onClick={() => getBills("1")}
              disabled={loadingOpen} // 二重送信対策
            >
              <SearchIcon className={classes.searchIcon} />
              検索
            </Button>
          </form>
          {searchApiErrorMsg === "" && (
            <>
              {/* 精算明細書検索結果 */}
              {searchResult && (
                <BillSearchResult
                  resultsRows={searchResult}
                  conditionState={searchCondition}
                  setApiErrorMsg={setSearchApiErrorMsg}
                  setLoadingOpen={setLoadingOpen}
                  getBills={getBills}
                />
              )}
            </>
          )}
        </Container>
      </Dashboard>
    </>
  );
};

export default DataBillPage;
