/**
 * JTB側-変更・解約申請詳細画面の各種操作ボタン一覧
 * メモ：変更・解約申請詳細画面の各種操作のボタンの一覧
 */
import React, { useCallback, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import clsx from "clsx";
import { Grid, Button, makeStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import MessageIcon from "@material-ui/icons/Message";

import { buttonStyles } from "../styles";

import { TDealTypeStr } from "../types";

import { ADMIN_COLOR } from "../../../../constants/common";
import {
  EDIT_DEAL_STATUSES,
  EDIT_DEAL_STATUSES_FOR_JTB_MANAGER,
  EDIT_DEAL_STATUSES_FOR_JTB_STAFF,
  TWorkflowStatus,
} from "../../../../constants/workflowStatus";
import {
  DEAL_TYPE_STR_TO_NUM,
  DEAL_SHOW_PAGE_URL_FOR_DEALER,
} from "../../../../constants/deal";

import formStore from "../../../../store/formStore";

import DownLoading from "../molecules/DownLoading";
import DealUserMessageModal from "../../../admin/deal/components/organisms/DealUserMessageModal";
import AdditionalFileUploadModal from "../../../admin/deal/components/organisms/AdditionalFileUploadModal";
import {
  isJtbManagerUser,
  isJtbStaffUser,
  isJtbSysADUser,
} from "../../../../utils/auth";

const useStyles = makeStyles(() => ({
  commonButton: {
    paddingTop: 11,
    paddingBottom: 11,
    textAlign: "center",
  },
  Icon: {
    position: "absolute",
    top: 11,
    left: 20,
  },
  buttonGridList: {
    margin: "0 auto",
  },
  buttonGrid: {
    width: "49%",
  },
}));

interface Props extends RouteComponentProps {
  dealId: number;
  customerId: number;
  workflowStatus: TWorkflowStatus;
  dealType: TDealTypeStr;
  updateFileList: () => Promise<void>;
  fileData: { fileName: string; fileId: number }[];
}

const ChangeOrTerminateDealShowButtonList: React.FC<Props> = (props: Props) => {
  const {
    dealId,
    customerId,
    workflowStatus,
    dealType,
    updateFileList,
    history,
    fileData,
  } = props;
  const [downloading] = useState(false);
  const [userMessageOpen, setUserMessageOpen] = useState(false);
  const [fileUploadOpen, setFileUploadOpen] = useState(false);

  const classes = useStyles();
  const buttonClasses = buttonStyles({
    width: "100%",
    marginTop: 0,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const handleToEditPage = useCallback(() => {
    // 「現在のワークフローステータス」をセットする
    formStore.setWorkflowStatus(workflowStatus);
    // 「次のワークフローステータス」をセットする（ワークフローの進行がない編集のため、現在のワークフローステータスの数字を入れる）
    formStore.setNextWorkflowStatus(workflowStatus);

    // dealTypeにより、「申込内容を編集する」ボタンの遷移先urlを変更する
    const editDealTypeNum = DEAL_TYPE_STR_TO_NUM[dealType];
    const editDealTypeUrl = DEAL_SHOW_PAGE_URL_FOR_DEALER[editDealTypeNum];
    const editDealUrl = `${editDealTypeUrl}${dealId}/edit`;
    history.push(editDealUrl);
  }, [history, dealId, workflowStatus, dealType]);

  return (
    <>
      <DownLoading loadingOpen={downloading} />
      {userMessageOpen && (
        <DealUserMessageModal
          dealId={dealId}
          dealType={dealType}
          setOpen={setUserMessageOpen}
        />
      )}
      {fileUploadOpen && (
        <AdditionalFileUploadModal
          dealId={dealId}
          customerId={customerId}
          updateFileList={updateFileList}
          setOpen={setFileUploadOpen}
          addedFileData={fileData}
        />
      )}
      <Grid container spacing={2} className={classes.buttonGridList}>
        <Grid item xs={12}>
          <Grid container justify="flex-start" spacing={2}>
            {/* 追加資料アップロード  */}
            <Grid item className={classes.buttonGrid}>
              <Button
                onClick={() => setFileUploadOpen(true)}
                className={clsx(buttonClasses.button, classes.commonButton)}
                variant="contained"
              >
                <EditIcon className={classes.Icon} />
                追加資料アップロード
              </Button>
            </Grid>
            {/* 申請編集 */}
            {((isJtbSysADUser() &&
              EDIT_DEAL_STATUSES.includes(workflowStatus)) ||
              (isJtbManagerUser() &&
                EDIT_DEAL_STATUSES_FOR_JTB_MANAGER.includes(workflowStatus)) ||
              (isJtbStaffUser() &&
                EDIT_DEAL_STATUSES_FOR_JTB_STAFF.includes(workflowStatus))) && (
              <Grid item className={classes.buttonGrid}>
                <Button
                  onClick={handleToEditPage}
                  className={clsx(buttonClasses.button, classes.commonButton)}
                  variant="contained"
                >
                  <EditIcon className={classes.Icon} />
                  申込内容を編集する
                </Button>
              </Grid>
            )}
            {/* メッセージ送信 */}
            <Grid item className={classes.buttonGrid}>
              <Button
                onClick={() => setUserMessageOpen(true)}
                className={clsx(buttonClasses.button, classes.commonButton)}
                variant="contained"
              >
                <MessageIcon className={classes.Icon} />
                メッセージを送信する
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(ChangeOrTerminateDealShowButtonList);
