/**
 * フォーム関係-バリデーションエラーメッセージ
 */
import React, { useMemo } from "react";

import { errorObject } from "../../../common/components/types";

// エラーの表示順
const ERROR_PRIORITY = [
  "develop",
  "required",
  "disabled",
  "houjin",
  "houjin_mente",
  "isDuplicate",
  "pattern",
  "maxLength",
  "rowNumber",
  "rowMaxLength",
  "sjis",
  "relation",
];

type Props = { errors: errorObject[] };

const ValidationMsg: React.FC<Props> = (props: Props) => {
  const { errors } = props;

  const errorMsgArray = useMemo(() => {
    const err = errors
      .filter((e) => e.type && ERROR_PRIORITY.includes(e.type))
      .sort(
        (a, b) =>
          ERROR_PRIORITY.indexOf(a.type || "") -
          ERROR_PRIORITY.indexOf(b.type || "")
      );
    return err.map((e) => e.message);
  }, [errors]);

  return (
    <>
      {errorMsgArray.map((line, index) => (
        <span key={line}>
          {line}
          {errorMsgArray.length - 1 !== index && <br />}
        </span>
      ))}
    </>
  );
};

export default ValidationMsg;
