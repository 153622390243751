import React, { useState } from "react";
import { Table, TableBody, TableContainer } from "@material-ui/core";
import { ThemeColorProps } from "../../../../constants/common";
import formStore from "../../../../store/formStore";
import TableHeading from "../../../common/components/atoms/TableHeading";
import { formStyles } from "../../../common/components/styles";
import { TRequiredKey } from "../../../common/components/types";
import ChangeDealConfirmFormValueRow from "../atoms/ChangeDealConfirmFormValueRow";

type Props = {
  formGroupNameId: number;
  requiredKey: TRequiredKey;
  terminalGridId: number | undefined;
  idx: number;
  colors: ThemeColorProps;
};
const ChangeDealConfirmFormGroup: React.FC<Props> = (props: Props) => {
  const { formGroupNameId, requiredKey, terminalGridId, idx, colors } = props;
  const [groupData] = useState(
    formStore.getFormGroupDataForChangeRequest(formGroupNameId, true)
  );

  const formClasses = formStyles({
    color: colors.base,
    backgroundColor: colors.primary,
  });

  if (!groupData) return null;

  return (
    <>
      <div className={formClasses.tableSpacing}>
        <TableHeading
          colors={{
            color: colors.base,
            backgroundColor: colors.primary,
          }}
          title={groupData.formGroupName}
          key={groupData.formGroupNameId}
          index={idx}
        />
      </div>

      <TableContainer className={formClasses.tableContainer}>
        <Table>
          <TableBody>
            {groupData.forms.map((form) => (
              <ChangeDealConfirmFormValueRow
                key={form.columnName}
                columnName={form.columnName}
                requiredKey={requiredKey}
                terminalGridId={terminalGridId}
                gridId={undefined}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ChangeDealConfirmFormGroup;
