/**
 * 加盟店側-ユーザー追加フォームの会社選択モーダルの会社リストを表示する
 * メモ：モーダル内で検索結果の会社一覧を表示する
 */
import React from "react";

import clsx from "clsx";
import {
  FormControl,
  Radio,
  FormControlLabel,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  makeStyles,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import {
  formStyles,
  radioStyles,
  paginationStyles,
} from "../../../../common/components/styles";

import { ContractorSearchCondition } from "../../types";
import { IContractorSearchResponse } from "../../../../../api/types";

import {
  FONT,
  GUEST_COLOR,
  SEARCH_PER_PAGE,
} from "../../../../../constants/common";

import SearchCountText from "../../../../common/components/atoms/SearchCountText";

// スタイル
const useStyles = makeStyles(() => ({
  modalCell: {
    padding: 0,
    fontFamily: FONT,
    width: 100,
  },
  modalThCell: {
    lineHeight: "1.0rem",
    padding: 10,
  },
  thCell: {
    fontFamily: FONT,
    color: GUEST_COLOR.base,
    backgroundColor: GUEST_COLOR.primary,
  },
  messageHeader: {
    fontFamily: FONT,
    textAlign: "center",
    fontSize: "1.1rem",
    marginTop: "40px",
    marginBottom: "10px",
    color: GUEST_COLOR.primary,
  },
  check: {
    width: "30px!important",
  },
  contractorContainer: {
    maxHeight: 250,
  },
  radioStyle: {
    border: `1px solid ${GUEST_COLOR.primary}`,
    width: "20px!important",
    height: "20px!important",
    marginLeft: "20px!important",
  },
  form: {
    marginTop: "5px!important",
  },
}));

// 契約者リスト表示配列
type ContractorListDisplayData = {
  id: number; // マスター契約者ID
  companyName: string; // 会社名
  customerCode: string; // 加盟店コード
  customerName: string; // 加盟店名
  customerPhoneNum: string; // 加盟店電話番号
};

// 契約者リストのインターフェース
interface Props {
  resultsRows: IContractorSearchResponse;
  conditionState: ContractorSearchCondition;
  contractorId: string;
  setContractorId: (arg: string) => void;
  getContractors: (arg: string) => void;
}

const ContractorSearchResult: React.FC<Props> = (props: Props) => {
  const formClasses = formStyles({
    color: GUEST_COLOR.base,
    backgroundColor: GUEST_COLOR.primary,
  });
  const paginationClasses = paginationStyles({
    backgroundColor: GUEST_COLOR.primary,
    color: GUEST_COLOR.base,
  });
  const radioClasses = radioStyles({
    backgroundColor: GUEST_COLOR.primary,
  });

  const classes = useStyles();

  const {
    resultsRows,
    conditionState,
    contractorId,
    setContractorId,
    getContractors,
  } = props;

  // 表示用カラム名
  const columnNames = [
    "",
    "会社名",
    "加盟店コード",
    "加盟店名",
    "加盟店電話番号",
  ] as const;

  // 表示用データ作成
  const displayDataList: ContractorListDisplayData[] = [];
  if (resultsRows.result) {
    resultsRows.result.forEach((resultData) => {
      // 表示用データ作成
      const displayData = {
        id: resultData.id,
        companyName: resultData.companyName,
      } as ContractorListDisplayData;
      // 加盟店があれば表示データ作成する
      if (resultData.masterCustomers.length > 0) {
        // 加盟店が他に何軒あるか
        const customerCount = resultData.masterCustomers.length - 1;
        displayData.customerCode =
          customerCount === 0
            ? resultData.masterCustomers[0].customerCode
            : `${resultData.masterCustomers[0].customerCode}(他${customerCount}件)`;
        displayData.customerName =
          customerCount === 0
            ? resultData.masterCustomers[0].customerName
            : `${resultData.masterCustomers[0].customerName}(他${customerCount}件)`;
        displayData.customerPhoneNum =
          customerCount === 0
            ? resultData.masterCustomers[0].customerPhoneNum
            : `${resultData.masterCustomers[0].customerPhoneNum}(他${customerCount}件)`;
      }
      displayDataList.push(displayData);
    });
  }

  // ラジオボタンのチェック処理
  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setContractorId(event.currentTarget.dataset.id as string);
  };

  return (
    <>
      {!resultsRows && (
        <div className={classes.messageHeader}>
          {` ${"検索条件を指定して契約者を検索してください。"}`}
        </div>
      )}
      {resultsRows && resultsRows.totalCount === 0 && (
        <div className={classes.messageHeader}>
          {` ${"該当する契約者はありません。"}`}
        </div>
      )}
      {resultsRows && resultsRows.totalCount !== 0 && (
        <>
          {/* 検索結果件数 */}
          <SearchCountText count={resultsRows.totalCount as number} />
          {/* 検索結果 */}
          <FormControl className={clsx(formClasses.form, classes.form)}>
            <TableContainer
              component={Paper}
              className={classes.contractorContainer}
            >
              <Table
                stickyHeader
                className={formClasses.table}
                aria-label="simple table"
              >
                <TableHead className={formClasses.th}>
                  <TableRow>
                    {/* タイトル */}
                    {columnNames.map((column) => (
                      <TableCell
                        key={column}
                        align="center"
                        className={clsx(classes.thCell, classes.modalThCell)}
                      >
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayDataList.map((rowData) => (
                    <TableRow key={rowData.id}>
                      {/* ラジオボタン */}
                      <TableCell
                        align="center"
                        className={clsx(classes.modalCell, classes.check)}
                      >
                        <FormControlLabel
                          className={radioClasses.radio}
                          label=""
                          control={
                            <Radio
                              data-id={rowData.id}
                              className={classes.radioStyle}
                              onClick={handleClick}
                              checked={Number(contractorId) === rowData.id}
                            />
                          }
                        />
                      </TableCell>
                      {/* 会社名 */}
                      <TableCell align="center" className={classes.modalCell}>
                        {rowData.companyName || "-"}
                      </TableCell>
                      {/* 加盟店コード */}
                      <TableCell align="center" className={classes.modalCell}>
                        {rowData.customerCode || "-"}
                      </TableCell>
                      {/* 加盟店名 */}
                      <TableCell align="center" className={classes.modalCell}>
                        {rowData.customerName || "-"}
                      </TableCell>
                      {/* 加盟店電話番号 */}
                      <TableCell align="center" className={classes.modalCell}>
                        {rowData.customerPhoneNum || "-"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* ページネーション */}
            <div className={paginationClasses.parent}>
              <Pagination
                count={Math.ceil(
                  (resultsRows.totalCount as number) / SEARCH_PER_PAGE
                )}
                className={clsx(
                  paginationClasses.pagination,
                  paginationClasses.pageCurrent
                )}
                onChange={(e: React.ChangeEvent<unknown>, num) =>
                  getContractors(String(num))
                }
                page={Number(conditionState.pageNumber)}
              />
            </div>
          </FormControl>
        </>
      )}
    </>
  );
};

export default ContractorSearchResult;
