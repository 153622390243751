import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import CognitoAuthProvider from "./cognito/CognitoAuthProvider";
import authconfig from "./cognito/authconfig";

ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/">
        <CognitoAuthProvider amplifyConfig={authconfig}>
          <App />
        </CognitoAuthProvider>
      </Route>
    </Switch>
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
