/**
 * 加盟店側-申請編集完了画面
 */
import React, { useEffect, useMemo } from "react";
import { RouteComponentProps, Link, withRouter } from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";

import { useContainerStyles } from "../../../../templates/styles";
import {
  buttonStyles,
  completeStyles,
} from "../../../../common/components/styles";

import { GUEST_COLOR } from "../../../../../constants/common";

import formStore from "../../../../../store/formStore";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";

const useStyles = makeStyles(() => ({
  Icon: {
    position: "absolute",
    top: 8,
    left: 20,
  },
  buttonBlock: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  messageButton: {
    width: 250,
  },
  returnButton: {
    width: 250,
    marginLeft: 20,
    "& .MuiButton-label": {
      marginLeft: 34,
    },
  },
}));

const GuestDealEditCompletePage: React.FC<
  RouteComponentProps<{ id: string }>
> = (props: RouteComponentProps<{ id: string }>) => {
  const { match } = props;

  const dealId = useMemo(() => match.params.id, [match]);

  const breadCrumbs = [
    { name: "TOP", link: "/guest" },
    { name: "完了", link: "" },
  ];

  const classes = useStyles();
  const completeClasses = completeStyles();
  const containerClasses = useContainerStyles();
  const buttonClasses = buttonStyles({
    width: "100%",
    marginTop: 0,
    backgroundColor: GUEST_COLOR.primary,
  });

  useEffect(() => {
    // 編集用申請種別リセット
    formStore.setEditDealType(null);
    // 「現在のワークフローステータス」リセット
    formStore.setWorkflowStatus(10);
    // 「次のワークフローステータス」リセット
    formStore.setNextWorkflowStatus(10);
    // 契約者IDをリセット
    formStore.setContractorId(null);
    // フォームバージョンをリセット
    formStore.setEditFormVersion(null);
    // 以下ID系もすべてリセット
    formStore.setMasterCustomerId(null);
    formStore.setMasterCustomerFormId(null);
    formStore.setDealOtherFormId(null);
    formStore.setTerminalIds([]);
    formStore.deleteDraft(`customer-deal-${match.params.id}`);
  }, [match.params.id]);

  return (
    <Dashboard
      title="申請管理"
      color={GUEST_COLOR.base}
      backgroundColor={GUEST_COLOR.primary}
      iconName="deal"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {/* 文言 */}
        <div className={completeClasses.message}>
          編集が完了しました。
          <br />
          <br />
          （申請番号：{dealId}）
        </div>
        <div className={classes.buttonBlock}>
          {/* ダッシュボードに戻るボタン */}
          <Button
            type="button"
            component={Link}
            to="/guest"
            className={clsx(buttonClasses.button, classes.returnButton)}
            variant="contained"
          >
            <KeyboardReturnIcon className={classes.Icon} />
            ダッシュボードに戻る
          </Button>
        </div>
      </Container>
    </Dashboard>
  );
};

export default withRouter(GuestDealEditCompletePage);
