/**
 * 加盟店側-ユーザー間メッセージモーダル
 */
import React, { useContext, useMemo, useState } from "react";

import clsx from "clsx";
import {
  Button,
  CircularProgress,
  makeStyles,
  Modal,
  TextareaAutosize,
  Theme,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Email";

import { buttonStyles } from "../../../../common/components/styles";

import { FONT, GUEST_COLOR } from "../../../../../constants/common";

import deal from "../../../../../api/deal";
import { AuthContext } from "../../../../../cognito/AuthContext";
import getErrorMessage from "../../../../../utils/error";
import { GlobalPopupContext } from "../../../../../App";
import { compareSJISWhiteList } from "../../../../../form/validation";

import CustomSelect from "../../../../common/components/molecules/CustomSelect";

const useStyle = makeStyles((theme: Theme) => ({
  popup: {
    position: "absolute",
    minWidth: 700,
    maxWidth: 950,
    width: "60%",
    minHeight: 150,
    maxHeight: 750,
    height: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
  },
  modalTitle: {
    fontSize: "1.1em",
    color: GUEST_COLOR.primary,
    fontFamily: FONT,
    margin: 0,
  },
  systemMessage: {
    margin: "10px 0px",
  },
  selectBlock: {
    margin: "10px 0px",
  },
  textAreaBlock: {
    margin: "10px 0px",
    marginTop: 20,
  },
  textArea: {
    width: "100%",
    backgroundColor: "#f2f7fb",
    border: 0,
    fontSize: "1.0rem",
    fontFamily: FONT,
    minHeight: 100,
    maxHeight: 300,
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderWidth: 2,
    },
    resize: "vertical",
  },
  buttonBlock: {
    display: "flex",
    justifyContent: "end",
    marginTop: 20,
  },
  selectButton: {
    paddingBottom: 8,
    marginRight: 20,
  },
  cancelButton: {
    paddingBottom: 8,
  },
  closeIcon: {
    fontSize: "1.1rem",
    position: "relative",
    marginLeft: -3,
    marginBottom: -5,
    left: -2,
    top: -1,
  },
  sendIcon: {
    position: "relative",
    marginLeft: -3,
    marginBottom: -5,
    left: -6,
    top: 2,
  },
  remark: {
    color: "#777",
    fontFamily: FONT,
  },
  progress: {
    width: 300,
    display: "flex",
    justifyContent: "center",
    color: GUEST_COLOR.primary,
  },
}));

const MESSAGE_TARGET = ["社内", "JTB担当者"] as const;

type TMessageTarget = (typeof MESSAGE_TARGET)[number];

/** JTB担当者を選んだときに渡す値 */
const JTB_GENERAL = "20";

type Props = {
  dealId: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  systemMessage?: string; // ポップアップ上部に表示する注意文言
  optionalApi?: () => Promise<void>; // メッセージ送信API前に叩くAPI
};

const DealUserMessageModal: React.FC<Props> = (props: Props) => {
  const { dealId, setOpen, systemMessage, optionalApi } = props;

  const { authHeader } = useContext(AuthContext);
  const { setSnackbar, setLoading } = useContext(GlobalPopupContext);

  const modalStyle = useMemo(
    () => ({
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }),
    []
  );

  const [target, setTarget] = useState<TMessageTarget | "">(""); // 選択した宛先
  const [showUserSelect, setShowUserSelect] = useState(false); // 社内の宛先ユーザーのセレクトボックスを表示するかどうか
  const [usersLoadingOpen, setUsersLoadingOpen] = useState(false);
  const [users, setUsers] = useState<{ userName: string; name: string }[]>([]);
  const [selectedName, setSelectedName] = useState<string>(""); // 選択した社内の宛先ユーザー
  const [targetUser, setTargetUser] = useState<string>(""); // APIに渡す値
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<string>("");

  const classes = useStyle();
  const buttonClasses = buttonStyles({
    width: 130,
    marginTop: 0,
    backgroundColor: GUEST_COLOR.primary,
  });

  const selectTarget = async (selectedTarget: TMessageTarget) => {
    // 宛先に変更がなければ何もしない
    if (target === selectedTarget) return;

    const isDealer = selectedTarget === "JTB担当者";
    setShowUserSelect(!isDealer); // 宛先が「JTB担当者」でない場合は社内の宛先ユーザーのセレクトボックスを表示
    setTarget(selectedTarget);
    setError("");

    if (isDealer) {
      // 宛先が「JTB担当者」の場合APIに渡す値をセット
      setTargetUser(JTB_GENERAL);
    } else {
      // 宛先が「JTB担当者」でない場合は加盟店ユーザーリスト取得APIを実行
      setUsersLoadingOpen(true);
      await deal
        .getDealCustomerUser(authHeader as string, dealId)
        .then((res) => {
          setUsers(res.data.result.customers);
        })
        .catch((err) => {
          setError(getErrorMessage(err));
        })
        .finally(() => {
          setUsersLoadingOpen(false);
        });
    }
  };

  const selectTargetUser = (name: string) => {
    const findUser = users.find((user) => user.name === name);
    if (!findUser) return;

    setSelectedName(name);
    setTargetUser(findUser.userName); // APIに渡す値をセット
    setError("");
  };

  const handleChangeMessage = (text: string) => {
    setMessage(text);
    setError("");
  };

  const handleSendMessage = async () => {
    if (!message) {
      setError("メッセージを入力してください");
      return;
    }
    if (message.length > 500) {
      setError("メッセージは500文字以内で入力してください");
      return;
    }
    const errChar = compareSJISWhiteList([message]);
    if (errChar) {
      setError(`当システムで使用できない文字が含まれています。（${errChar}）`);
      return;
    }
    if (!targetUser) {
      setError("宛先を選択してください");
      return;
    }

    setLoading(true);
    if (optionalApi) {
      let isError = false;
      await optionalApi().catch((err) => {
        setError(getErrorMessage(err));
        isError = true;
        setLoading(false);
      });
      // エラーじゃない場合は続けてメッセージAPIを叩くのでLoadingのまま
      if (isError) return;
    }

    await deal
      .sendUserMessage(authHeader as string, dealId, targetUser, message)
      .then(() => {
        setSnackbar({
          openProps: true,
          message: "メッセージを送信しました。",
          severity: "info",
        });
        setLoading(false);
        setOpen(false);
      })
      .catch((err) => {
        setError(getErrorMessage(err));
        setLoading(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.popup}>
        {/* タイトル */}
        <h2 id="simple-modal-title" className={classes.modalTitle}>
          {" ユーザーメッセージ送信"}
        </h2>
        {/* 注意文言 */}
        {systemMessage && (
          <div className={classes.systemMessage}>
            <Alert severity="warning">{systemMessage}</Alert>
          </div>
        )}
        <div>
          {/* 宛先のセレクトボックス */}
          <div className={classes.selectBlock}>
            <div className={classes.remark}>宛先を選択してください</div>
            <CustomSelect
              pulldownArray={[...MESSAGE_TARGET]}
              onChange={(e) => selectTarget(e.target.value as TMessageTarget)}
              value={target}
            />
          </div>
          {/* 社内の宛先ユーザーのセレクトボックス（宛先で社内を選んだ際のみ表示） */}
          {showUserSelect && (
            <div className={classes.selectBlock}>
              <div className={classes.remark}>
                社内の宛先ユーザーを選択してください。
              </div>
              {usersLoadingOpen ? (
                <div className={classes.progress}>
                  <CircularProgress color="inherit" />
                </div>
              ) : (
                <CustomSelect
                  pulldownArray={users.map((user) => user.name)}
                  onChange={(e) => selectTargetUser(e.target.value)}
                  value={selectedName}
                />
              )}
            </div>
          )}
          {/* メッセージ入力欄 */}
          <div className={classes.textAreaBlock}>
            <TextareaAutosize
              onChange={(e) => handleChangeMessage(e.target.value)}
              className={classes.textArea}
              value={message}
              placeholder="メッセージを入力してください（最大500文字）"
            />
          </div>
        </div>
        {/* エラー文言 */}
        {error && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        )}

        <div className={classes.buttonBlock}>
          {/* 送信ボタン */}
          <Button
            type="button"
            className={clsx(buttonClasses.button, classes.selectButton)}
            onClick={handleSendMessage}
            variant="contained"
            disabled={!!error || !targetUser || !message}
          >
            <SendIcon className={classes.sendIcon} />
            送信
          </Button>
          {/* キャンセルボタン */}
          <Button
            type="button"
            className={clsx(buttonClasses.button, classes.cancelButton)}
            onClick={handleClose}
            variant="contained"
          >
            <CloseIcon className={classes.closeIcon} />
            キャンセル
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DealUserMessageModal;
