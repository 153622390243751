/**
 * フォーム関係-コメント・メモフォーム
 */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { Button, TextareaAutosize, makeStyles } from "@material-ui/core";

import { buttonStyles } from "../../../common/components/styles";

import { FONT, ThemeColorProps } from "../../../../constants/common";

import deal from "../../../../api/deal";
import { GlobalPopupContext } from "../../../../App";
import { AuthContext } from "../../../../cognito/AuthContext";
import formStore from "../../../../store/formStore";
import { getErrorMessage } from "../../../../utils/error";

import LineBreaksText from "../../../common/components/atoms/LineBreaksText";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
  },
  message: (props: { theme: ThemeColorProps }) => ({
    fontSize: "0.9em",
    fontWeight: "bold",
    fontFamily: FONT,
    color: props.theme.primary,
    marginBottom: 15,
  }),
  commentContainer: {
    display: "flex",
    minHeight: 100,
  },
  textArea: {
    width: "100%",
    backgroundColor: "#f2f7fb",
    border: 0,
    fontSize: "1.0rem",
    fontFamily: FONT,
    minHeight: 100,
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderWidth: 2,
    },
    resize: "vertical",
  },
  comment: {
    width: "100%",
    padding: 8,
    backgroundColor: "#fafafa",
  },
  buttonContainer: {
    margin: "0 auto",
    clear: "both",
    width: 284,
    marginTop: 20,
  },
  placeholder: {
    color: "#9f9f9f",
  },
}));

type Props = {
  dealId: string;
  colors: ThemeColorProps;
};

const CommentForm: React.FC<Props> = (props: Props) => {
  const { dealId, colors } = props;

  const { authHeader } = useContext(AuthContext);
  const { setSnackbar } = useContext(GlobalPopupContext);
  const [comment, setComment] = useState(formStore.getFormComment());
  const [active, setActive] = useState(false);
  // コンポーネント削除時の更新判定用
  const activeRef = useRef(false);

  const classes = useStyles({ theme: colors });
  const buttonClasses = buttonStyles({
    width: 130,
    marginTop: 20,
    backgroundColor: colors.primary,
  });

  const updateComment = useCallback(async () => {
    if (!authHeader) return;
    try {
      // Loading表示不要
      await deal.updateFormComment(
        authHeader,
        dealId,
        formStore.getFormComment() || ""
      );
      // API成功時は特に何もしない
    } catch (err) {
      setSnackbar({
        openProps: true,
        severity: "error",
        message: `コメント・メモの更新に失敗しました。${getErrorMessage(err)}`,
      });
    }
  }, [authHeader, dealId, setSnackbar]);

  useEffect(() => {
    const fn = () => {
      setComment(formStore.getFormComment());
    };
    formStore.addListener("comment", fn);

    return () => {
      formStore.removeListener("comment", fn);
      // コンポーネント削除時に編集中ならAPI叩いて保存する
      if (activeRef.current) {
        updateComment();
      }
    };
  }, [updateComment]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target;
      formStore.setFormComment(value);
    },
    []
  );

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      // 編集中->完了の場合はAPI叩く
      if (active) {
        updateComment();
      }
      activeRef.current = !active;
      setActive(!active);
    },
    [active, updateComment]
  );

  return (
    <div className={classes.container}>
      {/* 文言 */}
      <div className={classes.message}>
        編集ボタンを押すことでコメント・メモを編集できます。（最大500文字まで）
      </div>
      {/* フォーム */}
      <div className={classes.commentContainer}>
        {active ? (
          // 編集中の場合
          <TextareaAutosize
            onChange={handleChange}
            className={classes.textArea}
            value={comment || ""}
          />
        ) : (
          <div className={classes.comment}>
            {comment ? (
              <LineBreaksText text={comment} />
            ) : (
              <div className={classes.placeholder}>
                コメント・メモ（最大500文字まで）
              </div>
            )}
          </div>
        )}
      </div>
      {/* ボタン */}
      <div className={classes.buttonContainer}>
        <Button
          type="submit"
          onClick={handleClick}
          className={buttonClasses.button}
          variant="contained"
        >
          {active ? "編集終了" : "編集する"}
        </Button>
      </div>
    </div>
  );
};

export default CommentForm;
