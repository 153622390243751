// APIで返ってくる型は業務外の付与情報がついているのでフロントでは型を明確に定義しない。
import axios from "axios";
import { terminalSearchParameters } from "./types";
import {
  createAPIPromise,
  createLoadingPromise,
  loadingUrls,
  createParamStr,
} from "./utils/common";

/**
 * 端末情報検索API
 * @param authHeader 認証トークン
 * @param masterCustomerId 加盟店ID
 * @param customerName 加盟店名
 * @param customerCode 法人番号
 * @param termiIdentNum 端末識別番号
 * @param terminalType 端末種別タイプ
 * @param firstDLLDate 初回DLL日
 * @param terminalTerminationFlag 解約済みを含むか
 * @param pageNumber ページ番号
 */
const search = async (
  authHeader: string,
  masterCustomerId: string,
  customerName: string,
  customerCode: string,
  termiIdentNum: string,
  terminalType: string,
  firstDLLDate: string,
  terminalTerminationFlag: string,
  pageNumber: string
  // eslint-disable-next-line
): Promise<any> => {
  const params: terminalSearchParameters = {
    terminalTerminationFlag,
    pageNumber,
  };
  params.masterCustomerId =
    masterCustomerId === "" ? undefined : masterCustomerId;
  params.customerName = customerName === "" ? undefined : customerName;
  params.customerCode = customerCode === "" ? undefined : customerCode;
  params.termiIdentNum = termiIdentNum === "" ? undefined : termiIdentNum;
  params.terminalType = terminalType === "" ? undefined : terminalType;
  params.firstDLLDate = firstDLLDate === "" ? undefined : firstDLLDate;

  const path = `/terminals`;
  const paramStr = createParamStr(params);
  const loadingKey = `${path}?${paramStr}`;
  if (loadingUrls.includes(loadingKey)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(loadingKey);

  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    loadingKey
  );
};

/**
 * 個別端末情報取得API（JTB版用）
 * @param authHeader: 認証トークン
 * @param terminalId: 端末id
 * @returns
 */
const show = async (authHeader: string, terminalId: string) => {
  const path = `/terminals/${terminalId}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    path
  );
};

/**
 * ファイルダウンロードAPIのレスポンス
 */
export type TTerminalsFileResponse = {
  data: {
    statusCode: number;
    message: string;
    result: {
      file: string;
    };
  };
};

// 端末一覧ダウンロードAPI
export interface TerminalsFileParameters {
  masterCustomerId?: string;
  customerName?: string;
  customerCode?: string;
  termiIdentNum?: string;
  terminalType?: string;
  firstDLLDate?: string;
  terminalTerminationFlag: string;
}

/**
 * 端末一覧ダウンロードAPI
 * @param authHeader 認証トークン
 * @param masterCustomerId 加盟店ID
 * @param customerName 加盟店名
 * @param customerCode 法人番号
 * @param termiIdentNum 端末識別番号
 * @param terminalType 端末種別タイプ
 * @param firstDLLDate 初回DLL日
 * @param terminalTerminationFlag 解約済みを含むか
 * @returns
 */
const downloadTerminalListFile = async (
  authHeader: string,
  masterCustomerId: string,
  customerName: string,
  customerCode: string,
  termiIdentNum: string,
  terminalType: string,
  firstDLLDate: string,
  terminalTerminationFlag: string
  // eslint-disable-next-line
): Promise<TTerminalsFileResponse> => {
  const params: TerminalsFileParameters = { terminalTerminationFlag };
  params.masterCustomerId =
    masterCustomerId === "" ? undefined : masterCustomerId;
  params.customerName = customerName === "" ? undefined : customerName;
  params.customerCode = customerCode === "" ? undefined : customerCode;
  params.termiIdentNum = termiIdentNum === "" ? undefined : termiIdentNum;
  params.terminalType = terminalType === "" ? undefined : terminalType;
  params.firstDLLDate = firstDLLDate === "" ? undefined : firstDLLDate;

  const path = `/terminals/file`;
  const paramStr = createParamStr(params);
  const loadingKey = `${path}?${paramStr}`;
  if (loadingUrls.includes(loadingKey)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(loadingKey);

  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    loadingKey
  );
};

export default {
  search,
  downloadTerminalListFile,
  show,
};
