/**
 * 加盟店側-ユーザー追加入力フォーム
 * メモ：入力フォームを表示する
 */
import React, { useState } from "react";

import moment from "moment";
import clsx from "clsx";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  makeStyles,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  radioStyles,
  buttonStyles,
} from "../../../../common/components/styles";

import { GuestUserNewItemObject } from "../../types";

import {
  DISPLAY_NAME_MAX_LENGTH,
  EMAIL_MAX_LENGTH,
  GUEST_COLOR,
  FONT,
} from "../../../../../constants/common";
import {
  USER_TYPE_NAME_LIST,
  USER_TYPE_SEARCH_MAP_FOR_CUSTOMER,
} from "../../../../../constants/user";

import { getUserType } from "../../../../../api/utils/authUtils";
import { isSysAd, isCustomerStaffUser } from "../../../../../utils/auth";
import { extractCustomerTypeFromObject } from "../../../../../utils/string";

import CustomSelect from "../../../../common/components/molecules/CustomSelect";
import CustomTextField from "../../../../common/components/atoms/CustomTextField";
import ContractorSelectModal from "../molecules/ContractorSelectModal";

// レイアウト設定
const useStyles = makeStyles(() => ({
  table: {
    width: 800,
    minWidth: 800,
  },
  td: {
    padding: 1,
    backgroundColor: "#fff",
    color: "#000!important",
    borderTop: "1px solid #d2d5d8",
    borderBottom: "1px solid #d2d5d8",
    borderRight: "1px solid #d2d5d8",
    width: 400,
  },
  th: {
    padding: 0,
    fontFamily: FONT,

    backgroundColor: "#fff",
    color: "#000!important",
    width: 200,
    border: "1px solid #d2d5d8",
    position: "relative",
    paddingLeft: 20,
  },
  formControl: {
    marginTop: "0px!important",
  },
  lengthText: {
    color: "#777",
    fontFamily: FONT,
    fontSize: "0.7rem",
    fontWeight: 400,
    paddingLeft: 7,
  },
  radioTd: {
    paddingLeft: 11,
  },
  emptyMessage: {
    margin: 7,
  },
  contractor: {
    marginTop: 7,
    width: 500,
    float: "left",
    fontFamily: "Noto Sans JP900!important",
  },
  deleteIcon: {
    fontSize: "1.0em",
    marginLeft: 2,
    color: "#ed1c24",
    position: "relative",
    top: -6,
    left: -2,
  },
  contModalButton: {
    paddingTop: 7,
    paddingBottom: 8,
    textAlign: "center",
    float: "right",
    marginRight: 70,
    marginBottom: 7,
    fontSize: "0.9em",
  },
  contIconButton: {
    padding: 0,
    marginLeft: 10,
    paddingTop: 5,
  },
  buttonTd: {
    paddingLeft: 11,
  },
}));

// 加盟店ユーザー追加フォームのインターフェース
interface Props {
  userNewState: GuestUserNewItemObject;
  setUserNewState: (value: GuestUserNewItemObject) => void;
}

// 画面表示処理
const GuestSettingUserNewForm: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const backgroundColor = "#005bac";
  const buttonClasses = buttonStyles({
    width: 60,
    marginTop: 0,
    backgroundColor: GUEST_COLOR.primary,
  });
  const radioClasses = radioStyles({ backgroundColor });

  const { userNewState, setUserNewState } = props;

  const [contractorModalOpen, setContractorModalOpen] =
    useState<boolean>(false);

  const userType = getUserType();
  // ユーザー種別のプルダウン用リスト(指定なし省く&自身の権限と同等か下の権限のみ)
  // システムユーザーのみスーパーユーザーの再作成が可能
  const userTypeList = Object.keys(USER_TYPE_SEARCH_MAP_FOR_CUSTOMER).filter(
    (typeName) => {
      if (typeName !== "指定なし") {
        return isSysAd()
          ? true
          : typeName !== USER_TYPE_NAME_LIST.SUPER_REPRESENTATIVE_MANAGER &&
              USER_TYPE_SEARCH_MAP_FOR_CUSTOMER[typeName] >=
                (userType as string);
      }
      return false;
    }
  );

  // 会社選択用モーダルの制御
  const handleCreateUserBySysAd = () => {
    setContractorModalOpen(true);
  };

  return (
    <FormControl className={classes.formControl}>
      {contractorModalOpen && (
        <ContractorSelectModal
          open={contractorModalOpen}
          setOpen={setContractorModalOpen}
          setUserNewState={setUserNewState}
          userNewState={userNewState}
        />
      )}
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            {/* 会社名 システムユーザーのみ */}
            {isSysAd() && (
              <TableRow key="会社名">
                <TableCell align="left" className={classes.th}>
                  会社名
                </TableCell>
                <TableCell
                  align="left"
                  className={clsx(classes.td, classes.buttonTd)}
                >
                  <div className={classes.contractor}>
                    {userNewState.contractorId !== "" && (
                      <>
                        {/* 会社名を表示 */}
                        {userNewState.companyName}
                        {/* クリアボタン */}
                        <IconButton
                          color="secondary"
                          className={classes.contIconButton}
                          onClick={() =>
                            setUserNewState({
                              ...userNewState,
                              contractorId: "",
                              companyName: "",
                              masterCustomers: [],
                            })
                          }
                        >
                          <DeleteIcon className={classes.deleteIcon} />
                        </IconButton>
                      </>
                    )}
                    <Button
                      type="button"
                      onClick={handleCreateUserBySysAd}
                      variant="contained"
                      className={clsx(
                        buttonClasses.button,
                        classes.contModalButton
                      )}
                    >
                      選択
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {/* ユーザー種別 */}
            <TableRow key="ユーザー種別">
              <TableCell align="left" className={classes.th}>
                ユーザー種別
              </TableCell>
              <TableCell align="left" className={classes.td}>
                <CustomSelect
                  width="100%"
                  value={userNewState.userType}
                  pulldownArray={userTypeList}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUserNewState({
                      ...userNewState,
                      masterCustomerId: "",
                      userType: e.target.value,
                    })
                  }
                />
              </TableCell>
            </TableRow>
            {/* メールアドレス */}
            <TableRow key="メールアドレス">
              <TableCell align="left" className={classes.th}>
                メールアドレス
                <span
                  className={classes.lengthText}
                >{`(最大${EMAIL_MAX_LENGTH}文字)`}</span>
              </TableCell>
              <TableCell align="left" className={classes.td}>
                <CustomTextField
                  type="email"
                  value={userNewState.email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUserNewState({
                      ...userNewState,
                      email: e.target.value,
                    })
                  }
                />
              </TableCell>
            </TableRow>
            {/* ユーザー名 */}
            <TableRow key="ユーザー名">
              <TableCell align="left" className={classes.th}>
                ユーザー名
                <span
                  className={classes.lengthText}
                >{`(最大${DISPLAY_NAME_MAX_LENGTH}文字)`}</span>
              </TableCell>
              <TableCell align="left" className={classes.td}>
                <CustomTextField
                  type="text"
                  value={userNewState.name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUserNewState({
                      ...userNewState,
                      name: e.target.value,
                    })
                  }
                />
              </TableCell>
            </TableRow>
            {/* 所属加盟店  (加盟店所属ユーザーの場合は非表示) */}
            {!isCustomerStaffUser() &&
              userNewState.masterCustomers.length > 0 &&
              userNewState.userType !==
                USER_TYPE_NAME_LIST.SUPER_REPRESENTATIVE_MANAGER &&
              userNewState.userType !==
                USER_TYPE_NAME_LIST.REPRESENTATIVE_MANAGER &&
              userNewState.userType !== "" && (
                <TableRow key="所属加盟店">
                  <TableCell align="left" className={classes.th}>
                    所属加盟店
                  </TableCell>
                  <TableCell
                    align="left"
                    className={clsx(classes.td, classes.radioTd)}
                  >
                    <div>
                      <RadioGroup>
                        {userNewState.masterCustomers.map((radio) => (
                          <FormControlLabel
                            key={radio.masterCustomerId}
                            className={radioClasses.radio}
                            value={radio.masterCustomerId}
                            control={
                              <Radio
                                onClick={(
                                  e: React.MouseEvent<HTMLElement, MouseEvent>
                                ) =>
                                  setUserNewState({
                                    ...userNewState,
                                    masterCustomerId: (
                                      e.target as HTMLInputElement
                                    ).value,
                                  })
                                }
                                checked={
                                  userNewState.masterCustomerId !== "" &&
                                  radio.masterCustomerId ===
                                    Number(userNewState.masterCustomerId)
                                }
                                disabled={false}
                              />
                            }
                            label={`${
                              radio.customerName
                            }[${extractCustomerTypeFromObject(
                              radio.customerType
                            )}] (運用開始日:${
                              radio.customerStartDate
                                ? moment(radio.customerStartDate).format(
                                    "YYYY-MM-DD"
                                  )
                                : "-"
                            })`}
                          />
                        ))}
                      </RadioGroup>
                    </div>
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </FormControl>
  );
};

export default GuestSettingUserNewForm;
