// APIで返ってくる型は業務外の付与情報がついているのでフロントでは型を明確に定義しない。
import axios from "axios";
import {
  createAPIPromise,
  createLoadingPromise,
  createParamStr,
  loadingUrls,
} from "./utils/common";

// metabase url取得APIのインターフェース
interface metabaseParameters {
  type: string;
  termiIdentNum?: string;
}

/**
 * metabase url取得API（返却されるのはトークン？？）
 * @param authHeader 認証トークン
 * @param type 画面種別
 * @param termiIdentNum 端末識別番号（画面種別が8,9の場合必須）
 */
const getUrl = async (
  authHeader: string,
  type: string,
  termiIdentNum: string
  // eslint-disable-next-line
): Promise<any> => {
  const params: metabaseParameters = { type };
  params.termiIdentNum = termiIdentNum === "" ? undefined : termiIdentNum;

  const path = `/metabase`;
  const paramStr = createParamStr(params);
  const loadingKey = `${path}?${paramStr}`;
  if (loadingUrls.includes(loadingKey)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(loadingKey);

  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    loadingKey
  );
};

export default {
  getUrl,
};
