/**
 * JTB側-加盟店ユーザー検索結果リスト
 */
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import clsx from "clsx";
import {
  Button,
  Checkbox,
  FormControl,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";

import {
  buttonStyles,
  checkboxStyles,
  formStyles,
  paginationStyles,
} from "../../../../common/components/styles";

import {
  IGuestUserSearchResponse,
  IGuestUserSearchResult,
} from "../../../../../api/types";
import { GuestSearchCondition } from "../../types";

import {
  FONT,
  ADMIN_COLOR,
  SEARCH_PER_PAGE,
} from "../../../../../constants/common";
import {
  USER_TYPE_CONVERT_MAP,
  USER_TYPE_LIST,
} from "../../../../../constants/user";

import { getErrorMessage } from "../../../../../utils/error";
import cognitoUser from "../../../../../api/cognitoUser";
import { AuthContext } from "../../../../../cognito/AuthContext";
import { extractCustomerTypeFromObject } from "../../../../../utils/string";

import SearchCountText from "../../../../common/components/atoms/SearchCountText";
import CustomSnackbar from "../../../../common/components/atoms/CustomSnackbar";
import ConfirmModal from "../../../../common/components/atoms/ConfirmModal";

// スタイル
const useStyles = makeStyles(() => ({
  cell: {
    fontFamily: FONT,
    padding: 1,
    backgroundColor: ADMIN_COLOR.base,
    color: "#000!important",
    border: `1px solid ${ADMIN_COLOR.primary}`,
    width: 200,
    fontSize: "0.9em",
    height: 38,
  },
  thCell: {
    fontFamily: FONT,
    color: ADMIN_COLOR.primary,
    border: `1px solid ${ADMIN_COLOR.primary}!important`,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: ADMIN_COLOR.base,
    width: 400,
    position: "relative",
    fontSize: "0.9em",
  },
  check: {
    width: "50px!important",
    whiteSpace: "nowrap",
  },
  edit: {
    width: "100px!important",
  },
  icon: {
    position: "absolute",
    top: 7,
    left: 15,
    fontSize: "1.3rem",
  },
  messageHeader: {
    fontFamily: FONT,
    textAlign: "center",
    fontSize: "1.1rem",
    marginTop: "40px",
    marginBottom: "10px",
  },
  button: {
    marginLeft: 0,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 34,
    backgroundColor: `${ADMIN_COLOR.base}!important`,
    color: `${ADMIN_COLOR.primary}!important`,
    border: `1px solid ${ADMIN_COLOR.primary}`,
    borderRadius: 0,
    textAlign: "center",
    "&:hover": {
      backgroundColor: `${ADMIN_COLOR.btnHover}!important`,
    },
  },
  editButton: {
    width: 100,
    padding: "4px 10px",
    margin: "10px 20px",
    backgroundColor: ADMIN_COLOR.primary,
    color: ADMIN_COLOR.base,
    borderRadius: 0,
  },
  form: {
    marginTop: "5px!important",
  },
  message: {
    fontSize: "0.9em",
    fontWeight: "bold",
    fontFamily: FONT,
    color: ADMIN_COLOR.primary,
    marginTop: 5,
    marginBottom: 15,
  },
}));

// 加盟店ユーザーリストのインターフェース
interface Props {
  resultsRows: IGuestUserSearchResponse;
  conditionState: GuestSearchCondition;
  setApiErrorMsg: React.Dispatch<React.SetStateAction<string>>;
  setLoadingOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loadingOpen: boolean;
  getUsers: (arg: string) => void;
}

// 加盟店ユーザーリスト表示
const GuestUserSearchResult: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const formClasses = formStyles({
    color: ADMIN_COLOR.base,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const paginationClasses = paginationStyles({
    backgroundColor: ADMIN_COLOR.primary,
    color: ADMIN_COLOR.base,
  });
  const buttonClasses = buttonStyles({
    width: 213,
    marginTop: 15,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const checkboxClasses = checkboxStyles({
    backgroundColor: ADMIN_COLOR.primary,
  });

  const {
    resultsRows,
    conditionState,
    setApiErrorMsg,
    setLoadingOpen,
    loadingOpen,
    getUsers,
  } = props;

  const { authHeader } = useContext(AuthContext);
  const [checkedUserList, setCheckedUserList] = useState<number[]>([]);
  const [errorOpen, setErrorOpen] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [snackOpen, setSnackOpen] = useState<boolean>(false);

  // チェックボックスを押した際の処理
  const handleCheck = (id: number) => {
    const index = checkedUserList.indexOf(id);
    if (index < 0) {
      setCheckedUserList([...checkedUserList, id]);
    } else {
      setCheckedUserList(checkedUserList.filter((userId) => userId !== id));
    }
  };

  // ユーザー削除ボタンを押した際の処理
  const handleDeleteUser = () => {
    if (checkedUserList.length <= 0) {
      setErrorOpen(true);
      return;
    }
    setModalOpen(true);
  };

  // ユーザー削除の確認モーダルで「はい」を押した際の処理
  const onDeleteUser = async () => {
    setModalOpen(false);
    setLoadingOpen(true);
    try {
      // ユーザー削除API実行
      await cognitoUser.deleteCustomerUsers(
        checkedUserList,
        authHeader as string
      );
      // 削除成功時はユーザー一覧再取得
      await getUsers("1");
      setApiErrorMsg("");
      setCheckedUserList([]);
    } catch (error) {
      setApiErrorMsg(getErrorMessage(error));
      throw error;
    } finally {
      setLoadingOpen(false);
    }
    setSnackOpen(true);
  };

  const userList = [
    "会社名",
    "所属加盟店",
    "加盟店分類",
    "ユーザー名",
    "ユーザーID",
    "メールアドレス",
    "ユーザー種別",
  ] as const;

  return (
    <>
      <ConfirmModal
        modalTitle="本当に削除してよろしいですか？"
        actionMethod={onDeleteUser}
        open={modalOpen}
        setOpen={setModalOpen}
        colors={ADMIN_COLOR}
      />
      <CustomSnackbar
        openProps={snackOpen}
        setOpen={setSnackOpen}
        message="ユーザー削除が完了しました。"
        severity="info"
      />
      {resultsRows.result !== undefined && resultsRows.totalCount === 0 && (
        <>
          <div className={classes.messageHeader}>
            {` ${"ユーザーが登録されていません。"}`}
          </div>
        </>
      )}
      {resultsRows.result !== undefined && resultsRows.totalCount !== 0 && (
        <>
          <CustomSnackbar
            openProps={errorOpen}
            setOpen={setErrorOpen}
            message="削除するユーザーを選択してください。"
            severity="error"
          />
          {/* 検索結果件数 */}
          <SearchCountText count={resultsRows.totalCount as number} />
          {/* 検索結果 */}
          <FormControl className={clsx(formClasses.form, classes.form)}>
            <TableContainer>
              <Table className={formClasses.table} aria-label="simple table">
                <TableHead className={formClasses.th}>
                  <TableRow>
                    <TableCell
                      align="center"
                      className={clsx(classes.thCell, classes.check)}
                    >
                      削除
                    </TableCell>
                    {userList.map((user) => (
                      <TableCell
                        key={user}
                        align="center"
                        className={classes.thCell}
                      >
                        {user}
                      </TableCell>
                    ))}
                    <TableCell
                      align="center"
                      className={clsx(classes.thCell, classes.edit)}
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(resultsRows.result as IGuestUserSearchResult[]).map(
                    (resultsRow) => (
                      <TableRow key={resultsRow.userName}>
                        <TableCell
                          align="center"
                          className={clsx(classes.cell, classes.check)}
                        >
                          {/* 削除チェックボックス (本社スーパーユーザーには出さない) */}
                          {resultsRow.userType !==
                            String(
                              USER_TYPE_LIST.SUPER_REPRESENTATIVE_MANAGER
                            ) && (
                            <FormControl className={checkboxClasses.checkbox}>
                              <Checkbox
                                value={resultsRow.id}
                                checked={checkedUserList.includes(
                                  resultsRow.id
                                )}
                                onChange={() => handleCheck(resultsRow.id)}
                              />
                            </FormControl>
                          )}
                        </TableCell>
                        <TableCell align="center" className={classes.cell}>
                          {/* 会社名 */}
                          {resultsRow.contractorName || "-"}
                        </TableCell>
                        <TableCell align="center" className={classes.cell}>
                          {/* 所属加盟店 */}
                          {resultsRow.customerName || "-"}
                        </TableCell>
                        <TableCell align="center" className={classes.cell}>
                          {/* 加盟店分類 */}
                          {extractCustomerTypeFromObject(
                            resultsRow.customerType
                          )}
                        </TableCell>
                        <TableCell align="center" className={classes.cell}>
                          {/* ユーザー名 */}
                          {resultsRow.name || "-"}
                        </TableCell>
                        <TableCell align="center" className={classes.cell}>
                          {/* ユーザーID */}
                          {resultsRow.userName}
                        </TableCell>
                        <TableCell align="center" className={classes.cell}>
                          {/* メールアドレス */}
                          {resultsRow.email}
                        </TableCell>
                        <TableCell align="center" className={classes.cell}>
                          {/* ユーザー種別 */}
                          {USER_TYPE_CONVERT_MAP.get(resultsRow.userType)}
                        </TableCell>
                        {/* 編集ボタン */}
                        <TableCell
                          align="center"
                          className={clsx(classes.cell, classes.edit)}
                        >
                          <Button
                            className={clsx(
                              buttonClasses.button,
                              classes.editButton
                            )}
                            variant="contained"
                            component={Link}
                            to={{
                              pathname: `/dealer/setting/guestUser/${resultsRow.id}/edit`,
                              state: { ...resultsRow },
                            }}
                          >
                            編集
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* ページネーション */}
            <div className={paginationClasses.parent}>
              <Pagination
                count={Math.ceil(
                  (resultsRows.totalCount as number) / SEARCH_PER_PAGE
                )}
                className={clsx(
                  paginationClasses.pagination,
                  paginationClasses.pageCurrent
                )}
                onChange={(e: React.ChangeEvent<unknown>, num) =>
                  getUsers(String(num))
                }
                page={Number(conditionState.pageNumber)}
              />
            </div>
            {/* ユーザー削除ボタン */}
            <Button
              className={clsx(buttonClasses.button, classes.button)}
              variant="contained"
              onClick={handleDeleteUser}
              disabled={loadingOpen} // 二重送信対策
            >
              <PersonAddDisabledIcon className={classes.icon} />
              ユーザー削除
            </Button>
            <div className={classes.message}>
              本社スーパーユーザーは削除対象外です。
            </div>
          </FormControl>
        </>
      )}
    </>
  );
};

export default React.memo(GuestUserSearchResult);
