/**
 * フォーム関係-グリッドのクリアボタン
 */
import React, { useCallback } from "react";
import { MdCleaningServices } from "react-icons/md";

import clsx from "clsx";
import { makeStyles, Button } from "@material-ui/core";

import { buttonStyles } from "../../../common/components/styles";

import { TRequiredKey } from "../../../common/components/types";

import formStore from "../../../../store/formStore";

const useStyles = makeStyles({
  clearIcon: {
    fontSize: "1.6em",
    marginRight: 1,
    position: "relative",
    top: 4,
  },
  button: (props: {
    backgroundColor: string;
    color: string;
    btnHoverColor?: string;
  }) => ({
    float: "right",
    fontSize: "0.8em",
    backgroundColor: props.backgroundColor,
    border: `2px solid ${props.color}`,
    color: props.color,
    padding: 0,
    paddingTop: 2,
    paddingBottom: 4,
    "&:hover": {
      backgroundColor: `${props.btnHoverColor}!important`,
    },
  }),
  buttonContainer: {
    position: "absolute",
    top: 12,
    right: 12,
  },
});

type GridFormColors = {
  color: string;
  backgroundColor: string;
  gridColor: string;
  btnHoverColor?: string;
};

type Props = {
  formGroupNameId: number;
  terminalGridId: number;
  gridId: number;
  requiredKey: TRequiredKey;
  colors: GridFormColors;
};
const GridClearButton: React.FC<Props> = (props: Props) => {
  const { formGroupNameId, terminalGridId, gridId, requiredKey, colors } =
    props;

  const classes = useStyles({
    backgroundColor: colors.color,
    color: colors.backgroundColor,
    btnHoverColor: colors.btnHoverColor,
  });
  const ButtonClasses = buttonStyles({
    width: 80,
    marginTop: 0,
  });

  const handleClick = useCallback(() => {
    formStore.gridClear(formGroupNameId, terminalGridId, gridId, requiredKey);
  }, [formGroupNameId, terminalGridId, gridId, requiredKey]);

  return (
    <div className={classes.buttonContainer}>
      <Button
        className={clsx(ButtonClasses.button, classes.button)}
        onClick={handleClick}
      >
        <MdCleaningServices className={classes.clearIcon} />
        クリア
      </Button>
    </div>
  );
};

export default GridClearButton;
