/**
 * 共通-スナックバー
 * メモ：2つ目のスナックバーを表示したい場合に利用。CustomSnackbarより上方向にずらしてある。
 */
import React, { useMemo } from "react";

import { makeStyles, Snackbar } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import WarningTwoToneIcon from "@material-ui/icons/WarningTwoTone";
import ClearIcon from "@material-ui/icons/Clear";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import { snackbarProps } from "../types";

import { FONT } from "../../../../constants/common";

const useStyles = makeStyles(() => ({
  snackbar: {
    "& .MuiSnackbar-anchorOriginBottomCenter": {
      bottom: "initial",
      top: "40%",
    },
    "& .MuiSnackbarContent-root": {
      bottom: "initial",
      backgroundColor: "#fff",
      color: "red",
      border: "solid",
      borderWidth: "1px",
      boxShadow: "none",
    },
    "& .MuiSnackbarContent-message": {
      fontFamily: FONT,
      textIndent: "3em",
    },
  },
  warnSnackbar: {
    "& .MuiSnackbar-anchorOriginBottomCenter": {
      bottom: "initial",
      top: "40%",
    },
    "& .MuiSnackbarContent-root": {
      bottom: "initial",
      backgroundColor: "#fff",
      color: "#9d7f36",
      border: "solid",
      borderWidth: "2px",
      boxShadow: "none",
    },
    "& .MuiSnackbarContent-message": {
      fontFamily: FONT,
      textIndent: "3em",
    },
  },
  infoSnackbar: {
    "& .MuiSnackbar-anchorOriginBottomCenter": {
      bottom: "initial",
      top: "40%",
    },
    "& .MuiSnackbarContent-root": {
      bottom: "initial",
      backgroundColor: "#fff",
      color: "#4caf50",
      border: "solid",
      borderWidth: "2px",
      boxShadow: "none",
    },
    "& .MuiSnackbarContent-message": {
      fontFamily: FONT,
      textIndent: "3em",
    },
  },
  icon: {
    position: "absolute",
    left: 20,
  },
}));

const SecondSnackbar: React.FC<snackbarProps> = (props: snackbarProps) => {
  const classes = useStyles();

  const { openProps, setOpen, message, severity } = props;

  const snackbarClass = useMemo(() => {
    switch (severity) {
      case "info":
        return classes.infoSnackbar;
      case "warn":
        return classes.warnSnackbar;
      default:
        return classes.snackbar;
    }
  }, [severity, classes]);

  const snackbarIcon = useMemo(() => {
    switch (severity) {
      case "info":
        return <CheckCircleOutlineIcon className={classes.icon} />;
      case "warn":
        return <WarningTwoToneIcon className={classes.icon} />;
      default:
        return <ErrorOutlineIcon className={classes.icon} />;
    }
  }, [severity, classes.icon]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={snackbarClass}>
      <Snackbar
        open={openProps}
        message={message}
        onClose={handleClose}
        transitionDuration={{
          enter: 800,
          exit: 800,
        }}
        action={
          <>
            {snackbarIcon}
            <ClearIcon onClick={handleClose} />
          </>
        }
      />
    </div>
  );
};

export default SecondSnackbar;
