/**
 * 加盟店側-帳票・データ出力画面
 * メモ：帳票・データ出力系の各ボタンを表示する
 */
import React from "react";
import { Link } from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import BillIcon from "@material-ui/icons/LibraryBooksOutlined";

import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { GUEST_COLOR } from "../../../../../constants/common";

import { checkAuth } from "../../../../../utils/auth";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";

const breadCrumbs = [{ name: "帳票・データ出力", link: "" }];

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: 60,
  },
  button: {
    margin: "0 auto",
    marginTop: 40,
    paddingBottom: 10,
    paddingLeft: 30,
    textAlign: "center",
  },
  icon: {
    position: "absolute",
    top: 7,
    left: 35,
  },
}));

const GuestDataPage: React.FC = () => {
  const containerClasses = useContainerStyles();
  const classes = useStyles();
  const buttonClasses = buttonStyles({
    width: 300,
    marginTop: 40,
    backgroundColor: GUEST_COLOR.primary,
  });

  return (
    <Dashboard
      title="帳票・データ出力"
      color={GUEST_COLOR.base}
      backgroundColor={GUEST_COLOR.primary}
      iconName="data"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        <div className={classes.margin}>
          {/* 精算明細書 */}
          {checkAuth("settlementNoteShow") && (
            <Button
              className={clsx(buttonClasses.button, classes.button)}
              variant="contained"
              component={Link}
              to="/guest/data/bill"
            >
              <BillIcon className={classes.icon} />
              精算明細書
            </Button>
          )}
        </div>
      </Container>
    </Dashboard>
  );
};

export default GuestDataPage;
