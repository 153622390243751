/**
 * JTB側 - 端末管理 - 詳細画面
 * 操作ボタンの制御もここで行う
 */
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import {
  withRouter,
  RouteComponentProps,
  useLocation,
  Link,
} from "react-router-dom";

import clsx from "clsx";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  makeStyles,
  Grid,
  Box,
  Chip,
  Button,
  Typography,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import SpeakerNotes from "@material-ui/icons/SpeakerNotes";

import useContainerStyles from "../../../../templates/styles";
import { formStyles, buttonStyles } from "../../../../common/components/styles";

import {
  TFormColumn,
  TJsonRowObject,
  TTerminalFormColumn,
} from "../../../../common/components/types";

import { ADMIN_COLOR, FONT } from "../../../../../constants/common";
import {
  DISPLAY_TERMINAL_OPERATING_STATUS,
  TERMINAL_OPERATING_STATUS_NAME,
  TERMINAL_OPERATING_STATUS,
} from "../../../../../constants/terminal";

import { GlobalPopupContext } from "../../../../../App";
import deal from "../../../../../api/deal";
import terminal from "../../../../../api/terminal";
import preloadFormContent from "../../../../../api/utils/preUtils";
import getErrorMessage from "../../../../../utils/error";
import {
  getTerminalContents,
  json2FormData,
} from "../../../../../utils/formData";
import { checkAuth } from "../../../../../utils/auth";
import formStore from "../../../../../store/formStore";
import { AuthContext } from "../../../../../cognito/AuthContext";

import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import Dashboard from "../../../../templates/Dashboard";
import ShowItemHeading from "../../../../common/components/atoms/ShowItemHeading";
import ShowForm from "../../../../form/components/organisms/ShowForm";
import TabPanel from "../../../../common/components/atoms/TabPanel";

// スタイル設定
const useStyles = makeStyles(() => ({
  h2: {
    fontSize: "1.2em",
    color: ADMIN_COLOR.primary,
    border: `3px solid ${ADMIN_COLOR.primary}`,
    borderBottom: 0,
    paddingTop: 9,
    paddingLeft: 35,
    fontWeight: "bold",
    height: 50,
    fontFamily: FONT,
    backgroundColor: "#fff",
    zIndex: 2,
    lineHeight: "1.7em",
  },
  peopleIcon: {
    verticalAlign: "top",
    fontSize: "1.7rem",
    marginTop: 1,
  },
  speakerNotes: {
    verticalAlign: "top",
    left: -2,
    top: 0,
    position: "relative",
  },
  chip: {
    backgroundColor: ADMIN_COLOR.base,
    color: ADMIN_COLOR.primary,
    border: `1px solid ${ADMIN_COLOR.primary}`,
    top: -1,
    left: 30,
    position: "relative",
    borderRadius: 0,
  },
  box: {
    border: `3px solid ${ADMIN_COLOR.primary}`,
    marginBottom: 15,
    paddingBottom: 13,
    paddingLeft: 13,
    zIndex: 1,
    borderRadius: 0,
  },
  infoMessage: {
    fontSize: "0.9em",
    fontWeight: "bold",
    fontFamily: FONT,
    color: ADMIN_COLOR.primary,
    marginBottom: 15,
  },
  linkButton: {
    textAlign: "center",
  },
  customerData: {
    fontSize: "1.2em",
    color: ADMIN_COLOR.base,
    marginTop: 14,
    paddingTop: 14,
    fontWeight: "bold",
    height: 50,
    fontFamily: FONT,
    zIndex: 2,
    backgroundColor: ADMIN_COLOR.grid,
    position: "relative",
    paddingLeft: 28,
  },
  customerBox: {
    border: `3px solid ${ADMIN_COLOR.grid}`,
    padding: 24,
    paddingBottom: 20,
    marginTop: -24,
    marginBottom: 20,
    zIndex: 1,
    borderRadius: 0,
  },
  th: {
    borderBottom: "2px dotted #ddd",
    position: "relative",
  },
  td: {
    backgroundColor: "#fff",
    color: "#000!important",
    borderBottom: "2px dotted #ddd",
  },
  terminated: {
    backgroundColor: "gray",
  },
}));

const TerminalShowPage: React.FC<RouteComponentProps<{ id: string }>> = (
  props: RouteComponentProps<{ id: string }>
) => {
  const { match } = props;
  const { state } = useLocation(); // 現在の検索条件を受け取る(パンくずで戻る時用)

  const { authHeader } = useContext(AuthContext);
  const { setLoading } = useContext(GlobalPopupContext);
  const [apiErrorMsg, setApiErrorMsg] = useState("");
  const [init, setInit] = useState(false);
  const [displaySettings, setDisplaySettings] = useState({
    operatingStatus: "", // 端末稼働状態
    canChangeFlg: false, // 端末変更申請ボタン制御フラグ
    canTerminateFlg: false, // 端末解約申請ボタン制御フラグ
    dateReflected: "", // 反映日
    dateToBeReflected: "", // 反映予定日
    customerName: "-", // 加盟店名
    customerCode: "-", // 加盟店コード
    termiIdentNum: "", // 端末識別番号
  });

  const classes = useStyles();
  const containerClasses = useContainerStyles();
  const formClasses = formStyles({
    color: "#000",
    backgroundColor: "#fff",
  });
  const buttonClasses = buttonStyles({
    width: 226,
    marginTop: 15,
    backgroundColor: ADMIN_COLOR.primary,
  });

  // パンくずリスト
  const breadCrumbs = useMemo(
    () => [
      { name: "端末管理・検索", link: { pathname: "/dealer/terminal", state } },
      { name: "詳細", link: "" },
    ],
    [state]
  );

  // 端末情報の取得処理
  const getData = useCallback(async () => {
    if (!authHeader) return;
    setLoading(true);
    try {
      // Json取得
      const ret = await deal.getFormMaster(authHeader, "latest");
      await preloadFormContent(authHeader, "latest");
      const data = json2FormData(ret.data.result as TJsonRowObject[]);
      formStore.setFormData(
        data.formData,
        data.formGroupData,
        data.columnToCategory
      );
      // 端末情報報取得、反映
      const showApiRet = await terminal.show(authHeader, match.params.id);
      const showData = getTerminalContents(
        showApiRet.data.result.masterTerminals[0].masterTerminalForms
      );
      formStore.updateContents(showData.updateData, "dealer");

      // 他DB情報の設定
      Object.keys(showData.externalDBData).forEach((columnName) => {
        formStore.setExternalDBData(
          columnName as TFormColumn,
          0, // 端末情報だが、詳細はgridではなく1台ずつなのでterminalGridIdは0固定
          0, // gridIdもJsonによるgrid制御がないため0固定
          showData.externalDBData[columnName as TTerminalFormColumn]
        );
      });
      // ボタンの出しわけ等画面表示に使う項目をセット
      setDisplaySettings({
        operatingStatus:
          showApiRet.data.result.masterTerminals[0].operatingStatus,
        canChangeFlg: showApiRet.data.result.masterTerminals[0].canChangeFlg,
        canTerminateFlg:
          showApiRet.data.result.masterTerminals[0].canTerminateFlg,
        dateReflected: showApiRet.data.result.masterTerminals[0].dateReflected,
        dateToBeReflected:
          showApiRet.data.result.masterTerminals[0].dateToBeReflected,
        customerName: showApiRet.data.result.masterTerminals[0].customerName,
        customerCode: showApiRet.data.result.masterTerminals[0].customerCode,
        termiIdentNum: formStore.getFormRow("termiIdentNum")?.content as string,
      });
      setApiErrorMsg("");
      setLoading(false);
      setInit(true);
      // eslint-disable-next-line
    } catch (error: any) {
      setApiErrorMsg(getErrorMessage(error));
      setLoading(false);
      setInit(true);
    }
  }, [setLoading, authHeader, match.params.id]);

  // データ取得
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <Dashboard
      title="端末管理"
      color={ADMIN_COLOR.base}
      backgroundColor={ADMIN_COLOR.primary}
      iconName="terminal"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {apiErrorMsg !== "" && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {apiErrorMsg}
          </Alert>
        )}
        {init && apiErrorMsg === "" && (
          <>
            <Typography
              variant="h2"
              className={clsx(
                classes.h2,
                // 解約済みの場合グレーアウト
                displaySettings.operatingStatus ===
                  TERMINAL_OPERATING_STATUS.TERMINATED && classes.terminated
              )}
            >
              <PeopleOutlineIcon className={classes.peopleIcon} />
              各種操作
              {/* ステータスのタグ表示 */}
              {DISPLAY_TERMINAL_OPERATING_STATUS.includes(
                displaySettings.operatingStatus
              ) && (
                <Chip
                  label={
                    TERMINAL_OPERATING_STATUS_NAME[
                      displaySettings.operatingStatus
                    ]
                  }
                  color="primary"
                  variant="outlined"
                  className={classes.chip}
                />
              )}
            </Typography>
            {/* 操作ボタン */}
            <Box
              className={clsx(
                classes.box,
                // 解約済みの場合グレーアウト
                displaySettings.operatingStatus ===
                  TERMINAL_OPERATING_STATUS.TERMINATED && classes.terminated
              )}
              borderRadius={1}
            >
              <Grid container spacing={2}>
                {/* 端末変更申請ボタン */}
                {checkAuth("changeDealsCreate") && (
                  <Grid item>
                    <Button
                      className={clsx(buttonClasses.button, classes.linkButton)}
                      type="button"
                      variant="contained"
                      component={Link}
                      to="/dealer/deal/change/new"
                      disabled={!displaySettings.canChangeFlg}
                      onClick={() => {
                        // 加盟店IDをセットする
                        formStore.setCustomerId(null);
                        // マスター端末IDをセットする
                        formStore.setMasterTerminalId(Number(match.params.id));
                        // 端末識別番号をセットする
                        formStore.setTermiIdentNum(
                          Number(displaySettings.termiIdentNum)
                        );
                        // DealTypeをセットする
                        formStore.setDealType("端末変更");
                        // 変更有無チェックボックスリセット
                        formStore.resetChangeableColumnNames();
                      }}
                    >
                      端末変更申請
                    </Button>
                  </Grid>
                )}
                {/* 端末解約申請ボタン */}
                {checkAuth("changeDealsCreate") && (
                  <Grid item>
                    <Button
                      className={clsx(buttonClasses.button, classes.linkButton)}
                      type="button"
                      variant="contained"
                      component={Link}
                      to={{
                        pathname: "/dealer/deal/termination/new",
                        // 解約申請画面で必要な項目（キーがcolumnNameのオブジェクトの形式で渡す）
                        state: { termiIdentNum: displaySettings.termiIdentNum },
                      }}
                      disabled={!displaySettings.canTerminateFlg}
                      onClick={() => {
                        // マスター端末IDをセットする（解約申請系画面のパンくず、端末解約申請登録APIで利用）
                        formStore.setMasterTerminalId(Number(match.params.id));
                        // DealTypeをセットする
                        formStore.setDealType("端末解約");
                      }}
                    >
                      端末解約申請
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  {/* クレジット決済機能情報ボタン */}
                  <Button
                    className={clsx(buttonClasses.button, classes.linkButton)}
                    type="button"
                    variant="contained"
                    component={Link}
                    to={{
                      pathname: `/dealer/terminal/${match.params.id}/info`,
                      state: {
                        pageType: 1,
                        termiIdentNum: displaySettings.termiIdentNum,
                      },
                    }}
                  >
                    クレジット決済機能情報
                  </Button>
                </Grid>
                <Grid item>
                  {/* 電子マネー・QR決済機能情報ボタン */}
                  <Button
                    className={clsx(buttonClasses.button, classes.linkButton)}
                    type="button"
                    variant="contained"
                    component={Link}
                    to={{
                      pathname: `/dealer/terminal/${match.params.id}/info`,
                      state: {
                        pageType: 2,
                        termiIdentNum: displaySettings.termiIdentNum,
                      },
                    }}
                  >
                    電子マネー・QR決済機能情報
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <div className={classes.infoMessage}>
              ※他の申請が処理中の場合は、新規申請作成ボタンが押せない場合があります。必要であれば申請中のものをキャンセルの上、作成してください。
            </div>
            {/* 加盟店情報の表示 */}
            <Typography variant="h2" className={classes.customerData}>
              <SpeakerNotes className={classes.speakerNotes} />
              加盟店情報
            </Typography>
            <Box className={classes.customerBox} borderRadius={16}>
              <TableContainer className={formClasses.tableContainer}>
                <Table>
                  <TableBody>
                    <TableRow className={formClasses.tr}>
                      <TableCell
                        align="left"
                        className={clsx(formClasses.th, classes.th)}
                      >
                        加盟店名
                      </TableCell>
                      <TableCell align="left" className={classes.td}>
                        {displaySettings.customerName
                          ? displaySettings.customerName
                          : "-"}
                      </TableCell>
                    </TableRow>
                    <TableRow className={formClasses.tr}>
                      <TableCell
                        align="left"
                        className={clsx(formClasses.th, classes.th)}
                      >
                        加盟店コード
                      </TableCell>
                      <TableCell align="left" className={classes.td}>
                        {displaySettings.customerCode
                          ? displaySettings.customerCode
                          : "-"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            {/* 表示見出し */}
            <ShowItemHeading
              dateReflected={displaySettings.dateReflected}
              dateToBeReflected={displaySettings.dateToBeReflected}
              colors={ADMIN_COLOR}
            />
            <TabPanel value={0} index={0} borderColor={ADMIN_COLOR.primary}>
              {/* 詳細表示 */}
              <ShowForm
                formCategory={3}
                requiredKey="dealer"
                colors={ADMIN_COLOR}
                terminalGridId={0}
              />
            </TabPanel>
          </>
        )}
      </Container>
    </Dashboard>
  );
};

export default withRouter(TerminalShowPage);
