import { jsonParseSafety } from "../../utils/formData";
import {
  formTypeOptions,
  TFormRowData,
} from "../../views/common/components/types";
import { TFixedNumberProcessResult } from "./types";

/**
 * 業種情報マスターJson
 */
type TIndustryMasterJson = {
  industrySmallClassName: string;
  industrySmallClassCode: string;
  industryLargeClassName: string;
  industryLargeClassCode: string;
  merchandise: string;
  industryCode: number;
  industryName: string;
  merchandiseCode: string;
  customerType: number[];
}[];

/**
 * 業種データ
 */
type TIndustryData = {
  industryLargeClass: string;
  merchandise: string;
  industryCode: number;
  industryName: string;
  merchandiseCode: string;
};

/**
 * 業種データマッピング
 */
const INDUSTRY_CLASS_MAP = new Map<string, TIndustryData>();

/**
 * 小業種選択肢
 */
const INDUSTRY_SMALL_CLASS_OPTIONS_MAP = new Map<number, formTypeOptions[]>();

/**
 * 加工した小業種コード-元の小業種コードマッピング
 */
const INDUSTRY_SMALL_CODE_TO_RAW_CODE = new Map<string, string>();

/**
 * 業種データマッピング作成
 * @param json: 業種情報マスターJson
 */
export const setIndustryDataMap = (json: TIndustryMasterJson) => {
  // 小業種選択肢初期化
  INDUSTRY_SMALL_CLASS_OPTIONS_MAP.clear();
  json.forEach((j) => {
    // 小業種コードが一意でないため加工（大業種コード+小業種コード）
    const smallCode = j.industryLargeClassCode + j.industrySmallClassCode;
    // 小業種選択肢作成
    const sOpt: formTypeOptions = {};
    sOpt[smallCode] = j.industrySmallClassName;

    // 大業種選択肢作成
    const lOpt: formTypeOptions = {};
    lOpt[j.industryLargeClassCode] = j.industryLargeClassName;

    // 業種データ作成（ほぼJsonデータのまま）
    const data: TIndustryData = {
      ...j,
      // 大業種は選択肢をJson文字列にしたもの
      industryLargeClass: JSON.stringify(lOpt),
    };

    // 小分類選択肢を格納
    const customerTypes = j.customerType;
    customerTypes.forEach((c) => {
      const find = INDUSTRY_SMALL_CLASS_OPTIONS_MAP.get(c);
      if (find) {
        find.push(sOpt);
      } else {
        INDUSTRY_SMALL_CLASS_OPTIONS_MAP.set(c, [sOpt]);
      }
    });

    // データを格納
    INDUSTRY_CLASS_MAP.set(smallCode, data);
    INDUSTRY_SMALL_CODE_TO_RAW_CODE.set(smallCode, j.industrySmallClassCode);
  });
};

/**
 * APIで受け取った小業種コードと大業種コードから小業種コードを取得
 * @param rawSmallCode: APIで受け取った小業種コード
 * @param largeCode: 大業種コード
 * @returns 小業種コード
 */
export const convRawCodeToSmallCode = (
  rawSmallCode: string,
  largeCode: string
) => {
  if (!rawSmallCode || !largeCode) return "";
  const smallCode = largeCode + rawSmallCode;
  return INDUSTRY_SMALL_CODE_TO_RAW_CODE.get(smallCode) === rawSmallCode
    ? smallCode
    : "";
};

/**
 * 加工した小業種コードから元の小業種コードを取得
 * @param code: 加工した小業種コード
 * @returns 元の小業種コード
 */
export const convIndustrySmallCodeToRawCode = (code: string) =>
  INDUSTRY_SMALL_CODE_TO_RAW_CODE.get(code) || "";

/**
 * 小業種の選択肢をセット
 * @param data: TFormRowData 加盟店フォーム
 * @returns TFixedNumberProcessResult
 */
export const setIndustrySmallClassOptions: (
  data: TFormRowData
) => TFixedNumberProcessResult = (data: TFormRowData) => {
  const customerType = data.customerType[0];
  const customerTypeObj = jsonParseSafety(customerType.content);
  const customerTypeKey = Object.keys(customerTypeObj)[0];
  const customerTypeNum = parseInt(customerTypeKey, 10);

  if (!customerTypeNum) return [];

  const smallClass = data.industrySmallClass[0];
  return [
    {
      column: "industrySmallClass",
      content: smallClass.content,
      formTypeOptions:
        INDUSTRY_SMALL_CLASS_OPTIONS_MAP.get(customerTypeNum) || [],
    },
  ];
};

/**
 * 小業種による自動入力
 * @param data: TFormRowData 加盟店フォーム
 * @returns TFixedNumberProcessResult
 */
export const autoInputByIndstrySmallClass: (
  data: TFormRowData
) => TFixedNumberProcessResult = (data: TFormRowData) => {
  const smallClass = data.industrySmallClass[0];

  const code = smallClass.content
    ? Object.keys(JSON.parse(smallClass.content))[0]
    : "";

  const iData = INDUSTRY_CLASS_MAP.get(code);

  return [
    {
      column: "industryLargeClass",
      content: iData ? jsonParseSafety(iData.industryLargeClass) : "",
      formTypeOptions: iData ? [jsonParseSafety(iData.industryLargeClass)] : [],
    },
    {
      column: "industryCode",
      content: iData ? iData.industryCode.toString() : "",
    },
    {
      column: "industryName",
      content: iData ? iData.industryName : "",
    },
    {
      column: "merchandiseCode",
      content: iData ? iData.merchandiseCode : "",
    },
    {
      column: "merchandise",
      content: iData ? iData.merchandise : "",
    },
  ];
};
