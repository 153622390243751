/**
 * アプリケーションの元となるコンポーネント
 */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";

import {
  ConfirmModalParams,
  confirmModalProps,
  GlobalPopupHandler,
  SnackbarParams,
  snackbarProps,
} from "./views/common/components/types";

import { STORAGE_KEYS } from "./constants/localStorage";

import { AuthContext } from "./cognito/AuthContext";
import { clearDBSync, deleteItems, initDB } from "./utils/localStorage";
import { getBuildDate } from "./utils/date";

import TopHooks from "./views/admin/top/components/TopHooks";
import TopLoginPage from "./views/top-login/components/pages/TopLoginPage";
import GuestTopHooks from "./views/guest/top/components/GuestTopHooks";
import DealerLoginHooks from "./views/admin/dealer-login/components/DealerLoginHooks";
import GuestLoginHooks from "./views/guest/guest-login/components/GuestLoginHooks";
import OperationEnvironment from "./views/common/components/pages/OperationEnvironment";
import UserAuthObserver from "./views/common/components/atoms/UserAuthObserver";
import Loading from "./views/common/components/molecules/Loading";
import CustomSnackbar from "./views/common/components/atoms/CustomSnackbar";
import ConfirmModal from "./views/common/components/atoms/ConfirmModal";

const stub = (): never => {
  throw new Error(`Need initial method for Context`);
};

export const PathContext = createContext({ prevPath: "", path: "" });
export const GlobalPopupContext = createContext<GlobalPopupHandler>({
  setSnackbar: stub,
  setConfirmModal: stub,
  setLoading: stub,
});

const App: React.FC = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const [pathState, setPathState] = useState({ prevPath: "", path: "" });
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackProps, setSnackbarProps] = useState<snackbarProps | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalProps, setModalProps] = useState<confirmModalProps | null>(null);

  const history = useHistory();
  useEffect(() => {
    // url変化を検知して、現在のパスと一個前のパスを保存
    const unlisten = history.listen(() => {
      const prevPath = pathState.path;
      setPathState({ prevPath, path: history.location.pathname });
    });

    return () => unlisten();
  }, [history, pathState]);

  // Loading表示制御用
  const loadingCount = useRef(0);

  useEffect(() => {
    // ローカルストレージの「ビルド日時」
    const currentBuildDateTime = localStorage.getItem("localBuildDateTime");
    // 最新のビルド日時
    const newBuildDateTime = getBuildDate();
    // 「ローカルストレージビルド日時」と「最新のビルド日時」が等しい場合
    if (currentBuildDateTime && currentBuildDateTime === newBuildDateTime)
      return;

    // 「ローカルストレージビルド日時」と「最新のビルド日時」が異なる場合 と
    // ローカルストレージがない場合
    deleteItems([
      STORAGE_KEYS.DEAL_BASIC,
      STORAGE_KEYS.DEAL_PRE,
      STORAGE_KEYS.CUSTOMER_DEALS,
    ]);
    initDB("dealDraft", 1.0);
    clearDBSync();
    localStorage.setItem("localBuildDateTime", newBuildDateTime);
  }, []);

  const setSnackbar = useCallback((params: SnackbarParams) => {
    setSnackbarOpen(params.openProps);
    setSnackbarProps({ ...params, setOpen: setSnackbarOpen });
  }, []);

  const setConfirmModal = useCallback((params: ConfirmModalParams) => {
    setModalOpen(params.open);
    setModalProps({ ...params, setOpen: setModalOpen });
  }, []);

  const setLoading = useCallback((open: boolean, force?: boolean) => {
    // Loading表示が途中で解除されないように対応
    loadingCount.current += open ? 1 : -1;

    // 強制解除
    if (!!force && !open) loadingCount.current = 0;

    // カウントマイナスの場合は0に戻す
    if (loadingCount.current < 0) loadingCount.current = 0;

    // カウントが1以上であれば表示
    if (loadingCount.current > 0) {
      setLoadingOpen(true);
    } else {
      // 0以下になったら解除
      setLoadingOpen(false);
    }
  }, []);

  return (
    <GlobalPopupContext.Provider
      value={useMemo(
        () => ({ setSnackbar, setConfirmModal, setLoading }),
        [setSnackbar, setConfirmModal, setLoading]
      )}
    >
      <Loading loadingOpen={loadingOpen} />
      {snackbarOpen && snackProps && (
        <CustomSnackbar
          openProps={snackProps.openProps}
          setOpen={setSnackbarOpen}
          message={snackProps.message}
          severity={snackProps.severity}
        />
      )}
      {modalOpen && modalProps && (
        <ConfirmModal
          open={modalOpen}
          setOpen={setModalOpen}
          modalTitle={modalProps.modalTitle}
          actionMethod={modalProps.actionMethod}
          message={modalProps.message}
          okText={modalProps.okText}
          cancelText={modalProps.cancelText}
          colors={modalProps.colors}
        />
      )}
      <PathContext.Provider value={pathState}>
        <UserAuthObserver />
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              if (process.env.REACT_APP_ENV === "local") {
                return <TopLoginPage />;
              }

              return process.env.REACT_APP_DEPLOY_PATH === "jtb" ? (
                <Redirect to="/dealer/login" />
              ) : (
                <Redirect to="/guest/login" />
              );
            }}
          />
        </Switch>
        {/* ローカル環境もしくはJTB側ビルドのみ */}
        {(process.env.REACT_APP_ENV === "local" ||
          process.env.REACT_APP_DEPLOY_PATH === "jtb") && (
          <Switch>
            <Route path="/dealer/login" render={() => <DealerLoginHooks />} />
            <Route
              path="/dealer"
              render={() => {
                if (isAuthenticated) {
                  return <TopHooks />;
                }
                return <Redirect to="/dealer/login" />;
              }}
            />
            {process.env.REACT_APP_ENV !== "local" && (
              <Route
                path="/guest"
                render={() => <Redirect to="/dealer/login" />}
              />
            )}
          </Switch>
        )}
        {/* ローカル環境もしくは加盟店側ビルドのみ */}
        {(process.env.REACT_APP_ENV === "local" ||
          process.env.REACT_APP_DEPLOY_PATH === "customer") && (
          <Switch>
            <Route path="/guest/login" render={() => <GuestLoginHooks />} />
            <Route
              path="/guest"
              render={() => {
                if (isAuthenticated) {
                  return <GuestTopHooks />;
                }
                return <Redirect to="/guest/login" />;
              }}
            />
            {process.env.REACT_APP_ENV !== "local" && (
              <Route
                path="/dealer"
                render={() => <Redirect to="/guest/login" />}
              />
            )}
          </Switch>
        )}
        <Switch>
          <Route
            exact
            path="/environment"
            render={() => <OperationEnvironment />}
          />
        </Switch>
      </PathContext.Provider>
    </GlobalPopupContext.Provider>
  );
};

export default App;
