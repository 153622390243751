/**
 * JTB側-契約者登録完了画面
 */
import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";

import clsx from "clsx";
import { Button, Container, makeStyles } from "@material-ui/core";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";

import {
  buttonStyles,
  completeStyles,
} from "../../../../common/components/styles";
import useContainerStyles from "../../../../templates/styles";

import { ADMIN_COLOR } from "../../../../../constants/common";

import formStore from "../../../../../store/formStore";

import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import Dashboard from "../../../../templates/Dashboard";

const useStyles = makeStyles(() => ({
  Icon: {
    position: "absolute",
    top: 8,
    left: 20,
  },
  buttonBlock: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  returnButton: {
    width: 250,
    "& .MuiButton-label": {
      marginLeft: 34,
    },
  },
}));

const breadCrumbs = [
  { name: "契約者管理・検索", link: "/dealer/contractor" },
  { name: "登録", link: "/dealer/contractor/new" },
  { name: "完了", link: "" },
];

const ContractorNewCompletePage: React.FC = () => {
  const classes = useStyles();
  const containerClasses = useContainerStyles();
  const completeClasses = completeStyles();
  const buttonClasses = buttonStyles({
    width: "100%",
    marginTop: 0,
    backgroundColor: ADMIN_COLOR.primary,
  });

  useEffect(() => {
    formStore.deleteDraft("contractor");
  }, []);

  return (
    <Dashboard
      title="契約者登録"
      color={ADMIN_COLOR.base}
      backgroundColor={ADMIN_COLOR.primary}
      iconName="deal"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {/* 文言 */}
        <div className={completeClasses.message}>登録が完了しました。</div>
        <div className={classes.buttonBlock}>
          {/* ダッシュボードに戻るボタン */}
          <Button
            type="button"
            component={Link}
            to="/dealer"
            className={clsx(buttonClasses.button, classes.returnButton)}
            variant="contained"
          >
            <KeyboardReturnIcon className={classes.Icon} />
            ダッシュボードに戻る
          </Button>
        </div>
      </Container>
    </Dashboard>
  );
};

export default withRouter(ContractorNewCompletePage);
