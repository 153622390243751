/**
 * 加盟店側-メールアドレス変更確認コード入力画面
 * メモ：確認コードを入力して自身のメールアドレスを変更する画面
 */
import React, { useContext, useState } from "react";
import { withRouter, RouteComponentProps, useLocation } from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import EditIcon from "@material-ui/icons/Edit";

import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { EmailEditConfirmItemObject } from "../../types";

import { GUEST_COLOR } from "../../../../../constants/common";

import { AuthContext } from "../../../../../cognito/AuthContext";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import AlertMessage from "../../../../common/components/atoms/AuthAlertMsg";
import Loading from "../../../../common/components/molecules/Loading";
import GuestSettingMailEditConfirmForm from "../organisms/GuestSettingMailEditConfirmForm";

const breadCrumbs = [
  { name: "設定", link: "/guest/setting" },
  { name: "メールアドレス変更", link: "/guest/setting/mail/edit" },
  { name: "確認", link: "" },
];

const useStyles = makeStyles(() => ({
  editButton: {
    margin: "0 auto",
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 10,
    paddingLeft: 68,
  },
  editIcon: {
    position: "absolute",
    top: 8,
    left: 30,
  },
  alertInfo: {
    marginBottom: 20,
  },
}));

const GuestSettingMailEditConfirmPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const containerClasses = useContainerStyles();
  const buttonClasses = buttonStyles({
    width: 180,
    marginTop: 20,
    backgroundColor: GUEST_COLOR.primary,
  });
  const classes = useStyles();

  const { history } = props;
  const { state } = useLocation(); // メールアドレス変更画面から値を受け取る

  const [loadingOpen, setLoadingOpen] = useState(false);
  const [changeMailError, setChangeMailError] = useState("");
  const { confirmChangeEmail } = useContext(AuthContext);

  const [emailEditConfirmState, setEmailEditConfirmState] =
    useState<EmailEditConfirmItemObject>({
      newEmail: state.newEmail, // 送られてきた値を設定（newEmail）
      code: "",
    });
  const { mailEditSkip } = state; // 送られてきた値を設定（mailEditSkip）

  const handleToCompletePage = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    setLoadingOpen(true);

    // CognitoAuthProviderのメールアドレス変更確認メソッドを実行
    const { result, message } = await confirmChangeEmail(emailEditConfirmState);

    setLoadingOpen(false);
    if (result) {
      // 成功した場合、ログイン画面に遷移（ログアウトされるため）
      setChangeMailError("");
      history.push("/guest/login");
      return;
    }
    setChangeMailError(message);
  };

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Dashboard
        title="設定"
        color={GUEST_COLOR.base}
        backgroundColor={GUEST_COLOR.primary}
        iconName="setting"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {/* 注意文言 */}
          {!mailEditSkip && (
            <Alert severity="info" className={classes.alertInfo}>
              メールアドレスを確認するためのコードが、入力されたメールアドレスに送られました。確認コードを入力してください。
            </Alert>
          )}
          {/* エラーメッセージ */}
          {changeMailError && <AlertMessage errorMessage={changeMailError} />}
          {/* 入力フォーム */}
          <GuestSettingMailEditConfirmForm
            emailEditConfirmState={emailEditConfirmState}
            setEmailEditConfirmState={setEmailEditConfirmState}
          />
          {/* 変更するボタン */}
          <Button
            type="submit"
            onClick={handleToCompletePage}
            className={clsx(buttonClasses.button, classes.editButton)}
            variant="contained"
            disabled={loadingOpen} // 二重送信対策
          >
            <EditIcon className={classes.editIcon} />
            変更する
          </Button>
        </Container>
      </Dashboard>
    </>
  );
};

export default withRouter(GuestSettingMailEditConfirmPage);
