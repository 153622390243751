/**
 * 共通-ページネーション
 */
import React from "react";

import clsx from "clsx";
import { Pagination } from "@material-ui/lab";

import { paginationStyles } from "../styles";

type Props = {
  count: number;
  color: string;
  backgroundColor: string;
  onChange: (page: number) => void;
  currentPage?: number;
};

const CustomPagination: React.FC<Props> = (props: Props) => {
  const { count, backgroundColor, color, onChange, currentPage } = props;

  const classes = paginationStyles({ backgroundColor, color });

  return (
    <div className={classes.parent}>
      <Pagination
        count={count}
        className={clsx(classes.pagination, classes.pageCurrent)}
        onChange={(e: React.ChangeEvent<unknown>, num) => {
          onChange(num);
        }}
        page={currentPage}
      />
    </div>
  );
};

CustomPagination.defaultProps = {
  currentPage: 1,
};

export default CustomPagination;
