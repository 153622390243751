/**
 * 可変フォーム関連の設定値
 */

/**
 * 申請タブの内容一覧
 */
export const DEAL_FORM_TAB_NAMES = {
  DEAL_CONTENT: "申請内容",
  CONTRACTOR_INFO: "契約者情報",
  CUSTOMER_INFO: "加盟店情報",
  TERMINAL_INFO: "端末情報",
  /** 重要事項チェックシート */
  IMPORTANT_INFO: "重要事項",
  COMMENT_AND_MEMO: "コメント・メモ",
  /** チェックリスト（画面上もチェックリストにする） */
  CHECK_LIST: "チェックリスト",
  VARIOUS_OPERATION: "各種操作",
} as const;

/**
 * フォームカテゴリー（formCategory）
 */
export const FORM_CATEGORY = {
  /** 契約者情報 */
  CONTRACTOR: 1,
  /** 加盟店情報 */
  CUSTOMER: 2,
  /** 端末情報 */
  TERMINAL: 3,
  /** 重要事項チェックシート */
  CHECK_SHEET: 4,
  /** 申請管理情報 */
  DEAL_MANEGE: 5,
  /** 審査時チェックリスト */
  CHECK_LIST: 6,
} as const;

export default DEAL_FORM_TAB_NAMES;
