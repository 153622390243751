import { CognitoUser } from "amazon-cognito-identity-js";

export interface IDecodedJWTToken {
  // 公式のdecodePayload()メソッドの返り値の型が下記のanyが含まれた形のため、eslintをdisableにする。
  // eslint-disable-next-line
  [id: string]: any;
}

export interface IAuthState {
  isAuthenticated: boolean;
  isCustomer: boolean | undefined;
  user?: CognitoUser;
  userAttributes?: IDecodedJWTToken;
  authHeader?: string;
  challengeName?: string;
  loginError?: JSX.Element;
  isInitialized?: boolean;
}

const initialState: IAuthState = {
  isAuthenticated: false,
  isCustomer: undefined,
};
const stub = (): never => {
  throw new Error("Need initial method for <CognitoAuthProvider>");
};
export const initialContext = {
  ...initialState,
  signIn: stub,
  sendResetCode: stub,
  resetPassword: stub,
  renewDefaultPassword: stub,
  confirmMfaCode: stub,
  changeEmail: stub,
  confirmChangeEmail: stub,
  changePassword: stub,
  changeUserPhoneNum: stub,
  confirmChangeUserPhoneNum: stub,
  signOut: stub,
  sendUserList: stub,
  setLoginError: stub,
  reloadCurrentSession: stub,
};
