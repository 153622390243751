// ローカルストレージユーティリティ
import localforage from "localforage";

// 渡されたキーのlocalStorageを全て削除する。
export const deleteItems: (deleteStorageArray: string[]) => void = (
  deleteStorageArray: string[]
) => {
  for (let i = 0; i < deleteStorageArray.length; i += 1) {
    if (localStorage.getItem(deleteStorageArray[i]))
      localStorage.removeItem(deleteStorageArray[i]);
  }
};

// IndexedDB(localforage)の準備
export const initDB: (dbName: string, version: number) => void = (
  dbName: string,
  version: number
) => {
  localforage.config({
    name: dbName,
    version,
  });
};

export const setItemInDB: (key: string, value: unknown) => Promise<unknown> = (
  key: string,
  value: unknown
) => localforage.setItem(key, value);

export const setItemInDBSync: (key: string, value: unknown) => unknown = async (
  key: string,
  value: unknown
) => {
  const ret = await setItemInDB(key, value);
  return ret;
};

export const getItemFromDB: (key: string) => Promise<unknown> = (key: string) =>
  localforage.getItem(key);

export const getItemFromDBSync: (key: string) => unknown = async (
  key: string
) => {
  const ret = await getItemFromDB(key);
  return ret;
};

export const removeItemFromDB: (key: string) => Promise<void> = (key: string) =>
  localforage.removeItem(key);

export const removeItemFromDBSync: (key: string) => void = async (
  key: string
) => {
  await removeItemFromDB(key);
};

export const clearDB: () => Promise<void> = () => localforage.clear();

export const clearDBSync: () => void = async () => {
  await clearDB();
};

export default deleteItems;
