/**
 * 共通-申請の処理者、ワークフローステータスのラベル
 */
import React from "react";

import clsx from "clsx";
import { Chip, makeStyles } from "@material-ui/core";

import {
  WORKFLOW_STAFF_LABELS,
  ADMIN_COLOR,
  GUEST_COLOR,
} from "../../../../constants/common";
import {
  WORKFLOW_STATUS_IN_CUSTOMER,
  WORKFLOW_STATUS_IN_DEALER,
} from "../../../../constants/workflowStatus";
import { getWorkflowStatusName } from "../../../../utils/workflowStatus";
import { TDealTypeNum } from "../types";

const useStyles = makeStyles(() => ({
  customerChip: {
    backgroundColor: GUEST_COLOR.primary,
    color: GUEST_COLOR.base,
  },
  dealChip: {
    backgroundColor: ADMIN_COLOR.primary,
    color: ADMIN_COLOR.base,
  },
  staffChip: {
    top: -1,
    left: 30,
    position: "relative",
    borderRadius: 0,
    border: "none",
  },
  workflowChip: {
    backgroundColor: GUEST_COLOR.base,
    top: -1,
    left: 40,
    position: "relative",
    borderRadius: 0,
  },
  adminColor: {
    color: ADMIN_COLOR.primary,
    borderColor: ADMIN_COLOR.primary,
  },
  guestColor: {
    color: GUEST_COLOR.primary,
    borderColor: GUEST_COLOR.primary,
  },
}));

const getWorkflowStaffLabel: (status: number) => string = (status: number) => {
  if (WORKFLOW_STATUS_IN_DEALER.includes(status)) {
    return WORKFLOW_STAFF_LABELS.DEALER;
  }
  if (WORKFLOW_STATUS_IN_CUSTOMER.includes(status)) {
    return WORKFLOW_STAFF_LABELS.CUSTOMER;
  }
  return "";
};

interface Props {
  workflowState: number;
  dealType: TDealTypeNum;
}

const DealWorkflowStatus: React.FC<Props> = (props: Props) => {
  const { workflowState, dealType } = props;

  const classes = useStyles();
  const staff = getWorkflowStaffLabel(workflowState);
  const label = getWorkflowStatusName(dealType, String(workflowState));
  return (
    <>
      {staff && (
        <Chip
          label={staff}
          color="primary"
          variant="outlined"
          className={clsx(
            classes.staffChip,
            staff === WORKFLOW_STAFF_LABELS.CUSTOMER
              ? classes.customerChip
              : classes.dealChip
          )}
        />
      )}
      <Chip
        label={label}
        color="primary"
        variant="outlined"
        className={clsx(
          classes.workflowChip,
          staff === WORKFLOW_STAFF_LABELS.CUSTOMER
            ? classes.guestColor
            : classes.adminColor
        )}
      />
    </>
  );
};

export default DealWorkflowStatus;
