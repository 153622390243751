/**
 * JTB側-自社ユーザー追加入力フォーム
 * メモ：入力フォームを表示する
 */
import React from "react";

import {
  makeStyles,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";

import { UserNewItemObject } from "../../types";

import {
  ADMIN_COLOR,
  DISPLAY_NAME_MAX_LENGTH,
  EMAIL_MAX_LENGTH,
  PHONE_NUMBER_MAX_LENGTH,
  MEMO_MAX_LENGTH,
  FONT,
} from "../../../../../constants/common";
import { JTB_USER_TYPE_NAME_LIST } from "../../../../../constants/user";

import { isCardCompanyUser } from "../../../../../utils/auth";

import CustomSelect from "../../../../common/components/molecules/CustomSelect";
import CustomTextField from "../../../../common/components/atoms/CustomTextField";

const useStyles = makeStyles(() => ({
  table: {
    width: 800,
    minWidth: 800,
  },
  td: {
    padding: 1,
    backgroundColor: ADMIN_COLOR.base,
    color: "#000!important",
    borderTop: "1px solid #d2d5d8",
    borderBottom: "1px solid #d2d5d8",
    borderRight: "1px solid #d2d5d8",
    width: 400,
  },
  th: {
    padding: 0,
    fontFamily: FONT,
    backgroundColor: ADMIN_COLOR.base,
    color: "#000!important",
    width: 200,
    border: "1px solid #d2d5d8",
    position: "relative",
    paddingLeft: 20,
  },
  formControl: {
    marginTop: "0px!important",
  },
  lengthText: {
    color: "#777",
    fontFamily: FONT,
    fontSize: "0.7rem",
    fontWeight: 400,
    paddingLeft: 7,
  },
  infoText: {
    color: "#777",
    fontFamily: FONT,
    fontSize: "0.7rem",
    fontWeight: 400,
  },
}));

interface Props {
  userNewState: UserNewItemObject;
  setUserNewState: React.Dispatch<React.SetStateAction<UserNewItemObject>>;
}

const SettingUserNewForm: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const { userNewState, setUserNewState } = props;

  return (
    <FormControl className={classes.formControl}>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            {/* ユーザー種別 */}
            {/* JTB社ユーザーでない場合はユーザー種別を非表示 */}
            {!isCardCompanyUser() && (
              <TableRow key="ユーザー種別">
                <TableCell align="left" className={classes.th}>
                  ユーザー種別
                </TableCell>
                <TableCell align="left" className={classes.td}>
                  <CustomSelect
                    width="100%"
                    value={userNewState.userType}
                    pulldownArray={[...JTB_USER_TYPE_NAME_LIST]}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setUserNewState({
                        ...userNewState,
                        userType: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
            )}
            {/* メールアドレス */}
            <TableRow key="メールアドレス">
              <TableCell align="left" className={classes.th}>
                メールアドレス
                <span
                  className={classes.lengthText}
                >{`(最大${EMAIL_MAX_LENGTH}文字)`}</span>
              </TableCell>
              <TableCell align="left" className={classes.td}>
                <CustomTextField
                  type="email"
                  value={userNewState.email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUserNewState({ ...userNewState, email: e.target.value })
                  }
                />
              </TableCell>
            </TableRow>
            {/* ユーザー名 */}
            <TableRow key="ユーザー名">
              <TableCell align="left" className={classes.th}>
                ユーザー名
                <span
                  className={classes.lengthText}
                >{`(最大${DISPLAY_NAME_MAX_LENGTH}文字)`}</span>
              </TableCell>
              <TableCell align="left" className={classes.td}>
                <CustomTextField
                  type="text"
                  value={userNewState.name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUserNewState({ ...userNewState, name: e.target.value })
                  }
                />
              </TableCell>
            </TableRow>
            {/* 電話番号 */}
            <TableRow key="電話番号">
              <TableCell align="left" className={classes.th}>
                電話番号
                <span
                  className={classes.lengthText}
                >{`(最大${PHONE_NUMBER_MAX_LENGTH}文字)`}</span>
              </TableCell>
              <TableCell align="left" className={classes.td}>
                <CustomTextField
                  type="tel"
                  value={userNewState.phoneNumber}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUserNewState({
                      ...userNewState,
                      phoneNumber: e.target.value,
                    })
                  }
                />
              </TableCell>
            </TableRow>
            {/* メモ欄 */}
            <TableRow key="メモ欄">
              <TableCell align="left" className={classes.th}>
                メモ欄
                <span
                  className={classes.lengthText}
                >{`(任意入力。最大${MEMO_MAX_LENGTH}文字)`}</span>
                <div className={classes.infoText}>役割など</div>
              </TableCell>
              <TableCell align="left" className={classes.td}>
                <CustomTextField
                  type="text"
                  value={userNewState.memo}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUserNewState({ ...userNewState, memo: e.target.value })
                  }
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </FormControl>
  );
};

export default SettingUserNewForm;
