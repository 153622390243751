/**
 * 共通-ログイン系画面のロゴ
 */
import React from "react";

import { makeStyles } from "@material-ui/core";

import logo from "../../../assets/img/brand/logo.png";

const useStyles = makeStyles(() => ({
  logo: {
    textAlign: "center",
    margin: 25,
    marginBottom: 20,
    marginTop: 30,
  },
}));

const LogoForLogin: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.logo}>
      <img src={logo} width="130" height="auto" alt="JTB" />
    </div>
  );
};

export default LogoForLogin;
