import { isJtbUser } from "../../utils/auth";
import { TFormColumn, TFormRowData } from "../../views/common/components/types";
import { TFixedNumberProcessResult } from "./types";

const STORE_CONFIRMATION_COLUMNS: TFormColumn[] = [
  "storeConfirmation1",
  "storeConfirmation2",
  "storeConfirmation3",
];

export const requiredByCustomerUrl: (
  data: TFormRowData
) => TFixedNumberProcessResult = (data: TFormRowData) => {
  const customerUrl = data.customerUrl[0];
  const isRequired = !customerUrl.content;

  const ret: TFixedNumberProcessResult = [];

  const forms = STORE_CONFIRMATION_COLUMNS.map((f) => data[f][0]);

  forms.forEach((f) => {
    ret.push({
      column: f.column,
      content: f.content,
      isRequired: isRequired && !isJtbUser(),
    });
  });

  return ret;
};

export default requiredByCustomerUrl;
