// APIで返ってくる型は業務外の付与情報がついているのでフロントでは型を明確に定義しない。
import axios from "axios";
import {
  IRegisterChangeDealInput,
  IUpdateChangeDealInput,
  IUpdateChangeOrTerminateDealWorkflowInput,
} from "./types";
import {
  createAPIPromise,
  createLoadingPromise,
  loadingUrls,
} from "./utils/common";

/**
 * formMaster情報取得API
 * 共通なのでdealにあるgetFormMasterを使用する
 */

/**
 * 変更申請キャンセルAPI
 * @param authHeader 認証トークン
 * @param id 申請id
 * @returns
 */
const cancelChangeDeal = (authHeader: string, id: string) => {
  const path = `/changeDeals/${id}/cancel`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.put(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

/**
 * 変更申請登録API
 * 変更申請の種類により必須となるデータが違う事に注意、API設計書も要確認
 * @param authHeader: 認証トークン
 * @param dealPostData: 変更申請POSTデータ
 */
const storeChangeDeal = async (
  authHeader: string,
  dealPostData: IRegisterChangeDealInput
  // eslint-disable-next-line
): Promise<any> => {
  const path = `/changeDeals`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);

  return createAPIPromise(
    axios.post(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      {
        ...dealPostData,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

/**
 * 変更申請詳細情報取得API
 * 変更申請の詳細画面にて表示するためのデータ取得に使用
 * @param authHeader: 認証トークン
 * @param id: 申請ID
 */
const showChangeDeal = async (authHeader: string, id: string) => {
  const path = `/changeDeals/info/${id}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    path
  );
};

/**
 * 変更申請入力情報取得API
 * 変更申請の編集画面にて表示するためのデータ取得に使用
 * @param authHeader: 認証トークン
 * @param id: 申請ID
 */
const editChangeDeal = async (authHeader: string, id: string) => {
  const path = `/changeDeals/${id}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    path
  );
};

/**
 * 追加資料リスト取得API
 * 共通なのでdealにあるgetExtraUploadFilesを使用する
 */

/**
 * 変更申請更新（編集）API
 * @param authHeader: 認証トークン
 * @param id: 申請ID
 * @param dealObject: 申請オブジェクト
 */
const updateChangeDeal = async (
  authHeader: string,
  id: string,
  dealObject: IUpdateChangeDealInput
  // eslint-disable-next-line
): Promise<any> => {
  const path = `/changeDeals/${id}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.put(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      { ...dealObject },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

/**
 * 申請情報pdf保管API
 * 共通なのでdealにあるstoreDealPdfを使用する
 */

/**
 * 加盟店ユーザーリスト取得API
 * 共通なのでdealにあるgetDealCustomerUserを使用する
 */

/**
 * ユーザーメッセージ送信API
 * 共通なのでdealにあるsendUserMessageを使用する
 */

/**
 * コメントAPI
 * 共通なのでdealにあるupdateFormCommentを使用する
 */

/**
 * 変更申請ワークフローステータス進行API
 * @param authHeader: 認証トークン
 * @param id: 申請ID
 * @param workflowStatusPutData: 変更申請ワークフローステータス進行PUTデータ
 */
const updateWorkflow = async (
  authHeader: string,
  id: string,
  workflowStatusPutData: IUpdateChangeOrTerminateDealWorkflowInput
  // eslint-disable-next-line
): Promise<any> => {
  const path = `/changeDeals/workflow/${id}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.put(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      { ...workflowStatusPutData },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

export default {
  cancelChangeDeal,
  showChangeDeal,
  editChangeDeal,
  storeChangeDeal,
  updateChangeDeal,
  updateWorkflow,
};
