type Callback = () => void;

class CognitoListener {
  private listener: Map<string, Callback>;

  // トークン期限切れフラグ
  private expiredTokenFlag: boolean;

  constructor() {
    this.listener = new Map();
    this.expiredTokenFlag = false;
  }

  // 関数登録
  public addListener(key: string, func: Callback) {
    // すでに存在したら上書き
    if (this.listener.has(key)) {
      this.listener.delete(key);
    }
    this.listener.set(key, func);
  }

  // 登録した関数の削除
  public removeListener(key: string) {
    if (this.listener.has(key)) this.listener.delete(key);
  }

  // 登録した関数呼び出し
  public emit(key: string) {
    const func = this.listener.get(key);
    if (func) {
      func();
    }
  }

  // トークン期限切れフラグを取得
  public getExpiredTokenFlag(): boolean {
    return this.expiredTokenFlag;
  }

  // トークン期限切れフラグを設定
  public setExpiredTokenFlag(flag: boolean) {
    this.expiredTokenFlag = flag;
  }
}

// singleton
const cognitoListener = new CognitoListener();
export default cognitoListener;
