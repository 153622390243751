/**
 * JTB側-申請情報表示部分
 * メモ：変更申請詳細、解約申請詳細の申請情報
 */
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import moment from "moment";
import clsx from "clsx";
import {
  Box,
  Typography,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import SpeakerNotes from "@material-ui/icons/SpeakerNotes";

import { formStyles } from "../styles";

import { ADMIN_COLOR, FONT } from "../../../../constants/common";

// スタイル設定
const useStyles = makeStyles(() => ({
  speakerNotes: {
    verticalAlign: "top",
    left: -2,
    top: 0,
    position: "relative",
  },
  infoData: {
    fontSize: "1.2em",
    color: ADMIN_COLOR.base,
    marginTop: 14,
    paddingTop: 14,
    fontWeight: "bold",
    height: 50,
    fontFamily: FONT,
    zIndex: 2,
    backgroundColor: ADMIN_COLOR.grid,
    position: "relative",
    paddingLeft: 28,
  },
  infoDataBox: {
    border: `3px solid ${ADMIN_COLOR.grid}`,
    padding: 24,
    paddingBottom: 20,
    marginTop: -24,
    marginBottom: 20,
    zIndex: 1,
    borderRadius: 0,
  },
  th: {
    borderBottom: "2px dotted #ddd",
    position: "relative",
  },
  td: {
    backgroundColor: "#fff",
    color: "#000!important",
    borderBottom: "2px dotted #ddd",
  },
}));

interface Props extends RouteComponentProps {
  applicationDate: string;
  lastApprovalDate: string;
  cancelDateAndTime: string;
  cancelComment: string;
}

const DealInfoData: React.FC<Props> = (props: Props) => {
  const {
    applicationDate,
    lastApprovalDate,
    cancelDateAndTime,
    cancelComment,
  } = props;

  const classes = useStyles();
  const formClasses = formStyles({
    color: "#000",
    backgroundColor: "#fff",
  });

  return (
    <>
      {/* 申請情報の表示 */}
      <Typography variant="h2" className={classes.infoData}>
        <SpeakerNotes className={classes.speakerNotes} />
        申請情報
      </Typography>
      <Box className={classes.infoDataBox} borderRadius={16}>
        <TableContainer className={formClasses.tableContainer}>
          <Table>
            <TableBody>
              <TableRow className={formClasses.tr}>
                <TableCell
                  align="left"
                  className={clsx(formClasses.th, classes.th)}
                >
                  申請日時
                </TableCell>
                <TableCell align="left" className={classes.td}>
                  {applicationDate
                    ? moment(applicationDate, "YYYY/MM/DD HH:mm:ss").format(
                        "YYYY/MM/DD HH:mm:ss"
                      )
                    : ""}
                </TableCell>
              </TableRow>
              <TableRow className={formClasses.tr}>
                <TableCell
                  align="left"
                  className={clsx(formClasses.th, classes.th)}
                >
                  申請承認日時
                </TableCell>
                <TableCell align="left" className={classes.td}>
                  {lastApprovalDate
                    ? moment(lastApprovalDate).format("YYYY/MM/DD HH:mm:ss")
                    : ""}
                </TableCell>
              </TableRow>
              <TableRow className={formClasses.tr}>
                <TableCell
                  align="left"
                  className={clsx(formClasses.th, classes.th)}
                >
                  申請キャンセル日時
                </TableCell>
                <TableCell align="left" className={classes.td}>
                  {cancelDateAndTime
                    ? moment(cancelDateAndTime, "YYYY/MM/DD HH:mm:ss").format(
                        "YYYY/MM/DD HH:mm:ss"
                      )
                    : ""}
                </TableCell>
              </TableRow>
              {!!cancelComment && (
                <TableRow className={formClasses.tr}>
                  <TableCell
                    align="left"
                    className={clsx(formClasses.th, classes.th)}
                  >
                    キャンセル時の変更申請内容
                  </TableCell>
                  <TableCell align="left" className={classes.td}>
                    {cancelComment}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default withRouter(DealInfoData);
