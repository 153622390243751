import React, { useEffect, useState } from "react";
import { Table, TableBody, TableContainer } from "@material-ui/core";
import { ThemeColorProps } from "../../../../constants/common";
import formStore from "../../../../store/formStore";
import TableHeading from "../../../common/components/atoms/TableHeading";
import { formStyles } from "../../../common/components/styles";
import { TFormColumn, TRequiredKey } from "../../../common/components/types";
import ChangeDealFormTableRow from "../atoms/ChangeDealFormTableRow";
import runFixedNumberProcessForChangeDeal from "../../../../form/fixedNumberProcessForChangeDeal/fixedNumberProcessForChangeDeal";

type Props = {
  formGroupNameId: number;
  requiredKey: TRequiredKey;
  terminalGridId: number | undefined;
  idx: number;
  colors: ThemeColorProps;
};
const ChangeDealFormGroup: React.FC<Props> = (props: Props) => {
  const { formGroupNameId, requiredKey, terminalGridId, idx, colors } = props;
  const [groupData, setGroupData] = useState(
    formStore.getFormGroupDataForChangeRequest(formGroupNameId)
  );
  const [checkboxStates, setCheckboxStates] = useState<TFormColumn[]>([]);
  const formClasses = formStyles({
    color: colors.base,
    backgroundColor: colors.primary,
  });

  const handleCheckboxChange = (columnName: TFormColumn, checked: boolean) => {
    const targetList = runFixedNumberProcessForChangeDeal(
      columnName,
      checkboxStates,
      checked
    );
    if (checked) {
      setCheckboxStates([...checkboxStates, ...targetList]);
    } else {
      setCheckboxStates(
        checkboxStates.filter((item) => !targetList.includes(item))
      );
    }
  };

  useEffect(() => {
    const fn = () => {
      setGroupData(formStore.getFormGroupDataForChangeRequest(formGroupNameId));
    };

    formStore.addListener(formGroupNameId.toString(), fn);

    return () => formStore.removeListener(formGroupNameId.toString(), fn);
  }, [formGroupNameId]);

  useEffect(() => {
    const newStates = formStore
      .getChangeableColumnNames()
      .map((item) => item.columnName);
    setCheckboxStates([...checkboxStates, ...newStates]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!groupData) return null;

  return (
    <>
      <div className={formClasses.tableSpacing}>
        <TableHeading
          colors={{
            color: colors.base,
            backgroundColor: colors.primary,
          }}
          title={groupData.formGroupName}
          key={groupData.formGroupNameId}
          index={idx}
        />
      </div>

      <TableContainer className={formClasses.tableContainer}>
        <Table>
          <TableBody>
            {groupData.forms.map((form) => (
              <ChangeDealFormTableRow
                key={form.columnName}
                columnName={form.columnName}
                requiredKey={requiredKey}
                colors={colors}
                formType={form.formType}
                terminalGridId={terminalGridId}
                gridId={undefined}
                checked={checkboxStates.includes(form.columnName) ?? false}
                handleCheckboxChange={handleCheckboxChange}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ChangeDealFormGroup;
