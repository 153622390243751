/**
 * 加盟店側-パスワードリセット入力フォーム
 * メモ：入力フォームを表示する
 */
import React from "react";

import { FormControl } from "@material-ui/core";

import { loginFormStyles } from "../../../../common/components/styles";

import { PasswordResetItemObject } from "../../types";

import CustomTextField from "../../../../common/components/atoms/CustomTextField";

interface Props {
  passwordResetState: PasswordResetItemObject;
  setPasswordResetState: React.Dispatch<
    React.SetStateAction<PasswordResetItemObject>
  >;
}

const GuestPasswordResetForm: React.FC<Props> = (props: Props) => {
  const loginFormClasses = loginFormStyles();

  const { passwordResetState, setPasswordResetState } = props;

  return (
    <>
      {/* ユーザーID */}
      <FormControl className={loginFormClasses.form}>
        <CustomTextField
          value={passwordResetState.username}
          placeholder="ユーザーIDを入力"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPasswordResetState({
              ...passwordResetState,
              username: e.target.value,
            })
          }
        />
      </FormControl>
      {/* 確認コード */}
      <FormControl className={loginFormClasses.form}>
        <CustomTextField
          value={passwordResetState.code}
          placeholder="確認コードを入力"
          autoComplete="new-password"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPasswordResetState({
              ...passwordResetState,
              code: e.target.value,
            })
          }
        />
      </FormControl>
      {/* パスワード */}
      <FormControl className={loginFormClasses.form}>
        <CustomTextField
          type="password"
          value=""
          placeholder="新しいパスワードを入力"
          autoComplete="new-password"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPasswordResetState({
              ...passwordResetState,
              newPassword: e.target.value,
            })
          }
        />
      </FormControl>
    </>
  );
};

export default GuestPasswordResetForm;
