/**
 * 共通-「\n」を改行するテキストコンポーネント
 */
import React from "react";

const LineBreaksText: React.FC<{ text: string }> = (props: {
  text: string;
}) => {
  const { text } = props;

  const split = text.split("\n");

  return (
    <>
      {split.map((value) => (
        <div key={value}>{value}</div>
      ))}
    </>
  );
};

export default LineBreaksText;
