import { ITermData } from "../views/guest/term/types";

/**
 * 規約番号
 */
export const TERM_TYPE = {
  /** 端末機使用特約.pdf */
  STERA: "10",
  /** JTBクレジットカード一括加盟店 決済サービス 加盟店規約（宿泊施設）.pdf */
  JTB_HOTEL: "20",
  /** JTBクレジットカード一括加盟店 決済サービス 加盟店規約（JTB提携販売店）.pdf */
  JTB_PARTNER: "30",
  /** JTBクレジットカード一括加盟店 決済サービス 加盟店規約（JTBたびなかネット スタンプ）.pdf */
  JTB_STAMP: "40",
  /** 取引照会WEB21利用に関するご案内.pdf */
  WEB_21: "50",
  /** コード決済 サービスの利用に関する 特約.pdf */
  CODE_PAYMENT: "60",
  /** 電子マネー 決済サービスの利用に関する 特約.pdf */
  E_MONEY: "70",
} as const;

/**
 * 規約確認画面用データ（version,updatedAtはbackendから受け取る）
 */
export const LATEST_TERMS_DATA: ITermData[] = [
  {
    termsNumber: TERM_TYPE.STERA,
    name: "端末機使用特約",
    path: TERM_TYPE.STERA,
    version: "",
    updatedAt: "",
    isAnotherTab: false,
    order: 4,
  },
  {
    termsNumber: TERM_TYPE.JTB_HOTEL,
    name: "JTBクレジットカード一括加盟店 決済サービス 加盟店規約（宿泊施設）",
    path: TERM_TYPE.JTB_HOTEL,
    version: "",
    updatedAt: "",
    isAnotherTab: false,
    order: 1,
  },
  {
    termsNumber: TERM_TYPE.JTB_PARTNER,
    name: "JTBクレジットカード一括加盟店 決済サービス 加盟店規約（JTB提携販売店）",
    path: TERM_TYPE.JTB_PARTNER,
    version: "",
    updatedAt: "",
    isAnotherTab: false,
    order: 3,
  },
  {
    termsNumber: TERM_TYPE.JTB_STAMP,
    name: "JTBクレジットカード一括加盟店 決済サービス 加盟店規約（JTBたびなかネット スタンプ）",
    path: TERM_TYPE.JTB_STAMP,
    version: "",
    updatedAt: "",
    isAnotherTab: false,
    order: 2,
  },
  {
    termsNumber: TERM_TYPE.WEB_21,
    name: "取引照会WEB21利用に関するご案内",
    path: TERM_TYPE.WEB_21,
    version: "",
    updatedAt: "",
    isAnotherTab: false,
    order: 7,
  },
  {
    termsNumber: TERM_TYPE.CODE_PAYMENT,
    name: "コード決済 サービスの利用に関する 特約",
    path: TERM_TYPE.CODE_PAYMENT,
    version: "",
    updatedAt: "",
    isAnotherTab: false,
    order: 6,
  },
  {
    termsNumber: TERM_TYPE.E_MONEY,
    name: "電子マネー 決済サービスの利用に関する 特約",
    path: TERM_TYPE.E_MONEY,
    version: "",
    updatedAt: "",
    isAnotherTab: false,
    order: 5,
  },
];
