/**
 * 共通-ローディング中表示
 */
import React, { useEffect, useState } from "react";

import { makeStyles, Backdrop, CircularProgress } from "@material-ui/core";

import { FONT } from "../../../../constants/common";

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 1400,
    color: "#fff",
  },
  loading: {
    fontSize: "2.3rem",
  },
  loadingMessage: {
    fontSize: "1.8rem",
    fontFamily: FONT,
    textIndent: "0.5rem",
  },
  progress: {
    marginLeft: 245,
  },
}));

interface Props {
  loadingOpen: boolean;
}

// 本コンポーネントは非同期関数(Promiseなど)の処理に組み込むこと。
const Loading: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const { loadingOpen } = props;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(loadingOpen);
  }, [loadingOpen]);

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress className={classes.progress} color="inherit" />
    </Backdrop>
  );
};

export default Loading;
