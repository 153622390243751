/**
 * 加盟店側-規約一覧画面
 * メモ：規約確認用の規約を一覧表示
 */
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import InfoIcon from "@material-ui/icons/Info";

import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { ITermData } from "../../types";

import { FONT, GUEST_COLOR } from "../../../../../constants/common";
import { LATEST_TERMS_DATA } from "../../../../../constants/terms";

import terms from "../../../../../api/terms";
import { AuthContext } from "../../../../../cognito/AuthContext";
import { GlobalPopupContext } from "../../../../../App";
import getErrorMessage from "../../../../../utils/error";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";

const useStyles = makeStyles({
  termLink: {
    textDecoration: "none",
  },
  button: {
    paddingLeft: 10,
    paddingBottom: 10,
    textTransform: "none",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
    width: "100%",
  },
  updateAt: {
    width: 200,
    marginTop: 10,
    marginLeft: 20,
  },
  message: {
    fontFamily: FONT,
    textAlign: "center",
    fontSize: "1.0rem",
    marginTop: "40px",
    marginBottom: "50px",
  },
  infoIcon: {
    color: GUEST_COLOR.primary,
    verticalAlign: "top",
    fontSize: "1.3rem",
  },
});

const breadCrumbs = [{ name: "規約確認", link: "" }];

const GuestTermListPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const { history } = props;

  const { authHeader } = useContext(AuthContext);
  const { setLoading } = useContext(GlobalPopupContext);
  const [latestTerms, setTerms] = useState<ITermData[]>([]);
  const [errorMsg, setErrorMsg] = useState("");

  const containerClasses = useContainerStyles();
  const classes = useStyles();
  const buttonClasses = buttonStyles({
    width: 685,
    marginTop: 0,
    backgroundColor: GUEST_COLOR.primary,
  });

  const handleToTermsPdfPage = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();

    const termsNumber = e.currentTarget.getAttribute("data-id");
    // 規約取得APIを呼んでPDFデータを取得する
    setLoading(true);
    terms
      .get(authHeader as string, Number(termsNumber))
      .then((res) => {
        const pdf = res.data.result.pdfData;
        history.push({
          pathname: `/guest/latest-terms/pdf`,
          state: {
            pdfData: pdf,
          },
        });
      })
      .catch((error) => {
        setErrorMsg(getErrorMessage(error));
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const load = async () => {
      // 規約一覧取得API
      await terms
        .getLatestList(authHeader as string)
        .then((res) => {
          const results = res.data.result as ITermData[];
          const filteredTerms = results.map((result) => {
            const copyTerm = LATEST_TERMS_DATA.find(
              (term) =>
                term.termsNumber.toString() === result.termsNumber.toString()
            );
            return { ...copyTerm, ...result };
          });
          // 表示順を変更（orderの昇順でソート）
          filteredTerms.sort((a, b) => a.order - b.order);
          setTerms(filteredTerms);
          setLoading(false);
        })
        .catch((error) => {
          setErrorMsg(getErrorMessage(error));
          setLoading(false);
        });
    };

    setErrorMsg("");
    setLoading(true);
    load();
    // 表示時のみなので
    // eslint-disable-next-line
  }, []);

  return (
    <Dashboard
      title="規約確認"
      color={GUEST_COLOR.base}
      backgroundColor={GUEST_COLOR.primary}
      iconName="terms"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {errorMsg && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMsg}
          </Alert>
        )}
        {latestTerms.length > 0 ? (
          latestTerms.map((term) => (
            <div className={classes.buttonGroup} key={term.name}>
              {/* 規約ボタン */}
              {term.isAnotherTab ? (
                // 別タブ表示の場合
                <a
                  className={classes.termLink}
                  href={term.path}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    type="button"
                    className={clsx(buttonClasses.button, classes.button)}
                    variant="contained"
                  >
                    {`${term.name} ${term.version}`}
                  </Button>
                </a>
              ) : (
                // PDF表示の場合
                <Button
                  type="button"
                  className={clsx(buttonClasses.button, classes.button)}
                  data-id={term.termsNumber}
                  onClick={handleToTermsPdfPage}
                  variant="contained"
                >
                  {`${term.name}`} {term.version && `Ver.${term.version}`}
                </Button>
              )}
              {/* 更新日 */}
              <span className={classes.updateAt}>
                {term.updatedAt && `更新日: ${term.updatedAt}`}
              </span>
            </div>
          ))
        ) : (
          <div className={clsx(classes.message)}>
            <InfoIcon className={classes.infoIcon} />
            該当する規約はありません。
          </div>
        )}
      </Container>
    </Dashboard>
  );
};

export default withRouter(GuestTermListPage);
