/**
 * フォーム関係-フォーム用のアコーディオン
 * メモ：現在は端末でしか使っていない
 */
import React, { ReactNode, useCallback, useContext } from "react";

import clsx from "clsx";
import {
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { innerAccordionStyle } from "../../../common/components/styles";

import { FONT, ThemeColorProps } from "../../../../constants/common";

import formStore from "../../../../store/formStore";
import { GlobalPopupContext } from "../../../../App";

import ClusterAccordionTitleIcon from "../../../common/components/atoms/ClusterAccordionTitleIcon";
import CustomAccordion from "../../../common/components/atoms/CustomAccordion";

const useStyles = makeStyles({
  icon: {
    verticalAlign: "top",
  },
  buttonGroupPosition: {
    paddingRight: 18,
    marginLeft: "auto",
    position: "absolute",
    zIndex: 1,
    right: "51px",
    top: 0,
    height: "100%",
  },
  gridButton: {
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  optionButton: (props: { primary: string; btnHover: string }) => ({
    backgroundColor: "#fff",
    margin: "0 3px",
    fontFamily: FONT,
    "&:hover": {
      backgroundColor: `${props.btnHover}!important`,
    },
  }),
  addButton: (props: { primary: string; btnHover: string }) => ({
    color: props.primary,
    fontFamily: FONT,
  }),
});

type Props = {
  title: string;
  index: number;
  children: ReactNode;
  colors: ThemeColorProps;
  type: string;
  defaultExpanded?: boolean;
};

const FormAccordion: React.FC<Props> = (props: Props) => {
  const { title, index, children, colors, type, defaultExpanded } = props;

  const { setConfirmModal } = useContext(GlobalPopupContext);

  const gridNum = formStore.getTerminalGridCount();

  const classes = useStyles({
    primary: colors.primary,
    btnHover: colors.btnHover,
  });
  const innerAccordionClasses = innerAccordionStyle({
    color: "#fff",
    backgroundColor: colors.primary,
    bandColor: "#fff",
    borderColor: "#fff",
  });

  const minusGrid = useCallback(() => {
    formStore.terminalGridMinus(index);
    setConfirmModal({
      open: false,
      modalTitle: "",
      actionMethod: () => {
        /* 何もしない */
      },
      colors,
    });
  }, [colors, index, setConfirmModal]);

  const handleMinusClick = useCallback(
    (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (event) event.stopPropagation();
      setConfirmModal({
        open: true,
        modalTitle: `${title}を削除します`,
        actionMethod: minusGrid,
        colors,
      });
    },
    [colors, minusGrid, setConfirmModal, title]
  );

  const handlePlusClick = useCallback(
    (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (event) event.stopPropagation();
      formStore.terminalGridPlus(index);
    },
    [index]
  );

  return (
    <CustomAccordion
      key={index}
      className={innerAccordionClasses.accordion}
      defaultExpanded={defaultExpanded}
      expandKey={`${title}_${index + 1}`}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon className={innerAccordionClasses.expandIcon} />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={innerAccordionClasses.accordionSummary}
      >
        <Typography variant="h3" className={innerAccordionClasses.h3}>
          {/* 現在は端末でしか使ってないのでアイコン固定する */}
          <ClusterAccordionTitleIcon id={3} className={classes.icon} />
          {`${title}_${index + 1}`}
          {/* typeがnew（=入力フォーム）の場合のみ増減ボタンを表示 */}
          {type === "new" && (
            <div className={classes.buttonGroupPosition}>
              <div className={classes.gridButton}>
                {/* 申込端末削除ボタン */}
                <Button
                  onClick={handleMinusClick}
                  disabled={gridNum <= 1}
                  className={clsx(classes.optionButton)}
                >
                  申込端末削除
                </Button>
                {/* 申込端末追加ボタン */}
                <Button
                  onClick={handlePlusClick}
                  disabled={gridNum >= 30}
                  className={clsx(classes.optionButton, classes.addButton)}
                >
                  申込端末追加
                </Button>
              </div>
            </div>
          )}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={innerAccordionClasses.accordionDetail}>
        {children}
      </AccordionDetails>
    </CustomAccordion>
  );
};

FormAccordion.defaultProps = {
  defaultExpanded: false,
};
export default FormAccordion;
