/**
 * 共通-パンくず
 */
import React from "react";
import { Link } from "react-router-dom";

import { makeStyles, Breadcrumbs } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import { FONT } from "../../../../constants/common";

const useStyles = makeStyles(() => ({
  breadcrumbs: {
    marginBottom: 20,
    fontFamily: FONT,
  },
  lastLink: {
    color: "black",
  },
  link: {
    textDecoration: "none",
    color: "#000",
  },
}));

interface breadcrumbInt {
  name: string;
  link: string | object;
}

interface Props {
  breadCrumbs: Array<breadcrumbInt>;
}

const CustomBreadcrumbs: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const { breadCrumbs } = props;

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      className={classes.breadcrumbs}
    >
      {breadCrumbs.map((breadCrumb, index) =>
        breadCrumbs.length - 1 !== index ? (
          <Link
            className={classes.link}
            key={breadCrumb.name}
            to={breadCrumb.link}
            color="inherit"
          >
            {breadCrumb.name}
          </Link>
        ) : (
          <span key={breadCrumb.name} className={classes.lastLink}>
            {breadCrumb.name}
          </span>
        )
      )}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
