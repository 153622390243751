/**
 * フォーム関係-法人番号検索結果表示ポップアップ
 */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import clsx from "clsx";
import {
  Button,
  Modal,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Theme,
  CircularProgress,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import AssignmentIcon from "@material-ui/icons/AssignmentOutlined";

import { buttonStyles, formStyles } from "../../../common/components/styles";

import {
  TFormColumn,
  TFormObject,
  TRequiredKey,
} from "../../../common/components/types";

import { ADMIN_COLOR, FONT } from "../../../../constants/common";

import { AuthContext } from "../../../../cognito/AuthContext";
import houjin from "../../../../api/houjin";
import { splitAddress } from "../../../../form/input/houjinAddressAutoInput";
import formStore from "../../../../store/formStore";
import { cloneObject } from "../../../../utils/object";
import getErrorMessage from "../../../../utils/error";

import BreakElementForLineText from "../../../common/components/atoms/BreakElementForLineText";
import RequiredChip from "../../../common/components/atoms/RequiredChip";

const useStyle = makeStyles((theme: Theme) => ({
  popup: {
    position: "absolute",
    minWidth: 700,
    maxWidth: 950,
    width: "60%",
    minHeight: 350,
    maxHeight: 850,
    height: "80%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
  },
  modalTitle: {
    fontSize: "1.1em",
    color: ADMIN_COLOR.primary,
    fontFamily: FONT,
    margin: 0,
  },
  messageBlock: {
    marginTop: 20,
  },
  modalMessage: {
    fontSize: "0.9em",
    fontWeight: "bold",
    fontFamily: FONT,
    color: ADMIN_COLOR.primary,
    marginBottom: 15,
  },
  buttonBlock: {
    position: "absolute",
    display: "flex",
    justifyContent: "end",
    bottom: 20,
    right: 20,
  },
  selectButton: {
    paddingBottom: 8,
    width: 300,
    marginRight: 20,
  },
  cancelButton: {
    paddingBottom: 8,
  },
  closeIcon: {
    fontSize: "1.1rem",
    position: "relative",
    marginLeft: -3,
    marginBottom: -5,
    left: -2,
    top: -1,
  },
  assignIcon: {
    position: "relative",
    marginLeft: -3,
    marginBottom: -5,
    left: -6,
    top: 2,
  },
  formArea: {
    height: "calc(100% - 90px - 90px)",
    overflowY: "scroll",
  },
  th: {
    fontFamily: FONT,
    backgroundColor: "#fff",
    color: "#000!important",
    width: 263,
    borderBottom: "2px dotted #ddd",
    position: "relative",
  },
  td: {
    backgroundColor: "#fff",
    color: "#000!important",
    borderBottom: "2px dotted #ddd",
  },
  thMessage: {
    width: 160,
  },
  loadingBlock: {
    width: "100%",
    height: "calc(100% - 220px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loading: {
    color: ADMIN_COLOR.primary,
  },
  text: {
    width: "100%",
    backgroundColor: "#f2f7fb",
    "& .MuiInputBase-input": {
      height: 0,
      paddingTop: 22,
    },
    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.09)",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: 0,
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderWidth: 2,
    },
  },
}));

type Props = {
  houjinBango: string;
  requiredKey: TRequiredKey;
  onCancel: () => void;
  error: boolean;
};

const HoujinBangoResultPopup: React.FC<Props> = (props: Props) => {
  const { houjinBango, requiredKey, onCancel, error } = props;

  const { authHeader } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [resultData, setResultData] = useState<TFormObject[]>([]);

  const updateData = useRef<{ [key in TFormColumn]?: string }>({});

  const getModalStyle = useCallback(
    () => ({
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }),
    []
  );
  const classes = useStyle();
  const buttonClasses = buttonStyles({
    width: 130,
    marginTop: 0,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const formClasses = formStyles({
    color: ADMIN_COLOR.base,
    backgroundColor: ADMIN_COLOR.primary,
  });

  useEffect(() => {
    const fn = async () => {
      if (!authHeader || !houjinBango) return;
      try {
        // 法人番号API
        const res = await houjin.get(
          authHeader,
          "4", // 最新版固定
          process.env.REACT_APP_HOUJIN_APPLICATION_ID as string,
          houjinBango, // 法人番号
          "02", // 「CSV、Unicode」固定
          "0" // 「履歴は残さない」固定)
        );

        // レスポンスを分割
        const { result } = res.data;
        const data: string[] = result.split(",");

        // 更新用データ作成
        updateData.current = {
          contractorPostcode: data[18].replaceAll(`"`, ""),
          contractorPrefecture: data[12].replaceAll(`"`, ""),
          contractorCity: data[13].replaceAll(`"`, ""),
          contractorTown: splitAddress(data[14].replaceAll(`"`, ""), 0),
          contractorAddressNumber: splitAddress(data[14], 2),
          contractorBuilding: splitAddress(data[14].replaceAll(`"`, ""), 1),
        };

        // 表示用データ作成
        const showData: TFormObject[] = [];
        // 郵便番号
        const postcode = cloneObject(
          formStore.getFormRow("contractorPostcode")
        );
        if (postcode) {
          postcode.content = data[18].replaceAll(`"`, "") || "";
          showData.push(postcode);
        }
        // 都道府県
        const pref = cloneObject(formStore.getFormRow("contractorPrefecture"));
        if (pref) {
          pref.content = data[12].replaceAll(`"`, "") || "";
          showData.push(pref);
        }
        // 市区町村
        const city = cloneObject(formStore.getFormRow("contractorCity"));
        if (city) {
          city.content = data[13].replaceAll(`"`, "") || "";
          showData.push(city);
        }
        // 町名
        const town = cloneObject(formStore.getFormRow("contractorTown"));
        if (town) {
          town.content = splitAddress(data[14].replaceAll(`"`, ""), 0);
          showData.push(town);
        }
        // 番地
        const addressNum = cloneObject(
          formStore.getFormRow("contractorAddressNumber")
        );
        if (addressNum) {
          addressNum.content = splitAddress(data[14], 2);
          showData.push(addressNum);
        }
        // 建物名
        const building = cloneObject(
          formStore.getFormRow("contractorBuilding")
        );
        if (building) {
          building.content = splitAddress(data[14].replaceAll(`"`, ""), 1);
          showData.push(building);
        }

        // 表示用データをセット
        setResultData(showData);

        setLoading(false);
        // eslint-disable-next-line
      } catch (error: any) {
        setErrorMessage(getErrorMessage(error));
        setLoading(false);
      }
    };

    setLoading(true);
    fn();
    // 開いた時のみ通信
    // eslint-disable-next-line
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    onCancel();
  }, [onCancel]);

  const handleDecide = useCallback(() => {
    formStore.updateContents(updateData.current, requiredKey);
    setOpen(false);
    onCancel();
  }, [onCancel, requiredKey]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={getModalStyle()} className={classes.popup}>
        {/* タイトル */}
        <h2 id="simple-modal-title" className={classes.modalTitle}>
          {" 法人番号検索結果"}
        </h2>
        {/* 文言 */}
        <div className={classes.messageBlock}>
          <div className={classes.modalMessage}>
            <BreakElementForLineText
              text={`表示されている企業名が貴社の情報でない場合、「キャンセル」ボタンで元の画面に戻って法人番号を修正して下さい。\n\n登記簿住所は下記で正しいか、今一度ご確認ください。\n※内容に誤りがあったり区切り位置がおかしい場合、審査に影響が出るので修正して下さい。`}
            />
          </div>
        </div>
        {/* 結果表示 */}
        {loading ? (
          // 読み込み中の場合
          <div className={classes.loadingBlock}>
            <div className={classes.loading}>
              <p>読み込み中</p>
              <div className={classes.loadingBlock}>
                <CircularProgress className={classes.loading} />
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.formArea}>
            {errorMessage && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {errorMessage}
              </Alert>
            )}
            {error ? (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                登録情報が取得できませんでした。「キャンセル」ボタンで元の画面に戻って法人番号をお確かめください。
              </Alert>
            ) : (
              <TableContainer
                className={formClasses.tableContainer}
                key="HoujinTableContainer"
              >
                <Table
                  className={formClasses.table}
                  aria-label="simple table"
                  key="HoujinTable"
                >
                  <TableBody key="HoujinTableBody">
                    {/* データ表示 */}
                    {resultData.map((row) => (
                      <TableRow
                        key={`Popup_${row.column}`}
                        className={formClasses.tr}
                      >
                        <TableCell align="left" className={classes.th}>
                          {/* 項目の名称 */}
                          <div className={classes.thMessage}>
                            <BreakElementForLineText text={row.json.formName} />
                          </div>
                          {/* 必須ラベル */}
                          {row.json.required[requiredKey] &&
                            row.json.fixedValue === null && (
                              <RequiredChip left={183} top="37%" />
                            )}
                        </TableCell>
                        <TableCell align="left" className={classes.td}>
                          {/* テキストフィールド */}
                          {row.json.formType === "text" && (
                            <TextField
                              type="text"
                              variant="outlined"
                              name={row.json.formName}
                              className={clsx(classes.text)}
                              value={row.content}
                              disabled
                              error={row.errors.length > 0}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        )}

        <div className={classes.buttonBlock}>
          {/* 反映するボタン */}
          <Button
            type="button"
            className={clsx(buttonClasses.button, classes.selectButton)}
            onClick={handleDecide}
            variant="contained"
            disabled={loading || error}
          >
            <AssignmentIcon className={classes.assignIcon} />
            上記の内容をフォームに反映する
          </Button>
          {/* キャンセルボタン */}
          <Button
            type="button"
            className={clsx(buttonClasses.button, classes.cancelButton)}
            onClick={handleClose}
            variant="contained"
          >
            <CloseIcon className={classes.closeIcon} />
            キャンセル
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default HoujinBangoResultPopup;
