/**
 * JTB側-ログイン入力フォーム
 * メモ：入力フォームを表示する
 */
import React from "react";

import { FormControl } from "@material-ui/core";

import { loginFormStyles } from "../../../../common/components/styles";

import { LoginItemObject } from "../../types";

import CustomTextField from "../../../../common/components/atoms/CustomTextField";

interface Props {
  loginState: LoginItemObject;
  setLoginState: React.Dispatch<React.SetStateAction<LoginItemObject>>;
}

const DealerLoginForm: React.FC<Props> = (props: Props) => {
  const loginFormClasses = loginFormStyles();
  const { loginState, setLoginState } = props;

  return (
    <>
      {/* ユーザーID */}
      <FormControl className={loginFormClasses.form}>
        <CustomTextField
          id="username"
          type="text"
          value={loginState.username}
          placeholder="ユーザーIDを入力"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setLoginState({ ...loginState, username: e.target.value })
          }
        />
      </FormControl>
      {/* パスワード */}
      <FormControl className={loginFormClasses.form}>
        <CustomTextField
          id="password"
          type="password"
          value=""
          placeholder="パスワードを入力"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setLoginState({ ...loginState, password: e.target.value })
          }
          autoComplete="current-password"
        />
      </FormControl>
    </>
  );
};

export default DealerLoginForm;
