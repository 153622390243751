/**
 * JTB側-2段階認証コード入力フォーム
 * メモ：入力フォームを表示する
 */
import React from "react";

import { FormControl } from "@material-ui/core";

import { loginFormStyles } from "../../../../common/components/styles";

import CustomTextField from "../../../../common/components/atoms/CustomTextField";

interface Props {
  mfaCode: string;
  setMfaCode: React.Dispatch<React.SetStateAction<string>>;
}

const DealerRequireMfaForm: React.FC<Props> = (props: Props) => {
  const loginFormClasses = loginFormStyles();
  const { mfaCode, setMfaCode } = props;

  return (
    <>
      <FormControl className={loginFormClasses.form}>
        <CustomTextField
          type="text"
          value={mfaCode}
          placeholder="認証コードを入力"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setMfaCode(e.target.value)
          }
        />
      </FormControl>
    </>
  );
};

export default DealerRequireMfaForm;
