import { getObjectValue } from "../../utils/formData";
import {
  formTypeOptions,
  TFormRowData,
} from "../../views/common/components/types";
import { TFixedNumberProcessResult } from "./types";

/**
 * 金融機関データ,支店データ
 */
type TBankData = {
  name: string;
  code: string;
  kana: string;
};

/**
 * 金融機関-支店データJson
 */
type TBankBranchMapJson = {
  largeClasses: {
    financialOrganName: string;
    financialOrganNameKana: string;
    financialOrganCd: string;
    smallClasses: {
      branchOfficeName: string;
      branchOfficeNameKana: string;
      branchOfficeCd: string;
    }[];
  }[];
};

/**
 * 金融機関名-金融機関データ
 */
const BANK_DATA_MAP = new Map<string, TBankData>();
/**
 * 金融機関名-支店名-支店データ
 */
const BRANCH_DATA_MAP = new Map<string, Map<string, TBankData>>();
/**
 * 金融機関名-支店選択肢
 */
const BANK_BRANCH_MAP = new Map<string, formTypeOptions[]>();
/**
 * 金融機関選択肢
 */
let BANK_OPTIONS: formTypeOptions[] = [];

/**
 * 金融機関-支店のマッピングデータ作成
 * @param json: TBankBranchMapJson 金融機関-支店データJson
 */
export const setBankBranchMap = (json: TBankBranchMapJson) => {
  // 金融機関選択肢を初期化
  BANK_OPTIONS = [];
  const { largeClasses } = json;

  largeClasses.forEach((l) => {
    const option: formTypeOptions = {};
    option[l.financialOrganCd] = l.financialOrganName;
    // 金融機関名選択肢をセット
    BANK_OPTIONS.push(option);
    // 金融機関データをセット
    BANK_DATA_MAP.set(l.financialOrganName, {
      name: l.financialOrganName,
      code: l.financialOrganCd,
      kana: l.financialOrganNameKana,
    });

    // 支店データ準備
    const branchOptions: formTypeOptions[] = [];
    const branchDataMap = new Map<string, TBankData>();
    l.smallClasses.forEach((s) => {
      const opt: formTypeOptions = {};
      opt[s.branchOfficeCd] = s.branchOfficeName;
      branchOptions.push(opt);
      branchDataMap.set(s.branchOfficeName, {
        name: s.branchOfficeName,
        code: s.branchOfficeCd,
        kana: s.branchOfficeNameKana,
      });
    });

    // 金融機関-支店選択肢セット
    BANK_BRANCH_MAP.set(l.financialOrganName, branchOptions);
    // 金融機関-支店データセット
    BRANCH_DATA_MAP.set(l.financialOrganName, branchDataMap);
  });
};

/**
 * 金融機関名選択肢セット
 * @param data: TFormRowData 加盟店情報フォーム
 * @returns TFixedNumberProcessResult
 */
export const setBankOptions: (
  data: TFormRowData
) => TFixedNumberProcessResult = (data: TFormRowData) => {
  const bankName = data.bankName[0];
  return bankName
    ? [{ column: "bankName", content: "", formTypeOptions: BANK_OPTIONS }]
    : [];
};

/**
 * 金融機関名をもとにした自動入力
 * @param data: TFormRowData 加盟店情報フォーム
 * @returns TFixedNumberProcessResult
 */
export const autoCalcByBankName: (
  data: TFormRowData
) => TFixedNumberProcessResult = (data: TFormRowData) => {
  // 金融機関名取得
  const bankName = data.bankName[0];
  const name = bankName.content
    ? getObjectValue(JSON.parse(bankName.content))[0]
    : "";

  // 未入力なら関連先を空にする
  if (!name) {
    return [
      { column: "bankCode", content: "" },
      { column: "bankNameK", content: "" },
      { column: "branchName", content: "", formTypeOptions: [] },
    ];
  }

  const ret: TFixedNumberProcessResult = [];

  // 金融機関データ設定
  const bankData = BANK_DATA_MAP.get(name);
  if (bankData) {
    ret.push({ column: "bankCode", content: bankData.code });
    ret.push({ column: "bankNameK", content: bankData.kana });
  }

  // 支店選択肢設定
  const branchOptions = BANK_BRANCH_MAP.get(name);
  if (branchOptions) {
    ret.push({
      column: "branchName",
      content: "",
      formTypeOptions: branchOptions,
    });
  }

  return ret;
};

/**
 * 支店名をもとにした自動入力
 * @param data: TFormRowData 加盟店情報フォーム
 * @returns TFixedNumberProcessResult
 */
export const autoCalcByBranchName: (
  data: TFormRowData
) => TFixedNumberProcessResult = (data: TFormRowData) => {
  // 金融機関名取得
  const bankName = data.bankName[0];
  const bankNameStr = bankName.content
    ? getObjectValue(JSON.parse(bankName.content))[0]
    : "";
  // 支店名取得
  const branchName = data.branchName[0];
  const branchNameStr = branchName.content
    ? getObjectValue(JSON.parse(branchName.content))[0]
    : "";

  // 金融機関名もしくは支店名が未入力なら関連先も空にする
  if (!bankNameStr || !branchNameStr) {
    return [
      { column: "branchCode", content: "" },
      { column: "branchNameK", content: "" },
    ];
  }

  // 支店データを設定
  const branchData = BRANCH_DATA_MAP.get(bankNameStr)?.get(branchNameStr);
  if (branchData) {
    return [
      { column: "branchCode", content: branchData.code },
      { column: "branchNameK", content: branchData.kana },
    ];
  }

  return [];
};
