import React, { useEffect, useState } from "react";
import formStore from "../../../../store/formStore";
import { TFormCategory, TRequiredKey } from "../../../common/components/types";
import { ThemeColorProps } from "../../../../constants/common";
import ChangeDealFormGroup from "../molecules/ChangeDealFormGroup";

type Props = {
  formCategory: TFormCategory;
  requiredKey: TRequiredKey;
  colors: ThemeColorProps;
  terminalGridId: number | undefined;
};
const ChangeDealNewForm: React.FC<Props> = (props: Props) => {
  const { formCategory, requiredKey, colors, terminalGridId } = props;
  const [formGroupIds, setFormGroupIds] = useState(
    formStore.getFilteredFormGroupNameIds(formCategory)
  );

  useEffect(() => {
    const fn = () => {
      const r = formStore.getFilteredFormGroupNameIds(formCategory);
      setFormGroupIds([]); // フォームリセット対応（一旦から配列でリセット）
      setFormGroupIds(r && [...r]);
    };
    formStore.addListener("form", fn);

    return () => formStore.removeListener("form", fn);
  }, [formCategory]);

  return (
    <div>
      {formGroupIds.map((id, index) => (
        <ChangeDealFormGroup
          key={id}
          formGroupNameId={id}
          requiredKey={requiredKey}
          terminalGridId={terminalGridId}
          idx={index}
          colors={colors}
        />
      ))}
    </div>
  );
};

export default ChangeDealNewForm;
