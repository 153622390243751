/**
 * 共通-ワークフロー関連ボタン
 */
import React, { useCallback, useContext, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import clsx from "clsx";
import { Button, Grid, makeStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn";
import PeopleIcon from "@material-ui/icons/People";

import { workflowButtonStyles } from "../styles";

import { TDealTypeStr } from "../types";

import {
  ADMIN_COLOR,
  FONT,
  ThemeColorProps,
} from "../../../../constants/common";
import {
  APPROVE_STATUSES,
  CHECK_STATUSES,
  INPUT_DEAL_STATUSES,
  RETURN_BACK_STATUSES,
  TWorkflowStatus,
  NEXT_WORKFLOW_MAP_NEW_CUSTOMER,
  NEXT_WORKFLOW_MAP_ADD_TERMINAL,
  NEXT_WORKFLOW_MAP_APPROVAL,
  NEXT_WORKFLOW_MAP_REJECTED,
  WORKFLOW_STATUS,
  RETURN_BACK_STATUSES_JTB_STAFF,
  RETURN_BACK_STATUSES_JTB_MANAGER,
  HIDE_RETURN_BACK_STATUSES_FOR_CHANGE_DEAL,
  HIDE_RETURN_BACK_STATUSES_FOR_CHANGE_DEAL_BY_TERMINAL,
  HIDE_RETURN_BACK_STATUSES_FOR_TERMINATE_DEAL,
  NEXT_WORKFLOW_MAP_CHANGE_DEAL,
  INPUT_DEAL_STATUSES_FOR_TERMINATE_DEAL,
  CHECK_STATUSES_FOR_TERMINATE_DEAL,
} from "../../../../constants/workflowStatus";
import {
  DEAL_TYPE_STR_TO_NUM,
  DEAL_SHOW_PAGE_URL_FOR_GUEST,
  DEAL_SHOW_PAGE_URL_FOR_DEALER,
} from "../../../../constants/deal";

import formStore from "../../../../store/formStore";
import {
  isJtbManagerUser,
  isJtbStaffUser,
  isJtbSysADUser,
  isJtbUser,
} from "../../../../utils/auth";
import { GlobalPopupContext } from "../../../../App";

import DealUserMessageModal from "../../../admin/deal/components/organisms/DealUserMessageModal";

const useStyles = makeStyles(() => ({
  commonButton: {
    paddingTop: 11,
    paddingBottom: 11,
    textAlign: "left",
    textIndent: "3.0em",
    width: "100%",
    marginTop: 0,
    display: "block",
  },
  buttonGridList: {
    marginTop: 20,
    margin: "0 auto",
  },
  buttonGrid: {
    width: "49%",
  },
  Icon: {
    position: "absolute",
    top: 11,
    left: 20,
  },
  message: {
    fontSize: "0.9em",
    fontWeight: "bold",
    fontFamily: FONT,
    color: ADMIN_COLOR.primary,
    marginTop: 5,
    marginBottom: 10,
  },
}));

interface Props extends RouteComponentProps {
  dealId: string;
  dealType: TDealTypeStr;
  workflowStatus: TWorkflowStatus;
  firstWorkflowStatus?: TWorkflowStatus;
  colors: ThemeColorProps;
  updateDeal: (next: TWorkflowStatus) => Promise<void>;
  urlPrefix: "dealer" | "guest";
  isRequiredFilledForTerminateDeal?: boolean;
}

const DealWorkflowButtons: React.FC<Props> = (props: Props) => {
  const {
    dealId,
    dealType,
    workflowStatus,
    firstWorkflowStatus,
    colors,
    updateDeal,
    history,
    urlPrefix,
    isRequiredFilledForTerminateDeal,
  } = props;

  const classes = useStyles();
  const workButtonClasses = workflowButtonStyles({
    width: "100%",
    marginTop: 0,
    backgroundColor: colors.primary,
    btnHoverColor: colors.btnHover,
  });

  const workflowStatusNum = Number(workflowStatus);
  const { setConfirmModal } = useContext(GlobalPopupContext);

  const [userMessageOpen, setUserMessageOpen] = useState(false); // 差し戻し処理で利用
  const [modalTitle, setModalTitle] = useState(""); // 差し戻し処理で利用
  const [nextStatus, setNextStatus] = useState<TWorkflowStatus>(); // 差し戻し処理で利用

  // 申請編集画面に遷移
  const handleToEditPage = useCallback(() => {
    // 「現在のワークフローステータス」をセットする
    formStore.setWorkflowStatus(workflowStatus);

    // 「次のワークフローステータス」をセットする
    if (dealType === "端末新規") {
      formStore.setNextWorkflowStatus(
        NEXT_WORKFLOW_MAP_NEW_CUSTOMER.get(workflowStatus) as TWorkflowStatus
      );
    } else if (dealType === "端末増設") {
      formStore.setNextWorkflowStatus(
        NEXT_WORKFLOW_MAP_ADD_TERMINAL.get(workflowStatus) as TWorkflowStatus
      );
    } else if (urlPrefix === "guest" && dealType.includes("変更")) {
      formStore.setNextWorkflowStatus(
        NEXT_WORKFLOW_MAP_CHANGE_DEAL.get(workflowStatus) as TWorkflowStatus
      );
    }

    // dealTypeにより、「申込内容を編集する」ボタンの遷移先urlを変更する
    const editDealTypeNum = DEAL_TYPE_STR_TO_NUM[dealType];
    const editDealTypeUrl =
      urlPrefix === "dealer"
        ? DEAL_SHOW_PAGE_URL_FOR_DEALER[editDealTypeNum]
        : DEAL_SHOW_PAGE_URL_FOR_GUEST[editDealTypeNum];
    const editDealUrl = `${editDealTypeUrl}${dealId}/edit`;
    history.push(editDealUrl);
  }, [history, dealId, workflowStatus, dealType, urlPrefix]);

  // チェック完了
  const handleCheck = useCallback(() => {
    const next = NEXT_WORKFLOW_MAP_APPROVAL.get(workflowStatus);
    setConfirmModal({
      open: false,
      modalTitle: "",
      colors: ADMIN_COLOR,
      actionMethod: () => {
        /* 何もしない */
      },
    });
    if (!next) return;
    const nextWorkflow = next[dealType] as TWorkflowStatus;
    updateDeal(nextWorkflow);
  }, [dealType, updateDeal, workflowStatus, setConfirmModal]);

  // チェック完了確認
  const checkConfirm = useCallback(() => {
    setConfirmModal({
      open: true,
      modalTitle: "チェックを完了し、ワークフローを進めます。",
      colors: ADMIN_COLOR,
      actionMethod: handleCheck,
    });
  }, [setConfirmModal, handleCheck]);

  // 承認
  const handleApproval = useCallback(async () => {
    const next = NEXT_WORKFLOW_MAP_APPROVAL.get(workflowStatus);
    setConfirmModal({
      open: false,
      modalTitle: "",
      colors: ADMIN_COLOR,
      actionMethod: () => {
        /* 何もしない */
      },
    });
    if (!next) return;
    const nextWorkflow = next[dealType] as TWorkflowStatus;
    updateDeal(nextWorkflow);
  }, [dealType, updateDeal, workflowStatus, setConfirmModal]);

  // 承認確認
  const approvalConfirm = useCallback(() => {
    setConfirmModal({
      open: true,
      modalTitle: "承認し、ワークフローを進めます。",
      colors: ADMIN_COLOR,
      actionMethod: handleApproval,
    });
  }, [setConfirmModal, handleApproval]);

  // 差し戻し
  const handleReturnBack = useCallback(() => {
    const next = NEXT_WORKFLOW_MAP_REJECTED.get(workflowStatus);
    if (!next) return;
    const nextWorkflow = next[dealType] as TWorkflowStatus;
    if (nextWorkflow === WORKFLOW_STATUS.DEAL_INFO_REJECTED) {
      // 加盟店へ差し戻す場合
      setModalTitle("加盟店へ差し戻します。差し戻し理由を入力してください。");
    } else {
      // JTB審査内で差し戻す場合
      setModalTitle(
        "前ステータスへ差し戻します。差し戻し理由を入力してください。"
      );
    }
    setNextStatus(nextWorkflow);
    setUserMessageOpen(true);
  }, [dealType, workflowStatus]);

  // 差し戻しボタン表示条件
  const isShowReturnBackButton = () => {
    // 申請作成がJTB開始かつ特定条件の場合false
    if (
      (isJtbUser() &&
        (dealType === "契約者変更" || dealType === "加盟店変更") &&
        HIDE_RETURN_BACK_STATUSES_FOR_CHANGE_DEAL.includes(workflowStatus) &&
        firstWorkflowStatus === WORKFLOW_STATUS.EXAMINATION_BY_DEALER) ||
      (isJtbUser() &&
        dealType === "端末変更" &&
        HIDE_RETURN_BACK_STATUSES_FOR_CHANGE_DEAL_BY_TERMINAL.includes(
          workflowStatus
        ) &&
        firstWorkflowStatus ===
          WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER) ||
      (isJtbUser() &&
        (dealType === "端末解約" || dealType === "加盟店解約") &&
        HIDE_RETURN_BACK_STATUSES_FOR_TERMINATE_DEAL.includes(workflowStatus) &&
        firstWorkflowStatus === WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER)
    ) {
      return false;
    }
    if (
      // JTB 管理者
      (isJtbManagerUser() &&
        RETURN_BACK_STATUSES_JTB_MANAGER.includes(workflowStatus)) ||
      // JTB 一般
      (isJtbStaffUser() &&
        RETURN_BACK_STATUSES_JTB_STAFF.includes(workflowStatus)) ||
      // JTB SYSAD
      (isJtbSysADUser() && RETURN_BACK_STATUSES.includes(workflowStatus))
    ) {
      return true;
    }
    return false;
  };

  // 申請編集ボタン表示条件
  const showEditButton = useMemo(() => {
    return INPUT_DEAL_STATUSES.includes(workflowStatusNum);
  }, [workflowStatusNum]);

  // チェック完了ボタン表示条件
  const showCheckButton = useMemo(() => {
    return (
      (isJtbStaffUser() || isJtbSysADUser()) && // JTB一般かJTBシステム担当者のみ
      CHECK_STATUSES.includes(workflowStatusNum)
    );
  }, [workflowStatusNum]);

  // 承認ボタン表示条件
  const showApproveButton = useMemo(() => {
    return (
      (isJtbManagerUser() || isJtbSysADUser()) && // JTB管理者かJTBシステム担当者のみ
      APPROVE_STATUSES.includes(workflowStatusNum)
    );
  }, [workflowStatusNum]);

  // 申請編集ボタン表示条件（加盟店・端末解約用）
  const showEditButtonForTerminateDeal = useMemo(() => {
    return (
      (dealType === "端末解約" || dealType === "加盟店解約") &&
      INPUT_DEAL_STATUSES_FOR_TERMINATE_DEAL.includes(workflowStatusNum) &&
      isRequiredFilledForTerminateDeal === false
    );
  }, [workflowStatusNum, dealType, isRequiredFilledForTerminateDeal]);

  // チェック完了ボタン非活性条件（加盟店・端末解約用）
  const disabledCheckButton = useMemo(() => {
    return (
      (dealType === "端末解約" || dealType === "加盟店解約") &&
      CHECK_STATUSES_FOR_TERMINATE_DEAL.includes(workflowStatusNum) &&
      isRequiredFilledForTerminateDeal === false
    );
  }, [workflowStatusNum, dealType, isRequiredFilledForTerminateDeal]);

  return (
    <>
      {userMessageOpen && (
        <DealUserMessageModal
          dealId={Number(dealId)}
          dealType={dealType}
          setOpen={setUserMessageOpen}
          systemMessage={modalTitle}
          updateDeal={updateDeal}
          nextStatus={nextStatus}
        />
      )}
      <Grid container spacing={2} className={classes.buttonGridList}>
        <Grid item xs={12}>
          <Grid container justify="flex-start" spacing={2}>
            {/* 申請編集ボタン */}
            {showEditButton && (
              <Grid item className={classes.buttonGrid}>
                <Button
                  onClick={handleToEditPage}
                  className={clsx(
                    workButtonClasses.button,
                    classes.commonButton
                  )}
                  variant="contained"
                >
                  <EditIcon className={classes.Icon} />
                  申込内容を編集する
                </Button>
              </Grid>
            )}
            {/* チェック完了ボタン */}
            {showCheckButton && (
              <Grid item className={classes.buttonGrid}>
                <Button
                  onClick={checkConfirm}
                  className={clsx(
                    !disabledCheckButton && workButtonClasses.button,
                    classes.commonButton
                  )}
                  variant="contained"
                  type="button"
                  disabled={disabledCheckButton}
                >
                  <EditIcon className={classes.Icon} />
                  チェックを完了して次の担当者へ
                </Button>
              </Grid>
            )}
            {/* 承認ボタン */}
            {showApproveButton && (
              <Grid item className={classes.buttonGrid}>
                <Button
                  onClick={approvalConfirm}
                  className={clsx(
                    workButtonClasses.button,
                    classes.commonButton
                  )}
                  variant="contained"
                >
                  <PeopleIcon className={classes.Icon} />
                  承認する
                </Button>
              </Grid>
            )}
            {/* 差し戻しボタン */}
            {isShowReturnBackButton() && (
              <Grid item className={classes.buttonGrid}>
                <Button
                  onClick={handleReturnBack}
                  className={clsx(
                    workButtonClasses.button,
                    classes.commonButton
                  )}
                  variant="contained"
                >
                  <AssignmentReturnIcon className={classes.Icon} />
                  差し戻しする
                </Button>
              </Grid>
            )}
            {showEditButtonForTerminateDeal && (
              <Grid item className={classes.buttonGrid}>
                <Button
                  onClick={handleToEditPage}
                  className={clsx(
                    workButtonClasses.button,
                    classes.commonButton
                  )}
                  variant="contained"
                >
                  <EditIcon className={classes.Icon} />
                  申込内容を編集する
                </Button>
                <div className={classes.message}>
                  ※ 申込内容を編集して
                  {dealType === "加盟店解約"
                    ? "「JTB加盟店解約理由」「JTB加盟店解約日」"
                    : "「JTB端末解約理由」「JTB端末解約日」"}
                  の入力をしてください。
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(DealWorkflowButtons);
