import formStore from "../../store/formStore";
import { TFormColumn } from "../../views/common/components/types";
import { extractCombineContentsForChangeDeal } from "../fixedNumberProcess/combineDestFormNameIds";
import autoCheckForCompanyName from "./companyName";
import autoCheckForCompanyNameK from "./companyNameK";

/**
 * 自動チェック処理
 * @param targetColumns: 手動チェック対象カラム名群
 * @param columnName: 更新した項目のカラム名
 * @param checkboxStates: 更新した項目のカラム名群
 * @param autoCheckColumns: 自動チェックされるカラム名群
 * @returns checkedがtrueの場合は追加するTFormColumn[]、falseの場合は除外するTFormColumn[]
 */
const autoCheck = (
  targetColumns: TFormColumn[],
  columnName: TFormColumn,
  checkboxStates: TFormColumn[],
  autoCheckColumns: TFormColumn[]
): TFormColumn[] => {
  // checkboxStatesに対象カラム名が含まれているかどうか
  const includeChecked = targetColumns.some(
    (item) => item !== columnName && checkboxStates.includes(item)
  );

  if (targetColumns.includes(columnName) && !includeChecked) {
    return autoCheckColumns;
  }

  return [];
};

/**
 * 変更申請フォームによる固定番号処理
 * @param columnName: 更新した項目のカラム名
 * @param checkboxStates: 更新した項目のカラム名群
 * @param checked: チェックボックス有無
 * @returns checkedがtrueの場合は追加するTFormColumn[]、falseの場合は除外するTFormColumn[]
 */
export const runFixedNumberProcessForChangeDeal = (
  columnName: TFormColumn,
  checkboxStates: TFormColumn[],
  checked: boolean
): TFormColumn[] => {
  const list: TFormColumn[] = [columnName];

  /**
   * 自動チェック処理を進行
   */
  const processAutoCheck = (ret: TFormColumn[]) => {
    if (ret.length > 0) {
      list.push(...ret);
      ret.forEach((item) => formStore.setChangeableColumnName(item, checked));
    }
  };

  // 結合項目の自動チェック
  const data = extractCombineContentsForChangeDeal(columnName);
  if (
    data &&
    data.autoCheckColumns.length > 0 &&
    data.targetColumns.length > 0
  ) {
    const ret = autoCheck(
      data.targetColumns,
      columnName,
      checkboxStates,
      data.autoCheckColumns
    );
    processAutoCheck(ret);
  }

  // 106: 契約者会社名
  if (
    [
      "companyNameNonLP",
      "legalPersonality",
      "legalPersonalityPos",
      "representFirstName",
      "representLastName",
    ].includes(columnName)
  ) {
    const ret = autoCheckForCompanyName(columnName, checkboxStates);
    processAutoCheck(ret);
  }

  // 107: 契約者会社名カナ
  if (
    [
      "companyNameKNonLP",
      "legalPersonality",
      "legalPersonalityPos",
      "representFirstNameK",
      "representLastNameK",
    ].includes(columnName)
  ) {
    const ret = autoCheckForCompanyNameK(columnName, checkboxStates);
    processAutoCheck(ret);
  }

  // 268：金融機関名(カナ)
  // 266：金融機関コード
  if (["bankName"].includes(columnName)) {
    const ret = autoCheck(["bankName"], columnName, checkboxStates, [
      "bankNameK",
      "bankCode",
    ]);
    processAutoCheck(ret);
  }

  // 271：支店名(カナ)
  // 269：支店コード
  if (["branchName"].includes(columnName)) {
    const ret = autoCheck(["branchName"], columnName, checkboxStates, [
      "branchNameK",
      "branchCode",
    ]);
    processAutoCheck(ret);
  }

  // 237：加盟店地区コード
  // 238：設置地区名
  if (["customerPrefecture"].includes(columnName)) {
    const ret = autoCheck(["customerPrefecture"], columnName, checkboxStates, [
      "customerDistrictCode",
      "installationAreaName",
    ]);
    processAutoCheck(ret);
  }

  // 契約者郵便番号
  if (["contractorPostcode"].includes(columnName)) {
    const ret = autoCheck(["contractorPostcode"], columnName, checkboxStates, [
      "contractorPrefecture",
      "contractorCity",
      "contractorTown",
      "contractorPrefectureK",
      "contractorCityK",
      "contractorTownK",
      "contractorAddress",
      "contractorAddressK",
      "contractorAddressToNumber",
      "contractorAddressToNumberK",
    ]);
    processAutoCheck(ret);
  }

  // 加盟店郵便番号
  if (["customerPostalCode"].includes(columnName)) {
    const ret = autoCheck(["customerPostalCode"], columnName, checkboxStates, [
      "customerPrefecture",
      "customerCity",
      "customerTown",
      "customerPrefectureK",
      "customerCityK",
      "customerTownK",
      "customerAddress",
      "customerAddressK",
      "customerAddressToNumber",
      "customerAddressToNumberK",
      "customerPrefectureE",
    ]);
    processAutoCheck(ret);
  }
  return list;
};

export default runFixedNumberProcessForChangeDeal;
