/**
 * JTB側-トップページ（ダッシュボード）
 */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import clsx from "clsx";
import { Container, makeStyles } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import { useContainerStyles } from "../../../../templates/styles";

import { TDashboardData } from "../../types";
import {
  TSortCondition,
  TRequestType,
} from "../../../../common/components/types";

import { ADMIN_COLOR } from "../../../../../constants/common";

import { GlobalPopupContext } from "../../../../../App";
import deal from "../../../../../api/deal";
import { AuthContext } from "../../../../../cognito/AuthContext";
import { getErrorMessage } from "../../../../../utils/error";
import { checkAuth } from "../../../../../utils/auth";

import Dashboard from "../../../../templates/Dashboard";
import DealTable from "../organisms/DealTable";
import InformationAlert from "../../../../common/components/organisms/InformationAlert";

const useStyles = makeStyles(() => ({
  back: {
    backgroundColor: "#f0f0f0",
    minHeight: 1050,
  },
  // 開発環境(develop)の場合のみ
  backImage: {
    backgroundColor: "#f0f0f0",
    minHeight: 1050,
    background: `url(${window.location.origin}/bgtestForGree.png) repeat`,
  },
  container: {
    paddingTop: 15,
    paddingBottom: 0,
  },
}));

const TopPage: React.FC = () => {
  const classes = useStyles();
  const containerClasses = useContainerStyles();

  const { authHeader } = useContext(AuthContext);
  const { setLoading } = useContext(GlobalPopupContext);
  const [needActionData, setNeedActionData] = useState<TDashboardData>();
  const [jtbData, setJtbData] = useState<TDashboardData>();
  const [smccData, setSmccData] = useState<TDashboardData>();
  const [errorMessage, setErrorMessage] = useState("");

  // 非同期処理中にページ離れた場合の対応
  const leavePage = useRef(false);

  // ダッシュボードAPI
  const getDashboardData = useCallback(
    async (
      page: number,
      requestType: TRequestType,
      sortCondition: TSortCondition
    ) => {
      setLoading(true);
      const res = await deal.dashboard(
        authHeader || "",
        page.toString(),
        requestType,
        sortCondition
      );

      setLoading(false);
      return {
        totalCount: res.data.totalCount,
        data: res.data.result,
        sortCondition,
      };
    },
    // eslint-disable-next-line
    [setLoading]
  );

  // 「あなたの対応が必要な申請」データ
  const updateNeedAction = useCallback(
    async (page: number, sortCondition: TSortCondition) => {
      const data = await getDashboardData(page, "needAction", sortCondition);
      // ページ離れてたらsetしない（メモリリーク対策）
      if (!leavePage.current) setNeedActionData(data);
    },
    [getDashboardData]
  );
  // 「JTB内処理中の申請」データ
  const updateJtb = useCallback(
    async (page: number, sortCondition: TSortCondition) => {
      const data = await getDashboardData(page, "jtb", sortCondition);
      // ページ離れてたらsetしない（メモリリーク対策）
      if (!leavePage.current) setJtbData(data);
    },
    [getDashboardData]
  );
  // 「SMCC結果待ちの申請」データ
  const updateSmcc = useCallback(
    async (page: number, sortCondition: TSortCondition) => {
      const data = await getDashboardData(page, "smcc", sortCondition);
      // ページ離れてたらsetしない（メモリリーク対策）
      if (!leavePage.current) setSmccData(data);
    },
    [getDashboardData]
  );

  const hasAuth = checkAuth("dashboard");

  // 初期表示用
  useEffect(() => {
    if (!hasAuth) return;
    Promise.all([
      updateNeedAction(1, { sortByDealId: "DESC" }),
      updateJtb(1, { sortByDealId: "DESC" }),
      updateSmcc(1, { sortByDealId: "DESC" }),
    ]).catch((err) => {
      setErrorMessage(getErrorMessage(err));
    });
  }, [updateSmcc, updateNeedAction, updateJtb, hasAuth]);

  // ページ離れ判定
  useEffect(() => {
    return () => {
      leavePage.current = true;
    };
  }, []);

  return (
    <Dashboard
      title="ダッシュボード"
      color={ADMIN_COLOR.base}
      backgroundColor={ADMIN_COLOR.primary}
      iconName="dashboard"
    >
      {/* TODO:検証環境でJTB様がマニュアル用の画面画像取るため一時的に開発環境のTESTの背景を外す */}
      {/* <div
        className={
          process.env.REACT_APP_ENV === "development"
            ? classes.backImage
            : classes.back
        }
      > */}
      <div className={classes.back}>
        {/* お知らせ */}
        <Container
          maxWidth="lg"
          className={clsx(containerClasses.container, classes.container)}
        >
          <InformationAlert delay={700} />
        </Container>
        {/* あなたの対応が必要な申請 */}
        <Container
          maxWidth="lg"
          className={clsx(containerClasses.container, classes.container)}
        >
          {errorMessage !== "" && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorMessage}
            </Alert>
          )}
          {hasAuth && errorMessage === "" && (
            <DealTable
              title="あなたの対応が必要な申請"
              data={needActionData}
              tableStatus="apply"
              apiFunc={updateNeedAction}
            />
          )}
        </Container>
        {/* JTB内処理中の申請 */}
        <Container
          maxWidth="lg"
          className={clsx(containerClasses.container, classes.container)}
        >
          {hasAuth && errorMessage === "" && (
            <DealTable
              title="JTB内処理中の申請"
              data={jtbData}
              tableStatus="progress"
              apiFunc={updateJtb}
            />
          )}
        </Container>
        {/* SMCC結果待ちの申請 */}
        <Container
          maxWidth="lg"
          className={clsx(containerClasses.container, classes.container)}
        >
          {hasAuth && errorMessage === "" && (
            <DealTable
              title="SMCC結果待ちの申請"
              data={smccData}
              tableStatus="complete"
              apiFunc={updateSmcc}
            />
          )}
        </Container>
      </div>
    </Dashboard>
  );
};

export default TopPage;
