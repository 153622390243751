/**
 * JTB側-カード会社連携ファイル検索入力フォーム
 * メモ：入力フォームを表示する
 */
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ja from "date-fns/locale/ja";
import moment from "moment";
import clsx from "clsx";
import {
  FormControl,
  FormLabel,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";

import { formStyles } from "../../../../common/components/styles";

import { CardFileSearchCondition } from "../../types";

import { ADMIN_COLOR, FONT } from "../../../../../constants/common";
import {
  CARD_COMPANY_SEARCH_MAP,
  DEAL_GROUP_SEARCH_MAP,
  CUSTOMER_GROUP_SEARCH_MAP,
  ALIGNMENT_DATE_PLACEHOLDER,
} from "../../../../../constants/cardFile";

import { isCardCompanyUser } from "../../../../../utils/auth";

import PatternMatchChip from "../../../../common/components/atoms/PatternMatchChip";
import CustomSelect from "../../../../common/components/molecules/CustomSelect";
import CustomTextField from "../../../../common/components/atoms/CustomTextField";

registerLocale("ja", ja);

const useStyles = makeStyles(() => ({
  datepickerWrap: {
    backgroundColor: "#f2f7fb",
  },
  datepicker: {
    padding: "12px",
    width: "100%",
    backgroundColor: "#f2f7fb",
    fontSize: "1rem",
    borderWidth: 0,
    outlineWidth: 1,
  },
  error: {
    border: "2px solid #f44336",
  },
  disabled: {
    backgroundColor: "rgba(0, 0, 0, 0.09)",
  },
  remark: {
    color: "#777",
    fontFamily: FONT,
  },
  url: {
    fontFamily: FONT,
  },
  errorMsg: {
    color: "#f44336",
    fontSize: "0.75rem",
  },
  datepickerSelect: {
    position: "relative",
    fontFamily: "inherit",
    fontSize: "1.02rem",
    backgroundColor: "transparent",
    margin: 4,
    padding: 4,
    borderRadius: 0,
    border: "none",
    borderBottom: "1px solid rgba(0,0,0, 0.3)",
    "&:focus": {
      outline: "none",
    },
  },
  label: {
    width: 800,
    textAlign: "left",
    padding: "0!important",
    height: 36,
  },
  table: {
    width: 800,
    minWidth: 800,
  },
  td: {
    padding: 1,
    backgroundColor: "#fff",
    color: "#000!important",
    borderTop: "1px solid #d2d5d8",
    borderBottom: "1px solid #d2d5d8",
    borderRight: "1px solid #d2d5d8",
    width: 400,
  },
  th: {
    padding: 0,
    fontFamily: FONT,
    backgroundColor: "#fff",
    color: "#000!important",
    width: 200,
    border: "1px solid #d2d5d8",
    position: "relative",
    paddingLeft: 20,
  },
}));

// 各プルダウン項目の選択肢
const cardCompanyPulldown = Object.keys(CARD_COMPANY_SEARCH_MAP);
const dealGroupPulldown = Object.keys(DEAL_GROUP_SEARCH_MAP);
const customerGroupPulldown = Object.keys(CUSTOMER_GROUP_SEARCH_MAP);

// 検索条件フォーム設定
const cardFileSearchFormData = [
  {
    name: "カード会社",
    formData: "cardCompany",
    formType: "pulldown",
    pulldownArray: cardCompanyPulldown,
    placeholder: "",
    matchType: "完全一致",
  },
  {
    name: "連携日(from)",
    formData: "alignmentDateFrom",
    formType: "date",
    pulldownArray: [],
    placeholder: ALIGNMENT_DATE_PLACEHOLDER,
    matchType: "完全一致",
  },
  {
    name: "連携日(to)",
    formData: "alignmentDateTo",
    formType: "date",
    pulldownArray: [],
    placeholder: ALIGNMENT_DATE_PLACEHOLDER,
    matchType: "完全一致",
  },
  {
    name: "業務",
    formData: "dealGroup",
    formType: "pulldown",
    pulldownArray: dealGroupPulldown,
    placeholder: "",
    matchType: "完全一致",
  },
  {
    name: "加盟店種別",
    formData: "customerGroup",
    formType: "pulldown",
    pulldownArray: customerGroupPulldown,
    placeholder: "",
    matchType: "完全一致",
  },
  {
    name: "ファイル名",
    formData: "fileName",
    formType: "text",
    pulldownArray: [],
    placeholder: "",
    matchType: "部分一致",
  },
];

interface Props {
  searchCondition: CardFileSearchCondition;
  setSearchCondition: React.Dispatch<
    React.SetStateAction<CardFileSearchCondition>
  >;
}

const CardFileSearchConditionForm: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const formClasses = formStyles({
    color: ADMIN_COLOR.base,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const { searchCondition, setSearchCondition } = props;

  // JTB社ユーザーでない場合はカード会社を非表示
  const cardFileSearchForm = isCardCompanyUser()
    ? cardFileSearchFormData.filter((item) => item.formData !== "cardCompany")
    : cardFileSearchFormData;

  return (
    <FormControl className={formClasses.form}>
      <FormLabel className={clsx(formClasses.formLabel, classes.label)}>
        連携ファイル検索
      </FormLabel>
      <TableContainer>
        <Table
          className={clsx(formClasses.table, classes.table)}
          aria-label="simple table"
        >
          <TableBody>
            {cardFileSearchForm.map((formItem) => (
              <TableRow key={formItem.name}>
                <TableCell
                  align="left"
                  className={clsx(formClasses.th, classes.th)}
                >
                  {formItem.name}
                  <PatternMatchChip
                    left={153}
                    top="24%"
                    text={formItem.matchType}
                  />
                </TableCell>
                <TableCell align="left" className={classes.td}>
                  {/* 日付の場合 */}
                  {formItem.formType === "date" && (
                    <DatePicker
                      selected={
                        searchCondition[
                          formItem.formData as keyof CardFileSearchCondition
                        ]
                          ? new Date(
                              searchCondition[
                                formItem.formData as keyof CardFileSearchCondition
                              ]
                            )
                          : null
                      }
                      onChange={(date: Date | null) =>
                        setSearchCondition({
                          ...searchCondition,
                          [formItem.formData]: date
                            ? moment(date).format("YYYY-MM-DD")
                            : "",
                        })
                      }
                      dateFormat="yyyy-MM-dd"
                      locale="ja"
                      className={classes.datepicker}
                      placeholderText={formItem.placeholder}
                    />
                  )}
                  {/* プルダウンの場合 */}
                  {formItem.formType === "pulldown" && (
                    <CustomSelect
                      value={
                        searchCondition[
                          formItem.formData as keyof CardFileSearchCondition
                        ] || "指定なし"
                      }
                      pulldownArray={formItem.pulldownArray}
                      width="100%"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSearchCondition({
                          ...searchCondition,
                          [formItem.formData]: e.target.value,
                        })
                      }
                    />
                  )}
                  {/* テキストフィールドの場合 */}
                  {formItem.formType === "text" && (
                    <CustomTextField
                      value={String(
                        searchCondition[
                          formItem.formData as keyof CardFileSearchCondition
                        ]
                      )}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSearchCondition({
                          ...searchCondition,
                          [formItem.formData]: e.target.value,
                        })
                      }
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FormControl>
  );
};

export default CardFileSearchConditionForm;
