/**
 * フォーム関係-詳細表示用のフォームでのフォームグループのコンポーネント
 */
import React, { useState } from "react";

import { Table, TableBody, TableContainer } from "@material-ui/core";

import { formStyles } from "../../../common/components/styles";

import { TRequiredKey } from "../../../common/components/types";

import { ThemeColorProps } from "../../../../constants/common";

import formStore from "../../../../store/formStore";

import TableHeading from "../../../common/components/atoms/TableHeading";
import ShowFormValueRow from "../atoms/ShowFormValueRow";
import ShowGridGroup from "./ShowGridGroup";

type Props = {
  formGroupNameId: number;
  requiredKey: TRequiredKey;
  terminalGridId: number | undefined;
  idx: number;
  colors: ThemeColorProps;
};

const ShowFormGroup: React.FC<Props> = (props: Props) => {
  const { formGroupNameId, requiredKey, terminalGridId, idx, colors } = props;

  const [groupData] = useState(formStore.getFormGroupData(formGroupNameId));
  const formClasses = formStyles({
    color: colors.base,
    backgroundColor: colors.primary,
  });

  if (!groupData) return null;

  return (
    <>
      {/* 見出し */}
      <div className={formClasses.tableSpacing}>
        <TableHeading
          colors={{
            color: colors.base,
            backgroundColor: colors.primary,
          }}
          title={groupData.formGroupName}
          key={groupData.formGroupNameId}
          index={idx}
        />
      </div>

      {groupData.gridFlag ? (
        // グリッドの場合
        <ShowGridGroup
          formGroupNameId={formGroupNameId}
          requiredKey={requiredKey}
          colors={colors}
          terminalGridId={terminalGridId}
        />
      ) : (
        <TableContainer className={formClasses.tableContainer}>
          <Table>
            <TableBody>
              {groupData.forms.map((form) => (
                <ShowFormValueRow
                  key={form.columnName}
                  columnName={form.columnName}
                  requiredKey={requiredKey}
                  terminalGridId={terminalGridId}
                  gridId={undefined}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default ShowFormGroup;
