import { TableCell, TableRow, TableRowProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { FONT } from "../../../../constants/common";
import { TRequiredKey } from "../../../common/components/types";
import useObserver from "../../../helpers/form/useObserver";
import FormTitle from "../atoms/FormTitle";
import { convertToChangeDealContents } from "../../../../utils/changeDeal";
import {
  TWorkflowStatus,
  WORKFLOW_BEFORE_APPROVAL_NUM,
} from "../../../../constants/workflowStatus";
import ChangeDealShowFormValue from "../atoms/ChangeDealShowFormValue";
import formStore from "../../../../store/formStore";

const useStyles = makeStyles(() => ({
  th: {
    fontFamily: FONT,
    fontWeight: 900,
    backgroundColor: "#fff",
    color: "#000!important",
    width: 263,
    borderBottom: "2px dotted #ddd",
    position: "relative",
  },
  td: {
    backgroundColor: "#fff",
    color: "#000!important",
    borderBottom: "2px dotted #ddd",
    minWidth: 200,
  },
}));

type Props = TableRowProps & {
  row: ReturnType<typeof convertToChangeDealContents>[0];
  requiredKey: TRequiredKey;
  workflowStatus: TWorkflowStatus;
};
const ChangeDealShowFormValueRow: React.FC<Props> = (props: Props) => {
  const { row, className, requiredKey, workflowStatus } = props;
  const [init, setInit] = useState(false);
  const [isHide, setHide] = useState(
    formStore.isHideRow(row.columnName, requiredKey, undefined)
  );

  useEffect(() => {
    const fn = () => {
      setHide(formStore.isHideRow(row.columnName, requiredKey, undefined));
    };
    formStore.addListener(row.columnName, fn);

    return () => formStore.removeListener(row.columnName, fn);
  }, [row.columnName, requiredKey]);

  const target = React.useRef<HTMLTableRowElement>(null);
  // 描画境界判定
  const intersect = useObserver(target, {
    root: document.getElementById("scroll-parent"), // TabPanel
    rootMargin: "300px 0px 300px 0px", // 上下300px広く判定
  });

  const classes = useStyles();

  useEffect(() => {
    // タブ切り替え時に"scroll-parent"がDOMについたあとuseObserverするため
    setInit(true);
  }, [row]);

  if (isHide) return null;

  return init ? (
    <TableRow
      className={className}
      key={row.columnName}
      innerRef={target}
      style={{
        height: intersect
          ? "auto"
          : "75px" /* 描画範囲外の場合は仮で高さを設定しておく */,
      }}
    >
      <TableCell align="left" className={classes.th}>
        <FormTitle columnName={row.columnName} requiredKey={requiredKey} />
      </TableCell>
      {WORKFLOW_BEFORE_APPROVAL_NUM.includes(workflowStatus) && (
        <TableCell align="left" className={classes.td}>
          {intersect ? (
            <ChangeDealShowFormValue
              columnName={row.columnName}
              content={row.dealFormContent}
            />
          ) : null}
        </TableCell>
      )}
      <TableCell align="left" className={classes.td}>
        {intersect ? (
          <ChangeDealShowFormValue
            columnName={row.columnName}
            content={row.tabFormContent}
          />
        ) : null}
      </TableCell>
    </TableRow>
  ) : null;
};

export default ChangeDealShowFormValueRow;
