/**
 * フォーム関係-確認用のフォーム
 */
import React, { useMemo } from "react";

import { TFormCategory, TRequiredKey } from "../../../common/components/types";

import { ThemeColorProps } from "../../../../constants/common";

import formStore from "../../../../store/formStore";

import ConfirmFormGroup from "../molecules/ConfirmFormGroup";

type Props = {
  formCategory: TFormCategory;
  requiredKey: TRequiredKey;
  colors: ThemeColorProps;
  terminalGridId: number | undefined;
};

const ConfirmForm: React.FC<Props> = (props: Props) => {
  const { formCategory, requiredKey, colors, terminalGridId } = props;

  const formGroupIds = useMemo(
    () => formStore.getFilteredFormGroupNameIds(formCategory),
    [formCategory]
  );

  return (
    <div>
      {formGroupIds.map((id, index) => (
        <ConfirmFormGroup
          key={id}
          formGroupNameId={id}
          requiredKey={requiredKey}
          terminalGridId={terminalGridId}
          idx={index}
          colors={colors}
        />
      ))}
    </div>
  );
};

export default ConfirmForm;
