/**
 * metabase関連の設定値
 */

/**
 * metabaseのURL取得時に使うタイプ
 */
export const METABASE_TYPE = {
  /** 端末DLL持ち込み状況一覧 */
  DLL_CONDITION_LIST: "1",
  /** 一括事業者管理マスタ */
  BUSINESS_PERSON_MASTER: "2",
  /** 加盟店分類マスタ */
  CUSTOMER_TYPE_MASTER: "3",
  /** 対加盟店クレジット手数料率管理マスタ */
  CREDIT_RATE_MASTER: "4",
  /** 決済機能利用パターンマスタ */
  SETTLEMENT_PTN_MASTER: "5",
  /** 還元データ付加情報マスタ */
  REDUCTION_ADD_MASTER: "6",
  /** カード会社事業者加盟店番号マスタ */
  CARD_CUSTOMER_NUM: "7",
  /** クレジット決済機能情報 */
  CREDIT_SETTLEMENT_DATA: "8",
  /** 電子マネー・QR決済機能情報 */
  E_MONEY_AND_CODE_PAYMENT_DATA: "9",
};

export default METABASE_TYPE;
