/**
 * 共通-アップロードされた画像イメージ表示
 */
import React, { useContext, useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import { imageStyle } from "../styles";

import { IMAGE_ACCEPT_TYPE, PDF_TYPE } from "../../../../constants/deal";

import pdf from "../../../assets/img/common/pdf.png";
import file from "../../../../api/file";
import { AuthContext } from "../../../../cognito/AuthContext";

const useStyles = makeStyles(() => ({
  ul: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    lineHeight: 0,
  },
  errorWrap: {
    display: "flex",
    justifyContent: "center",
  },
  imageFileName: {
    marginTop: 10,
  },
  infoIcon: { color: "#f44336", fontSize: "1.4rem" },
  error: { color: "#f44336" },
}));

interface Props {
  formGroupName: string;
  formContent: string;
  index: number; // 基本Gridで複数アップロード可能な場合につける番号
  isError?: boolean;
}

const UploadImageView: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const imageClasses = imageStyle();

  const { formGroupName, formContent, index, isError } = props;

  const { authHeader } = useContext(AuthContext);
  const [fileName, setFileName] = useState(formContent.toString());

  useEffect(() => {
    const func = async () => {
      // ファイルダウンロードAPI
      const res = await file.download(authHeader || "", formContent);
      setFileName(res.data.result.file);
    };
    // base64データを取得していなければAPI呼ぶ
    if (
      formContent !== "" &&
      fileName !== "" &&
      !fileName.startsWith("data:")
    ) {
      func();
    } else if (formContent.startsWith("data:")) {
      setFileName(formContent);
    } else if (formContent === "") {
      setFileName(formContent);
    }
  }, [authHeader, formContent, fileName]);

  // 画像ファイルかどうか
  const isImage = () => {
    const findImage = IMAGE_ACCEPT_TYPE.find(
      (type) => (fileName as string).indexOf(type) !== -1
    );
    return findImage !== undefined;
  };

  // PDFファイルかどうか
  const isPdf = () => (fileName as string).indexOf(PDF_TYPE) !== -1;

  return (
    <>
      {isError && (
        <div className={classes.errorWrap}>
          <InfoIcon className={classes.infoIcon} />
          <div className={classes.error}>
            {" エラーメッセージをご確認ください。"}
          </div>
          <br />
        </div>
      )}
      ここにファイルをドラッグ＆ドロップ または クリックしてアップロード
      <ul className={classes.ul}>
        {fileName !== "" && isImage() && (
          <>
            <img
              className={imageClasses.thumbnail}
              alt={formGroupName}
              src={fileName as string}
            />
            <br />
            <div className={classes.imageFileName}>{`${formGroupName}${
              index === 0 ? "" : index
            }`}</div>
          </>
        )}
        {fileName !== "" && isPdf() && (
          <>
            <img alt={formGroupName} src={pdf} />
            <br />
            {`${formGroupName}${index === 0 ? "" : index}`}
          </>
        )}
      </ul>
    </>
  );
};

export default React.memo(UploadImageView);
