/**
 * 加盟店側-規約確認
 * メモ：規約確認系画面の親コンポーネント
 */
import React from "react";
import { Route, Switch } from "react-router-dom";

import GuestTermListPage from "./pages/GuestTermListPage";
import GuestTermPdfPage from "./pages/GuestTermPdfPage";

const GuestTermHooks: React.FC = () => {
  return (
    <>
      <Switch>
        {/* 規約一覧 */}
        <Route
          path="/guest/latest-terms"
          render={() => <GuestTermListPage />}
          exact
        />
        {/* 規約詳細 */}
        <Route
          path="/guest/latest-terms/pdf"
          render={() => <GuestTermPdfPage />}
          exact
        />
      </Switch>
    </>
  );
};

export default GuestTermHooks;
