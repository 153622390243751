/**
 * フォーム関係-JSONのフォーム用のセレクトボックス
 */
import React, { useCallback, useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import {
  FormControl,
  FormHelperText,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";

import {
  TFormColumn,
  TPulldownArray,
  TRequiredKey,
} from "../../../common/components/types";

import { FONT } from "../../../../constants/common";

import formStore from "../../../../store/formStore";
import { jsonParseSafety } from "../../../../utils/formData";

import FormRemarks from "./FormRemarks";
import ValidationMsg from "./ValidateMsg";

const useStyles = makeStyles((theme) => ({
  select: {
    "& .MuiFilledInput-root": {
      backgroundColor: "#f2f7fb",
      borderRadius: 4,
      width: 300,
      fontFamily: FONT,
      "&:before": {
        borderBottom: "none",
      },
    },
    "& .MuiFilledInput-root.Mui-focused": {
      borderBottom: "none",
      backgroundColor: "#fff",
      transition: "none",
    },
    "& .MuiFilledInput-underline": {
      "&:after": {
        content: "none",
      },
    },
    "& .MuiFilledInput-root.Mui-error": {
      border: "2px solid #f44336",
    },
    "& .MuiFilledInput-input": {
      padding: "12px 12px 10px",
      borderRadius: 4,
    },
    "& .MuiSelect-select.Mui-disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.09)",
    },
  },
  selectInput: {
    "& .MuiFormControl-root": {
      margin: 0,
    },
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
  error: {
    marginLeft: 0,
  },
  url: {
    fontFamily: FONT,
  },
}));

type Props = {
  columnName: TFormColumn;
  requiredKey: TRequiredKey;
  terminalGridId: number | undefined;
  gridId: number | undefined;
  isChangeDeal?: boolean;
};

const CustomSelect: React.FC<Props> = (props: Props) => {
  const { columnName, requiredKey, terminalGridId, gridId, isChangeDeal } =
    props;

  const [row, setRow] = useState(
    formStore.getFormRow(columnName, terminalGridId, gridId)
  );
  const contentForView = useMemo(
    () => jsonParseSafety(row?.content || ""),
    [row]
  );

  const pulldownArray = useMemo(
    () =>
      row?.json.formTypeOptions.map((o) => {
        const key = Object.keys(o)[0].toString();
        const value = Object.values(o)[0].toString();
        const obj: TPulldownArray = {};
        obj[key] = value;
        return obj;
      }) || [],
    [row]
  );

  const disabled = useMemo(
    () => !row?.json.updateEnabled[requiredKey] || row.disabled,
    [row, requiredKey]
  );

  const classes = useStyles();

  useEffect(() => {
    const fn = () => {
      const r = formStore.getFormRow(columnName, terminalGridId, gridId);
      setRow(r && { ...r });
    };
    formStore.addListener(columnName, fn);

    return () => formStore.removeListener(columnName, fn);
  }, [columnName, terminalGridId, gridId]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const targetValue = event.target.value as string;
      const selected = pulldownArray.find((p) =>
        Object.keys(p).includes(targetValue)
      );
      const data = selected ? JSON.stringify(selected) : "";
      formStore.updateContent(
        columnName,
        data,
        requiredKey,
        terminalGridId,
        gridId
      );
    },
    [columnName, requiredKey, pulldownArray, terminalGridId, gridId]
  );

  // rowがなければ表示しない
  if (!row) return null;

  return (
    row && (
      <div>
        {row.json.fixedValue !== null ? (
          // 値がなければ単位等も表示しない
          <>
            {contentForView && typeof contentForView === "object"
              ? Object.values(contentForView)[0]
              : contentForView || ""}
          </>
        ) : (
          <>
            <FormControl
              variant="filled"
              className={clsx(classes.formControl, classes.select)}
              error={row.errors.length > 0}
            >
              {/* セレクトボックス */}
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={
                  row.content ? Object.keys(JSON.parse(row.content))[0] : ""
                }
                className={classes.selectInput}
                onChange={handleChange}
                label="Select"
                disabled={disabled}
              >
                {(pulldownArray as TPulldownArray[]).map((pulldown) => (
                  <MenuItem
                    key={JSON.stringify(pulldown)}
                    value={
                      Object.keys(pulldown)[0] === "9999"
                        ? ""
                        : Object.keys(pulldown)[0]
                    }
                  >
                    {Object.values(pulldown)[0]}
                  </MenuItem>
                ))}
              </Select>
              {/* 項目の説明 */}
              <FormRemarks
                remarks={
                  isChangeDeal
                    ? row.json.changeFormRemarks
                    : row.json.formRemarks
                }
              />
              {/* 項目の説明（URL） */}
              <a
                className={classes.url}
                href={row.json.url}
                target="_blank"
                rel="noopener noreferrer"
                tabIndex={row.json.url ? 0 : -1}
              >
                {row.json.url}
              </a>
              {/* バリデーションエラーメッセージ */}
              {row.errors.length > 0 && (
                <FormHelperText className={classes.error}>
                  <ValidationMsg errors={row.errors} />
                </FormHelperText>
              )}
            </FormControl>
          </>
        )}
      </div>
    )
  );
};

export default CustomSelect;
