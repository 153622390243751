import {
  Checkbox,
  FormControl,
  TableCell,
  TableRow,
  TableRowProps,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { FONT, ThemeColorProps } from "../../../../constants/common";
import ShowFormValue from "./ShowFormValue";
import formStore from "../../../../store/formStore";
import {
  TFormColumn,
  TFormType,
  TRequiredKey,
} from "../../../common/components/types";
import useObserver from "../../../helpers/form/useObserver";
import FormTitle from "./FormTitle";
import ShowExternalDBValue from "./ShowExternalDBValue";
import {
  checkboxStyles,
  colorByRequiredKey,
} from "../../../common/components/styles";
import ChangeDealFormValue from "./ChangeDealFormValue";

const useStyles = makeStyles(() => ({
  th: {
    fontFamily: FONT,
    fontWeight: 900,
    backgroundColor: "#fff",
    color: "#000!important",
    width: 263,
    borderBottom: "2px dotted #ddd",
    position: "relative",
  },
  td: {
    backgroundColor: "#fff",
    color: "#000!important",
    borderBottom: "2px dotted #ddd",
    width: 200,
  },
  check: {
    fontFamily: FONT,
    padding: 1,
    backgroundColor: "#fff",
    color: "#000!important",
    fontSize: "0.9em",
    height: 38,
    width: 50,
    whiteSpace: "nowrap",
    borderBottom: "2px dotted #ddd",
  },
  addBorder: {
    border: "1px solid #555",
  },
}));

type Props = TableRowProps & {
  columnName: TFormColumn;
  requiredKey: TRequiredKey;
  formType: TFormType;
  colors: ThemeColorProps;
  terminalGridId: number | undefined;
  gridId: number | undefined;
  checked: boolean;
  handleCheckboxChange: (columnName: TFormColumn, checked: boolean) => void;
};
const ChangeDealFormTableRow: React.FC<Props> = (props: Props) => {
  const {
    columnName,
    requiredKey,
    formType,
    colors,
    terminalGridId,
    gridId,
    className,
    checked,
    handleCheckboxChange,
  } = props;
  const [init, setInit] = useState(false);
  const [isHide, setHide] = useState(
    formStore.isHideRowForChangeRequest(columnName, requiredKey)
  );
  const target = React.useRef<HTMLTableRowElement>(null);
  // 描画境界判定
  const intersect = useObserver(target, {
    root: document.getElementById("scroll-parent"), // TabPanel
    rootMargin: "300px 0px 300px 0px", // 上下300px広く判定
  });

  const classes = useStyles();
  const checkboxClasses = checkboxStyles({
    backgroundColor: colorByRequiredKey(requiredKey).primary,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleCheckboxChange(columnName, event.target.checked);
    formStore.setChangeableColumnName(columnName, event.target.checked);
  };

  useEffect(() => {
    const fn = () => {
      setHide(formStore.isHideRowForChangeRequest(columnName, requiredKey));
    };
    formStore.addListener(columnName, fn);

    return () => formStore.removeListener(columnName, fn);
  }, [columnName, requiredKey]);

  useEffect(() => {
    // タブ切り替え時に"scroll-parent"がDOMについたあとuseObserverするため
    setInit(true);
  }, []);

  if (isHide) return null;

  return init ? (
    <TableRow
      className={className}
      key={columnName}
      innerRef={target}
      style={{
        height: intersect
          ? "auto"
          : "75px" /* 描画範囲外の場合は仮で高さを設定しておく */,
      }}
    >
      <TableCell align="center" className={classes.check}>
        <FormControl className={checkboxClasses.checkbox}>
          <Checkbox
            checked={checked}
            onChange={handleChange}
            classes={{ root: classes.addBorder }}
          />
        </FormControl>
      </TableCell>
      <TableCell align="left" className={classes.th}>
        <FormTitle
          columnName={columnName}
          requiredKey={requiredKey}
          isChangeDeal
        />
      </TableCell>
      <TableCell align="left" className={classes.td}>
        {intersect ? (
          <>
            {checked ? (
              <ChangeDealFormValue
                columnName={columnName}
                requiredKey={requiredKey}
                formType={formType}
                terminalGridId={terminalGridId}
                backgroundColor={colors.primary}
                gridId={gridId}
              />
            ) : (
              <ShowFormValue
                columnName={columnName}
                terminalGridId={terminalGridId}
                gridId={gridId}
              />
            )}
          </>
        ) : null}
      </TableCell>
      <TableCell align="left" className={classes.td}>
        {intersect ? (
          <ShowExternalDBValue
            columnName={columnName}
            terminalGridId={terminalGridId}
            gridId={gridId}
            dbName="jtbApproved"
          />
        ) : null}
      </TableCell>
      <TableCell align="left" className={classes.td}>
        {intersect ? (
          <ShowExternalDBValue
            columnName={columnName}
            terminalGridId={terminalGridId}
            gridId={gridId}
            dbName="centerReflected"
          />
        ) : null}
      </TableCell>
      <TableCell align="left" className={classes.td}>
        {intersect ? (
          <ShowExternalDBValue
            columnName={columnName}
            terminalGridId={terminalGridId}
            gridId={gridId}
            dbName="centerToBeReflected"
          />
        ) : null}
      </TableCell>
    </TableRow>
  ) : null;
};

export default ChangeDealFormTableRow;
