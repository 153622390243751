/**
 * 共通-一致条件のラベル
 */
import React from "react";

import { Chip, makeStyles } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";

interface Props {
  left?: number;
  top: string; // %で指定(例：37%%など)
  text?: string; // ラベル名
}

const PatternMatchChip: React.FC<Props> = (props: Props) => {
  const { left, top, text } = props;

  const useStyles = makeStyles(() => ({
    // 「前方一致」
    frontChip: {
      borderRadius: 0,
      marginLeft: 10,
      position: "absolute",
      backgroundColor: "#fff",
      color: "rgb(90, 88, 88)",
      border: "1px solid rgb(90, 88, 88)",
      height: 24,
      left,
      top,
    },
    // 「完全一致」
    completeChip: {
      borderRadius: 0,
      marginLeft: 10,
      position: "absolute",
      backgroundColor: "rgb(90, 88, 88)",
      color: "#fff",
      height: 24,
      left,
      top,
    },
  }));
  const classes = useStyles();

  return (
    <>
      <Chip
        className={
          text === "前方一致" || text === "部分一致"
            ? classes.frontChip
            : classes.completeChip
        }
        label={text}
        deleteIcon={<DoneIcon />}
      />
    </>
  );
};

export default PatternMatchChip;
