import React from "react";

const useObserver: (
  ref: React.RefObject<HTMLDivElement>,
  ops?: IntersectionObserverInit
) => boolean = (
  ref: React.RefObject<HTMLDivElement>,
  ops?: IntersectionObserverInit
) => {
  const [intersect, setIntersect] = React.useState<boolean>(false);
  React.useEffect(() => {
    function callback(entries: IntersectionObserverEntry[]) {
      entries.forEach((entry) => {
        setIntersect(entry.isIntersecting);
      });
    }
    const observer = new IntersectionObserver(callback, ops);
    if (ref.current === null) {
      return () => {
        /* 何もしない */
      };
    }
    observer.observe(ref.current);
    return () => {
      if (ref.current !== null) {
        // eslint-disable-next-line
        observer.unobserve(ref.current);
      }
    };
  });
  return intersect;
};

export default useObserver;
