/**
 * JTB側-精算明細書検索入力フォーム
 * メモ：入力フォームを表示する
 */
import React from "react";

import clsx from "clsx";
import {
  FormControl,
  FormLabel,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";

import { formStyles } from "../../../../common/components/styles";

import { BillSearchCondition } from "../../types";

import { ADMIN_COLOR, FONT } from "../../../../../constants/common";

import CustomTextField from "../../../../common/components/atoms/CustomTextField";
import PatternMatchChip from "../../../../common/components/atoms/PatternMatchChip";

const useStyles = makeStyles(() => ({
  label: {
    width: 800,
    textAlign: "left",
    padding: "0!important",
    height: 36,
  },
  table: {
    width: 800,
    minWidth: 800,
  },
  td: {
    padding: 1,
    backgroundColor: "#fff",
    color: "#000!important",
    borderTop: "1px solid #d2d5d8",
    borderBottom: "1px solid #d2d5d8",
    borderRight: "1px solid #d2d5d8",
    width: 400,
  },
  th: {
    padding: 0,
    fontFamily: FONT,
    backgroundColor: "#fff",
    color: "#000!important",
    width: 200,
    border: "1px solid #d2d5d8",
    position: "relative",
    paddingLeft: 20,
  },
}));

// 検索条件フォーム設定
const billSearchFormData = [
  {
    name: "加盟店コード",
    formData: "customerCode",
    matchType: "前方一致",
  },
  {
    name: "加盟店名",
    formData: "customerName",
    matchType: "部分一致",
  },
];

interface Props {
  searchCondition: BillSearchCondition;
  setSearchCondition: React.Dispatch<React.SetStateAction<BillSearchCondition>>;
}

const BillSearchConditionForm: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const formClasses = formStyles({
    color: ADMIN_COLOR.base,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const { searchCondition, setSearchCondition } = props;

  return (
    <FormControl className={formClasses.form}>
      <FormLabel className={clsx(formClasses.formLabel, classes.label)}>
        精算明細書検索
      </FormLabel>
      <TableContainer>
        <Table
          className={clsx(formClasses.table, classes.table)}
          aria-label="simple table"
        >
          <TableBody>
            {billSearchFormData.map((formItem) => (
              <TableRow key={formItem.name}>
                <TableCell
                  align="left"
                  className={clsx(formClasses.th, classes.th)}
                >
                  {formItem.name}
                  <PatternMatchChip
                    left={153}
                    top="24%"
                    text={formItem.matchType}
                  />
                </TableCell>
                <TableCell align="left" className={classes.td}>
                  <CustomTextField
                    value={
                      searchCondition[
                        formItem.formData as keyof BillSearchCondition
                      ]
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchCondition({
                        ...searchCondition,
                        [formItem.formData]: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FormControl>
  );
};

export default BillSearchConditionForm;
