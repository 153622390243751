/**
 * フォーム関係-項目の値を表示するコンポーネント
 * メモ：JSONのformTypeに応じて整形している
 */
import React, { useEffect, useState } from "react";

import { TFormColumn } from "../../../common/components/types";

import formStore from "../../../../store/formStore";
import { jsonParseSafety } from "../../../../utils/formData";
import { separateWithComma } from "../../../../utils/string";

import ShowFile from "../../../common/components/atoms/ShowFile";

type Props = {
  columnName: TFormColumn;
  terminalGridId: number | undefined;
  gridId: number | undefined;
};

const ShowFormValue: React.FC<Props> = (props: Props) => {
  const { columnName, terminalGridId, gridId } = props;

  const [row, setRow] = useState(
    formStore.getFormRow(columnName, terminalGridId, gridId)
  );

  useEffect(() => {
    const fn = () => {
      const r = formStore.getFormRow(columnName, terminalGridId, gridId);
      setRow(r && { ...r });
    };
    formStore.addListener(columnName, fn);

    return () => formStore.removeListener(columnName, fn);
  }, [columnName, terminalGridId, gridId]);

  if (!row) return null;

  return (
    <>
      {(row.json.formType === "text" || row.json.formType === "date") &&
        row.content}
      {row.json.formType === "number" && separateWithComma(row.content)}
      {(row.json.formType === "radio" ||
        row.json.formType === "select" ||
        row.json.formType === "search-select" ||
        // TODO: 今回 checkbox は対応見送りになっている。使う場合は調整が必要
        row.json.formType === "checkbox") &&
      row.content !== ""
        ? Object.values(jsonParseSafety(row.content))[0]
        : ""}
      {row.json.formType === "textArea" && (
        <>
          {row.content.split("\n").map((line: string) => (
            <span key={line}>
              {line}
              <br />
            </span>
          ))}
        </>
      )}
      {row.json.formType === "file" && (
        <ShowFile
          formGroupName={row.json.formGroupName}
          formContent={row.content}
        />
      )}
    </>
  );
};

export default ShowFormValue;
