/**
 * フォーム関係-解約申請確認用-フォームでの項目の行
 */
import React, { useEffect, useRef, useState } from "react";

import {
  TableCell,
  TableRow,
  TableRowProps,
  makeStyles,
} from "@material-ui/core";

import {
  TFormColumn,
  TRequiredKey,
  TFormObject,
} from "../../../common/components/types";

import { FONT } from "../../../../constants/common";

import useObserver from "../../../helpers/form/useObserver";

import FormTitleForTerminateDeal from "./FormTitleForTerminateDeal";
import ShowFormValueForTerminateDeal from "./ShowFormValueForTerminateDeal";

const useStyles = makeStyles(() => ({
  th: {
    fontFamily: FONT,
    fontWeight: 900,
    backgroundColor: "#fff",
    color: "#000!important",
    width: 263,
    borderBottom: "2px dotted #ddd",
    position: "relative",
  },
  td: {
    backgroundColor: "#fff",
    color: "#000!important",
    borderBottom: "2px dotted #ddd",
  },
  thMessage: {
    width: 160,
  },
}));

type Props = TableRowProps & {
  columnName: TFormColumn;
  terminateFormRowObj: TFormObject;
  requiredKey: TRequiredKey;
};

const getIsHide = (
  terminateFormRowObj: TFormObject,
  requiredKey: TRequiredKey
) => {
  const row = terminateFormRowObj;
  const res =
    !row ||
    !row.json ||
    !row.json.showEnabled ||
    !row.json.showEnabled[requiredKey];
  return res;
};

const ConfirmFormValueRowForTerminateDeal: React.FC<Props> = (props: Props) => {
  const { columnName, terminateFormRowObj, requiredKey, className } = props;

  const [init, setInit] = useState(false);
  const [isHide, setHide] = useState(
    getIsHide(terminateFormRowObj, requiredKey)
  );
  const target = useRef<HTMLTableRowElement>(null);
  // 描画境界判定
  const intersect = useObserver(target, {
    root: document.getElementById("scroll-parent"), // TabPanel
    rootMargin: "300px 0px 300px 0px", // 上下300px広く判定
  });

  const classes = useStyles();

  useEffect(() => {
    setHide(getIsHide(terminateFormRowObj, requiredKey));
  }, [terminateFormRowObj, requiredKey]);

  useEffect(() => {
    // タブ切り替え時に"scroll-parent"がDOMについたあとuseObserverするため
    setInit(true);
  }, []);

  if (isHide) return null;

  return init ? (
    <TableRow
      className={className}
      key={columnName}
      innerRef={target}
      style={{
        height: intersect
          ? "auto"
          : "75px" /* 描画範囲外の場合は仮で高さを設定しておく */,
      }}
    >
      {/* 項目の名称 */}
      <TableCell align="left" className={classes.th}>
        <FormTitleForTerminateDeal
          terminateFormRowObj={terminateFormRowObj}
          requiredKey={requiredKey}
        />
      </TableCell>
      {/* 項目の値 */}
      <TableCell align="left" className={classes.td}>
        {intersect ? (
          <ShowFormValueForTerminateDeal
            terminateFormRowObj={terminateFormRowObj}
          />
        ) : null}
      </TableCell>
    </TableRow>
  ) : null;
};

export default ConfirmFormValueRowForTerminateDeal;
