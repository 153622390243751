import { UserAuthInfo, ICustomers } from "../types";
import { PolicyList } from "../../constants/auth";

type LoginUserInfoObj = {
  loginUserAuth: UserAuthInfo | undefined;
  isCustomer: boolean;
  isLoaded: boolean;
};

// ログインユーザー情報保持オブジェクト
const LOGIN_USER_INFO_OBJ: LoginUserInfoObj = {
  loginUserAuth: undefined,
  isCustomer: false,
  isLoaded: false,
};

export const setLoaded: (loaded: boolean) => void = (loaded: boolean) => {
  LOGIN_USER_INFO_OBJ.isLoaded = loaded;
};

export const setLoginUserAuth: (
  auth: UserAuthInfo,
  authList: PolicyList,
  isCustomer?: boolean
) => void = (
  auth: UserAuthInfo,
  authList: PolicyList,
  isCustomer?: boolean
) => {
  LOGIN_USER_INFO_OBJ.loginUserAuth = auth;
  LOGIN_USER_INFO_OBJ.loginUserAuth.authorities = authList;
  LOGIN_USER_INFO_OBJ.isCustomer = !!isCustomer;
};

export const clearLoginUserAuth: () => void = () => {
  LOGIN_USER_INFO_OBJ.loginUserAuth = undefined;
  LOGIN_USER_INFO_OBJ.isCustomer = false;
};

export const getUserAuthFlags: () => PolicyList | undefined = () => {
  return LOGIN_USER_INFO_OBJ.loginUserAuth?.authorities;
};

export const getUserDisplayName: () => string = () => {
  return LOGIN_USER_INFO_OBJ.loginUserAuth?.name || "";
};

export const getUserType: () => string | undefined = () => {
  return LOGIN_USER_INFO_OBJ.loginUserAuth?.userType;
};

export const getContractorId: () => string | null | undefined = () => {
  return LOGIN_USER_INFO_OBJ.loginUserAuth?.contractorId;
};

export const getCustomerId: () => string | null | undefined = () => {
  return LOGIN_USER_INFO_OBJ.loginUserAuth?.masterCustomerId;
};

export const getCustomers: () => ICustomers[] | undefined = () => {
  return LOGIN_USER_INFO_OBJ.loginUserAuth?.customers;
};

export const getGroupName: () => string | undefined = () => {
  return LOGIN_USER_INFO_OBJ.loginUserAuth?.groupName;
};

export const getPhoneNumber: () => string = () => {
  return LOGIN_USER_INFO_OBJ.loginUserAuth?.phoneNumber ?? "";
};
// 権限情報ロード待ち関数
const waitLoad = async () => {
  if (LOGIN_USER_INFO_OBJ.isLoaded) {
    return;
  }
  const wait = async (ms: number) => {
    return new Promise((resol) => {
      setTimeout(resol, ms);
    });
  };

  await wait(330);
  await waitLoad();
};

// APIエラーレスポンスに構造を合わせる
// eslint-disable-next-line
export const checkAuth: (key: keyof PolicyList) => any | null = async (
  key: keyof PolicyList
) => {
  // 権限情報ロード待ち
  await waitLoad();

  // 権限フラグ取得
  const authFlags = getUserAuthFlags();
  if (!authFlags) {
    // フラグのオブジェクト自体取れない場合はログイン情報がない（発生しないはず）
    return {
      response: {
        status: 400,
        data: {
          message: "ログイン情報が不正です。再ログインしてください。",
        },
      },
    };
  }
  if (!authFlags[key]) {
    // フラグがtrueでなければ権限エラー
    return {
      response: {
        status: 400,
        data: {
          message: "権限がありません。",
        },
      },
    };
  }

  return null;
};
