/**
 * JTB側-申請編集画面
 * メモ：端末新規申請の場合、契約者情報は編集不可。端末増設申請の場合、契約者情報、加盟店情報は編集不可。
 */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import clsx from "clsx";
import { makeStyles, Container, Button, Tabs, Tab } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import StoreIcon from "@material-ui/icons/Store";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CommentIcon from "@material-ui/icons/CommentOutlined";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";

import { buttonStyles } from "../../../../common/components/styles";
import { useContainerStyles } from "../../../../templates/styles";

import { ADMIN_COLOR, FONT } from "../../../../../constants/common";
import {
  DEAL_FORM_TAB_NAMES,
  FORM_CATEGORY,
} from "../../../../../constants/form";

import { GlobalPopupContext } from "../../../../../App";
import formStore from "../../../../../store/formStore";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import TabPanel from "../../../../common/components/atoms/TabPanel";
import ConfirmForm from "../../../../form/components/organisms/ConfirmForm";
import NewForm from "../../../../form/components/organisms/NewForm";
import TerminalForm from "../../../../form/components/organisms/TerminalForm";
import CommentForm from "../../../../form/components/atoms/CommentForm";

const useStyles = makeStyles(() => ({
  returnButton: {
    margin: "0 auto",
    paddingTop: 8,
    paddingBottom: 10,
    paddingLeft: 42,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 24,
    },
    width: "130px",
  },
  checkIcon: {
    position: "absolute",
    top: 8,
    left: 20,
  },
  checkButton: {
    color: `${ADMIN_COLOR.primary}!important`,
    border: `1px solid ${ADMIN_COLOR.primary}`,
    backgroundColor: `${ADMIN_COLOR.base}!important`,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 24,
    },
    margin: "0 auto",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 0,
    "&:hover": {
      backgroundColor: `${ADMIN_COLOR.btnHover}!important`,
    },
    width: "130px",
  },
  buttonContainer: {
    margin: "0 auto",
    clear: "both",
    height: 100,
    width: 284,
    marginTop: 20,
  },
  returnIcon: {
    position: "absolute",
    top: 10,
    left: 31,
  },
  gridButtons: {
    float: "left",
  },

  Icon: {
    verticalAlign: "top",
    left: -2,
    top: 1,
    position: "relative",
  },
  tabs: {
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: ADMIN_COLOR.primary,
      color: ADMIN_COLOR.base,
    },
  },
  tab: {
    color: ADMIN_COLOR.primary,
    border: `3px solid ${ADMIN_COLOR.primary}`,
    marginRight: 4,
    minHeight: 35,
    borderBottom: "none",
    fontFamily: FONT,
    opacity: 1,
    borderRadius: "8px 8px 0px 0px",
    "& .MuiTab-wrapper": {
      display: "inline!important",
    },
    "& .MuiTab-labelIcon": {
      minHeight: 35,
    },
  },
  indicator: {
    display: "none",
  },
  message: {
    fontSize: "0.9em",
    fontWeight: "bold",
    fontFamily: FONT,
    color: ADMIN_COLOR.primary,
    marginBottom: 15,
  },
}));

const DealEditPage: React.FC<RouteComponentProps<{ id: string }>> = (
  props: RouteComponentProps<{ id: string }>
) => {
  const { match, history } = props;

  const { setSnackbar } = useContext(GlobalPopupContext);
  const [tabValue, setTabValue] = useState(0);
  const [isShowCheckSheet, setShowCheckSheet] = useState(() =>
    formStore.isShowChackSheet()
  );
  const [isShowCheckList, setIsShowCheckList] = useState(() =>
    formStore.isShowCheckList()
  );

  const dealType = useMemo(() => formStore.getEditDealType(), []);

  const breadCrumbs = [
    { name: "TOP", link: "/dealer" },
    { name: "申請詳細", link: `/dealer/deal/${match.params.id}` },
    { name: "編集", link: "" },
  ];

  const classes = useStyles();
  const moveButtonClasses = buttonStyles({
    width: 180,
    marginTop: 20,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const containerClasses = useContainerStyles();

  useEffect(() => {
    const key = `dealer-deal-${match.params.id}`;
    const loadDraftData = async () => {
      // この申請の下書きがあれば読み込み
      if (localStorage.getItem(key)) {
        const draftData = await formStore.loadDraft(key);
        if (!draftData) {
          // 下書きデータが存在しないときの処理
        }
      }
    };

    loadDraftData();
    const fn = () => {
      setIsShowCheckList(formStore.isShowCheckList());
      setShowCheckSheet(formStore.isShowChackSheet());
    };

    const save = () => {
      formStore.saveDraft(key);
    };
    // 加盟店分類で表示タブが変わるので
    formStore.addListener("customerType", fn);
    formStore.addListener("updatedContent", save);
    return () => {
      formStore.removeListener("customerType", fn);
      formStore.removeListener("updatedContent", save);
    };
  }, [match.params.id]);

  const handleToConfirmPage = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    // ボタンクリック以外（エンターキー押下など）の場合は何もしない
    if (e.detail !== 1) return;

    // バリデーション実行
    if (formStore.validationAll("dealer")) {
      history.push(`/dealer/deal/${match.params.id}/edit/confirm`);
    } else {
      // エラーのあるタブに移動
      const category = formStore.getFocusErrorCategory();
      if (category > 0) {
        setTabValue(category - 1);
      }
      setSnackbar({
        openProps: true,
        message: "入力不備のフィールドがあります。",
      });
    }
  };

  const handleChange = useCallback((_, newValue: number) => {
    setTabValue(newValue);
  }, []);

  return (
    <Dashboard
      title="申請管理"
      color={ADMIN_COLOR.base}
      backgroundColor={ADMIN_COLOR.primary}
      iconName="deal"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {/* 文言 */}
        <div className={classes.message}>
          {`タブをクリックすることで「${DEAL_FORM_TAB_NAMES.CONTRACTOR_INFO}」、「${DEAL_FORM_TAB_NAMES.CUSTOMER_INFO}」、「${DEAL_FORM_TAB_NAMES.TERMINAL_INFO}」`}
          {isShowCheckSheet && `、「${DEAL_FORM_TAB_NAMES.IMPORTANT_INFO}」`}
          {`、「${DEAL_FORM_TAB_NAMES.COMMENT_AND_MEMO}」の各フォームが表示されます。`}
        </div>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          className={classes.tabs}
          classes={{ indicator: classes.indicator }}
        >
          {/* 契約者情報タブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.CONTRACTOR_INFO}`}
            id="simple-tab-0"
            aria-labelledby="simple-tab-0"
            icon={<BusinessCenterIcon className={classes.Icon} />}
            className={classes.tab}
          />
          {/* 加盟店情報タブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.CUSTOMER_INFO}`}
            id="simple-tab-1"
            aria-labelledby="simple-tab-1"
            icon={<StoreIcon className={classes.Icon} />}
            className={classes.tab}
          />
          {/* 端末情報タブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.TERMINAL_INFO}`}
            id="simple-tab-2"
            aria-labelledby="simple-tab-2"
            icon={<PhonelinkSetupIcon className={classes.Icon} />}
            className={classes.tab}
          />
          {/* 重要事項タブ */}
          {isShowCheckSheet && (
            <Tab
              label={` ${DEAL_FORM_TAB_NAMES.IMPORTANT_INFO}`}
              id="simple-tab-3"
              aria-labelledby="simple-tab-3"
              icon={<CheckBoxIcon className={classes.Icon} />}
              className={classes.tab}
            />
          )}
          {/* コメント・メモタブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.COMMENT_AND_MEMO}`}
            id="simple-tab-4"
            aria-labelledby="simple-tab-4"
            icon={<CommentIcon className={classes.Icon} />}
            className={classes.tab}
          />
          {/* チェックリストタブ */}
          {isShowCheckList && (
            <Tab
              label={` ${DEAL_FORM_TAB_NAMES.CHECK_LIST}`}
              id="simple-tab-5"
              aria-labelledby="simple-tab-5"
              icon={<CheckBoxIcon className={classes.Icon} />}
              className={classes.tab}
            />
          )}
        </Tabs>
        <form>
          {/* 契約者情報 */}
          <TabPanel
            value={tabValue}
            index={0}
            borderColor={ADMIN_COLOR.primary}
          >
            <ConfirmForm
              formCategory={FORM_CATEGORY.CONTRACTOR} // 契約者情報
              requiredKey="dealer"
              colors={ADMIN_COLOR}
              terminalGridId={undefined}
            />
          </TabPanel>
          {/* 加盟店情報 */}
          <TabPanel
            value={tabValue}
            index={1}
            borderColor={ADMIN_COLOR.primary}
          >
            {/* 端末増設申請の場合は編集不可 */}
            {dealType === "端末新規" ? (
              <NewForm
                formCategory={FORM_CATEGORY.CUSTOMER} // 加盟店情報
                requiredKey="dealer"
                colors={ADMIN_COLOR}
                terminalGridId={undefined}
              />
            ) : (
              <ConfirmForm
                formCategory={FORM_CATEGORY.CUSTOMER} // 加盟店情報
                requiredKey="dealer"
                colors={ADMIN_COLOR}
                terminalGridId={undefined}
              />
            )}
          </TabPanel>
          {/* 端末情報 */}
          <TabPanel
            value={tabValue}
            index={2}
            borderColor={ADMIN_COLOR.primary}
          >
            <TerminalForm
              type="new"
              requiredKey="dealer"
              colors={ADMIN_COLOR}
            />
          </TabPanel>
          {/* 重要事項 */}
          {isShowCheckSheet && (
            <TabPanel
              value={tabValue}
              index={3}
              borderColor={ADMIN_COLOR.primary}
            >
              <NewForm
                formCategory={FORM_CATEGORY.CHECK_SHEET} // 重要事項チェックシート
                requiredKey="dealer"
                colors={ADMIN_COLOR}
                terminalGridId={undefined}
              />
            </TabPanel>
          )}
          {/* コメント・メモ */}
          <TabPanel
            value={tabValue}
            index={3 + (isShowCheckSheet ? 1 : 0)}
            borderColor={ADMIN_COLOR.primary}
          >
            <CommentForm dealId={match.params.id} colors={ADMIN_COLOR} />
          </TabPanel>
          {/* チェックリスト */}
          {isShowCheckList && (
            <TabPanel
              value={tabValue}
              index={4 + (isShowCheckSheet ? 1 : 0)}
              borderColor={ADMIN_COLOR.primary}
            >
              <NewForm
                formCategory={FORM_CATEGORY.CHECK_LIST}
                requiredKey="dealer"
                colors={ADMIN_COLOR}
                terminalGridId={undefined}
              />
            </TabPanel>
          )}
          <div className={classes.buttonContainer}>
            {/* 戻るボタン */}
            <Button
              className={clsx(
                moveButtonClasses.button,
                classes.returnButton,
                classes.gridButtons
              )}
              type="button"
              component={Link}
              to={`/dealer/deal/${match.params.id}`}
              variant="contained"
            >
              <KeyboardReturnIcon className={classes.returnIcon} />
              戻る
            </Button>
            {/* 確認するボタン */}
            <Button
              type="submit"
              onClick={handleToConfirmPage}
              className={clsx(moveButtonClasses.button, classes.checkButton)}
              variant="contained"
            >
              <CheckIcon className={classes.checkIcon} />
              確認する
            </Button>
          </div>
        </form>
      </Container>
    </Dashboard>
  );
};

export default withRouter(DealEditPage);
