import { ADDRESS_SPLIT_INDEX } from "../../constants/deal";

// 町名・番地・建物名を分離する
export const splitAddress: (str: string, index?: number) => string = (
  str: string,
  index?: number
) => {
  // 欲しい項目指定がなければ元の文字列を返す
  if (index === undefined) {
    return str;
  }

  const allNum = `([0-9０-９]+|[一二三四五六七八九十百千]+)`;
  // 丁目・番地などの文字列取得用正規表現
  const regExp = new RegExp(
    `${allNum}+(${allNum}|(番町|丁目|丁|番地|番|号|-|‐|–|ー|−|－|の)(北|東|西|南)*)*(${allNum}|(丁目|丁|番地|番|号))`,
    "g"
  );

  // 括弧を取り除く
  let address = str.replace(/\(|\)/g, "");
  address = address.replace(/（|）/g, "");

  // matchしたものを長いもの順に
  const addressNumber = address
    .match(regExp)
    ?.sort((a, b) => b.length - a.length);

  // 番地がなければ元の文字列返す
  if (!addressNumber || addressNumber.length <= 0) {
    return str;
  }

  // 番地を返す
  if (index === ADDRESS_SPLIT_INDEX.ADDRESS_NUMBER) {
    return addressNumber[0];
  }

  // 番地を改行コードに置換
  const withoutAddressNum = address.replace(addressNumber[0], "\n");
  const split = withoutAddressNum.split("\n");

  // 改行コードの前（町名）、後（建物名）
  return split.length > index ? split[index] : "";
};

export default splitAddress;
