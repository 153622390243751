/**
 * フォーム関係-JSONで設定されているformName（項目の名称）を表示するコンポーネント
 */
import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core";

import { TFormColumn, TRequiredKey } from "../../../common/components/types";

import formStore from "../../../../store/formStore";

import BreakElementForLineText from "../../../common/components/atoms/BreakElementForLineText";
import RequiredChip from "../../../common/components/atoms/RequiredChip";

const useStyles = makeStyles(() => ({
  thMessage: {
    width: 160,
  },
  thAttention: {
    display: "block",
    color: "#ed1c24",
  },
}));

type Props = {
  columnName: TFormColumn;
  requiredKey: TRequiredKey;
  isChangeDeal?: boolean;
};

const FormTitle: React.FC<Props> = (props: Props) => {
  const { columnName, requiredKey, isChangeDeal } = props;

  const [row, setRow] = useState(formStore.getFormRow(columnName));

  const classes = useStyles();

  useEffect(() => {
    const fn = () => {
      const r = formStore.getFormRow(columnName);
      setRow(r && { ...r });
    };
    formStore.addListener(columnName, fn);

    return () => formStore.removeListener(columnName, fn);
  }, [columnName]);

  return row ? (
    <>
      {/* 項目の名称 */}
      <div className={classes.thMessage}>
        <BreakElementForLineText text={row.json.formName} />
        {isChangeDeal && row.json.linkedItem && (
          <span className={classes.thAttention}>※カード会社連携項目</span>
        )}
      </div>
      {/* 必須ラベル */}
      {row.json.required[requiredKey] && row.json.fixedValue === null && (
        <RequiredChip left={183} top="37%" />
      )}
    </>
  ) : null;
};

export default FormTitle;
