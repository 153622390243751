import { getObjectValue, jsonParseSafety } from "../../utils/formData";
import { TFormRowData } from "../../views/common/components/types";
import { TFixedNumberProcessResult } from "./types";

/**
 * 法人格カナ変換定数
 */
const LEGALPERSONALITY_KANA = {
  株式会社: "ｶﾌﾞｼｷｶﾞｲｼｬ",
  有限会社: "ﾕｳｹﾞﾝｶﾞｲｼｬ",
  一般財団法人: "ｲｯﾊﾟﾝｻﾞｲﾀﾞﾝﾎｳｼﾞﾝ",
  一般社団法人: "ｲｯﾊﾟﾝｼｬﾀﾞﾝﾎｳｼﾞﾝ",
  公益財団法人: "ｺｳｴｷｻﾞｲﾀﾞﾝﾎｳｼﾞﾝ",
  合資会社: "ｺﾞｳｼｶﾞｲｼｬ",
  合名会社: "ｺﾞｳﾒｲｶﾞｲｼｬ",
  合同会社: "ｺﾞｳﾄﾞｳｶﾞｲｼｬ",
  協同組合: "ｷｮｳﾄﾞｳｸﾐｱｲ",
  生活協同組合: "ｾｲｶﾂｷｮｳﾄﾞｳｸﾐｱｲ",
  宗教法人: "ｼｭｳｷｮｳﾎｳｼﾞﾝ",
  事業団: "ｼﾞｷﾞｮｳﾀﾞﾝ",
  公営: "ｺｳｴｲ",
  なし: "", // 「なし」は空文字
  "": "",
} as const;

/**
 * ②契約者会社名、契約者会社名カナの値計算
 * @param data: TFormRowData フォームデータ
 * @returns TFixedNumberProcessResult
 */
export const calcCompanyName: (
  data: TFormRowData
) => TFixedNumberProcessResult = (data: TFormRowData) => {
  // 法人・個人区分
  const applicantType = data.applicantType.find((d) => d.content);
  // 未選択などの場合は空文字
  if (!applicantType)
    return [
      { column: "companyName", content: "" },
      { column: "companyNameK", content: "" },
    ];
  const isCompany = applicantType.content === JSON.stringify({ "1": "法人" });

  // 「個人」選択の場合は代表者名、代表者名カナを返す
  if (!isCompany) {
    const represent = `${data.representLastName[0].content}${"　"}${
      data.representFirstName[0].content
    }`;
    const representK = `${data.representLastNameK[0].content} ${data.representFirstNameK[0].content}`;
    return [
      { column: "companyName", content: represent },
      { column: "companyNameK", content: representK },
    ];
  }

  // 法人格項目取得
  const legalPersonality = data.legalPersonality.find((d) => d.content);
  // 選択した値取得
  const legalPersonalityContent = legalPersonality
    ? getObjectValue(jsonParseSafety(legalPersonality.content))[0]
    : "";
  const legalPersonalityPos = data.legalPersonalityPos.find((d) => d.content);
  if (!legalPersonalityPos)
    return [
      { column: "companyName", content: "" },
      { column: "companyNameK", content: "" },
    ];
  // 法人格（前）
  const legalPersonalityBefore = legalPersonalityPos.content.includes("前")
    ? legalPersonalityContent
    : "";
  // 法人格（後）
  const legalPersonalityAfter = legalPersonalityPos.content.includes("後")
    ? legalPersonalityContent
    : "";

  // 「なし」の場合は空文字
  const companyName = `${
    legalPersonalityBefore !== "なし" ? legalPersonalityBefore : ""
  }${data.companyNameNonLP[0].content}${
    legalPersonalityAfter !== "なし" ? legalPersonalityAfter : ""
  }`;
  const companyNameK = data.companyNameKNonLP[0].content
    ? `${
        LEGALPERSONALITY_KANA[
          legalPersonalityBefore as keyof typeof LEGALPERSONALITY_KANA
        ]
      }${data.companyNameKNonLP[0].content}${
        LEGALPERSONALITY_KANA[
          legalPersonalityAfter as keyof typeof LEGALPERSONALITY_KANA
        ]
      }`
    : "";

  return [
    { column: "companyName", content: companyName },
    { column: "companyNameK", content: companyNameK },
  ];
};

export default calcCompanyName;
