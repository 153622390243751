/**
 * JTB側-自社ユーザー編集入力フォーム
 * メモ：入力フォームを表示する
 */
import React from "react";

import clsx from "clsx";
import {
  FormControl,
  FormControlLabel,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";

import { checkboxStyles } from "../../../../common/components/styles";

import { UserEditItemObject } from "../../types";

import {
  ADMIN_COLOR,
  DISPLAY_NAME_MAX_LENGTH,
  MEMO_MAX_LENGTH,
  FONT,
} from "../../../../../constants/common";
import { JTB_USER_TYPE_NAME_LIST } from "../../../../../constants/user";

import { isCardCompanyUser } from "../../../../../utils/auth";

import CustomTextField from "../../../../common/components/atoms/CustomTextField";
import VariableCustomCheckbox from "../../../../common/components/atoms/VariableCustomCheckbox";
import CustomSelect from "../../../../common/components/molecules/CustomSelect";

const useStyles = makeStyles(() => ({
  table: {
    width: "100%",
    minWidth: 800,
  },
  td: {
    padding: 1,
    backgroundColor: ADMIN_COLOR.base,
    fontFamily: FONT,
    color: "#000!important",
    borderTop: "1px solid #d2d5d8",
    borderBottom: "1px solid #d2d5d8",
    borderRight: "1px solid #d2d5d8",
    width: 400,
  },
  text: {
    padding: "18.5px 14px",
    font: "inherit",
    backgroundColor: "rgba(0, 0, 0, 0.09)",
  },
  checkbox: {
    margin: "10px 20px",
  },
  th: {
    padding: 0,
    fontFamily: FONT,
    backgroundColor: "#fff",
    color: "#000!important",
    width: 200,
    border: "1px solid #d2d5d8",
    position: "relative",
    paddingLeft: 20,
  },
  formControl: {
    marginTop: "0px!important",
  },
  lengthText: {
    color: "#777",
    fontFamily: FONT,
    fontSize: "0.7rem",
    fontWeight: 400,
    paddingLeft: 7,
  },
  infoText: {
    color: "#777",
    fontFamily: FONT,
    fontSize: "0.7rem",
    fontWeight: 400,
  },
}));

interface Props {
  userEditState: UserEditItemObject;
  setUserEditState: React.Dispatch<React.SetStateAction<UserEditItemObject>>;
}

const SettingUserEditForm: React.FC<Props> = (props: Props) => {
  const checkboxClasses = checkboxStyles({
    backgroundColor: ADMIN_COLOR.primary,
  });
  const classes = useStyles();

  const { userEditState, setUserEditState } = props;

  return (
    <FormControl className={classes.formControl}>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            {/* ユーザーID */}
            <TableRow key="ユーザーID">
              <TableCell align="left" className={classes.th}>
                ユーザーID
              </TableCell>
              <TableCell
                align="left"
                className={clsx(classes.td, classes.text)}
              >
                {userEditState.userName}
              </TableCell>
            </TableRow>
            {/* ユーザー名 */}
            <TableRow key="ユーザー名">
              <TableCell align="left" className={classes.th}>
                ユーザー名
                <span
                  className={classes.lengthText}
                >{`(最大${DISPLAY_NAME_MAX_LENGTH}文字)`}</span>
              </TableCell>
              <TableCell align="left" className={clsx(classes.td)}>
                <CustomTextField
                  type="text"
                  value={userEditState.name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUserEditState({ ...userEditState, name: e.target.value })
                  }
                />
              </TableCell>
            </TableRow>
            {/* ユーザー種別 */}
            {/* JTB社ユーザーでない場合はユーザー種別を非表示 */}
            {!isCardCompanyUser() && (
              <TableRow key="ユーザー種別">
                <TableCell align="left" className={classes.th}>
                  ユーザー種別
                </TableCell>
                <TableCell align="left" className={classes.td}>
                  <CustomSelect
                    width="100%"
                    value={userEditState.userType}
                    pulldownArray={[...JTB_USER_TYPE_NAME_LIST]}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setUserEditState({
                        ...userEditState,
                        userType: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
            )}
            {/* メモ欄 */}
            <TableRow key="メモ欄">
              <TableCell align="left" className={classes.th}>
                メモ欄
                <span
                  className={classes.lengthText}
                >{`(任意入力。最大${MEMO_MAX_LENGTH}文字)`}</span>
                <div className={classes.infoText}>役割など</div>
              </TableCell>
              <TableCell align="left" className={classes.td}>
                <CustomTextField
                  type="text"
                  value={userEditState.memo}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUserEditState({ ...userEditState, memo: e.target.value })
                  }
                />
              </TableCell>
            </TableRow>
            {/* ロック解除 */}
            {/* ロックされているユーザーのみ表示 */}
            {userEditState.isLocked && (
              <TableRow key="ロック解除">
                <TableCell align="left" className={classes.th}>
                  ロック解除
                  <div className={classes.infoText}>
                    ロックされているユーザーのみ表示されます。
                  </div>
                </TableCell>
                <TableCell align="left" className={classes.td}>
                  <FormControlLabel
                    className={clsx(checkboxClasses.checkbox, classes.checkbox)}
                    control={
                      <VariableCustomCheckbox
                        name=""
                        value=""
                        checked={userEditState.releaseLock}
                        onChange={() => {
                          setUserEditState({
                            ...userEditState,
                            releaseLock: !userEditState.releaseLock,
                          });
                        }}
                      />
                    }
                    label=""
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </FormControl>
  );
};

export default SettingUserEditForm;
