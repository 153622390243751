/**
 * JTB側-加盟店管理・検索画面
 * メモ：加盟店管理画面を表示する
 */
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";

import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { CustomerSearchCondition } from "../../types";
import { ICustomerListResponse } from "../../../../../api/types";

import { ADMIN_COLOR } from "../../../../../constants/common";
import { CUSTOMER_TYPE_LIST } from "../../../../../constants/customer";

import { AuthContext } from "../../../../../cognito/AuthContext";
import { getErrorMessage } from "../../../../../utils/error";
import customer from "../../../../../api/customer";

import Loading from "../../../../common/components/molecules/Loading";
import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import CustomerSearchConditionForm from "../organisms/CustomerSearchConditionForm";
import CustomerSearchResult from "../organisms/CustomerSearchResult";

const breadCrumbs = [{ name: "加盟店管理・検索", link: "" }];

const useStyles = makeStyles(() => ({
  form: {
    width: 800,
  },
  searchButton: {
    paddingBottom: 8,
    margin: "0 auto",
    marginTop: 20,
  },
  searchIcon: {
    position: "absolute",
    top: 8,
    left: 50,
  },
  alert: {
    marginTop: "30px",
  },
}));

const CustomerPage: React.FC = () => {
  const { state } = useLocation();

  const classes = useStyles();
  const searchButtonClasses = buttonStyles({
    width: 180,
    marginTop: 15,
    textLeft: 10,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const containerClasses = useContainerStyles();

  const { authHeader } = useContext(AuthContext);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [searchApiErrorMsg, setSearchApiErrorMsg] = useState<string>("");
  const [searchCondition, setSearchCondition] =
    useState<CustomerSearchCondition>({
      // 検索条件が渡ってきていれば設定する
      contractorId: state && state.contractorId ? state.contractorId : "", // 契約者ID（契約者詳細からの時のみ使用）
      companyName: state && state.companyName ? state.companyName : "", // 会社名
      customerCode: state && state.customerCode ? state.customerCode : "", // 加盟店コード
      customerName: state && state.customerName ? state.customerName : "", // 加盟店名
      customerPhoneNum:
        state && state.customerPhoneNum ? state.customerPhoneNum : "", // 加盟店電話番号
      customerType: state && state.customerType ? state.customerType : "", // 加盟店分類
      businessCode: state && state.businessCode ? state.businessCode : "", // 事業者コード
      customerTerminationFlag:
        state && state.customerTerminationFlag
          ? state.customerTerminationFlag
          : false, // 解約を含むか
      pageNumber: state && state.pageNumber ? state.pageNumber : "1", // ページ番号
    });
  const [searchResult, setSearchResult] = useState<ICustomerListResponse>({});

  // 加盟店検索&取得
  const getCustomers = (pageNumber: string) => {
    setLoadingOpen(true);
    customer
      .search(
        authHeader as string,
        searchCondition.contractorId,
        searchCondition.companyName,
        searchCondition.customerCode,
        searchCondition.customerName,
        searchCondition.customerPhoneNum,
        CUSTOMER_TYPE_LIST.get(searchCondition.customerType) || "",
        searchCondition.businessCode,
        String(searchCondition.customerTerminationFlag),
        pageNumber
      )
      .then((res) => {
        // ユーザーリストの取得結果と検索条件をセット
        setSearchResult(res.data as ICustomerListResponse);
        setSearchCondition({ ...searchCondition, pageNumber });
        setSearchApiErrorMsg("");
      })
      .catch((error) => {
        setSearchApiErrorMsg(getErrorMessage(error));
        throw error;
      })
      .finally(() => {
        setSearchCondition({ ...searchCondition, pageNumber });
        setLoadingOpen(false);
      });
  };

  // 遷移時に検索条件が付与されていたら検索した状態にする
  useEffect(() => {
    if (state) {
      getCustomers(searchCondition.pageNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Dashboard
        title="加盟店管理"
        color={ADMIN_COLOR.base}
        backgroundColor={ADMIN_COLOR.primary}
        iconName="customer"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {searchApiErrorMsg === "" && (
            <>
              {/* 加盟店検索フォーム */}
              <form className={classes.form}>
                <CustomerSearchConditionForm
                  searchCondition={searchCondition}
                  setSearchCondition={setSearchCondition}
                />
                <Button
                  className={clsx(
                    searchButtonClasses.button,
                    classes.searchButton
                  )}
                  variant="contained"
                  onClick={() => getCustomers("1")}
                  disabled={loadingOpen} // 二重送信対策
                >
                  <SearchIcon className={classes.searchIcon} />
                  検索
                </Button>
              </form>
              {/* 加盟店検索結果 */}
              <CustomerSearchResult
                resultsRows={searchResult}
                conditionState={searchCondition}
                getCustomers={getCustomers}
              />
            </>
          )}
          {searchApiErrorMsg !== "" && (
            <Alert className={classes.alert} severity="error">
              {searchApiErrorMsg}
            </Alert>
          )}
        </Container>
      </Dashboard>
    </>
  );
};

export default CustomerPage;
