/**
 * 共通-検索結果件数
 */
import React from "react";

import { fontStyles } from "../styles";

interface Props {
  count: number;
}

const SearchCountText: React.FC<Props> = (props: Props) => {
  const { count } = props;

  const fontClasses = fontStyles();

  return <span className={fontClasses.family}>検索結果件数：{count}件</span>;
};

export default SearchCountText;
