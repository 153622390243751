/**
 * 共通-ログイン前画面
 * メモ：JTB側、加盟店側のそれぞれのログイン画面へ遷移するボタンを表示する
 * 現状ローカル環境でのみ利用されている
 */
import React from "react";
import { Link } from "react-router-dom";

import clsx from "clsx";
import { makeStyles, Grid, Button, Typography } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { buttonStyles } from "../../../common/components/styles";

import { ColorBackColorInt } from "../../../common/components/types";

import { ADMIN_COLOR, FONT, GUEST_COLOR } from "../../../../constants/common";

const useStyles = makeStyles(() => ({
  wrapDiv: {
    backgroundColor: "#f1f1f1",
    height: "100%",
  },
  // 開発環境(develop)の場合のみ
  wrapDivImage: {
    background: `url(${window.location.origin}/bgtestForGree.png) repeat`,
    backgroundColor: "#f1f1f1",
    height: "100%",
  },
  gridDiv: {
    paddingTop: 150,
    display: "block",
    margin: "0 auto",
    width: 1602,
  },
  arrowForwardIosButton: {
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 47,
  },
  arrowForwardIosIcon: {
    position: "absolute",
    top: 19,
    left: 261,
  },
  grid: {
    minWidth: 480, // 白枠も固定幅
    maxWidth: 480, // 白枠も固定幅
    padding: "35px!important",
    backgroundColor: "#fff",
  },
  gridSpacing: {
    flexBasis: "15.111111%",
    maxWidth: "15.111111%",
  },
  gridCenterSpacing: {
    flexBasis: "3.111111%",
    maxWidth: "3.111111%",
  },
  formLabel: {
    fontFamily: FONT,
    color: ADMIN_COLOR.primary,
    fontSize: "1.3rem!important",
    textAlign: "center",
  },
}));

const loginButtonStyles = makeStyles(() => ({
  button: (props: ColorBackColorInt) => ({
    margin: "0 auto",
    marginTop: 33,
    marginBottom: 15,
    height: 60,
    backgroundColor: props.backgroundColor,
    color: props.color,
    "& .MuiButton-label": {
      paddingTop: 10,
    },
    "&:hover": {
      backgroundColor: props.backgroundColor,
      color: props.color,
    },
  }),
}));

const TopLoginPage: React.FC = () => {
  const classes = useStyles();
  const guestButtonClasses = loginButtonStyles({
    backgroundColor: GUEST_COLOR.primary,
    color: GUEST_COLOR.base,
  });
  const adminButtonClasses = loginButtonStyles({
    backgroundColor: ADMIN_COLOR.primary,
    color: ADMIN_COLOR.base,
  });
  const buttonClasses = buttonStyles({
    width: 350,
    marginTop: 20,
    backgroundColor: "#000",
  });

  return (
    <div
      className={
        process.env.REACT_APP_ENV === "development"
          ? classes.wrapDivImage
          : classes.wrapDiv
      }
    >
      <div className={classes.gridDiv}>
        <Grid container spacing={3}>
          <Grid item xs={1} className={classes.gridSpacing} />
          {/* 加盟店側ログイン画面への遷移ボタン */}
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>加盟店様</Typography>
            <Button
              component={Link}
              to="/guest/login"
              className={clsx(
                buttonClasses.button,
                guestButtonClasses.button,
                classes.arrowForwardIosButton
              )}
              variant="contained"
            >
              こちらからログインください
              <ArrowForwardIosIcon className={classes.arrowForwardIosIcon} />
            </Button>
          </Grid>
          <Grid item xs={1} className={classes.gridCenterSpacing} />
          {/* JTB側ログイン画面への遷移ボタン */}
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>
              ご担当者様(営業社員の方など)
            </Typography>
            <Button
              component={Link}
              to="/dealer/login"
              className={clsx(
                buttonClasses.button,
                adminButtonClasses.button,
                classes.arrowForwardIosButton
              )}
              variant="contained"
            >
              こちらからログインください
              <ArrowForwardIosIcon className={classes.arrowForwardIosIcon} />
            </Button>
          </Grid>
          <Grid item xs={1} className={classes.gridSpacing} />
        </Grid>
      </div>
    </div>
  );
};

export default TopLoginPage;
