/**
 * フォーム関係-入力用のフォームでのフォームグループのコンポーネント
 */
import React, { useEffect, useState } from "react";

import { Table, TableBody, TableContainer } from "@material-ui/core";

import { formStyles } from "../../../common/components/styles";

import { TRequiredKey } from "../../../common/components/types";

import { ThemeColorProps } from "../../../../constants/common";

import formStore from "../../../../store/formStore";

import TableHeading from "../../../common/components/atoms/TableHeading";
import CustomTableRow from "../atoms/CustomTableRow";
import GridGroup from "./GridGroup";

type Props = {
  formGroupNameId: number;
  requiredKey: TRequiredKey;
  terminalGridId: number | undefined;
  idx: number;
  colors: ThemeColorProps;
};

const FormGroup: React.FC<Props> = (props: Props) => {
  const { formGroupNameId, requiredKey, terminalGridId, idx, colors } = props;

  const [groupData, setGroupData] = useState(
    formStore.getFormGroupData(formGroupNameId)
  );

  const formClasses = formStyles({
    color: colors.base,
    backgroundColor: colors.primary,
  });

  useEffect(() => {
    const fn = () => {
      setGroupData(formStore.getFormGroupData(formGroupNameId));
    };

    formStore.addListener(formGroupNameId.toString(), fn);

    return () => formStore.removeListener(formGroupNameId.toString(), fn);
  }, [formGroupNameId]);

  if (!groupData) return null;

  return (
    <>
      {/* 見出し */}
      <div className={formClasses.tableSpacing}>
        <TableHeading
          colors={{
            color: colors.base,
            backgroundColor: colors.primary,
          }}
          title={groupData.formGroupName}
          key={groupData.formGroupNameId}
          index={idx}
        />
      </div>

      {groupData.gridFlag ? (
        // グリッドの場合
        <GridGroup
          formGroupNameId={formGroupNameId}
          requiredKey={requiredKey}
          colors={colors}
          terminalGridId={terminalGridId}
        />
      ) : (
        <TableContainer className={formClasses.tableContainer}>
          <Table>
            <TableBody>
              {groupData.forms.map((form) => (
                <CustomTableRow
                  key={form.columnName}
                  columnName={form.columnName}
                  requiredKey={requiredKey}
                  formType={form.formType}
                  backgroundColor={colors.primary}
                  terminalGridId={terminalGridId}
                  gridId={undefined}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default FormGroup;
