import axios from "axios";
import {
  createAPIPromise,
  createLoadingPromise,
  loadingUrls,
} from "./utils/common";

// お知らせAPIからJsonを取得
// eslint-disable-next-line
const getInformation: () => Promise<any> = async () => {
  const path = `/information`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);

  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      headers: {
        "Content-Type": "application/json",
      },
      data: {},
    }),
    path
  );
};

export default {
  getInformation,
};
