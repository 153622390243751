/**
 * JTB側-解約申請編集完了画面
 * メモ：端末解約申請、加盟店解約申請の編集完了画面
 */
import React, { useEffect, useMemo, useState } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import MessageIcon from "@material-ui/icons/Message";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";

import { useContainerStyles } from "../../../../templates/styles";
import {
  buttonStyles,
  completeStyles,
} from "../../../../common/components/styles";

import { ADMIN_COLOR } from "../../../../../constants/common";

import formStore from "../../../../../store/formStore";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import DealUserMessageModal from "../../../deal/components/organisms/DealUserMessageModal";

const useStyles = makeStyles(() => ({
  icon: {
    position: "absolute",
    top: 8,
    left: 20,
  },
  buttonBlock: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  messageButton: {
    width: 250,
  },
  returnButton: {
    width: 250,
    marginLeft: 20,
    "& .MuiButton-label": {
      marginLeft: 34,
    },
  },
}));

const TerminateDealEditCompletePage: React.FC<
  RouteComponentProps<{ id: string }>
> = (props: RouteComponentProps<{ id: string }>) => {
  const classes = useStyles();
  const completeClasses = completeStyles();
  const containerClasses = useContainerStyles();
  const buttonClasses = buttonStyles({
    width: "100%",
    marginTop: 0,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const { match } = props;

  // パンくず
  const breadCrumbs = [
    { name: "TOP", link: "/dealer" },
    { name: "完了", link: "" },
  ];

  const dealId = useMemo(() => match.params.id, [match]);

  // 申請種別に応じた情報を取得（編集用）
  const dealTypeInfo = useMemo(
    () => formStore.getTerminateDealInfoByDealType(true),
    []
  );

  const [userMessageOpen, setUserMessageOpen] = useState(false);

  useEffect(() => {
    // 解約申請詳細画面への遷移時にセットした申請種別をリセット
    formStore.setEditDealType(null);
    // その他解約申請編集画面でセットしたものをリセット
    formStore.setEditFormVersion(null);
    formStore.setContractorId(null);
    formStore.setMasterCustomerId(null);
    formStore.setNextWorkflowStatus(10);
  }, []);

  return (
    <Dashboard
      title={`${dealTypeInfo.dealType}申請`}
      color={ADMIN_COLOR.base}
      backgroundColor={ADMIN_COLOR.primary}
      iconName="deal"
    >
      {userMessageOpen && dealId && (
        <DealUserMessageModal
          dealId={dealId ? parseInt(dealId.toString(), 10) : 0}
          setOpen={setUserMessageOpen}
          dealType={dealTypeInfo.dealType}
        />
      )}
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {/* 文言 */}
        <div className={completeClasses.message}>
          編集が完了しました。
          <br />
          <br />
          （申請番号：{dealId}）
        </div>
        <div className={classes.buttonBlock}>
          {/* メッセージ送信ボタン */}
          <Button
            onClick={() => {
              setUserMessageOpen(true);
            }}
            className={clsx(buttonClasses.button, classes.messageButton)}
            variant="contained"
          >
            <MessageIcon className={classes.icon} />
            メッセージを送信する
          </Button>
          {/* ダッシュボードに戻るボタン */}
          <Button
            type="button"
            component={Link}
            to="/dealer"
            className={clsx(buttonClasses.button, classes.returnButton)}
            variant="contained"
          >
            <KeyboardReturnIcon className={classes.icon} />
            ダッシュボードに戻る
          </Button>
        </div>
      </Container>
    </Dashboard>
  );
};

export default withRouter(TerminateDealEditCompletePage);
