/**
 * 共通-グリッドの見出し
 */
import React from "react";

import { Typography, makeStyles } from "@material-ui/core";

import { ADMIN_COLOR, FONT } from "../../../../constants/common";

const useStyles = makeStyles({
  heading: {
    backgroundColor: "#f2f7fb",
    color: "#000",
    fontWeight: "normal",
    fontFamily: FONT,
    width: "100%",
    borderLeft: `10px solid ${ADMIN_COLOR.primary}`,
    textIndent: "1em",
    fontSize: "1.2em",
    paddingTop: 18,
    height: 54,
    paddingBottom: 5,
    marginTop: 10,
    marginBottom: 15,
  },
});

interface Props {
  title: string;
}

const GridHeading: React.FC<Props> = (props: Props) => {
  const classes = useStyles(ADMIN_COLOR);

  const { title } = props;

  return (
    <Typography variant="h3" className={classes.heading}>
      {title}
    </Typography>
  );
};

export default GridHeading;
