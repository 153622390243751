/**
 * カード会社連携ファイル関連の設定値
 */

/* ---------- カード会社 ---------- */
/**
 * カード会社の名称リスト
 */
const CARD_COMPANY_LIST = {
  SMCC: "SMCC",
  MUN: "MUN",
  UC: "UC",
  NSR: "NSR",
  NC: "NC",
  AFS: "AFS",
} as const;

/**
 * 検索用のカード会社マッピング
 * @description 名称: 番号（文字列）
 * @member 指定なし: ""
 * @member SMCC: "10"
 * @member MUN: "20"
 * @member UC: "30"
 * @member NSR: "40"
 * @member NC: "50"
 * @member AFS: "60"
 */
export const CARD_COMPANY_SEARCH_MAP: { [key: string]: string } = {
  指定なし: "",
  [CARD_COMPANY_LIST.SMCC]: "10",
  [CARD_COMPANY_LIST.MUN]: "20",
  [CARD_COMPANY_LIST.UC]: "30",
  [CARD_COMPANY_LIST.NSR]: "40",
  [CARD_COMPANY_LIST.NC]: "50",
  [CARD_COMPANY_LIST.AFS]: "60",
};

/**
 * カード会社名称への変換表
 * @description [番号（文字列）, 名称]
 * @member ["10", "SMCC"]
 * @member ["20", "MUN"]
 * @member ["30", "UC"]
 * @member ["40", "NSR"]
 * @member ["50", "NC"]
 * @member ["60", "AFS"]
 */
export const CARD_COMPANY_CONVERT_MAP = new Map<string, string>([
  ["10", CARD_COMPANY_LIST.SMCC],
  ["20", CARD_COMPANY_LIST.MUN],
  ["30", CARD_COMPANY_LIST.UC],
  ["40", CARD_COMPANY_LIST.NSR],
  ["50", CARD_COMPANY_LIST.NC],
  ["60", CARD_COMPANY_LIST.AFS],
]);

/* ---------- 業務 ---------- */
/**
 * 業務の名称リスト
 */
const DEAL_GROUP_LIST = {
  NEW: "新規",
  CHANGE: "変更",
  CANCEL: "解約",
} as const;

/**
 * 検索用の業務マッピング
 * @description 名称: 番号（文字列）
 * @member 指定なし: ""
 * @member 新規: "10"
 * @member 変更: "20"
 * @member 解約: "30"
 */
export const DEAL_GROUP_SEARCH_MAP: { [key: string]: string } = {
  指定なし: "",
  [DEAL_GROUP_LIST.NEW]: "10",
  [DEAL_GROUP_LIST.CHANGE]: "20",
  [DEAL_GROUP_LIST.CANCEL]: "30",
};

/**
 * 業務名称への変換表
 * @description [番号（文字列）, 名称]
 * @member ["10", "新規"]
 * @member ["20", "変更"]
 * @member ["30", "解約"]
 */
export const DEAL_GROUP_CONVERT_MAP = new Map<string, string>([
  ["10", DEAL_GROUP_LIST.NEW],
  ["20", DEAL_GROUP_LIST.CHANGE],
  ["30", DEAL_GROUP_LIST.CANCEL],
]);

/* ---------- 加盟店種別 ---------- */
/**
 * 加盟店種別の名称リスト
 */
const CUSTOMER_GROUP_LIST = {
  TRAVEL_UNION: "旅連",
  JTB_BRANCH: "JTB支店",
  COMPREHENSIVE_COOPERATION: "総合提携",
  PARTNER: "パートナー",
  STAMP: "スタンプ",
} as const;

/**
 * 検索用の加盟店種別マッピング
 * @description 名称: 番号（文字列）
 * @member 指定なし: ""
 * @member 旅連: "10"
 * @member JTB支店: "20"
 * @member 総合提携: "30"
 * @member パートナー: "40"
 * @member スタンプ: "50"
 */
export const CUSTOMER_GROUP_SEARCH_MAP: { [key: string]: string } = {
  指定なし: "",
  [CUSTOMER_GROUP_LIST.TRAVEL_UNION]: "10",
  [CUSTOMER_GROUP_LIST.JTB_BRANCH]: "20",
  [CUSTOMER_GROUP_LIST.COMPREHENSIVE_COOPERATION]: "30",
  [CUSTOMER_GROUP_LIST.PARTNER]: "40",
  [CUSTOMER_GROUP_LIST.STAMP]: "50",
};

/**
 * 加盟店種別名称への変換表
 * @description [番号（文字列）, 名称]
 * @member ["10", "旅連"]
 * @member ["20", "JTB支店"]
 * @member ["30", "総合提携"]
 * @member ["40", "パートナー"]
 * @member ["50", "スタンプ"]
 */
export const CUSTOMER_GROUP_CONVERT_MAP = new Map<string, string>([
  ["10", CUSTOMER_GROUP_LIST.TRAVEL_UNION],
  ["20", CUSTOMER_GROUP_LIST.JTB_BRANCH],
  ["30", CUSTOMER_GROUP_LIST.COMPREHENSIVE_COOPERATION],
  ["40", CUSTOMER_GROUP_LIST.PARTNER],
  ["50", CUSTOMER_GROUP_LIST.STAMP],
]);

/* ---------- 連携日 ---------- */
export const ALIGNMENT_DATE_PLACEHOLDER = "YYYY-MM-DD";
export const ALIGNMENT_DATE_CHAR_TYPE =
  "^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$";
