/**
 * フォーム関係-JSONのフォーム用のラジオボタン
 */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import { radioStyles } from "../../../common/components/styles";

import {
  TFormColumn,
  TPulldownArray,
  TRequiredKey,
} from "../../../common/components/types";

import { FONT } from "../../../../constants/common";

import formStore from "../../../../store/formStore";
import { jsonParseSafety } from "../../../../utils/formData";

import FormRemarks from "./FormRemarks";
import ValidationMsg from "./ValidateMsg";

const useStyles = makeStyles(() => ({
  url: {
    fontFamily: FONT,
  },
  error: {
    marginLeft: 0,
  },
}));

type Props = {
  columnName: TFormColumn;
  requiredKey: TRequiredKey;
  backgroundColor: string;
  terminalGridId: number | undefined;
  gridId: number | undefined;
  isChangeDeal?: boolean;
};

const CustomRadioGroup: React.FC<Props> = (props: Props) => {
  const {
    columnName,
    requiredKey,
    backgroundColor,
    terminalGridId,
    gridId,
    isChangeDeal,
  } = props;

  const [row, setRow] = useState(
    formStore.getFormRow(columnName, terminalGridId, gridId)
  );
  const contentForView = useMemo(
    () => jsonParseSafety(row?.content || ""),
    [row]
  );

  const checked = useRef("");

  const radioArray = useMemo(
    () =>
      row?.json.formTypeOptions.map((o) => {
        const key = Object.keys(o)[0].toString();
        const value = Object.values(o)[0].toString();
        const obj: TPulldownArray = {};
        obj[key] = value;
        return obj;
      }) || [],
    [row]
  );

  const disabled = useMemo(
    () => !row?.json.updateEnabled[requiredKey] || row.disabled,
    [row, requiredKey]
  );

  const radioClasses = radioStyles({ backgroundColor });
  const classes = useStyles();

  useEffect(() => {
    const fn = () => {
      const r = formStore.getFormRow(columnName, terminalGridId, gridId);
      setRow(r && { ...r });
    };
    formStore.addListener(columnName, fn);

    return () => formStore.removeListener(columnName, fn);
  }, [columnName, terminalGridId, gridId]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const target = event.target as HTMLInputElement;
      const targetValue = target.value as string;
      if (targetValue === checked.current) {
        checked.current = "";
        formStore.updateContent(
          columnName,
          "",
          requiredKey,
          terminalGridId,
          gridId
        );
      } else {
        checked.current = targetValue;
        const selected = radioArray.find((p) =>
          Object.keys(p).includes(targetValue)
        );
        const data = selected ? JSON.stringify(selected) : "";
        formStore.updateContent(
          columnName,
          data,
          requiredKey,
          terminalGridId,
          gridId
        );
      }
    },
    [columnName, requiredKey, radioArray, terminalGridId, gridId]
  );

  // rowがなければ表示しない
  if (!row) return null;

  return (
    row && (
      <div>
        {row.json.fixedValue !== null ? (
          // 値がなければ単位等も表示しない
          <>
            {contentForView && typeof contentForView === "object"
              ? Object.values(contentForView)[0]
              : contentForView || ""}
          </>
        ) : (
          <>
            <FormControl variant="filled" error={row.errors.length > 0}>
              {/* ラジオボタン */}
              <RadioGroup row>
                {radioArray.map((radio) => (
                  <FormControlLabel
                    key={Object.values(radio)[0] as string}
                    className={
                      row.errors.length !== 0
                        ? radioClasses.errorRadio
                        : radioClasses.radio
                    }
                    value={Object.keys(radio)[0]}
                    control={
                      <Radio
                        onClick={handleClick}
                        checked={
                          row.content !== "" &&
                          JSON.stringify(radio) === row.content
                        }
                        disabled={disabled}
                      />
                    }
                    label={Object.values(radio)[0] as string}
                  />
                ))}
              </RadioGroup>
              {/* 項目の説明 */}
              <FormRemarks
                remarks={
                  isChangeDeal
                    ? row.json.changeFormRemarks
                    : row.json.formRemarks
                }
              />
              {/* 項目の説明（URL） */}
              <a
                className={classes.url}
                href={row.json.url}
                target="_blank"
                rel="noopener noreferrer"
                tabIndex={row.json.url ? 0 : -1}
              >
                {row.json.url}
              </a>
              {/* バリデーションエラーメッセージ */}
              {row.errors.length > 0 && (
                <FormHelperText className={classes.error}>
                  <ValidationMsg errors={row.errors} />
                </FormHelperText>
              )}
            </FormControl>
          </>
        )}
      </div>
    )
  );
};

export default CustomRadioGroup;
