/**
 * 加盟店側-端末管理
 * メモ：端末管理系画面の親コンポーネント
 */
import React from "react";
import { Route, Switch } from "react-router-dom";

import TerminalPage from "./pages/TerminalPage";
import TerminalShowPage from "./pages/TerminalShowPage";

const TerminalHooks: React.FC = () => {
  return (
    <>
      <Switch>
        {/* 端末管理・検索 */}
        <Route path="/guest/terminal" render={() => <TerminalPage />} exact />
        {/* 端末詳細 */}
        <Route
          path="/guest/terminal/:id"
          render={() => <TerminalShowPage />}
          exact
        />
      </Switch>
    </>
  );
};

export default TerminalHooks;
