/**
 * 共通-ファイル表示
 * メモ：JSONのformTypeがfileのもの
 */
import React, { useContext, useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core";

import { imageStyle } from "../styles";

import { PDF_TYPE } from "../../../../constants/deal";
import { FONT } from "../../../../constants/common";

import file from "../../../../api/file";
import { convBase64toBlob, downloadBlobFile } from "../../../../utils/file";
import { AuthContext } from "../../../../cognito/AuthContext";

import pdf from "../../../assets/img/common/pdf.png";

const useStyles = makeStyles(() => ({
  url: {
    fontFamily: FONT,
    marginLeft: 138,
  },
}));

interface Props {
  formGroupName: string;
  formContent: string;
  index?: number; // 主にGridの場合に使用する。
}

const ShowFile: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const imageClasses = imageStyle();

  const { formGroupName, formContent, index } = props;

  const { authHeader } = useContext(AuthContext);
  // formContentがanyで渡されているのでクラッシュ対策
  const [fileName, setFileName] = useState(formContent.toString());

  useEffect(() => {
    const func = async () => {
      // ファイルダウンロードAPI
      const res = await file.download(authHeader || "", fileName);
      setFileName(res.data.result.file);
    };
    // base64データを取得していなければAPI呼ぶ
    if (fileName !== "" && fileName !== "-" && !fileName.startsWith("data:")) {
      func();
    }
  }, [authHeader, fileName]);

  const handlePdfDownload = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    // base64 ⇨ pdf
    const blobPdf = convBase64toBlob(
      e.currentTarget.dataset.base64 as string,
      PDF_TYPE
    );
    // download
    downloadBlobFile(blobPdf, `${e.currentTarget.dataset.name as string}.pdf`);
  };

  return (
    <>
      {fileName && fileName.startsWith("data:image") && (
        <>
          <img
            className={imageClasses.thumbnail}
            alt={`${formGroupName}${(index as number) === 0 ? "" : index}`}
            src={fileName}
          />
        </>
      )}
      {fileName && fileName.startsWith("data:application/pdf") && (
        <>
          <img
            className={imageClasses.thumbnail}
            alt={formGroupName}
            src={pdf}
          />
          <br />
          <a
            className={classes.url}
            href="/#"
            target="_blank"
            rel="noopener noreferrer"
            data-base64={fileName}
            data-name={`${formGroupName}${
              (index as number) === 0 ? "" : index
            }`}
            onClick={handlePdfDownload}
          >
            {`${formGroupName}${(index as number) === 0 ? "" : index}`}
          </a>
        </>
      )}
      {/* TODO 読み込み中の表示の調整 */}
      {fileName !== "" && fileName !== "-" && !fileName.startsWith("data:") && (
        <div>読み込み中</div>
      )}
      {fileName === "-" && <div>{fileName}</div>}
    </>
  );
};

ShowFile.defaultProps = {
  index: 0,
};

export default React.memo(ShowFile);
