/**
 * JTB側-精算明細書詳細画面
 * メモ：対象の加盟店の直近3ヶ月の精算明細書を表示する。精算明細書・データファイルのダウンロードも可能
 */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RouteComponentProps, withRouter, useLocation } from "react-router-dom";

import { Container, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useContainerStyles } from "../../../../templates/styles";

import { IBillShowResponse } from "../../../../../api/types";

import { ADMIN_COLOR } from "../../../../../constants/common";

import bill from "../../../../../api/bill";
import { AuthContext } from "../../../../../cognito/AuthContext";
import { getErrorMessage } from "../../../../../utils/error";

import Loading from "../../../../common/components/molecules/Loading";
import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import BillShowForm from "../organisms/BillShowForm";
import BillShowTable from "../organisms/BillShowTable";

const breadCrumbs = [
  { name: "帳票・データ出力", link: "/dealer/data" },
  { name: "精算明細書検索・一覧", link: "/dealer/data/bill" },
  { name: "精算明細書詳細", link: "" },
];

const useStyles = makeStyles(() => ({
  alert: {
    marginTop: "30px",
  },
  form: {
    width: 800,
  },
}));

const DataBillShowPage: React.FC<
  RouteComponentProps<{ masterCustomerId: string }>
> = (props: RouteComponentProps<{ masterCustomerId: string }>) => {
  const classes = useStyles();
  const containerClasses = useContainerStyles();

  const { state } = useLocation(); // 加盟店コードを受け取る
  const { match } = props;

  const { authHeader } = useContext(AuthContext);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>("");
  const [showResult, setShowResult] = useState<IBillShowResponse>({});

  // 精算明細書情報の取得処理
  const getData = useCallback(async () => {
    if (!authHeader) return;
    setLoadingOpen(true);

    bill
      .show(authHeader, match.params.masterCustomerId)
      .then((res) => {
        // 精算明細書の取得結果をセット
        setShowResult(res.data);
        setApiErrorMsg("");
      })
      .catch((error) => {
        setApiErrorMsg(getErrorMessage(error));
        throw error;
      })
      .finally(() => {
        setLoadingOpen(false);
      });
  }, [authHeader, match.params.masterCustomerId]);

  // データ取得
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Dashboard
        title="帳票・データ出力"
        color={ADMIN_COLOR.base}
        backgroundColor={ADMIN_COLOR.primary}
        iconName="data"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {apiErrorMsg !== "" && (
            <Alert className={classes.alert} severity="error">
              {apiErrorMsg}
            </Alert>
          )}
          {/* 加盟店コード表示 */}
          <form className={classes.form}>
            <BillShowForm customerCode={state} />
          </form>
          {apiErrorMsg === "" && (
            <>
              {/* 精算明細書 */}
              {showResult && (
                <BillShowTable
                  resultsRows={showResult}
                  setApiErrorMsg={setApiErrorMsg}
                  setLoadingOpen={setLoadingOpen}
                  getData={getData}
                />
              )}
            </>
          )}
        </Container>
      </Dashboard>
    </>
  );
};

export default withRouter(DataBillShowPage);
