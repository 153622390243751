/**
 * フォーム関係-入力用のフォームでの項目の行
 */
import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  TableCell,
  TableRow,
  TableRowProps,
  makeStyles,
} from "@material-ui/core";

import {
  TFormColumn,
  TFormType,
  TRequiredKey,
} from "../../../common/components/types";

import { FONT } from "../../../../constants/common";

import formStore from "../../../../store/formStore";
import useObserver from "../../../helpers/form/useObserver";

import CustomCheckbox from "./CustomCheckbox";
import CustomDatePicker from "./CustomDatePicker";
import CustomFileUploader from "./CustomFileUploader";
import CustomRadioGroup from "./CustomRadioGroup";
import CustomSearchSelect from "./CustomSearchSelect";
import CustomSelect from "./CustomSelect";
import CustomTextArea from "./CustomTextArea";
import CustomTextField from "./CustomTextField";
import FormTitle from "./FormTitle";

const useStyles = makeStyles(() => ({
  th: {
    fontFamily: FONT,
    fontWeight: 900,
    backgroundColor: "#fff",
    color: "#000!important",
    width: 263,
    borderBottom: "2px dotted #ddd",
    position: "relative",
  },
  td: {
    backgroundColor: "#fff",
    color: "#000!important",
    borderBottom: "2px dotted #ddd",
  },
  thMessage: {
    width: 160,
  },
}));

type Props = TableRowProps & {
  columnName: TFormColumn;
  requiredKey: TRequiredKey;
  formType: TFormType;
  backgroundColor: string;
  terminalGridId: number | undefined;
  gridId: number | undefined;
};

const CustomTableRow: React.FC<Props> = (props: Props) => {
  const {
    columnName,
    requiredKey,
    formType,
    backgroundColor,
    terminalGridId,
    gridId,
    className,
  } = props;

  const [init, setInit] = useState(false);
  const [isHide, setHide] = useState(
    formStore.isHideRow(columnName, requiredKey, terminalGridId)
  );
  const hasError = useMemo(
    () => formStore.focusError(columnName, terminalGridId, gridId),
    [columnName, terminalGridId, gridId]
  );
  const updateEnable = useMemo(
    () => formStore.isUpdateEnableRow(columnName, requiredKey),
    [columnName, requiredKey]
  );
  const target = useRef<HTMLTableRowElement>(null);
  // 描画境界判定
  const intersect = useObserver(target, {
    root: document.getElementById("scroll-parent"), // TabPanel
    rootMargin: "900px 0px 900px 0px", // 上下900px広く判定
  });

  // 非活性項目の表示境界判定
  const intersectEnable = useObserver(target, {
    root: document.getElementById("scroll-parent"), // TabPanel
    rootMargin: "300px 0px 300px 0px", // 上下300px広く判定
  });

  const classes = useStyles();

  useEffect(() => {
    const fn = () => {
      setHide(formStore.isHideRow(columnName, requiredKey, terminalGridId));
    };
    formStore.addListener(columnName, fn);

    return () => formStore.removeListener(columnName, fn);
  }, [columnName, requiredKey, terminalGridId, gridId]);

  useEffect(() => {
    if (init && target.current && hasError) {
      target.current.scrollIntoView();
    }

    // eslint-disable-next-line
  }, [init, hasError]);

  const child = useMemo(() => {
    switch (formType) {
      case "text":
      case "number": {
        return (
          <CustomTextField
            columnName={columnName}
            requiredKey={requiredKey}
            terminalGridId={terminalGridId}
            gridId={gridId}
          />
        );
      }
      case "date": {
        return (
          <CustomDatePicker
            columnName={columnName}
            requiredKey={requiredKey}
            terminalGridId={terminalGridId}
            gridId={gridId}
          />
        );
      }
      case "textArea": {
        return (
          <CustomTextArea
            columnName={columnName}
            requiredKey={requiredKey}
            terminalGridId={terminalGridId}
            gridId={gridId}
          />
        );
      }
      case "select": {
        return (
          <CustomSelect
            columnName={columnName}
            requiredKey={requiredKey}
            terminalGridId={terminalGridId}
            gridId={gridId}
          />
        );
      }
      case "search-select": {
        return (
          <CustomSearchSelect
            columnName={columnName}
            requiredKey={requiredKey}
            terminalGridId={terminalGridId}
            gridId={gridId}
          />
        );
      }
      case "radio": {
        return (
          <CustomRadioGroup
            columnName={columnName}
            requiredKey={requiredKey}
            backgroundColor={backgroundColor}
            terminalGridId={terminalGridId}
            gridId={gridId}
          />
        );
      }
      case "checkbox": {
        return (
          <CustomCheckbox
            columnName={columnName}
            requiredKey={requiredKey}
            backgroundColor={backgroundColor}
            terminalGridId={terminalGridId}
            gridId={gridId}
          />
        );
      }
      case "file": {
        return (
          <CustomFileUploader
            columnName={columnName}
            requiredKey={requiredKey}
            terminalGridId={terminalGridId}
            gridId={gridId}
          />
        );
      }
      default:
        return null;
    }
  }, [
    formType,
    columnName,
    requiredKey,
    backgroundColor,
    terminalGridId,
    gridId,
  ]);

  useEffect(() => {
    // タブ切り替え時に"scroll-parent"がDOMについたあとuseObserverするため
    setInit(true);
  }, []);

  if (isHide) return null;

  return init ? (
    <TableRow
      className={className}
      key={columnName}
      innerRef={target}
      style={{
        height: intersect
          ? "auto"
          : "75px" /* 描画範囲外の場合は仮で高さを設定しておく */,
        /*
         * Tabボタン移動対応
         * 非活性項目が範囲外の場合に非表示にする
         * DOMツリー上には残すようにvisibilityで制御（位置は詰めるためcollapse指定）
         */
        visibility: intersectEnable || updateEnable ? "visible" : "collapse",
      }}
    >
      {/* 項目の名称 */}
      <TableCell align="left" className={classes.th}>
        <FormTitle columnName={columnName} requiredKey={requiredKey} />
      </TableCell>
      {/* 項目の入力欄 */}
      <TableCell align="left" className={classes.td}>
        {intersect ? child : null}
      </TableCell>
    </TableRow>
  ) : null;
};

export default CustomTableRow;
