/**
 * JTB側-マスタ管理画面
 */
import React, { useMemo } from "react";
import { withRouter, Link } from "react-router-dom";

import clsx from "clsx";
import { Container, makeStyles, Button } from "@material-ui/core";

import useContainerStyles from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { ADMIN_COLOR } from "../../../../../constants/common";
import { METABASE_TYPE } from "../../../../../constants/metabase";

import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import Dashboard from "../../../../templates/Dashboard";

// スタイル設定
const useStyles = makeStyles(() => ({
  linkButton: {
    margin: "0 auto",
    marginTop: 40, // 再設定しないと効かないので
    textAlign: "center",
    paddingBottom: 10,
  },
}));

// 表示するMasterの設定
type masterTypeNum = "2" | "3" | "4" | "5" | "6" | "7";
const masterTypeList = {
  [METABASE_TYPE.BUSINESS_PERSON_MASTER]: "一括事業者管理マスタ",
  [METABASE_TYPE.CUSTOMER_TYPE_MASTER]: "加盟店分類マスタ",
  [METABASE_TYPE.CREDIT_RATE_MASTER]: "対加盟店クレジット手数料率管理マスタ",
  [METABASE_TYPE.SETTLEMENT_PTN_MASTER]: "決済機能利用パターンマスタ",
  [METABASE_TYPE.REDUCTION_ADD_MASTER]: "還元データ付加情報マスタ",
  [METABASE_TYPE.CARD_CUSTOMER_NUM]: "カード会社事業者加盟店番号マスタ",
};

const MasterPage: React.FC = () => {
  const classes = useStyles();
  const containerClasses = useContainerStyles();
  const buttonClasses = buttonStyles({
    width: 300,
    marginTop: 40,
    backgroundColor: ADMIN_COLOR.primary,
  });

  // パンくずリスト
  const breadCrumbs = useMemo(() => [{ name: "マスタ管理", link: "" }], []);

  return (
    <Dashboard
      title="マスタ管理"
      color={ADMIN_COLOR.base}
      backgroundColor={ADMIN_COLOR.primary}
      iconName="master"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        <>
          {/* ボタン */}
          {Object.keys(masterTypeList).map((type) => (
            <Button
              className={clsx(buttonClasses.button, classes.linkButton)}
              type="button"
              variant="contained"
              component={Link}
              key={type}
              to={{
                pathname: `/dealer/master/info`,
                state: {
                  type,
                  name: masterTypeList[type as masterTypeNum],
                },
              }}
            >
              {masterTypeList[type as masterTypeNum]}
            </Button>
          ))}
        </>
      </Container>
    </Dashboard>
  );
};

export default withRouter(MasterPage);
