import { jsonParseSafety } from "../../utils/formData";
import {
  formTypeOptions,
  TFormRowData,
} from "../../views/common/components/types";
import { TFixedNumberProcessResult } from "./types";

/**
 * 加盟店地区マッピングJson
 */
type TDistrictDataJson = {
  [prefecture: string]: string;
};

/**
 * 都道府県-加盟店地区データマップ
 */
const PREFECTURE_DISTRICT_DATA_MAP = new Map<
  string,
  { code: string; district: string }
>();

/**
 * 都道府県-加盟店地区データマップセット
 * @param json: 加盟店地区マッピングJson
 */
export const setDistrictDataMap = (json: TDistrictDataJson) => {
  const keys = Object.keys(json);
  keys.forEach((k) => {
    const opt: formTypeOptions = {};
    opt[json[k]] = json[k];
    PREFECTURE_DISTRICT_DATA_MAP.set(k, {
      code: JSON.stringify(opt),
      district: k,
    });
    // 都道府県を排除したバージョンも保存
    PREFECTURE_DISTRICT_DATA_MAP.set(k.slice(0, -2), {
      code: JSON.stringify(opt),
      district: k,
    });
  });
};

/**
 * 加盟店都道府県での加盟店地区データ自動入力
 * @param data: TFormRowData 加盟店情報フォーム
 * @returns TFixedNumberProcessResult
 */
export const autoInputByCustomerPrefecture: (
  data: TFormRowData
) => TFixedNumberProcessResult = (data: TFormRowData) => {
  // 加盟店都道府県項目取得
  const customerPrefecture = data.customerPrefecture
    ? data.customerPrefecture[0]
    : null;

  // 都道府県から地区データ取得
  const dataMap = PREFECTURE_DISTRICT_DATA_MAP.get(
    customerPrefecture?.content || ""
  );
  if (dataMap) {
    // データ取得できたら自動入力
    return [
      {
        column: "customerDistrictCode",
        content: jsonParseSafety(dataMap.code),
        formTypeOptions: [jsonParseSafety(dataMap.code)],
      },
      {
        column: "installationAreaName",
        content: dataMap.district,
      },
    ];
  }

  // データ取得できなかったら空入力
  return [
    {
      column: "customerDistrictCode",
      content: "",
    },
    {
      column: "installationAreaName",
      content: "",
    },
  ];
};
