import axios from "axios";
import {
  createAPIPromiseWithoutAuth,
  createLoadingPromise,
  loadingUrls,
} from "./utils/common";

const getAddress: (
  postcode: string,
  authHeader: string
  // eslint-disable-next-line
) => Promise<any> = async (postcode: string, authHeader: string) => {
  const path = `/proxy/postcodes/${postcode}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromiseWithoutAuth(
    axios.get<string>(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
    }),
    path
  );
};

export default { getAddress };
