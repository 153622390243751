import axios from "axios";
import {
  createAPIPromise,
  createLoadingPromise,
  loadingUrls,
} from "./utils/common";

// マニュアル取得API
const get: (
  authHeader: string,
  manualType: number
  // eslint-disable-next-line
) => Promise<any> = async (authHeader: string, manualType: number) => {
  // documentTypeがMANUAL:10 のみのため直書き
  const path = `/documents/10`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      params: {
        manualType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    path
  );
};

// マニュアル一覧取得API
// eslint-disable-next-line
const getList: (authHeader: string) => Promise<any> = async (
  authHeader: string
) => {
  const path = `/documents`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    path
  );
};

export default { get, getList };
