/**
 * フォーム関係-解約申請確認用-formName（項目の名称）を表示するコンポーネント
 */
import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core";

import { TRequiredKey, TFormObject } from "../../../common/components/types";

import BreakElementForLineText from "../../../common/components/atoms/BreakElementForLineText";
import RequiredChip from "../../../common/components/atoms/RequiredChip";

const useStyles = makeStyles(() => ({
  thMessage: {
    width: 160,
  },
}));

type Props = {
  requiredKey: TRequiredKey;
  terminateFormRowObj: TFormObject;
};

const FormTitleForTerminateDeal: React.FC<Props> = (props: Props) => {
  const { requiredKey, terminateFormRowObj } = props;

  const [row, setRow] = useState(terminateFormRowObj);
  const classes = useStyles();

  useEffect(() => {
    setRow(terminateFormRowObj);
  }, [terminateFormRowObj]);

  return row ? (
    <>
      {/* 項目の名称 */}
      <div className={classes.thMessage}>
        <BreakElementForLineText text={row.json.formName} />
      </div>
      {/* 必須ラベル */}
      {row.json.required[requiredKey] && row.json.fixedValue === null && (
        <RequiredChip left={183} top="37%" />
      )}
    </>
  ) : null;
};

export default FormTitleForTerminateDeal;
