/**
 * JTB側-連携ファイルアップロード用ダイアログ
 * メモ：連携ファイル（エクセル）をアップロードするダイアログを表示する
 */
import React from "react";
import { useDropzone } from "react-dropzone";

import clsx from "clsx";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";

import { buttonStyles } from "../../../../common/components/styles";

import {
  ACCEPT_EXTENSIONS,
  EXCEL_EXTENSIONS,
} from "../../../../../constants/excel";
import { ADMIN_COLOR, FONT } from "../../../../../constants/common";

import getBase64, { getExtension } from "../../../../../utils/file";
import cardCompanyFile from "../../../../../api/cardCompanyFile";
import { AuthContext } from "../../../../../cognito/AuthContext";
import { getErrorMessage } from "../../../../../utils/error";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: 200,
    border: "2px dashed #494949",
    padding: 20,
    textAlign: "center",
    color: "#666",
    fontFamily: FONT,
    justifyContent: "center",
    alignItems: "center",
  },
  excelUploader: {
    marginTop: 20,
  },
  error: {
    marginLeft: 0,
    color: "#f44336",
    fontSize: "0.75rem",
  },
  dialogTitle: {
    color: ADMIN_COLOR.primary,
    "& .MuiTypography-h6": {
      fontSize: "1.0em",

      fontFamily: FONT,
      textIndent: "1.5em",
    },
  },
  dialogContentText: {
    fontFamily: FONT,
    color: "#000",
  },
  uploadIcon: {
    position: "absolute",
    top: 17,
    left: 20,
    fontSize: "1.3rem",
  },
  cancelButton: {
    paddingBottom: 8,
  },
  uploadButton: {
    paddingBottom: 8,
  },
  dialogActions: {
    height: 59,
    margin: "0 auto",
  },
}));

interface Props {
  open: boolean;
  title: string;
  message: string;
  onUpload: (file: string, fileName: string) => void;
  onCancel: () => void;
  loadingOpen: boolean;
  setLoadingOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CardExcelUploadDialog: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const buttonClasses = buttonStyles({
    width: 120,
    marginTop: 0,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const {
    open,
    title,
    message,
    onUpload,
    onCancel,
    loadingOpen,
    setLoadingOpen,
  } = props;

  const { authHeader } = React.useContext(AuthContext);
  const [excel, setExcel] = React.useState<string>("");
  const [fileName, setFileName] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState<string>("");
  const [exist, setExist] = React.useState<boolean>(false);

  const clearData = () => {
    setExcel("");
    setFileName("");
    setErrorMsg("");
    setExist(false);
  };

  // アップロードボタンを押した際の処理
  const handleUpload = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if (!excel) {
      setErrorMsg("ファイルを選択してください。");
      return;
    }

    onUpload(excel, fileName);
    clearData();
  };

  // キャンセルボタンを押した際の処理
  const handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    onCancel();
    clearData();
  };

  // ファイルをドロップや選択した際の処理
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length <= 0) {
        return;
      }
      clearData();

      // 拡張子がExcelでなかった場合
      if (!EXCEL_EXTENSIONS.includes(getExtension(acceptedFiles[0].name))) {
        setErrorMsg("Excel形式のファイルをアップロードしてください。");
        return;
      }
      // 拡張子がxls,xlsmの場合（マクロが含まれる可能性があるので不許可）
      if (!ACCEPT_EXTENSIONS.includes(getExtension(acceptedFiles[0].name))) {
        setErrorMsg(
          "セキュリティリスクがあるため、ファイル形式をxlsx形式に変更してください。"
        );
        return;
      }

      getBase64(acceptedFiles[0])
        .then((value) => {
          const base64 = value as string;
          setExcel(base64);
          setFileName(acceptedFiles[0].name);
          setErrorMsg("");
        })
        .catch(() => {
          setErrorMsg("ファイルの読み込みに失敗しました。");
        });

      setLoadingOpen(true);
      // ファイル存在確認
      cardCompanyFile
        .confirmExistence(authHeader as string, acceptedFiles[0].name)
        .then((res) => {
          setExist(res.data.result.fileExistence);
        })
        .catch((error) => {
          setErrorMsg(getErrorMessage(error));
        })
        .finally(() => {
          setLoadingOpen(false);
        });
    },
    [authHeader, setLoadingOpen]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle className={classes.dialogTitle}>
        <PublishIcon className={classes.uploadIcon} />
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogContentText}>
          {message}
          {/* 選択したファイル名 */}
          {fileName && `選択中ファイル: ${fileName}`}
        </DialogContentText>
        {/* ファイルドロップエリア */}
        <FormControl variant="filled" style={{ display: "block" }}>
          {/* eslint-disable */}
          {/* TODO：lint無効を解除したいが調べる時間がないので一旦このままとする。 */}
          <div {...getRootProps()} className={classes.excelUploader}>
            <input {...getInputProps()} />
            <div className={classes.container}>
              stera進捗管理表をドラッグ&ドロップ または
              <br />
              クリックしてアップロード
              {/* エラー文言 */}
              {errorMsg && (
                <FormHelperText className={classes.error}>
                  {errorMsg}
                </FormHelperText>
              )}
            </div>
          </div>
          {/* eslint-enable */}
          {/* ファイル上書き注意文言 */}
          {exist && (
            <FormHelperText className={classes.error}>
              {`${fileName}はアップロード済みです。`}
              <br />
              アップロードすると上書きされます。
            </FormHelperText>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {/* キャンセルボタン */}
        <Button
          className={clsx(buttonClasses.button, classes.cancelButton)}
          onClick={handleCancel}
          variant="contained"
        >
          キャンセル
        </Button>
        {/* アップロードボタン */}
        <Button
          className={clsx(buttonClasses.button, classes.uploadButton)}
          onClick={handleUpload}
          variant="contained"
          disabled={!fileName || loadingOpen}
        >
          アップロード
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CardExcelUploadDialog;
