/**
 * 共通-ワークフローステータス表示欄
 * メモ：新規申請、変更申請、解約申請それぞれの詳細画面で本コンポーネントが利用されている
 */
import React, { useCallback, useContext, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import clsx from "clsx";
import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import ClearIcon from "@material-ui/icons/Clear";

import { workflowButtonStyles } from "../styles";

import { TDealTypeStr } from "../types";

import { FONT, ThemeColorProps } from "../../../../constants/common";
import {
  TWorkflowStatus,
  WORKFLOW_STATUS_IN_CUSTOMER,
  WORKFLOW_STATUS_IN_DEALER,
  WORKFLOW_STATUS_IN_DEALER_COMMON,
  WORKFLOW_STATUS_IN_DEALER_MANAGER,
  INPUT_CONTRACTOR_STATUSES,
  CANCEL_STATUSES_COMMON,
} from "../../../../constants/workflowStatus";
import { DEAL_TYPE_STR_TO_NUM } from "../../../../constants/deal";

import { GlobalPopupContext } from "../../../../App";
import { AuthContext } from "../../../../cognito/AuthContext";
import deal from "../../../../api/deal";
import changeDeal from "../../../../api/changeDeal";
import terminateDeal from "../../../../api/terminateDeal";
import {
  checkAuth,
  isJtbSysADUser,
  isJtbStaffUser,
  isJtbManagerUser,
} from "../../../../utils/auth";

import DealWorkflowStatus from "../molecules/DealWorkflowStatus";
import DealWorkflowButtons from "../molecules/DealWorkflowButtons";
import DealUserMessageModal from "../../../admin/deal/components/organisms/DealUserMessageModal";
import GuestUserMessageModal from "../../../guest/deal/components/organisms/DealUserMessageModal";

const useStyles = makeStyles(() => ({
  box: (props: { colors: ThemeColorProps }) => ({
    border: `3px solid ${props.colors.primaryDark}`,
    paddingBottom: 13,
    zIndex: 1,
    borderRadius: 0,
  }),
  innerBox: (props: { colors: ThemeColorProps }) => ({
    border: `3px solid ${props.colors.primaryDark}`,
    paddingBottom: 13,
    paddingLeft: 4,
    marginTop: -32,
    width: "91%",
    margin: "0 auto",
    zIndex: 1,
  }),
  h2: (props: { colors: ThemeColorProps }) => ({
    fontSize: "1.2em",
    color: props.colors.primaryDark,
    border: `3px solid ${props.colors.primaryDark}`,
    paddingTop: 9,
    paddingLeft: 35,
    fontWeight: "bold",
    height: 50,
    fontFamily: FONT,
    backgroundColor: "#fff",
    zIndex: 2,
  }),
  h2WithWorkflowEdit: (props: { colors: ThemeColorProps }) => ({
    borderColor: props.colors.primaryDark,
    borderBottom: 0,
  }),
  cancelButton: {
    fontSize: "0.8125rem",
    width: "auto",
    display: "inline",
    float: "right",
    paddingTop: 5,
    paddingBottom: 5,
    textAlign: "left",
    textIndent: "3.0em",
    top: -4,
    left: -20,
    position: "relative",
    borderRadius: 0,
  },
  innerH2: (props: { colors: ThemeColorProps }) => ({
    fontSize: "1.2em",
    color: props.colors.primary,
    paddingTop: 8,
    fontWeight: "bold",
    height: 50,
    fontFamily: FONT,
    backgroundColor: props.colors.base,
    position: "relative",
    width: 320,
    marginLeft: 94,
    paddingLeft: 5,
    marginTop: 18,
  }),
  touchAppIcon: {
    verticalAlign: "top",
    fontSize: "1.7rem",
    marginTop: -3,
  },
  peopleIcon: {
    verticalAlign: "top",
    fontSize: "1.7rem",
    marginTop: 1,
  },
  clearIcon: {
    position: "absolute",
    top: 5,
    left: 20,
  },
}));

type Props = {
  dealId: string;
  dealType: TDealTypeStr;
  workflowStatus: TWorkflowStatus;
  firstWorkflowStatus?: TWorkflowStatus;
  colors: ThemeColorProps;
  urlPrefix: "dealer" | "guest";
  updateDeal: (next: TWorkflowStatus) => Promise<void>;
  isRequiredFilledForTerminateDeal?: boolean;
} & RouteComponentProps;

const DealShowWorkflow: React.FC<Props> = (props: Props) => {
  const {
    dealId,
    dealType,
    workflowStatus,
    firstWorkflowStatus,
    colors,
    urlPrefix,
    updateDeal,
    history,
    isRequiredFilledForTerminateDeal,
  } = props;

  const classes = useStyles({ colors });
  const workButtonClasses = workflowButtonStyles({
    width: "100%",
    marginTop: 0,
    backgroundColor: colors.primary,
    btnHoverColor: colors.btnHover,
  });

  const { setConfirmModal } = useContext(GlobalPopupContext);
  const { authHeader } = useContext(AuthContext);
  const [userMessageOpen, setUserMessageOpen] = useState(false);

  // ステータス進行のボタンがあるか
  const withWorkflowEdit = useMemo(() => {
    if (isJtbSysADUser()) {
      // JTBシステム担当は管理者、一般どちらのボタンも表示する
      return WORKFLOW_STATUS_IN_DEALER.includes(workflowStatus);
    }
    if (isJtbStaffUser()) {
      // JTB一般
      return WORKFLOW_STATUS_IN_DEALER_COMMON.includes(workflowStatus);
    }
    if (isJtbManagerUser()) {
      // JTB管理者
      return WORKFLOW_STATUS_IN_DEALER_MANAGER.includes(workflowStatus);
    }
    // 上記以外は加盟店
    // 20: 加盟店契約者情報入力の間は押せるボタンがないので非表示にする
    return (
      WORKFLOW_STATUS_IN_CUSTOMER.includes(workflowStatus) &&
      !INPUT_CONTRACTOR_STATUSES.includes(workflowStatus)
    );
  }, [workflowStatus]);

  // キャンセルボタン表示条件
  const showCancelButton = useMemo(() => {
    return (
      checkAuth("dealsCancel") &&
      CANCEL_STATUSES_COMMON.includes(workflowStatus)
    );
  }, [workflowStatus]);

  const cancelDeal = useCallback(async () => {
    if (!authHeader) return;
    if (dealType.includes("変更")) {
      await changeDeal.cancelChangeDeal(authHeader, dealId);
    } else if (dealType === "端末新規" || dealType === "端末増設") {
      await deal.cancelNewDeal(authHeader, dealId);
    } else if (dealType === "端末解約") {
      await terminateDeal.cancelTerminalTerminateDeal(authHeader, dealId);
    } else if (dealType === "加盟店解約") {
      await terminateDeal.cancelCustomerTerminateDeal(authHeader, dealId);
    }
    history.push(`/${urlPrefix}`);
  }, [authHeader, dealId, dealType, history, urlPrefix]);

  return (
    <>
      {userMessageOpen && (
        <>
          {urlPrefix === "dealer" && (
            <DealUserMessageModal
              dealId={parseInt(dealId, 10)}
              dealType={dealType}
              setOpen={setUserMessageOpen}
              systemMessage="申請をキャンセルします。キャンセル理由を入力してください。"
              optionalApi={cancelDeal}
            />
          )}
          {urlPrefix === "guest" && (
            <GuestUserMessageModal
              dealId={parseInt(dealId, 10)}
              setOpen={setUserMessageOpen}
              systemMessage="申請をキャンセルします。キャンセル理由を入力してください。"
              optionalApi={cancelDeal}
            />
          )}
        </>
      )}
      <Typography
        variant="h2"
        className={clsx(
          classes.h2,
          withWorkflowEdit && classes.h2WithWorkflowEdit
        )}
      >
        <PeopleOutlineIcon className={classes.peopleIcon} />
        {" ワークフローステータス"}
        {/* 申請の処理者、ワークフローステータスのラベル */}
        <DealWorkflowStatus
          workflowState={workflowStatus}
          dealType={DEAL_TYPE_STR_TO_NUM[dealType]}
        />
        {/* キャンセルボタン */}
        {showCancelButton && (
          <Button
            onClick={() =>
              setConfirmModal({
                open: true,
                modalTitle: "申請をキャンセルする",
                actionMethod: () => {
                  setUserMessageOpen(true);
                  // 確認モーダルを閉じる
                  setConfirmModal({
                    open: false,
                    modalTitle: "",
                    actionMethod: () => {
                      /* 何もしない */
                    },
                    colors,
                  });
                },
                colors,
              })
            }
            className={clsx(workButtonClasses.button, classes.cancelButton)}
            variant="contained"
          >
            <ClearIcon className={classes.clearIcon} />
            申請をキャンセル
          </Button>
        )}
      </Typography>
      {/* ワークフローステータスを進めるボタン表示欄 */}
      {withWorkflowEdit && (
        <Box className={classes.box} borderRadius={1}>
          <Typography variant="h2" className={classes.innerH2}>
            <TouchAppIcon className={classes.touchAppIcon} />
            {" ワークフローステータスを進める"}
          </Typography>
          <Box className={classes.innerBox} borderRadius={16}>
            {/* ワークフロー関連ボタン */}
            <DealWorkflowButtons
              dealId={dealId}
              dealType={dealType}
              workflowStatus={workflowStatus}
              firstWorkflowStatus={firstWorkflowStatus}
              colors={colors}
              updateDeal={updateDeal}
              urlPrefix={urlPrefix}
              isRequiredFilledForTerminateDeal={
                isRequiredFilledForTerminateDeal
              }
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default withRouter(DealShowWorkflow);
