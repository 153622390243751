/**
 * JTB側-解約申請詳細画面
 */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Container, makeStyles, Tab, Tabs } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import CommentIcon from "@material-ui/icons/CommentOutlined";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import StoreIcon from "@material-ui/icons/Store";
import TouchAppIcon from "@material-ui/icons/TouchApp";

import { useContainerStyles } from "../../../../templates/styles";

import {
  TDealTypeStr,
  TJsonRowObject,
  TFormColumn,
  TFormRowData,
} from "../../../../common/components/types";

import { ADMIN_COLOR, FONT } from "../../../../../constants/common";
import DEAL_FORM_TAB_NAMES, {
  FORM_CATEGORY,
} from "../../../../../constants/form";
import {
  TWorkflowStatus,
  WORKFLOW_STATUS,
} from "../../../../../constants/workflowStatus";

import { GlobalPopupContext } from "../../../../../App";
import formStore from "../../../../../store/formStore";
import deal from "../../../../../api/deal";
import terminateDeal from "../../../../../api/terminateDeal";
import preloadFormContent from "../../../../../api/utils/preUtils";
import { getErrorMessage } from "../../../../../utils/error";
import {
  convIndustrySmallCode,
  json2FormData,
} from "../../../../../utils/formData";
import { AuthContext } from "../../../../../cognito/AuthContext";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import DealShowWorkflow from "../../../../common/components/organisms/DealShowWorkflow";
import TabPanel from "../../../../common/components/atoms/TabPanel";
import ConfirmForm from "../../../../form/components/organisms/ConfirmForm";
import CommentForm from "../../../../form/components/atoms/CommentForm";
import ChangeOrTerminateDealShowButtonList from "../../../../common/components/organisms/ChangeOrTerminateDealShowButtonList";
import UploadedAdditionalFiles from "../../../deal/components/molecules/UploadedAdditionalFiles";
import DealInfoData from "../../../../common/components/organisms/DealInfoData";
import ConfirmFormForTerminateDeal from "../../../../form/components/organisms/ConfirmFormForTerminateDeal";
import SecondSnackbar from "../../../../common/components/atoms/SecondSnackbar";

const breadCrumbs = [
  { name: "TOP", link: "/dealer" },
  { name: "申請詳細", link: "" },
];

const useStyles = makeStyles(() => ({
  settingIcon: {
    verticalAlign: "top",
    left: -2,
    top: 1,
    position: "relative",
  },
  Icon: {
    verticalAlign: "top",
    left: -2,
    top: 1,
    position: "relative",
  },
  tabs: {
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: ADMIN_COLOR.primary,
      color: ADMIN_COLOR.base,
    },
  },
  tab: {
    color: ADMIN_COLOR.primary,
    border: `3px solid ${ADMIN_COLOR.primary}`,
    marginRight: 4,
    minHeight: 35,
    borderBottom: "none",
    fontFamily: FONT,
    opacity: 1,
    borderRadius: "8px 8px 0px 0px",
    "& .MuiTab-wrapper": {
      display: "inline!important",
    },
    "& .MuiTab-labelIcon": {
      minHeight: 35,
    },
  },
  indicator: {
    display: "none",
  },
  message: {
    fontSize: "0.9em",
    fontWeight: "bold",
    fontFamily: FONT,
    color: ADMIN_COLOR.primary,
    marginTop: 20,
    marginBottom: 10,
  },
}));

// 申請情報のコンポーネント用
type DealInfoType = {
  applicationDate: string;
  lastApprovalDate: string;
  cancelDateAndTime: string;
  cancelComment: string;
};

const TerminateDealShowPage: React.FC<RouteComponentProps<{ id: string }>> = (
  props: RouteComponentProps<{ id: string }>
) => {
  const { match } = props;

  // 遷移元（申請管理画面の検索結果、ダッシュボード）でセットされたdealType
  const [dealType] = useState<TDealTypeStr | null>(formStore.getEditDealType());

  const [renderReady, setRenderReady] = useState<boolean>(false);
  const { authHeader } = useContext(AuthContext);
  const { setLoading } = useContext(GlobalPopupContext);
  const [workflowStatus, setWorkflowStatus] = useState<TWorkflowStatus>();
  const [firstWorkflowStatus, setFirstWorkflowStatus] =
    useState<TWorkflowStatus>();
  const [dealData, setDealData] = useState<DealInfoType>({} as DealInfoType);
  const [uploadedFiles, setUploadedFiles] = useState<
    { fileName: string; fileId: number }[]
  >([]);
  const [tabValue, setTabValue] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [columnNames, setColumnNames] = useState<TFormColumn[]>([]);
  const [terminateFormRow, setTerminateFormRow] = useState<TFormRowData>(
    {} as TFormRowData
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [
    isRequiredFilledForTerminateDeal,
    setIsRequiredFilledForTerminateDeal,
  ] = useState<boolean | undefined>(undefined);

  // ID類は初回API取得以降はページ遷移なしに変化しないのでRefを使う
  const dealId = useRef(match.params.id);
  const customerId = useRef(0);

  // 端末解約の場合に「端末情報」タブが表示されるので、その後のタブのindexをずらす
  const plusIndex = useMemo(() => {
    if (dealType === "端末解約") return 1;
    return 0;
  }, [dealType]);

  const classes = useStyles();
  const containerClasses = useContainerStyles();
  // 追加アップロードファイル一覧取得API
  const getExtraUploadFiles = useCallback(async () => {
    const fileRes = await deal.getExtraUploadFiles(
      authHeader || "",
      dealId.current
    );
    // 追加資料一覧セット
    setUploadedFiles(fileRes.data.result.filesList);
  }, [authHeader]);

  const getDataApi = useCallback(async () => {
    try {
      // 申請詳細情報取得API
      const res =
        dealType === "端末解約"
          ? await terminateDeal.showTerminalTerminateDeal(
              authHeader || "",
              dealId.current
            )
          : await terminateDeal.showCustomerTerminateDeal(
              authHeader || "",
              dealId.current
            );
      // formMaster取得API
      const jsonRes = await deal.getFormMaster(
        authHeader || "",
        res.data.result.formVersion
      );

      await getExtraUploadFiles();

      await preloadFormContent(authHeader || "", res.data.result.formVersion);

      // formMasterを整形して保存
      const formData = json2FormData(jsonRes.data.result);
      formStore.setFormData(
        formData.formData,
        formData.formGroupData,
        formData.columnToCategory
      );

      // 契約者情報を反映
      formStore.updateContents(res.data.result.contractorForm || {}, "dealer");

      // 加盟店情報を反映
      const smallClass = convIndustrySmallCode(res.data.result.customerForm);
      formStore.updateContents(
        {
          ...res.data.result.customerForm,
          industrySmallClass: smallClass,
        } || {},
        "dealer"
      );

      if (dealType === "加盟店解約") {
        // 加盟店解約申請の必須項目入力有無を保存
        setIsRequiredFilledForTerminateDeal(
          !!(
            res.data.result.dealForm.jtbTerminationDate &&
            res.data.result.dealForm.jtbTerminationReason
          )
        );
      }

      // 端末情報を反映 (端末解約の場合) マスタデータのため1端末1データなのでグリッドはなし
      if (dealType === "端末解約") {
        // 端末解約申請の必須項目入力有無を保存
        setIsRequiredFilledForTerminateDeal(
          !!(
            res.data.result.dealForm.terminalTerminationDate &&
            res.data.result.dealForm.jtbTerminalTerminationReason
          )
        );
        formStore.updateContents(res.data.result.terminalForm || {}, "dealer");
      }

      // その他を反映
      customerId.current = res.data.result.masterCustomerId;

      formStore.setFormComment(res.data.result.comment);
      setWorkflowStatus(
        Number(res.data.result.workflowStatus) as TWorkflowStatus
      );
      setFirstWorkflowStatus(
        Number(res.data.result.firstWorkflowStatus) as TWorkflowStatus
      );

      // フォームバージョンを保存
      formStore.setEditFormVersion(res.data.result.formVersion);

      // 申請情報反映
      setDealData({
        applicationDate: res.data.result.dealOtherForm.applicationDate,
        lastApprovalDate: res.data.result.lastApprovalDate,
        cancelDateAndTime: res.data.result.dealOtherForm.cancelDateAndTime,
        cancelComment: "",
      });

      /// //////////// 申請内容タブ ///////////////////// ///
      // 解約用のformMasterを取得
      const terminateFormMasterRes = await deal.getFormMaster(
        authHeader || "",
        res.data.result.formVersion,
        true
      );

      const formCategory =
        dealType === "端末解約"
          ? FORM_CATEGORY.TERMINAL
          : FORM_CATEGORY.CUSTOMER;

      const terminateJsonData = json2FormData(
        terminateFormMasterRes.data.result.filter(
          (r: TJsonRowObject) => r.formCategory === formCategory && r.columnName
        ) as TJsonRowObject[]
      );

      // 申請内容を反映
      const terminateForm = terminateJsonData.formData[formCategory][0].form;
      const terminateColumnNames = Object.keys(
        terminateForm || {}
      ) as TFormColumn[];
      terminateColumnNames.forEach((columnName) => {
        terminateForm[columnName][0].content =
          res.data.result.dealForm[columnName] || "";
      });
      // 申請内容のcolumnNamesを保存
      setColumnNames(terminateColumnNames);
      // 申請内容の行データを保存
      setTerminateFormRow(terminateForm);
      // データが揃ったのでレンダリング準備完了のフラグを立てる
      setRenderReady(true);
      setLoading(false);
    } catch (err) {
      setErrorMessage(getErrorMessage(err));
      // エラーの場合にも画面表示させるためレンダリング準備完了のフラグを立てる
      setRenderReady(true);
      setLoading(false);
    }
  }, [authHeader, getExtraUploadFiles, setLoading, dealType]);
  // 申請を更新する処理
  const updateDataApi = useCallback(
    async (nextWorkflow: TWorkflowStatus) => {
      formStore.setNextWorkflowStatus(nextWorkflow);
      const request =
        formStore.getApiRequestDataForChangeOrTerminateDealWorkflow();

      if (!authHeader || !request) return;

      try {
        setLoading(true);
        // 決裁時は申請情報pdf保管APIを実行する（審査室長が決裁後のWORKFLOW_STATUS: 130）
        if (nextWorkflow === WORKFLOW_STATUS.CREATE_INFO_SEND_TO_CUSTOMER_DB) {
          await deal.storeDealPdf(authHeader, dealId.current);
        }
        // 端末・加盟店解約申請ワークフローステータス進行APIの実行
        if (dealType === "端末解約") {
          await terminateDeal.progressTerminalTermiDealWorkflow(
            authHeader,
            dealId.current,
            request
          );
        } else if (dealType === "加盟店解約") {
          await terminateDeal.progressCustomerTermiDealWorkflow(
            authHeader,
            dealId.current,
            request
          );
        }
        await getDataApi();
        setSnackbarOpen(true); // snackbarを表示
        setLoading(false);
      } catch (err) {
        setErrorMessage(getErrorMessage(err));
        setLoading(false);
        throw err; // この関数を利用しているDealUserMessageModal側でエラーをcatchできるように再度throwする
      }
    },
    [authHeader, getDataApi, setLoading, dealType]
  );

  useEffect(() => {
    setLoading(true);
    getDataApi();
    // eslint-disable-next-line
  }, [setLoading]);

  const handleChange = useCallback((_, newValue: number) => {
    setTabValue(newValue);
  }, []);

  // dealTypeの型がundefinedとのユニオン型なので、ここでundefinedでない場合を保証する
  if (!dealType) return null;
  // レスポンスデータが全て揃うまでローディング
  if (!renderReady) return null;

  return (
    <Dashboard
      title="申請管理"
      color={ADMIN_COLOR.base}
      backgroundColor={ADMIN_COLOR.primary}
      iconName="deal"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {/* メッセージ送信のsnackbarと被らないように別でsnackbarを表示 */}
        <SecondSnackbar
          openProps={snackbarOpen}
          setOpen={setSnackbarOpen}
          message="ワークフローステータスを進めました。"
          severity="info"
        />
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        {/* ワークフローステータス表示欄 */}
        {workflowStatus && (
          <DealShowWorkflow
            dealId={dealId.current}
            dealType={dealType}
            workflowStatus={workflowStatus}
            colors={ADMIN_COLOR}
            urlPrefix="dealer"
            updateDeal={updateDataApi}
            firstWorkflowStatus={firstWorkflowStatus}
            isRequiredFilledForTerminateDeal={isRequiredFilledForTerminateDeal}
          />
        )}
        {/* 申請情報 */}
        <DealInfoData
          applicationDate={dealData.applicationDate}
          lastApprovalDate={dealData.lastApprovalDate}
          cancelDateAndTime={dealData.cancelDateAndTime}
          cancelComment={dealData.cancelComment}
        />
        {/* 文言 */}
        <div className={classes.message}>
          {`タブをクリックすることで「${DEAL_FORM_TAB_NAMES.DEAL_CONTENT}」、「${DEAL_FORM_TAB_NAMES.CONTRACTOR_INFO}」`}
          {(dealType === "加盟店解約" || dealType === "端末解約") &&
            `、「${DEAL_FORM_TAB_NAMES.CUSTOMER_INFO}」`}
          {dealType === "端末解約" &&
            `、「${DEAL_FORM_TAB_NAMES.TERMINAL_INFO}」`}
          {`、「${DEAL_FORM_TAB_NAMES.COMMENT_AND_MEMO}」、「${DEAL_FORM_TAB_NAMES.VARIOUS_OPERATION}」が表示されます。`}
        </div>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          className={classes.tabs}
          classes={{ indicator: classes.indicator }}
        >
          {/* 申請内容タブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.DEAL_CONTENT}`}
            id="simple-tab-0"
            aria-labelledby="simple-tab-0"
            icon={<BusinessCenterIcon className={classes.Icon} />}
            className={classes.tab}
          />
          {/* 契約者情報タブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.CONTRACTOR_INFO}`}
            id="simple-tab-1"
            aria-labelledby="simple-tab-1"
            icon={<BusinessCenterIcon className={classes.Icon} />}
            className={classes.tab}
          />
          {/* 加盟店情報タブ */}
          {(dealType === "加盟店解約" || dealType === "端末解約") && (
            <Tab
              label={` ${DEAL_FORM_TAB_NAMES.CUSTOMER_INFO}`}
              id="simple-tab-2"
              aria-labelledby="simple-tab-2"
              icon={<StoreIcon className={classes.Icon} />}
              className={classes.tab}
            />
          )}
          {/* 端末情報タブ */}
          {dealType === "端末解約" && (
            <Tab
              label={` ${DEAL_FORM_TAB_NAMES.TERMINAL_INFO}`}
              id="simple-tab-3"
              aria-labelledby="simple-tab-3"
              icon={<PhonelinkSetupIcon className={classes.Icon} />}
              className={classes.tab}
            />
          )}
          {/* コメント・メモタブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.COMMENT_AND_MEMO}`}
            id="simple-tab-4"
            aria-labelledby="simple-tab-4"
            icon={<CommentIcon className={classes.settingIcon} />}
            className={classes.tab}
          />
          {/* 各種操作タブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.VARIOUS_OPERATION}`}
            id="simple-tab-5"
            aria-labelledby="simple-tab-5"
            icon={<TouchAppIcon className={classes.settingIcon} />}
            className={classes.tab}
          />
        </Tabs>
        <form>
          {/* 申請内容 */}
          <TabPanel
            value={tabValue}
            index={0}
            borderColor={ADMIN_COLOR.primary}
          >
            <ConfirmFormForTerminateDeal
              columnNames={columnNames}
              terminateFormRow={terminateFormRow}
              requiredKey="dealer"
              colors={ADMIN_COLOR}
            />
          </TabPanel>
          {/* 契約者情報 */}
          <TabPanel
            value={tabValue}
            index={1}
            borderColor={ADMIN_COLOR.primary}
          >
            <ConfirmForm
              formCategory={FORM_CATEGORY.CONTRACTOR} // 契約者情報
              requiredKey="dealer"
              colors={ADMIN_COLOR}
              terminalGridId={undefined}
            />
          </TabPanel>
          {/* 加盟店情報 */}
          {(dealType === "加盟店解約" || dealType === "端末解約") && (
            <TabPanel
              value={tabValue}
              index={2}
              borderColor={ADMIN_COLOR.primary}
            >
              <ConfirmForm
                formCategory={FORM_CATEGORY.CUSTOMER} // 加盟店情報
                requiredKey="dealer"
                colors={ADMIN_COLOR}
                terminalGridId={undefined}
              />
            </TabPanel>
          )}
          {/* 端末情報  */}
          {dealType === "端末解約" && (
            <TabPanel
              value={tabValue}
              index={3}
              borderColor={ADMIN_COLOR.primary}
            >
              <ConfirmForm
                formCategory={FORM_CATEGORY.TERMINAL} // 端末情報
                requiredKey="dealer"
                colors={ADMIN_COLOR}
                terminalGridId={undefined}
              />
            </TabPanel>
          )}
          {/* コメント・メモ */}
          <TabPanel
            value={tabValue}
            index={3 + plusIndex}
            borderColor={ADMIN_COLOR.primary}
          >
            <CommentForm dealId={dealId.current} colors={ADMIN_COLOR} />
          </TabPanel>
          {/* 各種操作 */}
          <TabPanel
            value={tabValue}
            index={4 + plusIndex}
            borderColor={ADMIN_COLOR.primary}
          >
            {/* 各操作ボタン一覧 */}
            {workflowStatus && (
              <ChangeOrTerminateDealShowButtonList
                dealId={parseInt(dealId.current, 10)}
                customerId={customerId.current}
                dealType={dealType}
                workflowStatus={workflowStatus}
                updateFileList={getExtraUploadFiles}
                fileData={uploadedFiles}
              />
            )}
            {/* 追加アップロードファイル一覧 */}
            {uploadedFiles.length > 0 && (
              <UploadedAdditionalFiles fileData={uploadedFiles} />
            )}
          </TabPanel>
        </form>
      </Container>
    </Dashboard>
  );
};

export default withRouter(TerminateDealShowPage);
