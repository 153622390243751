import cognitoListener from "../../cognito/CognitoListener";

// 通信中APIリスト
export const loadingUrls: string[] = [];

// getのparamsをURLエンコードする
// any許可のため
// eslint-disable-next-line
export const createParamStr: (params: any) => string = (params: any) => {
  const searchParams = new URLSearchParams(params);
  return searchParams.toString();
};

// any許可のため
/* eslint-disable */
export const createLoadingPromise: () => Promise<any> = () =>
  new Promise<any>(() => {
    // Pending状態で何もしない（then内の処理もcatch内の処理も呼ばれない）
  });
/* eslint-enable */

const endLoading = (path: string) => {
  const index = loadingUrls.indexOf(path);
  if (index >= 0) {
    // 通信中登録解除
    loadingUrls.splice(index, 1);
  }
};

// any許可のため
/* eslint-disable */
export const createAPIPromiseWithoutAuth: (
  axiosPromise: Promise<any>,
  path: string
) => Promise<any> = (axiosPromise: Promise<any>, path: string) => {
  const ret = new Promise<any>((resolve, reject) => {
    axiosPromise
      .then((res) => {
        // 通信終了処理
        endLoading(path);
        resolve(res);
      })
      .catch((error) => {
        if (
          error.response.status === 503 &&
          error.response.data.isMaintenance
        ) {
          // メンテナンス時。
          // 5秒後に更新発火させる。awaitつけない非同期実行にすることで、先にエラーメッセージ表示処理を実行させつつ、その後に、更新を発火させる。
          // 更新を発火させることで、メンテ用のWAFがかかったフロントのファイル配信エンドポイントにアクセスしに行って、WAFがメンテ用のHTMLを返す。
          reloadAfterFewSeconds();

          endLoading(path);
          reject(error);
        } else {
          // 通信終了処理
          endLoading(path);
          reject(error);
        }
      });
  });
  return ret;
};
/* eslint-enable */

// any許可のため
/* eslint-disable */
export const createAPIPromise: (
  axiosPromise: Promise<any>,
  path: string
) => Promise<any> = (axiosPromise: Promise<any>, path: string) => {
  const ret = new Promise<any>((resolve, reject) => {
    axiosPromise
      .then((res) => {
        // token期限更新
        cognitoListener.emit("updateToken");

        // 通信終了処理
        endLoading(path);
        resolve(res);
      })
      .catch((error) => {
        // トークン期限切れ
        // status code 401判定
        if (error.response.status === 401) {
          // タイムアウトフラグを立てる。
          cognitoListener.setExpiredTokenFlag(true);
          // 必要であれば環境変数で分岐
          // dev環境はtoken更新させるなど
          // トークン期限切れ処理
          cognitoListener.emit("signOut");
          // 通信終了処理
          endLoading(path);
          reject(error);
        } else if (
          error.response.status === 503 &&
          error.response.data.isMaintenance
        ) {
          // メンテナンス時。
          // 5秒後に更新発火させる。awaitつけない非同期実行にすることで、先にエラーメッセージ表示処理を実行させつつ、その後に、更新を発火させる。
          // 更新を発火させることで、メンテ用のWAFがかかったフロントのファイル配信エンドポイントにアクセスしに行って、WAFがメンテ用のHTMLを返す。
          reloadAfterFewSeconds();

          endLoading(path);
          reject(error);
        } else {
          // 401以外
          // 通信終了処理
          endLoading(path);
          reject(error);
        }
      });
  });
  return ret;
};
/* eslint-enable */

// 5秒後にブラウザをリロードする。
export const reloadAfterFewSeconds: () => void = () => {
  const reload = () => window.location.reload();
  const waitTime = 5000; // 単位はmsec(ミリ秒)
  setTimeout(reload, waitTime);
};
