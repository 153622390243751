/**
 * 共通-アコーディオンのタイトルアイコン
 */
import React from "react";

import Person from "@material-ui/icons/Person";
import Store from "@material-ui/icons/Store";
import Storefront from "@material-ui/icons/Storefront";
import PhonelinkSetup from "@material-ui/icons/PhonelinkSetup";
import Menu from "@material-ui/icons/Menu";

interface Props {
  id: number;
  className: string;
}

const ClusterAccordionTitleIcon: React.FC<Props> = (props: Props) => {
  const { id, className } = props;

  switch (id) {
    case 0:
      return <Person className={className} />;
    case 1:
      return <Store className={className} />;
    case 2:
      return <Storefront className={className} />;
    case 3:
      return <PhonelinkSetup className={className} />;
    case 4:
      return <Menu className={className} />;
    default:
      return null;
  }
};

export default ClusterAccordionTitleIcon;
