/**
 * 加盟店側-解約申請確認画面
 * メモ：端末解約申請、加盟店解約申請の入力内容の確認を行う画面
 */
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { withRouter, Link, RouteComponentProps } from "react-router-dom";

import clsx from "clsx";
import { Button, Container, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import { Alert, AlertTitle } from "@material-ui/lab";

import { buttonStyles } from "../../../../common/components/styles";
import useContainerStyles from "../../../../templates/styles";

import {
  IRegisterTerminalTerminateDealInput,
  IRegisterCustomerTerminateDealInput,
} from "../../../../../api/types";

import { GUEST_COLOR } from "../../../../../constants/common";

import { GlobalPopupContext } from "../../../../../App";
import formStore from "../../../../../store/formStore";
import getErrorMessage from "../../../../../utils/error";
import { AuthContext } from "../../../../../cognito/AuthContext";
import terminateDeal from "../../../../../api/terminateDeal";

import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import Dashboard from "../../../../templates/Dashboard";
import ConfirmForm from "../../../../form/components/organisms/ConfirmForm";
import TabPanel from "../../../../common/components/atoms/TabPanel";

const useStyles = makeStyles(() => ({
  returnButton: {
    margin: "0 auto",
    paddingTop: 8,
    paddingBottom: 10,
    paddingLeft: 42,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 24,
    },
  },
  addButton: {
    margin: "0 auto",
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 8,
    color: `${GUEST_COLOR.primary}!important`,
    border: `1px solid ${GUEST_COLOR.primary}`,
    backgroundColor: `${GUEST_COLOR.base}!important`,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 16,
    },
    "&:hover": {
      backgroundColor: `${GUEST_COLOR.btnHover}!important`,
    },
  },
  returnIcon: {
    position: "absolute",
    top: 10,
    left: 31,
  },
  addIcon: {
    position: "absolute",
    top: 8,
    left: 20,
  },
  buttonContainer: {
    margin: "0 auto",
    clear: "both",
    height: 100,
    width: 284,
    marginTop: 20,
  },
  gridButtons: {
    float: "left",
  },
}));

const GuestTerminateDealNewConfirmPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const classes = useStyles();
  const containerClasses = useContainerStyles();
  const moveButtonClasses = buttonStyles({
    width: 130,
    marginTop: 20,
    backgroundColor: GUEST_COLOR.primary,
  });

  const { history } = props;

  // 申請種別に応じた情報を取得
  const dealTypeInfo = useMemo(
    () => formStore.getTerminateDealInfoByDealType(),
    []
  );
  const breadCrumbs = useMemo(
    () => [
      { name: dealTypeInfo.pageName, link: `/guest${dealTypeInfo.pageUrl}` },
      { name: "詳細", link: `/guest${dealTypeInfo.showPageUrl}` },
      { name: "解約申請入力", link: "/guest/deal/termination/new" },
      { name: "確認", link: "" },
    ],
    [dealTypeInfo]
  );

  const { setLoading } = useContext(GlobalPopupContext);
  const { authHeader } = useContext(AuthContext);
  const loading = useRef(false);
  const [apiErrorMsg, setApiErrorMsg] = useState("");

  const handleRegister = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      setLoading(true);
      loading.current = true;

      try {
        // POST用データを用意
        const request =
          formStore.getApiRequestDataForNewTerminateDeal("customer");
        if (!request) {
          setApiErrorMsg(
            "入力内容に問題が発生しました。入力画面に戻り確認してください。"
          );
          setLoading(false);
          return;
        }

        let ret;
        // 端末解約の場合
        if (dealTypeInfo.dealType === "端末解約") {
          // 端末解約申請登録API
          ret = await terminateDeal.storeTerminal(
            authHeader || "",
            request as IRegisterTerminalTerminateDealInput
          );
        }
        // 加盟店解約の場合
        if (dealTypeInfo.dealType === "加盟店解約") {
          // 加盟店解約申請登録API
          ret = await terminateDeal.storeCustomer(
            authHeader || "",
            request as IRegisterCustomerTerminateDealInput
          );
        }

        setLoading(false);
        loading.current = false;
        history.push(`/guest/deal/termination/complete/${ret.data.result.id}`);
      } catch (err) {
        setApiErrorMsg(getErrorMessage(err));
        setLoading(false);
        loading.current = false;
      }
    },
    [authHeader, history, setLoading, dealTypeInfo]
  );

  return (
    <Dashboard
      title={`${dealTypeInfo.dealType}申請`}
      color={GUEST_COLOR.base}
      backgroundColor={GUEST_COLOR.primary}
      iconName="deal"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {apiErrorMsg !== "" && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {apiErrorMsg}
          </Alert>
        )}
        {/* 確認フォーム */}
        <TabPanel value={0} index={0} borderColor={GUEST_COLOR.primary}>
          <ConfirmForm
            formCategory={dealTypeInfo.formCategory}
            requiredKey="customer"
            colors={GUEST_COLOR}
            terminalGridId={0}
          />
        </TabPanel>
        <div className={classes.buttonContainer}>
          {/* 戻るボタン */}
          <Button
            className={clsx(
              moveButtonClasses.button,
              classes.returnButton,
              classes.gridButtons
            )}
            type="button"
            component={Link}
            to="/guest/deal/termination/new"
            variant="contained"
          >
            <KeyboardReturnIcon className={classes.returnIcon} />
            戻る
          </Button>
          {/* 登録するボタン */}
          <Button
            type="submit"
            onClick={handleRegister}
            className={clsx(moveButtonClasses.button, classes.addButton)}
            variant="contained"
            disabled={loading.current} // 非同期処理が行われている間はボタンを押せないようにする。(二重送信対策)
          >
            <AddIcon className={classes.addIcon} />
            登録する
          </Button>
        </div>
      </Container>
    </Dashboard>
  );
};

export default withRouter(GuestTerminateDealNewConfirmPage);
