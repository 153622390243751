// ワークフローステータス関連の定数定義

import { TDealTypeStr } from "../views/common/components/types";

/**
 * ワークフローステータス
 */
export const WORKFLOW_STATUS = {
  /** 営業WEB入力（申込情報） ※DB上このステータスになることはないため実質存在しない */
  INPUT_DEAL_BY_DEALER: 10,
  /** 加盟店契約者情報入力 */
  INPUT_CONTRACTOR_BY_CUSTOMER: 20,
  /** 加盟店WEB入力（申込情報） */
  INPUT_DEAL_BY_CUSTOMER: 30,
  /** 申込情報差戻し */
  DEAL_INFO_REJECTED: 40,
  /** JTB審査中（営業担当） */
  EXAMINATION_BY_DEALER: 50,
  /** JTB審査中（営業担当）差戻し */
  EXAMINATION_BY_DEALER_REJECTED: 60,
  /** JTB審査中（加盟店管理担当） */
  EXAMINATION_BY_DEALER_MANAGER: 70,
  /** JTB審査中（加盟店管理担当）差戻し */
  EXAMINATION_BY_DEALER_MANAGER_REJECTED: 80,
  /** JTB審査中（審査担当） */
  EXAMINATION_BY_EXAMINER: 90,
  /** JTB審査中（審査担当）差戻し */
  EXAMINATION_BY_EXAMINER_REJECTED: 100,
  /** JTB審査中（審査室長） */
  EXAMINATION_BY_EXAMINER_MANAGER: 110,
  /** TID採番（バッチ） */
  NUMBERING_TID: 120,
  /** 加盟店DB連携情報作成（バッチ） */
  CREATE_INFO_SEND_TO_CUSTOMER_DB: 130,
  /** チェックシートデータ出力、送付 ※現状未実装、未稼働 */
  EXPORT_CHECK_SHEET: 140,
  /** バッチ中（151~157）のフロント側ステータス */
  IN_BATCH: 150,
  /** バッチ取扱種別設定&集計テーブル反映バッチ */
  BATCH_FOR_HANDLING_TYPE_AND_TOTALLING_TABLE: 151,
  /** JTB店子申請フォーマットバッチ */
  BATCH_FOR_JTB_TENANT_ORDER: 152,
  /** stera code加盟店申込書バッチ */
  BATCH_FOR_STERA_CODE_CUSTOMER_ORDER: 153,
  /** 端末リストバッチ */
  BATCH_FOR_TERMINAL_LIST: 154,
  /** 端末変更・番号入替・抹消用データバッチ */
  BATCH_FOR_DATA_OF_CHANGE_TERMINAL: 155,
  /** JTBstera進捗管理表バッチ */
  BATCH_FOR_STERA_PROGRESS_INFO: 156,
  /** ファイル出力&申込WEBマスタ反映バッチ */
  BATCH_FOR_EXPORT_FILE_AND_UPDATE_MASTER_DB: 157,
  /** stera進捗管理表取込待ち */
  WAIT_FOR_CAPTURE_STERA_PROGRESS_INFO: 160,
  /** stera進捗管理表取込NG */
  FAILED_CAPTURE_STERA_PROGRESS_INFO: 170,
  /** 受注登録待ち */
  WAIT_FOR_REGISTRATION: 180,
  /** 料率変更メール送信 @deprecated ※先方（GMO）整理で不要になった */
  SEND_RATE_CHANGE_MAIL: 190,
  /** 完了 */
  COMPLETE: 900,
  /** キャンセル */
  CANCEL: 990,
} as const;

/**
 * JTB側一般担当のステータス
 * @member 10: 営業WEB入力
 * @member 50: JTB審査中（JTB営業担当）
 * @member 60: JTB審査中（JTB営業担当） 差戻し
 * @member 70: JTB審査中（JTB加盟店管理担当）
 * @member 80: JTB審査中（JTB加盟店管理担当） 差戻し
 */
export const WORKFLOW_STATUS_IN_DEALER_COMMON: number[] = [
  WORKFLOW_STATUS.INPUT_DEAL_BY_DEALER,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_REJECTED,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
];

/**
 * JTB側管理者担当のステータス
 * @member 90: JTB審査中（審査担当）
 * @member 100: JTB審査中（審査担当） 差戻し
 * @member 110: JTB審査中（審査室長）
 */
export const WORKFLOW_STATUS_IN_DEALER_MANAGER: number[] = [
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_REJECTED,
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
];

/**
 * JTB側担当のステータス
 * @member 10: 営業WEB入力
 * @member 50: JTB審査中（JTB営業担当）
 * @member 60: JTB審査中（JTB営業担当） 差戻し
 * @member 70: JTB審査中（JTB加盟店管理担当）
 * @member 80: JTB審査中（JTB加盟店管理担当） 差戻し
 * @member 90: JTB審査中（審査担当）
 * @member 100: JTB審査中（審査担当） 差戻し
 * @member 110: JTB審査中（審査室長）
 */
export const WORKFLOW_STATUS_IN_DEALER: number[] = [
  ...WORKFLOW_STATUS_IN_DEALER_COMMON,
  ...WORKFLOW_STATUS_IN_DEALER_MANAGER,
];

/**
 * 加盟店側担当のステータス
 * @member 20: 加盟店契約者情報入力
 * @member 30: 加盟店WEB入力
 * @member 40: 申込情報 差戻し
 */
export const WORKFLOW_STATUS_IN_CUSTOMER: number[] = [
  WORKFLOW_STATUS.INPUT_CONTRACTOR_BY_CUSTOMER,
  WORKFLOW_STATUS.INPUT_DEAL_BY_CUSTOMER,
  WORKFLOW_STATUS.DEAL_INFO_REJECTED,
];

// WORKFLOW_STATUSの数字のユニオン型
export type TWorkflowStatus =
  (typeof WORKFLOW_STATUS)[keyof typeof WORKFLOW_STATUS];

export type TWorkflowStatusMessages = {
  [key in TWorkflowStatus]: string;
};

/**
 * ワークフローステータス表示文言（JTB側）
 */
export const WORKFLOW_STATUS_MESSAGES_FOR_DEALER = {
  10: "JTB入力中",
  20: "加盟店 契約者情報入力",
  30: "加盟店申込中",
  40: "申込情報差戻し",
  50: "JTB審査中（営業担当）",
  60: "JTB審査中（営業担当）差戻し",
  70: "JTB審査中（加盟店管理担当）",
  80: "JTB審査中（加盟店管理担当）差戻し ",
  90: "JTB審査中（審査担当）",
  100: "JTB審査中（審査担当）差戻し",
  110: "JTB審査中（審査室長）",
  120: "カード会社連携ファイル出力待ち",
  130: "カード会社連携ファイル出力待ち",
  140: "カード会社連携ファイル出力待ち",
  151: "カード会社連携ファイル出力待ち",
  152: "カード会社連携ファイル出力待ち",
  153: "カード会社連携ファイル出力待ち",
  154: "カード会社連携ファイル出力待ち",
  155: "カード会社連携ファイル出力待ち",
  156: "カード会社連携ファイル出力待ち",
  157: "カード会社連携ファイル出力待ち",
  160: "カード会社審査中",
  170: "カード会社審査NG",
  180: "審査結果受領済み",
  190: "カード会社連携ファイル出力待ち",
  900: "完了",
  990: "申込手続きキャンセル",
} as TWorkflowStatusMessages;

/**
 * ワークフローステータス表示文言（加盟店側）
 */
export const WORKFLOW_STATUS_MESSAGES_FOR_CUSTOMER = {
  ...WORKFLOW_STATUS_MESSAGES_FOR_DEALER,
  50: "JTB審査中",
  60: "JTB審査中",
  70: "JTB審査中",
  80: "JTB審査中",
  90: "JTB審査中",
  100: "JTB審査中",
  110: "JTB審査中",
  120: "カード会社審査中",
  130: "カード会社審査中",
  140: "カード会社審査中",
  151: "カード会社審査中",
  152: "カード会社審査中",
  153: "カード会社審査中",
  154: "カード会社審査中",
  155: "カード会社審査中",
  156: "カード会社審査中",
  157: "カード会社審査中",
  160: "カード会社審査中",
  170: "カード会社審査中",
  180: "完了",
} as TWorkflowStatusMessages;

// 端末増設・変更・解約は120から完了表示
export const WORKFLOW_STATUS_MESSAGES_NOT_EXAMINATION_FOR_CUSTOMER = {
  ...WORKFLOW_STATUS_MESSAGES_FOR_CUSTOMER,
  120: "完了",
  130: "完了",
  140: "完了",
  151: "完了",
  152: "完了",
  153: "完了",
  154: "完了",
  155: "完了",
  156: "完了",
  157: "完了",
  160: "完了",
  170: "完了",
} as TWorkflowStatusMessages;

// 解約は上記に加え、50から110まで「JTB確認中」
export const WORKFLOW_STATUS_MESSAGES_NOT_EXAMINATION_AND_TERMINATION_FOR_CUSTOMER =
  {
    ...WORKFLOW_STATUS_MESSAGES_NOT_EXAMINATION_FOR_CUSTOMER,
    50: "JTB確認中",
    60: "JTB確認中",
    70: "JTB確認中",
    80: "JTB確認中",
    90: "JTB確認中",
    100: "JTB確認中",
    110: "JTB確認中",
  } as TWorkflowStatusMessages;

/**
 * 申請キャンセル可能ステータス（共通）
 * @member 10: 営業WEB入力
 * @member 20: 加盟店契約者情報入力
 * @member 30: 加盟店WEB入力
 * @member 40: 申込情報 差戻し
 * @member 50: JTB審査中（JTB営業担当）
 * @member 60: JTB審査中（JTB営業担当） 差戻し
 * @member 70: JTB審査中（JTB加盟店管理担当）
 * @member 80: JTB審査中（JTB加盟店管理担当） 差戻し
 * @member 90: JTB審査中（審査担当）
 * @member 100: JTB審査中（審査担当） 差戻し
 * @member 110: JTB審査中（審査室長）
 */
export const CANCEL_STATUSES_COMMON: number[] = [
  WORKFLOW_STATUS.INPUT_DEAL_BY_DEALER,
  WORKFLOW_STATUS.INPUT_CONTRACTOR_BY_CUSTOMER,
  WORKFLOW_STATUS.INPUT_DEAL_BY_CUSTOMER,
  WORKFLOW_STATUS.DEAL_INFO_REJECTED,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_REJECTED,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_REJECTED,
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
];

/**
 * 申請情報編集ボタン表示ステータス
 * @member 10: 営業WEB入力
 * @member 30: 加盟店WEB入力
 * @member 40: 申込情報 差戻し
 */
export const INPUT_DEAL_STATUSES: number[] = [
  WORKFLOW_STATUS.INPUT_DEAL_BY_DEALER,
  WORKFLOW_STATUS.INPUT_DEAL_BY_CUSTOMER,
  WORKFLOW_STATUS.DEAL_INFO_REJECTED,
];

/**
 * 申請情報編集ボタン表示ステータス（加盟店・端末解約用）
 * @member 50: JTB審査中（JTB営業担当）
 * @member 60: JTB審査中（JTB営業担当） 差戻し
 * @member 70: JTB審査中（JTB加盟店管理担当）
 * @member 80: JTB審査中（JTB加盟店管理担当） 差戻し
 */
export const INPUT_DEAL_STATUSES_FOR_TERMINATE_DEAL: number[] = [
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_REJECTED,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
];

/**
 * チェック完了ボタン表示ステータス（加盟店・端末解約用）
 * @member 70: JTB審査中（JTB加盟店管理担当）
 * @member 80: JTB審査中（JTB加盟店管理担当） 差戻し
 */
export const CHECK_STATUSES_FOR_TERMINATE_DEAL: number[] = [
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
];

/**
 * 現在のワークフローステータスと次のワークフローステータスの変換表（端末新規申請）
 * @description [現在のワークフローステータス, 次のワークフローステータス]
 * @member [30: 加盟店WEB入力, 50: JTB審査中（JTB営業担当）]
 * @member [40: 申込情報 差戻し, 50: JTB審査中（JTB営業担当）]
 */
export const NEXT_WORKFLOW_MAP_NEW_CUSTOMER = new Map<number, number>([
  [
    WORKFLOW_STATUS.INPUT_DEAL_BY_CUSTOMER,
    WORKFLOW_STATUS.EXAMINATION_BY_DEALER,
  ],
  [WORKFLOW_STATUS.DEAL_INFO_REJECTED, WORKFLOW_STATUS.EXAMINATION_BY_DEALER],
]);

/**
 * 現在のワークフローステータスと次のワークフローステータスの変換表（端末増設申請）
 * @description [現在のワークフローステータス, 次のワークフローステータス]
 * @member [30: 加盟店WEB入力, 70: JTB審査中（JTB加盟店管理担当）]
 * @member [40: 申込情報 差戻し, 70: JTB審査中（JTB加盟店管理担当）]
 */
export const NEXT_WORKFLOW_MAP_ADD_TERMINAL = new Map<number, number>([
  [
    WORKFLOW_STATUS.INPUT_DEAL_BY_CUSTOMER,
    WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
  ],
  [
    WORKFLOW_STATUS.DEAL_INFO_REJECTED,
    WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
  ],
]);

/**
 * 現在のワークフローステータスと次のワークフローステータスの変換表（解約申請）
 * @description [現在のワークフローステータス, 次のワークフローステータス]
 * @member [40: 申込情報 差戻し, 50: JTB審査中（JTB営業担当）]
 */
export const NEXT_WORKFLOW_MAP_CUSTOM_TERMINATE = new Map<number, number>([
  [WORKFLOW_STATUS.DEAL_INFO_REJECTED, WORKFLOW_STATUS.EXAMINATION_BY_DEALER],
]);

/**
 * 現在のワークフローステータスと次のワークフローステータスの変換表（変更申請）
 * @description [現在のワークフローステータス, 次のワークフローステータス]
 * @member [40: 申込情報 差戻し, 50: JTB審査中（JTB営業担当）]
 */
export const NEXT_WORKFLOW_MAP_CHANGE_DEAL = new Map<number, number>([
  [WORKFLOW_STATUS.DEAL_INFO_REJECTED, WORKFLOW_STATUS.EXAMINATION_BY_DEALER],
]);

/**
 * 現在のワークフローステータスと次のワークフローステータスの変換表（承認）
 * @description [現在のワークフローステータス, {申請種別: 次のワークフローステータス}]
 */
export const NEXT_WORKFLOW_MAP_APPROVAL = new Map<
  number,
  { [key in TDealTypeStr]: number }
>([
  [
    WORKFLOW_STATUS.EXAMINATION_BY_DEALER,
    {
      端末新規: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
      端末増設: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
      契約者変更: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
      加盟店変更: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
      端末変更: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
      端末解約: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
      加盟店解約: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
    },
  ],
  [
    WORKFLOW_STATUS.EXAMINATION_BY_DEALER_REJECTED,
    {
      端末新規: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
      端末増設: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
      契約者変更: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
      加盟店変更: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
      端末変更: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
      端末解約: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
      加盟店解約: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
    },
  ],
  [
    WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
    {
      端末新規: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
      端末増設: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
      契約者変更: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
      加盟店変更: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
      端末変更: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
      端末解約: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
      加盟店解約: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
    },
  ],
  [
    WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
    {
      端末新規: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
      端末増設: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
      契約者変更: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
      加盟店変更: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
      端末変更: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
      端末解約: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
      加盟店解約: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
    },
  ],
  [
    WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
    {
      端末新規: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
      端末増設: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
      契約者変更: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
      加盟店変更: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
      端末変更: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
      端末解約: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
      加盟店解約: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
    },
  ],
  [
    WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_REJECTED,
    {
      端末新規: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
      端末増設: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
      契約者変更: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
      加盟店変更: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
      端末変更: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
      端末解約: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
      加盟店解約: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
    },
  ],
  [
    WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
    {
      端末新規: WORKFLOW_STATUS.NUMBERING_TID,
      端末増設: WORKFLOW_STATUS.NUMBERING_TID,
      契約者変更: WORKFLOW_STATUS.CREATE_INFO_SEND_TO_CUSTOMER_DB,
      加盟店変更: WORKFLOW_STATUS.CREATE_INFO_SEND_TO_CUSTOMER_DB,
      端末変更: WORKFLOW_STATUS.CREATE_INFO_SEND_TO_CUSTOMER_DB,
      端末解約: WORKFLOW_STATUS.CREATE_INFO_SEND_TO_CUSTOMER_DB,
      加盟店解約: WORKFLOW_STATUS.CREATE_INFO_SEND_TO_CUSTOMER_DB,
    },
  ],
]);

/**
 * 現在のワークフローステータスと次のワークフローステータスの変換表（差し戻し）
 * @description [現在のワークフローステータス, {申請種別: 次のワークフローステータス}]
 */
export const NEXT_WORKFLOW_MAP_REJECTED = new Map<
  number,
  { [key in TDealTypeStr]: number }
>([
  [
    WORKFLOW_STATUS.EXAMINATION_BY_DEALER,
    {
      端末新規: WORKFLOW_STATUS.DEAL_INFO_REJECTED,
      端末増設: WORKFLOW_STATUS.DEAL_INFO_REJECTED,
      契約者変更: WORKFLOW_STATUS.DEAL_INFO_REJECTED,
      加盟店変更: WORKFLOW_STATUS.DEAL_INFO_REJECTED,
      端末変更: WORKFLOW_STATUS.DEAL_INFO_REJECTED,
      端末解約: WORKFLOW_STATUS.DEAL_INFO_REJECTED,
      加盟店解約: WORKFLOW_STATUS.DEAL_INFO_REJECTED,
    },
  ],
  [
    WORKFLOW_STATUS.EXAMINATION_BY_DEALER_REJECTED,
    {
      端末新規: WORKFLOW_STATUS.DEAL_INFO_REJECTED,
      端末増設: WORKFLOW_STATUS.DEAL_INFO_REJECTED,
      契約者変更: WORKFLOW_STATUS.DEAL_INFO_REJECTED,
      加盟店変更: WORKFLOW_STATUS.DEAL_INFO_REJECTED,
      端末変更: WORKFLOW_STATUS.DEAL_INFO_REJECTED,
      端末解約: WORKFLOW_STATUS.DEAL_INFO_REJECTED,
      加盟店解約: WORKFLOW_STATUS.DEAL_INFO_REJECTED,
    },
  ],
  [
    WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
    {
      端末新規: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_REJECTED,
      端末増設: WORKFLOW_STATUS.DEAL_INFO_REJECTED,
      契約者変更: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_REJECTED,
      加盟店変更: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_REJECTED,
      端末変更: WORKFLOW_STATUS.DEAL_INFO_REJECTED,
      端末解約: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_REJECTED,
      加盟店解約: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_REJECTED,
    },
  ],
  [
    WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
    {
      端末新規: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_REJECTED,
      端末増設: WORKFLOW_STATUS.DEAL_INFO_REJECTED,
      契約者変更: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_REJECTED,
      加盟店変更: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_REJECTED,
      端末変更: WORKFLOW_STATUS.DEAL_INFO_REJECTED,
      端末解約: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_REJECTED,
      加盟店解約: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_REJECTED,
    },
  ],
  [
    WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
    {
      端末新規: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
      端末増設: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
      契約者変更: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
      加盟店変更: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
      端末変更: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
      端末解約: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
      加盟店解約: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
    },
  ],
  [
    WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_REJECTED,
    {
      端末新規: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
      端末増設: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
      契約者変更: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
      加盟店変更: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
      端末変更: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
      端末解約: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
      加盟店解約: WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
    },
  ],
  [
    WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
    {
      端末新規: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_REJECTED,
      端末増設: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_REJECTED,
      契約者変更: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_REJECTED,
      加盟店変更: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_REJECTED,
      端末変更: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_REJECTED,
      端末解約: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_REJECTED,
      加盟店解約: WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_REJECTED,
    },
  ],
]);

/**
 * 契約者情報編集ボタン表示ステータス
 * @member 20: 加盟店契約者情報入力
 */
export const INPUT_CONTRACTOR_STATUSES: number[] = [
  WORKFLOW_STATUS.INPUT_CONTRACTOR_BY_CUSTOMER,
];

/**
 * チェック完了ボタン表示ステータス
 * @member 50: JTB審査中（JTB営業担当）
 * @member 60: JTB審査中（JTB営業担当） 差戻し
 * @member 70: JTB審査中（JTB加盟店管理担当）
 * @member 80: JTB審査中（JTB加盟店管理担当） 差戻し
 */
export const CHECK_STATUSES: number[] = [
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_REJECTED,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
];

/**
 * 承認ボタン表示ステータス
 * @member 90: JTB審査中（審査担当）
 * @member 100: JTB審査中（審査担当） 差戻し
 * @member 110: JTB審査中（審査室長）
 */
export const APPROVE_STATUSES: number[] = [
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_REJECTED,
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
];

/**
 * 差し戻しボタン表示ステータス
 * @member 50: JTB審査中（JTB営業担当）
 * @member 60: JTB審査中（JTB営業担当） 差戻し
 * @member 70: JTB審査中（JTB加盟店管理担当）
 * @member 80: JTB審査中（JTB加盟店管理担当） 差戻し
 * @member 90: JTB審査中（審査担当）
 * @member 100: JTB審査中（審査担当） 差戻し
 * @member 110: JTB審査中（審査室長）
 */
export const RETURN_BACK_STATUSES: number[] = [
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_REJECTED,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_REJECTED,
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
];

/**
 * JTB一般ユーザー: 差し戻しボタン表示ステータス
 * @member 50: JTB審査中（JTB営業担当）
 * @member 60: JTB審査中（JTB営業担当） 差戻し
 * @member 70: JTB審査中（JTB加盟店管理担当）
 * @member 80: JTB審査中（JTB加盟店管理担当） 差戻し
 */
export const RETURN_BACK_STATUSES_JTB_STAFF: number[] = [
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_REJECTED,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
];

/**
 * JTB管理者ユーザー: 差し戻しボタン表示ステータス
 * @member 90: JTB審査中（審査担当）
 * @member 100: JTB審査中（審査担当） 差戻し
 * @member 110: JTB審査中（審査室長）
 */
export const RETURN_BACK_STATUSES_JTB_MANAGER: number[] = [
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_REJECTED,
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
];

/**
 * 加盟店コード入力ボタン表示ステータス
 * @member 120: TID採番
 * @member 130: 加盟店DB連携情報作成
 * @member 140: チェックシートデータ出力、送付
 * @member 150: バッチ中（151~157）
 * @member 160: stera進捗管理表取込待ち
 * @member 170: stera進捗管理表取込NG
 * @member 180: 受注登録待ち
 */
export const EDIT_CUSTOMER_CODE_STATUSES: number[] = [
  WORKFLOW_STATUS.NUMBERING_TID,
  WORKFLOW_STATUS.CREATE_INFO_SEND_TO_CUSTOMER_DB,
  WORKFLOW_STATUS.EXPORT_CHECK_SHEET,
  WORKFLOW_STATUS.IN_BATCH,
  WORKFLOW_STATUS.BATCH_FOR_HANDLING_TYPE_AND_TOTALLING_TABLE,
  WORKFLOW_STATUS.BATCH_FOR_JTB_TENANT_ORDER,
  WORKFLOW_STATUS.BATCH_FOR_STERA_CODE_CUSTOMER_ORDER,
  WORKFLOW_STATUS.BATCH_FOR_TERMINAL_LIST,
  WORKFLOW_STATUS.BATCH_FOR_DATA_OF_CHANGE_TERMINAL,
  WORKFLOW_STATUS.BATCH_FOR_STERA_PROGRESS_INFO,
  WORKFLOW_STATUS.BATCH_FOR_EXPORT_FILE_AND_UPDATE_MASTER_DB,
  WORKFLOW_STATUS.WAIT_FOR_CAPTURE_STERA_PROGRESS_INFO,
  WORKFLOW_STATUS.FAILED_CAPTURE_STERA_PROGRESS_INFO,
  WORKFLOW_STATUS.WAIT_FOR_REGISTRATION,
];

/**
 * 申請編集ボタン表示ステータス（各種操作）
 * @member 50: JTB審査中（JTB営業担当）
 * @member 60: JTB審査中（JTB営業担当） 差戻し
 * @member 70: JTB審査中（JTB加盟店管理担当）
 * @member 80: JTB審査中（JTB加盟店管理担当） 差戻し
 * @member 90: JTB審査中（審査担当）
 * @member 100: JTB審査中（審査担当） 差戻し
 * @member 110: JTB審査中（審査室長）
 */
export const EDIT_DEAL_STATUSES: number[] = [
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_REJECTED,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_REJECTED,
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
];

/**
 * JTB一般ユーザー: 申請編集ボタン表示ステータス（各種操作）
 * @member 50: JTB審査中（JTB営業担当）
 * @member 60: JTB審査中（JTB営業担当） 差戻し
 * @member 70: JTB審査中（JTB加盟店管理担当）
 * @member 80: JTB審査中（JTB加盟店管理担当） 差戻し
 */
export const EDIT_DEAL_STATUSES_FOR_JTB_STAFF: number[] = [
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_REJECTED,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
];

/**
 * JTB管理者ユーザー: 申請編集ボタン表示ステータス（各種操作）
 * @member 90: JTB審査中（審査担当）
 * @member 100: JTB審査中（審査担当） 差戻し
 * @member 110: JTB審査中（審査室長）
 */
export const EDIT_DEAL_STATUSES_FOR_JTB_MANAGER: number[] = [
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_REJECTED,
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
];

/**
 * 申請検索ステータスのプルダウン内容(JTB側）
 * APIがステータスマッピングに使用しているため変更する場合バックエンドも要対応
 */
export const WORKFLOW_STATUS_OPTIONS_JTB = new Map<string, string>([
  ["加盟店 契約者情報入力", "1"],
  ["加盟店申込中", "2"],
  ["申込情報差戻し", "3"],
  ["JTB審査中（営業担当）", "4"],
  ["JTB審査中（営業担当）差戻し", "5"],
  ["JTB審査中（加盟店管理担当）", "6"],
  ["JTB審査中（加盟店管理担当）差戻し ", "7"],
  ["JTB審査中（審査担当）", "8"],
  ["JTB審査中（審査担当）差戻し", "9"],
  ["JTB審査中（審査室長）", "10"],
  ["カード会社連携ファイル出力待ち", "11"],
  ["カード会社審査中", "12"],
  ["カード会社審査NG", "13"],
  ["審査結果受領済み", "14"],
  ["完了", "15"],
  ["申込手続きキャンセル", "16"],
]);

/**
 * 申請検索ステータスのプルダウン内容(加盟店側）
 * APIがステータスマッピングに使用しているため変更する場合バックエンドも要対応
 */
export const WORKFLOW_STATUS_OPTIONS_CUSTOMER = new Map<string, string>([
  ["加盟店 契約者情報入力", "1"],
  ["加盟店申込中", "2"],
  ["申込情報差戻し", "3"],
  ["JTB審査中・JTB確認中", "4"],
  ["カード会社審査中", "5"],
  ["完了", "6"],
  ["申込手続きキャンセル", "7"],
]);

/**
 * 変更申請＿カード会社連携ファイル出力前
 * @member 30: 加盟店WEB入力
 * @member 40: 申込情報 差戻し
 * @member 50: JTB審査中（JTB営業担当）
 * @member 60: JTB審査中（JTB営業担当） 差戻し
 * @member 70: JTB審査中（JTB加盟店管理担当）
 * @member 80: JTB審査中（JTB加盟店管理担当） 差戻し
 * @member 90: JTB審査中（審査担当）
 * @member 100: JTB審査中（審査担当） 差戻し
 * @member 110: JTB審査中（審査室長）
 */
export const WORKFLOW_BEFORE_APPROVAL_NUM: number[] = [
  WORKFLOW_STATUS.INPUT_DEAL_BY_CUSTOMER,
  WORKFLOW_STATUS.DEAL_INFO_REJECTED,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_REJECTED,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER,
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_REJECTED,
  WORKFLOW_STATUS.EXAMINATION_BY_EXAMINER_MANAGER,
];

/**
 * 変更申請＿ワークフローステータスを進める内の差し戻しボタン非表示制御（契約者変更、加盟店変更の場合）
 * @member 50: JTB審査中（JTB営業担当）
 * @member 60: JTB審査中（JTB営業担当） 差戻し
 */
export const HIDE_RETURN_BACK_STATUSES_FOR_CHANGE_DEAL: number[] = [
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_REJECTED,
];

/**
 * 変更申請＿ワークフローステータスを進める内の差し戻しボタン非表示制御（端末変更の場合）
 * @member 70: JTB審査中（JTB加盟店管理担当）
 * @member 80: JTB審査中（JTB加盟店管理担当） 差戻し
 */
export const HIDE_RETURN_BACK_STATUSES_FOR_CHANGE_DEAL_BY_TERMINAL: number[] = [
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
];

/**
 * 解約申請＿ワークフローステータスを進める内の差し戻しボタン非表示制御
 * @member 70: JTB審査中（JTB加盟店管理担当）
 * @member 80: JTB審査中（JTB加盟店管理担当） 差戻し
 */
export const HIDE_RETURN_BACK_STATUSES_FOR_TERMINATE_DEAL: number[] = [
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER,
  WORKFLOW_STATUS.EXAMINATION_BY_DEALER_MANAGER_REJECTED,
];
