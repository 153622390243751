/**
 * 加盟店側-マニュアル詳細画面
 * メモ：マニュアルのPDFを表示する
 */
import React from "react";
import { RouteComponentProps, useLocation, withRouter } from "react-router-dom";

import clsx from "clsx";
import { makeStyles, Button, CircularProgress } from "@material-ui/core";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";

import { buttonStyles } from "../../../../common/components/styles";

import { PDF_TYPE } from "../../../../../constants/deal";
import { GUEST_COLOR } from "../../../../../constants/common";

const useStyles = makeStyles(() => ({
  pdf: {
    clear: "both",
    paddingTop: 25,
  },
  rightButton: {
    float: "right",
    marginRight: 140,
  },
  mainLayout: {
    width: "80%",
    margin: "0 auto",
  },
  embedPosition: {
    position: "absolute",
    left: 0,
    top: 70,
    width: "100%",
    height: "100%",
  },
  keyboardReturnButton: {
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 89,
  },
  keyboardReturn: {
    position: "absolute",
    top: 8,
    left: 50,
  },
  progress: {
    width: "100%",
    height: "50vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: GUEST_COLOR.primary,
  },
}));

const GuestDocumentPdfPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const { history } = props;

  const { state } = useLocation();
  const pdfData = state && state.pdfData ? state.pdfData : "";

  const classes = useStyles();
  const buttonClasses = buttonStyles({
    width: 180,
    marginTop: 20,
    marginLeft: 135,
    backgroundColor: GUEST_COLOR.primary,
  });

  const handleToPrePage = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    history.push("/guest/documents");
  };

  return (
    <>
      <div>
        {/* 戻るボタン */}
        <Button
          className={clsx(
            buttonClasses.button,
            classes.rightButton,
            classes.keyboardReturnButton
          )}
          type="button"
          onClick={handleToPrePage}
          variant="contained"
        >
          <KeyboardReturnIcon className={classes.keyboardReturn} />
          戻る
        </Button>
      </div>

      <div id="pdf" className={classes.pdf}>
        <div id="main" className={classes.mainLayout}>
          {/* PDF表示 */}
          {pdfData ? (
            <embed
              className={classes.embedPosition}
              type={PDF_TYPE}
              src={pdfData}
            />
          ) : (
            <div className={classes.progress}>
              <CircularProgress color="inherit" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(GuestDocumentPdfPage);
