/**
 * JTB側-メールアドレス変更画面
 * メモ：自身のメールアドレスを変更する画面。確認コードを発行する。
 */
import React, { useContext, useState } from "react";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import EditIcon from "@material-ui/icons/Edit";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import { useContainerStyles } from "../../../../templates/styles";
import {
  buttonStyles,
  loginFormStyles,
} from "../../../../common/components/styles";

import { EmailEditItemObject } from "../../types";

import { ADMIN_COLOR, EMAIL_CHAR_TYPE } from "../../../../../constants/common";

import { AuthContext } from "../../../../../cognito/AuthContext";
import { checkPattern } from "../../../../../form/validation";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import AlertMessage from "../../../../common/components/atoms/AuthAlertMsg";
import Loading from "../../../../common/components/molecules/Loading";
import SettingMailEditForm from "../organisms/SettingMailEditForm";

const breadCrumbs = [
  { name: "設定", link: "/dealer/setting" },
  { name: "メールアドレス変更", link: "" },
];

const useStyles = makeStyles(() => ({
  editButton: {
    margin: "0 auto",
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 10,
    paddingLeft: 40,
  },
  editIcon: {
    position: "absolute",
    top: 8,
    left: 10,
  },
  linkIcon: {
    position: "relative",
    top: 8,
    left: 5,
    fontSize: "1.8em",
    color: ADMIN_COLOR.primary,
  },
  alertInfo: {
    marginBottom: 20,
  },
}));

const SettingMailEditPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const containerClasses = useContainerStyles();
  const buttonClasses = buttonStyles({
    width: 200,
    marginTop: 20,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const loginFormClasses = loginFormStyles();
  const classes = useStyles();

  const { history } = props;

  const [changeMailError, setChangeMailError] = useState("");
  const [loadingOpen, setLoadingOpen] = useState(false);
  const { changeEmail } = useContext(AuthContext);

  const [emailEditState, setEmailEditState] = useState<EmailEditItemObject>({
    newEmail: "",
    newEmailConfirm: "",
  });

  const formatCheck = (string: string) => {
    const errorArray = checkPattern(
      string,
      EMAIL_CHAR_TYPE,
      "メールアドレス形式",
      []
    );
    return errorArray[0];
  };

  const handleToConfirmPage = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();

    // メールアドレスのフォーマットチェック
    const error = formatCheck(emailEditState.newEmail);
    if (error) {
      setChangeMailError("正しいメールアドレス形式をご指定ください");
      return;
    }

    setLoadingOpen(true);

    // CognitoAuthProviderのメールアドレス変更（確認コード送信）メソッドを実行
    const { result, message } = await changeEmail(emailEditState);

    setLoadingOpen(false);
    if (result) {
      // 成功した場合、メールアドレス変更確認コード入力画面に遷移
      setChangeMailError("");
      history.push({
        pathname: "/dealer/setting/mail/edit/confirm/",
        state: { mailEditSkip: false, newEmail: emailEditState.newEmail },
      });
      return;
    }
    setChangeMailError(message);
  };

  const handleMailEditSkip = (e: React.MouseEvent<HTMLElement>) => {
    const { newEmail } = emailEditState;
    setChangeMailError("");

    if (!newEmail) {
      // 新しいメールアドレスが未入力の場合はエラー
      e.preventDefault();
      setChangeMailError(
        "確認コードを発行した時点の新しいメールアドレスを入力してください。"
      );
    }

    // メールアドレスのフォーマットチェック
    const error = formatCheck(emailEditState.newEmail);
    if (error) {
      e.preventDefault();
      setChangeMailError("正しいメールアドレス形式をご指定ください");
    }
  };

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Dashboard
        title="設定"
        color={ADMIN_COLOR.base}
        backgroundColor={ADMIN_COLOR.primary}
        iconName="setting"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {/* 注意文言 */}
          <Alert severity="info" className={classes.alertInfo}>
            メールアドレス変更後は、再ログインが必要になります。
          </Alert>
          {/* エラーメッセージ */}
          {changeMailError && <AlertMessage errorMessage={changeMailError} />}
          <form>
            {/* 入力フォーム */}
            <SettingMailEditForm
              emailEditState={emailEditState}
              setEmailEditState={setEmailEditState}
            />
            {/* 既に確認コードをお持ちの方はこちらリンク */}
            <Link
              to={{
                pathname: "/dealer/setting/mail/edit/confirm/",
                state: {
                  mailEditSkip: true,
                  newEmail: emailEditState.newEmail,
                },
              }}
              className={loginFormClasses.link}
              onClick={handleMailEditSkip}
            >
              既に確認コードをお持ちの方はこちら
              <KeyboardArrowRightIcon className={classes.linkIcon} />
            </Link>
            {/* 文言 */}
            <p>
              確認コードを再発行したい場合は、同じ内容を再度登録してください。
            </p>
            {/* 確認コードを発行するボタン */}
            <Button
              type="submit"
              onClick={handleToConfirmPage}
              className={clsx(buttonClasses.button, classes.editButton)}
              variant="contained"
              disabled={loadingOpen} // 二重送信対策
            >
              <EditIcon className={classes.editIcon} />
              確認コードを発行する
            </Button>
          </form>
        </Container>
      </Dashboard>
    </>
  );
};

export default withRouter(SettingMailEditPage);
