/**
 * フォーム関係-JSONで設定されているformRemarks（項目の説明）を表示するコンポーネント
 */
import React from "react";

import { makeStyles } from "@material-ui/core";

import { FONT } from "../../../../constants/common";

import LineBreaksText from "../../../common/components/atoms/LineBreaksText";

const useStyles = makeStyles(() => ({
  remark: {
    color: "#777",
    fontFamily: FONT,
  },
}));

type Props = {
  remarks: string;
};

const FormRemarks: React.FC<Props> = (props: Props) => {
  const { remarks } = props;

  const classes = useStyles();

  return (
    <div className={classes.remark}>
      <LineBreaksText text={remarks} />
    </div>
  );
};

export default FormRemarks;
