/**
 * JTB側-申請編集確認画面
 */
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";

import clsx from "clsx";
import { makeStyles, Container, Button, Tabs, Tab } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import StoreIcon from "@material-ui/icons/Store";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CommentIcon from "@material-ui/icons/CommentOutlined";

import { buttonStyles } from "../../../../common/components/styles";
import { useContainerStyles } from "../../../../templates/styles";

import { ADMIN_COLOR, FONT } from "../../../../../constants/common";
import {
  DEAL_FORM_TAB_NAMES,
  FORM_CATEGORY,
} from "../../../../../constants/form";
import { WORKFLOW_STATUS_MESSAGES_FOR_DEALER } from "../../../../../constants/workflowStatus";
import { UPLOAD_FILE_TYPE } from "../../../../../constants/deal";

import { GlobalPopupContext } from "../../../../../App";
import { getErrorMessage } from "../../../../../utils/error";
import formStore from "../../../../../store/formStore";
import deal from "../../../../../api/deal";
import { AuthContext } from "../../../../../cognito/AuthContext";

import StatusUpdateModal from "../../../../common/components/molecules/StatusUpdateModal";
import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import TabPanel from "../../../../common/components/atoms/TabPanel";
import ConfirmForm from "../../../../form/components/organisms/ConfirmForm";
import TerminalForm from "../../../../form/components/organisms/TerminalForm";
import CommentForm from "../../../../form/components/atoms/CommentForm";

const useStyles = makeStyles(() => ({
  returnButton: {
    margin: "0 auto",
    paddingTop: 8,
    paddingBottom: 10,
    paddingLeft: 42,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 24,
    },
  },
  addButton: {
    color: `${ADMIN_COLOR.primary}!important`,
    border: `1px solid ${ADMIN_COLOR.primary}`,
    backgroundColor: `${ADMIN_COLOR.base}!important`,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 24,
    },
    margin: "0 auto",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 0,
    "&:hover": {
      backgroundColor: `${ADMIN_COLOR.btnHover}!important`,
    },
  },
  addIcon: {
    position: "absolute",
    top: 8,
    left: 20,
  },
  buttonContainer: {
    margin: "0 auto",
    clear: "both",
    height: 100,
    width: 284,
    marginTop: 20,
  },
  returnIcon: {
    position: "absolute",
    top: 10,
    left: 31,
  },
  gridButtons: {
    float: "left",
  },
  Icon: {
    verticalAlign: "top",
    left: -2,
    top: 1,
    position: "relative",
  },
  tabs: {
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: ADMIN_COLOR.primary,
      color: ADMIN_COLOR.base,
    },
  },
  tab: {
    color: ADMIN_COLOR.primary,
    border: `3px solid ${ADMIN_COLOR.primary}`,
    marginRight: 4,
    minHeight: 35,
    borderBottom: "none",
    fontFamily: FONT,
    opacity: 1,
    borderRadius: "8px 8px 0px 0px",
    "& .MuiTab-wrapper": {
      display: "inline!important",
    },
    "& .MuiTab-labelIcon": {
      minHeight: 35,
    },
  },
  indicator: {
    display: "none",
  },
  message: {
    fontSize: "0.9em",
    fontWeight: "bold",
    fontFamily: FONT,
    color: ADMIN_COLOR.primary,
    marginBottom: 15,
  },
}));

const DealEditConfirmPage: React.FC<RouteComponentProps<{ id: string }>> = (
  props: RouteComponentProps<{ id: string }>
) => {
  const { match, history } = props;

  const { setLoading } = useContext(GlobalPopupContext);
  const { authHeader } = useContext(AuthContext);
  const [tabValue, setTabValue] = useState(0);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>("");
  const [open, setOpen] = useState(false);
  const loading = useRef(false);

  // 「現在のワークフローステータス」を取得
  const workflowStatus = useMemo(() => formStore.getWorkflowStatus(), []);
  // 「次のワークフローステータス」を取得
  const nextWorkflowStatus = useMemo(
    () => formStore.getNextWorkflowStatus(),
    []
  );

  const breadCrumbs = [
    { name: "TOP", link: "/dealer" },
    { name: "申請詳細", link: `/dealer/deal/${match.params.id}` },
    { name: "編集", link: `/dealer/deal/${match.params.id}/edit` },
    { name: "確認", link: "" },
  ];

  const isShowCheckSheet = useMemo(() => formStore.isShowChackSheet(), []);
  const isShowCheckList = useMemo(() => formStore.isShowCheckList(), []);

  const classes = useStyles();
  const moveButtonClasses = buttonStyles({
    width: 130,
    marginTop: 20,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const containerClasses = useContainerStyles();

  const handleUpdate = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      setLoading(true);
      loading.current = true;

      try {
        // ファイルアップロードAPI
        const fileIds = await formStore.uploadFormFiles(
          authHeader || "",
          "dealer",
          UPLOAD_FILE_TYPE.CUSTOMER
        );
        // POST用データを用意
        const request = formStore.getApiRequestDataForEditDeal(
          Number(match.params.id),
          "dealer"
        );
        if (!request) {
          setApiErrorMsg(
            "入力内容に問題が発生しました。入力画面に戻り確認してください。"
          );
          return;
        }
        // 新規申請編集API
        await deal.update(authHeader as string, match.params.id, {
          ...request,
          fileIds,
        });

        setLoading(false);
        loading.current = false;
        history.push(`/dealer/deal/${match.params.id}/edit/complete`);
      } catch (error) {
        setApiErrorMsg(getErrorMessage(error));
        setLoading(false);
        loading.current = false;
      }
    },
    [history, setLoading, match.params.id, authHeader]
  );

  const handleChange = useCallback((_, newValue: number) => {
    setTabValue(newValue);
  }, []);

  return (
    <>
      <StatusUpdateModal
        modalTitle={`ステータスを「${WORKFLOW_STATUS_MESSAGES_FOR_DEALER[workflowStatus]}」から「${WORKFLOW_STATUS_MESSAGES_FOR_DEALER[nextWorkflowStatus]}」に更新しますか？`}
        handleUpdate={handleUpdate}
        open={open}
        setOpen={setOpen}
        loadingOpen={loading.current}
        colors={ADMIN_COLOR}
      />
      <Dashboard
        title="申請管理"
        color={ADMIN_COLOR.base}
        backgroundColor={ADMIN_COLOR.primary}
        iconName="deal"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {apiErrorMsg && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {apiErrorMsg}
            </Alert>
          )}
          {/* 文言 */}
          <div className={classes.message}>
            {`タブをクリックすることで「${DEAL_FORM_TAB_NAMES.CONTRACTOR_INFO}」、「${DEAL_FORM_TAB_NAMES.CUSTOMER_INFO}」、「${DEAL_FORM_TAB_NAMES.TERMINAL_INFO}」`}
            {isShowCheckSheet && `、「${DEAL_FORM_TAB_NAMES.IMPORTANT_INFO}」`}
            {`、「${DEAL_FORM_TAB_NAMES.COMMENT_AND_MEMO}」の各入力内容が表示されます。`}
          </div>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            indicatorColor="primary"
            className={classes.tabs}
            classes={{ indicator: classes.indicator }}
          >
            {/* 契約者情報タブ */}
            <Tab
              label={` ${DEAL_FORM_TAB_NAMES.CONTRACTOR_INFO}`}
              id="simple-tab-0"
              aria-labelledby="simple-tab-0"
              icon={<BusinessCenterIcon className={classes.Icon} />}
              className={classes.tab}
            />
            {/* 加盟店情報タブ */}
            <Tab
              label={` ${DEAL_FORM_TAB_NAMES.CUSTOMER_INFO}`}
              id="simple-tab-1"
              aria-labelledby="simple-tab-1"
              icon={<StoreIcon className={classes.Icon} />}
              className={classes.tab}
            />
            {/* 端末情報タブ */}
            <Tab
              label={` ${DEAL_FORM_TAB_NAMES.TERMINAL_INFO}`}
              id="simple-tab-2"
              aria-labelledby="simple-tab-2"
              icon={<PhonelinkSetupIcon className={classes.Icon} />}
              className={classes.tab}
            />
            {/* 重要事項タブ */}
            {isShowCheckSheet && (
              <Tab
                label={` ${DEAL_FORM_TAB_NAMES.IMPORTANT_INFO}`}
                id="simple-tab-3"
                aria-labelledby="simple-tab-3"
                icon={<CheckBoxIcon className={classes.Icon} />}
                className={classes.tab}
              />
            )}
            {/* コメント・メモタブ */}
            <Tab
              label={` ${DEAL_FORM_TAB_NAMES.COMMENT_AND_MEMO}`}
              id="simple-tab-4"
              aria-labelledby="simple-tab-4"
              icon={<CommentIcon className={classes.Icon} />}
              className={classes.tab}
            />
            {/* チェックリストタブ */}
            {isShowCheckList && (
              <Tab
                label={` ${DEAL_FORM_TAB_NAMES.CHECK_LIST}`}
                id="simple-tab-5"
                aria-labelledby="simple-tab-5"
                icon={<CheckBoxIcon className={classes.Icon} />}
                className={classes.tab}
              />
            )}
          </Tabs>
          <form>
            {/* 契約者情報 */}
            <TabPanel
              value={tabValue}
              index={0}
              borderColor={ADMIN_COLOR.primary}
            >
              <ConfirmForm
                formCategory={FORM_CATEGORY.CONTRACTOR} // 契約者情報
                requiredKey="dealer"
                colors={ADMIN_COLOR}
                terminalGridId={undefined}
              />
            </TabPanel>
            {/* 加盟店情報 */}
            <TabPanel
              value={tabValue}
              index={1}
              borderColor={ADMIN_COLOR.primary}
            >
              <ConfirmForm
                formCategory={FORM_CATEGORY.CUSTOMER} // 加盟店情報
                requiredKey="dealer"
                colors={ADMIN_COLOR}
                terminalGridId={undefined}
              />
            </TabPanel>
            {/* 端末情報 */}
            <TabPanel
              value={tabValue}
              index={2}
              borderColor={ADMIN_COLOR.primary}
            >
              <TerminalForm
                type="confirm"
                requiredKey="dealer"
                colors={ADMIN_COLOR}
              />
            </TabPanel>
            {/* 重要事項 */}
            {isShowCheckSheet && (
              <TabPanel
                value={tabValue}
                index={3}
                borderColor={ADMIN_COLOR.primary}
              >
                <ConfirmForm
                  formCategory={FORM_CATEGORY.CHECK_SHEET} // 重要事項チェックシート
                  requiredKey="dealer"
                  colors={ADMIN_COLOR}
                  terminalGridId={undefined}
                />
              </TabPanel>
            )}
            {/* コメント・メモ */}
            <TabPanel
              value={tabValue}
              index={3 + (isShowCheckSheet ? 1 : 0)}
              borderColor={ADMIN_COLOR.primary}
            >
              <CommentForm dealId={match.params.id} colors={ADMIN_COLOR} />
            </TabPanel>
            {/* チェックリスト */}
            {isShowCheckList && (
              <TabPanel
                value={tabValue}
                index={4 + (isShowCheckSheet ? 1 : 0)}
                borderColor={ADMIN_COLOR.primary}
              >
                <ConfirmForm
                  formCategory={FORM_CATEGORY.CHECK_LIST}
                  requiredKey="dealer"
                  colors={ADMIN_COLOR}
                  terminalGridId={undefined}
                />
              </TabPanel>
            )}
          </form>
          <div className={classes.buttonContainer}>
            {/* 戻るボタン */}
            <Button
              className={clsx(
                moveButtonClasses.button,
                classes.returnButton,
                classes.gridButtons
              )}
              type="button"
              component={Link}
              to={`/dealer/deal/${match.params.id}/edit`}
              variant="contained"
            >
              <KeyboardReturnIcon className={classes.returnIcon} />
              戻る
            </Button>
            {/* 登録するボタン */}
            <Button
              type="submit"
              onClick={(e) =>
                // 現在のステータスと次のステータスが一致しない（=ワークフローが進む）場合はモーダルを開く
                nextWorkflowStatus !== workflowStatus
                  ? setOpen(true)
                  : handleUpdate(e)
              }
              className={clsx(moveButtonClasses.button, classes.addButton)}
              variant="contained"
              disabled={loading.current} // 非同期処理が行われている間はボタンを押せないようにする。(二重送信対策)
            >
              <AddIcon className={classes.addIcon} />
              登録する
            </Button>
          </div>
        </Container>
      </Dashboard>
    </>
  );
};

export default withRouter(DealEditConfirmPage);
