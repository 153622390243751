/**
 * 加盟店側-パスワード変更画面
 * メモ：自身のパスワードを変更する画面
 */
import React, { useContext, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { PasswordEditItemObject } from "../../types";

import { GUEST_COLOR } from "../../../../../constants/common";

import { AuthContext } from "../../../../../cognito/AuthContext";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import AlertMessage from "../../../../common/components/atoms/AuthAlertMsg";
import Loading from "../../../../common/components/molecules/Loading";
import GuestSettingPasswordEditForm from "../organisms/GuestSettingPasswordEditForm";

const useStyles = makeStyles(() => ({
  editButton: {
    margin: "0 auto",
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 10,
    paddingLeft: 68,
  },
  editIcon: {
    position: "absolute",
    top: 8,
    left: 30,
  },
}));

const breadCrumbs = [
  { name: "設定", link: "/guest/setting" },
  { name: "パスワード変更", link: "" },
];

const GuestSettingPasswordEditPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const classes = useStyles();
  const containerClasses = useContainerStyles();
  const buttonClasses = buttonStyles({
    width: 180,
    marginTop: 20,
    backgroundColor: GUEST_COLOR.primary,
  });

  const { history } = props;

  const [changePasswordError, setChangePasswordError] = useState("");
  const [loadingOpen, setLoadingOpen] = useState(false);
  const { changePassword } = useContext(AuthContext);

  const [passwordEditState, setPasswordEditState] =
    useState<PasswordEditItemObject>({
      currentPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    });

  const handleToCompletePage = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    setLoadingOpen(true);

    // CognitoAuthProviderのパスワード変更メソッドを実行
    const { result, message } = await changePassword(passwordEditState);

    setLoadingOpen(false);
    if (result) {
      // 成功した場合、パスワード変更完了画面に遷移
      setChangePasswordError("");
      history.push("/guest/setting/password/edit/complete");
      return;
    }
    setChangePasswordError(message);
  };

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Dashboard
        title="設定"
        color={GUEST_COLOR.base}
        backgroundColor={GUEST_COLOR.primary}
        iconName="setting"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {changePasswordError && (
            <AlertMessage errorMessage={changePasswordError} />
          )}
          <form>
            {/* 入力フォーム */}
            <GuestSettingPasswordEditForm
              passwordEditState={passwordEditState}
              setPasswordEditState={setPasswordEditState}
            />
            <Button
              type="submit"
              onClick={handleToCompletePage}
              className={clsx(buttonClasses.button, classes.editButton)}
              variant="contained"
              disabled={loadingOpen} // 二重送信対策
            >
              <EditIcon className={classes.editIcon} />
              変更する
            </Button>
          </form>
        </Container>
      </Dashboard>
    </>
  );
};

export default withRouter(GuestSettingPasswordEditPage);
