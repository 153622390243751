/**
 * フォーム関係-JSONのフォーム用のテキストエリア
 */
import React, { useEffect, useMemo, useState, useCallback } from "react";

import clsx from "clsx";
import { TextareaAutosize, makeStyles } from "@material-ui/core/";

import { TFormColumn, TRequiredKey } from "../../../common/components/types";

import { FONT } from "../../../../constants/common";

import formStore from "../../../../store/formStore";

import FormRemarks from "./FormRemarks";
import ValidationMsg from "./ValidateMsg";

const useStyles = makeStyles(() => ({
  textArea: {
    width: "100%",
    backgroundColor: "#f2f7fb",
    border: 0,
    fontSize: "1.0rem",
    fontFamily: FONT,
    minHeight: 52,
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderWidth: 2,
    },
    resize: "vertical",
  },
  textAreaDisable: {
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.09)",
    border: 0,
    fontSize: "1.0rem",
    fontFamily: FONT,
    minHeight: 52,
    resize: "none",
  },
  url: {
    fontFamily: FONT,
  },
  errorMsg: {
    color: "#f44336",
    fontSize: "0.75rem",
  },
  error: {
    border: "2px solid #f44336",
  },
  nonError: {
    borderWidth: 0,
  },
}));

type Props = {
  columnName: TFormColumn;
  requiredKey: TRequiredKey;
  terminalGridId: number | undefined;
  gridId: number | undefined;
  isChangeDeal?: boolean;
};

const CustomTextArea: React.FC<Props> = (props: Props) => {
  const { columnName, requiredKey, terminalGridId, gridId, isChangeDeal } =
    props;

  const [row, setRow] = useState(
    formStore.getFormRow(columnName, terminalGridId, gridId)
  );

  const disabled = useMemo(
    () => !row?.json.updateEnabled[requiredKey] || row.disabled,
    [row, requiredKey]
  );

  const classes = useStyles();

  useEffect(() => {
    const fn = () => {
      const r = formStore.getFormRow(columnName, terminalGridId, gridId);
      setRow(r && { ...r });
    };
    formStore.addListener(columnName, fn);

    return () => formStore.removeListener(columnName, fn);
  }, [columnName, terminalGridId, gridId]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target;
      formStore.updateContent(
        columnName,
        value,
        requiredKey,
        terminalGridId,
        gridId
      );
    },
    [columnName, requiredKey, terminalGridId, gridId]
  );

  // rowがなければ表示しない
  if (!row) return null;

  return (
    row && (
      <div>
        {row.json.fixedValue !== null ? (
          // 値がなければ単位等も表示しない
          <>{row.content ? row.content : ""}</>
        ) : (
          <>
            {/* テキストエリア */}
            <TextareaAutosize
              onChange={handleChange}
              className={clsx(
                disabled ? classes.textAreaDisable : classes.textArea,
                row.errors.length !== 0 ? classes.error : classes.nonError
              )}
              disabled={disabled}
              value={row.content}
            />
            {/* 項目の説明 */}
            <FormRemarks
              remarks={
                isChangeDeal ? row.json.changeFormRemarks : row.json.formRemarks
              }
            />
            {/* 項目の説明（URL） */}
            <a
              className={classes.url}
              href={row.json.url}
              target="_blank"
              rel="noopener noreferrer"
              tabIndex={row.json.url ? 0 : -1}
            >
              {row.json.url}
            </a>
            {/* バリデーションエラーメッセージ */}
            <div className={classes.errorMsg}>
              <ValidationMsg errors={row.errors} />
            </div>
          </>
        )}
      </div>
    )
  );
};

export default CustomTextArea;
