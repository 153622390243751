import { FORM_CATEGORY } from "../constants/form";
import { checkSingleContentValidation } from "../form/formValidation";
import {
  getDefaultValue,
  getFixedValue,
  jsonParseSafety,
} from "../utils/formData";
import {
  initDB,
  getItemFromDB,
  // 2023 / 7/13 下書き機能を一時廃止
  // setItemInDB,
  removeItemFromDB,
} from "../utils/localStorage";
import { cloneObject } from "../utils/object";
import {
  TFormCategory,
  TFormColumn,
  TFormData,
  TFormGroupData,
  TRequiredKey,
  TExternalDBData,
  TColumnToCategory,
  TDealTypeStr,
  TTerminalFormData,
  TContractorFormData,
  TCustomerFormData,
} from "../views/common/components/types";
import BaseStore from "./baseStore";
import { TWorkflowStatus } from "../constants/workflowStatus";
import runFixedNumberProcess from "../form/fixedNumberProcess/fixedNumberProcess";
import { DEAL_TYPE_STR_TO_NUM } from "../constants/deal";
import fileUtils from "../api/utils/fileUtils";
import {
  IRegisterChangeDealInput,
  IRegisterDealInput,
  IUpdateNewDealInput,
  IRegisterTerminalTerminateDealInput,
  IRegisterCustomerTerminateDealInput,
  IUpdateChangeDealInput,
  IUpdateTerminalTerminateDealInput,
  IUpdateCustomerTermiDealInput,
} from "../api/types";
import { convIndustrySmallCodeToRawCode } from "../form/fixedNumberProcess/industryClass";

class FormStore extends BaseStore {
  // 既存契約者情報取得に使う（API成功後null更新するのを忘れない）
  private contractorId: number | null;

  // 既存加盟店情報取得に使う（API成功後null更新するのを忘れない）
  private customerId: number | null;

  // 申請種別
  private dealType: TDealTypeStr | null;

  // フォーム情報
  private formData: TFormData | null;

  // カラム名からフォームカテゴリー取得する用のデータ
  private columnToCategory: TColumnToCategory | null;

  // フォームグループ情報
  private formGroupData: TFormGroupData[];

  // コメント・メモ
  private formComment: string;

  // 編集用申請種別
  private editDealType: TDealTypeStr | null;

  // 現在のワークフローステータス
  private workflowStatus: TWorkflowStatus;

  // 次のワークフローステータス
  private nextWorkflowStatus: TWorkflowStatus;

  // 編集用フォームバージョン
  private editFormVersion: string | null;

  private newFiles: {
    columnNames: string[];
    name: string;
    data: string;
    fileId?: number;
  }[];

  // 編集用マスター加盟店ID
  private masterCustomerId: number | null;

  // 編集用マスター加盟店フォーム情報ID
  private masterCustomerFormId: number | null;

  // 編集用申請基本フォームID
  private dealOtherFormId: number | null;

  // 編集用端末系IDの配列
  private terminalIds: {
    id: number;
    formId: number;
  }[];

  // マスター端末ID（API成功後null更新するのを忘れない）
  private masterTerminalId: number | null;

  // バリデーションエラー項目のカラム名一覧
  private errorColumns: TFormColumn[];

  // 変更申請時による変更カラム一覧
  private changeableColumnNames: {
    columnName: TFormColumn;
    originContent: string;
  }[];

  // 端末識別番号
  private termiIdentNum: number | null;

  constructor() {
    super();
    initDB("dealDraft", 1.0);
    this.contractorId = null;
    this.customerId = null;
    this.dealType = null;
    this.formData = null;
    this.columnToCategory = null;
    this.formGroupData = [];
    this.formComment = "";
    this.editDealType = null;
    this.workflowStatus = 10;
    this.nextWorkflowStatus = 10;
    this.editFormVersion = null;
    this.newFiles = [];
    this.masterCustomerId = null;
    this.masterCustomerFormId = null;
    this.dealOtherFormId = null;
    this.terminalIds = [];
    this.masterTerminalId = null;
    this.errorColumns = [];
    this.changeableColumnNames = [];
    this.termiIdentNum = null;
  }

  private toCategory = (columnName: TFormColumn) =>
    this.columnToCategory && this.columnToCategory[columnName];

  /// //////////// 既存契約者・既存加盟店関連 ///////////////////// ///

  /**
   * 既存契約者IDのSetter
   * @param id: 契約者ID
   */
  public setContractorId = (id: number | null) => {
    this.contractorId = id;
  };

  /**
   * 既存加盟店IDのSetter
   * @param id: 加盟店ID
   */
  public setCustomerId = (id: number | null) => {
    this.customerId = id;
  };

  /**
   * 申請種別のSetter
   * @param type: 申請種別
   */
  public setDealType = (type: TDealTypeStr | null) => {
    this.dealType = type;
  };

  /**
   * 既存契約者ID取得（API成功後、Setterでnull更新するのを忘れない）
   * @returns contractorId
   */
  public getContractorId = () => this.contractorId;

  /**
   * 既存加盟店ID取得（API成功後、Setterでnull更新するのを忘れない）
   * @returns customerId
   */
  public getCustomerId = () => this.customerId;

  /**
   * 申請種別取得
   * @returns
   */
  public getDealType = () => this.dealType;

  /// //////////// 申請編集関連 ///////////////////// ///
  /**
   * 編集用申請種別のSetter
   * @param type: 申請種別
   */
  public setEditDealType = (type: TDealTypeStr | null) => {
    this.editDealType = type;
  };

  /**
   * 編集用申請種別取得
   * @returns
   */
  public getEditDealType = () => this.editDealType;

  /**
   * 現在のワークフローステータスのSetter
   * @param status: ワークフローステータス
   */
  public setWorkflowStatus = (status: TWorkflowStatus) => {
    this.workflowStatus = status;
  };

  /**
   * 現在のワークフローステータス取得
   * @returns
   */
  public getWorkflowStatus = () => this.workflowStatus;

  /**
   * 次のワークフローステータスのSetter
   * @param status: ワークフローステータス
   */
  public setNextWorkflowStatus = (status: TWorkflowStatus) => {
    this.nextWorkflowStatus = status;
  };

  /**
   * 次のワークフローステータス取得
   * @returns
   */
  public getNextWorkflowStatus = () => this.nextWorkflowStatus;

  /**
   * 編集用フォームバージョンのSetter
   * @param version: フォームバージョン
   */
  public setEditFormVersion = (version: string | null) => {
    this.editFormVersion = version;
  };

  /**
   * 編集用フォームバージョン取得
   * @returns
   */
  public getEditFormVersion = () => this.editFormVersion;

  /**
   * 編集用マスター加盟店IDのSetter
   * @param id: マスター加盟店ID
   */
  public setMasterCustomerId = (id: number | null) => {
    this.masterCustomerId = id;
  };

  /**
   * 編集用マスター加盟店フォーム情報IDのSetter
   * @param id: マスター加盟店フォーム情報ID
   */
  public setMasterCustomerFormId = (id: number | null) => {
    this.masterCustomerFormId = id;
  };

  /**
   * 編集用申請基本フォームIDのSetter
   * @param id: 申請基本フォームID
   */
  public setDealOtherFormId = (id: number | null) => {
    this.dealOtherFormId = id;
  };

  /**
   * 編集用端末系IDのSetter
   * @param ids: 端末系IDの配列
   */
  public setTerminalIds = (ids: []) => {
    this.terminalIds = ids;
  };

  /**
   * マスター端末IDのSetter
   * @param id: 契約者ID
   */
  public setMasterTerminalId = (id: number | null) => {
    this.masterTerminalId = id;
  };

  /**
   * マスター端末ID取得
   * @returns masterTerminalId
   */
  public getMasterTerminalId = () => this.masterTerminalId;

  /**
   * 端末識別番号のSetter
   * @param termiIdentNum: 端末識別番号
   */
  public setTermiIdentNum = (termiIdentNum: number | null) => {
    this.termiIdentNum = termiIdentNum;
  };

  /// //////////// フォーム情報関連 Getter ///////////////////// ///

  /**
   * フォームの全項目データ取得
   */
  public getAllData = () => this.formData;

  /**
   * 解約系画面で使用する情報を取得
   * @description 編集用で利用する際は引数にtrueを入れること
   */
  public getTerminateDealInfoByDealType = (isEdit?: boolean) => {
    const dealType = isEdit ? this.editDealType : this.dealType;
    switch (dealType) {
      case "加盟店解約":
        return {
          dealType,
          pageUrl: "/customer",
          pageName: "加盟店管理・検索",
          showPageUrl: `/customer/${this.customerId}`,
          formCategory: FORM_CATEGORY.CUSTOMER,
        };
      case "端末解約":
        return {
          dealType,
          pageUrl: "/terminal",
          pageName: "端末管理・検索",
          showPageUrl: `/terminal/${this.masterTerminalId}`,
          formCategory: FORM_CATEGORY.TERMINAL,
        };
      default:
        throw new Error("dealType error");
    }
  };

  /**
   * APIリクエスト用に構造変更してデータ取得
   */
  public getApiRequestFormData = (
    category: TFormCategory,
    requiredKey: TRequiredKey
  ) => {
    if (!this.formData) return {};
    const ret: Partial<{ [key in TFormColumn]: string }> = {};

    const formData = this.formData[category];
    if (!formData) return {};

    formData.forEach((d) => {
      const columns = Object.keys(d.form) as TFormColumn[];
      columns.forEach((c) => {
        const val = d.form[c];
        if (val[0].json.showEnabled[requiredKey]) {
          if (c === "industrySmallClass") {
            const { content } = val[0];
            if (content) {
              const code = Object.keys(jsonParseSafety(content))[0];
              const rep = convIndustrySmallCodeToRawCode(code);
              const v = content.replace(code, rep);
              ret[c] = v;
            }
          } else {
            ret[c] = val[0].content;
          }
        }
      });
    });

    return ret;
  };

  /**
   * 新規申請APIリクエスト用に構造変換してデータ取得
   */
  public getApiRequestDataForNewDeal = (requiredKey: TRequiredKey) => {
    if (!this.formData) return null;

    const customerForm = this.getApiRequestFormData(
      FORM_CATEGORY.CUSTOMER,
      requiredKey
    );

    const terminals = this.formData[FORM_CATEGORY.TERMINAL]
      ? this.formData[3].map((data) => {
          const form: Partial<{ [key in TFormColumn]: string }> = {};
          const columns = Object.keys(data.form) as TFormColumn[];
          columns.forEach((c) => {
            const val = data.form[c];
            if (val[0].json.showEnabled[requiredKey]) {
              form[c] = val[0].content;
            }
          });

          return { dealTerminalForm: form };
        })
      : [];

    const dealOtherForm = {
      ...this.getApiRequestFormData(FORM_CATEGORY.CHECK_SHEET, requiredKey),
      ...this.getApiRequestFormData(FORM_CATEGORY.DEAL_MANEGE, requiredKey),
    };
    return {
      dealType: this.dealType
        ? DEAL_TYPE_STR_TO_NUM[this.dealType].toString()
        : "",
      contractorId: this.contractorId,
      formVersion: this.formData.formVersion || "",
      customer: {
        id:
          this.dealType === "端末増設"
            ? this.customerId || undefined
            : undefined,
        customerForm,
      },
      terminals,
      dealOtherForm,
      fileIds: [],
    } as IRegisterDealInput;
  };

  /**
   * 変更申請登録APIリクエスト用に構造変換してデータ取得
   */
  public getApiRequestDataForNewChangeDeal = (requiredKey: TRequiredKey) => {
    if (!this.formData) return null;

    const dealContractorForm = this.getChangeableColumns(
      this.getApiRequestFormData(FORM_CATEGORY.CONTRACTOR, requiredKey)
    );
    const customerForm = this.getChangeableColumns(
      this.getApiRequestFormData(FORM_CATEGORY.CUSTOMER, requiredKey)
    );
    const terminal = this.getChangeableColumns(
      this.getApiRequestFormData(FORM_CATEGORY.TERMINAL, requiredKey)
    );
    return {
      dealType: this.dealType
        ? DEAL_TYPE_STR_TO_NUM[this.dealType].toString()
        : "",
      contractorId: this.contractorId,
      formVersion: this.formData.formVersion || "",
      contractor:
        this.dealType === "契約者変更"
          ? {
              dealContractorForm,
            }
          : undefined,
      customer:
        this.dealType === "加盟店変更"
          ? {
              masterCustomerId: this.masterCustomerId,
              dealCustomerForm: customerForm,
            }
          : undefined,
      terminal:
        this.dealType === "端末変更" && this.termiIdentNum
          ? {
              masterCustomerId: this.masterCustomerId,
              dealTerminalForm: {
                ...terminal,
                termiIdentNum: this.termiIdentNum.toString(),
              },
            }
          : undefined,
    } as IRegisterChangeDealInput;
  };

  /**
   * 変更申請更新（編集）APIリクエスト用に構造変換してデータ取得
   */
  public getApiRequestDataForEditChangeDeal = (requiredKey: TRequiredKey) => {
    if (!this.formData) return null;
    const dealContractorForm = this.getChangeableColumns(
      this.getApiRequestFormData(FORM_CATEGORY.CONTRACTOR, requiredKey)
    );
    const customerForm = this.getChangeableColumns(
      this.getApiRequestFormData(FORM_CATEGORY.CUSTOMER, requiredKey)
    );
    const terminal = this.getChangeableColumns(
      this.getApiRequestFormData(FORM_CATEGORY.TERMINAL, requiredKey)
    );
    return {
      formVersion: this.formData.formVersion || "",
      nextWorkflowStatus: String(this.nextWorkflowStatus),
      contractorId: this.contractorId,
      contractor:
        this.editDealType === "契約者変更"
          ? {
              dealContractorForm,
            }
          : undefined,
      customer:
        this.editDealType === "加盟店変更"
          ? {
              masterCustomerId: this.masterCustomerId,
              dealCustomerForm: customerForm,
            }
          : undefined,
      terminal:
        this.editDealType === "端末変更"
          ? {
              masterCustomerId: this.masterCustomerId,
              dealTerminalForm: terminal,
            }
          : undefined,
    } as IUpdateChangeDealInput;
  };

  /**
   * 変更申請、加盟店解約申請、端末解約申請ワークフローステータス進行APIリクエスト用に構造変換してデータ取得
   */
  public getApiRequestDataForChangeOrTerminateDealWorkflow = () => {
    if (!this.formData) return null;

    return {
      formVersion: this.formData.formVersion || "",
      nextWorkflowStatus: String(this.nextWorkflowStatus),
    };
  };

  /**
   * 解約申請登録APIリクエスト用に構造変換してデータ取得
   * @description 端末解約と加盟店解約はAPIが別のため、dealTypeに応じて返す値を変える
   */
  public getApiRequestDataForNewTerminateDeal = (requiredKey: TRequiredKey) => {
    if (!this.formData) return null;

    // 端末解約の場合
    if (this.dealType === "端末解約") {
      const terminalForm = this.getApiRequestFormData(
        FORM_CATEGORY.TERMINAL,
        requiredKey
      );

      return {
        formVersion: this.formData.formVersion || "",
        masterTerminalId: this.masterTerminalId,
        terminalForm,
      } as IRegisterTerminalTerminateDealInput;
    }
    // 加盟店解約の場合
    if (this.dealType === "加盟店解約") {
      const customerForm = this.getApiRequestFormData(
        FORM_CATEGORY.CUSTOMER,
        requiredKey
      );

      return {
        formVersion: this.formData.formVersion || "",
        masterCustomerId: this.customerId,
        customerForm,
      } as IRegisterCustomerTerminateDealInput;
    }
    return null;
  };

  /**
   * 解約申請更新（編集）APIリクエスト用に構造変換してデータ取得
   */
  public getApiRequestDataForEditTerminateDeal = (
    requiredKey: TRequiredKey
  ) => {
    if (!this.formData) return null;

    // 端末解約の場合
    if (this.editDealType === "端末解約") {
      const terminalForm = this.getApiRequestFormData(
        FORM_CATEGORY.TERMINAL,
        requiredKey
      );

      return {
        nextWorkflowStatus: String(this.nextWorkflowStatus),
        formVersion: this.editFormVersion || "",
        contractorId: this.contractorId,
        masterCustomerId: this.masterCustomerId,
        terminalForm,
      } as IUpdateTerminalTerminateDealInput;
    }
    // 加盟店解約の場合
    if (this.editDealType === "加盟店解約") {
      const customerForm = this.getApiRequestFormData(
        FORM_CATEGORY.CUSTOMER,
        requiredKey
      );

      return {
        nextWorkflowStatus: String(this.nextWorkflowStatus),
        formVersion: this.editFormVersion || "",
        contractorId: this.contractorId,
        masterCustomerId: this.masterCustomerId,
        customerForm,
      } as IUpdateCustomerTermiDealInput;
    }
    return null;
  };

  /**
   * 新規申請編集APIリクエスト用に構造変換してデータ取得
   */
  public getApiRequestDataForEditDeal = (
    dealId: number,
    requiredKey: TRequiredKey
  ) => {
    if (!this.formData) return null;

    const customerForm = this.getApiRequestFormData(
      FORM_CATEGORY.CUSTOMER,
      requiredKey
    );

    const terminals = this.formData[FORM_CATEGORY.TERMINAL]
      ? this.formData[3].map((data, index) => {
          const form: Partial<{ [key in TFormColumn]: string }> = {};
          const columns = Object.keys(data.form) as TFormColumn[];
          columns.forEach((c) => {
            const val = data.form[c];
            form[c] = val[0].content;
          });

          return {
            id: this.terminalIds[index]?.id, // 申請端末ID（新規追加でない場合は必須）
            dealTerminalForm: {
              ...form,
              id: this.terminalIds[index]?.formId, // 申請端末フォーム情報ID（新規追加でない場合は必須）
            },
          };
        })
      : [];

    const dealOtherForm = {
      ...this.getApiRequestFormData(FORM_CATEGORY.CHECK_SHEET, requiredKey),
      ...this.getApiRequestFormData(FORM_CATEGORY.DEAL_MANEGE, requiredKey),
      ...this.getApiRequestFormData(FORM_CATEGORY.CHECK_LIST, requiredKey),
    };

    return {
      id: dealId,
      nextWorkflowStatus: String(this.nextWorkflowStatus),
      contractorId: this.contractorId,
      agreedTerms: undefined,
      formVersion: this.editFormVersion || "",
      fileIds: [],
      customer: {
        id: this.masterCustomerId,
        // customerFormは端末新規の場合のみ必須
        customerForm:
          this.editDealType === "端末新規"
            ? { ...customerForm, id: this.masterCustomerFormId } // idはマスター加盟店フォーム情報ID
            : undefined,
        terminals,
      },
      dealOtherForm: { ...dealOtherForm, id: this.dealOtherFormId }, // idは申請基本フォーム情報ID
    } as IUpdateNewDealInput;
  };

  /**
   * formGroupNameIdの一覧取得
   * @returns formGroupNameId[]
   */
  public getFormGroupNameIds = () =>
    this.formGroupData.map((d) => d.formGroupNameId);

  /**
   * formCategoryで絞り込んだformGroupNameIdの一覧取得
   * @param formCategory
   * @returns formGroupNameId[]
   */
  public getFilteredFormGroupNameIds = (formCategory: TFormCategory) =>
    this.formGroupData
      .filter((d) => d.formCategory === formCategory)
      .map((d) => d.formGroupNameId);

  /**
   * 指定のformGroupの項目のカラム名一覧、gridFlagを取得
   * @param formGroupNameId
   */
  public getFormGroupData = (formGroupNameId: number) =>
    this.formGroupData.find(
      (d) =>
        d.formGroupNameId === formGroupNameId &&
        // グループ内項目が全て非表示対象の場合は返さない
        d.forms
          .map((f) => {
            const row = this.getFormRow(f.columnName);
            return !row || !!row.isHide;
          })
          .includes(false)
    );

  /**
   * 指定のformGroupの項目のカラム名一覧、gridFlagを取得
   * 変更申請用
   * @param formGroupNameId
   */
  public getFormGroupDataForChangeRequest = (
    formGroupNameId: number,
    isConfirmPage = false
  ) =>
    this.formGroupData.find(
      (d) =>
        d.formGroupNameId === formGroupNameId &&
        // グループ内項目が全て非表示対象or変更リスエストが偽の場合は返さない
        d.forms
          .map((f) => {
            const row = this.getFormRow(f.columnName);
            const showRow = isConfirmPage
              ? this.isChangeableColumnName(f.columnName)
              : true;
            return !row || !!row.isHide || !row.json.changeRequest || !showRow;
          })
          .includes(false)
    );

  /**
   * フォームの単項目データ取得
   * @param columnName: カラム名
   */
  public getFormRow = (
    columnName: TFormColumn,
    terminalGridId?: number,
    gridId?: number
  ) => {
    const row = this.getFormRowAllGrid(columnName, terminalGridId);
    return row && row[gridId || 0];
  };

  /**
   * フォームの単項目の全グリッドデータ取得（）
   * @param columnName: カラム名
   */
  public getFormRowAllGrid = (
    columnName: TFormColumn,
    terminalGridId?: number
  ) => {
    const category = this.toCategory(columnName);
    if (!this.formData || !category) return undefined;

    const formData = this.formData[category];

    return formData.length > 0
      ? formData[terminalGridId || 0].form[columnName]
      : undefined;
  };

  /**
   * 非表示項目確認
   * @param columnName カラム名
   * @param requiredKey TRequiredKey
   * @returns boolean
   */
  public isHideRow = (
    columnName: TFormColumn,
    requiredKey: TRequiredKey,
    terminalGridId?: number
  ) => {
    const row = this.getFormRowAllGrid(columnName, terminalGridId);
    return !row || !row[0].json.showEnabled[requiredKey] || row[0].isHide;
  };

  /**
   * 変更リクエストの値による非表示項目確認
   * @param columnName カラム名
   * @param requiredKey TRequiredKey
   * @returns boolean
   */
  public isHideRowForChangeRequest = (
    columnName: TFormColumn,
    requiredKey: TRequiredKey,
    terminalGridId?: number
  ) => {
    const row = this.getFormRowAllGrid(columnName, terminalGridId);
    return (
      !row ||
      !row[0].json.showEnabled[requiredKey] ||
      row[0].isHide ||
      !row[0].json.changeRequest
    );
  };

  /**
   * 更新可能項目確認
   * @param columnName カラム名
   * @param requiredKey TRequiredKey
   * @returns boolean
   */
  public isUpdateEnableRow = (
    columnName: TFormColumn,
    requiredKey: TRequiredKey
  ) => {
    const row = this.getFormRowAllGrid(columnName);
    return row && row[0].json.updateEnabled[requiredKey];
  };

  /**
   * バリデーションエラーフォーカス対象確認
   * @param columnName カラム名
   * @param terminalGridId 端末グリッドID
   * @param gridId グリッドID
   * @returns boolean
   */
  public focusError = (
    columnName: TFormColumn,
    terminalGridId?: number,
    gridId?: number
  ) => {
    const row = this.getFormRow(columnName, terminalGridId, gridId);
    return row && row.errors.length > 0 && this.errorColumns[0] === columnName;
  };

  /**
   * バリデーションエラーで一番最初の項目があるタブを取得
   * @returns number（formCategory or 0）
   */
  public getFocusErrorCategory = () => {
    if (this.errorColumns.length <= 0 || !this.columnToCategory) return 0;

    const category = this.columnToCategory[this.errorColumns[0]];
    return (category as number) || 0;
  };

  /**
   * フォームのコメント・メモを取得
   * @returns: string
   */
  public getFormComment = () => this.formComment;

  /**
   * 審査時チェックリストタブ表示制御
   */
  public isShowCheckList = () => {
    const customerType = this.getFormRow("customerType");

    // JTB支店の場合は非表示
    return (
      !!customerType &&
      !!customerType.content &&
      !customerType.content.includes("JTB支店")
    );
  };

  /**
   * 重要事項チェックシートタブ表示制御
   */
  public isShowChackSheet = () => {
    const customerType = this.getFormRow("customerType");

    // JTB支店の場合は非表示
    return (
      !!customerType &&
      !!customerType.content &&
      !customerType.content.includes("JTB支店")
    );
  };

  /// //////////// フォーム情報更新関連 ///////////////////// ///

  /**
   * フォームの全項目上書き（リセットなど）
   * @param formData: フォームデータ
   */
  public setFormData = (
    formData: TFormData,
    groupData: TFormGroupData[],
    columnToCategory: TColumnToCategory,
    isTermination?: boolean
  ) => {
    this.formData = formData;
    this.formGroupData = groupData;
    this.columnToCategory = columnToCategory;
    this.newFiles = [];

    // 解約申請の場合は固定処理強制全実行をしない
    if (!isTermination) {
      this.forceUpdateFixedNumberProcess();
    }

    this.emit("form");
  };

  /**
   * 外部DBの値設定
   * @param columnName: カラム名
   * @param gridId: gridId
   * @param externalDBData: 外部DBデータ
   * @returns
   */
  public setExternalDBData = (
    columnName: TFormColumn,
    terminalGridId?: number,
    gridId?: number,
    externalDBData?: TExternalDBData
  ) => {
    const row = this.getFormRow(columnName, terminalGridId, gridId);
    if (!row) return;

    // 値なしの場合などは"-"表示
    row.jtbApproved = externalDBData?.jtbApproved || "-";
    row.centerReflected = externalDBData?.centerReflected || "-";
    row.centerToBeReflected = externalDBData?.centerToBeReflected || "-";
  };

  /**
   * 項目の入力値更新
   * @param columnName: カラム名
   * @param content: 入力値
   * @param requiredKey: requiredKey
   * @param gridId?: gridId
   */
  public updateContent = (
    columnName: TFormColumn,
    // eslint-disable-next-line
    content: any,
    requiredKey: TRequiredKey,
    terminalGridId?: number,
    gridId?: number,
    externalDBData?: TExternalDBData
  ) => {
    const row = this.getFormRow(columnName, terminalGridId, gridId);
    if (!row) return;
    row.content =
      typeof content !== "string" ? JSON.stringify(content) : content;
    checkSingleContentValidation(row, requiredKey);

    // 固定番号処理
    this.updateFixedNumberProcess(
      columnName,
      requiredKey,
      terminalGridId,
      gridId
    );

    // 外部DBデータをセット
    if (externalDBData) {
      this.setExternalDBData(
        columnName,
        terminalGridId,
        gridId,
        externalDBData
      );
    }

    this.emit(columnName);

    // 項目更新で何かしたい時用のトリガー発火
    this.emit("updatedContent");
  };

  /**
   * 複数項目の入力値更新
   * @param contents: { [key in TFormColumn]?: any } APIレスポンスデータなど
   * @param requiredKey: requiredKey
   */
  /* eslint-disable */
  public updateContents = (
    contents: {
      [key in TFormColumn]?: any;
    },
    requiredKey: TRequiredKey,
    terminalGridId?: number,
    externalDBData?: {
      [key in TFormColumn]?: TExternalDBData | TExternalDBData[];
    }
  ) => {
    const keys = Object.keys(contents);
    keys.forEach((key) => {
      const k = key as TFormColumn;
      const val = contents[k];
      const dbData = externalDBData ? externalDBData[k] : undefined;
      if (Array.isArray(val)) {
        val.forEach((v, index) => {
          // contentが配列（グリッド項目）の場合外部DBのデータも配列のはず？
          const d = Array.isArray(dbData) ? dbData[index] : dbData;
          this.updateContent(k, v, requiredKey, terminalGridId, index, d);
        });
      } else {
        // contentが配列でないなら外部DBデータも配列ではないはず
        const d = Array.isArray(dbData) ? dbData[0] : dbData;
        this.updateContent(k, val, requiredKey, terminalGridId, undefined, d);
      }
    });
  };
  /* eslint-enable */

  /**
   * コメント・メモの更新
   * @param comment: string
   */
  public setFormComment = (comment: string) => {
    this.formComment = comment;
    this.emit("comment");
  };

  /**
   * 変更申請時による変更有無の更新
   */
  public setChangeableColumnName = (
    columnName: TFormColumn,
    checked: boolean
  ) => {
    const exists = this.changeableColumnNames.some(
      (item) => item.columnName === columnName
    );

    if (checked && !exists) {
      const row = this.getFormRow(columnName);
      this.changeableColumnNames.push({
        columnName,
        originContent: row ? row.content : "",
      });
    }
    if (!checked && exists) {
      const originContent = this.changeableColumnNames.find(
        (item) => item.columnName === columnName
      )?.originContent;
      this.changeableColumnNames = this.changeableColumnNames.filter(
        (item) => item.columnName !== columnName
      );
      this.updateContent(columnName, originContent, "dealer");
    }
  };

  /**
   * 変更申請時による変更有無のカラム一覧リセット
   */
  public resetChangeableColumnNames = () => {
    this.changeableColumnNames = [];
  };

  /**
   * 変更申請時による変更有無のカラム表示項目制御
   */
  public isChangeableColumnName = (columnName: TFormColumn) =>
    this.changeableColumnNames.some((item) => item.columnName === columnName);

  /**
   * 変更有のカラム名を取得
   */
  public getChangeableColumnNames = () => this.changeableColumnNames;

  /**
   * 変更有のカラムを取得
   */
  private getChangeableColumns = (
    formData: Partial<{ [key in TFormColumn]: string }>
  ): Partial<{ [key in TFormColumn]: string }> =>
    Object.fromEntries(
      Object.entries(formData).filter(([k]) =>
        this.changeableColumnNames.some((item) => item.columnName === k)
      )
    );

  /**
   * 変更申請編集用に更新
   */
  public updateContentsForChangeDealEdt = (
    changeableData: TContractorFormData | TCustomerFormData | TTerminalFormData,
    requiredKey: TRequiredKey
  ) => {
    Object.keys(changeableData).forEach((key) => {
      const formKey = key as TFormColumn;
      this.setChangeableColumnName(formKey, true);
    });
    this.updateContents(changeableData, requiredKey);
  };

  /// //////////// ファイル項目関連 ///////////////////// ///

  public setNewFile = (
    columnName: string,
    name: string,
    data: string,
    fileId?: string
  ) => {
    this.removeNewFile(columnName);

    const find = this.newFiles.find((f) => f.name === name);

    if (find) {
      // 同名ファイルを複数回位アップロードしないように
      find.columnNames.push(columnName);
    } else {
      const fileData: {
        columnNames: string[];
        name: string;
        data: string;
        fileId?: number;
      } = {
        columnNames: [columnName],
        name,
        data,
      };
      if (fileId) {
        fileData.fileId = parseInt(fileId, 10);
      }
      this.newFiles.push(fileData);
    }
  };

  public removeNewFile = (columnName: string) => {
    const find = this.newFiles.find((f) => f.columnNames.includes(columnName));
    if (!find) return;

    find.columnNames = find.columnNames.filter((c) => c !== columnName);

    // columnNamesなくなったらnewFilesからも削除
    if (find.columnNames.length <= 0) {
      this.newFiles = this.newFiles.filter((f) => f.name !== find.name);
    }
  };

  public getNewFileIds = () =>
    this.newFiles.map((f) => f.fileId || -1).filter((n) => n > 0);

  public uploadFormFiles = async (
    authHeader: string,
    requiredKey: TRequiredKey,
    fileType: string,
    terminalGridId?: number
  ): Promise<number[]> => {
    const api = fileUtils.getUploadFileApi(authHeader, this.newFiles, fileType);

    const fileIds: number[] = []; // 'let' を 'const' に変更

    await Promise.all(api)
      .then((res) => {
        res.forEach((r) => {
          if (r.columnNames && r.columnNames.length > 0) {
            r.columnNames.forEach((c) => {
              const find = this.newFiles.find((f) => f.columnNames.includes(c));
              if (find) find.fileId = r.fileId;

              this.updateContent(
                c as TFormColumn,
                r.fileId.toString(),
                requiredKey,
                terminalGridId
              );
              fileIds.push(r.fileId);
            });
          }
        });
      })
      .catch((err) => {
        throw err;
      });

    return fileIds;
  };

  /// //////////// 固定番号処理関連 ///////////////////// ///

  /**
   * 固定番号処理実行
   * @param columnName: カラム名
   * @param requiredKey: requiredKey
   * @param terminalGridId: terminalGridId
   * @param gridId?: gridId
   */
  private updateFixedNumberProcess = (
    columnName: TFormColumn,
    requiredKey: TRequiredKey,
    terminalGridId?: number,
    gridId?: number
  ) => {
    if (!this.formData || !this.columnToCategory) return;

    // 固定番号処理実行
    const updates = runFixedNumberProcess(
      columnName,
      this.formData,
      terminalGridId
    );

    const updatedGroups: string[] = [];

    // 固定番号処理結果で更新
    updates.forEach((u) => {
      const row = this.getFormRowAllGrid(u.column, terminalGridId);
      if (row) {
        row.forEach((r) => {
          const newR = r;
          if (u.isHide !== undefined) newR.isHide = u.isHide;
          if (u.isRequired !== undefined) newR.isRequired = u.isRequired;
          if (u.disabled !== undefined) newR.disabled = u.disabled;
          if (u.formTypeOptions) {
            newR.json.formTypeOptions = u.formTypeOptions;
          }
        });

        const find = updatedGroups.find(
          (g) => g === row[0].json.formGroupNameId.toString()
        );
        if (!find) {
          updatedGroups.push(row[0].json.formGroupNameId.toString());
        }
      }

      this.updateContent(
        u.column,
        u.content,
        requiredKey,
        terminalGridId,
        gridId
      );
    });

    // 更新が入ったグループを発火
    updatedGroups.forEach((g) => {
      this.emit(g);
    });
  };

  /**
   * 固定番号処理強制全実行（フォーム初期表示時用）
   */
  public forceUpdateFixedNumberProcess = () => {
    if (!this.formData) return;
    const updates = runFixedNumberProcess("", this.formData, undefined, true);

    // 固定番号処理結果で更新
    updates.forEach((u) => {
      const row = this.getFormRowAllGrid(u.column);
      if (row) {
        row.forEach((r) => {
          const newR = r;
          newR.isHide = u.isHide;
          newR.isRequired = u.isRequired;
          newR.disabled = u.disabled;
          if (u.formTypeOptions) {
            newR.json.formTypeOptions = u.formTypeOptions;
          }
          newR.content = u.content;
        });
      }
    });
  };

  /// //////////// 端末グリッド関連 ///////////////////// ///

  /**
   * 端末グリッドコピー
   * @param terminalGridId: 端末グリッドID
   */
  public terminalGridCopy = (terminalGridId: number) => {
    if (!this.formData) return;
    const terminalForms = this.formData[FORM_CATEGORY.TERMINAL];
    const clone = cloneObject(terminalForms[terminalGridId]);

    terminalForms.splice(terminalGridId + 1, 0, clone);

    terminalForms.forEach((info, idx) => {
      const i = info;
      i.terminalGridId = idx;
    });

    this.emit("terminal");
  };

  /**
   * 端末グリッド追加
   * @param terminalGridId: 端末グリッドID
   */
  public terminalGridPlus = (terminalGridId: number) => {
    if (!this.formData) return;
    const terminalForms = this.formData[FORM_CATEGORY.TERMINAL];
    const clone = cloneObject(terminalForms[terminalGridId]);

    const keys = Object.keys(clone.form);
    keys.forEach((key) => {
      const row = clone.form[key as TFormColumn];
      row[0].content =
        getFixedValue(row[0].json) || getDefaultValue(row[0].json);
      row[0].errors = [];

      row.splice(1);
    });

    terminalForms.splice(terminalGridId + 1, 0, clone);

    terminalForms.forEach((info, idx) => {
      const i = info;
      i.terminalGridId = idx;
    });
    const updatedGroups: string[] = [];

    keys.forEach((key) => {
      if (this.formData) {
        const updates = runFixedNumberProcess(
          key as TFormColumn,
          this.formData,
          terminalGridId + 1
        );

        updates.forEach((u) => {
          const findRow = this.getFormRowAllGrid(u.column, terminalGridId + 1);
          if (findRow) {
            findRow.forEach((r) => {
              const newR = r;
              if (u.isHide !== undefined) newR.isHide = u.isHide;
              if (u.isRequired !== undefined) newR.isRequired = u.isRequired;
              if (u.disabled !== undefined) newR.disabled = u.disabled;
              if (u.formTypeOptions) {
                newR.json.formTypeOptions = u.formTypeOptions;
              }
            });

            const find = updatedGroups.find(
              (g) => g === findRow[0].json.formGroupNameId.toString()
            );
            if (!find) {
              updatedGroups.push(findRow[0].json.formGroupNameId.toString());
            }

            this.emit(u.column);
          }
        });
      }
    });

    this.emit("terminal");
  };

  /**
   * 端末グリッド削除
   * @param terminalGridId: 端末グリッドID
   */
  public terminalGridMinus = (terminalGridId: number) => {
    if (!this.formData) return;
    const terminalForms = this.formData[FORM_CATEGORY.TERMINAL];

    terminalForms.splice(terminalGridId, 1);

    terminalForms.forEach((info, idx) => {
      const i = info;
      i.terminalGridId = idx;
    });

    this.emit("terminal");
  };

  /**
   * 端末グリッド数取得
   * @returns: number
   */
  public getTerminalGridCount = () =>
    this.formData ? this.formData[FORM_CATEGORY.TERMINAL].length : 0;

  /// //////////// グリッド関連 ///////////////////// ///

  /**
   * グリッド追加
   * @param formGroupNameId: formGroupNameId
   * @param gridId: 追加ボタンのグリッドID
   * @param requiredKey: requiredKey
   */
  public gridPlus = (
    formGroupNameId: number,
    terminalGridId: number,
    gridId: number,
    requiredKey: TRequiredKey
  ) => {
    const groupData = this.getFormGroupData(formGroupNameId);
    if (!groupData) return;

    let gridCount = 0;

    const forms = groupData.forms.map((f) => {
      const formData = this.getFormRowAllGrid(f.columnName, terminalGridId);

      // 項目のグリッド追加対応
      if (formData) {
        const obj = formData[gridId];
        // clone
        const clone = cloneObject(obj);
        // 入力値、エラー初期化
        clone.content = "";
        clone.errors = [];

        // insert
        formData.splice(gridId + 1, 0, clone);

        // gridIDの更新
        formData.forEach((d, idx) => {
          const data = d;
          data.gridId = idx;
        });
        gridCount = formData.length;
      }

      const ret = {
        column: f.columnName,
        content: formData?.map((d) => d.content) || [],
      };
      return ret;
    });

    // グリッド数の更新
    groupData.gridCount = gridCount;
    // グリッド項目の更新
    // eslint-disable-next-line
    const updateData: { [key in TFormColumn]?: any } = {};
    forms.forEach((d) => {
      updateData[d.column] = d.content;
    });
    this.updateContents(
      // eslint-disable-next-line
      updateData as unknown as { [key in TFormColumn]: any },
      requiredKey,
      terminalGridId
    );

    // グリッド更新通知
    this.emit("grid");
  };

  /**
   * グリッド削除
   *@param formGroupNameId: formGroupNameId
   * @param gridId: 削除ボタンのグリッドID
   * @param requiredKey: requiredKey
   */
  public gridMinus = (
    formGroupNameId: number,
    terminalGridId: number,
    gridId: number,
    requiredKey: TRequiredKey
  ) => {
    const groupData = this.getFormGroupData(formGroupNameId);
    if (!groupData) return;

    let gridCount = 0;

    const forms = groupData.forms.map((f) => {
      const formData = this.getFormRowAllGrid(f.columnName, terminalGridId);
      // グリッド数1以下の場合は削除しない
      if (formData && formData.length > 1) {
        formData.splice(gridId, 1);
        formData.forEach((d, idx) => {
          const data = d;
          data.gridId = idx;
        });
        gridCount = formData.length;
      }

      const ret = {
        column: f.columnName,
        content: formData?.map((d) => d.content) || [],
      };
      return ret;
    });

    // グリッド数の更新
    groupData.gridCount = gridCount;
    // グリッド項目の更新
    // eslint-disable-next-line
    const updateData: { [key in TFormColumn]?: any } = {};
    forms.forEach((d) => {
      updateData[d.column] = d.content;
    });
    this.updateContents(
      // eslint-disable-next-line
      updateData as unknown as { [key in TFormColumn]: any },
      requiredKey,
      terminalGridId
    );
    // グリッド更新通知
    this.emit("grid");
  };

  /**
   * グリッド削除
   *@param formGroupNameId: formGroupNameId
   * @param gridId: クリアボタンのグリッドID
   * @param requiredKey: requiredKey
   */
  public gridClear = (
    formGroupNameId: number,
    terminalGridId: number,
    gridId: number,
    requiredKey: TRequiredKey
  ) => {
    const groupData = this.getFormGroupData(formGroupNameId);
    if (!groupData) return;

    groupData.forms.forEach((f) => {
      this.updateContent(f.columnName, "", requiredKey, terminalGridId, gridId);
    });
  };

  /// //////////// バリデーション関連 ///////////////////// ///

  /**
   * 全項目のバリデーションチェック
   * @param requiredKey: requiredKey
   * @returns true: OK, false: NG
   */
  public validationAll = (requiredKey: TRequiredKey) => {
    // エラー情報初期化
    this.errorColumns = [];

    if (!this.formData) return false;
    const { formData } = this;

    const results: boolean[] = [];
    const formKeys = Object.keys(this.formData).filter(
      (k) => k !== "formVersion"
    );

    // formCategoryでのループ
    formKeys.forEach((fKey) => {
      // terminalGridIdでのループ
      formData[parseInt(fKey, 10) as TFormCategory].forEach((info) => {
        const keys = Object.keys(info.form);
        // columnNameでのループ
        keys.forEach((key) => {
          const row = info.form[key as TFormColumn];
          // gridIdでのループ
          row.forEach((r) => {
            const result = checkSingleContentValidation(r, requiredKey);

            // エラーの場合エラー項目リストに追加
            if (!result) this.errorColumns.push(key as TFormColumn);

            results.push(result);
          });
          this.emit(row[0].column);
        });
      });
    });

    return !results.includes(false);
  };

  /**
   * formCategory に絞ったバリデーションチェック
   * @param requiredKey: requiredKey
   * @param formCategory: formCategory
   * @returns true: OK, false: NG
   */
  public validationFormCategory = (
    requiredKey: TRequiredKey,
    formCategory: number
  ) => {
    // エラー情報初期化
    this.errorColumns = [];

    if (!this.formData) return false;
    const { formData } = this;

    const results: boolean[] = [];
    const formKeys = Object.keys(this.formData).filter(
      (k) => k !== "formVersion"
    );

    // formCategoryでのループ
    formKeys.forEach((fKey) => {
      // terminalGridIdでのループ
      if (parseInt(fKey, 10) === formCategory) {
        formData[parseInt(fKey, 10) as TFormCategory].forEach((info) => {
          const keys = Object.keys(info.form);
          // columnNameでのループ
          keys.forEach((key) => {
            const row = info.form[key as TFormColumn];
            // gridIdでのループ
            row.forEach((r) => {
              const result = checkSingleContentValidation(r, requiredKey);

              // エラーの場合エラー項目リストに追加
              if (!result) this.errorColumns.push(key as TFormColumn);

              results.push(result);
            });
            this.emit(row[0].column);
          });
        });
      }
    });

    return !results.includes(false);
  };
  /// //////////// 下書き関連 ///////////////////// ///

  /**
   * 下書き保存
   * @param key: DBのkey
   */
  // eslint-disable-next-line
  public saveDraft = async (key: string) => {
    // 2023 / 7/13 下書き機能を一時廃止
    return true;
    // if (!this.formData) return false;

    // const saveData = cloneObject(this.formData);

    // for (let i = 1; i < 7; i += 1) {
    //   const form = saveData[i as TFormCategory];
    //   if (form && form.length > 0) {
    //     form.forEach((f) => {
    //       const keys = Object.keys(f.form);
    //       keys.forEach((k) => {
    //         f.form[k as TFormColumn].forEach((r) => {
    //           if (r.json.formType === "file") {
    //             const row = r;
    //             row.content = "";
    //           }
    //         });
    //       });
    //     });
    //   }
    // }

    // const ret = await setItemInDB(key, JSON.stringify(saveData));
    // localStorage.setItem(key, "true");
    // return ret;
  };

  /**
   * 下書き読み込み
   * @param key: DBのkey
   */
  public loadDraft = async (key: string): Promise<TFormData | null> => {
    const dataString = await getItemFromDB(key);
    if (!dataString) {
      return null;
    }

    // 2023 / 7/13 下書き機能を一時廃止
    this.deleteDraft(key); // 下書きデータがあったら削除
    return null;
    // const data: TFormData = jsonParseSafety(dataString as string);
    // this.formData = data;

    // return data;
  };

  /**
   * 下書き削除
   * @param key: DBのkey
   */
  // eslint-disable-next-line
  public deleteDraft = async (key: string) => {
    localStorage.removeItem(key);
    const ret = await removeItemFromDB(key);
    return ret;
  };
}

// シングルトンにする
const formStore = new FormStore();
export default formStore;
