/**
 * JTB側-精算明細書検索結果リスト
 * メモ：検索結果の一覧を表示する
 */
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import clsx from "clsx";
import {
  Button,
  FormControl,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import {
  buttonStyles,
  formStyles,
  paginationStyles,
} from "../../../../common/components/styles";

import {
  IBillSearchResponse,
  IBillList,
  IBillData,
} from "../../../../../api/types";
import { BillSearchCondition } from "../../types";

import {
  ADMIN_COLOR,
  FONT,
  SEARCH_PER_PAGE,
} from "../../../../../constants/common";
import { DOWNLOAD_FILE_TYPE, BLOB_TYPE } from "../../../../../constants/bill";

import { getErrorMessage } from "../../../../../utils/error";
import bill from "../../../../../api/bill";
import { AuthContext } from "../../../../../cognito/AuthContext";
import { convBase64toBlob, downloadBlobFile } from "../../../../../utils/file";

import SearchCountText from "../../../../common/components/atoms/SearchCountText";
import ConfirmModal from "../../../../common/components/atoms/ConfirmModal";
import BillStatusChip from "../../../../common/components/atoms/BillStatusChip";

const useStyles = makeStyles(() => ({
  table: {
    width: "auto",
  },
  cell: {
    fontFamily: FONT,
    padding: 1,
    backgroundColor: ADMIN_COLOR.base,
    color: "#000!important",
    border: `1px solid ${ADMIN_COLOR.primary}`,
    width: 200,
    fontSize: "0.9em",
    height: 38,
  },
  thCell: {
    fontFamily: FONT,
    color: ADMIN_COLOR.primary,
    border: `1px solid ${ADMIN_COLOR.primary}!important`,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: ADMIN_COLOR.base,
    width: 400,
    position: "relative",
    fontSize: "0.9em",
    height: 38,
  },
  detail: {
    width: "100px!important",
  },
  messageHeader: {
    fontFamily: FONT,
    textAlign: "center",
    fontSize: "1.1rem",
    marginTop: "40px",
    marginBottom: "10px",
  },
  detailButton: {
    width: 80,
    padding: "4px 10px",
    margin: "10px 20px",
    backgroundColor: ADMIN_COLOR.primary,
    color: ADMIN_COLOR.base,
    borderRadius: 0,
  },
  billButton: {
    width: 80,
    padding: "4px 10px",
    margin: "10px",
    backgroundColor: ADMIN_COLOR.primary,
    color: ADMIN_COLOR.base,
    borderRadius: 0,
    display: "inline",
  },
  form: {
    marginTop: "5px!important",
  },
}));

interface Props {
  resultsRows: IBillSearchResponse;
  conditionState: BillSearchCondition;
  setApiErrorMsg: React.Dispatch<React.SetStateAction<string>>;
  setLoadingOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getBills: (currentPage: string) => void;
}

const BillSearchResult: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const formClasses = formStyles({
    color: ADMIN_COLOR.base,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const buttonClasses = buttonStyles({
    width: 213,
    marginTop: 15,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const paginationClasses = paginationStyles({
    color: ADMIN_COLOR.base,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const {
    resultsRows,
    conditionState,
    setApiErrorMsg,
    setLoadingOpen,
    getBills,
  } = props;

  const { authHeader } = useContext(AuthContext);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [fileId, setFileId] = useState<string>("");

  const handleModalOpen = (id: number) => {
    setFileId(id.toString());
    setModalOpen(true);
  };

  const onDownload = async () => {
    setModalOpen(false);
    setLoadingOpen(true);
    try {
      // 精算明細書ダウンロード
      await bill
        .download(authHeader as string, fileId, DOWNLOAD_FILE_TYPE.BILL)
        .then((res) => {
          const data = res.data.result.file as string;
          // base64 -> blob
          const blob = convBase64toBlob(data, BLOB_TYPE.BILL);
          // download
          downloadBlobFile(blob, res.data.result.filename);
          setApiErrorMsg("");
        });

      // ダウンロード成功時は一覧再取得（API実行）
      await getBills(conditionState.pageNumber);
      setApiErrorMsg("");
    } catch (error) {
      setApiErrorMsg(getErrorMessage(error));
      throw error;
    } finally {
      setLoadingOpen(false);
    }
  };

  const customerColumnNames = ["加盟店コード", "加盟店名"] as const;

  return (
    <>
      <ConfirmModal
        modalTitle="本当にダウンロードしてよろしいですか？"
        actionMethod={onDownload}
        open={modalOpen}
        setOpen={setModalOpen}
        colors={ADMIN_COLOR}
      />
      {resultsRows.result !== undefined && resultsRows.totalCount === 0 && (
        <>
          <div className={classes.messageHeader}>
            {` ${"該当する加盟店、精算明細書はありません。"}`}
          </div>
        </>
      )}
      {resultsRows.result !== undefined && resultsRows.totalCount !== 0 && (
        <>
          {/* 検索結果件数 */}
          <SearchCountText count={resultsRows.totalCount as number} />
          {/* 検索結果 */}
          <FormControl className={clsx(formClasses.form, classes.form)}>
            <TableContainer>
              <Table
                className={clsx(formClasses.table, classes.table)}
                aria-label="simple table"
              >
                <TableHead className={formClasses.th}>
                  <TableRow>
                    <TableCell
                      align="center"
                      className={clsx(classes.thCell)}
                      colSpan={customerColumnNames.length}
                    >
                      加盟店
                    </TableCell>
                    <TableCell
                      align="center"
                      className={clsx(classes.thCell)}
                      colSpan={
                        (resultsRows.result?.yyyymmn as string[]).length + 1
                      }
                    >
                      ダウンロード
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 加盟店の項目名 */}
                    {customerColumnNames.map((column) => (
                      <TableCell
                        key={column}
                        align="center"
                        className={classes.thCell}
                      >
                        {column}
                      </TableCell>
                    ))}
                    {/* ダウンロードの項目名 */}
                    {resultsRows.result?.yyyymmn.map((column) => (
                      <TableCell
                        key={column}
                        align="center"
                        className={classes.thCell}
                      >
                        {/* 表示用に4文字目（yyyyとmmの間）にスラッシュを入れる */}
                        {`${column.slice(0, 4)}/${column.slice(4)}`}
                      </TableCell>
                    ))}
                    <TableCell
                      align="center"
                      className={clsx(classes.thCell, classes.detail)}
                    >
                      操作
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(resultsRows.result?.bills as IBillList[]).map(
                    (resultsRow) => (
                      <TableRow key={resultsRow.masterCustomerId}>
                        {/* 加盟店コード */}
                        <TableCell align="center" className={classes.cell}>
                          {resultsRow.customerCode || "-"}
                        </TableCell>
                        {/* 加盟店名 */}
                        <TableCell align="center" className={classes.cell}>
                          {resultsRow.customerName || "-"}
                        </TableCell>
                        {/* 各年月回の精算明細書情報 */}
                        {resultsRows.result?.yyyymmn.map((date) => (
                          <TableCell
                            align="center"
                            className={classes.cell}
                            key={date}
                          >
                            {/* 1,3回目の場合はハイフンを表示 */}
                            {(date.endsWith("1") || date.endsWith("3")) && (
                              <>
                                <BillStatusChip isDone={false} />-
                              </>
                            )}
                            {/* 2,4回目の場合、精算明細書がなければ利用なしを表示 */}
                            {(date.endsWith("2") || date.endsWith("4")) &&
                              !(resultsRow[date] as IBillData).billFile && (
                                <>
                                  <BillStatusChip isDone={false} />
                                  利用なし
                                </>
                              )}
                            {/* 2,4回目の場合、精算明細書があればボタンを表示 */}
                            {(date.endsWith("2") || date.endsWith("4")) &&
                              !!(resultsRow[date] as IBillData).billFile && (
                                <>
                                  {/* 精算明細書 or データファイルがダウンロードされていれば「済」 */}
                                  {!!Number(
                                    (resultsRow[date] as IBillData).billStatus
                                  ) ||
                                  !!Number(
                                    (resultsRow[date] as IBillData).dataStatus
                                  ) ? (
                                    <BillStatusChip isDone />
                                  ) : (
                                    <BillStatusChip isDone={false} />
                                  )}
                                  <Button
                                    className={clsx(
                                      buttonClasses.button,
                                      classes.billButton
                                    )}
                                    variant="contained"
                                    onClick={() =>
                                      handleModalOpen(
                                        (resultsRow[date] as IBillData)
                                          .billFile as number
                                      )
                                    }
                                  >
                                    明細書
                                  </Button>
                                </>
                              )}
                          </TableCell>
                        ))}
                        {/* 詳細ボタン */}
                        <TableCell
                          align="center"
                          className={clsx(classes.cell, classes.detail)}
                        >
                          <Button
                            className={clsx(
                              buttonClasses.button,
                              classes.detailButton
                            )}
                            variant="contained"
                            component={Link}
                            to={{
                              pathname: `/dealer/data/bill/${resultsRow.masterCustomerId}`,
                              state: resultsRow.customerCode,
                            }}
                          >
                            詳細
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* ページネーション */}
            <div className={paginationClasses.parent}>
              <Pagination
                count={Math.ceil(
                  (resultsRows.totalCount as number) / SEARCH_PER_PAGE
                )}
                className={clsx(
                  paginationClasses.pagination,
                  paginationClasses.pageCurrent
                )}
                onChange={(e: React.ChangeEvent<unknown>, num) =>
                  getBills(String(num))
                }
                page={Number(conditionState.pageNumber)}
              />
            </div>
          </FormControl>
        </>
      )}
    </>
  );
};

export default React.memo(BillSearchResult);
