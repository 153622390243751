import React from "react";
import { Route, Switch } from "react-router-dom";
import ChangeDealNewPage from "./pages/ChangeDealNewPage";
import ChangeDealNewConfirmPage from "./pages/ChangeDealNewConfirmPage";
import ChangeDealNewCompletePage from "./pages/ChangeDealNewCompletePage";
import ChangeDealShowPage from "./pages/ChangeDealShowPage";
import ChangeDealEditPage from "./pages/ChangeDealEditPage";
import ChangeDealEditConfirmPage from "./pages/ChangeDealEditConfirmPage";
import ChangeDealEditCompletePage from "./pages/ChangeDealEditCompletePage";

const ChangeDealHooks: React.FC = () => {
  return (
    <Switch>
      {/* 変更申請情報入力画面 */}
      <Route
        path="/guest/deal/change/new"
        render={() => <ChangeDealNewPage />}
        exact
      />
      {/* 変更申請情報確認画面 */}
      <Route
        path="/guest/deal/change/confirm"
        render={() => <ChangeDealNewConfirmPage />}
        exact
      />
      {/* 変更申請情報完了画面 */}
      <Route
        path="/guest/deal/change/complete/:id"
        render={() => <ChangeDealNewCompletePage />}
        exact
      />
      {/* 変更申請詳細画面 */}
      <Route
        path="/guest/deal/change/:id"
        render={() => <ChangeDealShowPage />}
        exact
      />
      {/* 申請編集画面 */}
      <Route
        path="/guest/deal/change/:id/edit"
        render={() => <ChangeDealEditPage />}
        exact
      />
      {/* 申請編集確認画面 */}
      <Route
        path="/guest/deal/change/:id/edit/confirm"
        render={() => <ChangeDealEditConfirmPage />}
        exact
      />
      {/* 申請編集完了画面 */}
      <Route
        path="/guest/deal/change/:id/edit/complete"
        render={() => <ChangeDealEditCompletePage />}
        exact
      />
    </Switch>
  );
};

export default ChangeDealHooks;
