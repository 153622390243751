// APIで返ってくる型は業務外の付与情報がついているのでフロントでは型を明確に定義しない。
import axios from "axios";
import { customerSearchParameters } from "./types";
import {
  createAPIPromise,
  createLoadingPromise,
  createParamStr,
  loadingUrls,
} from "./utils/common";

/**
 * 加盟店情報検索API
 * @param authHeader 認証トークン
 * @param contractorId 契約者ID
 * @param companyName 会社名
 * @param customerCode 加盟店コード
 * @param customerName 加盟店名
 * @param customerPhoneNum 加盟店電話番号
 * @param customerType 加盟店種別
 * @param businessCode 事業者コード
 * @param customerTerminationFlag 解約済みを含むか
 * @param pageNumber ページ番号
 */
const search = async (
  authHeader: string,
  contractorId: string,
  companyName: string,
  customerCode: string,
  customerName: string,
  customerPhoneNum: string,
  customerType: string,
  businessCode: string,
  customerTerminationFlag: string,
  pageNumber: string
  // eslint-disable-next-line
): Promise<any> => {
  const params: customerSearchParameters = {
    customerTerminationFlag,
    pageNumber,
  };
  params.contractorId = contractorId === "" ? undefined : contractorId;
  params.companyName = companyName === "" ? undefined : companyName;
  params.customerCode = customerCode === "" ? undefined : customerCode;
  params.customerName = customerName === "" ? undefined : customerName;
  params.customerPhoneNum =
    customerPhoneNum === "" ? undefined : customerPhoneNum;
  params.customerType = customerType === "" ? undefined : customerType;
  params.businessCode = businessCode === "" ? undefined : businessCode;

  const path = `/customers`;
  const paramStr = createParamStr(params);
  const loadingKey = `${path}?${paramStr}`;
  if (loadingUrls.includes(loadingKey)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(loadingKey);

  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    loadingKey
  );
};

/**
 * 個別加盟店情報取得API（JTB版用）
 * @param authHeader: 認証トークン
 * @param customerId: 加盟店id
 * @returns
 */
const show = async (authHeader: string, customerId: string) => {
  const path = `/customers/${customerId}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    path
  );
};

export default {
  show,
  search,
};
