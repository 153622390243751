/**
 * JTB側-変更申請編集画面
 * メモ：
 */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import clsx from "clsx";
import { Alert, AlertTitle } from "@material-ui/lab";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { withRouter, Link, RouteComponentProps } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";
import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import {
  TContractorFormColumn,
  TCustomerFormColumn,
  TFormColumn,
  TJsonRowObject,
  TTerminalFormColumn,
} from "../../../../common/components/types";
import contractors from "../../../../../api/contractors";
import { getErrorMessage } from "../../../../../utils/error";
import { ADMIN_COLOR, FONT } from "../../../../../constants/common";
import { GlobalPopupContext, PathContext } from "../../../../../App";
import formStore from "../../../../../store/formStore";
import deal from "../../../../../api/deal";
import {
  getContractorContents,
  getCustomerContents,
  getTerminalContents,
  json2FormData,
} from "../../../../../utils/formData";
import { AuthContext } from "../../../../../cognito/AuthContext";
import preloadFormContent from "../../../../../api/utils/preUtils";
import ShowItemHeading from "../../../../common/components/atoms/ShowItemHeading";
import customer from "../../../../../api/customer";
import terminal from "../../../../../api/terminal";
import useChangeDealInfoByDealType from "../../../../../utils/changeDeal";
import ChangeDealNewForm from "../../../../form/components/organisms/ChangeDealNewForm";
import changeDeal from "../../../../../api/changeDeal";

const useStyles = makeStyles(() => ({
  returnButton: {
    margin: "0 auto",
    paddingTop: 8,
    paddingBottom: 10,
    paddingLeft: 42,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 24,
    },
  },
  checkButton: {
    margin: "0 auto",
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 8,
    color: `${ADMIN_COLOR.primary}!important`,
    border: `1px solid ${ADMIN_COLOR.primary}`,
    backgroundColor: `${ADMIN_COLOR.base}!important`,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 16,
    },
    "&:hover": {
      backgroundColor: `${ADMIN_COLOR.btnHover}!important`,
    },
  },
  returnIcon: {
    position: "absolute",
    top: 10,
    left: 31,
  },
  checkIcon: {
    position: "absolute",
    top: 8,
    left: 20,
  },
  messageContainer: {
    position: "relative",
    minHeight: 80,
  },
  message: {
    fontSize: "0.9em",
    fontWeight: "bold",
    fontFamily: FONT,
    color: ADMIN_COLOR.primary,
    marginBottom: 15,
  },
  buttonContainer: {
    margin: "0 auto",
    clear: "both",
    height: 100,
    width: 284,
    marginTop: 20,
  },
  gridButtons: {
    float: "left",
  },
  tableContainer: {
    border: `3px solid ${ADMIN_COLOR.primary}`,
    maxHeight: 663,
    overflowY: "auto",
    padding: "20px 10px",
  },
}));
const ChangeDealEditPage: React.FC<RouteComponentProps<{ id: string }>> = (
  props: RouteComponentProps<{ id: string }>
) => {
  const { history, match } = props;
  const { authHeader } = useContext(AuthContext);
  const { setLoading, setSnackbar /* , setConfirmModal */ } =
    useContext(GlobalPopupContext);
  const { prevPath } = useContext(PathContext);
  const [init, setInit] = useState(false);
  const [errorMessage, setError] = useState("");
  const [displaySettings] = useState({
    dateReflected: "", // 反映日
    dateToBeReflected: "", // 反映予定日
  });
  const { dealType, contractorId, customerId, masterTerminalId, dealTypeInfo } =
    useChangeDealInfoByDealType("dealer");
  const breadCrumbs = useMemo(
    () => [
      { name: "TOP", link: "/dealer" },
      {
        name: "申請詳細",
        link: `/dealer/deal/change/${match.params.id}`,
      },
      { name: "編集", link: "" },
    ],
    [match.params.id]
  );

  const getJson = useCallback(async () => {
    if (!authHeader) return;
    setLoading(true);
    try {
      if (
        prevPath.includes(`/dealer/deal/change/${match.params.id}/edit/confirm`)
      ) {
        // 確認画面からの遷移時はAPIで取り直さない
        setError("");
        setLoading(false);
        setInit(true);
      } else {
        // Json取得
        // 申請詳細情報取得API
        const [ret, showChangeDealApiRet] = await Promise.all([
          deal.getFormMaster(authHeader, "latest"),
          changeDeal.editChangeDeal(authHeader || "", match.params.id),
        ]);
        // presetForm.json取得&マッピングデータ化
        await preloadFormContent(authHeader, "latest");

        const data = json2FormData(ret.data.result as TJsonRowObject[]);
        formStore.setFormData(
          data.formData,
          data.formGroupData,
          data.columnToCategory
        );

        if (contractorId && dealType === "契約者変更") {
          // 契約者情報取得、反映
          const showApiRet = await contractors.show(
            authHeader,
            contractorId.toString()
          );
          const showData = getContractorContents(
            showApiRet.data.result.contractors[0].masterContractorForms
          );
          formStore.updateContents(showData.updateData, "dealer");
          formStore.updateContentsForChangeDealEdt(
            showChangeDealApiRet.data.result.contractor.contractorForm
              .dealFormContent,
            "dealer"
          );
          formStore.setMasterCustomerId(null);

          // 他DB情報の設定
          Object.keys(showData.externalDBData).forEach((columnName) => {
            formStore.setExternalDBData(
              columnName as TFormColumn,
              0, // 端末情報ではないのでterminalGridIdは0固定
              0, // gridIdもJsonによるgrid制御がないため0固定
              showData.externalDBData[columnName as TContractorFormColumn]
            );
          });
        }
        if (customerId && dealType === "加盟店変更") {
          // 契約者情報取得、反映
          const showApiRet = await customer.show(
            authHeader,
            customerId.toString()
          );
          const showData = getCustomerContents(
            showApiRet.data.result.masterCustomers[0].masterCustomerForms
          );
          formStore.updateContents(showData.updateData, "dealer");
          formStore.updateContentsForChangeDealEdt(
            showChangeDealApiRet.data.result.customer.customerForm
              .dealFormContent,
            "dealer"
          );
          formStore.setMasterCustomerId(
            showApiRet.data.result.masterCustomers[0].id
          );

          // 他DB情報の設定
          Object.keys(showData.externalDBData).forEach((columnName) => {
            formStore.setExternalDBData(
              columnName as TFormColumn,
              0, // 端末情報ではないのでterminalGridIdは0固定
              0, // gridIdもJsonによるgrid制御がないため0固定
              showData.externalDBData[columnName as TCustomerFormColumn]
            );
          });
        }
        if (masterTerminalId && dealType === "端末変更") {
          // 端末識別番号は表示不要のため削除（申請の必須項目のため値が入ってきてしまうため）
          // 端末識別番号は性質上変更不可なので変更対象となることは無い
          delete showChangeDealApiRet.data.result.terminal.terminalForm
            .dealFormContent.termiIdentNum;
          // 端末情報報取得、反映
          const showApiRet = await terminal.show(
            authHeader,
            masterTerminalId.toString()
          );
          const showData = getTerminalContents(
            showApiRet.data.result.masterTerminals[0].masterTerminalForms
          );
          formStore.updateContents(showData.updateData, "dealer");
          formStore.updateContentsForChangeDealEdt(
            showChangeDealApiRet.data.result.terminal.terminalForm
              .dealFormContent,
            "dealer"
          );
          formStore.setContractorId(
            showApiRet.data.result.masterTerminals[0].contractorId
          );
          formStore.setMasterCustomerId(
            showApiRet.data.result.masterTerminals[0].masterCustomerId
          );

          // 他DB情報の設定
          Object.keys(showData.externalDBData).forEach((columnName) => {
            formStore.setExternalDBData(
              columnName as TFormColumn,
              0, // 端末情報だが、詳細はgridではなく1台ずつなのでterminalGridIdは0固定
              0, // gridIdもJsonによるgrid制御がないため0固定
              showData.externalDBData[columnName as TTerminalFormColumn]
            );
          });
        }
        setError("");
        setLoading(false);
        setInit(true);
      }
      // eslint-disable-next-line
    } catch (error: any) {
      setError(getErrorMessage(error));
      setLoading(false);
      setInit(true);
    }
  }, [
    setLoading,
    authHeader,
    prevPath,
    contractorId,
    customerId,
    masterTerminalId,
    match.params.id,
    dealType,
  ]);

  const classes = useStyles();
  const moveButtonClasses = buttonStyles({
    width: 130,
    marginTop: 20,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const containerClasses = useContainerStyles();

  // Jsonデータ取得
  useEffect(() => {
    getJson();
    // eslint-disable-next-line
  }, []);

  const handleToConfirmPage = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    // ボタンクリック以外（エンターキー押下など）の場合は何もしない
    if (e.detail !== 1) return;

    if (formStore.getChangeableColumnNames().length === 0) {
      setSnackbar({
        openProps: true,
        message: "変更項目を選択して下さい。",
      });
      return;
    }

    if (formStore.validationFormCategory("dealer", dealTypeInfo.formCategory)) {
      history.push(`/dealer/deal/change/${match.params.id}/edit/confirm`);
    } else {
      setSnackbar({
        openProps: true,
        message: "入力不備のフィールドがあります。",
      });
    }
  };

  return (
    <Dashboard
      title={`${dealType}申請`}
      color={ADMIN_COLOR.base}
      backgroundColor={ADMIN_COLOR.primary}
      iconName="deal"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        {init && (
          <>
            <div className={classes.messageContainer}>
              <div className={classes.message}>
                変更したい項目をチェックして、変更して下さい。
              </div>
            </div>
            {/* 表示見出し */}
            <ShowItemHeading
              dateReflected={displaySettings.dateReflected}
              dateToBeReflected={displaySettings.dateToBeReflected}
              colors={ADMIN_COLOR}
            />
            <form>
              <div className={classes.tableContainer}>
                {/* 詳細表示 */}
                <ChangeDealNewForm
                  formCategory={dealTypeInfo.formCategory}
                  requiredKey="dealer"
                  colors={ADMIN_COLOR}
                  terminalGridId={0}
                />
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  className={clsx(
                    moveButtonClasses.button,
                    classes.returnButton,
                    classes.gridButtons
                  )}
                  type="button"
                  component={Link}
                  to={`/dealer/deal/change/${match.params.id}`}
                  variant="contained"
                >
                  <KeyboardReturnIcon className={classes.returnIcon} />
                  戻る
                </Button>
                <Button
                  type="submit"
                  onClick={handleToConfirmPage}
                  className={clsx(
                    moveButtonClasses.button,
                    classes.checkButton
                  )}
                  variant="contained"
                >
                  <CheckIcon className={classes.checkIcon} />
                  確認する
                </Button>
              </div>
            </form>
          </>
        )}
      </Container>
    </Dashboard>
  );
};

export default withRouter(ChangeDealEditPage);
