/**
 * JTB側-登録申請情報入力内容確認画面
 */
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { withRouter, Link, RouteComponentProps } from "react-router-dom";

import clsx from "clsx";
import { makeStyles, Container, Button, Tabs, Tab } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import StoreIcon from "@material-ui/icons/Store";

import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import {
  DEAL_FORM_TAB_NAMES,
  FORM_CATEGORY,
} from "../../../../../constants/form";
import { ADMIN_COLOR, FONT } from "../../../../../constants/common";
import { UPLOAD_FILE_TYPE } from "../../../../../constants/deal";

import { getErrorMessage } from "../../../../../utils/error";
import { GlobalPopupContext } from "../../../../../App";
import deal from "../../../../../api/deal";
import formStore from "../../../../../store/formStore";
import { AuthContext } from "../../../../../cognito/AuthContext";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import TabPanel from "../../../../common/components/atoms/TabPanel";
import ConfirmForm from "../../../../form/components/organisms/ConfirmForm";
import TerminalForm from "../../../../form/components/organisms/TerminalForm";

const useStyles = makeStyles(() => ({
  returnButton: {
    margin: "0 auto",
    paddingTop: 8,
    paddingBottom: 10,
    paddingLeft: 42,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 24,
    },
  },
  checkButton: {
    margin: "0 auto",
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 8,
    color: `${ADMIN_COLOR.primary}!important`,
    border: `1px solid ${ADMIN_COLOR.primary}`,
    backgroundColor: `${ADMIN_COLOR.base}!important`,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 16,
    },
    "&:hover": {
      backgroundColor: `${ADMIN_COLOR.btnHover}!important`,
    },
  },
  returnIcon: {
    position: "absolute",
    top: 10,
    left: 31,
  },
  addIcon: {
    position: "absolute",
    top: 6,
    left: 17,
  },
  messageContainer: {
    position: "relative",
  },
  settingIcon: {
    verticalAlign: "top",
    left: -2,
    top: 1,
    position: "relative",
  },
  Icon: {
    verticalAlign: "top",
    left: -2,
    top: 1,
    position: "relative",
  },
  tabs: {
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: ADMIN_COLOR.primary,
      color: ADMIN_COLOR.base,
    },
  },
  tab: {
    color: ADMIN_COLOR.primary,
    border: `3px solid ${ADMIN_COLOR.primary}`,
    marginRight: 4,
    minHeight: 35,
    borderBottom: "none",
    fontFamily: FONT,
    opacity: 1,
    borderRadius: "8px 8px 0px 0px",
    "& .MuiTab-wrapper": {
      display: "inline!important",
    },
    "& .MuiTab-labelIcon": {
      minHeight: 35,
    },
  },
  indicator: {
    display: "none",
  },
  message: {
    fontSize: "0.9em",
    fontWeight: "bold",
    fontFamily: FONT,
    color: ADMIN_COLOR.primary,
    marginBottom: 15,
  },
  buttonContainer: {
    margin: "0 auto",
    clear: "both",
    height: 100,
    width: 284,
    marginTop: 20,
  },
  gridButtons: {
    float: "left",
  },
}));

const DealNewConfirmPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const { history } = props;

  const { authHeader } = useContext(AuthContext);
  const { setLoading } = useContext(GlobalPopupContext);
  const [apiErrorMsg, setApiErrorMsg] = useState("");
  const [tabValue, setTabValue] = useState(0);

  const dealType = useMemo(() => formStore.getDealType() || "端末新規", []);
  const loading = useRef(false);

  const returnPage = useMemo(() => {
    switch (dealType) {
      case "端末新規":
        return { url: "/dealer/contractor", name: "契約者管理・検索" };
      case "端末増設":
        return { url: "/dealer/customer", name: "加盟店管理・検索" };
      default:
        return { url: "/dealer", name: "TOP" };
    }
  }, [dealType]);
  const breadCrumbs = useMemo(
    () => [
      { name: returnPage.name, link: returnPage.url },
      { name: `${dealType}申請`, link: "/dealer/deal/new" },
      { name: "確認", link: "" },
    ],
    [dealType, returnPage]
  );

  const isShowCheckSheet = useMemo(() => formStore.isShowChackSheet(), []);

  const classes = useStyles();
  const moveButtonClasses = buttonStyles({
    width: 130,
    marginTop: 20,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const containerClasses = useContainerStyles();

  const handleChange = useCallback((_, newValue: number) => {
    setTabValue(newValue);
  }, []);

  // 登録処理
  const handleRegister = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      setLoading(true);
      loading.current = true;

      try {
        // ファイルアップロードAPI
        const fileIds = await formStore.uploadFormFiles(
          authHeader || "",
          "dealer",
          UPLOAD_FILE_TYPE.CUSTOMER
        );
        // POST用データを用意
        const request = formStore.getApiRequestDataForNewDeal("dealer");
        if (!request) {
          setApiErrorMsg(
            "入力内容に問題が発生しました。入力画面に戻り確認してください。"
          );
          return;
        }
        // 新規申請登録API
        const ret = await deal.store(authHeader || "", {
          ...request,
          fileIds,
        });
        setLoading(false);
        loading.current = false;
        history.push(`/dealer/deal/complete/${ret.data.result.dealId}`);
      } catch (err) {
        setApiErrorMsg(getErrorMessage(err));
        setLoading(false);
        loading.current = false;
      }
    },
    [history, setLoading, authHeader]
  );

  return (
    <Dashboard
      title={`${dealType}申請`}
      color={ADMIN_COLOR.base}
      backgroundColor={ADMIN_COLOR.primary}
      iconName="deal"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {apiErrorMsg && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {apiErrorMsg}
          </Alert>
        )}
        <div className={classes.messageContainer}>
          {/* 文言 */}
          <div className={classes.message}>
            {`タブをクリックすることで「${DEAL_FORM_TAB_NAMES.CONTRACTOR_INFO}」、「${DEAL_FORM_TAB_NAMES.CUSTOMER_INFO}」、「${DEAL_FORM_TAB_NAMES.TERMINAL_INFO}」`}
            {isShowCheckSheet && `、「${DEAL_FORM_TAB_NAMES.IMPORTANT_INFO}」`}
            の各入力内容が表示されます。
          </div>
        </div>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          className={classes.tabs}
          classes={{ indicator: classes.indicator }}
        >
          {/* 契約者情報タブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.CONTRACTOR_INFO}`}
            id="simple-tab-0"
            aria-labelledby="simple-tab-0"
            icon={<BusinessCenterIcon className={classes.Icon} />}
            className={classes.tab}
          />
          {/* 加盟店情報タブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.CUSTOMER_INFO}`}
            id="simple-tab-1"
            aria-labelledby="simple-tab-1"
            icon={<StoreIcon className={classes.Icon} />}
            className={classes.tab}
          />
          {/* 端末情報タブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.TERMINAL_INFO}`}
            id="simple-tab-2"
            aria-labelledby="simple-tab-2"
            icon={<PhonelinkSetupIcon className={classes.Icon} />}
            className={classes.tab}
          />
          {/* 重要事項タブ */}
          {isShowCheckSheet && (
            <Tab
              label={` ${DEAL_FORM_TAB_NAMES.IMPORTANT_INFO}`}
              id="simple-tab-3"
              aria-labelledby="simple-tab-3"
              icon={<CheckBoxIcon className={classes.settingIcon} />}
              className={classes.tab}
            />
          )}
        </Tabs>
        <form>
          {/* 契約者情報 */}
          <TabPanel
            value={tabValue}
            index={0}
            borderColor={ADMIN_COLOR.primary}
          >
            <ConfirmForm
              formCategory={FORM_CATEGORY.CONTRACTOR} // 契約者情報
              requiredKey="dealer"
              colors={ADMIN_COLOR}
              terminalGridId={undefined}
            />
          </TabPanel>
          {/* 加盟店情報 */}
          <TabPanel
            value={tabValue}
            index={1}
            borderColor={ADMIN_COLOR.primary}
          >
            <ConfirmForm
              formCategory={FORM_CATEGORY.CUSTOMER} // 加盟店情報
              requiredKey="dealer"
              colors={ADMIN_COLOR}
              terminalGridId={undefined}
            />
          </TabPanel>
          {/* 端末情報 */}
          <TabPanel
            value={tabValue}
            index={2}
            borderColor={ADMIN_COLOR.primary}
          >
            <TerminalForm
              type="confirm"
              requiredKey="dealer"
              colors={ADMIN_COLOR}
            />
          </TabPanel>
          {/* 重要事項 */}
          {isShowCheckSheet && (
            <TabPanel
              value={tabValue}
              index={3}
              borderColor={ADMIN_COLOR.primary}
            >
              <ConfirmForm
                formCategory={FORM_CATEGORY.CHECK_SHEET}
                requiredKey="dealer"
                colors={ADMIN_COLOR}
                terminalGridId={undefined}
              />
            </TabPanel>
          )}
          <div className={classes.buttonContainer}>
            {/* 戻るボタン */}
            <Button
              className={clsx(
                moveButtonClasses.button,
                classes.returnButton,
                classes.gridButtons
              )}
              type="button"
              component={Link}
              to="/dealer/deal/new"
              variant="contained"
            >
              <KeyboardReturnIcon className={classes.returnIcon} />
              戻る
            </Button>
            {/* 登録するボタン */}
            <Button
              type="submit"
              onClick={handleRegister}
              className={clsx(moveButtonClasses.button, classes.checkButton)}
              variant="contained"
              disabled={loading.current} // 非同期処理が行われている間はボタンを押せないようにする。(二重送信対策)
            >
              <AddIcon className={classes.addIcon} />
              登録する
            </Button>
          </div>
        </form>
      </Container>
    </Dashboard>
  );
};

export default withRouter(DealNewConfirmPage);
