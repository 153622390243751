/**
 * 契約者管理関連の設定値
 */

/**
 * 契約者稼働状態
 */
export const CONTRACTOR_OPERATING_STATUS = {
  /** 登録済み */
  DEFAULT: "0",
  /** 変更申請中 */
  CHANGING: "1",
  /** 解約申請中 */
  TERMINATING: "2",
  /** 契約者解約済 */
  TERMINATED: "3",
} as const;

/**
 * 契約者稼働状態の名称
 */
export const CONTRACTOR_OPERATING_STATUS_NAME: { [key: string]: string } = {
  [CONTRACTOR_OPERATING_STATUS.DEFAULT]: "登録済み", // 0: 登録済み
  [CONTRACTOR_OPERATING_STATUS.CHANGING]: "変更申請中", // 1: 変更申請中
  [CONTRACTOR_OPERATING_STATUS.TERMINATING]: "解約申請中", // 2: 解約申請中
  [CONTRACTOR_OPERATING_STATUS.TERMINATED]: "契約者解約済", // 3: 契約者解約済
};

/**
 * 画面に表示する契約者稼働状態のステータス
 * @member 1: 変更申請中
 * @member 3: 契約者解約済
 */
export const DISPLAY_CONTRACTOR_OPERATING_STATUS: string[] = [
  CONTRACTOR_OPERATING_STATUS.CHANGING,
  CONTRACTOR_OPERATING_STATUS.TERMINATED,
];
