/**
 * フォーム関係-JSONのフォーム用のファイルアップローダー
 */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";

import clsx from "clsx";
import { FormControl, FormHelperText, makeStyles } from "@material-ui/core";

import { TFormColumn, TRequiredKey } from "../../../common/components/types";

import { FONT } from "../../../../constants/common";
import { UPLOAD_ACCEPT_TYPE } from "../../../../constants/deal";

import { GlobalPopupContext } from "../../../../App";
import formStore from "../../../../store/formStore";
import getBase64, { getExtension } from "../../../../utils/file";

import ValidationMsg from "./ValidateMsg";
import FormRemarks from "./FormRemarks";
import UploadImageView from "../../../common/components/atoms/UploadImageView";

const useStyles = makeStyles(() => ({
  container: {
    border: "2px dashed #494949",
    padding: "20px 10px 20px 0px",
    textAlign: "center",
    color: "#666",
    fontFamily: FONT,
  },
  disableContainer: {
    border: "2px dashed #494949",
    padding: "20px 10px 20px 0px",
    textAlign: "center",
    color: "#666",
    backgroundColor: "rgba(0, 0, 0, 0.09)",
  },
  url: {
    fontFamily: FONT,
  },
  // エラーの場合の背景色
  errorBack: {
    backgroundColor: "rgb(253, 237, 237)",
    color: "rgb(95, 33, 32)",
  },
  nonErrorBack: { backgroundColor: "#fff", color: "#000" },
  error: { marginLeft: 0 },
}));

type Props = {
  columnName: TFormColumn;
  requiredKey: TRequiredKey;
  terminalGridId: number | undefined;
  gridId: number | undefined;
  isChangeDeal?: boolean;
};

const CustomFileUploader: React.FC<Props> = (props: Props) => {
  const { columnName, requiredKey, terminalGridId, gridId, isChangeDeal } =
    props;

  const classes = useStyles();

  const { setSnackbar } = useContext(GlobalPopupContext);
  const [row, setRow] = useState(
    formStore.getFormRow(columnName, terminalGridId, gridId)
  );

  const disabled = useMemo(
    () => !row?.json.updateEnabled[requiredKey] || !!row.disabled,
    [row, requiredKey]
  );

  useEffect(() => {
    const fn = () => {
      const r = formStore.getFormRow(columnName, terminalGridId, gridId);
      setRow(r && { ...r });
    };
    formStore.addListener(columnName, fn);

    return () => formStore.removeListener(columnName, fn);
  }, [columnName, terminalGridId, gridId]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length !== 0) {
        // バイト数(1MB)チェック
        if (acceptedFiles[0].size >= 1024 * 1024) {
          setSnackbar({
            openProps: true,
            severity: "error",
            message: "ファイルの上限サイズ1MBを超えています",
          });
          return;
        }
        // pdf 以外の場合はエラー
        if (!UPLOAD_ACCEPT_TYPE.includes(acceptedFiles[0].type)) {
          setSnackbar({
            openProps: true,
            severity: "error",
            message: "PDFのみ利用可能です",
          });
          return;
        }
        // jpgの場合は注意文言表示
        // アップロード自体は許可
        if (getExtension(acceptedFiles[0].name) === "jpg") {
          setSnackbar({
            openProps: true,
            severity: "error",
            message: "JPGファイルはJPEGとして保管されます",
          });
        }
        getBase64(acceptedFiles[0])
          .then((result: string | ArrayBuffer | null) => {
            formStore.setNewFile(
              columnName,
              acceptedFiles[0].name,
              (result as string) || "",
              row?.content
            );
            formStore.updateContent(
              columnName,
              result,
              requiredKey,
              terminalGridId,
              gridId
            );
          })
          .catch((error) => {
            setSnackbar({
              openProps: true,
              severity: "error",
              message: error,
            });
            throw error;
          });
      }
    },
    // eslint-disable-next-line
    [columnName, terminalGridId, gridId, requiredKey, setSnackbar]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const removeFile = () => {
    formStore.removeNewFile(columnName);
    formStore.updateContent(
      columnName,
      "",
      requiredKey,
      terminalGridId,
      gridId
    );
  };

  if (!row) return null;

  return (
    <div>
      <FormControl
        variant="filled"
        style={{ display: "block" }}
        error={row.errors.length !== 0}
      >
        {/* eslint-disable */}
        {/* TODO：lint無効を解除したいが調べる時間がないので一旦このままとする。 */}
        {/* ファイルアップローダー */}
        {disabled === false ? (
          <div {...getRootProps()}>
            {/* 活性の場合の処理 */}
            <input
              {...getInputProps()}
              accept={UPLOAD_ACCEPT_TYPE.toString()}
            />
            <div
              className={clsx(
                classes.container,
                row.errors.length !== 0
                  ? classes.errorBack
                  : classes.nonErrorBack
              )}
            >
              <UploadImageView
                formGroupName={row.json.formGroupName as string}
                formContent={row.content as string}
                index={gridId || 0}
                isError={row.errors.length !== 0}
              />
            </div>
          </div>
        ) : (
          <div>
            {/* 非活性の場合の処理 */}
            <div className={classes.disableContainer}>
              <UploadImageView
                formGroupName={row.json.formGroupName as string}
                formContent={row.content as string}
                index={gridId || 0}
              />
            </div>
          </div>
        )}
        {/* ファイル削除ボタン */}
        {row.content && (
          <div style={{ textAlign: "right" }}>
            <button onClick={removeFile}>ファイルを削除</button>
          </div>
        )}
        {/* eslint-enable */}
        {/* 項目の説明 */}
        <FormRemarks
          remarks={
            isChangeDeal ? row.json.changeFormRemarks : row.json.formRemarks
          }
        />
        {/* 項目の説明（URL） */}
        <a
          className={classes.url}
          href={row.json.url}
          target="_blank"
          rel="noopener noreferrer"
          tabIndex={row.json.url ? 0 : -1}
        >
          {row.json.url}
        </a>
        {/* バリデーションエラーメッセージ */}
        {row.errors.length !== 0 && (
          <FormHelperText className={classes.error}>
            <ValidationMsg errors={row.errors} />
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default CustomFileUploader;
