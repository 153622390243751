/**
 * 加盟店側-端末管理・検索画面
 * メモ：端末管理画面を表示する
 */
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";

import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { TerminalSearchCondition } from "../../types";
import { ITerminalSearchResponse } from "../../../../../api/types";

import { GUEST_COLOR } from "../../../../../constants/common";
import { TERMINAL_TYPE_LIST } from "../../../../../constants/terminal";

import { AuthContext } from "../../../../../cognito/AuthContext";
import { getErrorMessage } from "../../../../../utils/error";
import terminal from "../../../../../api/terminal";

import Loading from "../../../../common/components/molecules/Loading";
import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import TerminalSearchConditionForm from "../organisms/TerminalSearchConditionForm";
import TerminalSearchResult from "../organisms/TerminalSearchResult";

const breadCrumbs = [{ name: "端末管理・検索", link: "" }];

const useStyles = makeStyles(() => ({
  form: {
    width: 800,
  },
  searchButton: {
    paddingBottom: 8,
    margin: "0 auto",
    marginTop: 20,
  },
  searchIcon: {
    position: "absolute",
    top: 8,
    left: 50,
  },
  alert: {
    marginTop: "30px",
  },
}));

const TerminalPage: React.FC = () => {
  const classes = useStyles();
  const searchButtonClasses = buttonStyles({
    width: 180,
    marginTop: 15,
    textLeft: 10,
    backgroundColor: GUEST_COLOR.primary,
  });
  const containerClasses = useContainerStyles();

  const { state } = useLocation(); // 現在の検索条件を受け取る

  const { authHeader } = useContext(AuthContext);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [searchApiErrorMsg, setSearchApiErrorMsg] = useState<string>("");
  const [searchCondition, setSearchCondition] =
    useState<TerminalSearchCondition>({
      // 検索条件が渡ってきていれば設定する
      masterCustomerId:
        state && state.masterCustomerId ? state.masterCustomerId : "", // 加盟店ID（加盟店詳細からの時のみ使用）
      customerName: state && state.customerName ? state.customerName : "", // 加盟店名
      customerCode: state && state.customerCode ? state.customerCode : "", // 加盟店コード
      termiIdentNum: state && state.termiIdentNum ? state.termiIdentNum : "", // 端末識別番号
      terminalType: state && state.terminalType ? state.terminalType : "", // 端末種別
      firstDLLDate: state && state.firstDLLDate ? state.firstDLLDate : "", // 初回DLL日
      terminalTerminationFlag:
        state && state.terminalTerminationFlag
          ? state.terminalTerminationFlag
          : false, // 解約を含むか
      pageNumber: state && state.pageNumber ? state.pageNumber : "1", // ページ番号
    });
  const [searchResult, setSearchResult] = useState<ITerminalSearchResponse>({});

  // 端末検索&取得
  const getTerminals = (pageNumber: string) => {
    setLoadingOpen(true);
    terminal
      .search(
        authHeader as string,
        searchCondition.masterCustomerId,
        searchCondition.customerName,
        searchCondition.customerCode,
        searchCondition.termiIdentNum,
        TERMINAL_TYPE_LIST.get(searchCondition.terminalType) || "",
        searchCondition.firstDLLDate,
        String(searchCondition.terminalTerminationFlag),
        pageNumber
      )
      .then((res) => {
        // 端末リストの取得結果と検索条件をセット
        setSearchResult(res.data as ITerminalSearchResponse);
        setSearchCondition({ ...searchCondition, pageNumber });
        setSearchApiErrorMsg("");
      })
      .catch((error) => {
        setSearchApiErrorMsg(getErrorMessage(error));
        throw error;
      })
      .finally(() => {
        setSearchCondition({ ...searchCondition, pageNumber });
        setLoadingOpen(false);
      });
  };

  // 遷移時に検索条件が付与されていたら検索した状態にする
  useEffect(() => {
    if (state) {
      getTerminals(searchCondition.pageNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Dashboard
        title="端末管理"
        color={GUEST_COLOR.base}
        backgroundColor={GUEST_COLOR.primary}
        iconName="terminal"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {searchApiErrorMsg === "" && (
            <>
              {/* 端末検索フォーム */}
              <form className={classes.form}>
                <TerminalSearchConditionForm
                  searchCondition={searchCondition}
                  setSearchCondition={setSearchCondition}
                />
                <Button
                  className={clsx(
                    searchButtonClasses.button,
                    classes.searchButton
                  )}
                  variant="contained"
                  onClick={() => getTerminals("1")}
                  disabled={loadingOpen} // 二重送信対策
                >
                  <SearchIcon className={classes.searchIcon} />
                  検索
                </Button>
              </form>
              {/* 端末検索結果 */}
              <TerminalSearchResult
                resultsRows={searchResult}
                conditionState={searchCondition}
                getTerminals={getTerminals}
              />
            </>
          )}
          {searchApiErrorMsg !== "" && (
            <Alert className={classes.alert} severity="error">
              {searchApiErrorMsg}
            </Alert>
          )}
        </Container>
      </Dashboard>
    </>
  );
};

export default TerminalPage;
