/**
 * JTB側-カード会社連携ファイル検索結果リスト
 * メモ：検索結果の一覧を表示する
 */
import React, { useContext, useEffect, useState } from "react";

import clsx from "clsx";
import {
  Button,
  Checkbox,
  FormControl,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import DownloadIcon from "@material-ui/icons/GetApp";

import {
  buttonStyles,
  checkboxStyles,
  formStyles,
  paginationStyles,
} from "../../../../common/components/styles";

import {
  ICardFileSearchResult,
  ICardFileSearchResponse,
} from "../../../../../api/types";
import { CardFileSearchCondition } from "../../types";

import {
  ADMIN_COLOR,
  FONT,
  CARD_SEARCH_PER_PAGE,
} from "../../../../../constants/common";
import {
  CUSTOMER_GROUP_CONVERT_MAP,
  DEAL_GROUP_CONVERT_MAP,
  CARD_COMPANY_CONVERT_MAP,
} from "../../../../../constants/cardFile";

import { AuthContext } from "../../../../../cognito/AuthContext";
import { getErrorMessage } from "../../../../../utils/error";
import cardCompanyFile from "../../../../../api/cardCompanyFile";
import { downloadFile } from "../../../../../utils/file";
import { toTimeStamp } from "../../../../../utils/date";

import CustomSnackbar from "../../../../common/components/atoms/CustomSnackbar";
import SearchCountText from "../../../../common/components/atoms/SearchCountText";

const useStyles = makeStyles(() => ({
  table: {
    width: "auto",
  },
  cell: {
    fontFamily: FONT,
    padding: 1,
    backgroundColor: ADMIN_COLOR.base,
    color: "#000!important",
    border: `1px solid ${ADMIN_COLOR.primary}`,
    width: 200,
    fontSize: "0.9em",
    height: 38,
  },
  thCell: {
    fontFamily: FONT,
    color: ADMIN_COLOR.primary,
    border: `1px solid ${ADMIN_COLOR.primary}!important`,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: ADMIN_COLOR.base,
    width: 400,
    position: "relative",
    fontSize: "0.9em",
    height: 38,
  },
  check: {
    width: "85px!important",
  },
  icon: {
    position: "absolute",
    top: 7,
    left: 15,
    fontSize: "1.3rem",
  },
  messageHeader: {
    fontFamily: FONT,
    textAlign: "center",
    fontSize: "1.1rem",
    marginTop: "40px",
    marginBottom: "10px",
  },
  button: {
    marginLeft: 0,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 34,
    backgroundColor: `${ADMIN_COLOR.base}!important`,
    color: `${ADMIN_COLOR.primary}!important`,
    border: `1px solid ${ADMIN_COLOR.primary}`,
    borderRadius: 0,
    textAlign: "center",
    "&:hover": {
      backgroundColor: `${ADMIN_COLOR.btnHover}!important`,
    },
  },
  form: {
    marginTop: "5px!important",
  },
  cautionMessage: {
    fontSize: "1.0em",
    fontWeight: "bold",
    fontFamily: FONT,
    color: ADMIN_COLOR.primary,
    marginTop: 10,
    textAlign: "center",
  },
  message: {
    fontSize: "0.9em",
    fontWeight: "bold",
    fontFamily: FONT,
    color: ADMIN_COLOR.primary,
    marginTop: 10,
    marginBottom: 15,
  },
}));

interface Props {
  resultsRows: ICardFileSearchResponse;
  conditionState: CardFileSearchCondition;
  setApiErrorMsg: React.Dispatch<React.SetStateAction<string>>;
  setLoadingOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loadingOpen: boolean;
  getCardFiles: (pageNumber: string) => void;
}

const CardFileSearchResult: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const formClasses = formStyles({
    color: ADMIN_COLOR.base,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const buttonClasses = buttonStyles({
    width: 213,
    marginTop: 15,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const checkboxClasses = checkboxStyles({
    backgroundColor: ADMIN_COLOR.primary,
  });
  const paginationClasses = paginationStyles({
    color: ADMIN_COLOR.base,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const {
    resultsRows,
    conditionState,
    setApiErrorMsg,
    setLoadingOpen,
    loadingOpen,
    getCardFiles,
  } = props;

  const { authHeader } = useContext(AuthContext);
  const [checkedFileIdList, setCheckedFileIdList] = useState<number[]>([]);
  const [errorOpen, setErrorOpen] = useState<boolean>(false);

  // チェックボックスを押した際の処理
  const handleCheck = (id: number) => {
    const index = checkedFileIdList.indexOf(id);
    if (index < 0) {
      // リストになければリストに追加
      setCheckedFileIdList([...checkedFileIdList, id]);
    } else {
      // リストにあればリストから削除
      setCheckedFileIdList(checkedFileIdList.filter((fileId) => fileId !== id));
    }
  };

  const onDownload = async (idList: number[]) => {
    setLoadingOpen(true);

    cardCompanyFile
      .download(authHeader as string, idList)
      .then((res) => {
        const url = res.data.result.url as string;
        // download
        downloadFile(url, `cardCompanyFiles_${toTimeStamp(new Date())}.zip`);
        setApiErrorMsg("");
      })
      .catch((error) => {
        setApiErrorMsg(getErrorMessage(error));
        throw error;
      })
      .finally(() => {
        setLoadingOpen(false);
      });
  };

  // ダウンロードボタンを押した際の処理
  const handleDownload = () => {
    if (checkedFileIdList.length <= 0) {
      setErrorOpen(true);
      return;
    }
    onDownload(checkedFileIdList);
  };

  // ページ変更や検索をしたら選択リセット
  useEffect(() => {
    setCheckedFileIdList([]);
  }, [conditionState.pageNumber, resultsRows]);

  const columnNames = [
    "連携日",
    "アップロード日",
    "業務",
    "加盟店種別",
    "カード会社",
    "ファイル",
  ] as const;

  return (
    <>
      {resultsRows.result !== undefined && resultsRows.totalCount === 0 && (
        <>
          <div className={classes.messageHeader}>
            {` ${"ファイルが存在していません。"}`}
          </div>
        </>
      )}
      {resultsRows.result !== undefined && resultsRows.totalCount !== 0 && (
        <>
          <CustomSnackbar
            openProps={errorOpen}
            setOpen={setErrorOpen}
            message="ダウンロードするファイルを選択してください。"
            severity="error"
          />
          {/* 検索結果件数 */}
          <SearchCountText count={resultsRows.totalCount as number} />
          {/* 検索結果 */}
          <FormControl className={clsx(formClasses.form, classes.form)}>
            <TableContainer>
              <Table
                className={clsx(formClasses.table, classes.table)}
                aria-label="simple table"
              >
                <TableHead className={formClasses.th}>
                  <TableRow>
                    <TableCell
                      align="center"
                      className={clsx(classes.thCell, classes.check)}
                    />
                    {/* 項目名 */}
                    {columnNames.map((name) => (
                      <TableCell
                        key={name}
                        align="center"
                        className={classes.thCell}
                      >
                        {name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(resultsRows.result as ICardFileSearchResult[]).map(
                    (resultsRow) => (
                      <TableRow key={resultsRow.id}>
                        {/* ダウンロード用チェックボックス */}
                        <TableCell
                          align="center"
                          className={clsx(classes.cell, classes.check)}
                        >
                          <FormControl className={checkboxClasses.checkbox}>
                            <Checkbox
                              value={resultsRow.id}
                              checked={checkedFileIdList.includes(
                                resultsRow.id
                              )}
                              onChange={() => handleCheck(resultsRow.id)}
                            />
                          </FormControl>
                        </TableCell>
                        {/* 連携日 */}
                        <TableCell align="center" className={classes.cell}>
                          {resultsRow.alignmentDate}
                        </TableCell>
                        {/* アップロード日 */}
                        <TableCell align="center" className={classes.cell}>
                          {resultsRow.uploadDate}
                        </TableCell>
                        {/* 業務 */}
                        <TableCell align="center" className={classes.cell}>
                          {DEAL_GROUP_CONVERT_MAP.get(resultsRow.dealGroup)}
                        </TableCell>
                        {/* 加盟店種別 */}
                        <TableCell align="center" className={classes.cell}>
                          {CUSTOMER_GROUP_CONVERT_MAP.get(
                            resultsRow.customerGroup
                          ) ?? "-"}
                        </TableCell>
                        {/* カード会社 */}
                        <TableCell align="center" className={classes.cell}>
                          {CARD_COMPANY_CONVERT_MAP.get(resultsRow.cardCompany)}
                        </TableCell>
                        {/* ファイル */}
                        <TableCell align="center" className={classes.cell}>
                          {resultsRow.fileName}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* ページネーション */}
            <div className={paginationClasses.parent}>
              <Pagination
                count={Math.ceil(
                  (resultsRows.totalCount as number) / CARD_SEARCH_PER_PAGE
                )}
                className={clsx(
                  paginationClasses.pagination,
                  paginationClasses.pageCurrent
                )}
                onChange={(e: React.ChangeEvent<unknown>, num) =>
                  getCardFiles(String(num))
                }
                page={Number(conditionState.pageNumber)}
              />
            </div>
            {/* 注意文言 */}
            <div className={classes.cautionMessage}>
              ページを変更すると選択中の内容はリセットされます
            </div>
            {/* メッセージ */}
            <div className={classes.message}>
              選択したファイルを一括でダウンロードします
            </div>
            {/* ファイルダウンロードボタン */}
            <Button
              className={clsx(buttonClasses.button, classes.button)}
              variant="contained"
              onClick={handleDownload}
              disabled={loadingOpen} // 二重送信対策
            >
              <DownloadIcon className={classes.icon} />
              ファイルダウンロード
            </Button>
          </FormControl>
        </>
      )}
    </>
  );
};

export default React.memo(CardFileSearchResult);
