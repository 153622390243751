/**
 * JTB側-申請詳細画面
 * メモ：申請詳細画面を表示する。ワークフローステータスの進行操作もここから行う
 */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Container, makeStyles, Tab, Tabs } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import CommentIcon from "@material-ui/icons/CommentOutlined";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import StoreIcon from "@material-ui/icons/Store";
import TouchAppIcon from "@material-ui/icons/TouchApp";

import { useContainerStyles } from "../../../../templates/styles";

import {
  TDealTypeStr,
  TTerminalFormData,
  TDealTypeNum,
} from "../../../../common/components/types";

import { ADMIN_COLOR, FONT } from "../../../../../constants/common";
import DEAL_FORM_TAB_NAMES, {
  FORM_CATEGORY,
} from "../../../../../constants/form";
import {
  TWorkflowStatus,
  WORKFLOW_STATUS,
} from "../../../../../constants/workflowStatus";
import { DEAL_TYPE_NUM_TO_STR } from "../../../../../constants/deal";

import { GlobalPopupContext } from "../../../../../App";
import formStore from "../../../../../store/formStore";
import deal from "../../../../../api/deal";
import preloadFormContent from "../../../../../api/utils/preUtils";
import { getErrorMessage } from "../../../../../utils/error";
import {
  convIndustrySmallCode,
  json2FormData,
} from "../../../../../utils/formData";
import { AuthContext } from "../../../../../cognito/AuthContext";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import DealShowWorkflow from "../../../../common/components/organisms/DealShowWorkflow";
import TabPanel from "../../../../common/components/atoms/TabPanel";
import NewForm from "../../../../form/components/organisms/NewForm";
import ConfirmForm from "../../../../form/components/organisms/ConfirmForm";
import TerminalForm from "../../../../form/components/organisms/TerminalForm";
import CommentForm from "../../../../form/components/atoms/CommentForm";
import DealShowButtonList from "../organisms/DealShowButtonList";
import UserRecreateBox from "../molecules/UserRecreateBox";
import UploadedAdditionalFiles from "../molecules/UploadedAdditionalFiles";
import SecondSnackbar from "../../../../common/components/atoms/SecondSnackbar";

const breadCrumbs = [
  { name: "TOP", link: "/dealer" },
  { name: "申請詳細", link: "" },
];

const useStyles = makeStyles(() => ({
  settingIcon: {
    verticalAlign: "top",
    left: -2,
    top: 1,
    position: "relative",
  },
  Icon: {
    verticalAlign: "top",
    left: -2,
    top: 1,
    position: "relative",
  },
  tabs: {
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: ADMIN_COLOR.primary,
      color: ADMIN_COLOR.base,
    },
  },
  tab: {
    color: ADMIN_COLOR.primary,
    border: `3px solid ${ADMIN_COLOR.primary}`,
    marginRight: 4,
    minHeight: 35,
    borderBottom: "none",
    fontFamily: FONT,
    opacity: 1,
    borderRadius: "8px 8px 0px 0px",
    "& .MuiTab-wrapper": {
      display: "inline!important",
    },
    "& .MuiTab-labelIcon": {
      minHeight: 35,
    },
  },
  indicator: {
    display: "none",
  },
  message: {
    fontSize: "0.9em",
    fontWeight: "bold",
    fontFamily: FONT,
    color: ADMIN_COLOR.primary,
    marginTop: 20,
    marginBottom: 10,
  },
}));

const DealShowPage: React.FC<RouteComponentProps<{ id: string }>> = (
  props: RouteComponentProps<{ id: string }>
) => {
  const { match } = props;

  const { authHeader } = useContext(AuthContext);
  const { setLoading } = useContext(GlobalPopupContext);
  const [dealType, setDealType] = useState<TDealTypeStr | undefined>();
  const [workflowStatus, setWorkflowStatus] = useState<TWorkflowStatus>();
  const [uploadedFiles, setUploadedFiles] = useState<
    { fileName: string; fileId: number }[]
  >([]);
  const [tabValue, setTabValue] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // ID類は初回API取得以降はページ遷移なしに変化しないのでRefを使う
  const dealId = useRef(match.params.id);
  const contractorId = useRef(0);
  const customerId = useRef(0);

  // 加盟店コード更新APIが実行された際に詳細画面のデータを再取得させるためのstate
  const [executed, setExecuted] = useState(0);

  const [isShowCheckList, setIsShowCheckList] = useState(false);
  const [isShowCheckSheet, setIsShowCheckSheet] = useState(false);
  const [canRecreateUser, setCanRecreateUser] = useState(false);

  const classes = useStyles();
  const containerClasses = useContainerStyles();

  // 追加アップロードファイル一覧取得API
  const getExtraUploadFiles = useCallback(async () => {
    const fileRes = await deal.getExtraUploadFiles(
      authHeader || "",
      dealId.current
    );
    // 追加資料一覧セット
    setUploadedFiles(fileRes.data.result.filesList);
  }, [authHeader]);

  const getDataApi = useCallback(async () => {
    try {
      // 申請詳細情報取得API
      const res = await deal.show(authHeader || "", dealId.current);
      // formMaster取得API
      const jsonRes = await deal.getFormMaster(
        authHeader || "",
        res.data.result.formVersion
      );

      await getExtraUploadFiles();

      await preloadFormContent(authHeader || "", res.data.result.formVersion);

      // formMasterを整形して保存
      const formData = json2FormData(jsonRes.data.result);
      formStore.setFormData(
        formData.formData,
        formData.formGroupData,
        formData.columnToCategory
      );
      // 契約者情報を反映
      formStore.updateContents(
        res.data.result.contractor.contractorForm || {},
        "dealer"
      );
      // 加盟店情報を反映
      const smallClass = convIndustrySmallCode(
        res.data.result.customer.customerForm
      );
      formStore.updateContents(
        {
          ...res.data.result.customer.customerForm,
          industrySmallClass: smallClass,
        } || {},
        "dealer"
      );
      // 端末情報を反映
      // グリッドがある場合は先に足りない分の枠を作成する
      const terminalDataNum = res.data.result.customer.terminals.length;
      const terminalJsonNum = formData.formData[3].length;
      for (let i = terminalJsonNum; i < terminalDataNum; i += 1) {
        formStore.terminalGridCopy(0);
      }
      res.data.result.customer.terminals.forEach(
        (terminal: { dealTerminalForm: TTerminalFormData }, idx: number) => {
          formStore.updateContents(
            terminal.dealTerminalForm || {},
            "dealer",
            idx
          );
        }
      );

      // 申請基本情報を反映
      formStore.updateContents(res.data.result.dealOtherForm || {}, "dealer");

      // フォームの情報を利用して表示制御しているようなのでフォームの情報が保存された後に実行されるようにする
      setIsShowCheckList(formStore.isShowCheckList());
      setIsShowCheckSheet(formStore.isShowChackSheet());

      // ユーザー再作成が可能かの情報をセット
      setCanRecreateUser(res.data.result.canRecreateUser);

      // その他を反映
      contractorId.current = res.data.result.contractorId;
      customerId.current = res.data.result.customer.masterCustomerId;
      setDealType(
        DEAL_TYPE_NUM_TO_STR[res.data.result.dealType as TDealTypeNum]
      );
      formStore.setFormComment(res.data.result.comment);
      setWorkflowStatus(
        Number(res.data.result.workflowStatus) as TWorkflowStatus
      );

      // 以下は申請編集用にセット
      formStore.setContractorId(res.data.result.contractorId);
      formStore.setEditDealType(
        DEAL_TYPE_NUM_TO_STR[res.data.result.dealType as TDealTypeNum]
      );
      formStore.setEditFormVersion(res.data.result.formVersion);
      formStore.setMasterCustomerId(res.data.result.customer.masterCustomerId);
      formStore.setMasterCustomerFormId(
        res.data.result.customer.customerForm.id
      );
      formStore.setDealOtherFormId(res.data.result.dealOtherForm.id);
      const terminalIds = res.data.result.customer.terminals.map(
        (t: { id: number; dealTerminalForm: TTerminalFormData }) => {
          return { id: t.id, formId: t.dealTerminalForm.id };
        }
      );
      formStore.setTerminalIds(terminalIds);

      setLoading(false);
    } catch (err) {
      setErrorMessage(getErrorMessage(err));
      setLoading(false);
    }
  }, [authHeader, getExtraUploadFiles, setLoading]);

  // 申請を更新する処理
  const updateDataApi = useCallback(
    async (nextWorkflow: TWorkflowStatus) => {
      formStore.setNextWorkflowStatus(nextWorkflow);
      const request = formStore.getApiRequestDataForEditDeal(
        parseInt(dealId.current, 10),
        "dealer"
      );

      if (!authHeader || !request) return;

      try {
        setLoading(true);
        // 決裁時は申請情報pdf保管APIを実行する
        if (nextWorkflow === WORKFLOW_STATUS.NUMBERING_TID) {
          await deal.storeDealPdf(authHeader, dealId.current);
        }
        // 新規申請編集API
        await deal.update(authHeader, dealId.current, request);
        await getDataApi();
        setSnackbarOpen(true); // snackbarを表示
        setLoading(false);
      } catch (err) {
        setErrorMessage(getErrorMessage(err));
        setLoading(false);
        throw err; // この関数を利用しているDealUserMessageModal側でエラーをcatchできるように再度throwする
      }
    },
    [authHeader, getDataApi, setLoading]
  );

  useEffect(() => {
    setLoading(true);
    getDataApi();
    // eslint-disable-next-line
  }, [setLoading, executed]);

  const handleChange = useCallback((_, newValue: number) => {
    setTabValue(newValue);
  }, []);

  if (!dealType) return null;

  return (
    <Dashboard
      title="申請管理"
      color={ADMIN_COLOR.base}
      backgroundColor={ADMIN_COLOR.primary}
      iconName="deal"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {/* メッセージ送信のsnackbarと被らないように別でsnackbarを表示 */}
        <SecondSnackbar
          openProps={snackbarOpen}
          setOpen={setSnackbarOpen}
          message="ワークフローステータスを進めました。"
          severity="info"
        />
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        {/* ワークフローステータス表示欄 */}
        {workflowStatus && (
          <DealShowWorkflow
            dealId={dealId.current}
            dealType={dealType}
            workflowStatus={workflowStatus}
            colors={ADMIN_COLOR}
            urlPrefix="dealer"
            updateDeal={updateDataApi}
          />
        )}
        {/* 文言 */}
        <div className={classes.message}>
          {`タブをクリックすることで「${DEAL_FORM_TAB_NAMES.CONTRACTOR_INFO}」、「${DEAL_FORM_TAB_NAMES.CUSTOMER_INFO}」、「${DEAL_FORM_TAB_NAMES.TERMINAL_INFO}」`}
          {isShowCheckSheet && `、「${DEAL_FORM_TAB_NAMES.IMPORTANT_INFO}」`}
          {`、「${DEAL_FORM_TAB_NAMES.COMMENT_AND_MEMO}」`}
          {isShowCheckList && `、「${DEAL_FORM_TAB_NAMES.CHECK_LIST}」`}
          {`、「${DEAL_FORM_TAB_NAMES.VARIOUS_OPERATION}」が表示されます。`}
        </div>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          className={classes.tabs}
          classes={{ indicator: classes.indicator }}
        >
          {/* 契約者情報タブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.CONTRACTOR_INFO}`}
            id="simple-tab-0"
            aria-labelledby="simple-tab-0"
            icon={<BusinessCenterIcon className={classes.Icon} />}
            className={classes.tab}
          />
          {/* 加盟店情報タブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.CUSTOMER_INFO}`}
            id="simple-tab-1"
            aria-labelledby="simple-tab-1"
            icon={<StoreIcon className={classes.Icon} />}
            className={classes.tab}
          />
          {/* 端末情報タブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.TERMINAL_INFO}`}
            id="simple-tab-2"
            aria-labelledby="simple-tab-2"
            icon={<PhonelinkSetupIcon className={classes.Icon} />}
            className={classes.tab}
          />
          {/* 重要事項タブ */}
          {isShowCheckSheet && (
            <Tab
              label={` ${DEAL_FORM_TAB_NAMES.IMPORTANT_INFO}`}
              id="simple-tab-3"
              aria-labelledby="simple-tab-3"
              icon={<CheckBoxIcon className={classes.settingIcon} />}
              className={classes.tab}
            />
          )}
          {/* コメント・メモタブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.COMMENT_AND_MEMO}`}
            id="simple-tab-4"
            aria-labelledby="simple-tab-4"
            icon={<CommentIcon className={classes.settingIcon} />}
            className={classes.tab}
          />
          {/* チェックリストタブ */}
          {isShowCheckList && (
            <Tab
              label={` ${DEAL_FORM_TAB_NAMES.CHECK_LIST}`}
              id="simple-tab-5"
              aria-labelledby="simple-tab-5"
              icon={<CheckBoxIcon className={classes.settingIcon} />}
              className={classes.tab}
            />
          )}
          {/* 各種操作タブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.VARIOUS_OPERATION}`}
            id="simple-tab-6"
            aria-labelledby="simple-tab-6"
            icon={<TouchAppIcon className={classes.settingIcon} />}
            className={classes.tab}
          />
        </Tabs>
        <form>
          {/* 契約者情報 */}
          <TabPanel
            value={tabValue}
            index={0}
            borderColor={ADMIN_COLOR.primary}
          >
            <ConfirmForm
              formCategory={FORM_CATEGORY.CONTRACTOR} // 契約者情報
              requiredKey="dealer"
              colors={ADMIN_COLOR}
              terminalGridId={undefined}
            />
          </TabPanel>
          {/* 加盟店情報 */}
          <TabPanel
            value={tabValue}
            index={1}
            borderColor={ADMIN_COLOR.primary}
          >
            <ConfirmForm
              formCategory={FORM_CATEGORY.CUSTOMER} // 加盟店情報
              requiredKey="dealer"
              colors={ADMIN_COLOR}
              terminalGridId={undefined}
            />
          </TabPanel>
          {/* 端末情報 */}
          <TabPanel
            value={tabValue}
            index={2}
            borderColor={ADMIN_COLOR.primary}
          >
            <TerminalForm
              type="confirm"
              requiredKey="dealer"
              colors={ADMIN_COLOR}
            />
          </TabPanel>
          {/* 重要事項 */}
          {isShowCheckSheet && (
            <TabPanel
              value={tabValue}
              index={3}
              borderColor={ADMIN_COLOR.primary}
            >
              {/* 重要事項は表示形式が異なるためNewFormを使用 加盟店のみ入力可能の項目なので非アクティブになる */}
              <NewForm
                formCategory={FORM_CATEGORY.CHECK_SHEET} // 重要事項チェックシート
                requiredKey="dealer"
                colors={ADMIN_COLOR}
                terminalGridId={undefined}
              />
            </TabPanel>
          )}
          {/* コメント・メモ */}
          <TabPanel
            value={tabValue}
            index={3 + (isShowCheckSheet ? 1 : 0)}
            borderColor={ADMIN_COLOR.primary}
          >
            <CommentForm dealId={dealId.current} colors={ADMIN_COLOR} />
          </TabPanel>
          {/* チェックリスト */}
          {isShowCheckList && (
            <TabPanel
              value={tabValue}
              index={4 + (isShowCheckSheet ? 1 : 0)}
              borderColor={ADMIN_COLOR.primary}
            >
              <ConfirmForm
                formCategory={FORM_CATEGORY.CHECK_LIST}
                requiredKey="dealer"
                colors={ADMIN_COLOR}
                terminalGridId={undefined}
              />
            </TabPanel>
          )}
          {/* 各種操作 */}
          <TabPanel
            value={tabValue}
            index={4 + (isShowCheckSheet ? 1 : 0) + (isShowCheckList ? 1 : 0)}
            borderColor={ADMIN_COLOR.primary}
          >
            {/* 各操作ボタン一覧 */}
            {workflowStatus && (
              <DealShowButtonList
                dealId={parseInt(dealId.current, 10)}
                customerId={customerId.current}
                dealType={dealType}
                workflowStatus={workflowStatus}
                updateFileList={getExtraUploadFiles}
                setExecuted={setExecuted}
                fileData={uploadedFiles}
              />
            )}
            {/* 加盟店ユーザー再作成 */}
            {canRecreateUser && (
              <UserRecreateBox
                dealId={parseInt(dealId.current, 10)}
                contractorId={contractorId.current}
              />
            )}
            {/* 追加アップロードファイル一覧 */}
            {uploadedFiles.length > 0 && (
              <UploadedAdditionalFiles fileData={uploadedFiles} />
            )}
          </TabPanel>
        </form>
      </Container>
    </Dashboard>
  );
};

export default withRouter(DealShowPage);
