/* eslint-disable no-irregular-whitespace */
import { IDocumentData } from "../views/admin/document/types";

/**
 * マニュアル種別の番号
 */
const DOCUMENT_TYPE = {
  /** 運用マニュアル　宿泊施設.pdf */
  JTB_HOTEL: "10",
  /** 運用マニュアル　宿泊施設.pdf */
  ZENRYOREN: "20",
  /** 運用マニュアル　宿泊施設.pdf */
  RURUBU: "30",
  /** 運用マニュアル　たびなかネット・スタンプ契約店.pdf */
  STAMP: "40",
  /** 運用マニュアル　旅行業者提携販売店.pdf */
  PARTNER: "50",
  /** 申込WEB操作マニュアル.pdf */
  JTB_BRANCH: "60",
} as const;

/**
 * マニュアル画面用データ（version,updateAtはbackendから受け取る）
 */
export const LATEST_DOCUMENTS_DATA: IDocumentData[] = [
  {
    manualType: DOCUMENT_TYPE.JTB_HOTEL,
    name: "運用マニュアル　宿泊施設",
    version: "",
    updatedAt: "",
  }, // 加盟店分類コード：1
  {
    manualType: DOCUMENT_TYPE.ZENRYOREN,
    name: "運用マニュアル　宿泊施設",
    version: "",
    updatedAt: "",
  }, // 加盟店分類コード：3
  {
    manualType: DOCUMENT_TYPE.RURUBU,
    name: "運用マニュアル　宿泊施設",
    version: "",
    updatedAt: "",
  }, // 加盟店分類コード：2
  {
    manualType: DOCUMENT_TYPE.STAMP,
    name: "運用マニュアル　たびなかネット・スタンプ契約店",
    version: "",
    updatedAt: "",
  }, // 加盟店分類コード：7
  {
    manualType: DOCUMENT_TYPE.PARTNER,
    name: "運用マニュアル　旅行業者提携販売店",
    version: "",
    updatedAt: "",
  }, // 加盟店分類コード：5,6
  {
    manualType: DOCUMENT_TYPE.JTB_BRANCH,
    name: "申込WEB操作マニュアル",
    version: "",
    updatedAt: "",
  }, // 加盟店分類コード：4
];

export default LATEST_DOCUMENTS_DATA;
