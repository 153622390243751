/**
 * JTB側-加盟店コード入力モーダル
 */
import React, { useCallback, useContext, useMemo, useState } from "react";

import clsx from "clsx";

import { Button, Modal, Theme, makeStyles } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";

import { buttonStyles } from "../../../../common/components/styles";

import { ADMIN_COLOR, FONT } from "../../../../../constants/common";

import { GlobalPopupContext } from "../../../../../App";
import deal from "../../../../../api/deal";
import { AuthContext } from "../../../../../cognito/AuthContext";
import getErrorMessage from "../../../../../utils/error";

import CustomTextField from "../../../../common/components/atoms/CustomTextField";

const useStyle = makeStyles((theme: Theme) => ({
  popup: {
    position: "absolute",
    minWidth: 700,
    maxWidth: 950,
    width: "60%",
    minHeight: 150,
    maxHeight: 750,
    height: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
  },
  modalTitle: {
    fontSize: "1.1em",
    color: ADMIN_COLOR.primary,
    fontFamily: FONT,
    margin: 0,
  },
  textAreaBlock: {
    margin: "10px 0px",
    marginTop: 20,
  },
  buttonBlock: {
    display: "flex",
    justifyContent: "end",
    marginTop: 20,
  },
  selectButton: {
    paddingBottom: 8,
    marginRight: 20,
  },
  cancelButton: {
    paddingBottom: 8,
  },
  closeIcon: {
    fontSize: "1.1rem",
    position: "relative",
    marginLeft: -3,
    marginBottom: -5,
    left: -2,
    top: -1,
  },
  checkIcon: {
    position: "relative",
    marginLeft: -3,
    marginBottom: -5,
    left: -6,
    top: 2,
  },
}));

type Props = {
  dealId: number;
  customerCode?: string;
  setOpen: (open: boolean) => void;
  setExecuted: React.Dispatch<React.SetStateAction<number>>;
};

const CustomerCodeModal: React.FC<Props> = (props: Props) => {
  const { dealId, customerCode, setOpen, setExecuted } = props;

  const { authHeader } = useContext(AuthContext);
  const { setLoading, setSnackbar } = useContext(GlobalPopupContext);
  const [newCode, setNewCode] = useState(customerCode || "");
  const [error, setError] = useState("");

  const modalStyle = useMemo(
    () => ({
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }),
    []
  );
  const classes = useStyle();
  const buttonClasses = buttonStyles({
    width: 130,
    marginTop: 0,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const updateCode = useCallback(async () => {
    if (!authHeader) return;
    try {
      setLoading(true);
      await deal.updateCustomerCode(authHeader, dealId, newCode);

      // API OKなら閉じる
      setSnackbar({
        openProps: true,
        message: "加盟店コードを更新しました。",
        severity: "info",
      });
      handleClose();
      setLoading(false);
      setExecuted((prev) => prev + 1); // 申請詳細画面のデータを再取得させるためセット
    } catch (err) {
      setError(getErrorMessage(err));
      setLoading(false);
    }
  }, [
    authHeader,
    dealId,
    handleClose,
    newCode,
    setLoading,
    setSnackbar,
    setExecuted,
  ]);

  return (
    <Modal
      open
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.popup}>
        <h2 id="simple-modal-title" className={classes.modalTitle}>
          {" 加盟店コード編集"}
        </h2>
        <div>
          <div className={classes.textAreaBlock}>
            <CustomTextField
              value={newCode}
              onChange={(e) => setNewCode(e.target.value)}
            />
          </div>
          {error && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error}
            </Alert>
          )}

          <div className={classes.buttonBlock}>
            <Button
              type="button"
              className={clsx(buttonClasses.button, classes.selectButton)}
              onClick={updateCode}
              variant="contained"
              disabled={!newCode}
            >
              <CheckIcon className={classes.checkIcon} />
              更新
            </Button>
            <Button
              type="button"
              className={clsx(buttonClasses.button, classes.cancelButton)}
              onClick={handleClose}
              variant="contained"
            >
              <CloseIcon className={classes.closeIcon} />
              キャンセル
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomerCodeModal;
