/**
 * 加盟店側-解約申請完了画面
 * メモ：
 */
import React, { useEffect, useMemo } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";

import { useContainerStyles } from "../../../../templates/styles";
import {
  buttonStyles,
  completeStyles,
} from "../../../../common/components/styles";

import { GUEST_COLOR } from "../../../../../constants/common";

import formStore from "../../../../../store/formStore";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";

const useStyles = makeStyles(() => ({
  icon: {
    position: "absolute",
    top: 8,
    left: 20,
  },
  buttonBlock: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  returnButton: {
    width: 250,
    marginLeft: 20,
    "& .MuiButton-label": {
      marginLeft: 34,
    },
  },
}));

const GuestTerminateDealNewCompletePage: React.FC<
  RouteComponentProps<{ id: string }>
> = (props: RouteComponentProps<{ id: string }>) => {
  const classes = useStyles();
  const completeClasses = completeStyles();
  const containerClasses = useContainerStyles();
  const buttonClasses = buttonStyles({
    width: "100%",
    marginTop: 0,
    backgroundColor: GUEST_COLOR.primary,
  });

  const { match } = props;

  const dealId = useMemo(() => match.params.id, [match]);

  // 申請種別に応じた情報を取得
  const dealTypeInfo = useMemo(
    () => formStore.getTerminateDealInfoByDealType(),
    []
  );
  const breadCrumbs = useMemo(
    () => [
      { name: dealTypeInfo.pageName, link: `/guest${dealTypeInfo.pageUrl}` },
      { name: "詳細", link: `/guest${dealTypeInfo.showPageUrl}` },
      { name: "完了", link: "" },
    ],
    [dealTypeInfo]
  );

  useEffect(() => {
    // 加盟店IDリセット
    formStore.setCustomerId(null);
    // 端末IDリセット
    formStore.setMasterTerminalId(null);
    // 申請種別リセット
    formStore.setDealType(null);
  }, []);

  return (
    <Dashboard
      title={`${dealTypeInfo.dealType}申請`}
      color={GUEST_COLOR.base}
      backgroundColor={GUEST_COLOR.primary}
      iconName="deal"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {/* 文言 */}
        <div className={completeClasses.message}>
          解約申請を作成しました。
          <br />
          <br />
          （申請番号：{dealId}）
        </div>
        <div className={classes.buttonBlock}>
          {/* ダッシュボードに戻るボタン */}
          <Button
            type="button"
            component={Link}
            to="/guest"
            className={clsx(buttonClasses.button, classes.returnButton)}
            variant="contained"
          >
            <KeyboardReturnIcon className={classes.icon} />
            ダッシュボードに戻る
          </Button>
        </div>
      </Container>
    </Dashboard>
  );
};

export default withRouter(GuestTerminateDealNewCompletePage);
