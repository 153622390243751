// ストレージのキー
export const STORAGE_KEYS = {
  DEAL: "deal",
  DEAL_PRE: "dealPre",
  DEAL_BASIC: "dealBasic",
  LOCAL_BUILD_DATE_TIME: "localBuildDateTime",
  CUSTOMER_DEALS: "customerDeals",
  EXPIRE_TIME: btoa("expireTime"), // トークン有効期限（知られたくないためエンコード）
} as const;

export default STORAGE_KEYS;
