/**
 * JTB側-端末管理 検索結果
 * メモ：端末管理の検索結果リストを表示する
 */
import React from "react";
import { Link } from "react-router-dom";

import clsx from "clsx";
import {
  Button,
  FormControl,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import {
  buttonStyles,
  formStyles,
  paginationStyles,
} from "../../../../common/components/styles";

import { TerminalSearchCondition } from "../../types";
import {
  ITerminalSearchResponse,
  ITerminalResult,
} from "../../../../../api/types";

import {
  ADMIN_COLOR,
  FONT,
  SEARCH_PER_PAGE,
} from "../../../../../constants/common";
import { TERMINAL_OPERATING_STATUS } from "../../../../../constants/terminal";

import SearchCountText from "../../../../common/components/atoms/SearchCountText";

// スタイル設定
const useStyles = makeStyles(() => ({
  cell: {
    fontFamily: FONT,
    padding: 1,
    color: "#000!important",
    border: `1px solid ${ADMIN_COLOR.primary}`,
    width: 200,
    paddingTop: 5,
    paddingBottom: 5,
  },
  thCell: {
    fontFamily: FONT,
    color: ADMIN_COLOR.primary,
    border: `1px solid ${ADMIN_COLOR.primary}!important`,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: ADMIN_COLOR.base,
    width: 200,
    position: "relative",
  },
  messageHeader: {
    fontFamily: FONT,
    textAlign: "center",
    fontSize: "1.1rem",
    marginTop: "40px",
    marginBottom: "10px",
  },
  detail: {
    width: "100px!important",
  },
  detailButton: {
    width: 100,
    padding: "4px 10px",
    margin: "10px 20px",
    backgroundColor: ADMIN_COLOR.primary,
    color: ADMIN_COLOR.base,
    borderRadius: 0,
  },
  grayOut: {
    backgroundColor: "gray",
  },
}));

// 端末検索結果のインターフェース
interface Props {
  resultsRows: ITerminalSearchResponse;
  conditionState: TerminalSearchCondition;
  getTerminals: (currentPage: string) => void;
}

const TerminalSearchResult: React.FC<Props> = (props: Props) => {
  const formClasses = formStyles({
    color: ADMIN_COLOR.base,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const buttonClasses = buttonStyles({
    width: 213,
    marginTop: 15,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const paginationClasses = paginationStyles({
    color: ADMIN_COLOR.base,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const classes = useStyles();

  const { resultsRows, conditionState, getTerminals } = props;

  // 表示用カラム名
  const columnNames = [
    "加盟店名",
    "加盟店コード",
    "端末識別番号",
    "端末種別",
    "初回DLL日",
  ];

  // 表示用データ作成
  const displayDataList: ITerminalResult[] = [];
  if (resultsRows.result) {
    resultsRows.result.forEach((resultData) => {
      // 端末種別はオブジェクトの文字列になっているので名称を取り出す
      const terminalType =
        resultData.terminalType !== ""
          ? JSON.parse(resultData.terminalType)
          : "";
      const displayData = {
        masterTerminalId: resultData.masterTerminalId,
        customerName: resultData.customerName,
        customerCode: resultData.customerCode,
        termiIdentNum: resultData.termiIdentNum,
        terminalType:
          typeof terminalType === "object"
            ? String(Object.values(terminalType))
            : terminalType,
        firstDLLDate: resultData.firstDLLDate,
        operatingStatus: resultData.operatingStatus,
      };
      displayDataList.push(displayData);
    });
  }

  return (
    <>
      {resultsRows.result !== undefined && resultsRows.totalCount === 0 && (
        <>
          <div className={classes.messageHeader}>
            {` ${"該当する端末はありません。"}`}
          </div>
        </>
      )}
      {resultsRows.result !== undefined && resultsRows.totalCount !== 0 && (
        <>
          {/* 検索結果件数 */}
          <SearchCountText count={resultsRows.totalCount as number} />
          {/* 検索結果 */}
          <FormControl className={formClasses.form}>
            <TableContainer>
              <Table className={formClasses.table} aria-label="simple table">
                <TableHead className={formClasses.th}>
                  <TableRow>
                    {/* タイトル */}
                    {columnNames.map((column) => (
                      <TableCell
                        key={column}
                        align="center"
                        className={classes.thCell}
                      >
                        {column}
                      </TableCell>
                    ))}
                    <TableCell
                      align="center"
                      className={clsx(classes.thCell, classes.detail)}
                    >
                      操作
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* 検索結果のリスト(解約済ステータスはグレーアウトする) */}
                  {displayDataList.map((rowData) => {
                    return (
                      <TableRow
                        key={rowData.masterTerminalId}
                        className={
                          rowData.operatingStatus ===
                          TERMINAL_OPERATING_STATUS.TERMINATED
                            ? classes.grayOut
                            : ""
                        }
                      >
                        {/* 加盟店名 */}
                        <TableCell align="center" className={classes.cell}>
                          {rowData.customerName}
                        </TableCell>
                        {/* 加盟店コード */}
                        <TableCell align="center" className={classes.cell}>
                          {rowData.customerCode}
                        </TableCell>
                        {/* 端末識別番号 */}
                        <TableCell align="center" className={classes.cell}>
                          {rowData.termiIdentNum}
                        </TableCell>
                        {/* 端末種別 */}
                        <TableCell align="center" className={classes.cell}>
                          {rowData.terminalType}
                        </TableCell>
                        {/* 初回DLL日 */}
                        <TableCell align="center" className={classes.cell}>
                          {rowData.firstDLLDate}
                        </TableCell>
                        {/* 詳細ボタン */}
                        <TableCell
                          align="center"
                          className={clsx(classes.cell, classes.detail)}
                        >
                          <Button
                            className={clsx(
                              buttonClasses.button,
                              classes.detailButton
                            )}
                            variant="contained"
                            component={Link}
                            to={{
                              pathname: `/dealer/terminal/${rowData.masterTerminalId}`,
                              state: conditionState,
                            }}
                          >
                            詳細
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {/* ページネーション */}
            <div className={paginationClasses.parent}>
              <Pagination
                count={Math.ceil(
                  (resultsRows.totalCount as number) / SEARCH_PER_PAGE
                )}
                className={clsx(
                  paginationClasses.pagination,
                  paginationClasses.pageCurrent
                )}
                onChange={(e: React.ChangeEvent<unknown>, num) =>
                  getTerminals(String(num))
                }
                page={Number(conditionState.pageNumber)}
              />
            </div>
          </FormControl>
        </>
      )}
    </>
  );
};

export default TerminalSearchResult;
