/**
 * 共通-「\n」を改行するテキストコンポーネント
 */
import React from "react";

const BreakElementForLineText: React.FC<{ text: string }> = (props: {
  text: string;
}) => {
  const { text } = props;

  const split = text.split("\n");

  return (
    <>
      {split.map((value, index) => (
        // ブラウザのコンソールエラー表示回避のためvalueが空の場合は乱数をkeyにしておく
        <React.Fragment key={value || `${Math.random()}`}>
          {value}
          {index < split.length - 1 && <br />}
        </React.Fragment>
      ))}
    </>
  );
};

export default BreakElementForLineText;
