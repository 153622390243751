/**
 * JTB側-電話番号変更画面
 * メモ：自身の電話番号を変更する画面。確認コードを発行する。
 */
import React, { useContext, useState } from "react";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import { useContainerStyles } from "../../../../templates/styles";
import {
  buttonStyles,
  loginFormStyles,
} from "../../../../common/components/styles";

import { UserPhoneNumEditItemObject } from "../../types";

import {
  ADMIN_COLOR,
  PHONE_NUMBER_CHAR_TYPE,
} from "../../../../../constants/common";

import { AuthContext } from "../../../../../cognito/AuthContext";
import { checkPattern } from "../../../../../form/validation";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import AlertMessage from "../../../../common/components/atoms/AuthAlertMsg";
import Loading from "../../../../common/components/molecules/Loading";
import SettingUserPhoneNumEditForm from "../organisms/SettingUserPhoneNumEditForm";

const breadCrumbs = [
  { name: "設定", link: "/dealer/setting" },
  { name: "電話番号変更", link: "" },
];

const useStyles = makeStyles(() => ({
  editButton: {
    margin: "0 auto",
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 10,
    paddingLeft: 40,
  },
  editIcon: {
    position: "absolute",
    top: 8,
    left: 10,
  },
  linkIcon: {
    position: "relative",
    top: 8,
    left: 5,
    fontSize: "1.8em",
    color: ADMIN_COLOR.primary,
  },
  alertInfo: {
    marginBottom: 20,
  },
}));

const SettingUserPhoneNumEditPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const containerClasses = useContainerStyles();
  const buttonClasses = buttonStyles({
    width: 200,
    marginTop: 20,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const loginFormClasses = loginFormStyles();
  const classes = useStyles();

  const { history } = props;

  const [changeUserPhoneNumError, setChangeUserPhoneNumError] = useState("");
  const [loadingOpen, setLoadingOpen] = useState(false);
  const { changeUserPhoneNum } = useContext(AuthContext);

  const [userPhoneNumEditState, setUserPhoneNumEditState] =
    useState<UserPhoneNumEditItemObject>({
      newUserPhoneNum: "",
    });

  const formatCheck = (string: string) => {
    const errorArray = checkPattern(
      string,
      PHONE_NUMBER_CHAR_TYPE,
      "電話番号形式",
      []
    );
    return errorArray[0];
  };

  const handleToConfirmPage = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();

    // 電話番号のフォーマットチェック
    const error = formatCheck(userPhoneNumEditState.newUserPhoneNum);
    if (error) {
      setChangeUserPhoneNumError("正しい電話番号形式をご指定ください");
      return;
    }

    setLoadingOpen(true);

    // CognitoAuthProviderの電話番号変更（確認コード送信）メソッドを実行
    const { result, message } = await changeUserPhoneNum(userPhoneNumEditState);

    setLoadingOpen(false);
    if (result) {
      // 成功した場合、電話番号変更確認コード入力画面に遷移
      setChangeUserPhoneNumError("");
      history.push({
        pathname: "/dealer/setting/UserPhoneNum/edit/confirm/",
        state: {
          userPhoneNumEditSkip: false,
          newUserPhoneNum: userPhoneNumEditState.newUserPhoneNum,
        },
      });
      return;
    }
    setChangeUserPhoneNumError(message);
  };

  const handleUserPhoneNumEditSkip = (e: React.MouseEvent<HTMLElement>) => {
    const { newUserPhoneNum } = userPhoneNumEditState;
    setChangeUserPhoneNumError("");

    if (!newUserPhoneNum) {
      // 新しい電話番号が未入力の場合はエラー
      e.preventDefault();
      setChangeUserPhoneNumError(
        "確認コードを発行した時点の新しい電話番号を入力してください。"
      );
    }

    // 電話番号のフォーマットチェック
    const error = formatCheck(userPhoneNumEditState.newUserPhoneNum);
    if (error) {
      e.preventDefault();
      setChangeUserPhoneNumError("正しい電話番号形式をご指定ください");
    }
  };

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Dashboard
        title="設定"
        color={ADMIN_COLOR.base}
        backgroundColor={ADMIN_COLOR.primary}
        iconName="setting"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {/* エラーメッセージ */}
          {changeUserPhoneNumError && (
            <AlertMessage errorMessage={changeUserPhoneNumError} />
          )}
          <form>
            {/* 入力フォーム */}
            <SettingUserPhoneNumEditForm
              userPhoneNumEditState={userPhoneNumEditState}
              setUserPhoneNumEditState={setUserPhoneNumEditState}
            />
            {/* 既に確認コードをお持ちの方はこちらリンク */}
            <Link
              to={{
                pathname: "/dealer/setting/UserPhoneNum/edit/confirm/",
                state: {
                  userPhoneNumEditSkip: true,
                  newUserPhoneNum: userPhoneNumEditState.newUserPhoneNum,
                },
              }}
              className={loginFormClasses.link}
              onClick={handleUserPhoneNumEditSkip}
            >
              既に確認コードをお持ちの方はこちら
              <KeyboardArrowRightIcon className={classes.linkIcon} />
            </Link>
            {/* 文言 */}
            <p>
              確認コードを再発行したい場合は、同じ内容を再度登録してください。
            </p>
            {/* 確認コードを発行するボタン */}
            <Button
              type="submit"
              onClick={handleToConfirmPage}
              className={clsx(buttonClasses.button, classes.editButton)}
              variant="contained"
              disabled={loadingOpen} // 二重送信対策
            >
              <EditIcon className={classes.editIcon} />
              変更する
            </Button>
          </form>
        </Container>
      </Dashboard>
    </>
  );
};

export default withRouter(SettingUserPhoneNumEditPage);
