/**
 * 共通-セレクトボックス
 */
import React, { useEffect, useRef, useState } from "react";

import clsx from "clsx";
import {
  makeStyles,
  Theme,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";

import { FONT } from "../../../../constants/common";

import { cloneArray } from "../../../../utils/object";

type StyleProps = { widthValue: string | undefined };

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  select: {
    "& .MuiFilledInput-root": {
      backgroundColor: "#f2f7fb",
      borderRadius: 4,
      width: ({ widthValue }) => widthValue,
      "&:before": {
        borderBottom: "none",
      },
    },
    "& .MuiFilledInput-root.Mui-focused": {
      borderBottom: "none",
      backgroundColor: "#fff",
      transition: "none",
    },
    "& .MuiFilledInput-underline": {
      "&:after": {
        content: "none",
      },
    },
    "& .MuiFilledInput-input": {
      padding: "12px 12px 10px",
      borderRadius: 4,
    },
  },
  selectInput: {
    fontFamily: FONT,
    "& .MuiFormControl-root": {
      margin: 0,
    },
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
    width: "100%",
  },
}));

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | Array<string>;
  pulldownArray: Array<string>;
  width?: string | "logestCalc";
  isError?: boolean;
}

const CustomSelect: React.FC<Props> = (props: Props) => {
  const { pulldownArray, width, onChange, value, isError } = props;

  // 幅設定
  const [widthValue, setWidthValue] = useState(width);
  const styleProps = { widthValue };
  const classes = useStyles(styleProps);

  // "longestCalc"指定で最長選択肢に合わせる
  const [init, setInit] = useState(width === "longestCalc");
  const [select, setSelect] = useState(value);
  const longRef = useRef<HTMLLIElement | null>(null);

  // 最長選択肢取得（選択肢の並び順変わらないようにコピーする）
  const sortArray = cloneArray(pulldownArray);
  const mostLong = sortArray.sort(
    (a: string, b: string) => b.length - a.length
  )[0];

  // 最長選択肢の幅取得+設定
  useEffect(() => {
    if (longRef.current && width === "longestCalc") {
      setWidthValue(`${longRef.current.offsetWidth + 20}px`);
      // ダミー要素を取り除く
      setInit(false);
    }
  }, [width]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelect(event.target.value as string);
    onChange(event as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <FormControl
      variant="filled"
      className={clsx(classes.formControl, classes.select)}
      error={isError}
    >
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={select}
        defaultValue=""
        className={classes.selectInput}
        onChange={handleChange}
        label="Select"
      >
        {pulldownArray.map((pulldown) => (
          <MenuItem key={pulldown} value={pulldown}>
            {pulldown}
          </MenuItem>
        ))}
      </Select>
      {/* 最長幅取得のために最長選択肢のダミー要素。幅設定後unmountされる */}
      {init && (
        <MenuItem key={`dummy_${mostLong}`} value={mostLong} ref={longRef}>
          {mostLong}
        </MenuItem>
      )}
    </FormControl>
  );
};

CustomSelect.defaultProps = {
  width: "300px",
};

export default CustomSelect;
