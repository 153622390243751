/**
 * JTB側-ダッシュボード 申請表示領域
 */
import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import clsx from "clsx";
import moment from "moment";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import { TDashboardData } from "../../types";
import {
  TDealTypeNum,
  TSortCondition,
  TSortKey,
} from "../../../../common/components/types";

import {
  ADMIN_COLOR,
  FONT,
  MANAGE_PER_PAGE,
} from "../../../../../constants/common";
import {
  WORKFLOW_STATUS_MESSAGES_FOR_DEALER,
  TWorkflowStatus,
} from "../../../../../constants/workflowStatus";
import {
  DEAL_TYPE_NUM_TO_STR,
  DEAL_SHOW_PAGE_URL_FOR_DEALER,
} from "../../../../../constants/deal";

import { toOmittedString } from "../../../../../utils/string";
import formStore from "../../../../../store/formStore";

import LineBreaksText from "../../../../common/components/atoms/LineBreaksText";
import CustomPagination from "../../../../common/components/atoms/CustomPagination";
import ManagerHeading from "../../../../common/components/atoms/ManagerHeading";

const useStyles = makeStyles(() => ({
  tableContainer: {
    width: 1100,
    marginRight: 20,
    marginTop: 10,
    backgroundColor: ADMIN_COLOR.base,
    paddingBottom: 15,
  },
  table: {
    border: "1px solid #d2d5d8",
    borderLeft: "none",
    borderRight: "none",
  },
  thCell: {
    fontFamily: FONT,
    fontSize: "0.9em",
    padding: 4,
    backgroundColor: ADMIN_COLOR.base,
    color: ADMIN_COLOR.primary,
  },
  clWidth200: {
    width: 200,
  },
  clWidth175: {
    width: 175,
  },
  clWidth135: {
    width: 135,
  },
  clWidth125: {
    width: 125,
  },
  clWidth100: {
    width: 100,
  },
  cell: {
    fontFamily: FONT,
    fontWeight: 500,
    padding: 3,
    fontSize: "0.8em",
  },
  button: {
    width: 80,
    marginLeft: 0,
    fontFamily: FONT,
    backgroundColor: ADMIN_COLOR.base,
    boxShadow: "none",
    color: ADMIN_COLOR.primary,
    border: `1px solid ${ADMIN_COLOR.primary}`,
    fontWeight: 600,
    fontSize: "1.0em",
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 17,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: `${ADMIN_COLOR.btnHover}!important`,
    },
  },
  message: {
    fontFamily: FONT,
    textAlign: "center",
    fontSize: "1.0rem",
    marginTop: "40px",
    marginBottom: "50px",
  },
  infoIcon: {
    color: ADMIN_COLOR.primary,
    verticalAlign: "top",
    fontSize: "1.3rem",
  },
  sortButton: {
    minWidth: 15,
    padding: "0 4px",
  },
}));

/**
 * @param title: Tableのタイトル
 * @param data: Tableの表示データ（totalCount, sortConditionも含む）
 * @param tableStatus: Tableタイトルのアイコン用
 * @param apiFunc: data更新メソッド（API）
 * @param mode: JTB担当者欄制御用
 */
type Props = {
  title: string;
  data: TDashboardData | undefined;
  tableStatus: string;
  apiFunc: (page: number, sortCondition: TSortCondition) => void;
};

const DealTable: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const { title, data, tableStatus, apiFunc } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const sortCondition = useMemo(() => data?.sortCondition, [data]);

  const handlePageChange = useCallback(
    (page: number) => {
      if (!sortCondition) return;
      apiFunc(page, sortCondition);
      setCurrentPage(page);
    },
    [apiFunc, sortCondition]
  );

  // 2ページ目以降で現在のページにデータがない場合は1ページ前に戻す
  if (sortCondition && (!data || data?.data.length <= 0) && currentPage > 1) {
    apiFunc(currentPage - 1, sortCondition);
  }

  // ソートボタン処理
  const sortButton = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const condition = event.currentTarget.value as TSortKey;
      const targetName = event.currentTarget.name;
      apiFunc(currentPage, { [targetName]: condition });
    },
    [currentPage, apiFunc]
  );

  const SortButtonDisplay = useCallback(
    ({ name }) => {
      if (!sortCondition) return null;
      // 現在の順序（sortCondition[name]）の反対のものを入れる。初期表示時はundefinedなのでascを入れる（=降順）
      const cond =
        sortCondition[name] && sortCondition[name] === "ASC" ? "DESC" : "ASC";
      // condの反対のもの（=現在の順序）が入る。
      const arrow = cond === "ASC" ? "▼" : "▲";
      return (
        <Button
          className={classes.sortButton}
          onClick={sortButton}
          name={name}
          value={cond}
        >
          {arrow}
        </Button>
      );
    },
    [classes.sortButton, sortButton, sortCondition]
  );

  return (
    <div className={clsx(classes.tableContainer)}>
      <ManagerHeading
        title={title}
        tableStatus={tableStatus}
        backgroundColor={ADMIN_COLOR.primary}
        width={1100}
      />
      {!data || data.data.length <= 0 ? (
        <div className={clsx(classes.message)}>
          <InfoIcon className={classes.infoIcon} />
          {` ${"該当する申請はありません。"}`}
        </div>
      ) : (
        <>
          <TableContainer>
            <Table className={clsx(classes.table)} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    className={clsx(classes.thCell, classes.clWidth125)}
                  >
                    申請番号
                    <SortButtonDisplay name="sortByDealId" />
                  </TableCell>
                  <TableCell
                    align="center"
                    className={clsx(classes.thCell, classes.clWidth125)}
                  >
                    申請種別
                    <SortButtonDisplay name="sortByDealType" />
                  </TableCell>
                  <TableCell
                    align="center"
                    className={clsx(classes.thCell, classes.clWidth200)}
                  >
                    会社名
                    <SortButtonDisplay name="sortByContractorCompanyName" />
                  </TableCell>
                  <TableCell
                    align="center"
                    className={clsx(classes.thCell, classes.clWidth200)}
                  >
                    加盟店名
                    <SortButtonDisplay name="sortByCustomerName" />
                  </TableCell>
                  <TableCell
                    align="center"
                    className={clsx(classes.thCell, classes.clWidth125)}
                  >
                    申請日
                    <SortButtonDisplay name="sortByApplicationDate" />
                  </TableCell>
                  <TableCell
                    align="center"
                    className={clsx(classes.thCell, classes.clWidth135)}
                  >
                    ステータス
                    <SortButtonDisplay name="sortByWorkflowStatus" />
                  </TableCell>
                  <TableCell
                    align="center"
                    className={clsx(classes.thCell, classes.clWidth175)}
                  >
                    最終申請更新日付
                    <SortButtonDisplay name="sortByUpdatedAt" />
                  </TableCell>
                  <TableCell
                    className={clsx(classes.thCell, classes.clWidth100)}
                    align="center"
                  >
                    操作
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.data.map((row) => (
                  <TableRow key={row.id}>
                    {/* 申請ID */}
                    <TableCell align="center" className={classes.cell}>
                      {row.id || "−"}
                    </TableCell>
                    {/* 申請種別 */}
                    <TableCell align="center" className={classes.cell}>
                      {DEAL_TYPE_NUM_TO_STR[row.dealType as TDealTypeNum]}
                    </TableCell>
                    {/* 会社名 */}
                    <TableCell align="center" className={classes.cell}>
                      {row.companyName}
                    </TableCell>
                    {/* 加盟店名 */}
                    <TableCell align="center" className={classes.cell}>
                      <LineBreaksText
                        text={toOmittedString(row.customerName, 0)}
                      />
                    </TableCell>
                    {/* 申請日 */}
                    <TableCell align="center" className={classes.cell}>
                      {row.applicationDate
                        ? moment(
                            row.applicationDate,
                            "YYYY/MM/DD HH:mm:ss"
                          ).format("YYYY/MM/DD")
                        : ""}
                    </TableCell>
                    {/* ステータス */}
                    <TableCell align="center" className={classes.cell}>
                      {
                        WORKFLOW_STATUS_MESSAGES_FOR_DEALER[
                          parseInt(row.workflowStatus, 10) as TWorkflowStatus
                        ]
                      }
                    </TableCell>
                    {/* 最終申請更新日 */}
                    <TableCell align="center" className={classes.cell}>
                      {row.updatedAt
                        ? moment.utc(row.updatedAt).format("YYYY/MM/DD")
                        : ""}
                    </TableCell>
                    {/* 操作 */}
                    <TableCell align="center" className={classes.cell}>
                      {
                        /* TODO 権限チェック */
                        <Button
                          component={Link}
                          to={`${
                            DEAL_SHOW_PAGE_URL_FOR_DEALER[
                              row.dealType as TDealTypeNum
                            ]
                          }${row.id}`}
                          onClick={() => {
                            // 主に解約用にdealTypeをセット
                            formStore.setEditDealType(
                              DEAL_TYPE_NUM_TO_STR[row.dealType as TDealTypeNum]
                            );
                          }}
                          className={clsx(classes.button)}
                          variant="contained"
                        >
                          詳細
                        </Button>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* ページネーション */}
          <CustomPagination
            count={Math.ceil(data.totalCount / MANAGE_PER_PAGE)}
            color={ADMIN_COLOR.base}
            backgroundColor={ADMIN_COLOR.primary}
            onChange={handlePageChange}
            currentPage={currentPage}
          />
        </>
      )}
    </div>
  );
};

// dataが変化した場合だけ再描画させる
export default React.memo(
  DealTable,
  (prevProps, nextProps) => prevProps.data === nextProps.data
);
