/**
 * 加盟店側-精算明細書検索・一覧画面
 * メモ：ユーザーに紐づく各加盟店の直近1ヶ月の精算明細書を表示する。精算明細書のダウンロードも可能
 * メモ：システム担当者の場合のみ検索欄が表示される
 */
import React, { useContext, useEffect, useState } from "react";

import clsx from "clsx";
import { Container, makeStyles, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";

import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { GuestBillSearchCondition } from "../../types";
import { IBillSearchResponse } from "../../../../../api/types";

import { GUEST_COLOR } from "../../../../../constants/common";

import bill from "../../../../../api/bill";
import { AuthContext } from "../../../../../cognito/AuthContext";
import { getErrorMessage } from "../../../../../utils/error";
import { isSysAd } from "../../../../../utils/auth";

import Loading from "../../../../common/components/molecules/Loading";
import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import GuestBillSearchConditionForm from "../organisms/GuestBillSearchConditionForm";
import GuestBillSearchResult from "../organisms/GuestBillSearchResult";

const useStyles = makeStyles(() => ({
  alert: {
    marginTop: "30px",
  },
  form: {
    width: 800,
  },
  searchButton: {
    paddingBottom: 8,
    margin: "0 auto",
    marginTop: 20,
  },
  searchIcon: {
    position: "absolute",
    top: 8,
    left: 50,
  },
}));

const GuestDataBillPage: React.FC = () => {
  const classes = useStyles();
  const containerClasses = useContainerStyles();
  const searchButtonClasses = buttonStyles({
    width: 180,
    marginTop: 15,
    textLeft: 10,
    backgroundColor: GUEST_COLOR.primary,
  });

  const breadCrumbs = [
    { name: "帳票・データ出力", link: "/guest/data" },
    { name: isSysAd() ? "精算明細書検索・一覧" : "精算明細書一覧", link: "" },
  ];

  const { authHeader } = useContext(AuthContext);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [searchApiErrorMsg, setSearchApiErrorMsg] = useState<string>("");
  const [searchCondition, setSearchCondition] =
    useState<GuestBillSearchCondition>({
      customerCode: "",
      customerName: "",
      pageNumber: "1",
    });
  const [searchResult, setSearchResult] = useState<IBillSearchResponse>({});

  // 精算明細書検索&取得
  const getBills = (pageNumber: string) => {
    setLoadingOpen(true);

    bill
      .search(
        pageNumber, // ページ番号、検索時は1固定
        authHeader as string,
        searchCondition.customerCode,
        searchCondition.customerName
      )
      .then((res) => {
        // 精算明細書リストの取得結果と検索条件をセット
        setSearchResult(res.data);
        setSearchCondition({ ...searchCondition, pageNumber });
        setSearchApiErrorMsg("");
      })
      .catch((error) => {
        setSearchApiErrorMsg(getErrorMessage(error));
        throw error;
      })
      .finally(() => {
        setLoadingOpen(false);
      });
  };

  // システム担当者でない場合は初期表示時にAPIを実行
  useEffect(() => {
    if (!isSysAd()) getBills("1");
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Dashboard
        title="帳票・データ出力"
        color={GUEST_COLOR.base}
        backgroundColor={GUEST_COLOR.primary}
        iconName="data"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {searchApiErrorMsg !== "" && (
            <Alert className={classes.alert} severity="error">
              {searchApiErrorMsg}
            </Alert>
          )}
          {/* 精算明細書検索フォーム */}
          {/* システム担当者の場合のみ表示 */}
          {isSysAd() && (
            <form className={classes.form}>
              <GuestBillSearchConditionForm
                searchCondition={searchCondition}
                setSearchCondition={setSearchCondition}
              />
              <Button
                className={clsx(
                  searchButtonClasses.button,
                  classes.searchButton
                )}
                variant="contained"
                type="submit"
                onClick={() => getBills("1")}
                disabled={loadingOpen} // 二重送信対策
              >
                <SearchIcon className={classes.searchIcon} />
                検索
              </Button>
            </form>
          )}
          {searchApiErrorMsg === "" && (
            <>
              {/* 精算明細書検索結果 */}
              {searchResult && (
                <GuestBillSearchResult
                  resultsRows={searchResult}
                  conditionState={searchCondition}
                  setApiErrorMsg={setSearchApiErrorMsg}
                  setLoadingOpen={setLoadingOpen}
                  getBills={getBills}
                />
              )}
            </>
          )}
        </Container>
      </Dashboard>
    </>
  );
};

export default GuestDataBillPage;
