/**
 * JTB側-契約者編集画面
 * メモ：契約者の編集を行う画面
 */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import clsx from "clsx";
import { Button, Container, makeStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import { Alert, AlertTitle } from "@material-ui/lab";

import useContainerStyles from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { TJsonRowObject } from "../../../../common/components/types";

import { ADMIN_COLOR } from "../../../../../constants/common";

import { GlobalPopupContext, PathContext } from "../../../../../App";
import formStore from "../../../../../store/formStore";
import deal from "../../../../../api/deal";
import contractors from "../../../../../api/contractors";
import preloadFormContent from "../../../../../api/utils/preUtils";
import {
  getContractorContents,
  json2FormData,
} from "../../../../../utils/formData";
import getErrorMessage from "../../../../../utils/error";
import { AuthContext } from "../../../../../cognito/AuthContext";

import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import Dashboard from "../../../../templates/Dashboard";
import TabPanel from "../../../../common/components/atoms/TabPanel";
import NewForm from "../../../../form/components/organisms/NewForm";

const useStyles = makeStyles(() => ({
  returnButton: {
    margin: "0 auto",
    paddingTop: 8,
    paddingBottom: 10,
    paddingLeft: 42,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 24,
    },
  },
  checkButton: {
    margin: "0 auto",
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 8,
    color: `${ADMIN_COLOR.primary}!important`,
    border: `1px solid ${ADMIN_COLOR.primary}`,
    backgroundColor: `${ADMIN_COLOR.base}!important`,
    borderRadius: 0,
    "& .MuiButton-label": {
      marginLeft: 16,
    },
    "&:hover": {
      backgroundColor: `${ADMIN_COLOR.btnHover}!important`,
    },
  },
  returnIcon: {
    position: "absolute",
    top: 10,
    left: 31,
  },
  checkIcon: {
    position: "absolute",
    top: 8,
    left: 20,
  },
  buttonContainer: {
    margin: "0 auto",
    clear: "both",
    height: 100,
    width: 284,
    marginTop: 20,
  },
  gridButtons: {
    float: "left",
  },
}));

const ContractorEditPage: React.FC<RouteComponentProps<{ id: string }>> = (
  props: RouteComponentProps<{ id: string }>
) => {
  const { history, match } = props;

  const { authHeader } = useContext(AuthContext);
  const { setLoading, setSnackbar } = useContext(GlobalPopupContext);
  const { prevPath } = useContext(PathContext);
  const [init, setInit] = useState(false);
  const [errorMessage, setError] = useState("");

  const breadCrumbs = useMemo(
    () => [
      { name: "契約者管理・検索", link: "/dealer/contractor" },
      { name: "詳細", link: `/dealer/contractor/${match.params.id}` },
      { name: "編集", link: "" },
    ],
    [match.params.id]
  );

  const getData = useCallback(async () => {
    if (!authHeader) return;
    setLoading(true);
    try {
      if (prevPath.includes(`/contractor/${match.params.id}/edit/confirm`)) {
        // 確認画面からの遷移時はAPIで取り直さない
        setError("");
        setLoading(false);
        setInit(true);
      } else {
        // Json取得
        const ret = await deal.getFormMaster(authHeader, "latest");
        await preloadFormContent(authHeader, "latest");
        const data = json2FormData(ret.data.result as TJsonRowObject[]);
        formStore.setFormData(
          data.formData,
          data.formGroupData,
          data.columnToCategory
        );
        // 契約者情報取得、反映
        const showApiRet = await contractors.show(authHeader, match.params.id);
        formStore.updateContents(
          getContractorContents(
            showApiRet.data.result.contractors[0].masterContractorForms
          ).updateData,
          "dealer"
        );

        setError("");
        setLoading(false);
        setInit(true);
      }
      // eslint-disable-next-line
    } catch (error: any) {
      setError(getErrorMessage(error));
      setLoading(false);
      setInit(true);
    }
  }, [setLoading, authHeader, prevPath, match.params.id]);

  const containerClasses = useContainerStyles();
  const moveButtonClasses = buttonStyles({
    width: 130,
    marginTop: 20,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const classes = useStyles();

  // Jsonデータ取得
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const handleToConfirmPage = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    // ボタンクリック以外（エンターキー押下など）の場合は何もしない
    if (e.detail !== 1) return;

    // バリデーション実行
    if (formStore.validationFormCategory("dealer", 1)) {
      formStore.saveDraft("contractor");
      history.push(`/dealer/contractor/${match.params.id}/edit/confirm`);
    } else {
      setSnackbar({
        openProps: true,
        message: "入力不備のフィールドがあります。",
      });
    }
  };

  return (
    <Dashboard
      title="契約者編集"
      color={ADMIN_COLOR.base}
      backgroundColor={ADMIN_COLOR.primary}
      iconName="deal"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        {init && (
          <form>
            {/* 入力フォーム */}
            <TabPanel value={0} index={0} borderColor={ADMIN_COLOR.primary}>
              <NewForm
                formCategory={1}
                requiredKey="dealer"
                colors={ADMIN_COLOR}
                terminalGridId={0}
              />
            </TabPanel>
            <div className={classes.buttonContainer}>
              {/* 戻るボタン */}
              <Button
                className={clsx(
                  moveButtonClasses.button,
                  classes.returnButton,
                  classes.gridButtons
                )}
                type="button"
                component={Link}
                to={`/dealer/contractor/${match.params.id}`}
                variant="contained"
              >
                <KeyboardReturnIcon className={classes.returnIcon} />
                戻る
              </Button>
              {/* 確認するボタン */}
              <Button
                type="submit"
                onClick={handleToConfirmPage}
                className={clsx(moveButtonClasses.button, classes.checkButton)}
                variant="contained"
              >
                <CheckIcon className={classes.checkIcon} />
                確認する
              </Button>
            </div>
          </form>
        )}
      </Container>
    </Dashboard>
  );
};

export default withRouter(ContractorEditPage);
