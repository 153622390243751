/**
 * 加盟店側-申請管理 検索結果
 */
import React from "react";
import { Link } from "react-router-dom";

import clsx from "clsx";
import {
  Button,
  FormControl,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import {
  formStyles,
  buttonStyles,
  paginationStyles,
} from "../../../../common/components/styles";

import { DealSearchCondition } from "../../types";
import {
  TDealTypeNum,
  TDealTypeStr,
} from "../../../../common/components/types";
import { IDealListResponse } from "../../../../../api/types";

import {
  GUEST_COLOR,
  FONT,
  DEAL_SEARCH_PER_PAGE,
} from "../../../../../constants/common";
import {
  DEAL_TYPE_NUM_TO_STR,
  DEAL_SHOW_PAGE_URL_FOR_GUEST,
} from "../../../../../constants/deal";

import { getWorkflowStatusName } from "../../../../../utils/workflowStatus";
import formStore from "../../../../../store/formStore";

import SearchCountText from "../../../../common/components/atoms/SearchCountText";

const useStyles = makeStyles(() => ({
  cell: {
    fontFamily: FONT,
    padding: 1,
    backgroundColor: GUEST_COLOR.base,
    color: "#000!important",
    border: `1px solid ${GUEST_COLOR.primary}`,
    width: 200,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: "0.9em",
  },
  thCell: {
    fontFamily: FONT,
    color: GUEST_COLOR.primary,
    border: `1px solid ${GUEST_COLOR.primary}!important`,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: GUEST_COLOR.base,
    width: 200,
    position: "relative",
    fontSize: "0.9em",
  },
  messageHeader: {
    fontFamily: FONT,
    textAlign: "center",
    fontSize: "1.1rem",
    marginTop: "40px",
    marginBottom: "10px",
  },
  detail: {
    width: "100px!important",
  },
  detailButton: {
    width: 100,
    padding: "4px 10px",
    margin: "10px 20px",
    backgroundColor: GUEST_COLOR.primary,
    color: GUEST_COLOR.base,
    borderRadius: 0,
  },
}));

// 申請リスト表示配列
type DealListDisplayData = {
  id: number; // 申請番号
  customerCode: string; // 加盟店コード
  customerName: string; // 加盟店名
  termiIdentNum: string; // 端末識別番号
  applicationDate: string; // 申請日
  dealType: string; // 申請種別
  workflowStatus: string; // ステータス
  detailLink: string; // 詳細リンク
};

// 申請検索結果
interface Props {
  resultsRows: IDealListResponse;
  conditionState: DealSearchCondition;
  getDeals: (pageNumber: string) => void;
}

const DealSearchResult: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const formClasses = formStyles({
    color: GUEST_COLOR.base,
    backgroundColor: GUEST_COLOR.primary,
  });
  const buttonClasses = buttonStyles({
    width: 213,
    marginTop: 15,
    backgroundColor: GUEST_COLOR.primary,
  });
  const paginationClasses = paginationStyles({
    color: GUEST_COLOR.base,
    backgroundColor: GUEST_COLOR.primary,
  });

  const { resultsRows, conditionState, getDeals } = props;

  // 表示用カラム名
  const columnNames = [
    "申請番号",
    "加盟店コード",
    "加盟店名",
    "端末識別番号",
    "申請日",
    "申請種別",
    "ステータス",
  ];

  // 表示用データ作成
  const displayDataList: DealListDisplayData[] = [];
  if (resultsRows.result && resultsRows.result.deals) {
    resultsRows.result.deals.forEach((resultData) => {
      // 申請種別は数字の文字列になっているので名称を取得する
      const dealType =
        DEAL_TYPE_NUM_TO_STR[resultData.dealType as TDealTypeNum];
      // ワークフローステータスは数字の文字列になっているので名称を取得する
      const workflowStatus = getWorkflowStatusName(
        resultData.dealType,
        resultData.workflowStatus
      );
      // 端末が複数ある場合
      let termiIdentNum = "";
      if (resultData.customer.terminals.length > 0) {
        const terminalCount = resultData.customer.terminals.length - 1;
        termiIdentNum =
          terminalCount === 0
            ? resultData.customer.terminals[0].terminalForm.termiIdentNum
            : `${resultData.customer.terminals[0].terminalForm.termiIdentNum}(他${terminalCount}件)`;
      }
      // 詳細リンク
      const detailLink = `${
        DEAL_SHOW_PAGE_URL_FOR_GUEST[resultData.dealType as TDealTypeNum]
      }${resultData.id}`;
      const displayData = {
        id: resultData.id,
        customerCode: resultData.customer.customerForm.customerCode,
        customerName: resultData.customer.customerForm.customerName,
        termiIdentNum,
        applicationDate: resultData.dealOtherForm.applicationDate,
        dealType,
        workflowStatus,
        detailLink,
      };
      displayDataList.push(displayData);
    });
  }

  return (
    <>
      {resultsRows.result !== undefined &&
        resultsRows.result?.dealsCount === 0 && (
          <>
            <div className={classes.messageHeader}>
              {` ${"該当する申請はありません。"}`}
            </div>
          </>
        )}
      {resultsRows.result !== undefined &&
        resultsRows.result?.dealsCount !== 0 && (
          <>
            {/* 検索結果件数 */}
            <SearchCountText count={resultsRows.result?.dealsCount as number} />
            {/* 検索結果 */}
            <FormControl className={formClasses.form}>
              <TableContainer>
                <Table className={formClasses.table} aria-label="simple table">
                  <TableHead className={formClasses.th}>
                    <TableRow>
                      {/* タイトル */}
                      {columnNames.map((column) => (
                        <TableCell
                          key={column}
                          align="center"
                          className={classes.thCell}
                        >
                          {column}
                        </TableCell>
                      ))}
                      <TableCell
                        align="center"
                        className={clsx(classes.thCell, classes.detail)}
                      >
                        操作
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {displayDataList.map((rowData) => (
                      <TableRow key={rowData.id}>
                        {/* 申請番号 */}
                        <TableCell align="center" className={classes.cell}>
                          {rowData.id || "-"}
                        </TableCell>
                        {/* 加盟店コード */}
                        <TableCell align="center" className={classes.cell}>
                          {rowData.customerCode || "-"}
                        </TableCell>
                        {/* 加盟店名 */}
                        <TableCell align="center" className={classes.cell}>
                          {rowData.customerName || "-"}
                        </TableCell>
                        {/* 端末識別番号 */}
                        <TableCell align="center" className={classes.cell}>
                          {rowData.termiIdentNum || "-"}
                        </TableCell>
                        {/* 申請日 */}
                        <TableCell align="center" className={classes.cell}>
                          {rowData.applicationDate || "-"}
                        </TableCell>
                        {/* 申請種別 */}
                        <TableCell align="center" className={classes.cell}>
                          {rowData.dealType || "-"}
                        </TableCell>
                        {/* ステータス */}
                        <TableCell align="center" className={classes.cell}>
                          {rowData.workflowStatus || "-"}
                        </TableCell>
                        {/* 詳細ボタン */}
                        <TableCell
                          align="center"
                          className={clsx(classes.cell, classes.detail)}
                        >
                          <Button
                            className={clsx(
                              buttonClasses.button,
                              classes.detailButton
                            )}
                            variant="contained"
                            component={Link}
                            to={{
                              pathname: rowData.detailLink,
                              state: conditionState,
                            }}
                            onClick={() => {
                              // 主に解約用にdealTypeをセット
                              formStore.setEditDealType(
                                rowData.dealType as TDealTypeStr
                              );
                            }}
                          >
                            詳細
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* ページネーション */}
              <div className={paginationClasses.parent}>
                <Pagination
                  count={Math.ceil(
                    (resultsRows.result?.dealsCount as number) /
                      DEAL_SEARCH_PER_PAGE
                  )}
                  className={clsx(
                    paginationClasses.pagination,
                    paginationClasses.pageCurrent
                  )}
                  onChange={(e: React.ChangeEvent<unknown>, num) =>
                    getDeals(String(num))
                  }
                  page={Number(conditionState.pageNumber)}
                />
              </div>
            </FormControl>
          </>
        )}
    </>
  );
};

export default DealSearchResult;
