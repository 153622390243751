/**
 * JTB側-メールアドレス変更確認コード入力フォーム
 * メモ：入力フォームを表示する
 */
import React from "react";

import {
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";

import { formStyles } from "../../../../common/components/styles";

import { EmailEditConfirmItemObject } from "../../types";

import { ADMIN_COLOR } from "../../../../../constants/common";

import CustomTextField from "../../../../common/components/atoms/CustomTextField";

interface Props {
  emailEditConfirmState: EmailEditConfirmItemObject;
  setEmailEditConfirmState: React.Dispatch<
    React.SetStateAction<EmailEditConfirmItemObject>
  >;
}

const SettingMailEditConfirmForm: React.FC<Props> = (props: Props) => {
  const formClasses = formStyles({
    color: ADMIN_COLOR.base,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const { emailEditConfirmState, setEmailEditConfirmState } = props;

  return (
    <FormControl className={formClasses.form}>
      <TableContainer component={Paper}>
        <Table className={formClasses.table} aria-label="simple table">
          <TableBody>
            {/* 確認コード */}
            <TableRow key="確認コード">
              <TableCell align="center" className={formClasses.th}>
                確認コード
              </TableCell>
              <TableCell align="left">
                <CustomTextField
                  value={emailEditConfirmState.code}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEmailEditConfirmState({
                      ...emailEditConfirmState,
                      code: e.target.value,
                    })
                  }
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </FormControl>
  );
};

export default SettingMailEditConfirmForm;
