/**
 * JTB側-各種操作ボタン一覧
 * メモ：新規申請詳細画面の各種操作のボタンの一覧
 */
import React, { useCallback, useState, useMemo } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import clsx from "clsx";
import { Grid, Button, makeStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import MessageIcon from "@material-ui/icons/Message";
import GetApp from "@material-ui/icons/GetApp";

import { buttonStyles } from "../../../../common/components/styles";

import { TDealTypeStr } from "../../../../common/components/types";

import { ADMIN_COLOR } from "../../../../../constants/common";
import {
  EDIT_CUSTOMER_CODE_STATUSES,
  EDIT_DEAL_STATUSES,
  EDIT_DEAL_STATUSES_FOR_JTB_MANAGER,
  EDIT_DEAL_STATUSES_FOR_JTB_STAFF,
  TWorkflowStatus,
} from "../../../../../constants/workflowStatus";
import {
  DEAL_TYPE_STR_TO_NUM,
  DEAL_SHOW_PAGE_URL_FOR_DEALER,
} from "../../../../../constants/deal";

import formStore from "../../../../../store/formStore";
import {
  isJtbManagerUser,
  isJtbStaffUser,
  isJtbSysADUser,
} from "../../../../../utils/auth";

import DownLoading from "../../../../common/components/molecules/DownLoading";
import DealUserMessageModal from "./DealUserMessageModal";
import CustomerCodeModal from "./CustomerCodeModal";
import AdditionalFileUploadModal from "./AdditionalFileUploadModal";

const useStyles = makeStyles(() => ({
  commonButton: {
    paddingTop: 11,
    paddingBottom: 11,
    textAlign: "center",
  },
  Icon: {
    position: "absolute",
    top: 11,
    left: 20,
  },
  buttonGridList: {
    margin: "0 auto",
  },
  buttonGrid: {
    width: "49%",
  },
}));

interface Props extends RouteComponentProps {
  dealId: number;
  customerId: number;
  workflowStatus: TWorkflowStatus;
  dealType: TDealTypeStr;
  customerCode?: string;
  updateFileList: () => Promise<void>;
  setExecuted: React.Dispatch<React.SetStateAction<number>>;
  fileData: { fileName: string; fileId: number }[];
}

const DealShowButtonList: React.FC<Props> = (props: Props) => {
  const {
    dealId,
    customerId,
    workflowStatus,
    dealType,
    customerCode,
    updateFileList,
    setExecuted,
    history,
    fileData,
  } = props;

  const classes = useStyles();
  const buttonClasses = buttonStyles({
    width: "100%",
    marginTop: 0,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const [downloading] = useState(false);
  const [userMessageOpen, setUserMessageOpen] = useState(false);
  const [customerCodeOpen, setCustomerCodeOpen] = useState(false);
  const [fileUploadOpen, setFileUploadOpen] = useState(false);

  // 申請編集ボタン表示条件
  const showEditButton = useMemo(() => {
    if (isJtbSysADUser()) {
      // JTBシステム担当は管理者、一般どちらのボタンも表示する
      return EDIT_DEAL_STATUSES.includes(workflowStatus);
    }
    if (isJtbManagerUser()) {
      // JTB管理者
      return EDIT_DEAL_STATUSES_FOR_JTB_MANAGER.includes(workflowStatus);
    }
    if (isJtbStaffUser()) {
      // JTB一般
      return EDIT_DEAL_STATUSES_FOR_JTB_STAFF.includes(workflowStatus);
    }
    return false;
  }, [workflowStatus]);

  // 加盟店コード入力ボタン表示条件
  const showCustomerCodeButton = useMemo(() => {
    return (
      dealType === "端末新規" &&
      EDIT_CUSTOMER_CODE_STATUSES.includes(workflowStatus)
    );
  }, [dealType, workflowStatus]);

  const handleToEditPage = useCallback(() => {
    // 「現在のワークフローステータス」をセットする
    formStore.setWorkflowStatus(workflowStatus);
    // 「次のワークフローステータス」をセットする（ワークフローの進行がない編集のため、現在のワークフローステータスの数字を入れる）
    formStore.setNextWorkflowStatus(workflowStatus);

    // dealTypeにより、「申込内容を編集する」ボタンの遷移先urlを変更する
    const editDealTypeNum = DEAL_TYPE_STR_TO_NUM[dealType];
    const editDealTypeUrl = DEAL_SHOW_PAGE_URL_FOR_DEALER[editDealTypeNum];
    const editDealUrl = `${editDealTypeUrl}${dealId}/edit`;
    history.push(editDealUrl);
  }, [history, dealId, workflowStatus, dealType]);

  return (
    <>
      <DownLoading loadingOpen={downloading} />
      {userMessageOpen && (
        <DealUserMessageModal
          dealId={dealId}
          dealType={dealType}
          setOpen={setUserMessageOpen}
        />
      )}
      {customerCodeOpen && (
        <CustomerCodeModal
          dealId={dealId}
          customerCode={customerCode}
          setOpen={setCustomerCodeOpen}
          setExecuted={setExecuted}
        />
      )}
      {fileUploadOpen && (
        <AdditionalFileUploadModal
          dealId={dealId}
          customerId={customerId}
          updateFileList={updateFileList}
          setOpen={setFileUploadOpen}
          addedFileData={fileData}
        />
      )}
      <Grid container spacing={2} className={classes.buttonGridList}>
        <Grid item xs={12}>
          <Grid container justify="flex-start" spacing={2}>
            {/* 追加資料アップロードボタン */}
            <Grid item className={classes.buttonGrid}>
              <Button
                onClick={() => setFileUploadOpen(true)}
                className={clsx(buttonClasses.button, classes.commonButton)}
                variant="contained"
              >
                <EditIcon className={classes.Icon} />
                追加資料アップロード
              </Button>
            </Grid>
            {/* 申請編集ボタン */}
            {showEditButton && (
              <Grid item className={classes.buttonGrid}>
                <Button
                  onClick={handleToEditPage}
                  className={clsx(buttonClasses.button, classes.commonButton)}
                  variant="contained"
                >
                  <EditIcon className={classes.Icon} />
                  申込内容を編集する
                </Button>
              </Grid>
            )}
            {/* 加盟店コード入力ボタン */}
            {showCustomerCodeButton && (
              <Grid item className={classes.buttonGrid}>
                <Button
                  onClick={() => setCustomerCodeOpen(true)}
                  className={clsx(buttonClasses.button, classes.commonButton)}
                  variant="contained"
                >
                  <GetApp className={classes.Icon} />
                  加盟店コード入力
                </Button>
              </Grid>
            )}
            {/* メッセージ送信ボタン */}
            <Grid item className={classes.buttonGrid}>
              <Button
                onClick={() => setUserMessageOpen(true)}
                className={clsx(buttonClasses.button, classes.commonButton)}
                variant="contained"
              >
                <MessageIcon className={classes.Icon} />
                メッセージを送信する
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(DealShowButtonList);
