/**
 * 共通-チェックボックス
 */
import React from "react";

import { Checkbox } from "@material-ui/core";

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | Array<string>;
  name: string;
  checked: boolean;
  disabled?: boolean;
}

const VariableCustomCheckbox: React.FC<Props> = (props: Props) => {
  const { name, value, checked, onChange, disabled } = props;

  return (
    <Checkbox
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
      inputProps={{ "aria-label": "primary checkbox" }}
      disabled={disabled}
    />
  );
};

VariableCustomCheckbox.defaultProps = {
  disabled: false,
};

export default VariableCustomCheckbox;
