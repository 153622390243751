/**
 * 加盟店側-契約者管理
 * メモ：契約者管理系のRouter設定
 */
import React from "react";
import { Route, Switch } from "react-router-dom";

import ContractorEditCompletePage from "./pages/ContractorEditCompletePage";
import ContractorEditConfirmPage from "./pages/ContractorEditConfirmPage";
import ContractorEditPage from "./pages/ContractorEditPage";
import ContractorPage from "./pages/ContractorPage";
import ContractorShowPage from "./pages/ContractorShowPage";

const ContractorHooks: React.FC = () => {
  return (
    <Switch>
      {/* 契約者検索 */}
      <Route exact path="/guest/contractor" render={() => <ContractorPage />} />
      {/* 契約者詳細 */}
      <Route
        path="/guest/contractor/:id"
        render={() => <ContractorShowPage />}
        exact
      />
      {/* 契約者編集 */}
      <Route
        path="/guest/contractor/:id/edit"
        render={() => <ContractorEditPage />}
        exact
      />
      {/* 契約者編集確認 */}
      <Route
        path="/guest/contractor/:id/edit/confirm"
        render={() => <ContractorEditConfirmPage />}
        exact
      />
      {/* 契約者編集完了 */}
      <Route
        path="/guest/contractor/:id/edit/complete"
        render={() => <ContractorEditCompletePage />}
        exact
      />
    </Switch>
  );
};

export default ContractorHooks;
