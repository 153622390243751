/**
 * 加盟店管理関連の設定値
 */

/**
 * 加盟店分類 の検索アイテム(Jsonから切り出し,オブジェクトの文字列でやりとりする)
 */
export const CUSTOMER_TYPE_LIST = new Map<string, string>([
  ["JTB契約旅館・ホテル", '{"1":"JTB契約旅館・ホテル"}'],
  ["るるぶトラベル契約", '{"2":"るるぶトラベル契約"}'],
  ["全旅連加盟宿泊施設", '{"3":"全旅連加盟宿泊施設"}'],
  ["JTB支店", '{"4":"JTB支店"}'],
  ["提携販売店（総合提携店）", '{"5":"提携販売店（総合提携店）"}'],
  ["提携販売店（パートナー）", '{"6":"提携販売店（パートナー）"}'],
  ["ダイヤスタンプ契約店", '{"7":"ダイヤスタンプ契約店"}'],
]);

/**
 * 加盟店稼働状態
 */
export const CUSTOMER_OPERATING_STATUS = {
  /** 新規登録時 */
  DEFAULT: "0",
  /** 審査前 */
  OUTPUTFILE: "1",
  /** 審査済 */
  AUDITED: "2",
  /** 変更申請中 */
  CHANGING: "3",
  /** 解約申請中 */
  TERMINATING: "4",
  /** 加盟店解約済 */
  TERMINATED: "5",
} as const;

/**
 * 加盟店稼働状態の名称
 */
export const CUSTOMER_OPERATING_STATUS_NAME: { [key: string]: string } = {
  [CUSTOMER_OPERATING_STATUS.DEFAULT]: "新規登録時", // 0: 新規登録時
  [CUSTOMER_OPERATING_STATUS.OUTPUTFILE]: "審査前", // 1: 審査前
  [CUSTOMER_OPERATING_STATUS.AUDITED]: "審査済", // 2: 審査済
  [CUSTOMER_OPERATING_STATUS.CHANGING]: "変更申請中", // 3: 変更申請中
  [CUSTOMER_OPERATING_STATUS.TERMINATING]: "解約申請中", // 4: 解約申請中
  [CUSTOMER_OPERATING_STATUS.TERMINATED]: "加盟店解約済", // 5: 加盟店解約済
};

/**
 * 画面に表示する加盟店稼働状態のステータス
 * @member 3: 変更申請中
 * @member 4: 解約申請中
 * @member 5: 加盟店解約済
 */
export const DISPLAY_CUSTOMER_OPERATING_STATUS: string[] = [
  CUSTOMER_OPERATING_STATUS.CHANGING,
  CUSTOMER_OPERATING_STATUS.TERMINATING,
  CUSTOMER_OPERATING_STATUS.TERMINATED,
];
