/**
 * 加盟店側-ユーザー追加フォームの会社選択モーダル
 * メモ：モーダルを表示する
 */
import React, { useContext, useState } from "react";

import clsx from "clsx";
import { Modal, Button, makeStyles, Theme } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import CloseIcon from "@material-ui/icons/Close";

import { buttonStyles } from "../../../../common/components/styles";

import { GuestUserNewItemObject, ContractorSearchCondition } from "../../types";
import { IContractorSearchResponse } from "../../../../../api/types";

import { FONT, GUEST_COLOR } from "../../../../../constants/common";

import contractors from "../../../../../api/contractors";
import { AuthContext } from "../../../../../cognito/AuthContext";
import { getErrorMessage } from "../../../../../utils/error";

import Loading from "../../../../common/components/molecules/Loading";
import CustomSnackbar from "../../../../common/components/atoms/CustomSnackbar";
import ContractorSearchConditionForm from "./ContractorSearchConditionForm";
import ContractorSearchResult from "./ContractorSearchResult";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: "absolute",
    width: 850,
    height: 610,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
  },
  modalTitle: {
    fontSize: "1.1em",
    color: GUEST_COLOR.primary,
    fontFamily: FONT,
    margin: "0 0 16px",
  },
  selectButton: {
    paddingBottom: 8,
    position: "absolute",
    left: 265,
    top: 555,
  },
  cancelButton: {
    paddingBottom: 8,
    position: "absolute",
    left: 405,
    top: 555,
  },
  touchAppIcon: {
    fontSize: "1.4rem",
    position: "relative",
    marginLeft: -3,
    marginBottom: -5,
    left: -5,
    top: -1,
  },
  closeIcon: {
    fontSize: "1.1rem",
    position: "relative",
    marginLeft: -3,
    marginBottom: -5,
    left: -2,
    top: -1,
  },
  BusinessCenterIcon: {
    fontSize: "1.4rem",
    position: "relative",
    top: 4,
    marginLeft: -3,
  },
}));

// 加盟店ユーザー追加フォームのインターフェース
interface Props {
  open: boolean;
  setOpen: (arg: boolean) => void;
  userNewState: GuestUserNewItemObject;
  setUserNewState: (value: GuestUserNewItemObject) => void;
}

const ContractorSelectModal: React.FC<Props> = (props: Props) => {
  const { open, setOpen, userNewState, setUserNewState } = props;

  const classes = useStyles();
  const buttonClasses = buttonStyles({
    width: 130,
    marginTop: 0,
    backgroundColor: GUEST_COLOR.primary,
  });
  const getModalStyle = () => ({
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  });
  const [modalStyle] = useState(getModalStyle);

  const { authHeader } = useContext(AuthContext);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>("");
  const [snackOpen, setSnackOpen] = useState<boolean>(false);

  const [searchResult, setSearchResult] = useState<IContractorSearchResponse>(
    {}
  );
  const [contractorId, setContractorId] = useState("");
  const [searchCondition, setSearchCondition] =
    useState<ContractorSearchCondition>({
      companyName: "",
      customerCode: "",
      customerName: "",
      customerPhoneNum: "",
      pageNumber: "",
    });

  // モーダル閉じる処理
  const handleClose = () => {
    setOpen(false);
  };

  // 契約者検索処理
  const getContractors = async (pageNumber: string) => {
    setLoadingOpen(true);
    contractors
      .search(
        authHeader as string,
        searchCondition.companyName,
        "", // 法人番号検索はないので空文字
        searchCondition.customerCode,
        searchCondition.customerName,
        searchCondition.customerPhoneNum,
        "false", // 解約済み契約者は含まない
        pageNumber
      )
      .then((res) => {
        // 契約者の取得結果と検索条件をセット
        setSearchResult(res.data);
        setSearchCondition({ ...searchCondition, pageNumber });
        setApiErrorMsg("");
      })
      .catch((error) => {
        setApiErrorMsg(getErrorMessage(error));
        throw error;
      })
      .finally(() => {
        setLoadingOpen(false);
      });
  };

  // 選択ボタンの処理
  const handleSelect = () => {
    // 何も選択していなければアラートを出す。
    if (!contractorId) {
      setSnackOpen(true);
      return;
    }
    const contractorData = searchResult.result?.find(
      (data) => data.id === Number(contractorId)
    );
    setUserNewState({
      ...userNewState,
      contractorId,
      companyName: contractorData?.companyName || "",
      masterCustomers: contractorData?.masterCustomers || [],
    });
    setOpen(false);
  };

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 id="simple-modal-title" className={classes.modalTitle}>
            <BusinessCenterIcon className={classes.BusinessCenterIcon} />
            {" 契約者選択"}
          </h2>
          {apiErrorMsg && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {apiErrorMsg}
            </Alert>
          )}
          <>
            <CustomSnackbar
              openProps={snackOpen}
              setOpen={setSnackOpen}
              message="会社を選択してください。"
            />
            {/* 会社検索フォーム */}
            <ContractorSearchConditionForm
              searchCondition={searchCondition}
              setSearchCondition={setSearchCondition}
              getContractors={getContractors}
            />
            {/* 会社検索結果 */}
            {searchResult.result && (
              <ContractorSearchResult
                resultsRows={searchResult}
                conditionState={searchCondition}
                contractorId={contractorId}
                setContractorId={setContractorId}
                getContractors={getContractors}
              />
            )}
            {/* 選択ボタン */}
            <Button
              type="button"
              className={clsx(buttonClasses.button, classes.selectButton)}
              onClick={handleSelect}
              variant="contained"
              disabled={!searchResult || searchResult.totalCount === 0}
            >
              <TouchAppIcon className={classes.touchAppIcon} />
              選択
            </Button>
            {/* キャンセルボタン */}
            <Button
              type="button"
              className={clsx(buttonClasses.button, classes.cancelButton)}
              onClick={() => {
                setOpen(false);
              }}
              variant="contained"
            >
              <CloseIcon className={classes.closeIcon} />
              キャンセル
            </Button>
          </>
        </div>
      </Modal>
    </>
  );
};

export default ContractorSelectModal;
