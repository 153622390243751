/**
 * 権限管理
 * ユーザータイプによる各機能の権限指定
 * 必要な権限が増えたらこちらを追加する
 */
import { USER_TYPE_LIST } from "./user";

export type PolicyList = {
  dashboard: boolean; // ダッシュボード
  jtbUserManaged: boolean; // JTBユーザー管理
  guestUserManaged: boolean; // 加盟店ユーザー管理
  guestUserLock: boolean; // 加盟店ユーザーロック
  guestUserReCreate: boolean; // 加盟店ユーザー再作成
  userMailEdit: boolean; // 自分のメールアドレス変更
  userPasswordEdit: boolean; // 自分のパスワード変更
  userNameEdit: boolean; // 自分の名前変更
  userPhoneNumEdit: boolean; // 自分の電話番号変更
  newDealsCreate: boolean; // 新規申請作成
  changeDealsCreate: boolean; // 変更申請作成
  dealsOperate: boolean; // 新規申請各種処理
  dealsCancel: boolean; // 新規申請キャンセル
  contractorShow: boolean; // 契約者情報検索・参照
  contractorEdit: boolean; // 契約者情報入力、編集
  customerShow: boolean; // 加盟店情報検索・参照
  terminalShow: boolean; // 端末情報検索・参照
  terminalFileDownload: boolean; // 端末一覧ファイルダウンロード
  terminalDLL: boolean; // 端末DLL持ち込み状況一覧
  settlementNoteShow: boolean; // 精算明細書検索・参照
  documentsDownload: boolean; // 規約・マニュアルダウンロード
  cardFileDownload: boolean; // カード会社連携ファイルダウンロード
  cardFileUpload: boolean; // カード会社連携ファイルアップロード
};

export const ROLE_POLICY_LIST: { [key: string]: PolicyList } = {
  // 何にもできない謎のuserTypeが来た場合に使用
  "0": {
    dashboard: false, // ダッシュボード
    jtbUserManaged: false, // JTBユーザー管理
    guestUserManaged: false, // 加盟店ユーザー管理
    guestUserLock: false, // 加盟店ユーザーロック
    guestUserReCreate: false, // 加盟店ユーザー再作成
    userMailEdit: false, // 自分のメールアドレス変更
    userPasswordEdit: false, // 自分のパスワード変更
    userNameEdit: false, // 自分の名前変更
    userPhoneNumEdit: false, // 自分の電話番号変更
    newDealsCreate: false, // 新規申請作成
    changeDealsCreate: false, // 変更申請作成
    dealsOperate: false, // 新規申請各種処理
    dealsCancel: false, // 新規申請キャンセル
    contractorShow: false, // 契約者情報検索・参照
    contractorEdit: false, // 契約者情報入力、編集
    customerShow: false, // 加盟店情報検索・参照
    terminalShow: false, // 端末情報検索・参照
    terminalFileDownload: false, // 端末一覧ファイルダウンロード
    terminalDLL: false, // 端末DLL持ち込み状況一覧
    settlementNoteShow: false, // 精算明細書検索・参照
    documentsDownload: false, // 規約・マニュアルダウンロード
    cardFileDownload: false, // カード会社連携ファイルダウンロード
    cardFileUpload: false, // カード会社連携ファイルアップロード
  },
  // [JTB]管理者の権限
  [USER_TYPE_LIST.MANAGER_JTB]: {
    dashboard: true, // ダッシュボード
    jtbUserManaged: true, // JTBユーザー管理
    guestUserManaged: false, // 加盟店ユーザー管理
    guestUserLock: false, // 加盟店ユーザーロック
    guestUserReCreate: true, // 加盟店ユーザー再作成
    userMailEdit: true, // 自分のメールアドレス変更
    userPasswordEdit: true, // 自分のパスワード変更
    userNameEdit: true, // 自分の名前変更
    userPhoneNumEdit: true, // 自分の電話番号変更
    newDealsCreate: false, // 新規申請作成
    changeDealsCreate: false, // 変更申請作成
    dealsOperate: true, // 新規申請各種処理
    dealsCancel: true, // 新規申請キャンセル
    contractorShow: true, // 契約者情報検索・参照
    contractorEdit: false, // 契約者情報入力、編集
    customerShow: true, // 加盟店情報検索・参照
    terminalShow: true, // 端末情報検索・参照
    terminalFileDownload: true, // 端末一覧ファイルダウンロード
    terminalDLL: false, // 端末DLL持ち込み状況一覧
    settlementNoteShow: true, // 精算明細書検索・参照
    documentsDownload: true, // 規約・マニュアルダウンロード
    cardFileDownload: true, // カード会社連携ファイルダウンロード
    cardFileUpload: false, // カード会社連携ファイルアップロード
  },
  // [JTB]一般の権限
  [USER_TYPE_LIST.STAFF_JTB]: {
    dashboard: true, // ダッシュボード
    jtbUserManaged: false, // JTBユーザー管理
    guestUserManaged: true, // 加盟店ユーザー管理
    guestUserLock: true, // 加盟店ユーザーロック
    guestUserReCreate: true, // 加盟店ユーザー再作成
    userMailEdit: true, // 自分のメールアドレス変更
    userPasswordEdit: true, // 自分のパスワード変更
    userNameEdit: true, // 自分の名前変更
    userPhoneNumEdit: true, // 自分の電話番号変更
    newDealsCreate: true, // 新規申請作成
    changeDealsCreate: true, // 変更申請作成
    dealsOperate: true, // 新規申請各種処理
    dealsCancel: false, // 新規申請キャンセル
    contractorShow: true, // 契約者情報検索・参照
    contractorEdit: true, // 契約者情報入力、編集
    customerShow: true, // 加盟店情報検索・参照
    terminalShow: true, // 端末情報検索・参照
    terminalFileDownload: true, // 端末一覧ファイルダウンロード
    terminalDLL: true, // 端末DLL持ち込み状況一覧
    settlementNoteShow: true, // 精算明細書検索・参照
    documentsDownload: true, // 規約・マニュアルダウンロード
    cardFileDownload: true, // カード会社連携ファイルダウンロード
    cardFileUpload: false, // カード会社連携ファイルアップロード
  },
  // [JTB]システムの権限
  [USER_TYPE_LIST.SYSAD_JTB]: {
    dashboard: true, // ダッシュボード
    jtbUserManaged: true, // JTBユーザー管理
    guestUserManaged: true, // 加盟店ユーザー管理
    guestUserLock: true, // 加盟店ユーザーロック
    guestUserReCreate: true, // 加盟店ユーザー再作成
    userMailEdit: true, // 自分のメールアドレス変更
    userPasswordEdit: true, // 自分のパスワード変更
    userNameEdit: false, // 自分の名前変更
    userPhoneNumEdit: true, // 自分の電話番号変更
    newDealsCreate: true, // 新規申請作成
    changeDealsCreate: true, // 変更申請作成
    dealsOperate: true, // 新規申請各種処理
    dealsCancel: true, // 新規申請キャンセル
    contractorShow: true, // 契約者情報検索・参照
    contractorEdit: true, // 契約者情報入力、編集
    customerShow: true, // 加盟店情報検索・参照
    terminalShow: true, // 端末情報検索・参照
    terminalFileDownload: true, // 端末一覧ファイルダウンロード
    terminalDLL: true, // 端末DLL持ち込み状況一覧
    settlementNoteShow: true, // 精算明細書検索・参照
    documentsDownload: true, // 規約・マニュアルダウンロード
    cardFileDownload: true, // カード会社連携ファイルダウンロード
    cardFileUpload: false, // カード会社連携ファイルアップロード
  },
  // [加盟店]本社スーパーユーザー
  [USER_TYPE_LIST.SUPER_REPRESENTATIVE_MANAGER]: {
    dashboard: true, // ダッシュボード
    jtbUserManaged: false, // JTBユーザー管理
    guestUserManaged: true, // 加盟店ユーザー管理
    guestUserLock: false, // 加盟店ユーザーロック
    guestUserReCreate: false, // 加盟店ユーザー再作成
    userMailEdit: true, // 自分のメールアドレス変更
    userPasswordEdit: true, // 自分のパスワード変更
    userNameEdit: true, // 自分の名前変更
    userPhoneNumEdit: false, // 自分の電話番号変更
    newDealsCreate: true, // 新規申請作成(端末増設のみ可)
    changeDealsCreate: true, // 変更申請作成
    dealsOperate: true, // 新規申請各種処理
    dealsCancel: false, // 新規申請キャンセル
    contractorShow: true, // 契約者情報検索・参照
    contractorEdit: true, // 契約者情報入力、編集
    customerShow: true, // 加盟店情報検索・参照
    terminalShow: true, // 端末情報検索・参照
    terminalFileDownload: false, // 端末一覧ファイルダウンロード
    terminalDLL: false, // 端末DLL持ち込み状況一覧
    settlementNoteShow: true, // 精算明細書検索・参照
    documentsDownload: true, // 規約・マニュアルダウンロード
    cardFileDownload: false, // カード会社連携ファイルダウンロード
    cardFileUpload: false, // カード会社連携ファイルアップロード
  },
  // [加盟店]本社管理者
  [USER_TYPE_LIST.REPRESENTATIVE_MANAGER]: {
    dashboard: true, // ダッシュボード
    jtbUserManaged: false, // JTBユーザー管理
    guestUserManaged: true, // 加盟店ユーザー管理
    guestUserLock: false, // 加盟店ユーザーロック
    guestUserReCreate: false, // 加盟店ユーザー再作成
    userMailEdit: true, // 自分のメールアドレス変更
    userPasswordEdit: true, // 自分のパスワード変更
    userNameEdit: true, // 自分の名前変更
    userPhoneNumEdit: false, // 自分の電話番号変更
    newDealsCreate: true, // 新規申請作成(端末増設のみ可)
    changeDealsCreate: true, // 変更申請作成
    dealsOperate: true, // 新規申請各種処理
    dealsCancel: false, // 新規申請キャンセル
    contractorShow: true, // 契約者情報検索・参照
    contractorEdit: true, // 契約者情報入力、編集
    customerShow: true, // 加盟店情報検索・参照
    terminalShow: true, // 端末情報検索・参照
    terminalFileDownload: false, // 端末一覧ファイルダウンロード
    terminalDLL: false, // 端末DLL持ち込み状況一覧
    settlementNoteShow: true, // 精算明細書検索・参照
    documentsDownload: true, // 規約・マニュアルダウンロード
    cardFileDownload: false, // カード会社連携ファイルダウンロード
    cardFileUpload: false, // カード会社連携ファイルアップロード
  },
  // [加盟店]加盟店管理者
  [USER_TYPE_LIST.MANAGER]: {
    dashboard: true, // ダッシュボード
    jtbUserManaged: false, // JTBユーザー管理
    guestUserManaged: true, // 加盟店ユーザー管理
    guestUserLock: false, // 加盟店ユーザーロック
    guestUserReCreate: false, // 加盟店ユーザー再作成
    userMailEdit: true, // 自分のメールアドレス変更
    userPasswordEdit: true, // 自分のパスワード変更
    userNameEdit: true, // 自分の名前変更
    userPhoneNumEdit: false, // 自分の電話番号変更
    newDealsCreate: true, // 新規申請作成(端末増設のみ可)
    changeDealsCreate: true, // 変更申請作成
    dealsOperate: true, // 新規申請各種処理
    dealsCancel: false, // 新規申請キャンセル
    contractorShow: true, // 契約者情報検索・参照
    contractorEdit: false, // 契約者情報入力、編集
    customerShow: true, // 加盟店情報検索・参照
    terminalShow: true, // 端末情報検索・参照
    terminalFileDownload: false, // 端末一覧ファイルダウンロード
    terminalDLL: false, // 端末DLL持ち込み状況一覧
    settlementNoteShow: true, // 精算明細書検索・参照
    documentsDownload: true, // 規約・マニュアルダウンロード
    cardFileDownload: false, // カード会社連携ファイルダウンロード
    cardFileUpload: false, // カード会社連携ファイルアップロード
  },
  // [加盟店]加盟店一般(事務担当)
  [USER_TYPE_LIST.OFFICE_STAFF]: {
    dashboard: true, // ダッシュボード
    jtbUserManaged: false, // JTBユーザー管理
    guestUserManaged: false, // 加盟店ユーザー管理
    guestUserLock: false, // 加盟店ユーザーロック
    guestUserReCreate: false, // 加盟店ユーザー再作成
    userMailEdit: true, // 自分のメールアドレス変更
    userPasswordEdit: true, // 自分のパスワード変更
    userNameEdit: true, // 自分の名前変更
    userPhoneNumEdit: false, // 自分の電話番号変更
    newDealsCreate: true, // 新規申請作成(端末増設のみ可)
    changeDealsCreate: true, // 変更申請作成
    dealsOperate: true, // 新規申請各種処理
    dealsCancel: false, // 新規申請キャンセル
    contractorShow: true, // 契約者情報検索・参照
    contractorEdit: false, // 契約者情報入力、編集
    customerShow: true, // 加盟店情報検索・参照
    terminalShow: true, // 端末情報検索・参照
    terminalFileDownload: false, // 端末一覧ファイルダウンロード
    terminalDLL: false, // 端末DLL持ち込み状況一覧
    settlementNoteShow: false, // 精算明細書検索・参照
    documentsDownload: true, // 規約・マニュアルダウンロード
    cardFileDownload: false, // カード会社連携ファイルダウンロード
    cardFileUpload: false, // カード会社連携ファイルアップロード
  },
  // [加盟店]加盟店一般(経理)
  [USER_TYPE_LIST.ACCOUNTING]: {
    dashboard: false, // ダッシュボード
    jtbUserManaged: false, // JTBユーザー管理
    guestUserManaged: false, // 加盟店ユーザー管理
    guestUserLock: false, // 加盟店ユーザーロック
    guestUserReCreate: false, // 加盟店ユーザー再作成
    userMailEdit: true, // 自分のメールアドレス変更
    userPasswordEdit: true, // 自分のパスワード変更
    userNameEdit: true, // 自分の名前変更
    userPhoneNumEdit: false, // 自分の電話番号変更
    newDealsCreate: false, // 新規申請作成(端末増設のみ可)
    changeDealsCreate: false, // 変更申請作成
    dealsOperate: false, // 新規申請各種処理
    dealsCancel: false, // 新規申請キャンセル
    contractorShow: false, // 契約者情報検索・参照
    contractorEdit: false, // 契約者情報入力、編集
    customerShow: false, // 加盟店情報検索・参照
    terminalShow: false, // 端末情報検索・参照
    terminalFileDownload: false, // 端末一覧ファイルダウンロード
    terminalDLL: false, // 端末DLL持ち込み状況一覧
    settlementNoteShow: true, // 精算明細書検索・参照
    documentsDownload: false, // 規約・マニュアルダウンロード
    cardFileDownload: false, // カード会社連携ファイルダウンロード
    cardFileUpload: false, // カード会社連携ファイルアップロード
  },
  // [加盟店]加盟店システム担当者
  [USER_TYPE_LIST.SYSAD_CUSTOMER]: {
    dashboard: true, // ダッシュボード
    jtbUserManaged: false, // JTBユーザー管理
    guestUserManaged: true, // 加盟店ユーザー管理
    guestUserLock: false, // 加盟店ユーザーロック
    guestUserReCreate: false, // 加盟店ユーザー再作成
    userMailEdit: true, // 自分のメールアドレス変更
    userPasswordEdit: true, // 自分のパスワード変更
    userNameEdit: false, // 自分の名前変更
    userPhoneNumEdit: false, // 自分の電話番号変更
    newDealsCreate: true, // 新規申請作成(端末増設のみ可)
    changeDealsCreate: true, // 変更申請作成
    dealsOperate: true, // 新規申請各種処理
    dealsCancel: false, // 新規申請キャンセル
    contractorShow: true, // 契約者情報検索・参照
    contractorEdit: true, // 契約者情報入力、編集
    customerShow: true, // 加盟店情報検索・参照
    terminalShow: true, // 端末情報検索・参照
    terminalFileDownload: false, // 端末一覧ファイルダウンロード(表では○だが加盟店側に機能ないはず)
    terminalDLL: false, // 端末DLL持ち込み状況一覧
    settlementNoteShow: true, // 精算明細書検索・参照
    documentsDownload: true, // 規約・マニュアルダウンロード
    cardFileDownload: false, // カード会社連携ファイルダウンロード(表では○だが加盟店側に機能ないはず)
    cardFileUpload: false, // カード会社連携ファイルアップロード
  },
  // [カード会社]カード会社ユーザー
  [USER_TYPE_LIST.CARD_COMPANY]: {
    dashboard: false, // ダッシュボード
    jtbUserManaged: true, // JTBユーザー管理
    guestUserManaged: false, // 加盟店ユーザー管理
    guestUserLock: false, // 加盟店ユーザーロック
    guestUserReCreate: false, // 加盟店ユーザー再作成
    userMailEdit: true, // 自分のメールアドレス変更
    userPasswordEdit: true, // 自分のパスワード変更
    userNameEdit: true, // 自分の名前変更
    userPhoneNumEdit: true, // 自分の電話番号変更
    newDealsCreate: false, // 新規申請作成
    changeDealsCreate: false, // 変更申請作成
    dealsOperate: false, // 新規申請各種処理
    dealsCancel: false, // 新規申請キャンセル
    contractorShow: false, // 契約者情報検索・参照
    contractorEdit: false, // 契約者情報入力、編集
    customerShow: false, // 加盟店情報検索・参照
    terminalShow: false, // 端末情報検索・参照
    terminalFileDownload: false, // 端末一覧ファイルダウンロード
    terminalDLL: false, // 端末DLL持ち込み状況一覧
    settlementNoteShow: false, // 精算明細書検索・参照
    documentsDownload: false, // 規約・マニュアルダウンロード
    cardFileDownload: true, // カード会社連携ファイルダウンロード
    cardFileUpload: true, // カード会社連携ファイルアップロード
  },
  // [カード会社]カード会社システム担当者
  [USER_TYPE_LIST.SYSAD_CARD_COMPANY]: {
    dashboard: false, // ダッシュボード
    jtbUserManaged: true, // JTBユーザー管理
    guestUserManaged: false, // 加盟店ユーザー管理
    guestUserLock: false, // 加盟店ユーザーロック
    guestUserReCreate: false, // 加盟店ユーザー再作成
    userMailEdit: true, // 自分のメールアドレス変更
    userPasswordEdit: true, // 自分のパスワード変更
    userNameEdit: false, // 自分の名前変更
    userPhoneNumEdit: true, // 自分の電話番号変更
    newDealsCreate: false, // 新規申請作成
    changeDealsCreate: false, // 変更申請作成
    dealsOperate: false, // 新規申請各種処理
    dealsCancel: false, // 新規申請キャンセル
    contractorShow: false, // 契約者情報検索・参照
    contractorEdit: false, // 契約者情報入力、編集
    customerShow: false, // 加盟店情報検索・参照
    terminalShow: false, // 端末情報検索・参照
    terminalFileDownload: false, // 端末一覧ファイルダウンロード
    terminalDLL: false, // 端末DLL持ち込み状況一覧
    settlementNoteShow: false, // 精算明細書検索・参照
    documentsDownload: false, // 規約・マニュアルダウンロード
    cardFileDownload: true, // カード会社連携ファイルダウンロード
    cardFileUpload: true, // カード会社連携ファイルアップロード
  },
};
