// 先方要望によりこの画面に来るためのリンクはコメントアウトされており、現在は使用されていない（詳しくは GuestLoginPage を確認）
/**
 * 加盟店側-ユーザーID確認画面
 * メモ：ユーザーIDの確認メールを送信する画面
 */
import React, { useContext, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import clsx from "clsx";
import { Paper, Button, makeStyles } from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import { Alert } from "@material-ui/lab";

import {
  buttonStyles,
  loginStyles,
} from "../../../../common/components/styles";

import { SendUserItemObject } from "../../types";

import { GUEST_COLOR } from "../../../../../constants/common";

import { AuthContext } from "../../../../../cognito/AuthContext";

import AlertMessage from "../../../../common/components/atoms/AuthAlertMsg";
import Loading from "../../../../common/components/molecules/Loading";
import LogoForLogin from "../../../../common/components/atoms/LogoForLogin";
import GuestSendUserForm from "../organisms/GuestSendUserForm";

const useStyles = makeStyles(() => ({
  div: {
    backgroundColor: "#f1f1f1",
    height: "100%",
  },
  // 開発環境(develop)の場合のみ
  divImage: {
    background: `url(${window.location.origin}/bgtestForGree.png) repeat`,
    backgroundColor: "#f1f1f1",
    height: "100%",
  },
  mailButton: {
    marginTop: 40,
    paddingLeft: 52,
    paddingBottom: 10,
  },
  mailIcon: {
    position: "absolute",
    top: 7,
    left: 30,
  },
}));

const GuestSendUserPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const classes = useStyles();
  const loginClasses = loginStyles({
    backgroundColor: GUEST_COLOR.primary,
    color: GUEST_COLOR.base,
  });
  const buttonClasses = buttonStyles({
    width: 240,
    marginTop: 40,
    backgroundColor: GUEST_COLOR.primary,
  });

  const { history } = props;

  const { sendUserList } = useContext(AuthContext);
  const [error, setError] = useState(<div />);
  const [loadingOpen, setLoadingOpen] = useState(false);

  const sendUserItem: SendUserItemObject = {
    email: "",
    tid: "",
  };
  const [sendUserState, setSendUserState] = useState(sendUserItem);

  const handleToCompletePage = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    setLoadingOpen(true);

    // CognitoAuthProviderのユーザーリスト送信メソッドを実行
    // try-catchはメソッド内でハンドリングしてるので不要。
    const { result, message } = await sendUserList(sendUserState);

    setLoadingOpen(false);
    // 成功した場合、ユーザー名送信完了画面へ遷移
    if (result) {
      setError(<div />);
      history.push("/guest/login/send-user/complete");
    }
    setError(<AlertMessage errorMessage={message} />);
  };

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      {/* TODO:検証環境でJTB様がマニュアル用の画面画像取るため一時的に開発環境のTESTの背景を外す */}
      {/* <div
        className={
          process.env.REACT_APP_ENV === "development"
            ? classes.divImage
            : classes.div
        }
      > */}
      <div className={classes.div}>
        <Paper className={loginClasses.paper} elevation={0}>
          {/* ロゴ */}
          <LogoForLogin />
          {/* エラーメッセージ */}
          {error}
          <form>
            <div className={loginClasses.innerForm}>
              {/* 入力フォーム */}
              <GuestSendUserForm
                sendUserState={sendUserState}
                setSendUserState={setSendUserState}
              />
              {/* ユーザー名送信ボタン */}
              <Button
                type="submit"
                onClick={handleToCompletePage}
                className={clsx(
                  buttonClasses.button,
                  loginClasses.button,
                  classes.mailButton
                )}
                variant="contained"
                disabled={loadingOpen} // 二重送信対策
              >
                <MailIcon className={classes.mailIcon} />
                ユーザーIDを送信する
              </Button>
            </div>
          </form>
          {/* 文言 */}
          <div className={loginClasses.alert}>
            <Alert variant="outlined" severity="info">
              メールアドレス宛てにメールアドレスを登録しているユーザーの一覧をお送りします。
            </Alert>
          </div>
        </Paper>
      </div>
    </>
  );
};

export default withRouter(GuestSendUserPage);
