import { TFormObject, TRequiredKey } from "../views/common/components/types";

/**
 * 必須チェック
 * @param data: 項目データ
 * @returns true: OK, false: NG
 * */
const checkRequired = (data: TFormObject, required: boolean) => {
  const d = data;
  // 必須エラー初期化
  d.errors = data.errors.filter((e) => e.type !== "required");
  if (!required || data.content) return true;

  d.errors.push({ type: "required", message: "必須項目です。" });
  return false;
};

/**
 * 正規表現チェック
 * @param data: 項目データ
 * @returns true: OK, false: NG
 */
const checkPattern = (data: TFormObject) => {
  // 指定なしはOK
  if (!data.json.formChartype) return true;

  const d = data;
  // 正規表現エラー初期化
  d.errors = data.errors.filter((e) => e.type !== "pattern");
  const regexp = new RegExp(data.json.formChartype);
  if (data.content.length <= 0 || regexp.test(data.content)) return true;

  d.errors.push({
    type: "pattern",
    message: `正しい形式(${data.json.formChartypeFormat})で入力してください。`,
  });
  return false;
};

/**
 * 文字数チェック
 * @param data: 項目データ
 * @returns true: OK, false: NG
 */
const checkLength = (data: TFormObject) => {
  const d = data;
  // 文字数エラー初期化
  d.errors = data.errors.filter((e) => e.type !== "maxLength");
  if (
    !data.json.formLength ||
    data.content.length <= parseInt(data.json.formLength, 10)
  )
    return true;

  d.errors.push({
    type: "maxLength",
    message: `${data.json.formLength}文字以内で入力してください。`,
  });
  return false;
};

/**
 * 項目のバリデーションチェック
 * @param data: 項目データ
 * @returns true:OK, false: NG
 */
export const checkSingleContentValidation = (
  data: TFormObject,
  requiredKey: TRequiredKey
) => {
  // 非表示の項目は無条件でバリデーションOK
  if (!data.json.showEnabled[requiredKey] || data.isHide) return true;

  // コンテンツが「（削除）」の場合は無条件でバリデーションOK
  if (data.content === "（削除）") return true;

  const results: boolean[] = [
    checkRequired(data, data.json.required[requiredKey] || !!data.isRequired),
    checkLength(data),
    checkPattern(data),
  ];

  // バリデーション結果を返却
  return !results.includes(false);
};

export default { checkSingleContentValidation };
