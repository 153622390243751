// APIで返ってくる型は業務外の付与情報がついているのでフロントでは型を明確に定義しない。
import axios from "axios";
import {
  createAPIPromise,
  createLoadingPromise,
  createParamStr,
  loadingUrls,
} from "./utils/common";

// 法人検索APIのプロキシAPI
const get: (
  authHeader: string,
  version: string,
  id: string,
  number: string,
  type: string,
  history: string
  // eslint-disable-next-line
) => Promise<any> = async (
  authHeader: string,
  version: string, // 国税庁の法人番号検索APIのバージョン
  id: string, // アプリケーションID
  number: string, // 法人番号
  type: string, // 応答形式
  history: string // 変更履歴
) => {
  const path = `/proxy/houjinBango/${version}/num`;
  const paramStr = createParamStr({
    id,
    number,
    type,
    history,
  });
  const loadingKey = `${path}?${paramStr}`;
  if (loadingUrls.includes(loadingKey)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(loadingKey);
  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      params: {
        id,
        number,
        type,
        history,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    loadingKey
  );
};

export default { get };
