/**
 * 共通-ログインユーザーの権限を管理するコンポーネント
 */
import React, { useContext, useEffect } from "react";

import { UserAuthInfo } from "../../../../api/types";

import { ROLE_POLICY_LIST } from "../../../../constants/auth";

import cognitoUser from "../../../../api/cognitoUser";
import {
  clearLoginUserAuth,
  setLoaded,
  setLoginUserAuth,
} from "../../../../api/utils/authUtils";
import { reloadAfterFewSeconds } from "../../../../api/utils/common";
import { AuthContext } from "../../../../cognito/AuthContext";

import AlertMessage from "./AuthAlertMsg";

// userTypeに合わせた権限リストを返却する(謎のuserTypeの場合は無権限のリスト)
const getAuthorities = (userType: string) => {
  return ROLE_POLICY_LIST[userType]
    ? ROLE_POLICY_LIST[userType]
    : ROLE_POLICY_LIST["0"];
};

const UserAuthObserver: React.FC = () => {
  const {
    authHeader,
    isAuthenticated,
    userAttributes,
    isCustomer,
    isInitialized,
    setLoginError,
    signOut,
  } = useContext(AuthContext);

  useEffect(() => {
    // Cognitoの準備完了してない場合は何もしない
    if (!isInitialized) return;
    if (!authHeader || !isAuthenticated || !userAttributes) {
      // ログアウト状態
      // メモリ上のデータを消去
      clearLoginUserAuth();
      setLoaded(true);
    } else {
      const func = async () => {
        // 権限データロード前
        setLoaded(false);
        cognitoUser
          .getUserInfo(userAttributes["cognito:username"], authHeader as string)
          .then((res) => {
            const authInfo = res.data.result as UserAuthInfo;
            const authList = getAuthorities(authInfo.userType);
            // メモリ上のデータを更新
            setLoginUserAuth(authInfo, authList, isCustomer);
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.status === 503 &&
              error.response.data.isMaintenance
            ) {
              // メンテナンス時。
              // 5秒後に更新発火させる。awaitつけない非同期実行にすることで、先にエラーメッセージ表示処理を実行させつつ、その後に、更新を発火させる。
              // 更新を発火させることで、メンテ用のWAFがかかったフロントのファイル配信エンドポイントにアクセスしに行って、WAFがメンテ用のHTMLを返す。
              reloadAfterFewSeconds();

              setLoginError(
                <AlertMessage errorMessage={error.response.data.message} />
              );
            } else {
              // eslint-disable-next-line no-lonely-if
              if (error.response) {
                setLoginError(
                  <AlertMessage errorMessage={error.response.data.message} />
                );
              } else {
                setLoginError(
                  <AlertMessage errorMessage="ユーザー情報の取得に失敗しました。しばらく時間をおいて再度お試しください。" />
                );
              }
            }
            // メモリ上のデータを消去
            clearLoginUserAuth();

            signOut();
          })
          .finally(() => {
            // 権限データロード完了
            setLoaded(true);
          });
      };
      func();
    }
    // authHeaderを監視すると無限ループする
    // eslint-disable-next-line
  }, [isAuthenticated, userAttributes, isCustomer, isInitialized]);

  // 何も描画しない
  return null;
};

export default UserAuthObserver;
