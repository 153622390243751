/**
 * 精算明細書関連の設定値
 */

/**
 * ダウンロードするファイルの種類
 */
export const DOWNLOAD_FILE_TYPE = {
  /** 精算明細書 */
  BILL: "1",
  /** データファイル */
  DATA: "2",
} as const;

/**
 * blobのMIMEタイプ
 */
export const BLOB_TYPE = {
  /** 精算明細書（.xls） */
  BILL: "application/vnd.ms-excel",
  /** データファイル（.dat） */
  DATA: "application/octet-stream",
} as const;
