/**
 * 加盟店側-変更申請編集完了画面
 * メモ：
 */
import React, { useEffect, useMemo } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";

import { useContainerStyles } from "../../../../templates/styles";
import {
  buttonStyles,
  completeStyles,
} from "../../../../common/components/styles";

import { GUEST_COLOR } from "../../../../../constants/common";

import formStore from "../../../../../store/formStore";

import useChangeDealInfoByDealType from "../../../../../utils/changeDeal";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";

const useStyles = makeStyles(() => ({
  Icon: {
    position: "absolute",
    top: 8,
    left: 20,
  },
  buttonBlock: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  returnButton: {
    width: 250,
    marginLeft: 20,
    "& .MuiButton-label": {
      marginLeft: 34,
    },
  },
}));

const ChangeDealEditCompletePage: React.FC<
  RouteComponentProps<{ id: string }>
> = (props: RouteComponentProps<{ id: string }>) => {
  const classes = useStyles();
  const completeClasses = completeStyles();
  const containerClasses = useContainerStyles();
  const buttonClasses = buttonStyles({
    width: "100%",
    marginTop: 0,
    backgroundColor: GUEST_COLOR.primary,
  });

  const { match } = props;
  const dealId = match.params.id;

  const { dealType } = useChangeDealInfoByDealType("customer");

  const breadCrumbs = useMemo(
    () => [
      { name: "TOP", link: "/guest" },
      {
        name: "詳細",
        link: `/guest/deal/change/${match.params.id}`,
      },
      { name: "完了", link: "" },
    ],
    [match.params.id]
  );

  useEffect(() => {
    // TOOD: 暫定null。適切な申請種別をセット
    formStore.setDealType(null);
    formStore.setTermiIdentNum(null);
    formStore.resetChangeableColumnNames();
  }, []);

  return (
    <Dashboard
      title={`${dealType}申請`}
      color={GUEST_COLOR.base}
      backgroundColor={GUEST_COLOR.primary}
      iconName="deal"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {/* 文言 */}
        <div className={completeClasses.message}>
          編集が完了しました。
          <br />
          <br />
          （申請番号：{dealId}）
        </div>
        <div className={classes.buttonBlock}>
          {/* ダッシュボードに戻るボタン */}
          <Button
            type="button"
            component={Link}
            to="/guest"
            className={clsx(buttonClasses.button, classes.returnButton)}
            variant="contained"
          >
            <KeyboardReturnIcon className={classes.Icon} />
            ダッシュボードに戻る
          </Button>
        </div>
      </Container>
    </Dashboard>
  );
};

export default withRouter(ChangeDealEditCompletePage);
