import { TFormColumn, TFormData } from "../../views/common/components/types";
import displayFormByApplicantType from "./applicantType";
import {
  autoCalcByBankName,
  autoCalcByBranchName,
  setBankOptions,
} from "./bankCode";
import { autoInputByCircuitType, autoInputByDHCP } from "./circuitType";
import {
  combineContents,
  setCombineDestColumns,
} from "./combineDestFormNameIds";
import calcCompanyName from "./companyName";
import {
  autoCalcByCustomerGroup,
  displayCheckSheetByCustomerType,
  displayFormByCustomerType,
  displayFormBySettlementPattern,
} from "./customerGroup";
import { autoInputByCustomerPrefecture } from "./customerPrefecture";
import requiredByCustomerUrl from "./customerUrl";
import {
  autoInputByIndstrySmallClass,
  setIndustrySmallClassOptions,
} from "./industryClass";
import autoCalcByTerminalType from "./teminalType";
import { TFixedNumberProcessResult } from "./types";
import displayFormByOldTerminal from "./oldTerminal";
import { autoCalcByInstallationMethod } from "./installationMethod";
import formStore from "../../store/formStore";

/**
 * 契約者会社名処理に関連する項目カラム名
 */
const COMPANY_NAME = [
  "applicantType",
  "representFirstName",
  "representFirstNameK",
  "representLastName",
  "representLastNameK",
  "legalPersonality",
  "legalPersonalityPos",
  "companyNameNonLP",
  "companyNameKNonLP",
];

/**
 * 申込者区分項目のカラム名
 */
const APPLICANT_TYPE = "applicantType";

/**
 * 加盟店分類のカラム名
 */
const CUSTOMER_TYPE = "customerType";

/**
 * 加盟店小業種のカラム名
 */
const INDUSTRY_SMALL_CLASS = "industrySmallClass";

/**
 * 決済機能利用パターンのカラム名
 */
const SETTLEMENT_PATTERN = "settlementPtn";

/**
 * 加盟店都道府県項目のカラム名
 */
const CUSTOMER_PREFECTURE = "customerPrefecture";

/**
 * 加盟店URL項目のカラム名
 */
const CUSTOMER_URL = "customerUrl";

/**
 * 金融機関名項目のカラム名
 */
const BANK_NAME = "bankName";
/**
 * 支店名項目のカラム名
 */
const BRANCH_NAME = "branchName";

/**
 * 端末タイプ項目のカラム名
 */
const TERMINAL_TYPE = "terminalType";

/**
 * 設置方式のカラム名
 */
const INSTALLATION_METHOD = "installationMethod";

/**
 * 回線種別項目のカラム名
 */
const CIRCUIT_TYPE = "circuitType";

/**
 * DHCP項目のカラム名
 */
const DHCP = "DHCP";

/**
 * 旧端末識別番号項目のカラム名
 */
const OLD_TID = "oldTID";

/**
 * 固定番号処理
 * @param columnName: 更新した項目のカラム名
 * @param data: TFormData フォームデータ
 * @param terminalGridId?: 端末グリッド
 * @param isForce?: 強制実行フラグ
 * @returns TFixedNumberProcessResult
 */
export const runFixedNumberProcess = (
  columnName: string,
  data: TFormData,
  terminalGridId?: number,
  isForce?: boolean
) => {
  const contractorForm = data[1];
  const customerForm = data[2];
  const terminalForm = data[3];

  let ret: TFixedNumberProcessResult = [];

  // 結合項目情報保存
  // isForce時（フォーム初期設定時）のみ
  if (isForce) {
    setCombineDestColumns(data);
  }

  // 結合項目
  // 更新対象かはcombineContents内で判定している
  ret = ret.concat(combineContents(data, columnName as TFormColumn));

  // 契約者会社名処理
  if (
    contractorForm &&
    contractorForm.length > 0 &&
    (isForce || COMPANY_NAME.includes(columnName))
  ) {
    // 契約者情報フォームのみが対象
    ret = ret.concat(calcCompanyName(contractorForm[0].form));
  }

  // 申込者区分処理
  if (
    contractorForm &&
    contractorForm.length > 0 &&
    (isForce || columnName === APPLICANT_TYPE)
  ) {
    // 契約者情報フォームのみが対象
    ret = ret.concat(displayFormByApplicantType(contractorForm[0].form));
  }

  // 加盟店分類による重要事項の表示制御
  if (isForce || columnName === CUSTOMER_TYPE) {
    // 加盟店情報、重要事項が対象なので全フォーム渡す
    ret = ret.concat(displayCheckSheetByCustomerType(data));
  }

  // 金融機関名選択肢設定
  // isForce時（フォーム初期設定時）のみ
  if (customerForm && customerForm.length > 0 && isForce) {
    ret = ret.concat(setBankOptions(customerForm[0].form));
  }

  // 加盟店分類による制御
  if (
    customerForm &&
    customerForm.length > 0 &&
    (isForce || columnName === CUSTOMER_TYPE)
  ) {
    ret = ret.concat(autoCalcByCustomerGroup(customerForm[0].form));
  }

  // 加盟店小業種選択肢設定
  // 加盟店分類による制御
  if (
    customerForm &&
    customerForm.length > 0 &&
    (isForce || columnName === CUSTOMER_TYPE)
  ) {
    ret = ret.concat(setIndustrySmallClassOptions(customerForm[0].form));
  }

  // 加盟店小業種による自動入力
  if (
    customerForm &&
    customerForm.length > 0 &&
    (isForce || columnName === INDUSTRY_SMALL_CLASS) &&
    formStore.getDealType() !== "加盟店変更"
  ) {
    ret = ret.concat(autoInputByIndstrySmallClass(customerForm[0].form));
  }

  // 加盟店都道府県での地区データ自動入力
  if (
    customerForm &&
    customerForm.length > 0 &&
    (isForce || columnName === CUSTOMER_PREFECTURE)
  ) {
    ret = ret.concat(autoInputByCustomerPrefecture(customerForm[0].form));
  }

  // 加盟店URLの入力状況で店舗資料項目の必須制御
  if (
    customerForm &&
    customerForm.length > 0 &&
    (isForce || columnName === CUSTOMER_URL)
  ) {
    ret = ret.concat(requiredByCustomerUrl(customerForm[0].form));
  }

  // 金融機関名での自動入力 & 支店名選択肢設定
  if (
    customerForm &&
    customerForm.length > 0 &&
    (isForce || columnName === BANK_NAME)
  ) {
    ret = ret.concat(autoCalcByBankName(customerForm[0].form));
  }
  // 支店名での自動入力
  if (
    customerForm &&
    customerForm.length > 0 &&
    (isForce || columnName === BRANCH_NAME)
  ) {
    ret = ret.concat(autoCalcByBranchName(customerForm[0].form));
  }

  // 端末タイプでの回線種別などの関連処理
  if (
    terminalForm &&
    terminalForm.length > (terminalGridId || 0) &&
    (isForce || columnName === TERMINAL_TYPE)
  ) {
    ret = ret.concat(
      autoCalcByTerminalType(terminalForm[terminalGridId || 0].form)
    );
  }

  // 設置方式での自動入力
  if (
    terminalForm &&
    terminalForm.length > (terminalGridId || 0) &&
    (isForce || columnName === INSTALLATION_METHOD)
  ) {
    ret = ret.concat(
      autoCalcByInstallationMethod(terminalForm[terminalGridId || 0].form)
    );
  }

  // 回線種別での自動入力
  if (
    terminalForm &&
    terminalForm.length > (terminalGridId || 0) &&
    (isForce || columnName === CIRCUIT_TYPE)
  ) {
    ret = ret.concat(
      autoInputByCircuitType(terminalForm[terminalGridId || 0].form)
    );
  }

  // DHCPでの自動入力
  if (
    terminalForm &&
    terminalForm.length > (terminalGridId || 0) &&
    (isForce || columnName === DHCP)
  ) {
    ret = ret.concat(autoInputByDHCP(terminalForm[terminalGridId || 0].form));
  }

  // 旧端末識別番号での表示制御
  if (
    terminalForm &&
    terminalForm.length > (terminalGridId || 0) &&
    (isForce || columnName === OLD_TID)
  ) {
    ret = ret.concat(
      displayFormByOldTerminal(terminalForm[terminalGridId || 0].form)
    );
  }

  // 決済機能利用パターンに夜表示制御
  if (
    customerForm &&
    customerForm.length > 0 &&
    (isForce || columnName === SETTLEMENT_PATTERN)
  ) {
    ret = ret.concat(displayFormBySettlementPattern(data));
  }

  // 加盟店分類による通帳コピー、営業許可証の表示制御
  if (isForce || columnName === CUSTOMER_TYPE) {
    // 契約者情報、加盟店情報が対象なので全フォーム渡す
    ret = ret.concat(displayFormByCustomerType(data));
  }

  return ret;
};

export default runFixedNumberProcess;
