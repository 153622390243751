/**
 * 加盟店側-解約申請詳細画面
 */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Container, makeStyles, Tab, Tabs } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import StoreIcon from "@material-ui/icons/Store";
import TouchAppIcon from "@material-ui/icons/TouchApp";

import { useContainerStyles } from "../../../../templates/styles";

import {
  TDealTypeStr,
  TJsonRowObject,
  TFormColumn,
  TFormRowData,
} from "../../../../common/components/types";

import { GUEST_COLOR, FONT } from "../../../../../constants/common";
import DEAL_FORM_TAB_NAMES, {
  FORM_CATEGORY,
} from "../../../../../constants/form";
import { TWorkflowStatus } from "../../../../../constants/workflowStatus";

import { GlobalPopupContext } from "../../../../../App";
import formStore from "../../../../../store/formStore";
import deal from "../../../../../api/deal";
import terminateDeal from "../../../../../api/terminateDeal";
import preloadFormContent from "../../../../../api/utils/preUtils";
import { getErrorMessage } from "../../../../../utils/error";
import {
  convIndustrySmallCode,
  json2FormData,
} from "../../../../../utils/formData";
import { AuthContext } from "../../../../../cognito/AuthContext";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import DealShowWorkflow from "../../../../common/components/organisms/DealShowWorkflow";
import TabPanel from "../../../../common/components/atoms/TabPanel";
import ConfirmForm from "../../../../form/components/organisms/ConfirmForm";
import ChangeOrTerminateDealShowButtonList from "../../../../common/components/organisms/GuestChangeOrTerminateDealShowButtonList";
import ConfirmFormForTerminateDeal from "../../../../form/components/organisms/ConfirmFormForTerminateDeal";

const breadCrumbs = [
  { name: "TOP", link: "/guest" },
  { name: "申請詳細", link: "" },
];

const useStyles = makeStyles(() => ({
  settingIcon: {
    verticalAlign: "top",
    left: -2,
    top: 1,
    position: "relative",
  },
  Icon: {
    verticalAlign: "top",
    left: -2,
    top: 1,
    position: "relative",
  },
  tabs: {
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: GUEST_COLOR.primary,
      color: GUEST_COLOR.base,
    },
  },
  tab: {
    color: GUEST_COLOR.primary,
    border: `3px solid ${GUEST_COLOR.primary}`,
    marginRight: 4,
    minHeight: 35,
    borderBottom: "none",
    fontFamily: FONT,
    opacity: 1,
    borderRadius: "8px 8px 0px 0px",
    "& .MuiTab-wrapper": {
      display: "inline!important",
    },
    "& .MuiTab-labelIcon": {
      minHeight: 35,
    },
  },
  indicator: {
    display: "none",
  },
  message: {
    fontSize: "0.9em",
    fontWeight: "bold",
    fontFamily: FONT,
    color: GUEST_COLOR.primary,
    marginTop: 20,
    marginBottom: 10,
  },
}));

const GuestTerminateDealShowPage: React.FC<
  RouteComponentProps<{ id: string }>
> = (props: RouteComponentProps<{ id: string }>) => {
  const { match } = props;

  // 遷移元（申請管理画面の検索結果、ダッシュボード）でセットされたdealType
  const [dealType] = useState<TDealTypeStr | null>(formStore.getEditDealType());

  const [renderReady, setRenderReady] = useState<boolean>(false);
  const { authHeader } = useContext(AuthContext);
  const { setLoading } = useContext(GlobalPopupContext);
  const [workflowStatus, setWorkflowStatus] = useState<TWorkflowStatus>();
  const [firstWorkflowStatus, setFirstWorkflowStatus] =
    useState<TWorkflowStatus>();
  const [tabValue, setTabValue] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [columnNames, setColumnNames] = useState<TFormColumn[]>([]);
  const [terminateFormRow, setTerminateFormRow] = useState<TFormRowData>(
    {} as TFormRowData
  );

  // ID類は初回API取得以降はページ遷移なしに変化しないのでRefを使う
  const dealId = useRef(match.params.id);

  // 端末解約の場合に「端末情報」タブが表示されるので、その後のタブのindexをずらす
  const plusIndex = useMemo(() => {
    if (dealType === "端末解約") return 1;
    return 0;
  }, [dealType]);

  const classes = useStyles();
  const containerClasses = useContainerStyles();

  const getDataApi = useCallback(async () => {
    try {
      // 申請詳細情報取得API
      const res =
        dealType === "端末解約"
          ? await terminateDeal.showTerminalTerminateDeal(
              authHeader || "",
              dealId.current
            )
          : await terminateDeal.showCustomerTerminateDeal(
              authHeader || "",
              dealId.current
            );
      // formMaster取得API
      const jsonRes = await deal.getFormMaster(
        authHeader || "",
        res.data.result.formVersion
      );

      await preloadFormContent(authHeader || "", res.data.result.formVersion);

      // formMasterを整形して保存
      const formData = json2FormData(jsonRes.data.result);
      formStore.setFormData(
        formData.formData,
        formData.formGroupData,
        formData.columnToCategory
      );

      // 契約者情報を反映
      formStore.updateContents(
        res.data.result.contractorForm || {},
        "customer"
      );

      // 加盟店情報を反映
      const smallClass = convIndustrySmallCode(res.data.result.customerForm);
      formStore.updateContents(
        {
          ...res.data.result.customerForm,
          industrySmallClass: smallClass,
        } || {},
        "customer"
      );
      // 端末情報を反映 (端末解約の場合) マスタデータのため1端末1データなのでグリッドはなし
      if (dealType === "端末解約") {
        formStore.updateContents(
          res.data.result.terminalForm || {},
          "customer"
        );
      }

      // その他を反映
      setWorkflowStatus(
        Number(res.data.result.workflowStatus) as TWorkflowStatus
      );
      setFirstWorkflowStatus(
        Number(res.data.result.firstWorkflowStatus) as TWorkflowStatus
      );

      // フォームバージョンを保存
      formStore.setEditFormVersion(res.data.result.formVersion);

      /// //////////// 申請内容タブ ///////////////////// ///
      // 解約用のformMasterを取得
      const terminateFormMasterRes = await deal.getFormMaster(
        authHeader || "",
        res.data.result.formVersion,
        true
      );

      const formCategory =
        dealType === "端末解約"
          ? FORM_CATEGORY.TERMINAL
          : FORM_CATEGORY.CUSTOMER;

      const terminateJsonData = json2FormData(
        terminateFormMasterRes.data.result.filter(
          (r: TJsonRowObject) => r.formCategory === formCategory && r.columnName
        ) as TJsonRowObject[]
      );

      // 申請内容を反映
      const terminateForm = terminateJsonData.formData[formCategory][0].form;
      const terminateColumnNames = Object.keys(
        terminateForm || {}
      ) as TFormColumn[];
      terminateColumnNames.forEach((columnName) => {
        terminateForm[columnName][0].content =
          res.data.result.dealForm[columnName] || "";
      });
      // 申請内容のcolumnNamesを保存
      setColumnNames(terminateColumnNames);
      // 申請内容の行データを保存
      setTerminateFormRow(terminateForm);

      // データが揃ったのでレンダリング準備完了のフラグを立てる
      setRenderReady(true);
      setLoading(false);
    } catch (err) {
      setErrorMessage(getErrorMessage(err));
      // エラーの場合にも画面表示させるためレンダリング準備完了のフラグを立てる
      setRenderReady(true);
      setLoading(false);
    }
  }, [authHeader, setLoading, dealType]);

  // 申請を更新する処理
  const updateDataApi = useCallback(async () => {
    // 加盟店側は承認ないはずなので必要ないはず
    // 必要なら処理追加
  }, []);

  useEffect(() => {
    setLoading(true);
    getDataApi();
    // eslint-disable-next-line
  }, [setLoading]);

  const handleChange = useCallback((_, newValue: number) => {
    setTabValue(newValue);
  }, []);

  // dealTypeの型がundefinedとのユニオン型なので、ここでundefinedでない場合を保証する
  if (!dealType) return null;
  // レスポンスデータが全て揃うまでローディング
  if (!renderReady) return null;

  return (
    <Dashboard
      title="申請管理"
      color={GUEST_COLOR.base}
      backgroundColor={GUEST_COLOR.primary}
      iconName="deal"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        {/* ワークフローステータス表示欄 */}
        {workflowStatus && (
          <DealShowWorkflow
            dealId={dealId.current}
            dealType={dealType}
            workflowStatus={workflowStatus}
            colors={GUEST_COLOR}
            urlPrefix="guest"
            updateDeal={updateDataApi}
            firstWorkflowStatus={firstWorkflowStatus}
          />
        )}
        {/* 文言 */}
        <div className={classes.message}>
          {`タブをクリックすることで「${DEAL_FORM_TAB_NAMES.DEAL_CONTENT}」、「${DEAL_FORM_TAB_NAMES.CONTRACTOR_INFO}」`}
          {(dealType === "加盟店解約" || dealType === "端末解約") &&
            `、「${DEAL_FORM_TAB_NAMES.CUSTOMER_INFO}」`}
          {dealType === "端末解約" &&
            `、「${DEAL_FORM_TAB_NAMES.TERMINAL_INFO}」`}
          {`、「${DEAL_FORM_TAB_NAMES.VARIOUS_OPERATION}」が表示されます。`}
        </div>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          className={classes.tabs}
          classes={{ indicator: classes.indicator }}
        >
          {/* 申請内容タブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.DEAL_CONTENT}`}
            id="simple-tab-0"
            aria-labelledby="simple-tab-0"
            icon={<BusinessCenterIcon className={classes.Icon} />}
            className={classes.tab}
          />
          {/* 契約者情報タブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.CONTRACTOR_INFO}`}
            id="simple-tab-1"
            aria-labelledby="simple-tab-1"
            icon={<BusinessCenterIcon className={classes.Icon} />}
            className={classes.tab}
          />
          {/* 加盟店情報タブ */}
          {(dealType === "加盟店解約" || dealType === "端末解約") && (
            <Tab
              label={` ${DEAL_FORM_TAB_NAMES.CUSTOMER_INFO}`}
              id="simple-tab-2"
              aria-labelledby="simple-tab-2"
              icon={<StoreIcon className={classes.Icon} />}
              className={classes.tab}
            />
          )}
          {/* 端末情報タブ */}
          {dealType === "端末解約" && (
            <Tab
              label={` ${DEAL_FORM_TAB_NAMES.TERMINAL_INFO}`}
              id="simple-tab-3"
              aria-labelledby="simple-tab-3"
              icon={<PhonelinkSetupIcon className={classes.Icon} />}
              className={classes.tab}
            />
          )}
          {/* 各種操作タブ */}
          <Tab
            label={` ${DEAL_FORM_TAB_NAMES.VARIOUS_OPERATION}`}
            id="simple-tab-5"
            aria-labelledby="simple-tab-5"
            icon={<TouchAppIcon className={classes.settingIcon} />}
            className={classes.tab}
          />
        </Tabs>
        <form>
          {/* 申請内容 */}
          <TabPanel
            value={tabValue}
            index={0}
            borderColor={GUEST_COLOR.primary}
          >
            <ConfirmFormForTerminateDeal
              columnNames={columnNames}
              terminateFormRow={terminateFormRow}
              requiredKey="customer"
              colors={GUEST_COLOR}
            />
          </TabPanel>
          {/* 契約者情報 */}
          <TabPanel
            value={tabValue}
            index={1}
            borderColor={GUEST_COLOR.primary}
          >
            <ConfirmForm
              formCategory={FORM_CATEGORY.CONTRACTOR} // 契約者情報
              requiredKey="customer"
              colors={GUEST_COLOR}
              terminalGridId={undefined}
            />
          </TabPanel>
          {/* 加盟店情報 */}
          {(dealType === "加盟店解約" || dealType === "端末解約") && (
            <TabPanel
              value={tabValue}
              index={2}
              borderColor={GUEST_COLOR.primary}
            >
              <ConfirmForm
                formCategory={FORM_CATEGORY.CUSTOMER} // 加盟店情報
                requiredKey="customer"
                colors={GUEST_COLOR}
                terminalGridId={undefined}
              />
            </TabPanel>
          )}
          {/* 端末情報  */}
          {dealType === "端末解約" && (
            <TabPanel
              value={tabValue}
              index={3}
              borderColor={GUEST_COLOR.primary}
            >
              <ConfirmForm
                formCategory={FORM_CATEGORY.TERMINAL} // 端末情報
                requiredKey="customer"
                colors={GUEST_COLOR}
                terminalGridId={undefined}
              />
            </TabPanel>
          )}
          {/* 各種操作 */}
          <TabPanel
            value={tabValue}
            index={3 + plusIndex}
            borderColor={GUEST_COLOR.primary}
          >
            {/* 各操作ボタン一覧 */}
            {workflowStatus && (
              <ChangeOrTerminateDealShowButtonList
                dealId={parseInt(dealId.current, 10)}
              />
            )}
          </TabPanel>
        </form>
      </Container>
    </Dashboard>
  );
};

export default withRouter(GuestTerminateDealShowPage);
