/**
 * JTB側-2段階認証コード入力画面
 * メモ：ログイン成功後ダッシュボードへ遷移する。
 */
import React, { useContext, useState } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";

import clsx from "clsx";
import { Paper, Button, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import {
  buttonStyles,
  loginStyles,
  loginFormStyles,
} from "../../../../common/components/styles";

import { ADMIN_COLOR } from "../../../../../constants/common";

import { AuthContext } from "../../../../../cognito/AuthContext";

import AlertMessage from "../../../../common/components/atoms/AuthAlertMsg";
import Loading from "../../../../common/components/molecules/Loading";
import LogoForLogin from "../../../../common/components/atoms/LogoForLogin";
import DealerRequireMfaForm from "../organisms/DealerRequireMfaForm";

const useStyles = makeStyles(() => ({
  div: { height: "100%" },
  // 開発環境(develop)の場合のみ
  divImage: {
    background: `url(${window.location.origin}/bgtestForGree.png) repeat`,
    height: "100%",
  },
  touchAppButton: {
    marginTop: 40,
    paddingLeft: 45,
    paddingBottom: 10,
  },
  touchAppIcon: {
    position: "absolute",
    top: 6,
    left: 30,
  },
  linkIcon: {
    position: "relative",
    top: 8,
    left: 5,
    fontSize: "1.8em",
    color: ADMIN_COLOR.primary,
  },
}));

const DealerRequireMfaPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const classes = useStyles();
  const loginClasses = loginStyles({
    backgroundColor: ADMIN_COLOR.primary,
    color: ADMIN_COLOR.base,
  });
  const buttonClasses = buttonStyles({
    width: 240,
    marginTop: 40,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const loginFormClasses = loginFormStyles();

  const { history } = props;

  const { confirmMfaCode, challengeName } = useContext(AuthContext);
  const [error, setError] = useState(<div />);
  const [loadingOpen, setLoadingOpen] = useState(false);

  const [mfaCode, setMfaCode] = useState("");

  const handleConfirmCode = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    setLoadingOpen(true);

    // CognitoAuthProviderの2段階認証メソッドを実行
    // try-catchはメソッド内でハンドリングしてるので不要。
    const { result, message, isCustomer } = await (async () => {
      return confirmMfaCode({
        code: mfaCode,
      });
    })();

    setLoadingOpen(false);
    if (result) {
      setError(<div />);
      if (isCustomer === null) {
        // 商談担当/加盟店情報が取得できなかった場合（想定外のエラーなど）
        setError(
          <AlertMessage errorMessage="想定外のエラーが発生しました。しばらくしてから再度お試しください。" />
        );
        return;
      }
      // 成功した場合、ダッシュボードへ遷移
      const path = isCustomer ? "/guest" : "/dealer";
      history.push(path);
      return;
    }
    setError(<AlertMessage errorMessage={message} />);
  };

  // リロードなどでchallengeNameがundefinedになったらログインページへ戻す
  if (!challengeName) {
    history.push("/dealer/login");
  }

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      {/* TODO:検証環境でJTB様がマニュアル用の画面画像取るため一時的に開発環境のTESTの背景を外す */}
      {/* <div
        className={
          process.env.REACT_APP_ENV === "development"
            ? classes.divImage
            : classes.div
        }
      > */}
      <div className={classes.div}>
        <Paper className={loginClasses.paper} elevation={0}>
          {/* ロゴ */}
          <LogoForLogin />
          {/* エラーメッセージ */}
          {error}
          <form>
            <div className={loginClasses.innerForm}>
              {/* 文言 */}
              <div className={loginClasses.alert}>
                <Alert severity="info">
                  2段階認証コードを入力してください。
                  <br />
                  コードを再送信したい場合は再度ログイン画面からやり直してください。
                </Alert>
              </div>
              {/* 入力フォーム */}
              <DealerRequireMfaForm mfaCode={mfaCode} setMfaCode={setMfaCode} />
              {/* 認証コード送信ボタン */}
              <Button
                type="submit"
                onClick={handleConfirmCode}
                className={clsx(
                  buttonClasses.button,
                  loginClasses.button,
                  classes.touchAppButton
                )}
                variant="contained"
                disabled={loadingOpen} // 二重送信対策
              >
                <TouchAppIcon className={classes.touchAppIcon} />
                認証コードを送信する
              </Button>
              {/* ログイン画面に戻るリンク */}
              <Link to="/dealer/login" className={loginFormClasses.link}>
                ログイン画面に戻る
                <KeyboardArrowRightIcon className={classes.linkIcon} />
              </Link>
            </div>
          </form>
        </Paper>
      </div>
    </>
  );
};

export default withRouter(DealerRequireMfaPage);
