/**
 * JTB側-ユーザー間メッセージモーダル
 */
import React, { useContext, useMemo, useState } from "react";

import clsx from "clsx";
import {
  Button,
  makeStyles,
  Modal,
  TextareaAutosize,
  Theme,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Email";

import { buttonStyles } from "../../../../common/components/styles";

import { TDealTypeStr } from "../../../../common/components/types";

import { ADMIN_COLOR, FONT } from "../../../../../constants/common";
import { TWorkflowStatus } from "../../../../../constants/workflowStatus";

import { GlobalPopupContext } from "../../../../../App";
import deal from "../../../../../api/deal";
import { AuthContext } from "../../../../../cognito/AuthContext";
import getErrorMessage from "../../../../../utils/error";
import { compareSJISWhiteList } from "../../../../../form/validation";

import CustomSelect from "../../../../common/components/molecules/CustomSelect";

const useStyle = makeStyles((theme: Theme) => ({
  popup: {
    position: "absolute",
    minWidth: 700,
    maxWidth: 950,
    width: "60%",
    minHeight: 150,
    maxHeight: 750,
    height: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
  },
  modalTitle: {
    fontSize: "1.1em",
    color: ADMIN_COLOR.primary,
    fontFamily: FONT,
    margin: 0,
  },
  systemMessage: {
    margin: "10px 0px",
  },
  selectBlock: {
    margin: "10px 0px",
  },
  textAreaBlock: {
    margin: "10px 0px",
    marginTop: 20,
  },
  textArea: {
    width: "100%",
    backgroundColor: "#f2f7fb",
    border: 0,
    fontSize: "1.0rem",
    fontFamily: FONT,
    minHeight: 100,
    maxHeight: 300,
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderWidth: 2,
    },
    resize: "vertical",
  },
  buttonBlock: {
    display: "flex",
    justifyContent: "end",
    marginTop: 20,
  },
  selectButton: {
    paddingBottom: 8,
    marginRight: 20,
  },
  cancelButton: {
    paddingBottom: 8,
  },
  closeIcon: {
    fontSize: "1.1rem",
    position: "relative",
    marginLeft: -3,
    marginBottom: -5,
    left: -2,
    top: -1,
  },
  sendIcon: {
    position: "relative",
    marginLeft: -3,
    marginBottom: -5,
    left: -6,
    top: 2,
  },
  remark: {
    color: "#777",
    fontFamily: FONT,
  },
}));

const MESSAGE_TARGET = ["社内", "契約者", "加盟店"] as const;
const USER_TARGET = ["一般", "管理者"] as const;

type TMessageTarget = (typeof MESSAGE_TARGET)[number];
type TUserTarget = (typeof USER_TARGET)[number];
type TTarget = Exclude<TMessageTarget, "社内"> | TUserTarget;

const TARGET: { [key in TTarget]: string } = {
  管理者: "10",
  一般: "20",
  契約者: "30",
  加盟店: "40",
};

type Props = {
  dealId: number;
  dealType: TDealTypeStr;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  systemMessage?: string;
  optionalApi?: () => Promise<void>;
  updateDeal?: (next: TWorkflowStatus) => Promise<void>;
  nextStatus?: TWorkflowStatus;
};

/**
 * ユーザー間メッセージモーダル（JTB側）
 * @param dealId: 申請ID
 * @param dealType: 申請種別
 * @param setOpen: 表示/非表示制御
 * @param systemMessage?: ポップアップ上部に表示する注意文言
 * @param optionalApi?: メッセージ送信API前に叩くAPI
 * @param updateDeal?: 「差し戻し」用：メッセージ送信API前に叩くAPIの処理
 * @param nextStatus?: 「差し戻し」用：updateDealに使用する次のワークフローステータス
 */
const DealUserMessageModal: React.FC<Props> = (props: Props) => {
  const {
    dealId,
    dealType,
    setOpen,
    systemMessage,
    optionalApi,
    updateDeal,
    nextStatus,
  } = props;

  const { authHeader } = useContext(AuthContext);
  const { setSnackbar, setLoading } = useContext(GlobalPopupContext);

  const modalStyle = useMemo(
    () => ({
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }),
    []
  );
  // 宛先に加盟店が選択不可の場合は選択肢から除外
  const messageTargetPullArray = useMemo(
    () =>
      dealType !== "契約者変更"
        ? MESSAGE_TARGET
        : MESSAGE_TARGET.filter((v) => v !== "加盟店"),
    [dealType]
  );
  const [target, setTarget] = useState<TMessageTarget | "">(""); // 選択した宛先
  const [showUserSelect, setShowUserSelect] = useState(false); // 社内の宛先ユーザーのセレクトボックスを表示するかどうか
  const [selectedUser, setSelectedUser] = useState<TUserTarget | "">(""); // 選択した社内の宛先ユーザー
  const [targetUser, setTargetUser] = useState<TTarget | "">(""); // APIに渡す値
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<string>("");

  const classes = useStyle();
  const buttonClasses = buttonStyles({
    width: 130,
    marginTop: 0,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const selectTarget = async (selectedTarget: TMessageTarget) => {
    // 宛先に変更がなければ何もしない
    if (target === selectedTarget) return;

    const toDealer = selectedTarget === "社内";
    setShowUserSelect(toDealer); // 宛先が「社内」の場合は社内の宛先ユーザーのセレクトボックスを表示
    setTarget(selectedTarget);

    if (!toDealer) {
      // 宛先が「社内」でない場合はAPIに送る値をセット
      setTargetUser(selectedTarget);
    }

    setError("");
  };

  const selectTargetUser = (user: TUserTarget) => {
    setTargetUser(user); // APIに送る値をセット
    setSelectedUser(user);
    setError("");
  };

  const handleChangeMessage = (text: string) => {
    setMessage(text);
    setError("");
  };

  const handleSendMessage = async () => {
    if (!message) {
      setError("メッセージを入力してください");
      return;
    }
    if (message.length > 500) {
      setError("メッセージは500文字以内で入力してください");
      return;
    }
    const errChar = compareSJISWhiteList([message]);
    if (errChar) {
      setError(`当システムで使用できない文字が含まれています。（${errChar}）`);
      return;
    }
    if (!targetUser) {
      setError("宛先を選択してください");
      return;
    }

    setLoading(true);
    if (optionalApi) {
      let isError = false;
      await optionalApi().catch((err) => {
        setError(getErrorMessage(err));
        isError = true;
        setLoading(false);
      });
      // エラーじゃない場合は続けてメッセージAPIを叩くのでLoadingのまま
      if (isError) return;
    }

    // 以下は「差し戻し」用の処理
    if (updateDeal && nextStatus) {
      let isError = false;
      await updateDeal(nextStatus).catch(() => {
        isError = true;
        setLoading(false);
        setOpen(false);
      });
      // エラーじゃない場合は続けてメッセージAPIを叩くのでLoadingのまま
      if (isError) return;
    }

    await deal
      .sendUserMessage(
        authHeader as string,
        dealId,
        TARGET[targetUser],
        message
      )
      .then(() => {
        setSnackbar({
          openProps: true,
          message: "メッセージを送信しました。",
          severity: "info",
        });
        setLoading(false);
        setOpen(false);
      })
      .catch((err) => {
        setSnackbar({
          openProps: true,
          message: `メッセージが送信できませんでした。${getErrorMessage(err)}`,
          severity: "error",
        });
        setError(getErrorMessage(err));
        setLoading(false);
        setOpen(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.popup}>
        {/* タイトル */}
        <h2 id="simple-modal-title" className={classes.modalTitle}>
          {" ユーザーメッセージ送信"}
        </h2>
        {/* 注意文言 */}
        {systemMessage && (
          <div className={classes.systemMessage}>
            <Alert severity="warning">{systemMessage}</Alert>
          </div>
        )}
        <div>
          {/* 宛先のセレクトボックス */}
          <div className={classes.selectBlock}>
            <div className={classes.remark}>宛先を選択してください</div>
            <CustomSelect
              pulldownArray={messageTargetPullArray as string[]}
              onChange={(e) => selectTarget(e.target.value as TMessageTarget)}
              value={target}
            />
          </div>
          {/* 社内の宛先ユーザーのセレクトボックス（宛先で社内を選んだ際のみ表示） */}
          {showUserSelect && (
            <div className={classes.selectBlock}>
              <div className={classes.remark}>
                社内の宛先ユーザーを選択してください。
              </div>
              <CustomSelect
                pulldownArray={[...USER_TARGET]}
                onChange={(e) =>
                  selectTargetUser(e.target.value as TUserTarget)
                }
                value={selectedUser}
              />
            </div>
          )}
          {/* メッセージ入力欄 */}
          <div className={classes.textAreaBlock}>
            <TextareaAutosize
              onChange={(e) => handleChangeMessage(e.target.value)}
              className={classes.textArea}
              value={message}
              placeholder="メッセージを入力してください（最大500文字）"
            />
          </div>
        </div>
        {/* エラー文言 */}
        {error && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        )}

        <div className={classes.buttonBlock}>
          {/* 送信ボタン */}
          <Button
            type="button"
            className={clsx(buttonClasses.button, classes.selectButton)}
            onClick={handleSendMessage}
            variant="contained"
            disabled={!!error || !targetUser || !message}
          >
            <SendIcon className={classes.sendIcon} />
            送信
          </Button>
          {/* キャンセルボタン */}
          <Button
            type="button"
            className={clsx(buttonClasses.button, classes.cancelButton)}
            onClick={handleClose}
            variant="contained"
          >
            <CloseIcon className={classes.closeIcon} />
            キャンセル
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DealUserMessageModal;
