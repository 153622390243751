/**
 * 加盟店側-ユーザー管理・検索画面
 * メモ：ユーザー管理の検索画面を表示する
 */
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import clsx from "clsx";
import { Container, makeStyles, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SearchIcon from "@material-ui/icons/Search";

import useContainerStyles from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { IGuestUserSearchResponse } from "../../../../../api/types";
import { GuestSearchCondition } from "../../types";

import { GUEST_COLOR } from "../../../../../constants/common";
import { USER_TYPE_SEARCH_MAP_FOR_CUSTOMER } from "../../../../../constants/user";

import { AuthContext } from "../../../../../cognito/AuthContext";
import cognitoUser from "../../../../../api/cognitoUser";
import { getErrorMessage } from "../../../../../utils/error";

import Loading from "../../../../common/components/molecules/Loading";
import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import GuestUserSearchConditionForm from "../organisms/GuestUserSearchConditionForm";
import GuestUserSearchResult from "../organisms/GuestUserSearchResult";

// パンくずリストの設定
const breadCrumbs = [
  { name: "設定", link: "/guest/setting" },
  { name: "ユーザー管理・検索", link: "" },
];

// レイアウト設定
const useStyles = makeStyles(() => ({
  alert: {
    marginTop: "30px",
  },
  button: {
    marginLeft: 0,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 34,
    backgroundColor: `${GUEST_COLOR.base}!important`,
    color: `${GUEST_COLOR.primary}!important`,
    border: `1px solid ${GUEST_COLOR.primary}`,
    borderRadius: 0,
    textAlign: "center",
    "&:hover": {
      backgroundColor: `${GUEST_COLOR.btnHover}!important`,
    },
  },
  addButton: {
    marginBottom: 20,
  },
  icon: {
    position: "absolute",
    top: 7,
    left: 15,
    fontSize: "1.3rem",
  },
  form: {
    width: 800,
  },
  searchButton: {
    paddingBottom: 8,
    margin: "0 auto",
    marginTop: 20,
  },
  searchIcon: {
    position: "absolute",
    top: 8,
    left: 50,
  },
}));

// 画面表示処理
const GuestSettingUserManagementPage: React.FC = () => {
  // レイアウト調整
  const containerClasses = useContainerStyles();
  const classes = useStyles();
  const buttonClasses = buttonStyles({
    width: 213,
    marginTop: 15,
    backgroundColor: GUEST_COLOR.primary,
  });
  const searchButtonClasses = buttonStyles({
    width: 180,
    marginTop: 15,
    textLeft: 10,
    backgroundColor: GUEST_COLOR.primary,
  });

  // 変数宣言系
  const { authHeader } = useContext(AuthContext);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [searchApiErrorMsg, setSearchApiErrorMsg] = useState<string>("");
  const [searchResult, setSearchResult] = useState<IGuestUserSearchResponse>(
    {}
  );
  const [searchCondition, setSearchCondition] = useState<GuestSearchCondition>({
    contractorName: "",
    customerName: "",
    name: "",
    userName: "",
    email: "",
    userType: "",
    pageNumber: "1",
  });

  // ユーザー検索&取得用関数
  const getUsers = (pageNumber: string) => {
    setLoadingOpen(true);
    const userType = USER_TYPE_SEARCH_MAP_FOR_CUSTOMER[searchCondition.userType]
      ? USER_TYPE_SEARCH_MAP_FOR_CUSTOMER[searchCondition.userType]
      : "";
    cognitoUser
      .searchCustomerUsers(
        pageNumber, // ページ番号、検索時は1固定
        authHeader as string,
        searchCondition.contractorName,
        searchCondition.customerName,
        searchCondition.name,
        searchCondition.userName,
        searchCondition.email,
        String(userType)
      )
      .then((res) => {
        // ユーザーリストの取得結果と検索条件をセット
        setSearchResult(res.data);
        setSearchCondition({ ...searchCondition, pageNumber });
        setSearchApiErrorMsg("");
      })
      .catch((error) => {
        setSearchApiErrorMsg(getErrorMessage(error));
        throw error;
      })
      .finally(() => {
        setLoadingOpen(false);
      });
  };

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Dashboard
        title="設定"
        color={GUEST_COLOR.base}
        backgroundColor={GUEST_COLOR.primary}
        iconName="setting"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {searchApiErrorMsg !== "" && (
            <Alert className={classes.alert} severity="error">
              {searchApiErrorMsg}
            </Alert>
          )}
          {/* ユーザー追加ボタン */}
          <Button
            className={clsx(
              buttonClasses.button,
              classes.button,
              classes.addButton
            )}
            variant="contained"
            component={Link}
            to="/guest/setting/user/new"
          >
            <PersonAddIcon className={classes.icon} />
            ユーザー追加
          </Button>
          {/* ユーザー検索フォーム */}
          <form className={classes.form}>
            <GuestUserSearchConditionForm
              searchCondition={searchCondition}
              setSearchCondition={setSearchCondition}
            />
            <Button
              className={clsx(searchButtonClasses.button, classes.searchButton)}
              variant="contained"
              type="submit"
              onClick={() => getUsers("1")}
              disabled={loadingOpen} // 二重送信対策
            >
              <SearchIcon className={classes.searchIcon} />
              検索
            </Button>
          </form>
          {searchApiErrorMsg === "" && (
            <>
              {/* ユーザー検索結果 */}
              {searchResult && (
                <GuestUserSearchResult
                  resultsRows={searchResult}
                  conditionState={searchCondition}
                  setApiErrorMsg={setSearchApiErrorMsg}
                  setLoadingOpen={setLoadingOpen}
                  loadingOpen={loadingOpen}
                  getUsers={getUsers}
                />
              )}
            </>
          )}
        </Container>
      </Dashboard>
    </>
  );
};

export default GuestSettingUserManagementPage;
