/**
 * 加盟店側-申請編集確認画面
 * メモ：加盟店側は規約の確認がある
 */
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useEffect,
  useState,
} from "react";
import {
  RouteComponentProps,
  withRouter,
  Link,
  useLocation,
} from "react-router-dom";

import clsx from "clsx";
import { makeStyles, Container, Button, Tabs, Tab } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import StoreIcon from "@material-ui/icons/Store";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import { buttonStyles } from "../../../../common/components/styles";
import { useContainerStyles } from "../../../../templates/styles";

import { GUEST_COLOR, FONT } from "../../../../../constants/common";
import {
  DEAL_FORM_TAB_NAMES,
  FORM_CATEGORY,
} from "../../../../../constants/form";
import { WORKFLOW_STATUS_MESSAGES_FOR_CUSTOMER } from "../../../../../constants/workflowStatus";
import { TERM_TYPE } from "../../../../../constants/terms";
import { UPLOAD_FILE_TYPE } from "../../../../../constants/deal";

import { GlobalPopupContext } from "../../../../../App";
import { getErrorMessage } from "../../../../../utils/error";
import formStore from "../../../../../store/formStore";
import deal from "../../../../../api/deal";
import preset from "../../../../../api/preset";
import { AuthContext } from "../../../../../cognito/AuthContext";
import { TSettlementPatternMasterJson } from "../../../../../form/fixedNumberProcess/customerGroup";

import StatusUpdateModal from "../../../../common/components/molecules/StatusUpdateModal";
import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import TabPanel from "../../../../common/components/atoms/TabPanel";
import ConfirmForm from "../../../../form/components/organisms/ConfirmForm";
import TerminalForm from "../../../../form/components/organisms/TerminalForm";
import GuestDealEditTermList from "../organisms/GuestDealEditTermList";

const useStyles = makeStyles(() => ({
  returnButton: {
    margin: "0 auto",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 75,
    borderRadius: 0,
    width: 300,
    "& .MuiButton-label": {
      marginLeft: 24,
    },
  },
  addButton: {
    color: `${GUEST_COLOR.primary}!important`,
    border: `1px solid ${GUEST_COLOR.primary}`,
    backgroundColor: `${GUEST_COLOR.base}!important`,
    borderRadius: 0,
    width: 300,
    margin: "0 auto",
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 29,
    "&:hover": {
      backgroundColor: `${GUEST_COLOR.btnHover}!important`,
    },
  },
  addIcon: {
    position: "absolute",
    top: 18,
    left: 21,
  },
  buttonContainer: {
    margin: "0 auto",
    clear: "both",
    height: 100,
    width: 653,
    marginTop: 20,
  },
  returnIcon: {
    position: "absolute",
    top: 19,
    left: 63,
  },
  gridButtons: {
    float: "left",
  },
  Icon: {
    verticalAlign: "top",
    left: -2,
    top: 1,
    position: "relative",
  },
  tabs: {
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: GUEST_COLOR.primary,
      color: GUEST_COLOR.base,
    },
  },
  tab: {
    color: GUEST_COLOR.primary,
    border: `3px solid ${GUEST_COLOR.primary}`,
    marginRight: 4,
    minHeight: 35,
    borderBottom: "none",
    fontFamily: FONT,
    opacity: 1,
    borderRadius: "8px 8px 0px 0px",
    "& .MuiTab-wrapper": {
      display: "inline!important",
    },
    "& .MuiTab-labelIcon": {
      minHeight: 35,
    },
  },
  indicator: {
    display: "none",
  },
  message: {
    fontSize: "0.9em",
    fontWeight: "bold",
    fontFamily: FONT,
    color: GUEST_COLOR.primary,
    marginBottom: 15,
  },
}));

// TODO type用のファイルに移動
type TermsObject = {
  need: boolean;
  open: boolean;
  termsType: string;
  termsVersion: string;
};
// TODO key名を適切なものに変更。type用のファイルに移動
export type TermsList = {
  term1: TermsObject;
  term2: TermsObject;
  term3: TermsObject;
  term4: TermsObject;
  term5: TermsObject;
  term6: TermsObject;
  term7: TermsObject;
};
// 規約のPostデータ用
type AgreedTermsType = {
  termsType: string;
  termsVersion: string;
};

const GuestDealEditConfirmPage: React.FC<
  RouteComponentProps<{ id: string }>
> = (props: RouteComponentProps<{ id: string }>) => {
  const { match, history } = props;
  const { state } = useLocation(); // termsStateをstateとして受け取る（現状は規約のPDF画面から戻ってきた際）

  const { setLoading } = useContext(GlobalPopupContext);
  const { authHeader } = useContext(AuthContext);
  const loading = useRef(false);
  const [tabValue, setTabValue] = useState(0);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>("");
  const [open, setOpen] = useState(false);

  // 規約表示条件
  const [termsCondition, setTermsCondition] = useState({
    term1: [] as number[], // 無条件
    term2: [1, 2, 3],
    term3: [7],
    term4: [5, 6],
    term5: [] as number[], // 条件生成する
    term6: [] as number[], // 無条件
    term7: [] as number[], // 条件生成する
  });

  // ↓規約関係
  const [termsState, setTermsState] = useState<TermsList>({
    term1:
      state && state.term1
        ? state.term1
        : {
            need: false,
            open: false,
            termsType: TERM_TYPE.STERA,
            termsVersion: "",
          },
    term2:
      state && state.term2
        ? state.term2
        : {
            need: false,
            open: false,
            termsType: TERM_TYPE.JTB_HOTEL,
            termsVersion: "",
          },
    term3:
      state && state.term3
        ? state.term3
        : {
            need: false,
            open: false,
            termsType: TERM_TYPE.JTB_STAMP,
            termsVersion: "",
          },
    term4:
      state && state.term4
        ? state.term4
        : {
            need: false,
            open: false,
            termsType: TERM_TYPE.JTB_PARTNER,
            termsVersion: "",
          },
    term5:
      state && state.term5
        ? state.term5
        : {
            need: false,
            open: false,
            termsType: TERM_TYPE.E_MONEY,
            termsVersion: "",
          },
    term6:
      state && state.term6
        ? state.term6
        : {
            need: false,
            open: false,
            termsType: TERM_TYPE.WEB_21,
            termsVersion: "",
          },
    term7:
      state && state.term7
        ? state.term7
        : {
            need: false,
            open: false,
            termsType: TERM_TYPE.CODE_PAYMENT,
            termsVersion: "",
          },
  });

  // 規約判定に使う決裁利用パターンの取得
  const createTargetList = () => {
    preset
      .getPresetFormJson(authHeader as string, "settlementPtnMst", "latest")
      .then((res) => {
        const targetEmoney: number[] = [];
        const targetPaypay: number[] = [];
        // 規約用の決裁パターン条件を準備
        const jsonData = res.data.result as TSettlementPatternMasterJson;
        jsonData.forEach((data) => {
          // 電子マネーが有効のもの
          if (Number(data.brand_transportation_emoney) === 1) {
            targetEmoney.push(Number(data.settlement_ptn_cd));
          }
          // paypayが有効のもの
          if (Number(data.brand_paypay) === 1) {
            targetPaypay.push(Number(data.settlement_ptn_cd));
          }
        });
        setTermsCondition({
          ...termsCondition,
          term5: targetEmoney,
          term7: targetPaypay,
        });
      })
      .catch((error) => {
        throw error;
      });
  };

  // 遷移時の処理
  useEffect(() => {
    const getList = async () => {
      await createTargetList();
    };
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 必要規約を確定するため加盟店分類と決裁機能パターンを取得する
  const customerTypeData = JSON.parse(
    formStore.getFormRow("customerType")?.content as string
  );
  const customerType = Number(Object.keys(customerTypeData)[0]);
  const settlementPtnData = JSON.parse(
    formStore.getFormRow("settlementPtn")?.content as string
  );
  const settlementPtn = Number(Object.keys(settlementPtnData)[0]);

  // 規約表示の条件をチェック TODO かなり突貫工事なので落ち着いたらリファクタしたいところ
  useEffect(() => {
    const newTermsState = { ...termsState };

    // 端末機使用特約 無条件
    termsState.term1.need = true;
    // JTBクレジットカード一括加盟店 決済サービス 加盟店規約（宿泊施設） 加盟店分類 1,2,3
    if (termsCondition.term2.includes(customerType)) {
      termsState.term2.need = true;
    }
    // JTBクレジットカード一括加盟店 決済サービス 加盟店規約（JTBたびなかネット スタンプ） 加盟店分類 7
    if (termsCondition.term3.includes(customerType)) {
      termsState.term3.need = true;
    }
    // JTBクレジットカード一括加盟店 決済サービス 加盟店規約（JTB提携販売店） 加盟店分類 5,6
    if (termsCondition.term4.includes(customerType)) {
      termsState.term4.need = true;
    }
    // 電子マネー 決済サービスの利用に関する 特約 決済機能パターン:交通系指定
    if (termsCondition.term5.includes(settlementPtn)) {
      termsState.term5.need = true;
    }
    // 取引照会WEB21利用に関するご案内 無条件
    termsState.term6.need = true;
    // コード決済 サービスの利用に関する 特約 決済機能パターン:PayPay指定
    if (termsCondition.term7.includes(settlementPtn)) {
      termsState.term7.need = true;
    }
    setTermsState(newTermsState);
    // eslint-disable-next-line
  }, [customerType, settlementPtn, termsCondition]);
  // ↑規約関係

  // 「現在のワークフローステータス」を取得
  const workflowStatus = useMemo(() => formStore.getWorkflowStatus(), []);
  // 「次のワークフローステータス」を取得
  const nextWorkflowStatus = useMemo(
    () => formStore.getNextWorkflowStatus(),
    []
  );

  const breadCrumbs = [
    { name: "TOP", link: "/guest" },
    { name: "申請詳細", link: `/guest/deal/${match.params.id}` },
    { name: "編集", link: `/guest/deal/${match.params.id}/edit` },
    { name: "確認", link: "" },
  ];

  const isShowCheckSheet = useMemo(() => formStore.isShowChackSheet(), []);

  const classes = useStyles();
  const moveButtonClasses = buttonStyles({
    width: 180,
    marginTop: 20,
    backgroundColor: GUEST_COLOR.primary,
  });
  const containerClasses = useContainerStyles();

  const createAgreedData = useCallback(() => {
    const agreedTerms = [] as AgreedTermsType[];
    Object.keys(termsState).forEach((termKey) => {
      if (termsState[termKey as keyof TermsList].need) {
        agreedTerms.push({
          termsType: termsState[termKey as keyof TermsList].termsType,
          termsVersion: termsState[termKey as keyof TermsList].termsVersion,
        });
      }
    });
    return agreedTerms;
  }, [termsState]);

  const handleUpdate = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      setLoading(true);
      loading.current = true;

      // 規約同意データを作成する
      const agreedData = createAgreedData();

      try {
        // ファイルアップロードAPI
        const fileIds = await formStore.uploadFormFiles(
          authHeader || "",
          "customer",
          UPLOAD_FILE_TYPE.CUSTOMER
        );
        // POST用データを用意
        const request = formStore.getApiRequestDataForEditDeal(
          Number(match.params.id),
          "customer"
        );
        if (!request) {
          setApiErrorMsg(
            "入力内容に問題が発生しました。入力画面に戻り確認してください。"
          );
          return;
        }
        // 規約の同意情報を入れて編集APIを実行する
        request.agreedTerms = agreedData;
        // 新規申請編集API
        await deal.update(authHeader as string, match.params.id, {
          ...request,
          fileIds,
        });

        setLoading(false);
        loading.current = false;
        history.push(`/guest/deal/${match.params.id}/edit/complete`);
      } catch (error) {
        setApiErrorMsg(getErrorMessage(error));
        setOpen(false);
        setLoading(false);
        loading.current = false;
      }
    },
    [history, setLoading, match.params.id, authHeader, createAgreedData]
  );

  const handleChange = useCallback((_, newValue: number) => {
    setTabValue(newValue);
  }, []);

  // 必要な規約がすべて同意済みかどうか
  const isAllNeedTermsOpen = () => {
    const needTerms = Object.keys(termsState).filter(
      (key) => termsState[key as keyof TermsList].need === true
    );
    return needTerms.every(
      (key) => termsState[key as keyof TermsList].open === true
    );
  };

  return (
    <>
      <StatusUpdateModal
        modalTitle={`ステータスが「${WORKFLOW_STATUS_MESSAGES_FOR_CUSTOMER[workflowStatus]}」から「${WORKFLOW_STATUS_MESSAGES_FOR_CUSTOMER[nextWorkflowStatus]}」に更新されますがよろしいでしょうか？`}
        handleUpdate={handleUpdate}
        open={open}
        setOpen={setOpen}
        loadingOpen={loading.current}
        colors={GUEST_COLOR}
      />
      <Dashboard
        title="申請管理"
        color={GUEST_COLOR.base}
        backgroundColor={GUEST_COLOR.primary}
        iconName="deal"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {apiErrorMsg && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {apiErrorMsg}
            </Alert>
          )}
          {/* 文言 */}
          <div className={classes.message}>
            {`タブをクリックすることで「${DEAL_FORM_TAB_NAMES.CONTRACTOR_INFO}」、「${DEAL_FORM_TAB_NAMES.CUSTOMER_INFO}」、「${DEAL_FORM_TAB_NAMES.TERMINAL_INFO}」`}
            {isShowCheckSheet && `、「${DEAL_FORM_TAB_NAMES.IMPORTANT_INFO}」`}
            の各入力内容が表示されます。
          </div>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            indicatorColor="primary"
            className={classes.tabs}
            classes={{ indicator: classes.indicator }}
          >
            {/* 契約者情報タブ */}
            <Tab
              label={` ${DEAL_FORM_TAB_NAMES.CONTRACTOR_INFO}`}
              id="simple-tab-0"
              aria-labelledby="simple-tab-0"
              icon={<BusinessCenterIcon className={classes.Icon} />}
              className={classes.tab}
            />
            {/* 加盟店情報タブ */}
            <Tab
              label={` ${DEAL_FORM_TAB_NAMES.CUSTOMER_INFO}`}
              id="simple-tab-1"
              aria-labelledby="simple-tab-1"
              icon={<StoreIcon className={classes.Icon} />}
              className={classes.tab}
            />
            {/* 端末情報タブ */}
            <Tab
              label={` ${DEAL_FORM_TAB_NAMES.TERMINAL_INFO}`}
              id="simple-tab-2"
              aria-labelledby="simple-tab-2"
              icon={<PhonelinkSetupIcon className={classes.Icon} />}
              className={classes.tab}
            />
            {/* 重要事項タブ */}
            {isShowCheckSheet && (
              <Tab
                label={` ${DEAL_FORM_TAB_NAMES.IMPORTANT_INFO}`}
                id="simple-tab-3"
                aria-labelledby="simple-tab-3"
                icon={<CheckBoxIcon className={classes.Icon} />}
                className={classes.tab}
              />
            )}
          </Tabs>
          <form>
            {/* 契約者情報 */}
            <TabPanel
              value={tabValue}
              index={0}
              borderColor={GUEST_COLOR.primary}
            >
              <ConfirmForm
                formCategory={FORM_CATEGORY.CONTRACTOR} // 契約者情報
                requiredKey="customer"
                colors={GUEST_COLOR}
                terminalGridId={undefined}
              />
            </TabPanel>
            {/* 加盟店情報 */}
            <TabPanel
              value={tabValue}
              index={1}
              borderColor={GUEST_COLOR.primary}
            >
              <ConfirmForm
                formCategory={FORM_CATEGORY.CUSTOMER} // 加盟店情報
                requiredKey="customer"
                colors={GUEST_COLOR}
                terminalGridId={undefined}
              />
            </TabPanel>
            {/* 端末情報 */}
            <TabPanel
              value={tabValue}
              index={2}
              borderColor={GUEST_COLOR.primary}
            >
              <TerminalForm
                type="confirm"
                requiredKey="customer"
                colors={GUEST_COLOR}
              />
            </TabPanel>
            {/* 重要事項 */}
            {isShowCheckSheet && (
              <TabPanel
                value={tabValue}
                index={3}
                borderColor={GUEST_COLOR.primary}
              >
                <ConfirmForm
                  formCategory={FORM_CATEGORY.CHECK_SHEET} // 重要事項チェックシート
                  requiredKey="customer"
                  colors={GUEST_COLOR}
                  terminalGridId={undefined}
                />
              </TabPanel>
            )}
          </form>
          {/* 規約一覧 */}
          <GuestDealEditTermList
            termsState={termsState}
            setTermsState={setTermsState}
          />
          <div className={classes.buttonContainer}>
            {/* 戻るボタン */}
            <Button
              className={clsx(
                moveButtonClasses.button,
                classes.returnButton,
                classes.gridButtons
              )}
              type="button"
              component={Link}
              to={`/guest/deal/${match.params.id}/edit`}
              variant="contained"
            >
              <KeyboardReturnIcon className={classes.returnIcon} />
              前の画面に戻る
            </Button>
            {/* 同意して登録するボタン */}
            <Button
              type="submit"
              onClick={(e) =>
                // 現在のステータスと次のステータスが一致しない（=ワークフローが進む）場合はモーダルを開く
                nextWorkflowStatus !== workflowStatus
                  ? setOpen(true)
                  : handleUpdate(e)
              }
              className={clsx(moveButtonClasses.button, classes.addButton)}
              variant="contained"
              disabled={!isAllNeedTermsOpen() || loading.current} // loading.currentは二重送信対策
            >
              <AddIcon className={classes.addIcon} />
              上記の利用規約を契約の内容と
              <br />
              することに同意して申し込みます
            </Button>
          </div>
        </Container>
      </Dashboard>
    </>
  );
};

export default withRouter(GuestDealEditConfirmPage);
