/**
 * 共通-表の見出し
 */
import React from "react";

import { Typography, makeStyles } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import { ColorBackColorInt } from "../types";

import { FONT } from "../../../../constants/common";

import BreakElementForLineText from "./BreakElementForLineText";

const useStyles = makeStyles({
  heading: (props: ColorBackColorInt) => ({
    color: props.backgroundColor,
    fontFamily: FONT,
    textAlign: "center",
    fontSize: "1.4em",
    paddingTop: 5,
    paddingBottom: 5,
    marginTop: props.height,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  }),
  headingTop: {
    marginTop: "0px!important",
  },
  icon: {
    verticalAlign: "top",
  },
});

interface Props {
  title: string;
  colors: ColorBackColorInt;
  index: number; // 処理中の行数
}

const TableHeading: React.FC<Props> = (props: Props) => {
  const { colors, title, index } = props;

  const classes = useStyles({
    color: colors.color,
    backgroundColor: colors.backgroundColor,
    height: index !== 0 ? 20 : 0, // 1行目のヘッダーのみはmarginTopは0で良い。
  });

  return (
    <div>
      <Typography variant="h2" className={classes.heading}>
        <InfoIcon className={classes.icon} />
        <BreakElementForLineText text={` ${title}`} />
      </Typography>
    </div>
  );
};

export default TableHeading;
