import { makeStyles } from "@material-ui/core/styles";
import { FONT } from "../../constants/common";

export const useContainerStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

export const SideBarStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    color: theme.palette.text.secondary,
  },
  linkName: {
    "& span": {
      fontFamily: FONT,
      fontWeight: 500,
    },
  },
}));

export default useContainerStyles;
