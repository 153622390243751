/**
 * JTB側-精算明細書詳細画面のフォーム
 */
import React from "react";

import clsx from "clsx";
import {
  FormControl,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";

import { ADMIN_COLOR, FONT } from "../../../../../constants/common";

const useStyles = makeStyles(() => ({
  table: {
    width: "100%",
    minWidth: 800,
  },
  td: {
    padding: 1,
    backgroundColor: ADMIN_COLOR.base,
    fontFamily: FONT,
    color: "#000!important",
    borderTop: "1px solid #d2d5d8",
    borderBottom: "1px solid #d2d5d8",
    borderRight: "1px solid #d2d5d8",
    width: 400,
  },
  text: {
    padding: "18.5px 14px",
    font: "inherit",
  },
  th: {
    padding: 0,
    fontFamily: FONT,
    backgroundColor: "#fff",
    color: "#000!important",
    width: 200,
    border: "1px solid #d2d5d8",
    position: "relative",
    paddingLeft: 20,
  },
  formControl: {
    marginTop: "0px!important",
  },
}));

interface Props {
  customerCode: string;
}

const BillShowForm: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { customerCode } = props;

  return (
    <FormControl className={classes.formControl}>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            {/* 加盟店コード */}
            <TableRow key="加盟店コード">
              <TableCell align="left" className={classes.th}>
                加盟店コード
              </TableCell>
              <TableCell
                align="left"
                className={clsx(classes.td, classes.text)}
              >
                {customerCode}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </FormControl>
  );
};

export default BillShowForm;
