/**
 * JTB側-自社ユーザー追加画面
 * メモ：ユーザー追加画面を表示する
 */
import React, { useContext, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { UserNewItemObject } from "../../types";

import {
  ADMIN_COLOR,
  DISPLAY_NAME_MAX_LENGTH,
  EMAIL_MAX_LENGTH,
  PHONE_NUMBER_MAX_LENGTH,
  MEMO_MAX_LENGTH,
  EMAIL_CHAR_TYPE,
  PHONE_NUMBER_CHAR_TYPE,
} from "../../../../../constants/common";
import {
  USER_TYPE_NEW_MAP,
  USER_TYPE_LIST,
} from "../../../../../constants/user";

import { AuthContext } from "../../../../../cognito/AuthContext";
import {
  compareSJISWhiteList,
  checkPattern,
} from "../../../../../form/validation";
import { isCardCompanyUser } from "../../../../../utils/auth";
import cognitoUser from "../../../../../api/cognitoUser";
import getErrorMessage from "../../../../../utils/error";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import SettingUserNewForm from "../organisms/SettingUserNewForm";
import AlertMessage from "../../../../common/components/atoms/AuthAlertMsg";
import Loading from "../../../../common/components/molecules/Loading";

const breadCrumbs = [
  { name: "設定", link: "/dealer/setting" },
  { name: "自社ユーザー管理・検索", link: "/dealer/setting/user" },
  { name: "自社ユーザー追加", link: "" },
];

const useStyles = makeStyles(() => ({
  addButton: {
    margin: "0 auto",
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 27,
  },
  addIcon: {
    position: "absolute",
    top: 8,
    left: 31,
  },
  form: {
    width: 800,
    marginTop: 20,
  },
}));

const SettingUserNewPage: React.FC<RouteComponentProps> = (props) => {
  const buttonClasses = buttonStyles({
    width: 180,
    marginTop: 20,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const containerClasses = useContainerStyles();
  const classes = useStyles();

  const { history } = props;

  const { authHeader } = useContext(AuthContext);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [createUserError, setCreateUserError] = useState(<div />);
  const [userNewState, setUserNewState] = useState<UserNewItemObject>({
    userType: "", // ユーザー種別
    email: "", // メールアドレス
    name: "", // ユーザー名
    phoneNumber: "", // 電話番号
    memo: "", // メモ欄
  });

  const formatCheck = (string: string, charType: string) => {
    const errorArray = checkPattern(string, charType, "形式", []);
    return errorArray[0];
  };

  // 追加するボタンを押した際の処理
  const handleToCompletePage = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    // Material-UIのボタンは、e.preventDefault入れないと、強制画面遷移(同じ画面でも同じ画面に遷移)で、画面全体がDOMマウントから再レンダーされてしまう。
    e.preventDefault();

    // 必須チェック
    // ユーザー種別はJTB社ユーザーでない場合は項目がないためチェックしない
    if (!isCardCompanyUser() && userNewState.userType === "") {
      setCreateUserError(
        <AlertMessage errorMessage="ユーザー種別を入力してください。" />
      );
      return;
    }
    if (userNewState.email === "") {
      setCreateUserError(
        <AlertMessage errorMessage="メールアドレスを入力してください。" />
      );
      return;
    }
    if (userNewState.name === "") {
      setCreateUserError(
        <AlertMessage errorMessage="ユーザー名を入力してください。" />
      );
      return;
    }
    if (userNewState.phoneNumber === "") {
      setCreateUserError(
        <AlertMessage errorMessage="電話番号を入力してください。" />
      );
      return;
    }

    // 桁数チェック
    if (userNewState.email.toString().length > EMAIL_MAX_LENGTH) {
      setCreateUserError(
        <AlertMessage
          errorMessage={`メールアドレスは最大${EMAIL_MAX_LENGTH}文字です。`}
        />
      );
      return;
    }
    if (userNewState.name.toString().length > DISPLAY_NAME_MAX_LENGTH) {
      setCreateUserError(
        <AlertMessage
          errorMessage={`ユーザー名は最大${DISPLAY_NAME_MAX_LENGTH}文字です。`}
        />
      );
      return;
    }
    if (userNewState.phoneNumber.toString().length > PHONE_NUMBER_MAX_LENGTH) {
      setCreateUserError(
        <AlertMessage
          errorMessage={`電話番号は最大${PHONE_NUMBER_MAX_LENGTH}文字です。`}
        />
      );
      return;
    }
    if (userNewState.memo.toString().length > MEMO_MAX_LENGTH) {
      setCreateUserError(
        <AlertMessage
          errorMessage={`メモ欄は最大${MEMO_MAX_LENGTH}文字です。`}
        />
      );
      return;
    }

    // メールアドレスのフォーマットチェック
    const emailError = formatCheck(userNewState.email, EMAIL_CHAR_TYPE);
    if (emailError) {
      setCreateUserError(
        <AlertMessage errorMessage="正しいメールアドレス形式をご指定ください" />
      );
      return;
    }
    // 電話番号のフォーマットチェック
    const phoneNumberError = formatCheck(
      userNewState.phoneNumber,
      PHONE_NUMBER_CHAR_TYPE
    );
    if (phoneNumberError) {
      setCreateUserError(
        <AlertMessage errorMessage="正しい電話番号形式をご指定ください" />
      );
      return;
    }

    // 使用可能文字チェック
    const errChar = compareSJISWhiteList([
      userNewState.name.toString(),
      userNewState.memo.toString(),
    ]);
    if (errChar) {
      setCreateUserError(
        <AlertMessage
          errorMessage={`当システムで使用できない文字が含まれています。（${errChar}）`}
        />
      );
      return;
    }

    setLoadingOpen(true);

    // ユーザー種別を名称から番号に変換
    const jtbUserType = USER_TYPE_NEW_MAP[userNewState.userType]
      ? USER_TYPE_NEW_MAP[userNewState.userType]
      : "";
    // カード会社ユーザーの場合は固定値(100)を送る
    const userType = isCardCompanyUser()
      ? USER_TYPE_LIST.CARD_COMPANY
      : jtbUserType;

    await cognitoUser
      .createUser(
        authHeader as string,
        userType,
        userNewState.email,
        userNewState.name,
        userNewState.phoneNumber,
        userNewState.memo
      )
      .then((res) => {
        if (res.data.result) {
          setCreateUserError(<div />);
          history.push("/dealer/setting/user/new/complete");
          return;
        }
        setCreateUserError(
          <AlertMessage errorMessage="エラーが発生しました。管理者へお問い合わせください。" />
        );
      })
      .catch((error) => {
        setCreateUserError(
          <AlertMessage errorMessage={getErrorMessage(error)} />
        );
      })
      .finally(() => {
        setLoadingOpen(false);
      });
  };

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Dashboard
        title="設定"
        color={ADMIN_COLOR.base}
        backgroundColor={ADMIN_COLOR.primary}
        iconName="setting"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {createUserError}
          <form className={classes.form}>
            {/* ユーザー追加フォーム */}
            <SettingUserNewForm
              userNewState={userNewState}
              setUserNewState={setUserNewState}
            />
            {/* 追加するボタン */}
            <Button
              type="submit"
              onClick={handleToCompletePage}
              className={clsx(buttonClasses.button, classes.addButton)}
              variant="contained"
              disabled={loadingOpen} // 二重送信対策
            >
              <AddIcon className={classes.addIcon} />
              追加する
            </Button>
          </form>
        </Container>
      </Dashboard>
    </>
  );
};

export default withRouter(SettingUserNewPage);
