/**
 * 共通-ダッシュボードの申請表示領域の見出し
 */
import React from "react";

import { makeStyles } from "@material-ui/core";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import CachedIcon from "@material-ui/icons/Cached";
import DoneIcon from "@material-ui/icons/Done";

const useStyles = makeStyles({
  heading: (props: ManagerHeader) => ({
    color: props.backgroundColor,
    textAlign: "left",
    fontSize: "1.0rem",
    paddingTop: 5,
    marginTop: 5,
    paddingBottom: 5,
    marginBottom: 5,
    paddingLeft: 10,
    width: props.width,
  }),
  icon: {
    position: "relative",
    top: 5,
    fontSize: "1.3em",
  },
});

type ManagerHeader = {
  width: number;
  backgroundColor: string;
};

interface Props {
  title: string;
  tableStatus: string;
  backgroundColor: string;
  width: number;
}

const ManagerHeading: React.FC<Props> = (props: Props) => {
  const { title, tableStatus, backgroundColor, width } = props;

  const classes = useStyles({ backgroundColor, width });

  const iconView = {
    apply: <AccessibilityNewIcon className={classes.icon} />,
    progress: <CachedIcon className={classes.icon} />,
    complete: <DoneIcon className={classes.icon} />,
  };

  return (
    <h2 className={classes.heading}>
      {/* アイコン */}
      {iconView[tableStatus as keyof typeof iconView]}
      {/* タイトル */}
      {` ${title}`}
    </h2>
  );
};

export default ManagerHeading;
