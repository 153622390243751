// APIで返ってくる型は業務外の付与情報がついているのでフロントでは型を明確に定義しない。
import axios from "axios";
import { TSortCondition, TRequestType } from "../views/common/components/types";
import {
  dealSearchParameters,
  IRegisterDealInput,
  IUpdateNewDealInput,
} from "./types";
import { checkAuth } from "./utils/authUtils";
import {
  createAPIPromise,
  createLoadingPromise,
  createParamStr,
  loadingUrls,
} from "./utils/common";

/**
 * formMaster情報取得API
 * @param authHeader: 認証トークン
 * @param ver: formVersion
 * @param terminationFlag?: 解約フラグ（解約用JSONを取得したい場合に使用。trueを入れる）
 * @returns Promise<{result: {json: TJsonRowObject[]}}>
 */
const getFormMaster = (
  authHeader: string,
  ver: string,
  terminationFlag?: boolean
) => {
  const path = `/formMasterJson/${ver}`;
  const params = { terminationFlag };
  const paramStr = createParamStr(params);
  const loadingKey = `${path}?${paramStr}`;

  if (loadingUrls.includes(loadingKey)) {
    return createLoadingPromise();
  }
  loadingUrls.push(loadingKey);
  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    loadingKey
  );
};

/**
 * ダッシュボードAPI
 * @param authHeader: 認証トークン
 * @param pageNumber: ページ指定
 * @param requestType: TRequestType
 * @param sortCondition: ソート情報
 * @returns
 */
const dashboard: (
  authHeader: string,
  pageNumber: string,
  requestType: TRequestType,
  sortCondition?: TSortCondition
  // eslint-disable-next-line
) => Promise<any> = async (
  authHeader: string,
  pageNumber: string,
  requestType: TRequestType,
  sortCondition?: TSortCondition
) => {
  // 権限チェック
  const error = await checkAuth("dashboard");
  if (error) {
    return Promise.reject(error);
  }

  const path = `/dashboards`;
  const paramStr = createParamStr({
    pageNumber,
    requestType,
    ...sortCondition,
  });
  const loadingKey = `${path}?${paramStr}`;
  if (loadingUrls.includes(loadingKey)) {
    return createLoadingPromise();
  }
  loadingUrls.push(loadingKey);
  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      params: { pageNumber, requestType, ...sortCondition },
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    loadingKey
  );
};

/**
 * 新規申請キャンセルAPI
 * @param authHeader 認証トークン
 * @param id 申請id
 * @returns
 */
const cancelNewDeal = (authHeader: string, id: string) => {
  const path = `/newDeals/${id}/cancel`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.put(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

/**
 * 申請検索API（JTB用）
 * @param authHeader 認証トークン
 * @param customerName 加盟店名
 * @param dealId 申請番号
 * @param corporateNum 法人番号
 * @param customerCode 加盟店コード
 * @param companyName 会社名
 * @param termiIdentNum 端末識別番号
 * @param applicationDateFrom 申請日(From)
 * @param applicationDateTo  申請日(To)
 * @param dealType 申請種別
 * @param workflowStatus  ワークフローステータス
 * @param pageNumber ページ番号
 */
const searchForJTB = async (
  authHeader: string,
  customerName: string,
  dealId: string,
  corporateNum: string,
  customerCode: string,
  companyName: string,
  termiIdentNum: string,
  applicationDateFrom: string,
  applicationDateTo: string,
  dealType: string,
  workflowStatus: string,
  pageNumber: string
  // eslint-disable-next-line
): Promise<any> => {
  const params: dealSearchParameters = {
    pageNumber,
  };
  // APIの日付受付がYYYY/MM/DD hh:mm:ssなので置換と時刻の追加を実施
  // 帳票・データ出力などでの日付検索と入力内容合わせるため入力フォームはいじらない
  params.customerName = customerName === "" ? undefined : customerName;
  params.dealId = dealId === "" ? undefined : dealId;
  params.corporateNum = corporateNum === "" ? undefined : corporateNum;
  params.customerCode = customerCode === "" ? undefined : customerCode;
  params.companyName = companyName === "" ? undefined : companyName;
  params.termiIdentNum = termiIdentNum === "" ? undefined : termiIdentNum;
  params.applicationDateFrom =
    applicationDateFrom === ""
      ? undefined
      : `${applicationDateFrom.replace(/-/g, "/")} 00:00:00`;
  params.applicationDateTo =
    applicationDateTo === ""
      ? undefined
      : `${applicationDateTo.replace(/-/g, "/")} 23:59:59`;
  params.dealType = dealType === "" ? undefined : dealType;
  params.workflowStatus = workflowStatus === "" ? undefined : workflowStatus;

  const path = `/dealsForJTB`;
  const paramStr = createParamStr(params);
  const loadingKey = `${path}?${paramStr}`;
  if (loadingUrls.includes(loadingKey)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(loadingKey);

  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    loadingKey
  );
};

/**
 * 申請検索API（加盟店用）
 * @param authHeader 認証トークン
 * @param customerName 加盟店名
 * @param dealId 申請番号
 * @param customerCode 加盟店コード
 * @param termiIdentNum 端末識別番号
 * @param applicationDateFrom 申請日(From)
 * @param applicationDateTo  申請日(To)
 * @param dealType 申請種別
 * @param workflowStatus  ワークフローステータス
 * @param pageNumber ページ番号
 */
const searchForCustomer = async (
  authHeader: string,
  customerName: string,
  dealId: string,
  customerCode: string,
  termiIdentNum: string,
  applicationDateFrom: string,
  applicationDateTo: string,
  dealType: string,
  workflowStatus: string,
  pageNumber: string
  // eslint-disable-next-line
): Promise<any> => {
  const params: dealSearchParameters = {
    pageNumber,
  };
  // APIの日付受付がYYYY/MM/DD hh:mm:ssなので置換と時刻の追加を実施
  // 帳票・データ出力などでの日付検索と入力内容合わせるため入力フォームはいじらない
  params.customerName = customerName === "" ? undefined : customerName;
  params.dealId = dealId === "" ? undefined : dealId;
  params.customerCode = customerCode === "" ? undefined : customerCode;
  params.termiIdentNum = termiIdentNum === "" ? undefined : termiIdentNum;
  params.applicationDateFrom =
    applicationDateFrom === ""
      ? undefined
      : `${applicationDateFrom.replace(/-/g, "/")} 00:00:00`;
  params.applicationDateTo =
    applicationDateTo === ""
      ? undefined
      : `${applicationDateTo.replace(/-/g, "/")} 23:59:59`;
  params.dealType = dealType === "" ? undefined : dealType;
  params.workflowStatus = workflowStatus === "" ? undefined : workflowStatus;

  const path = `/dealsForCustomer`;
  const paramStr = createParamStr(params);
  const loadingKey = `${path}?${paramStr}`;
  if (loadingUrls.includes(loadingKey)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(loadingKey);

  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    loadingKey
  );
};

/**
 * 新規申請登録API
 * @param authHeader: 認証トークン
 * @param dealPostData: 申請POSTデータ
 */
const store = async (
  authHeader: string,
  dealPostData: IRegisterDealInput
  // eslint-disable-next-line
): Promise<any> => {
  const path = `/newDeals`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);

  return createAPIPromise(
    axios.post(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      {
        ...dealPostData,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

/**
 * 個別新規申請情報取得API
 * @param authHeader: 認証トークン
 * @param id: 申請ID
 */
const show = async (authHeader: string, id: string) => {
  const path = `/newDeals/${id}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    path
  );
};

/**
 * 追加資料リスト取得API
 * @param authHeader: 認証トークン
 * @param id: 申請ID
 */
const getExtraUploadFiles = async (authHeader: string, id: string) => {
  const path = `/files/${id}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    path
  );
};

/**
 * 新規申請編集API
 * @param authHeader: 認証トークン
 * @param id: 申請ID
 * @param dealObject: 申請オブジェクト
 */
const update = async (
  authHeader: string,
  id: string,
  dealObject: IUpdateNewDealInput
  // eslint-disable-next-line
): Promise<any> => {
  const path = `/newDeals/${id}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.put(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      { ...dealObject },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

/**
 * 申請情報pdf保管API
 * @param authHeader: 認証トークン
 * @param id: 申請ID
 */
const storeDealPdf = async (
  authHeader: string,
  id: string
  // eslint-disable-next-line
): Promise<any> => {
  // POSTメソッドだが、クエリパラメタが必要なのでくっつける
  const path = `/storeDealPdf?id=${id}`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.post(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

/**
 * 加盟店ユーザーリスト取得API
 * @param authHeader: 認証トークン
 * @param id: 申請ID
 */
const getDealCustomerUser: (
  authHeader: string,
  id: number
  // eslint-disable-next-line
) => Promise<any> = async (authHeader: string, id: number) => {
  const path = `/deals/${id}/customerList`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.get(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      data: {},
    }),
    path
  );
};

/**
 * ユーザーメッセージ送信API
 */
const sendUserMessage: (
  authHeader: string,
  dealId: number,
  addresses: string,
  message: string
  // eslint-disable-next-line
) => Promise<any> = async (
  authHeader: string,
  dealId: number,
  addresses: string,
  message: string
) => {
  const params = { dealId, addresses, message };
  const path = `/messages`;
  const paramStr = createParamStr(params);
  const loadingKey = `${path}?${paramStr}`;
  if (loadingUrls.includes(loadingKey)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(loadingKey);
  return createAPIPromise(
    axios.post(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, params, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
    }),
    loadingKey
  );
};

const updateFormComment = async (
  authHeader: string,
  dealId: string,
  comment: string
) => {
  const path = `/deals/${dealId}/comment`;
  const params = { comment };
  const paramStr = createParamStr(params);
  const loadingKey = `${path}?${paramStr}`;
  if (loadingUrls.includes(loadingKey)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(loadingKey);
  return createAPIPromise(
    axios.put(`${process.env.REACT_APP_API_DOMAIN as string}${path}`, params, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
    }),
    loadingKey
  );
};

/**
 * 加盟店コード更新API
 * @param authHeader: 認証トークン
 * @param dealId: 申請ID
 * @param customerCode: 加盟店コード
 */
const updateCustomerCode = async (
  authHeader: string,
  dealId: number,
  customerCode: string
) => {
  const path = `/deals/${dealId}/customerCode`;
  if (loadingUrls.includes(path)) {
    return createLoadingPromise();
  }
  // 通信中登録
  loadingUrls.push(path);
  return createAPIPromise(
    axios.put(
      `${process.env.REACT_APP_API_DOMAIN as string}${path}`,
      {
        customerCode,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      }
    ),
    path
  );
};

export default {
  getFormMaster,
  dashboard,
  cancelNewDeal,
  searchForJTB,
  searchForCustomer,
  show,
  getExtraUploadFiles,
  store,
  update,
  storeDealPdf,
  getDealCustomerUser,
  sendUserMessage,
  updateFormComment,
  updateCustomerCode,
};
