/**
 * 共通-ステータス更新モーダル
 * メモ: ワークフローステータス更新時に表示するモーダル
 */
import React from "react";
import clsx from "clsx";

import { makeStyles, Theme, Modal, Grid, Button } from "@material-ui/core";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";

import { buttonStyles } from "../styles";

import { ThemeColorProps } from "../../../../constants/common";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: "absolute",
    width: 334,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
  },
  problemIcon: (props: ColorProps) => ({
    position: "relative",
    top: 5,
    left: 0,
    fontSize: "1.4em",
    color: props.color,
  }),
  confirmMessage: {
    marginBottom: 20,
    color: "#777",
  },
  modalTitle: {
    fontSize: "1.1em",
  },
  commonButton: {
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: "center",
    fontSize: "0.9em",
  },
  cancelButton: (props: ColorProps) => ({
    color: `${props.color}!important`,
    backgroundColor: `${props.backgroundColor}!important`,
    border: `1px solid ${props.color}`,
    paddingBottom: 6,
    "&:disabled": {
      borderColor: "#e0e0e0",
    },
    "&:hover": {
      backgroundColor: `${props.btnHoverColor}!important`,
    },
  }),
  rightGrid: {
    marginLeft: 10,
  },
}));

type ColorProps = {
  color: string;
  backgroundColor: string;
  btnHoverColor: string;
};

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalTitle: string;
  handleUpdate: (e: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void>;
  loadingOpen: boolean;
  colors: ThemeColorProps; // 画面の色（JTB色か加盟店色を指定）
}

const StatusUpdateModal: React.FC<Props> = (props: Props) => {
  const { modalTitle, handleUpdate, open, setOpen, loadingOpen, colors } =
    props;

  const classes = useStyles({
    color: colors.primary,
    backgroundColor: colors.base,
    btnHoverColor: colors.btnHover,
  });
  const modalButtonClasses = buttonStyles({
    width: 130,
    marginTop: 0,
    backgroundColor: colors.primary,
  });
  const getModalStyle = () => ({
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  });
  const [modalStyle] = React.useState(getModalStyle);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <h2 id="simple-modal-title" className={classes.modalTitle}>
          {modalTitle}
        </h2>
        <p id="simple-modal-description" className={classes.confirmMessage}>
          <ReportProblemIcon className={classes.problemIcon} />
          {" 本当に実行しますか？"}
        </p>
        <Grid container justify="flex-start">
          <Grid item>
            <Button
              onClick={handleUpdate}
              className={clsx(modalButtonClasses.button, classes.commonButton)}
              variant="contained"
              disabled={loadingOpen} // 二重送信対策
            >
              はい
            </Button>
          </Grid>
          <Grid item className={classes.rightGrid}>
            <Button
              onClick={handleClose}
              className={clsx(
                modalButtonClasses.button,
                classes.commonButton,
                classes.cancelButton
              )}
              variant="contained"
              disabled={loadingOpen} // 二重送信対策
            >
              いいえ
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default StatusUpdateModal;
