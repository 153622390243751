/**
 * 加盟店側-加盟店管理 検索フォーム
 * メモ：加盟店管理の検索フォームを表示する
 */
import React from "react";

import clsx from "clsx";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";

import {
  formStyles,
  checkboxStyles,
} from "../../../../common/components/styles";

import { CustomerSearchCondition } from "../../types";

import { GUEST_COLOR, FONT } from "../../../../../constants/common";
import { CUSTOMER_TYPE_LIST } from "../../../../../constants/customer";

import { isSysAd } from "../../../../../utils/auth";

import CustomTextField from "../../../../common/components/atoms/CustomTextField";
import VariableCustomCheckbox from "../../../../common/components/atoms/VariableCustomCheckbox";
import PatternMatchChip from "../../../../common/components/atoms/PatternMatchChip";

// スタイル設定
const useStyles = makeStyles(() => ({
  label: {
    width: 800,
    textAlign: "left",
    padding: "0!important",
    height: 36,
  },
  table: {
    width: 800,
    minWidth: 800,
  },
  td: {
    padding: 1,
    backgroundColor: "#fff",
    color: "#000!important",
    borderTop: "1px solid #d2d5d8",
    borderBottom: "1px solid #d2d5d8",
    borderRight: "1px solid #d2d5d8",
    width: 400,
  },
  th: {
    padding: 0,
    fontFamily: FONT,
    backgroundColor: "#fff",
    color: "#000!important",
    width: 200,
    border: "1px solid #d2d5d8",
    position: "relative",
    paddingLeft: 20,
  },
  checkbox: {
    margin: "10px 20px",
  },
}));

// 検索条件フォーム設定
const customerSearchFormData = [
  {
    name: "契約者",
    formData: "companyName",
    matchType: "部分一致",
  },
  {
    name: "加盟店コード",
    formData: "customerCode",
    matchType: "前方一致",
  },
  {
    name: "加盟店名",
    formData: "customerName",
    matchType: "部分一致",
  },
  {
    name: "加盟店電話番号",
    formData: "customerPhoneNum",
    matchType: "完全一致",
  },
  {
    name: "解約済みの加盟店",
    formData: "customerTerminationFlag",
    matchType: "",
  },
];

// 加盟店検索入力フォームインターフェース
interface Props {
  searchCondition: CustomerSearchCondition;
  setSearchCondition: React.Dispatch<
    React.SetStateAction<CustomerSearchCondition>
  >;
}

const CustomerSearchConditionForm: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const formClasses = formStyles({
    color: GUEST_COLOR.base,
    backgroundColor: GUEST_COLOR.primary,
  });
  const checkboxClasses = checkboxStyles({
    backgroundColor: GUEST_COLOR.primary,
  });

  const { searchCondition, setSearchCondition } = props;

  // 加盟店分類のプルダウン内容
  const customerTypeArray = Array.from(CUSTOMER_TYPE_LIST.keys());
  customerTypeArray.unshift("指定なし");

  // システム担当者以外は会社名の選択肢を省く
  const userSearchForm = isSysAd()
    ? customerSearchFormData
    : customerSearchFormData.filter((item) => item.formData !== "companyName");

  return (
    <FormControl className={formClasses.form}>
      <FormLabel className={clsx(formClasses.formLabel, classes.label)}>
        加盟店検索
      </FormLabel>
      <TableContainer>
        <Table
          className={clsx(formClasses.table, classes.table)}
          aria-label="simple table"
        >
          <TableBody>
            {userSearchForm.map((formItem) => (
              <TableRow key={formItem.name}>
                <TableCell
                  align="left"
                  className={clsx(formClasses.th, classes.th)}
                >
                  {formItem.name}
                  {formItem.matchType && (
                    <PatternMatchChip
                      left={153}
                      top="24%"
                      text={formItem.matchType}
                    />
                  )}
                </TableCell>
                <TableCell align="left" className={classes.td}>
                  {/* 解約済み含むチェックボックス  */}
                  {formItem.formData === "customerTerminationFlag" && (
                    <FormControlLabel
                      className={clsx(
                        checkboxClasses.checkbox,
                        classes.checkbox
                      )}
                      control={
                        <VariableCustomCheckbox
                          name={formItem.name}
                          value={formItem.formData}
                          checked={searchCondition.customerTerminationFlag}
                          onChange={() =>
                            setSearchCondition({
                              ...searchCondition,
                              customerTerminationFlag:
                                !searchCondition.customerTerminationFlag,
                            })
                          }
                        />
                      }
                      label="含む"
                    />
                  )}
                  {/* その他の項目 */}
                  {formItem.formData !== "customerTerminationFlag" && (
                    <CustomTextField
                      value={String(
                        searchCondition[
                          formItem.formData as keyof CustomerSearchCondition
                        ]
                      )}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSearchCondition({
                          ...searchCondition,
                          [formItem.formData]: e.target.value,
                        })
                      }
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FormControl>
  );
};

export default CustomerSearchConditionForm;
