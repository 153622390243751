/**
 * 加盟店側 - 契約者管理 - 詳細画面
 * 操作ボタンの制御もここで行う
 */
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import {
  withRouter,
  RouteComponentProps,
  Link,
  useLocation,
} from "react-router-dom";

import clsx from "clsx";
import {
  Container,
  makeStyles,
  Grid,
  Box,
  Chip,
  Button,
  Typography,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";

import useContainerStyles from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import {
  TFormColumn,
  TJsonRowObject,
  TContractorFormColumn,
} from "../../../../common/components/types";

import { GUEST_COLOR, FONT } from "../../../../../constants/common";
import {
  DISPLAY_CONTRACTOR_OPERATING_STATUS,
  CONTRACTOR_OPERATING_STATUS_NAME,
  CONTRACTOR_OPERATING_STATUS,
} from "../../../../../constants/contractor";

import { GlobalPopupContext } from "../../../../../App";
import { AuthContext } from "../../../../../cognito/AuthContext";
import deal from "../../../../../api/deal";
import contractors from "../../../../../api/contractors";
import preloadFormContent from "../../../../../api/utils/preUtils";
import {
  getContractorContents,
  json2FormData,
} from "../../../../../utils/formData";
import { checkAuth, isCustomerStaffUser } from "../../../../../utils/auth";
import getErrorMessage from "../../../../../utils/error";
import formStore from "../../../../../store/formStore";

import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import Dashboard from "../../../../templates/Dashboard";
import ShowForm from "../../../../form/components/organisms/ShowForm";
import ShowItemHeading from "../../../../common/components/atoms/ShowItemHeading";
import TabPanel from "../../../../common/components/atoms/TabPanel";

// スタイル設定
const useStyles = makeStyles(() => ({
  h2: {
    fontSize: "1.2em",
    color: GUEST_COLOR.primary,
    border: `3px solid ${GUEST_COLOR.primary}`,
    borderBottom: 0,
    paddingTop: 9,
    paddingLeft: 35,
    fontWeight: "bold",
    height: 50,
    fontFamily: FONT,
    backgroundColor: "#fff",
    zIndex: 2,
    lineHeight: "1.7em",
  },
  peopleIcon: {
    verticalAlign: "top",
    fontSize: "1.7rem",
    marginTop: 1,
  },
  chip: {
    backgroundColor: GUEST_COLOR.base,
    color: GUEST_COLOR.primary,
    border: `1px solid ${GUEST_COLOR.primary}`,
    top: -1,
    left: 30,
    position: "relative",
    borderRadius: 0,
  },
  box: {
    border: `3px solid ${GUEST_COLOR.primary}`,
    marginBottom: 15,
    paddingBottom: 13,
    paddingLeft: 13,
    zIndex: 1,
    borderRadius: 0,
  },
  leftBox: {
    width: 263,
    textAlign: "center",
    borderRadius: 0,
  },
  titleBox: {
    fontSize: "0.9em",
    fontWeight: "bold",
    fontFamily: FONT,
    backgroundColor: GUEST_COLOR.primary,
    border: `3px solid ${GUEST_COLOR.primary}`,
    color: GUEST_COLOR.base,
    textAlign: "center",
    borderRadius: 0,
  },
  titleGrid: {
    alignItems: "center",
  },
  message: {
    fontSize: "1.2em",
    fontWeight: "bold",
    fontFamily: FONT,
    color: GUEST_COLOR.primary,
    marginBottom: 15,
    marginTop: 26,
    marginLeft: 35,
  },
  infoMessage: {
    fontSize: "0.9em",
    fontWeight: "bold",
    fontFamily: FONT,
    color: GUEST_COLOR.primary,
    marginBottom: 15,
  },
  linkButton: {
    textAlign: "center",
  },
  terminated: {
    backgroundColor: "gray",
  },
}));

const ContractorShowPage: React.FC<RouteComponentProps<{ id: string }>> = (
  props: RouteComponentProps<{ id: string }>
) => {
  const { match } = props;
  const { state } = useLocation(); // 現在の検索条件を受け取る(パンくずで戻る時用)

  const { authHeader } = useContext(AuthContext);
  const { setLoading } = useContext(GlobalPopupContext);
  const [apiErrorMsg, setApiErrorMsg] = useState("");
  const [init, setInit] = useState(false);
  const [displaySettings, setDisplaySettings] = useState({
    operatingStatus: "", // 契約者稼働状態
    canChangeFlg: false, // 契約者変更申請ボタン制御フラグ
    canCustomerEditFlg: false, // 契約者情報編集ボタン(加盟店)制御フラグ
    dateReflected: "", // 反映日
    dateToBeReflected: "", // 反映予定日
  });

  const classes = useStyles();
  const containerClasses = useContainerStyles();
  const buttonClasses = buttonStyles({
    width: 213,
    marginTop: 15,
    backgroundColor: GUEST_COLOR.primary,
  });

  // パンくずリスト
  const breadCrumbs = useMemo(
    () => [
      {
        name: "契約者管理・検索",
        link: { pathname: "/guest/contractor", state },
      },
      { name: "詳細", link: "" },
    ],
    [state]
  );

  // 契約者情報の取得処理
  const getData = useCallback(async () => {
    if (!authHeader) return;
    setLoading(true);
    try {
      // Json取得
      const ret = await deal.getFormMaster(authHeader, "latest");
      await preloadFormContent(authHeader, "latest");
      const data = json2FormData(ret.data.result as TJsonRowObject[]);
      formStore.setFormData(
        data.formData,
        data.formGroupData,
        data.columnToCategory
      );

      // 契約者情報取得、反映
      const showApiRet = await contractors.show(authHeader, match.params.id);
      const showData = getContractorContents(
        showApiRet.data.result.contractors[0].masterContractorForms
      );
      formStore.updateContents(showData.updateData, "dealer");

      // 他DB情報の設定
      Object.keys(showData.externalDBData).forEach((columnName) => {
        formStore.setExternalDBData(
          columnName as TFormColumn,
          0, // 端末情報ではないのでterminalGridIdは0固定
          0, // gridIdもJsonによるgrid制御がないため0固定
          showData.externalDBData[columnName as TContractorFormColumn]
        );
      });

      // ボタンの出しわけ等画面表示に使う項目をセット
      setDisplaySettings({
        operatingStatus: showApiRet.data.result.contractors[0].operatingStatus,
        canChangeFlg: showApiRet.data.result.contractors[0].canChangeFlg,
        canCustomerEditFlg:
          showApiRet.data.result.contractors[0].canCustomerEditFlg,
        dateReflected: showApiRet.data.result.contractors[0].dateReflected,
        dateToBeReflected:
          showApiRet.data.result.contractors[0].dateToBeReflected,
      });
      setApiErrorMsg("");
      setLoading(false);
      setInit(true);
      // eslint-disable-next-line
    } catch (error: any) {
      setApiErrorMsg(getErrorMessage(error));
      setLoading(false);
      setInit(true);
    }
  }, [setLoading, authHeader, match.params.id]);

  // データ取得
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <Dashboard
      title="契約者管理"
      color={GUEST_COLOR.base}
      backgroundColor={GUEST_COLOR.primary}
      iconName="contractor"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {apiErrorMsg !== "" && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {apiErrorMsg}
          </Alert>
        )}
        {init && apiErrorMsg === "" && (
          <>
            <Typography
              variant="h2"
              className={clsx(
                classes.h2,
                // 解約済みの場合グレーアウト
                displaySettings.operatingStatus ===
                  CONTRACTOR_OPERATING_STATUS.TERMINATED && classes.terminated
              )}
            >
              <PeopleOutlineIcon className={classes.peopleIcon} />
              各種操作
              {/* ステータスのタグ表示 */}
              {DISPLAY_CONTRACTOR_OPERATING_STATUS.includes(
                displaySettings.operatingStatus
              ) && (
                <Chip
                  label={
                    CONTRACTOR_OPERATING_STATUS_NAME[
                      displaySettings.operatingStatus
                    ]
                  }
                  color="primary"
                  variant="outlined"
                  className={classes.chip}
                />
              )}
            </Typography>
            {/* 操作ボタン */}
            <Box
              className={clsx(
                classes.box,
                // 解約済みの場合グレーアウト
                displaySettings.operatingStatus ===
                  CONTRACTOR_OPERATING_STATUS.TERMINATED && classes.terminated
              )}
              borderRadius={1}
            >
              <Grid container spacing={2}>
                {/* 契約者情報編集ボタン */}
                {checkAuth("contractorEdit") && (
                  <Grid item>
                    <Button
                      className={clsx(buttonClasses.button, classes.linkButton)}
                      type="button"
                      variant="contained"
                      component={Link}
                      to={`/guest/contractor/${match.params.id}/edit`}
                      disabled={!displaySettings.canCustomerEditFlg}
                    >
                      契約者情報編集
                    </Button>
                  </Grid>
                )}
                {/* 契約者変更申請ボタン */}
                {/* 加盟店管理者、加盟店一般(事務担当)は契約者情報の変更は不可。加盟店一般(経理)はそもそも変更権限がない */}
                {checkAuth("changeDealsCreate") && !isCustomerStaffUser() && (
                  <Grid item>
                    <Button
                      className={clsx(buttonClasses.button, classes.linkButton)}
                      type="button"
                      variant="contained"
                      component={Link}
                      to="/guest/deal/change/new"
                      disabled={!displaySettings.canChangeFlg}
                      onClick={() => {
                        // 契約者IDをセットする
                        formStore.setContractorId(Number(match.params.id));
                        // 加盟店IDをセットする
                        formStore.setCustomerId(null);
                        // マスター加盟店IDをセットする
                        formStore.setMasterCustomerId(null);
                        // マスター端末IDをセットする
                        formStore.setMasterTerminalId(null);
                        // DealTypeをセットする
                        formStore.setDealType("契約者変更");
                        // 変更有無チェックボックスリセット
                        formStore.resetChangeableColumnNames();
                      }}
                    >
                      契約者変更申請
                    </Button>
                  </Grid>
                )}
                {/* 加盟店一覧ボタン */}
                <Grid item>
                  <Button
                    className={clsx(buttonClasses.button, classes.linkButton)}
                    type="button"
                    variant="contained"
                    component={Link}
                    to={{
                      pathname: "/guest/customer",
                      state: {
                        contractorId: match.params.id,
                        customerTerminationFlag: true, // 解約済み加盟店を含む
                      },
                    }}
                  >
                    加盟店一覧
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <div className={classes.infoMessage}>
              ※他の申請が処理中の場合は、新規申請作成ボタンが押せない場合があります。必要であれば申請中のものをキャンセルの上、作成してください。
            </div>
            {/* 表示見出し */}
            <ShowItemHeading
              dateReflected={displaySettings.dateReflected}
              dateToBeReflected={displaySettings.dateToBeReflected}
              colors={GUEST_COLOR}
            />
            <TabPanel value={0} index={0} borderColor={GUEST_COLOR.primary}>
              {/* 詳細表示 */}
              <ShowForm
                formCategory={1}
                requiredKey="customer"
                colors={GUEST_COLOR}
                terminalGridId={0}
              />
            </TabPanel>
          </>
        )}
      </Container>
    </Dashboard>
  );
};

export default withRouter(ContractorShowPage);
