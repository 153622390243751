/**
 * JTB側-契約者検索結果リスト
 * メモ：検索結果の一覧を表示する
 */
import React from "react";
import { Link } from "react-router-dom";

import clsx from "clsx";
import {
  Button,
  FormControl,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import {
  buttonStyles,
  formStyles,
  paginationStyles,
} from "../../../../common/components/styles";

import { ContractorSearchCondition } from "../../types";
import { IContractorSearchResponse } from "../../../../../api/types";

import {
  ADMIN_COLOR,
  FONT,
  SEARCH_PER_PAGE,
} from "../../../../../constants/common";
import { CONTRACTOR_OPERATING_STATUS } from "../../../../../constants/contractor";

import SearchCountText from "../../../../common/components/atoms/SearchCountText";

// スタイル設定
const useStyles = makeStyles(() => ({
  table: {
    width: "auto",
  },
  cell: {
    fontFamily: FONT,
    padding: 1,
    color: "#000!important",
    border: `1px solid ${ADMIN_COLOR.primary}`,
    width: 200,
    fontSize: "0.9em",
    height: 38,
  },
  thCell: {
    fontFamily: FONT,
    color: ADMIN_COLOR.primary,
    border: `1px solid ${ADMIN_COLOR.primary}!important`,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: ADMIN_COLOR.base,
    width: 400,
    position: "relative",
    fontSize: "0.9em",
    height: 38,
  },
  edit: {
    width: "100px!important",
  },
  icon: {
    position: "absolute",
    top: 7,
    left: 15,
    fontSize: "1.3rem",
  },
  messageHeader: {
    fontFamily: FONT,
    textAlign: "center",
    fontSize: "1.1rem",
    marginTop: "40px",
    marginBottom: "10px",
  },
  editButton: {
    width: 100,
    padding: "4px 10px",
    margin: "10px 20px",
    backgroundColor: ADMIN_COLOR.primary,
    color: ADMIN_COLOR.base,
    borderRadius: 0,
  },
  form: {
    marginTop: "5px!important",
  },
  grayOut: {
    backgroundColor: "gray",
  },
}));

// 契約者リスト表示配列
type ContractorListDisplayData = {
  id: number; // マスター契約者ID
  companyName: string; // 会社名
  corporateNum: string; // 法人番号
  customerCode: string; // 加盟店コード
  customerName: string; // 加盟店名
  customerPhoneNum: string; // 加盟店電話番号
  operatingStatus: string; // 契約者稼働状況
};

// 契約者リストのインターフェース
interface Props {
  resultsRows: IContractorSearchResponse;
  conditionState: ContractorSearchCondition;
  getContractors: (currentPage: string) => void;
}

// 契約者リスト表示
const ContractorSearchResult: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const formClasses = formStyles({
    color: ADMIN_COLOR.base,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const buttonClasses = buttonStyles({
    width: 213,
    marginTop: 15,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const paginationClasses = paginationStyles({
    color: ADMIN_COLOR.base,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const { resultsRows, conditionState, getContractors } = props;

  // 表示用カラム名
  const columnNames = [
    "会社名",
    "法人番号",
    "加盟店コード",
    "加盟店名",
    "加盟店電話番号",
  ] as const;

  // 表示用データ作成
  const displayDataList: ContractorListDisplayData[] = [];
  if (resultsRows.result) {
    resultsRows.result.forEach((resultData) => {
      // 表示用データ作成
      const displayData = {
        id: resultData.id,
        companyName: resultData.companyName,
        corporateNum: resultData.corporateNum,
        operatingStatus: resultData.operatingStatus,
      } as ContractorListDisplayData;
      // 加盟店があれば表示データ作成する
      if (resultData.masterCustomers.length > 0) {
        // 加盟店が他に何軒あるか
        const customerCount = resultData.masterCustomers.length - 1;
        displayData.customerCode =
          customerCount === 0
            ? resultData.masterCustomers[0].customerCode
            : `${resultData.masterCustomers[0].customerCode}(他${customerCount}件)`;
        displayData.customerName =
          customerCount === 0
            ? resultData.masterCustomers[0].customerName
            : `${resultData.masterCustomers[0].customerName}(他${customerCount}件)`;
        displayData.customerPhoneNum =
          customerCount === 0
            ? resultData.masterCustomers[0].customerPhoneNum
            : `${resultData.masterCustomers[0].customerPhoneNum}(他${customerCount}件)`;
      }
      displayDataList.push(displayData);
    });
  }

  return (
    <>
      {resultsRows.result !== undefined && resultsRows.totalCount === 0 && (
        <>
          <div className={classes.messageHeader}>
            {` ${"契約者が登録されていません。"}`}
          </div>
        </>
      )}
      {resultsRows.result !== undefined && resultsRows.totalCount !== 0 && (
        <>
          {/* 検索結果件数 */}
          <SearchCountText count={resultsRows.totalCount as number} />
          {/* 検索結果 */}
          <FormControl className={clsx(formClasses.form, classes.form)}>
            <TableContainer>
              <Table
                className={clsx(formClasses.table, classes.table)}
                aria-label="simple table"
              >
                <TableHead className={formClasses.th}>
                  <TableRow>
                    {/* タイトル */}
                    {columnNames.map((column) => (
                      <TableCell
                        key={column}
                        align="center"
                        className={classes.thCell}
                      >
                        {column}
                      </TableCell>
                    ))}
                    <TableCell
                      align="center"
                      className={clsx(classes.thCell, classes.edit)}
                    >
                      操作
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* 検索結果のリスト(解約済ステータスはグレーアウトする) */}
                  {displayDataList.map((rowData) => (
                    <TableRow
                      key={rowData.id}
                      className={
                        rowData.operatingStatus ===
                        CONTRACTOR_OPERATING_STATUS.TERMINATED
                          ? classes.grayOut
                          : ""
                      }
                    >
                      {/* 会社名 */}
                      <TableCell align="center" className={classes.cell}>
                        {rowData.companyName || "-"}
                      </TableCell>
                      {/* 法人番号 */}
                      <TableCell align="center" className={classes.cell}>
                        {rowData.corporateNum || "-"}
                      </TableCell>
                      {/* 加盟店コード */}
                      <TableCell align="center" className={classes.cell}>
                        {rowData.customerCode || "-"}
                      </TableCell>
                      {/* 加盟店名 */}
                      <TableCell align="center" className={classes.cell}>
                        {rowData.customerName || "-"}
                      </TableCell>
                      {/* 加盟店電話番号 */}
                      <TableCell align="center" className={classes.cell}>
                        {rowData.customerPhoneNum || "-"}
                      </TableCell>
                      {/* 詳細ボタン */}
                      <TableCell
                        align="center"
                        className={clsx(classes.cell, classes.edit)}
                      >
                        <Button
                          className={clsx(
                            buttonClasses.button,
                            classes.editButton
                          )}
                          variant="contained"
                          component={Link}
                          to={{
                            pathname: `/dealer/contractor/${rowData.id}`,
                            state: conditionState,
                          }}
                        >
                          詳細
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* ページネーション */}
            <div className={paginationClasses.parent}>
              <Pagination
                count={Math.ceil(
                  (resultsRows.totalCount as number) / SEARCH_PER_PAGE
                )}
                className={clsx(
                  paginationClasses.pagination,
                  paginationClasses.pageCurrent
                )}
                onChange={(e: React.ChangeEvent<unknown>, num) =>
                  getContractors(String(num))
                }
                page={Number(conditionState.pageNumber)}
              />
            </div>
          </FormControl>
        </>
      )}
    </>
  );
};

export default React.memo(ContractorSearchResult);
