/**
 * 共通-契約者、加盟店、端末の各詳細画面でデータ表示部分の上部項目表示をする
 * メモ：現状は上記３種類の詳細画面で共通の内容
 */
import React from "react";

import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
  makeStyles,
} from "@material-ui/core";

import { FONT, ThemeColorProps } from "../../../../constants/common";

const useStyles = makeStyles(() => ({
  box: (props: StyleSetting) => ({
    backgroundColor: props.backgroundColor,
    padding: "0 40px",
    overflowY: "auto",
    border: `3px solid ${props.backgroundColor}`,
  }),
  div: {
    maxWidth: 1131, // スクロールバーの分表内容と位置がずれるのでここで固定
  },
  th: (props: StyleSetting) => ({
    fontFamily: FONT,
    fontWeight: 900,
    backgroundColor: props.backgroundColor,
    color: props.color,
    width: 259,
    position: "relative",
    border: "none",
    padding: "0 16px",
  }),
  td: (props: StyleSetting) => ({
    fontFamily: FONT,
    fontWeight: 900,
    backgroundColor: props.backgroundColor,
    color: props.color,
    width: 200,
    border: "none",
    padding: "0 16px",
  }),
}));

type StyleSetting = {
  backgroundColor: string;
  color: string;
};

interface Props {
  dateReflected: string; // 反映日
  dateToBeReflected: string; // 反映予定日
  colors: ThemeColorProps; // 画面の色（JTB色か加盟店色を指定）
}

const ShowItemHeading: React.FC<Props> = (props: Props) => {
  const { dateReflected, dateToBeReflected, colors } = props;

  const classes = useStyles({
    color: colors.base,
    backgroundColor: colors.primary,
  });

  return (
    <Box className={classes.box}>
      <div className={classes.div}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align="left" className={classes.th}>
                  項目
                </TableCell>
                <TableCell align="left" className={classes.td}>
                  申込内容
                </TableCell>
                <TableCell align="left" className={classes.td}>
                  JTB承認済内容
                </TableCell>
                <TableCell align="left" className={classes.td}>
                  センター反映済内容
                  <br />({dateReflected || "-"})
                </TableCell>
                <TableCell align="left" className={classes.td}>
                  センター反映済予定内容
                  <br />({dateToBeReflected || "-"})
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Box>
  );
};

export default ShowItemHeading;
