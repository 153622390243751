/**
 * 共通-サイドバー
 */
import React from "react";
import { Route, Switch } from "react-router-dom";

import { makeStyles, Divider, Box } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";

import { AuthContext } from "../../cognito/AuthContext";
import { getUserDisplayName } from "../../utils/auth";

import logo from "../assets/img/brand/logo.png";
import AdminList from "./AdminList";
import GuestList from "./GuestList";

const useStyles = makeStyles(() => ({
  logo: {
    textAlign: "center",
    margin: "15px 10px 10px 10px",
  },
  loginNameBox: {
    margin: "10px 0",
  },
  loginName: {
    margin: "5px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loginNameText: {
    maxWidth: 204,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const MainListItems: React.FC = () => {
  const classes = useStyles();
  const { userAttributes } = React.useContext(AuthContext);

  return (
    <div>
      {/* ロゴ */}
      <div className={classes.logo}>
        <img src={logo} width="100" height="auto" alt="JTB" />
      </div>
      {userAttributes && (
        <Box className={classes.loginNameBox}>
          {/* ユーザー名 */}
          <Box className={classes.loginName}>
            <PersonIcon />
            <Box className={classes.loginNameText}>
              {getUserDisplayName() || "−"}
            </Box>
          </Box>
          {/* ユーザーID */}
          <Box className={classes.loginName}>
            ID: {userAttributes["cognito:username"]}
          </Box>
        </Box>
      )}
      <Divider />
      {/* サイドメニュー */}
      <Switch>
        <Route path="/guest" component={GuestList} />
        <Route path="/" component={AdminList} />
      </Switch>
    </div>
  );
};

export default MainListItems;
