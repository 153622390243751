// エラー処理関連のユーティリティ

// WASのエラー調査用ログ集積
// また調査の必要があった時用にコメントアウト
/*
import moment from "moment";
let logs: { date: string; log: string }[] = [];

export const log: (message: string) => void = (message: string) => {
  if (logs.length <= 0) {
    const d = localStorage.getItem("WAS_LOG") || "[]";
    logs = JSON.parse(d);
  }
  const now = moment();
  const date = now.format("YYYY-MM-DD HH:mm:ss");
  logs.push({ date, log: `${date}| ${message}` });
  // 2時間以内のログだけ保存
  localStorage.setItem(
    "WAS_LOG",
    JSON.stringify(
      logs.filter((l) => now.diff(moment(l.date)) <= 1000 * 60 * 60 * 2)
    )
  );
};

export const exportLog: () => void = () => {
  const d = localStorage.getItem("WAS_LOG") || "[]";
  const l: { date: string; log: string }[] = JSON.parse(d);
  // eslint-disable-next-line
  l.forEach((lo) => console.error(lo.log));
};
*/

const SHOW_MESSAGE_STATUS = [400, 470, 503];

// エラーメッセージを取得する
// SHOW_MESSAGE_STATUSに設定したステータスコードのみresponseメッセージを取り出す。
// レスポンスはunknownで明確に型を定義するものでないため。
// eslint-disable-next-line
export const getErrorMessage = (error: any) =>
  error.response && SHOW_MESSAGE_STATUS.includes(error.response.status)
    ? error.response.data.message
    : "エラーが発生しました。しばらくしてから再度お試しください。";

export default getErrorMessage;
