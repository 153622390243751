/**
 * JTB側-ユーザー名変更画面
 * メモ：自身のユーザー名を変更する画面
 */
import React, { useContext, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import clsx from "clsx";
import {
  Button,
  Container,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import { buttonStyles } from "../../../../common/components/styles";
import useContainerStyles from "../../../../templates/styles";

import {
  ADMIN_COLOR,
  FONT,
  DISPLAY_NAME_MAX_LENGTH,
} from "../../../../../constants/common";

import cognitoUser from "../../../../../api/cognitoUser";
import { GlobalPopupContext } from "../../../../../App";
import { AuthContext } from "../../../../../cognito/AuthContext";
import { compareSJISWhiteList } from "../../../../../form/validation";
import { getUserDisplayName } from "../../../../../utils/auth";
import getErrorMessage from "../../../../../utils/error";

import CustomTextField from "../../../../common/components/atoms/CustomTextField";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import Loading from "../../../../common/components/molecules/Loading";
import Dashboard from "../../../../templates/Dashboard";
import AlertMessage from "../../../../common/components/atoms/AuthAlertMsg";

const breadCrumbs = [
  { name: "設定", link: "/dealer/setting" },
  { name: "ユーザー名変更", link: "" },
];

const useStyles = makeStyles(() => ({
  table: {
    width: 800,
    minWidth: 800,
  },
  td: {
    padding: 1,
    backgroundColor: ADMIN_COLOR.base,
    color: "#000!important",
    borderTop: "1px solid #d2d5d8",
    borderBottom: "1px solid #d2d5d8",
    borderRight: "1px solid #d2d5d8",
    width: 400,
  },
  th: {
    padding: 0,
    fontFamily: FONT,
    backgroundColor: ADMIN_COLOR.base,
    color: "#000!important",
    width: 200,
    border: "1px solid #d2d5d8",
    position: "relative",
    paddingLeft: 20,
  },
  text: {
    padding: "14px",
    font: "inherit",
  },
  editButton: {
    margin: "0 auto",
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 10,
    paddingLeft: 68,
  },
  editIcon: {
    position: "absolute",
    top: 8,
    left: 30,
  },
  form: {
    marginTop: 20,
    marginBottom: 20,
  },
  lengthText: {
    color: "#777",
    fontFamily: FONT,
    fontSize: "0.7rem",
    fontWeight: 400,
    paddingLeft: 7,
  },
}));

const SettingDisplayNameEditPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const containerClasses = useContainerStyles();
  const buttonClasses = buttonStyles({
    width: 180,
    marginTop: 20,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const classes = useStyles();

  const { history } = props;

  const { authHeader, user, reloadCurrentSession } = useContext(AuthContext);
  const { setConfirmModal, setSnackbar } = useContext(GlobalPopupContext);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [updateError, setUpdateError] = useState("");

  const [newName, setNewName] = useState("");

  const requestUpdate = async () => {
    if (!user) return;
    setLoadingOpen(true);

    await cognitoUser
      .updateDisplayName(
        authHeader as string,
        newName,
        user.getSignInUserSession()?.getAccessToken().getJwtToken() as string
      )
      .then(async (res) => {
        // cognitoのSession情報を最新に
        // 更新された情報を取得するため少し遅らせる（500ms）
        await setTimeout(() => reloadCurrentSession(), 500);

        setLoadingOpen(false);
        if (res.data.result) {
          // 成功した場合、設定画面に遷移
          history.push(`/dealer/setting`);
          setSnackbar({
            openProps: true,
            message: "ユーザー名の変更が完了しました。",
            severity: "info",
          });
        }
      })
      .catch((error) => {
        setUpdateError(getErrorMessage(error));
        setLoadingOpen(false);
      })
      .finally(() => {
        setConfirmModal({
          open: false,
          modalTitle: "",
          message: ``,
          colors: ADMIN_COLOR,
          actionMethod: () => {
            /* 何もしない */
          },
        });
      });
  };

  const openConfirm = () => {
    // 必須チェック
    if (!newName) {
      setUpdateError("新しいユーザー名を入力してください");
      return;
    }

    // 桁数チェック
    if (newName.length > DISPLAY_NAME_MAX_LENGTH) {
      setUpdateError(`ユーザー名は最大${DISPLAY_NAME_MAX_LENGTH}文字です。`);
      return;
    }

    // 使用可能文字チェック
    const errChar = compareSJISWhiteList([newName]);
    if (errChar) {
      setUpdateError(
        `当システムで使用できない文字が含まれています。（${errChar}）`
      );
      return;
    }

    setConfirmModal({
      open: true,
      modalTitle: "確認",
      message: `ユーザー名を「${
        getUserDisplayName() || "-"
      }」 から 「${newName}」 に変更しますか？`,
      colors: ADMIN_COLOR,
      actionMethod: requestUpdate,
    });
  };

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Dashboard
        title="設定"
        color={ADMIN_COLOR.base}
        backgroundColor={ADMIN_COLOR.primary}
        iconName="setting"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {/* エラーメッセージ */}
          {updateError && <AlertMessage errorMessage={updateError} />}
          {/* 入力フォーム */}
          <form
            className={classes.form}
            onSubmit={(e) => {
              e.preventDefault();
              openConfirm();
            }}
          >
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableBody>
                  {/* ユーザー名 */}
                  <TableRow key="ユーザー名">
                    <TableCell align="left" className={classes.th}>
                      ユーザー名
                    </TableCell>
                    <TableCell
                      align="left"
                      className={clsx(classes.td, classes.text)}
                    >
                      {getUserDisplayName()}
                    </TableCell>
                  </TableRow>
                  {/* 新しいユーザー名 */}
                  <TableRow key="新しいユーザー名">
                    <TableCell align="left" className={classes.th}>
                      新しいユーザー名
                      <span
                        className={classes.lengthText}
                      >{`(最大${DISPLAY_NAME_MAX_LENGTH}文字)`}</span>
                    </TableCell>
                    <TableCell align="left" className={classes.td}>
                      <CustomTextField
                        type="text"
                        value={newName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setNewName(e.target.value)
                        }
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {/* 変更するボタン */}
            <Button
              type="button"
              onClick={openConfirm}
              className={clsx(buttonClasses.button, classes.editButton)}
              variant="contained"
              disabled={loadingOpen} // 二重送信対策
            >
              <EditIcon className={classes.editIcon} />
              変更する
            </Button>
          </form>
        </Container>
      </Dashboard>
    </>
  );
};

export default withRouter(SettingDisplayNameEditPage);
