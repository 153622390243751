import { TFormRowData } from "../../views/common/components/types";
import { TFixedNumberProcessResult } from "./types";

/**
 * 旧端末識別番号による表示制御
 * @param data: TFormRowData（端末情報フォーム）
 * @returns TFixedNumberProcessResult
 */
export const displayFormByOldTerminal: (
  data: TFormRowData
) => TFixedNumberProcessResult = (data: TFormRowData) => {
  const oldTerminal = data.oldTID[0];

  if (!oldTerminal) return [];

  return [
    {
      column: "terminationOfOldReplacementTerminal",
      content: "",
      isHide: !oldTerminal.content,
    },
  ];
};

export default displayFormByOldTerminal;
