/**
 * JTB側-登録申請情報入力完了画面
 */
import React, { useEffect, useMemo, useState } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import MessageIcon from "@material-ui/icons/Message";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";

import { useContainerStyles } from "../../../../templates/styles";
import {
  buttonStyles,
  completeStyles,
} from "../../../../common/components/styles";

import { ADMIN_COLOR } from "../../../../../constants/common";

import formStore from "../../../../../store/formStore";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import DealUserMessageModal from "../organisms/DealUserMessageModal";

const useStyles = makeStyles(() => ({
  Icon: {
    position: "absolute",
    top: 8,
    left: 20,
  },
  buttonBlock: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  messageButton: {
    width: 250,
  },
  returnButton: {
    width: 250,
    marginLeft: 20,
    "& .MuiButton-label": {
      marginLeft: 34,
    },
  },
}));

const DealNewCompletePage: React.FC<RouteComponentProps<{ id: string }>> = (
  props: RouteComponentProps<{ id: string }>
) => {
  const classes = useStyles();
  const completeClasses = completeStyles();
  const containerClasses = useContainerStyles();
  const buttonClasses = buttonStyles({
    width: "100%",
    marginTop: 0,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const { match } = props;
  const [userMessageOpen, setUserMessageOpen] = useState(false);

  const dealId = useMemo(() => match.params.id, [match]);

  const dealType = useMemo(() => formStore.getDealType() || "端末新規", []);
  const returnPage = useMemo(() => {
    switch (dealType) {
      case "端末新規":
        return { url: "/dealer/contractor", name: "契約者管理・検索" };
      case "端末増設":
        return { url: "/dealer/customer", name: "加盟店管理・検索" };
      default:
        return { url: "/dealer", name: "TOP" };
    }
  }, [dealType]);
  // 事前にどのJSONを使用するか選択する形になったので、登録画面にパンクズに遷移させない。
  const breadCrumbs = useMemo(
    () => [
      { name: returnPage.name, link: returnPage.url },
      { name: "完了", link: "" },
    ],
    [returnPage]
  );

  useEffect(() => {
    // 下書き削除
    formStore.deleteDraft(`deal-${formStore.getContractorId()}`);
    // 申請種別リセット
    formStore.setDealType(null);
  }, []);

  return (
    <Dashboard
      title={`${dealType}申請`}
      color={ADMIN_COLOR.base}
      backgroundColor={ADMIN_COLOR.primary}
      iconName="deal"
    >
      {userMessageOpen && dealId && (
        <DealUserMessageModal
          dealId={dealId ? parseInt(dealId.toString(), 10) : 0}
          setOpen={setUserMessageOpen}
          dealType={dealType}
        />
      )}
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {/* 文言 */}
        <div className={completeClasses.message}>
          登録が完了しました。
          <br />
          <br />
          （申請番号：{dealId}）
        </div>
        <div className={classes.buttonBlock}>
          {/* メッセージ送信ボタン */}
          <Button
            onClick={() => {
              setUserMessageOpen(true);
            }}
            className={clsx(buttonClasses.button, classes.messageButton)}
            variant="contained"
          >
            <MessageIcon className={classes.Icon} />
            メッセージを送信する
          </Button>
          {/* ダッシュボードに戻るボタン */}
          <Button
            type="button"
            component={Link}
            to="/dealer"
            className={clsx(buttonClasses.button, classes.returnButton)}
            variant="contained"
          >
            <KeyboardReturnIcon className={classes.Icon} />
            ダッシュボードに戻る
          </Button>
        </div>
      </Container>
    </Dashboard>
  );
};

export default withRouter(DealNewCompletePage);
