import { FORM_CATEGORY } from "../../constants/form";
import { isJtbUser } from "../../utils/auth";
import {
  formTypeOptions,
  TFormColumn,
  TFormData,
  TFormRowData,
} from "../../views/common/components/types";
import { TFixedNumberProcessResult } from "./types";

/**
 * 加盟店分類マスターJson
 */
type TCustomerGroupMasterJson = {
  menb_typ_cd: number;
  menb_typ_grp: string;
}[];

/**
 * 一括事業者管理マスターJson
 */
type TBusinessCodeMasterJson = {
  jigyo_cd: string;
  jigyo_name: string;
  menb_typ_grp: string;
}[];

/**
 * 対加盟店クレジット手数料管理マスターJson
 */
type TRatePatternMasterJson = {
  [key: string]: string | number | undefined;
  memb_cre_charge_ptn: string;
  memb_cre_charge_name: string;
  menb_typ_cd: number;
}[];

/**
 * 決済機能利用パターンマスターJson
 */
export type TSettlementPatternMasterJson = {
  settlement_ptn_cd: string;
  settlement_ptn_name: string;
  menb_typ_grp: string;
  brand_transportation_emoney: number;
  brand_paypay: number;
}[];

/**
 * 加盟店分類-加盟店分類グループマッピング
 */
const CUSTOMER_GROUP_MAP = new Map<string, string>();

/**
 * 加盟店分類-事業コードマッピング
 */
const BUSINESS_CODE_MAP = new Map<string, formTypeOptions[]>();

/**
 * 加盟店種別-手数料パターンマッピング
 */
const RATE_PATTERN_MAP = new Map<string, formTypeOptions[]>();

/**
 * 加盟店分類-決済機能パターンマッピング
 */
const SETTLEMENT_PATTERN_MAP = new Map<string, formTypeOptions[]>();

/**
 * 決済機能パターン-PayPayフラグマッピング
 */
const SETTLEMENT_PAYPAY_MAP = new Map<string, boolean>();

/**
 * 加盟店分類マッピング作成
 * @param json: 加盟店分類マスタJson
 */
export const setCustomerGroupMap = (json: TCustomerGroupMasterJson) => {
  json.forEach((j) => {
    CUSTOMER_GROUP_MAP.set(j.menb_typ_cd.toString(), j.menb_typ_grp);
  });
};

/**
 * 事業コードマッピング作成
 * @param json: 一括事業者管理マスターJson
 */
export const setBusinessCodeMap = (json: TBusinessCodeMasterJson) => {
  // 事業コードマッピング初期化
  BUSINESS_CODE_MAP.clear();
  json.forEach((j) => {
    const options = BUSINESS_CODE_MAP.get(j.menb_typ_grp);
    const opt: formTypeOptions = {};
    opt[j.jigyo_cd] = j.jigyo_name;
    if (options) {
      options.push(opt);
    } else {
      BUSINESS_CODE_MAP.set(j.menb_typ_grp, [opt]);
    }
  });
};

/**
 * 手数料パターンマッピング作成
 * @param json: 対加盟店クレジット手数料管理マスターJson
 */
export const setRatePatternMap = (json: TRatePatternMasterJson) => {
  // 手数料パターンマップ初期化
  RATE_PATTERN_MAP.clear();
  // 必要データ部分が同じデータが大量に重複しているので重複排除
  const uniqueArr: TRatePatternMasterJson = Array.from(
    new Set(
      json.map((j) => {
        const r = j;
        const keys = Object.keys(r);
        // 必要データ以外はundefinedを入れる
        keys.forEach((k) => {
          if (
            k !== "memb_cre_charge_ptn" &&
            k !== "memb_cre_charge_name" &&
            k !== "menb_typ_cd"
          ) {
            r[k] = undefined;
          }
        });
        return JSON.stringify(j);
      })
    )
  ).map((a) => JSON.parse(a));

  uniqueArr.forEach((row) => {
    const options = RATE_PATTERN_MAP.get(row.menb_typ_cd.toString());
    const opt: formTypeOptions = {};
    opt[row.memb_cre_charge_ptn] = row.memb_cre_charge_name;
    if (options) {
      options.push(opt);
    } else {
      RATE_PATTERN_MAP.set(row.menb_typ_cd.toString(), [opt]);
    }
  });
};

/**
 * 決済機能パターンマッピング作成
 * @param json: 決済機能利用パターンマスターJson
 */
export const setSettlementPatternMap = (json: TSettlementPatternMasterJson) => {
  // 決済機能パターンマッピング初期化
  SETTLEMENT_PATTERN_MAP.clear();
  json.forEach((j) => {
    const options = SETTLEMENT_PATTERN_MAP.get(j.menb_typ_grp);
    const opt: formTypeOptions = {};
    opt[j.settlement_ptn_cd] = j.settlement_ptn_name;
    if (options) {
      options.push(opt);
    } else {
      SETTLEMENT_PATTERN_MAP.set(j.menb_typ_grp, [opt]);
    }

    SETTLEMENT_PAYPAY_MAP.set(j.settlement_ptn_cd, !!Number(j.brand_paypay));
  });
};

/**
 * 加盟店分類による選択肢制御
 * @param data: TFormRowData 加盟店情報フォーム
 * @returns TFixedNumberProcessResult
 */
export const autoCalcByCustomerGroup: (
  data: TFormRowData
) => TFixedNumberProcessResult = (data: TFormRowData) => {
  const customerType = data.customerType[0];
  const customerTypeStr = customerType.content
    ? Object.keys(JSON.parse(customerType.content))[0]
    : "";

  if (!customerTypeStr) {
    return [
      { column: "businessCode", content: "" },
      { column: "ratePattern", content: "" },
      { column: "settlementPtn", content: "" },
    ];
  }

  const customerGroup = CUSTOMER_GROUP_MAP.get(customerTypeStr) || "";

  return [
    {
      column: "businessCode",
      content: "",
      formTypeOptions: BUSINESS_CODE_MAP.get(customerGroup) || [],
    },
    {
      column: "ratePattern",
      content: "",
      formTypeOptions: RATE_PATTERN_MAP.get(customerTypeStr) || [],
    },
    {
      column: "settlementPtn",
      content: "",
      formTypeOptions: SETTLEMENT_PATTERN_MAP.get(customerGroup) || [],
    },
  ];
};

/**
 * 決済機能利用パターンによる表示制御
 * @param data: TFormRowData 加盟店情報フォーム
 * @returns TFixedNumberProcessResult
 */
export const displayFormBySettlementPattern: (
  data: TFormData
) => TFixedNumberProcessResult = (data: TFormData) => {
  const settlementPattern =
    data[FORM_CATEGORY.CUSTOMER][0].form.settlementPtn[0];
  const settlementPatternCode = settlementPattern.content
    ? Object.keys(JSON.parse(settlementPattern.content))[0]
    : "";

  const customerForm = data[FORM_CATEGORY.CUSTOMER];

  const ret: TFixedNumberProcessResult = [];

  if (!customerForm) return ret;

  const customerData = customerForm[0].form;

  const customerType = customerData.customerType[0];
  const customerTypeStr = customerType.content
    ? Object.keys(JSON.parse(customerType.content))[0]
    : "";

  const isDisplayTravelAgencyRegistration =
    customerTypeStr === "4" ||
    customerTypeStr === "5" ||
    customerTypeStr === "6";

  const paypayFlag = SETTLEMENT_PAYPAY_MAP.get(settlementPatternCode) || false;

  return [
    {
      column: "travelAgencyRegistration",
      content: "",
      isHide: !paypayFlag || !isDisplayTravelAgencyRegistration,
    },
    {
      column: "customerUrl",
      content: "",
      isHide: !paypayFlag,
    },
    {
      column: "storeConfirmation1",
      content: "",
      isHide: !paypayFlag,
    },
    {
      column: "storeConfirmation2",
      content: "",
      isHide: !paypayFlag,
    },
    {
      column: "storeConfirmation3",
      content: "",
      isHide: !paypayFlag,
    },
  ];
};

/**
 * 加盟店分類による通帳コピー、営業許可証、本人確認書類の表示制御
 * @param data TFormData
 * @returns TFixedNumberProcessResult
 */
export const displayFormByCustomerType: (
  data: TFormData
) => TFixedNumberProcessResult = (data: TFormData) => {
  const customerForm = data[FORM_CATEGORY.CUSTOMER];

  const ret: TFixedNumberProcessResult = [];

  if (!customerForm) return ret;

  const customerData = customerForm[0].form;

  const customerType = customerData.customerType[0];
  const customerTypeStr = customerType.content
    ? Object.keys(JSON.parse(customerType.content))[0]
    : "";

  const isDisplayBankBookCopy =
    customerTypeStr === "2" ||
    customerTypeStr === "3" ||
    customerTypeStr === "4" ||
    customerTypeStr === "5" ||
    customerTypeStr === "6" ||
    customerTypeStr === "7";

  const isDisplayBusinessLicense =
    customerTypeStr === "1" ||
    customerTypeStr === "2" ||
    customerTypeStr === "3";

  ret.push({
    column: "bankBookCopy",
    content: "",
    isHide: !isDisplayBankBookCopy,
  });
  ret.push({
    column: "businessLicense",
    content: "",
    isHide: !isDisplayBusinessLicense,
  });

  const isDisplayBankCodeAndMore =
    customerTypeStr === "2" ||
    customerTypeStr === "3" ||
    customerTypeStr === "4" ||
    customerTypeStr === "5" ||
    customerTypeStr === "6" ||
    customerTypeStr === "7";

  ret.push({
    column: "bankCode",
    content: "",
    isHide: !isDisplayBankCodeAndMore,
  });
  ret.push({
    column: "bankName",
    content: "",
    isHide: !isDisplayBankCodeAndMore,
  });
  ret.push({
    column: "bankNameK",
    content: "",
    isHide: !isDisplayBankCodeAndMore,
  });
  ret.push({
    column: "branchCode",
    content: "",
    isHide: !isDisplayBankCodeAndMore,
  });
  ret.push({
    column: "branchName",
    content: "",
    isHide: !isDisplayBankCodeAndMore,
  });
  ret.push({
    column: "branchNameK",
    content: "",
    isHide: !isDisplayBankCodeAndMore,
  });
  ret.push({
    column: "accountType",
    content: "",
    isHide: !isDisplayBankCodeAndMore,
  });
  ret.push({
    column: "accountNum",
    content: "",
    isHide: !isDisplayBankCodeAndMore,
  });
  ret.push({
    column: "accountName",
    content: "",
    isHide: !isDisplayBankCodeAndMore,
  });
  ret.push({
    column: "accountNameK",
    content: "",
    isHide: !isDisplayBankCodeAndMore,
  });

  // 本人確認書類の表示制御は申込区分のみでやることになったので削除

  return ret;
};

export const displayCheckSheetByCustomerType: (
  data: TFormData
) => TFixedNumberProcessResult = (data: TFormData) => {
  const customerForm = data[FORM_CATEGORY.CUSTOMER];
  const checkSheet = data[FORM_CATEGORY.CHECK_SHEET];

  const ret: TFixedNumberProcessResult = [];

  if (!customerForm || !checkSheet) return ret;

  const customerData = customerForm[0].form;
  const checkSheetData = checkSheet[0].form;

  const customerType = customerData.customerType[0];
  const customerTypeStr = customerType.content
    ? Object.keys(JSON.parse(customerType.content))[0]
    : "";

  const keys = Object.keys(checkSheetData);

  // JTB支店は不要
  if (!customerTypeStr || customerTypeStr === "4") {
    return ret.concat(
      keys.map((k) => {
        return {
          column: k as TFormColumn,
          content: "",
          isHide: true,
          isRequired: false,
        };
      })
    );
  }

  // 1：JTB契約旅館・ホテル、2：るるぶトラベル契約、3：全旅連加盟宿泊施設 は100-109
  if (
    customerTypeStr &&
    (customerTypeStr === "1" ||
      customerTypeStr === "2" ||
      customerTypeStr === "3")
  ) {
    return ret.concat(
      keys.map((k) => {
        const row = checkSheetData[k as TFormColumn][0];
        const hide =
          row.json.formGroupNameId < 100 || row.json.formGroupNameId > 109;

        return {
          column: k as TFormColumn,
          content: "",
          isHide: hide,
          isRequired: !hide && !isJtbUser(),
        };
      })
    );
  }

  // 7：ダイヤスタンプ契約店 は120-129
  if (customerTypeStr && customerTypeStr === "7") {
    return ret.concat(
      keys.map((k) => {
        const row = checkSheetData[k as TFormColumn][0];
        const hide =
          row.json.formGroupNameId < 120 || row.json.formGroupNameId > 129;

        return {
          column: k as TFormColumn,
          content: "",
          isHide: hide,
          isRequired: !hide && !isJtbUser(),
        };
      })
    );
  }

  // 5：提携販売店（総合提携店）、6：提携販売店（パートナー） は140-148
  if (customerTypeStr && (customerTypeStr === "5" || customerTypeStr === "6")) {
    return ret.concat(
      keys.map((k) => {
        const row = checkSheetData[k as TFormColumn][0];
        const hide =
          row.json.formGroupNameId < 140 || row.json.formGroupNameId > 148;

        return {
          column: k as TFormColumn,
          content: "",
          isHide: hide,
          isRequired: !hide && !isJtbUser(),
        };
      })
    );
  }

  return ret;
};
