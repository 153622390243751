/**
 * JTB側-電話番号変更フォーム
 * メモ：入力フォームを表示する
 */
import React from "react";

import clsx from "clsx";
import {
  FormControl,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";

import { formStyles } from "../../../../common/components/styles";

import { UserPhoneNumEditItemObject } from "../../types";

import { ADMIN_COLOR, FONT } from "../../../../../constants/common";

import { getUserPhoneNumber } from "../../../../../utils/auth";

import CustomTextField from "../../../../common/components/atoms/CustomTextField";

const useStyles = makeStyles(() => ({
  cell: {
    fontFamily: FONT,
  },
  th: {
    width: 300,
  },
  lengthText: {
    color: ADMIN_COLOR.base,
    fontFamily: FONT,
    fontSize: "0.7rem",
    fontWeight: 400,
    paddingLeft: 7,
  },
}));

interface Props {
  userPhoneNumEditState: UserPhoneNumEditItemObject;
  setUserPhoneNumEditState: React.Dispatch<
    React.SetStateAction<UserPhoneNumEditItemObject>
  >;
}

const SettingUserPhoneNumEditForm: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const formClasses = formStyles({
    color: ADMIN_COLOR.base,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const { userPhoneNumEditState, setUserPhoneNumEditState } = props;

  return (
    <FormControl className={formClasses.form}>
      <TableContainer component={Paper}>
        <Table className={formClasses.table} aria-label="simple table">
          <TableBody>
            {/* 現在の電話番号 */}
            <TableRow>
              <TableCell
                align="left"
                className={clsx(formClasses.th, classes.th)}
              >
                現在の電話番号
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                {getUserPhoneNumber()}
              </TableCell>
            </TableRow>
            {/* 新しい電話番号 */}
            <TableRow key="新しい電話番号">
              <TableCell
                align="left"
                className={clsx(formClasses.th, classes.th)}
              >
                新しい電話番号
              </TableCell>
              <TableCell align="left">
                <CustomTextField
                  type="text"
                  value={userPhoneNumEditState.newUserPhoneNum}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUserPhoneNumEditState({
                      ...userPhoneNumEditState,
                      newUserPhoneNum: e.target.value,
                    })
                  }
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </FormControl>
  );
};

export default SettingUserPhoneNumEditForm;
