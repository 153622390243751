/**
 * フォーム関係-詳細表示用のフォームでの項目の行
 */
import React, { useEffect, useRef, useState } from "react";

import {
  TableCell,
  TableRow,
  TableRowProps,
  makeStyles,
} from "@material-ui/core";

import { TFormColumn, TRequiredKey } from "../../../common/components/types";

import { FONT } from "../../../../constants/common";

import formStore from "../../../../store/formStore";
import useObserver from "../../../helpers/form/useObserver";

import FormTitle from "./FormTitle";
import ShowExternalDBValue from "./ShowExternalDBValue";
import ShowFormValue from "./ShowFormValue";

const useStyles = makeStyles(() => ({
  th: {
    fontFamily: FONT,
    fontWeight: 900,
    backgroundColor: "#fff",
    color: "#000!important",
    width: 263,
    borderBottom: "2px dotted #ddd",
    position: "relative",
  },
  td: {
    backgroundColor: "#fff",
    color: "#000!important",
    borderBottom: "2px dotted #ddd",
    width: 200,
  },
}));

type Props = TableRowProps & {
  columnName: TFormColumn;
  requiredKey: TRequiredKey;
  terminalGridId: number | undefined;
  gridId: number | undefined;
};

const ShowFormValueRow: React.FC<Props> = (props: Props) => {
  const { columnName, requiredKey, terminalGridId, gridId, className } = props;

  const [init, setInit] = useState(false);
  const [isHide, setHide] = useState(
    formStore.isHideRow(columnName, requiredKey)
  );
  const target = useRef<HTMLTableRowElement>(null);
  // 描画境界判定
  const intersect = useObserver(target, {
    root: document.getElementById("scroll-parent"), // TabPanel
    rootMargin: "300px 0px 300px 0px", // 上下300px広く判定
  });

  const classes = useStyles();

  useEffect(() => {
    const fn = () => {
      setHide(formStore.isHideRow(columnName, requiredKey));
    };
    formStore.addListener(columnName, fn);

    return () => formStore.removeListener(columnName, fn);
  }, [columnName, requiredKey]);

  useEffect(() => {
    // タブ切り替え時に"scroll-parent"がDOMについたあとuseObserverするため
    setInit(true);
  }, []);

  if (isHide) return null;

  return init ? (
    <TableRow
      className={className}
      key={columnName}
      innerRef={target}
      style={{
        height: intersect
          ? "auto"
          : "75px" /* 描画範囲外の場合は仮で高さを設定しておく */,
      }}
    >
      {/* 項目の名称 */}
      <TableCell align="left" className={classes.th}>
        <FormTitle columnName={columnName} requiredKey={requiredKey} />
      </TableCell>
      {/* 申込内容（項目の値） */}
      <TableCell align="left" className={classes.td}>
        {intersect ? (
          <ShowFormValue
            columnName={columnName}
            terminalGridId={terminalGridId}
            gridId={gridId}
          />
        ) : null}
      </TableCell>
      {/* JTB承認済内容（外部DBの値） */}
      <TableCell align="left" className={classes.td}>
        {intersect ? (
          <ShowExternalDBValue
            columnName={columnName}
            terminalGridId={terminalGridId}
            gridId={gridId}
            dbName="jtbApproved"
          />
        ) : null}
      </TableCell>
      {/* センター反映済内容（外部DBの値） */}
      <TableCell align="left" className={classes.td}>
        {intersect ? (
          <ShowExternalDBValue
            columnName={columnName}
            terminalGridId={terminalGridId}
            gridId={gridId}
            dbName="centerReflected"
          />
        ) : null}
      </TableCell>
      {/* センター反映予定内容（外部DBの値） */}
      <TableCell align="left" className={classes.td}>
        {intersect ? (
          <ShowExternalDBValue
            columnName={columnName}
            terminalGridId={terminalGridId}
            gridId={gridId}
            dbName="centerToBeReflected"
          />
        ) : null}
      </TableCell>
    </TableRow>
  ) : null;
};

export default ShowFormValueRow;
