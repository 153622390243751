import { useMemo, useState } from "react";
import { DealTypeInfo } from "../views/admin/change-deal/types";
import {
  TDealTypeStr,
  TFormColumn,
  TRequiredKey,
} from "../views/common/components/types";
import formStore from "../store/formStore";

/**
 * 申請種別によって異なる共通情報を取得
 */
export const useChangeDealInfoByDealType = (requiredKey: TRequiredKey) => {
  const [dealType] = useState<TDealTypeStr>(
    formStore.getDealType() || formStore.getEditDealType() || "契約者変更"
  );
  const contractorId = formStore.getContractorId();
  const customerId = formStore.getCustomerId();
  const masterTerminalId = formStore.getMasterTerminalId();
  const key = requiredKey === "dealer" ? "dealer" : "guest";
  const dealTypeInfo = useMemo((): DealTypeInfo => {
    switch (dealType) {
      case "契約者変更":
        return {
          url: `/${key}/contractor`,
          name: "契約者管理・検索",
          formCategory: 1,
          detailId: contractorId,
        };
      case "加盟店変更":
        return {
          url: `/${key}/customer`,
          name: "加盟店管理・検索",
          formCategory: 2,
          detailId: customerId,
        };
      case "端末変更":
        return {
          url: `/${key}/terminal`,
          name: "端末管理・検索",
          formCategory: 3,
          detailId: masterTerminalId,
        };
      default:
        return {
          url: `/${key}`,
          name: "TOP",
          formCategory: 1,
          detailId: null,
        };
    }
  }, [dealType, contractorId, customerId, masterTerminalId, key]);

  return { dealType, contractorId, customerId, masterTerminalId, dealTypeInfo };
};

/**
 * 変更詳細ページの申請情報設定
 * @param afterContents: 変更申請後情報（変更情報のみ）
 * @param allContents: 変更申請前情報（全情報）
 */
export const convertToChangeDealContents = (
  afterContents: Partial<{ [key in TFormColumn]: string }>,
  allContents: Partial<{ [key in TFormColumn]: string }>
) => {
  return Object.keys(afterContents).map((key) => {
    const formKey = key as TFormColumn;
    return {
      columnName: formKey,
      tabFormContent: afterContents[formKey] ?? "",
      dealFormContent: allContents[formKey] ?? "",
    };
  });
};

export default useChangeDealInfoByDealType;
