/**
 * 共通-お知らせ
 */
import React, { useEffect, useState } from "react";

import { Alert } from "@material-ui/lab";

import { IInformation } from "../../../../api/types";

import information from "../../../../api/information";

interface Props {
  delay: number;
}

const InformationAlert: React.FC<Props> = (props: Props) => {
  // お知らせの取得
  const [informationState, setInformationState] = useState<IInformation>({
    message: "",
  } as IInformation);
  // ダッシュボード画面をリロードした際のコンポーネント再描画のために必要。
  // ダッシュボード画面は、ブラウザリロード時にログイン画面を介するため、API多重リクエストとなってしまう。
  // そのため、ダッシュボード側はdelayを多めにとってsetTimeoutでAPIリクエストを遅らせる。
  const { delay } = props;

  // 初期表示でお知らせを取得
  useEffect(() => {
    const getInformation = async () => {
      await information
        .getInformation()
        .then((res) => {
          setInformationState(res.data.result);
        })
        .catch((error) => {
          // エラー発生時は、このコンポーネントでは特に何もしない。
          throw error;
        });
    };

    setTimeout(() => getInformation(), delay);
  }, [delay]);

  return (
    <>
      {/* エラー発生時、メッセージが無いため表示されない */}
      {informationState.message !== "" && (
        <Alert variant="outlined" severity="info">
          {informationState.message}
        </Alert>
      )}
    </>
  );
};

export default InformationAlert;
