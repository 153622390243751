/**
 * 加盟店側-帳票・データ出力
 * メモ：帳票・データ出力系画面の親コンポーネント
 */
import React from "react";
import { Route, Switch } from "react-router-dom";

import GuestDataPage from "./pages/GuestDataPage";
import GuestDataBillPage from "./pages/GuestDataBillPage";
import GuestDataBillShowPage from "./pages/GuestDataBillShowPage";

const GuestDataHooks: React.FC = () => {
  return (
    <Switch>
      {/* 精算明細書詳細画面 */}
      <Route
        exact
        path="/guest/data/bill/:masterCustomerId"
        render={() => <GuestDataBillShowPage />}
      />
      {/* 精算明細書検索・一覧画面 */}
      <Route
        exact
        path="/guest/data/bill"
        render={() => <GuestDataBillPage />}
      />
      {/* 帳票・データ出力画面 */}
      <Route exact path="/guest/data" render={() => <GuestDataPage />} />
    </Switch>
  );
};

export default GuestDataHooks;
