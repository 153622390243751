/* eslint @typescript-eslint/no-non-null-assertion: 0 */
// 設定値であって確実に入るので、undefinedではないため、eslintを除外。
const authconfig = {
  Auth: {
    region: process.env.REACT_APP_REGION!,
    userPoolId: process.env.REACT_APP_USER_POOL_ID!,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID!,
    authenticationFlowType: process.env.REACT_APP_AUTHENTICATION_FLOWTYPE!,
  },
};

export default authconfig;
