/**
 * 加盟店側-変更・解約申請詳細画面の各種操作ボタン一覧
 * メモ：変更・解約申請詳細画面の各種操作のボタンの一覧
 */
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import clsx from "clsx";
import { Grid, Button, makeStyles } from "@material-ui/core";
import MessageIcon from "@material-ui/icons/Message";

import { buttonStyles } from "../styles";

import { GUEST_COLOR } from "../../../../constants/common";

import DealUserMessageModal from "../../../guest/deal/components/organisms/DealUserMessageModal";

const useStyles = makeStyles(() => ({
  commonButton: {
    paddingTop: 11,
    paddingBottom: 11,
    textAlign: "center",
  },
  commonIcon: {
    position: "absolute",
    top: 11,
    left: 20,
  },
  buttonGridList: {
    margin: "0 auto",
  },
  buttonGrid: {
    width: "49%",
  },
}));

interface Props extends RouteComponentProps {
  dealId: number;
}

const ChangeOrTerminateDealShowButtonList: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const buttonClasses = buttonStyles({
    width: "100%",
    marginTop: 0,
    backgroundColor: GUEST_COLOR.primary,
  });

  const { dealId } = props;

  const [userMessageOpen, setUserMessageOpen] = useState(false);

  return (
    <>
      {userMessageOpen && (
        <DealUserMessageModal dealId={dealId} setOpen={setUserMessageOpen} />
      )}
      <Grid container spacing={2} className={classes.buttonGridList}>
        <Grid item xs={12}>
          <Grid container justify="flex-start" spacing={2}>
            {/* メッセージ送信 */}
            <Grid item className={classes.buttonGrid}>
              <Button
                onClick={() => {
                  setUserMessageOpen(true);
                }}
                className={clsx(buttonClasses.button, classes.commonButton)}
                variant="contained"
              >
                <MessageIcon className={classes.commonIcon} />
                メッセージを送信する
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(ChangeOrTerminateDealShowButtonList);
