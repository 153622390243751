/**
 * JTB側-契約者管理・検索画面
 * メモ：契約者管理画面を表示する
 */
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import clsx from "clsx";
import { Container, makeStyles, Button } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SearchIcon from "@material-ui/icons/Search";
import { Alert } from "@material-ui/lab";

import useContainerStyles from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { ContractorSearchCondition } from "../../types";
import { IContractorSearchResponse } from "../../../../../api/types";

import { ADMIN_COLOR } from "../../../../../constants/common";
import { USER_TYPE_LIST } from "../../../../../constants/user";

import { AuthContext } from "../../../../../cognito/AuthContext";
import { getUserType } from "../../../../../api/utils/authUtils";
import { getErrorMessage } from "../../../../../utils/error";
import contractors from "../../../../../api/contractors";

import Loading from "../../../../common/components/molecules/Loading";
import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import ContractorSearchConditionForm from "../organisms/ContractorSearchConditionForm";
import ContractorSearchResult from "../organisms/ContractorSearchResult";

const breadCrumbs = [{ name: "契約者管理・検索", link: "" }];

const useStyles = makeStyles(() => ({
  alert: {
    marginTop: "30px",
  },
  button: {
    marginLeft: 0,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 34,
    backgroundColor: `${ADMIN_COLOR.base}!important`,
    color: `${ADMIN_COLOR.primary}!important`,
    border: `1px solid ${ADMIN_COLOR.primary}`,
    borderRadius: 0,
    textAlign: "center",
    "&:hover": {
      backgroundColor: `${ADMIN_COLOR.btnHover}!important`,
    },
  },
  addButton: {
    marginBottom: 20,
  },
  icon: {
    position: "absolute",
    top: 7,
    left: 15,
    fontSize: "1.3rem",
  },
  form: {
    width: 800,
  },
  searchButton: {
    paddingBottom: 8,
    margin: "0 auto",
    marginTop: 20,
  },
  searchIcon: {
    position: "absolute",
    top: 8,
    left: 50,
  },
}));

const ContractorPage: React.FC = () => {
  const containerClasses = useContainerStyles();
  const classes = useStyles();
  const buttonClasses = buttonStyles({
    width: 213,
    marginTop: 15,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const searchButtonClasses = buttonStyles({
    width: 180,
    marginTop: 15,
    textLeft: 10,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const { state } = useLocation(); // 現在の検索条件を受け取る
  const { authHeader } = useContext(AuthContext);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [searchApiErrorMsg, setSearchApiErrorMsg] = useState<string>("");
  const [searchCondition, setSearchCondition] =
    useState<ContractorSearchCondition>({
      // 検索条件が渡ってきていれば設定する
      companyName: state && state.companyName ? state.companyName : "", // 会社名
      corporateNum: state && state.corporateNum ? state.corporateNum : "", // 法人番号
      customerCode: state && state.customerCode ? state.customerCode : "", // 加盟店コード
      customerName: state && state.customerName ? state.customerName : "", // 加盟店名
      customerPhoneNum:
        state && state.customerPhoneNum ? state.customerPhoneNum : "", // 加盟店電話番号
      contractorTerminationFlag:
        state && state.contractorTerminationFlag
          ? state.contractorTerminationFlag
          : false, // 解約含むか
      pageNumber: state && state.pageNumber ? state.pageNumber : "1", // ページ番号
    });
  const [searchResult, setSearchResult] = useState<IContractorSearchResponse>(
    {}
  );

  const operatorUserType = getUserType();

  // 契約者検索&取得
  const getContractors = (pageNumber: string) => {
    setLoadingOpen(true);
    contractors
      .search(
        authHeader as string,
        searchCondition.companyName,
        searchCondition.corporateNum,
        searchCondition.customerCode,
        searchCondition.customerName,
        searchCondition.customerPhoneNum,
        String(searchCondition.contractorTerminationFlag),
        pageNumber
      )
      .then((res) => {
        // ユーザーリストの取得結果と検索条件をセット
        setSearchResult(res.data);
        setSearchCondition({ ...searchCondition, pageNumber });
        setSearchApiErrorMsg("");
      })
      .catch((error) => {
        setSearchApiErrorMsg(getErrorMessage(error));
        throw error;
      })
      .finally(() => {
        setSearchCondition({ ...searchCondition, pageNumber });
        setLoadingOpen(false);
      });
  };

  // 遷移時に検索条件が付与されていたら検索した状態にする
  useEffect(() => {
    if (state) {
      getContractors(searchCondition.pageNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Dashboard
        title="契約者管理"
        color={ADMIN_COLOR.base}
        backgroundColor={ADMIN_COLOR.primary}
        iconName="contractor"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {/* 契約者追加ボタン 管理者は追加ボタン非表示 */}
          {operatorUserType !== USER_TYPE_LIST.MANAGER_JTB && (
            <Button
              className={clsx(
                buttonClasses.button,
                classes.button,
                classes.addButton
              )}
              variant="contained"
              component={Link}
              to="/dealer/contractor/new"
            >
              <PersonAddIcon className={classes.icon} />
              新規契約者登録
            </Button>
          )}
          {/* 契約者検索フォーム */}
          <form className={classes.form}>
            <ContractorSearchConditionForm
              searchCondition={searchCondition}
              setSearchCondition={setSearchCondition}
            />
            <Button
              className={clsx(searchButtonClasses.button, classes.searchButton)}
              variant="contained"
              onClick={() => getContractors("1")}
              disabled={loadingOpen} // 二重送信対策
            >
              <SearchIcon className={classes.searchIcon} />
              検索
            </Button>
          </form>
          {searchApiErrorMsg === "" && (
            <>
              {/* 契約者検索結果 */}
              {searchResult && (
                <ContractorSearchResult
                  resultsRows={searchResult}
                  conditionState={searchCondition}
                  getContractors={getContractors}
                />
              )}
            </>
          )}
          {searchApiErrorMsg !== "" && (
            <Alert className={classes.alert} severity="error">
              {searchApiErrorMsg}
            </Alert>
          )}
        </Container>
      </Dashboard>
    </>
  );
};

export default ContractorPage;
