/**
 * 共通-動作環境画面
 */
import React from "react";
import { Link } from "react-router-dom";

import { makeStyles, Container, Button, Typography } from "@material-ui/core";

import { ADMIN_COLOR, FONT } from "../../../../constants/common";

import { PathContext } from "../../../../App";

const useStyles = makeStyles(() => ({
  div: { height: "100%" },
  divImage: {
    height: "100%",
    background: `url(${window.location.origin}/bgtestForGree.png) repeat`,
  },
  container: {
    minWidth: 480, // 白枠も固定幅
    maxWidth: 480, // 白枠も固定幅
    padding: "35px!important",
    backgroundColor: ADMIN_COLOR.base,
    margin: "0 auto",
  },
  h2: {
    fontSize: "1.5em",
    color: ADMIN_COLOR.primary,
    paddingTop: 17,
    fontWeight: "bold",
    height: 50,
    fontFamily: FONT,
    zIndex: 2,
    backgroundColor: ADMIN_COLOR.base,
    position: "relative",
    width: 250,
  },
  h3: {
    fontSize: "1.2em",
    fontWeight: "bold",
    fontFamily: FONT,
    zIndex: 2,
    backgroundColor: ADMIN_COLOR.base,
    width: 370,
    marginTop: 20,
    position: "relative",
    padding: "0.5rem 0.5rem",
    borderBottom: "3px solid rgb(90, 88, 88)",
  },
  message: {
    width: 400,
    display: "block",
    fontFamily: FONT,
    fontSize: "0.9em",
  },
  button: {
    fontFamily: FONT,
    backgroundColor: ADMIN_COLOR.base,
    boxShadow: "none",
    color: ADMIN_COLOR.primary,
    border: `1px solid ${ADMIN_COLOR.primary}`,
    fontSize: "1.0em",
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 18,
    borderRadius: 0,
    display: "block",
    margin: "0 auto",
    marginTop: 30,
    width: 70,
    "&:hover": {
      backgroundColor: `${ADMIN_COLOR.btnHover}!important`,
    },
  },
}));

const OperationEnvironment: React.FC = () => {
  const classes = useStyles();

  const pathState = React.useContext(PathContext);

  return (
    // TODO:検証環境でJTB様がマニュアル用の画面画像取るため一時的に開発環境のTESTの背景を外す
    // <div
    //   className={
    //     process.env.REACT_APP_ENV === "development"
    //       ? classes.divImage
    //       : classes.div
    //   }
    // >
    <div className={classes.div}>
      <Container maxWidth="lg" className={classes.container}>
        <Typography variant="h2" className={classes.h2}>
          動作環境
        </Typography>
        <div className={classes.message}>
          ※現状はPCのみの対応になります。
          <br />
          ※常に各ブラウザのバージョンを最新化していただくことが望ましいです。
        </div>
        <Typography variant="h3" className={classes.h3}>
          PCブラウザ
        </Typography>
        <p>以下のバージョン未満の場合は使用できません。</p>
        <ul>
          <li>Microsoft Edge(80)</li>
          <li>Safari(13.4)</li>
          <li>Google Chrome(80)</li>
        </ul>
        <Button
          type="button"
          className={classes.button}
          component={Link}
          to={pathState.prevPath}
          variant="contained"
        >
          戻る
        </Button>
      </Container>
    </div>
  );
};

export default OperationEnvironment;
