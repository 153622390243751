/**
 * JTB側-追加資料アップロードモーダル
 */
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";

import clsx from "clsx";
import { Button, Modal, Theme, makeStyles } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import WarnIcon from "@material-ui/icons/Warning";

import { buttonStyles } from "../../../../common/components/styles";

import { ADMIN_COLOR, FONT } from "../../../../../constants/common";
import { ADDITIONAL_UPLOAD_ACCEPT_TYPE } from "../../../../../constants/deal";

import { GlobalPopupContext } from "../../../../../App";
import { AuthContext } from "../../../../../cognito/AuthContext";
import getErrorMessage from "../../../../../utils/error";
import getBase64 from "../../../../../utils/file";
import fileUtils from "../../../../../api/utils/fileUtils";
import formStore from "../../../../../store/formStore";
import ConfirmModal from "../../../../common/components/atoms/ConfirmModal";

const useStyle = makeStyles((theme: Theme) => ({
  popup: {
    position: "absolute",
    minWidth: 700,
    maxWidth: 950,
    width: "60%",
    minHeight: 150,
    maxHeight: 750,
    height: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
  },
  modalTitle: {
    fontSize: "1.1em",
    color: ADMIN_COLOR.primary,
    fontFamily: FONT,
    margin: 0,
  },
  dropAreaBlock: {
    display: "flex",
    margin: "10px 0px",
    marginTop: 20,
    minHeight: 200,
    border: "2px dashed #494949",
    padding: "20px 10px 20px 0px",
    textAlign: "center",
    fontFamily: FONT,
    justifyContent: "center",
    alignItems: "center",
    color: "#666",
  },
  buttonBlock: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  selectButton: {
    paddingBottom: 8,
    marginRight: 20,
  },
  cancelButton: {
    paddingBottom: 8,
    backgroundColor: `${ADMIN_COLOR.base}!important`,
    color: `${ADMIN_COLOR.primary}!important`,
    border: `2px solid ${ADMIN_COLOR.primary}`,
  },
  warnIcon: {
    position: "relative",
    marginLeft: -3,
    marginBottom: -5,
    left: -6,
    top: 2,
  },
  warnMessage: {
    color: ADMIN_COLOR.primary,
    textAlign: "center",
  },
}));

type TFileData = { data: string; name: string };

type Props = {
  dealId: number;
  customerId: number;
  updateFileList: () => Promise<void>;
  setOpen: (open: boolean) => void;
  addedFileData: { fileName: string; fileId: number }[];
};

const AdditionalFileUploadModal: React.FC<Props> = (props: Props) => {
  const { dealId, customerId, updateFileList, setOpen, addedFileData } = props;

  const { authHeader } = useContext(AuthContext);
  const { setLoading } = useContext(GlobalPopupContext);
  const [files, setFiles] = useState<TFileData[]>([]);
  const [error, setError] = useState("");
  const [confirmOverwrite, setConfirmOverwrite] = useState(false);
  const modalStyle = useMemo(
    () => ({
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }),
    []
  );
  const classes = useStyle();
  const buttonClasses = buttonStyles({
    width: 130,
    marginTop: 0,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length <= 0) return;

    setError("");
    setFiles([]);

    // バイト数(1MB)チェック
    // eslint-disable-next-line
    if (acceptedFiles.map((f: any) => f.size >= 1024 * 1024).includes(true)) {
      setError("上限サイズ1MBを超えるファイルがあります");
      return;
    }

    // pdf, xlsx 以外の場合はエラー
    if (
      acceptedFiles
        // eslint-disable-next-line
        .map((f: any) => ADDITIONAL_UPLOAD_ACCEPT_TYPE.includes(f.type))
        .includes(false)
    ) {
      setError("PDFとExcel（.xlsx）のみ利用可能です");
      return;
    }

    // base64変換処理作成（非同期）
    const promises = acceptedFiles.map(
      // eslint-disable-next-line
      (f: any) =>
        new Promise<TFileData>((resolve, reject) => {
          getBase64(f)
            .then((result) => {
              resolve({ data: result as string, name: f.name });
            })
            .catch((err) => {
              reject(err);
            });
        })
    );

    // 変換処理実行
    Promise.all<TFileData[]>(promises)
      .then((results) => {
        setFiles(results);
      })
      .catch((err) => {
        setError(err);
        throw err;
      });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const uploadFiles = useCallback(async () => {
    if (!authHeader) return;
    setLoading(true);
    const contractorId = formStore.getContractorId();
    const dealType = formStore.getEditDealType();

    // APIのPromise配列取得
    // 契約者変更の場合で場合分け
    const api =
      contractorId && dealType && dealType === "契約者変更"
        ? fileUtils.getUploadAdditionalForContractorFileApi(
            authHeader,
            files,
            dealId,
            contractorId
          )
        : fileUtils.getUploadAdditionalFileApi(
            authHeader,
            files,
            dealId,
            customerId
          );

    // API実行
    Promise.all(api)
      .then(() => {
        updateFileList().then(() => {
          handleClose();
          setLoading(false);
        });
      })
      .catch((err) => {
        setError(getErrorMessage(err));
        setLoading(false);
      });
  }, [
    authHeader,
    dealId,
    customerId,
    files,
    handleClose,
    setLoading,
    updateFileList,
  ]);

  const checkFileName = () => {
    if (
      addedFileData?.some((addedFile) =>
        files.some((currentFile) => addedFile.fileName === currentFile.name)
      )
    ) {
      return setConfirmOverwrite(true);
    }
    return uploadFiles();
  };

  return (
    <>
      {confirmOverwrite && (
        <ConfirmModal
          open={confirmOverwrite}
          setOpen={setConfirmOverwrite}
          modalTitle=""
          message="同名ファイルを上書きしますが、本当に実行しますか？"
          actionMethod={uploadFiles}
          colors={ADMIN_COLOR}
        />
      )}
      <Modal
        open
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.popup}>
          <h2 id="simple-modal-title" className={classes.modalTitle}>
            {" 追加資料アップロード"}
          </h2>
          <div>
            {error && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {error}
              </Alert>
            )}
            {/* eslint-disable */}
            <div className={classes.dropAreaBlock} {...getRootProps()}>
              <input
                {...getInputProps()}
                accept={ADDITIONAL_UPLOAD_ACCEPT_TYPE.toString()}
              />
              ここにファイルをドラッグ＆ドロップ または
              <br />
              クリックしてアップロード
              {files.length > 0 && (
                <>
                  <br />
                  <br />
                  {`選択中のファイル: ${files.map((f) => f.name)}`}
                </>
              )}
            </div>
            {/* eslint-enable */}

            <div className={classes.warnMessage}>
              <WarnIcon className={classes.warnIcon} />
              アップロードしますか？
            </div>

            <div className={classes.buttonBlock}>
              <Button
                type="button"
                className={clsx(buttonClasses.button, classes.selectButton)}
                onClick={checkFileName}
                variant="contained"
                disabled={files.length <= 0}
              >
                はい
              </Button>
              <Button
                type="button"
                className={clsx(buttonClasses.button, classes.cancelButton)}
                onClick={handleClose}
                variant="contained"
              >
                いいえ
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AdditionalFileUploadModal;
