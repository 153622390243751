/**
 * 加盟店側-解約申請
 * メモ：解約申請系画面の親コンポーネント
 */
import React from "react";
import { Route, Switch } from "react-router-dom";

import GuestTerminateDealNewPage from "./pages/GuestTerminateDealNewPage";
import GuestTerminateDealNewConfirmPage from "./pages/GuestTerminateDealNewConfirmPage";
import GuestTerminateDealNewCompletePage from "./pages/GuestTerminateDealNewCompletePage";
import GuestTerminateDealShowPage from "./pages/GuestTerminateDealShowPage";
import GuestTerminateDealEditPage from "./pages/GuestTerminateDealEditPage";
import GuestTerminateDealEditConfirmPage from "./pages/GuestTerminateDealEditConfirmPage";
import GuestTerminateDealEditCompletePage from "./pages/GuestTerminateDealEditCompletePage";

const TerminateDealHooks: React.FC = () => {
  return (
    <Switch>
      {/* 解約申請入力画面 */}
      <Route
        path="/guest/deal/termination/new"
        render={() => <GuestTerminateDealNewPage />}
        exact
      />
      {/* 解約申請確認画面 */}
      <Route
        path="/guest/deal/termination/confirm"
        render={() => <GuestTerminateDealNewConfirmPage />}
        exact
      />
      {/* 解約申請完了画面 */}
      <Route
        path="/guest/deal/termination/complete/:id"
        render={() => <GuestTerminateDealNewCompletePage />}
        exact
      />
      {/* 解約申請詳細画面 */}
      <Route
        path="/guest/deal/termination/:id"
        render={() => <GuestTerminateDealShowPage />}
        exact
      />
      {/* 解約申請編集画面 */}
      <Route
        path="/guest/deal/termination/:id/edit"
        render={() => <GuestTerminateDealEditPage />}
        exact
      />
      {/* 解約申請編集確認画面 */}
      <Route
        path="/guest/deal/termination/:id/edit/confirm"
        render={() => <GuestTerminateDealEditConfirmPage />}
        exact
      />
      {/* 解約申請編集完了画面 */}
      <Route
        path="/guest/deal/termination/:id/edit/complete"
        render={() => <GuestTerminateDealEditCompletePage />}
        exact
      />
    </Switch>
  );
};

export default TerminateDealHooks;
