/**
 * JTB側-精算明細書詳細画面の表
 * メモ：精算明細書のリストを表示する
 */
import React, { useContext, useState } from "react";

import clsx from "clsx";
import {
  Button,
  FormControl,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { buttonStyles, formStyles } from "../../../../common/components/styles";

import { IBillShowResponse, IBillShowList } from "../../../../../api/types";

import { ADMIN_COLOR, FONT } from "../../../../../constants/common";
import { DOWNLOAD_FILE_TYPE, BLOB_TYPE } from "../../../../../constants/bill";

import { getErrorMessage } from "../../../../../utils/error";
import bill from "../../../../../api/bill";
import { AuthContext } from "../../../../../cognito/AuthContext";
import { convBase64toBlob, downloadBlobFile } from "../../../../../utils/file";

import ConfirmModal from "../../../../common/components/atoms/ConfirmModal";
import BillStatusChip from "../../../../common/components/atoms/BillStatusChip";

const useStyles = makeStyles(() => ({
  table: {
    width: "auto",
  },
  cell: {
    fontFamily: FONT,
    padding: 1,
    backgroundColor: ADMIN_COLOR.base,
    color: "#000!important",
    border: `1px solid ${ADMIN_COLOR.primary}`,
    width: 200,
    fontSize: "0.9em",
    height: 38,
  },
  thCell: {
    fontFamily: FONT,
    color: ADMIN_COLOR.primary,
    border: `1px solid ${ADMIN_COLOR.primary}!important`,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: ADMIN_COLOR.base,
    width: 400,
    position: "relative",
    fontSize: "0.9em",
    height: 38,
  },
  billButton: {
    width: 130,
    padding: "4px 10px",
    margin: "10px",
    backgroundColor: ADMIN_COLOR.primary,
    color: ADMIN_COLOR.base,
    borderRadius: 0,
    display: "inline",
  },
  form: {
    marginTop: "20px!important",
  },
}));

interface Props {
  resultsRows: IBillShowResponse;
  setApiErrorMsg: React.Dispatch<React.SetStateAction<string>>;
  setLoadingOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getData: () => void;
}

const BillShowTable: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const formClasses = formStyles({
    color: ADMIN_COLOR.base,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const buttonClasses = buttonStyles({
    width: 213,
    marginTop: 15,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const { resultsRows, setApiErrorMsg, setLoadingOpen, getData } = props;

  const { authHeader } = useContext(AuthContext);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [fileId, setFileId] = useState<string>("");
  const [fileType, setFileType] = useState<string>("");

  const handleModalOpen = (id: number, type: string) => {
    setFileId(id.toString());
    setFileType(type);
    setModalOpen(true);
  };

  const onDownload = async () => {
    setModalOpen(false);
    setLoadingOpen(true);
    try {
      // 精算明細書、データファイルダウンロード
      await bill
        .download(authHeader as string, fileId, fileType)
        .then((res) => {
          const data = res.data.result.file as string;
          // base64 -> blob
          const blob = convBase64toBlob(
            data,
            fileType === DOWNLOAD_FILE_TYPE.BILL
              ? BLOB_TYPE.BILL
              : BLOB_TYPE.DATA
          );
          // download
          downloadBlobFile(blob, res.data.result.filename);
          setApiErrorMsg("");
        });

      // ダウンロード成功時はリスト再取得（API実行）
      await getData();
      setApiErrorMsg("");
    } catch (error) {
      setApiErrorMsg(getErrorMessage(error));
      throw error;
    } finally {
      setLoadingOpen(false);
    }
  };

  const columnNames = ["行番号", "未・済", "年月-回", "ダウンロード"] as const;

  return (
    <>
      <ConfirmModal
        modalTitle="本当にダウンロードしてよろしいですか？"
        actionMethod={onDownload}
        open={modalOpen}
        setOpen={setModalOpen}
        colors={ADMIN_COLOR}
      />
      {resultsRows.result !== undefined && (
        <>
          {/* 精算明細書のリスト */}
          <FormControl className={clsx(formClasses.form, classes.form)}>
            <TableContainer>
              <Table
                className={clsx(formClasses.table, classes.table)}
                aria-label="simple table"
              >
                <TableHead className={formClasses.th}>
                  <TableRow>
                    {/* 項目名 */}
                    {columnNames.map((column) => (
                      <TableCell
                        key={column}
                        align="center"
                        className={classes.thCell}
                      >
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(resultsRows.result?.bills as IBillShowList[]).map(
                    (resultsRow, index) => (
                      <TableRow key={resultsRow.yyyymmn}>
                        {/* 行番号 */}
                        <TableCell align="center" className={classes.cell}>
                          {index + 1}
                        </TableCell>
                        {/* 未・済 */}
                        <TableCell align="center" className={classes.cell}>
                          {/* 精算明細書 or データファイルがダウンロードされていれば「済」 */}
                          {!!Number(resultsRow.billStatus) ||
                          !!Number(resultsRow.dataStatus) ? (
                            <BillStatusChip isDone />
                          ) : (
                            <BillStatusChip isDone={false} />
                          )}
                        </TableCell>
                        {/* 年月-回 */}
                        <TableCell align="center" className={classes.cell}>
                          {/* 表示用に4文字目（yyyyとmmの間）にスラッシュを入れる */}
                          {`${resultsRow.yyyymmn.slice(
                            0,
                            4
                          )}/${resultsRow.yyyymmn.slice(4)}`}
                        </TableCell>
                        {/* ダウンロード */}
                        <TableCell align="center" className={classes.cell}>
                          {/* 1,3回目の場合はハイフンを表示 */}
                          {(resultsRow.yyyymmn.endsWith("1") ||
                            resultsRow.yyyymmn.endsWith("3")) &&
                            "-"}
                          {/* 2,4回目の場合、精算明細書がなければ利用なしを表示 */}
                          {(resultsRow.yyyymmn.endsWith("2") ||
                            resultsRow.yyyymmn.endsWith("4")) &&
                            !resultsRow.billFile &&
                            "今回の利用はありません"}
                          {/* 2,4回目の場合、精算明細書があればボタンを表示 */}
                          {(resultsRow.yyyymmn.endsWith("2") ||
                            resultsRow.yyyymmn.endsWith("4")) &&
                            !!resultsRow.billFile && (
                              <Button
                                className={clsx(
                                  buttonClasses.button,
                                  classes.billButton
                                )}
                                variant="contained"
                                onClick={() =>
                                  handleModalOpen(
                                    resultsRow.billFile as number,
                                    DOWNLOAD_FILE_TYPE.BILL
                                  )
                                }
                              >
                                精算明細書
                              </Button>
                            )}
                          {/* 2,4回目の場合、データファイルがあればボタンを表示 */}
                          {(resultsRow.yyyymmn.endsWith("2") ||
                            resultsRow.yyyymmn.endsWith("4")) &&
                            !!resultsRow.dataFile && (
                              <Button
                                className={clsx(
                                  buttonClasses.button,
                                  classes.billButton
                                )}
                                variant="contained"
                                onClick={() =>
                                  handleModalOpen(
                                    resultsRow.dataFile as number,
                                    DOWNLOAD_FILE_TYPE.DATA
                                  )
                                }
                              >
                                データファイル
                              </Button>
                            )}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </FormControl>
        </>
      )}
    </>
  );
};

export default React.memo(BillShowTable);
