import { formNameIdToColumnName } from "../../utils/formData";
import { TFormColumn, TFormData } from "../../views/common/components/types";
import { TFixedNumberProcessResult } from "./types";

/**
 * 結合項目スペース入れる用の値
 */
type TCombineSpaceValue = -1 | -2;

/**
 * 結合項目情報
 */
type TCombineData = {
  column: TFormColumn;
  combine: (TFormColumn | TCombineSpaceValue)[];
};

/**
 * 結合対象項目一覧
 */
let COMBINE_DEST_COLUMNS: TFormColumn[] = [];

/**
 * 結合項目情報一覧
 */
const COMBINE_MAP = new Map<TFormColumn, TCombineData[]>();

/**
 * 全フォームのデータを全項目データ配列化
 * @param data TFormData 全フォームのデータ
 * @returns TFormObject[]
 */
const getAllFormObjects = (data: Omit<TFormData, "formVersion">) => {
  const keys = Object.keys(data).filter((k) => k !== "formVersion");
  const forms = keys
    .flatMap((k) => {
      const form = data[k as unknown as keyof typeof data];
      if (!form || form.length <= 0) return [];

      return form.flatMap((f) => f.form);
    })
    .flatMap((form) => {
      const columns = Object.keys(form);
      return columns.flatMap((c) => form[c as TFormColumn]);
    });

  return forms;
};

/**
 * 結合項目情報の保存
 * @param data: TFormData 全フォームのデータ
 */
export const setCombineDestColumns = (data: TFormData) => {
  // 結合対象項目一覧の初期化
  COMBINE_DEST_COLUMNS = [];
  COMBINE_MAP.clear();

  // 全項目情報取得
  const forms = getAllFormObjects(data);

  // 結合項目だけを抽出
  const combineForms = forms.filter(
    (o) =>
      o.json.combineDestFormNameIds && o.json.combineDestFormNameIds.length > 0
  );

  combineForms.forEach((c) => {
    // 結合する項目のformNameIdリスト
    const destIds = c.json.combineDestFormNameIds;
    if (destIds && destIds.length > 0) {
      destIds.forEach((id) => {
        // 結合対象項目取得
        const find = forms.find((f) => f.json.formNameId === id);
        if (find) {
          // 対象のカラム名保存
          COMBINE_DEST_COLUMNS.push(find.column);

          // 結合情報
          const combineData = {
            column: c.column,
            combine: formNameIdToColumnName(forms, destIds) as (
              | TFormColumn
              | TCombineSpaceValue
            )[],
          };

          // 結合情報の保存
          const hit = COMBINE_MAP.get(find.column);
          if (hit) {
            hit.push(combineData);
          } else {
            COMBINE_MAP.set(find.column, [combineData]);
          }
        }
      });
    }
  });
};

/**
 * 項目の結合処理
 * @param data: TFormData 全フォーム情報
 * @param columnName: 入力値が更新された項目の項目名
 * @returns TFixedNumberProcessResult
 */
export const combineContents: (
  data: TFormData,
  columnName: TFormColumn
) => TFixedNumberProcessResult = (data: TFormData, columnName: TFormColumn) => {
  const ret: TFixedNumberProcessResult = [];

  // 結合対象に含まれてなければ空配列
  if (!COMBINE_DEST_COLUMNS.includes(columnName)) return ret;

  const combineData = COMBINE_MAP.get(columnName);

  // 結合情報が保存されてなければ空配列
  if (!combineData) return ret;

  // 全項目情報取得
  const forms = getAllFormObjects(data);

  combineData.forEach((cd) => {
    // 結合後の値で更新する対象項目
    const target = forms.find((f) => f.column === cd.column);
    if (target) {
      let result = ""; // 結合後の値
      let reserve = ""; // スペースの場合の一時保存

      cd.combine.forEach((c) => {
        // number（-1 or -2）の場合はスペースを入れる
        if (typeof c === "number") {
          reserve += c === -1 ? " " : "　";
        }
        // stringの場合は結合対象のカラム名が入っているので入力値を取得
        if (typeof c === "string") {
          const content = forms.find((f) => f.column === c)?.content || "";
          if (content) {
            // 直前がスペースの可能性があるので、一時保存を経由して結合後の値に追加する
            reserve += content;
            result += reserve;
          }
          // カラム名だった場合は空文字かどうかにかかわらず一時保存はリセット
          reserve = "";
        }
      });

      // 結合完了した値で更新情報を返す
      ret.push({
        column: target.column,
        content: result,
      });
    }
  });

  return ret;
};

/**
 * 自動チェックのための結合項目の抽出
 * @param columnName: 入力値が更新された項目の項目名
 * @returns: { targetColumns: TFormColumn[]; autoCheckColumns: TFormColumn[] }
 */
export const extractCombineContentsForChangeDeal = (
  columnName: TFormColumn
) => {
  const targetColumns: TFormColumn[] = [];
  const autoCheckColumns: TFormColumn[] = [];
  const combineData = COMBINE_MAP.get(columnName);

  if (!combineData) return { targetColumns, autoCheckColumns };

  combineData.forEach((cd) => {
    autoCheckColumns.push(cd.column);
    cd.combine.forEach((c) => {
      if (typeof c === "string") {
        targetColumns.push(c);
      }
    });
  });

  return { targetColumns, autoCheckColumns };
};
