/**
 * 加盟店側-サイドメニューのリンク一覧
 * メモ：ユーザーの権限によって出し分けされる
 */
import React, { useContext } from "react";
import { NavLink, Link } from "react-router-dom";

import clsx from "clsx";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonIcon from "@material-ui/icons/PersonOutline";
import StoreIcon from "@material-ui/icons/Store";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import PlaylistAddCheck from "@material-ui/icons/PlaylistAddCheck";
import ReportIcon from "@material-ui/icons/AssessmentOutlined";
import TermsIcon from "@material-ui/icons/LibraryBooksOutlined";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import InfoIcon from "@material-ui/icons/InfoOutlined";

import { SideBarStyles } from "./styles";
import { Current } from "../common/components/styles";

import { AuthContext } from "../../cognito/AuthContext";
import { checkAuth, getUserContractorId, isSysAd } from "../../utils/auth";

const GuestList: React.FC = () => {
  const classes = SideBarStyles();

  const { signOut } = useContext(AuthContext);

  return (
    <>
      {/* ダッシュボード */}
      {checkAuth("dashboard") && (
        <>
          <NavLink
            exact
            to="/guest"
            className={classes.link}
            activeStyle={Current}
          >
            <ListItem button>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText
                className={classes.linkName}
                primary="ダッシュボード"
              />
            </ListItem>
          </NavLink>
          <Divider />
        </>
      )}
      {/* 契約者管理 */}
      {(checkAuth("contractorShow") || checkAuth("contractorEdit")) && (
        <>
          <NavLink
            to={
              // システム担当ユーザーは契約者検索画面,それ以外は契約者詳細画面へ
              isSysAd()
                ? "/guest/contractor"
                : `/guest/contractor/${getUserContractorId()}`
            }
            className={classes.link}
            activeStyle={Current}
          >
            <ListItem button>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText className={classes.linkName} primary="契約者管理" />
            </ListItem>
          </NavLink>
          <Divider />
        </>
      )}
      {/* 加盟店管理 */}
      {checkAuth("customerShow") && (
        <>
          <NavLink
            to="/guest/customer"
            className={classes.link}
            activeStyle={Current}
          >
            <ListItem button>
              <ListItemIcon>
                <StoreIcon />
              </ListItemIcon>
              <ListItemText className={classes.linkName} primary="加盟店管理" />
            </ListItem>
          </NavLink>
          <Divider />
        </>
      )}
      {/* 端末管理 */}
      {checkAuth("terminalShow") && (
        <>
          <NavLink
            to="/guest/terminal"
            className={classes.link}
            activeStyle={Current}
          >
            <ListItem button>
              <ListItemIcon>
                <PhonelinkSetupIcon />
              </ListItemIcon>
              <ListItemText className={classes.linkName} primary="端末管理" />
            </ListItem>
          </NavLink>
          <Divider />
        </>
      )}
      {/* 申請管理 */}
      {checkAuth("dealsOperate") && (
        <>
          <NavLink
            to="/guest/deal"
            className={classes.link}
            activeStyle={Current}
          >
            <ListItem button>
              <ListItemIcon>
                <PlaylistAddCheck />
              </ListItemIcon>
              <ListItemText className={classes.linkName} primary="申請管理" />
            </ListItem>
          </NavLink>
          <Divider />
        </>
      )}
      {/* 帳票・データ出力 */}
      {(checkAuth("terminalDLL") ||
        checkAuth("terminalFileDownload") ||
        checkAuth("settlementNoteShow") ||
        checkAuth("cardFileUpload") ||
        checkAuth("cardFileDownload")) && (
        <>
          <NavLink
            to="/guest/data"
            className={classes.link}
            activeStyle={Current}
          >
            <ListItem button>
              <ListItemIcon>
                <ReportIcon />
              </ListItemIcon>
              <ListItemText
                className={classes.linkName}
                primary="帳票・データ出力"
              />
            </ListItem>
          </NavLink>
          <Divider />
        </>
      )}
      {checkAuth("documentsDownload") && (
        <>
          {/* 規約確認 */}
          <NavLink
            to="/guest/latest-terms"
            className={classes.link}
            activeStyle={Current}
          >
            <ListItem button>
              <ListItemIcon>
                <TermsIcon />
              </ListItemIcon>
              <ListItemText className={classes.linkName} primary="規約確認" />
            </ListItem>
          </NavLink>
          <Divider />
          {/* マニュアル確認 */}
          <NavLink
            to="/guest/documents"
            className={classes.link}
            activeStyle={Current}
          >
            <ListItem button>
              <ListItemIcon>
                <TermsIcon />
              </ListItemIcon>
              <ListItemText
                className={clsx(classes.linkName)}
                primary="マニュアル確認"
              />
            </ListItem>
          </NavLink>
          <Divider />
        </>
      )}
      {/* 設定 */}
      <NavLink
        to="/guest/setting"
        className={classes.link}
        activeStyle={Current}
      >
        <ListItem button>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText className={classes.linkName} primary="設定" />
        </ListItem>
      </NavLink>
      <Divider />
      <Divider />
      {/* 案内板（各種申請） */}
      <NavLink to="/guest/guide" className={classes.link} activeStyle={Current}>
        <ListItem button>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText className={classes.linkName} primary="各種申請" />
        </ListItem>
      </NavLink>
      <Divider />
      {/* ログアウト */}
      <Link
        to="/guest/login"
        className={classes.link}
        onClick={() => {
          signOut();
        }}
      >
        <ListItem button>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText className={classes.linkName} primary="ログアウト" />
        </ListItem>
      </Link>
      <Divider />
    </>
  );
};

export default GuestList;
