/**
 * JTB側-設定画面
 * メモ：権限に合った設定メニューを表示する
 */
import React from "react";
import { Link } from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import CallIcon from "@material-ui/icons/Call";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AccessibilityIcon from "@material-ui/icons/Accessibility";

import {
  buttonStyles,
  settingStyles,
} from "../../../../common/components/styles";
import { useContainerStyles } from "../../../../templates/styles";

import { ADMIN_COLOR } from "../../../../../constants/common";

import { checkAuth } from "../../../../../utils/auth";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";

const breadCrumbs = [{ name: "設定", link: "" }];

const useStyles = makeStyles(() => ({
  mailButton: {
    margin: "0 auto",
    marginTop: 40,
    paddingLeft: 74,
    paddingBottom: 10,
  },
  mailIcon: {
    position: "absolute",
    top: 7,
    left: 35,
  },
  lockOpenButton: {
    margin: "0 auto",
    marginTop: 40,
    paddingLeft: 78,
    paddingBottom: 10,
  },
  lockOpenIcon: {
    position: "absolute",
    top: 7,
    left: 40,
  },
  accountCircleButton: {
    margin: "0 auto",
    marginTop: 40,
    paddingLeft: 86,
    paddingBottom: 10,
  },
  accountCircleIcon: {
    position: "absolute",
    top: 7,
    left: 49,
  },
  accessibilityButton: {
    margin: "0 auto",
    marginTop: 40,
    paddingLeft: 86,
    paddingBottom: 10,
  },
  accessibilityIcon: {
    position: "absolute",
    top: 7,
    left: 49,
  },
}));

const SettingPage: React.FC = () => {
  const classes = useStyles();
  const containerClasses = useContainerStyles();
  const buttonClasses = buttonStyles({
    width: 250,
    marginTop: 40,
    backgroundColor: ADMIN_COLOR.primary,
  });
  const settingClasses = settingStyles();

  return (
    <Dashboard
      title="設定"
      color={ADMIN_COLOR.base}
      backgroundColor={ADMIN_COLOR.primary}
      iconName="setting"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        <div className={settingClasses.margin}>
          {checkAuth("userMailEdit") && (
            <Button
              className={clsx(buttonClasses.button, classes.mailButton)}
              variant="contained"
              component={Link}
              to="/dealer/setting/mail/edit"
            >
              <MailIcon className={classes.mailIcon} />
              メールアドレス変更
            </Button>
          )}
          {checkAuth("userPasswordEdit") && (
            <Button
              className={clsx(buttonClasses.button, classes.lockOpenButton)}
              variant="contained"
              component={Link}
              to="/dealer/setting/password/edit"
            >
              <LockOpenIcon className={classes.lockOpenIcon} />
              パスワード変更
            </Button>
          )}
          {checkAuth("userPhoneNumEdit") && (
            <Button
              className={clsx(buttonClasses.button, classes.lockOpenButton)}
              variant="contained"
              component={Link}
              to="/dealer/setting/userPhoneNum/edit"
            >
              <CallIcon className={classes.lockOpenIcon} />
              電話番号変更
            </Button>
          )}
          {checkAuth("userNameEdit") && (
            <Button
              className={clsx(
                buttonClasses.button,
                classes.accountCircleButton
              )}
              variant="contained"
              component={Link}
              to="/dealer/setting/name/edit"
            >
              <AccountCircleIcon className={classes.accountCircleIcon} />
              ユーザー名変更
            </Button>
          )}
          {checkAuth("jtbUserManaged") && (
            <Button
              className={clsx(
                buttonClasses.button,
                classes.accessibilityButton
              )}
              variant="contained"
              component={Link}
              to="/dealer/setting/user"
            >
              <AccessibilityIcon className={classes.accessibilityIcon} />
              自社ユーザー管理
            </Button>
          )}
          {checkAuth("guestUserManaged") && (
            <Button
              className={clsx(
                buttonClasses.button,
                classes.accessibilityButton
              )}
              variant="contained"
              component={Link}
              to="/dealer/setting/guestUser"
            >
              <AccessibilityIcon className={classes.accessibilityIcon} />
              加盟店ユーザー管理
            </Button>
          )}
        </div>
      </Container>
    </Dashboard>
  );
};

export default SettingPage;
