import { TFormRowData } from "../../views/common/components/types";
import { TFixedNumberProcessResult } from "./types";

/**
 * 回線種別での自動入力
 * @param data: 端末情報フォーム
 * @returns TFixedNumberProcessResult
 */
export const autoInputByCircuitType: (
  data: TFormRowData
) => TFixedNumberProcessResult = (data: TFormRowData) => {
  const circuitType = data.circuitType[0];
  const isLTE = circuitType.content === JSON.stringify({ "3": "LTE" });

  return [
    {
      column: "DHCP",
      content: isLTE ? JSON.stringify({ "2": "無し" }) : "",
      disabled: isLTE,
    },
  ];
};

/**
 * DHCPでの自動入力
 * @param data: 端末情報フォーム
 * @returns TFixedNumberProcessResult
 */
export const autoInputByDHCP: (
  data: TFormRowData
) => TFixedNumberProcessResult = (data: TFormRowData) => {
  const circuitType = data.circuitType[0];
  const isNotLTE =
    circuitType.content === JSON.stringify({ "1": "有線LAN" }) ||
    circuitType.content === JSON.stringify({ "2": "無線LAN" });

  const dhcp = data.DHCP[0];
  const isNoDHCP = dhcp.content === JSON.stringify({ "2": "無し" });

  //   const ipAddress = data.IPAddress[0];

  return [
    {
      column: "IPAddress",
      content: "",
      isRequired: isNotLTE && isNoDHCP,
      isHide: !(isNotLTE && isNoDHCP),
    },
    {
      column: "subNet",
      content: "",
      isRequired: isNotLTE && isNoDHCP,
      isHide: !(isNotLTE && isNoDHCP),
    },
    {
      column: "gateway",
      content: "",
      isRequired: isNotLTE && isNoDHCP,
      isHide: !(isNotLTE && isNoDHCP),
    },
    {
      column: "DNS",
      content: "",
      isRequired: isNotLTE && isNoDHCP,
      isHide: !(isNotLTE && isNoDHCP),
    },
  ];
};
