import { setBankBranchMap } from "../../form/fixedNumberProcess/bankCode";
import {
  setBusinessCodeMap,
  setCustomerGroupMap,
  setRatePatternMap,
  setSettlementPatternMap,
} from "../../form/fixedNumberProcess/customerGroup";
import { setDistrictDataMap } from "../../form/fixedNumberProcess/customerPrefecture";
import { setIndustryDataMap } from "../../form/fixedNumberProcess/industryClass";
import preset from "../preset";

/**
 * presetForm.json一覧
 *
 * json名とマッピング化メソッド
 */
const PRESET_JSONS = [
  { name: "membTypMst", func: setCustomerGroupMap },
  { name: "jigyoMst", func: setBusinessCodeMap },
  { name: "membCreChargeMst", func: setRatePatternMap },
  { name: "settlementPtnMst", func: setSettlementPatternMap },
  { name: "customerDistrictCode", func: setDistrictDataMap },
  { name: "industrySmallClass", func: setIndustryDataMap },
  { name: "financialOrganCodeMap", func: setBankBranchMap },
];

/**
 * presetForm.jsonの取得とマッピングデータ化
 * @param authHeader: 認証トークン
 * @param version: フォームバージョン
 */
export const preloadFormContent: (
  authHeader: string,
  version: string
) => Promise<void> = async (authHeader: string, version: string) => {
  // json取得API呼び出し
  const promises = PRESET_JSONS.map((pre) => {
    return new Promise((resolve) => {
      preset.getPresetFormJson(authHeader, pre.name, version).then((res) => {
        // 取得したjsonをマッピンデータに整形
        const f = pre.func;
        f(res.data.result);
        resolve(res);
      });
    });
  });

  await Promise.all(promises);
};

export default preloadFormContent;
