/**
 * 加盟店側-ユーザーID確認用項目の入力フォーム
 * メモ：入力フォームを表示する
 */
import React from "react";

import { FormControl } from "@material-ui/core";

import { loginFormStyles } from "../../../../common/components/styles";

import { SendUserItemObject } from "../../types";

import CustomTextField from "../../../../common/components/atoms/CustomTextField";

interface Props {
  sendUserState: SendUserItemObject;
  setSendUserState: React.Dispatch<React.SetStateAction<SendUserItemObject>>;
}

const GuestSendUserForm: React.FC<Props> = (props: Props) => {
  const loginFormClasses = loginFormStyles();

  const { sendUserState, setSendUserState } = props;

  return (
    <>
      {/* メールアドレス */}
      <FormControl className={loginFormClasses.form}>
        <CustomTextField
          value={sendUserState.email}
          placeholder="メールアドレスを入力"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSendUserState({
              ...sendUserState,
              email: e.target.value,
            })
          }
        />
      </FormControl>
      {/* 端末識別番号 */}
      <FormControl className={loginFormClasses.form}>
        <CustomTextField
          value={sendUserState.tid}
          placeholder="端末識別番号を入力"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSendUserState({
              ...sendUserState,
              tid: e.target.value,
            })
          }
        />
      </FormControl>
    </>
  );
};

export default GuestSendUserForm;
