/**
 * JTB側-カード会社連携ファイル画面
 * メモ：カード会社連携ファイルの各ボタンを表示する。アップロードはこの画面で実施
 */
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import UploadIcon from "@material-ui/icons/Publish";
import DownloadIcon from "@material-ui/icons/GetApp";

import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { ADMIN_COLOR } from "../../../../../constants/common";

import { checkAuth, isSmccUser } from "../../../../../utils/auth";
import cardCompanyFile from "../../../../../api/cardCompanyFile";
import { AuthContext } from "../../../../../cognito/AuthContext";
import { getErrorMessage } from "../../../../../utils/error";
import { GlobalPopupContext } from "../../../../../App";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import CardExcelUploadDialog from "../molecules/CardExcelUploadDialog";
import AlertMessage from "../../../../common/components/atoms/AuthAlertMsg";
import Loading from "../../../../common/components/molecules/Loading";

const breadCrumbs = [
  { name: "帳票・データ出力", link: "/dealer/data" },
  { name: "カード会社連携ファイル", link: "" },
];

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: 60,
  },
  button: {
    margin: "0 auto",
    marginTop: 40,
    paddingBottom: 10,
    paddingLeft: 45,
    textAlign: "center",
  },
  icon: {
    position: "absolute",
    top: 7,
    left: 20,
  },
}));

const DataCardFilePage: React.FC = () => {
  const containerClasses = useContainerStyles();
  const classes = useStyles();
  const buttonClasses = buttonStyles({
    width: 300,
    marginTop: 40,
    backgroundColor: ADMIN_COLOR.primary,
  });

  const { authHeader } = useContext(AuthContext);
  const { setSnackbar } = useContext(GlobalPopupContext);
  const [uploadDialogOpen, setUploadDialogOpen] = useState<boolean>(false);
  const [uploadFileError, setUploadFileError] = useState("");
  const [loadingOpen, setLoadingOpen] = useState(false);

  // アップロードダイアログを開く
  const handleUploadDialogOpen = () => {
    setUploadDialogOpen(true);
  };

  // アップロードダイアログ内でボタンを押した際の処理
  const handleUpload = (file: string, fileName: string) => {
    setLoadingOpen(true);
    // ファイルアップロード
    cardCompanyFile
      .upload(authHeader as string, file, fileName)
      .then(() => {
        setUploadFileError("");
        setSnackbar({
          openProps: true,
          message: "アップロードが完了しました。",
          severity: "info",
        });
      })
      .catch((error) => {
        setUploadFileError(getErrorMessage(error));
      })
      .finally(() => {
        setUploadDialogOpen(false);
        setLoadingOpen(false);
      });
  };
  const handleCancel = () => {
    setUploadDialogOpen(false);
  };

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Dashboard
        title="帳票・データ出力"
        color={ADMIN_COLOR.base}
        backgroundColor={ADMIN_COLOR.primary}
        iconName="data"
      >
        {/* 連携ファイルアップロード用ダイアログ */}
        <CardExcelUploadDialog
          open={uploadDialogOpen}
          title="連携ファイルアップロード"
          message=""
          onUpload={handleUpload}
          onCancel={handleCancel}
          loadingOpen={loadingOpen}
          setLoadingOpen={setLoadingOpen}
        />
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {uploadFileError && <AlertMessage errorMessage={uploadFileError} />}
          <div className={classes.margin}>
            {/* 連携ファイルアップロード（SMCCユーザーのみ） */}
            {checkAuth("cardFileUpload") && isSmccUser() && (
              <Button
                className={clsx(buttonClasses.button, classes.button)}
                variant="contained"
                onClick={handleUploadDialogOpen}
              >
                <UploadIcon className={classes.icon} />
                連携ファイルアップロード
              </Button>
            )}
            {/* 連携ファイル検索・ダウンロード */}
            <Button
              className={clsx(buttonClasses.button, classes.button)}
              variant="contained"
              component={Link}
              to="/dealer/data/card-file/download"
            >
              <DownloadIcon className={classes.icon} />
              連携ファイル検索・ダウンロード
            </Button>
          </div>
        </Container>
      </Dashboard>
    </>
  );
};

export default DataCardFilePage;
