/**
 * 加盟店側-ユーザー追加画面
 * メモ：入力フォームを表示する
 */
import React, { useContext, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AddIcon from "@material-ui/icons/Add";

import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { GuestUserNewItemObject } from "../../types";

import {
  DISPLAY_NAME_MAX_LENGTH,
  EMAIL_MAX_LENGTH,
  GUEST_COLOR,
} from "../../../../../constants/common";
import {
  USER_TYPE_NAME_LIST,
  USER_TYPE_SEARCH_MAP_FOR_CUSTOMER,
} from "../../../../../constants/user";

import { GlobalPopupContext } from "../../../../../App";
import { AuthContext } from "../../../../../cognito/AuthContext";
import { compareSJISWhiteList } from "../../../../../form/validation";
import cognitoUser from "../../../../../api/cognitoUser";
import {
  isSysAd,
  isCustomerStaffUser,
  getUserContractorId,
  getUserCustomerId,
  getUserCustomers,
} from "../../../../../utils/auth";
import { getErrorMessage } from "../../../../../utils/error";

import Loading from "../../../../common/components/molecules/Loading";
import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";
import AlertMessage from "../../../../common/components/atoms/AuthAlertMsg";
import GuestSettingUserNewForm from "../organisms/GuestSettingUserNewForm";

// パンくずリストの設定
const breadCrumbs = [
  { name: "設定", link: "/guest/setting" },
  { name: "ユーザー管理・検索", link: "/guest/setting/user" },
  { name: "ユーザー追加", link: "" },
];

// レイアウト設定
const useStyles = makeStyles(() => ({
  addButton: {
    margin: "0 auto",
    marginTop: 20,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 27,
  },
  addIcon: {
    position: "absolute",
    top: 8,
    left: 31,
  },
  form: {
    width: 800,
    marginTop: 20,
  },
  flashMessage: {
    marginBottom: 20,
  },
}));

// 画面表示処理
const GuestSettingUserNewPage: React.FC<RouteComponentProps> = (props) => {
  const buttonClasses = buttonStyles({
    width: 180,
    marginTop: 20,
    backgroundColor: GUEST_COLOR.primary,
  });
  const containerClasses = useContainerStyles();
  const classes = useStyles();

  const { history } = props;

  const { authHeader, userAttributes } = useContext(AuthContext);
  const { setConfirmModal, setSnackbar } = useContext(GlobalPopupContext);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [createUserError, setCreateUserError] = useState(<div />);

  const [userNewState, setUserNewState] = useState<GuestUserNewItemObject>({
    contractorId: isSysAd() ? "" : getUserContractorId(), // 契約者ID
    companyName: "", // 契約者名（表示用）
    masterCustomers: isSysAd() ? [] : getUserCustomers(), // 加盟店リスト（表示用）
    name: "", // ユーザー名
    userType: "", // ユーザー種別
    email: "", // メールアドレス
    masterCustomerId: isCustomerStaffUser() ? getUserCustomerId() : "", // 加盟店ID
  });

  // ユーザー追加処理
  const createGuestUser = async () => {
    setLoadingOpen(true);
    cognitoUser
      .guestUserCreate(
        authHeader as string,
        userNewState.contractorId,
        USER_TYPE_SEARCH_MAP_FOR_CUSTOMER[userNewState.userType],
        // メールアドレスが空の場合は追加実施者のメールアドレスを使用（システムユーザーは必須入力チェック済み）
        userNewState.email === "" ? userAttributes?.email : userNewState.email,
        userNewState.name,
        userNewState.masterCustomerId
      )
      .then(() => {
        // 作成成功時
        setCreateUserError(<div />);
        history.push("/guest/setting/user/new/complete");
        setSnackbar({
          openProps: true,
          message: "ユーザーの作成が完了しました。",
          severity: "info",
        });
      })
      .catch((error) => {
        setCreateUserError(
          <AlertMessage errorMessage={getErrorMessage(error)} />
        );
        throw error;
      })
      .finally(() => {
        setConfirmModal({
          open: false,
          modalTitle: "",
          message: ``,
          colors: GUEST_COLOR,
          actionMethod: () => {
            /* 何もしない */
          },
        });
        setLoadingOpen(false);
      });
  };

  // バリデーション
  const handleConfirm = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    // Material-UIのボタンは、e.preventDefault入れないと、強制画面遷移(同じ画面でも同じ画面に遷移)で、画面全体がDOMマウントから再レンダーされてしまう。
    e.preventDefault();
    // -------- バリデーション処理 --------
    // 会社名は必須（システムユーザー以外は自身の契約者IDを自動で設定済み）
    if (userNewState.contractorId === "") {
      setCreateUserError(
        <AlertMessage errorMessage="会社名を選択してください。" />
      );
      return;
    }
    // ユーザー種別は必須
    if (userNewState.userType === "") {
      setCreateUserError(
        <AlertMessage errorMessage="ユーザー種別を選択してください。" />
      );
      return;
    }
    // 「加盟店管理者」,「加盟店一般(事務担当)」,「加盟店一般(経理)」は所属必須
    if (
      userNewState.userType !==
        USER_TYPE_NAME_LIST.SUPER_REPRESENTATIVE_MANAGER &&
      userNewState.userType !== USER_TYPE_NAME_LIST.REPRESENTATIVE_MANAGER &&
      userNewState.masterCustomerId === ""
    ) {
      setCreateUserError(
        <AlertMessage errorMessage="所属加盟店を選択してください。" />
      );
      return;
    }
    // メールアドレス必須チェック(シスアドユーザーのみ)
    if (isSysAd() && userNewState.email.toString().length === 0) {
      setCreateUserError(
        <AlertMessage errorMessage="メールアドレスを入力してください" />
      );
      return;
    }
    // ユーザー名必須チェック
    if (userNewState.name.toString().length === 0) {
      setCreateUserError(
        <AlertMessage errorMessage="ユーザー名を入力してください" />
      );
      return;
    }
    // メールアドレス長さチェック
    if (userNewState.email.toString().length > EMAIL_MAX_LENGTH) {
      setCreateUserError(
        <AlertMessage
          errorMessage={`メールアドレスは最大${EMAIL_MAX_LENGTH}文字です。`}
        />
      );
      return;
    }
    // ユーザー名長さチェック
    if (userNewState.name.toString().length > DISPLAY_NAME_MAX_LENGTH) {
      setCreateUserError(
        <AlertMessage
          errorMessage={`名前は最大${DISPLAY_NAME_MAX_LENGTH}文字です。`}
        />
      );
      return;
    }
    const errChar = compareSJISWhiteList([userNewState.name.toString()]);
    if (errChar) {
      setCreateUserError(
        <AlertMessage
          errorMessage={`当システムで使用できない文字が含まれています。（${errChar}）`}
        />
      );
      return;
    }

    if (
      userNewState.userType === USER_TYPE_NAME_LIST.SUPER_REPRESENTATIVE_MANAGER
    ) {
      // スーパーユーザー再作成の場合は注意モーダル表示
      setConfirmModal({
        open: true,
        modalTitle:
          "対象の加盟店側の既に存在する本社スーパーユーザーが削除され、新しく本社スーパーユーザーが作成されます。事前に加盟店への連絡を忘れないようにしてください。",
        message: "本当に実行しますか？",
        colors: GUEST_COLOR,
        actionMethod: createGuestUser,
      });
    } else {
      // 通常のユーザー追加
      createGuestUser();
    }
  };

  return (
    <>
      <Loading loadingOpen={loadingOpen} />
      <Dashboard
        title="設定"
        color={GUEST_COLOR.base}
        backgroundColor={GUEST_COLOR.primary}
        iconName="setting"
      >
        <Container maxWidth="lg" className={containerClasses.container}>
          <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
          {/* 文言 */}
          {!isSysAd() && (
            <Alert severity="warning" className={classes.flashMessage}>
              メールアドレスを指定しない場合は、ユーザー追加した方のメールアドレスが登録されます。
            </Alert>
          )}
          {createUserError}
          <form className={classes.form}>
            {/* ユーザー追加フォーム */}
            <GuestSettingUserNewForm
              userNewState={userNewState}
              setUserNewState={setUserNewState}
            />
            {/* 追加するボタン */}
            <Button
              type="submit"
              onClick={handleConfirm}
              className={clsx(buttonClasses.button, classes.addButton)}
              variant="contained"
              disabled={loadingOpen} // 二重送信対策
            >
              <AddIcon className={classes.addIcon} />
              追加する
            </Button>
          </form>
        </Container>
      </Dashboard>
    </>
  );
};

export default withRouter(GuestSettingUserNewPage);
