// 先方要望によりこの画面に来るためのリンクはコメントアウトされており、現在は使用されていない（詳しくは GuestLoginPage を確認）
/**
 * 加盟店側-ユーザーID送信完了画面
 * メモ：完了メッセージを表示する
 */
import React from "react";
import { withRouter, Link } from "react-router-dom";

import clsx from "clsx";
import { Paper, Button, makeStyles } from "@material-ui/core";

import {
  buttonStyles,
  loginStyles,
} from "../../../../common/components/styles";

import { GUEST_COLOR } from "../../../../../constants/common";

import LogoForLogin from "../../../../common/components/atoms/LogoForLogin";

const useStyles = makeStyles(() => ({
  div: {
    backgroundColor: "#f1f1f1",
    height: "100%",
  },
  // 開発環境(develop)の場合のみ
  divImage: {
    background: `url(${window.location.origin}/bgtestForGree.png) repeat`,
    backgroundColor: "#f1f1f1",
    height: "100%",
  },
  completeButton: {
    marginTop: 40,
    paddingBottom: 10,
    textAlign: "center",
  },
}));

const GuestSendUserCompletePage: React.FC = () => {
  const classes = useStyles();
  const loginClasses = loginStyles({
    backgroundColor: GUEST_COLOR.primary,
    color: GUEST_COLOR.base,
  });
  const buttonClasses = buttonStyles({
    width: 240,
    marginTop: 40,
    backgroundColor: GUEST_COLOR.primary,
  });

  return (
    // TODO:検証環境でJTB様がマニュアル用の画面画像取るため一時的に開発環境のTESTの背景を外す
    // <div
    //   className={
    //     process.env.REACT_APP_ENV === "development"
    //       ? classes.divImage
    //       : classes.div
    //   }
    // >
    <div className={classes.div}>
      <Paper className={loginClasses.paper} elevation={0}>
        {/* ロゴ */}
        <LogoForLogin />
        <div className={loginClasses.complete}>
          {/* 完了メッセージ */}
          ご入力頂いたメールアドレスを登録しているユーザーの一覧が送信されました。
          {/* ログイン画面ボタン */}
          <Button
            component={Link}
            to="/guest/login"
            className={clsx(
              buttonClasses.button,
              loginClasses.button,
              classes.completeButton
            )}
            variant="contained"
          >
            ログイン画面
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default withRouter(GuestSendUserCompletePage);
