/**
 * 共通-テキストフィールド
 */
import React, { useState } from "react";

import { makeStyles, TextField } from "@material-ui/core";

import { FONT } from "../../../../constants/common";

const useStyles = makeStyles(() => ({
  text: {
    width: "100%",
    backgroundColor: "#f2f7fb",
    "& .MuiInputBase-input": {
      height: 0,
      paddingTop: 22,
      fontFamily: FONT,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: 0,
    },
  },
}));

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | Array<string>;
  id?: string;
  type?: string;
  placeholder?: string;
  autoComplete?: "new-password" | "current-password"; // ブラウザのパスワード保存機能無効のため,ブラウザコンソールのCreate Amazing Password Formsログ消し
}

const CustomTextField: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const { onChange, value, id, type, placeholder, autoComplete } = props;

  const [textValue, setTextValue] = useState(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextValue(event.target.value);
    onChange(event);
  };

  return (
    <TextField
      id={id}
      type={type}
      placeholder={placeholder}
      value={textValue}
      className={classes.text}
      onChange={handleChange}
      variant="outlined"
      autoComplete={autoComplete}
    />
  );
};

CustomTextField.defaultProps = {
  type: "text",
  placeholder: "",
};

export default CustomTextField;
