/**
 * 加盟店側-規約一覧
 * メモ：申請編集画面での規約確認用の規約を一覧表示する
 */
import React, { useContext } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import clsx from "clsx";
import {
  makeStyles,
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

import { buttonStyles } from "../../../../common/components/styles";

import { GUEST_COLOR, FONT } from "../../../../../constants/common";

import { TermsList } from "../pages/GuestDealEditConfirmPage";
import { AuthContext } from "../../../../../cognito/AuthContext";
import terms from "../../../../../api/terms";

import SwitchStatus from "../../../../common/components/molecules/SwitchStatus";

const useStyles = makeStyles(() => ({
  paper: {
    margin: "0 auto",
    marginTop: 50,
    padding: 34,
    paddingTop: 17,
    paddingBottom: 17,
    width: 900,
    textAlign: "center",
    border: `2px solid ${GUEST_COLOR.grid}`,
  },
  tr: {
    "&:last-child td": {
      borderBottom: "0px",
    },
  },
  term: {
    fontFamily: FONT,
    width: 514,
    fontSize: "1.0rem",
  },
  td: {
    fontFamily: FONT,
    width: 314,
    fontSize: "1.1rem",
  },
  cellButton: {
    padding: 12,
  },
  checkButton: {
    margin: "0 auto",
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 23,
  },
  checkIcon: {
    position: "absolute",
    fontSize: "1.2rem",
    top: 8,
    left: 20,
  },
  termLink: {
    textDecoration: "none",
  },
}));

interface Props extends RouteComponentProps<{ id: string }> {
  termsState: TermsList;
  setTermsState: React.Dispatch<React.SetStateAction<TermsList>>;
}

const GuestDealEditTermList: React.FC<Props> = (props: Props) => {
  const { termsState, setTermsState, history, match } = props;

  const classes = useStyles();
  const moveButtonClasses = buttonStyles({
    width: 180,
    marginTop: 20,
    backgroundColor: GUEST_COLOR.primary,
  });

  const { authHeader } = useContext(AuthContext);

  const setTermsOpened = (name: keyof TermsList, version: string) => {
    const newState = {
      ...termsState,
      [name]: { ...termsState[name], open: true, termsVersion: version },
    };
    setTermsState(newState);
    return newState; // PDF画面への遷移で利用できるように更新した値を返す
  };

  const handleToTermsPdfPage = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();

    const termsNumber = e.currentTarget.getAttribute("data-id");
    const termKey = e.currentTarget.dataset.name;
    // APIを呼んでPDFを入れる。
    terms
      .get(authHeader as string, Number(termsNumber))
      .then((res) => {
        const pdf = res.data.result.pdfData;
        const version = res.data.result.termsVersion;
        const newTermsState = setTermsOpened(
          termKey as keyof TermsList,
          version
        );
        history.push({
          pathname: `/guest/deal/${match.params.id}/terms-pdf`,
          state: {
            pdfData: pdf,
            newTermsState, // 画面間でtermsStateの値を保持するため
          },
        });
      })
      .catch((error) => {
        throw error;
      });
  };

  return (
    <>
      <Paper className={classes.paper} elevation={0}>
        <TableContainer id="apply-confirm-table">
          <Table>
            <TableBody>
              {termsState.term2.need && (
                // PDF表示の規約
                <TableRow className={classes.tr}>
                  <TableCell align="center" className={classes.term}>
                    JTBクレジットカード一括加盟店 決済サービス
                    加盟店規約（宿泊施設）
                  </TableCell>
                  <TableCell align="center" className={classes.td}>
                    <SwitchStatus isDone={termsState.term2.open} />
                  </TableCell>
                  <TableCell align="center" className={classes.cellButton}>
                    <Button
                      type="submit"
                      onClick={handleToTermsPdfPage}
                      data-id={termsState.term2.termsType}
                      data-name="term2"
                      className={clsx(
                        moveButtonClasses.button,
                        classes.checkButton
                      )}
                      variant="contained"
                    >
                      <CheckIcon className={classes.checkIcon} />
                      確認する
                    </Button>
                  </TableCell>
                </TableRow>
              )}
              {termsState.term3.need && (
                // PDF表示の規約
                <TableRow className={classes.tr}>
                  <TableCell align="center" className={classes.term}>
                    JTBクレジットカード一括加盟店 決済サービス
                    加盟店規約（JTBたびなかネット スタンプ）
                  </TableCell>
                  <TableCell align="center" className={classes.td}>
                    <SwitchStatus isDone={termsState.term3.open} />
                  </TableCell>
                  <TableCell align="center" className={classes.cellButton}>
                    <Button
                      type="submit"
                      onClick={handleToTermsPdfPage}
                      data-id={termsState.term3.termsType}
                      data-name="term3"
                      className={clsx(
                        moveButtonClasses.button,
                        classes.checkButton
                      )}
                      variant="contained"
                    >
                      <CheckIcon className={classes.checkIcon} />
                      確認する
                    </Button>
                  </TableCell>
                </TableRow>
              )}
              {termsState.term4.need && (
                // PDF表示の規約
                <TableRow className={classes.tr}>
                  <TableCell align="center" className={classes.term}>
                    JTBクレジットカード一括加盟店 決済サービス
                    加盟店規約（JTB提携販売店）
                  </TableCell>
                  <TableCell align="center" className={classes.td}>
                    <SwitchStatus isDone={termsState.term4.open} />
                  </TableCell>
                  <TableCell align="center" className={classes.cellButton}>
                    <Button
                      type="submit"
                      onClick={handleToTermsPdfPage}
                      data-id={termsState.term4.termsType}
                      data-name="term4"
                      className={clsx(
                        moveButtonClasses.button,
                        classes.checkButton
                      )}
                      variant="contained"
                    >
                      <CheckIcon className={classes.checkIcon} />
                      確認する
                    </Button>
                  </TableCell>
                </TableRow>
              )}
              {termsState.term1.need && (
                // PDF表示の規約
                <TableRow className={classes.tr}>
                  <TableCell align="center" className={classes.term}>
                    端末機使用特約
                  </TableCell>
                  <TableCell align="center" className={classes.td}>
                    <SwitchStatus isDone={termsState.term1.open} />
                  </TableCell>
                  <TableCell align="center" className={classes.cellButton}>
                    <Button
                      type="submit"
                      onClick={handleToTermsPdfPage}
                      data-id={termsState.term1.termsType}
                      data-name="term1"
                      className={clsx(
                        moveButtonClasses.button,
                        classes.checkButton
                      )}
                      variant="contained"
                    >
                      <CheckIcon className={classes.checkIcon} />
                      確認する
                    </Button>
                  </TableCell>
                </TableRow>
              )}
              {termsState.term5.need && (
                // PDF表示の規約
                <TableRow className={classes.tr}>
                  <TableCell align="center" className={classes.term}>
                    電子マネー 決済サービスの利用に関する 特約
                  </TableCell>
                  <TableCell align="center" className={classes.td}>
                    <SwitchStatus isDone={termsState.term5.open} />
                  </TableCell>
                  <TableCell align="center" className={classes.cellButton}>
                    <Button
                      type="submit"
                      onClick={handleToTermsPdfPage}
                      data-id={termsState.term5.termsType}
                      data-name="term5"
                      className={clsx(
                        moveButtonClasses.button,
                        classes.checkButton
                      )}
                      variant="contained"
                    >
                      <CheckIcon className={classes.checkIcon} />
                      確認する
                    </Button>
                  </TableCell>
                </TableRow>
              )}
              {termsState.term7.need && (
                // PDF表示の規約
                <TableRow className={classes.tr}>
                  <TableCell align="center" className={classes.term}>
                    コード決済 サービスの利用に関する 特約
                  </TableCell>
                  <TableCell align="center" className={classes.td}>
                    <SwitchStatus isDone={termsState.term7.open} />
                  </TableCell>
                  <TableCell align="center" className={classes.cellButton}>
                    <Button
                      type="submit"
                      onClick={handleToTermsPdfPage}
                      data-id={termsState.term7.termsType}
                      data-name="term7"
                      className={clsx(
                        moveButtonClasses.button,
                        classes.checkButton
                      )}
                      variant="contained"
                    >
                      <CheckIcon className={classes.checkIcon} />
                      確認する
                    </Button>
                  </TableCell>
                </TableRow>
              )}
              {termsState.term6.need && (
                // PDF表示の規約
                <TableRow className={classes.tr}>
                  <TableCell align="center" className={classes.term}>
                    取引照会WEB21利用に関するご案内
                  </TableCell>
                  <TableCell align="center" className={classes.td}>
                    <SwitchStatus isDone={termsState.term6.open} />
                  </TableCell>
                  <TableCell align="center" className={classes.cellButton}>
                    <Button
                      type="submit"
                      onClick={handleToTermsPdfPage}
                      data-id={termsState.term6.termsType}
                      data-name="term6"
                      className={clsx(
                        moveButtonClasses.button,
                        classes.checkButton
                      )}
                      variant="contained"
                    >
                      <CheckIcon className={classes.checkIcon} />
                      確認する
                    </Button>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {/* スクロールターゲットの透明div */}
          {/* TODO 以下どうするか検討 */}
          {/* <div ref={target} /> */}
        </TableContainer>
      </Paper>
    </>
  );
};

export default withRouter(GuestDealEditTermList);
