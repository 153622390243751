import { TDealTypeNum } from "../views/common/components/types";
import { DEAL_TYPE_STR_TO_NUM } from "../constants/deal";
import {
  TWorkflowStatus,
  TWorkflowStatusMessages,
  WORKFLOW_STATUS_MESSAGES_FOR_DEALER,
  WORKFLOW_STATUS_MESSAGES_FOR_CUSTOMER,
  WORKFLOW_STATUS_MESSAGES_NOT_EXAMINATION_FOR_CUSTOMER,
  WORKFLOW_STATUS_MESSAGES_NOT_EXAMINATION_AND_TERMINATION_FOR_CUSTOMER,
} from "../constants/workflowStatus";
import { isJtbUser } from "./auth";

// ワークフローステータス番号から名称への変換
export const getWorkflowStatusName = (
  dealType: string, // 申請種別
  workflowStatus: string // ワークフローステータス
): string => {
  // 申請種別に対応した名称一覧を取得
  let displayName = {} as TWorkflowStatusMessages;
  if (isJtbUser()) {
    // JTB側の名称 （現状は申請種別による差分なし）
    displayName = { ...WORKFLOW_STATUS_MESSAGES_FOR_DEALER };
  } else {
    // 加盟店側の名称（申請種別による差分あり）可読性悪くなるのでelse許容する
    // eslint-disable-next-line no-lonely-if
    if (DEAL_TYPE_STR_TO_NUM["端末新規"] === (dealType as TDealTypeNum)) {
      // 端末新規の場合
      displayName = { ...WORKFLOW_STATUS_MESSAGES_FOR_CUSTOMER };
    } else if (
      DEAL_TYPE_STR_TO_NUM["端末解約"] === (dealType as TDealTypeNum) ||
      DEAL_TYPE_STR_TO_NUM["加盟店解約"] === (dealType as TDealTypeNum)
    ) {
      // 解約の場合
      displayName = {
        ...WORKFLOW_STATUS_MESSAGES_NOT_EXAMINATION_AND_TERMINATION_FOR_CUSTOMER,
      };
    } else {
      // 端末新規,解約以外の場合
      displayName = {
        ...WORKFLOW_STATUS_MESSAGES_NOT_EXAMINATION_FOR_CUSTOMER,
      };
    }
  }
  return displayName[Number(workflowStatus) as TWorkflowStatus] || "";
};

export default { getWorkflowStatusName };
