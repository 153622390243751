import {
  getContractorId,
  getCustomerId,
  getUserAuthFlags,
  getCustomers,
  getUserDisplayName as getDisplayName,
  getUserType,
  getGroupName,
  getPhoneNumber,
} from "../api/utils/authUtils";
import { PolicyList } from "../constants/auth";
import { USER_TYPE_LIST } from "../constants/user";
import { ICustomers } from "../api/types";

// 権限チェック関数（ボタン制御用）
export const checkAuth: (key: keyof PolicyList) => boolean = (
  key: keyof PolicyList
) => {
  // 権限フラグ取得
  const authFlags = getUserAuthFlags();
  if (!authFlags || !authFlags[key]) {
    // フラグがtrueでなければ権限エラー
    return false;
  }
  return true;
};

/**
 * システム担当者かどうか
 */
export const isSysAd: () => boolean = () => {
  const userType = getUserType();
  if (
    userType === USER_TYPE_LIST.SYSAD_JTB ||
    userType === USER_TYPE_LIST.SYSAD_CUSTOMER ||
    userType === USER_TYPE_LIST.SYSAD_CARD_COMPANY
  ) {
    return true;
  }
  return false;
};

/**
 * JTBユーザーかどうか
 * JTBの管理、一般、システムであればtrue
 */
export const isJtbUser: () => boolean = () => {
  const userType = getUserType();
  if (
    userType === USER_TYPE_LIST.MANAGER_JTB ||
    userType === USER_TYPE_LIST.STAFF_JTB ||
    userType === USER_TYPE_LIST.SYSAD_JTB
  ) {
    return true;
  }
  return false;
};

/**
 * JTBシステム担当者であればtrue
 */
export const isJtbSysADUser: () => boolean = () => {
  const userType = getUserType();
  if (userType === USER_TYPE_LIST.SYSAD_JTB) {
    return true;
  }
  return false;
};

/**
 * JTB一般であればtrue
 */
export const isJtbStaffUser: () => boolean = () => {
  const userType = getUserType();
  if (userType === USER_TYPE_LIST.STAFF_JTB) {
    return true;
  }
  return false;
};

/**
 * JTB管理であればtrue
 */
export const isJtbManagerUser: () => boolean = () => {
  const userType = getUserType();
  if (userType === USER_TYPE_LIST.MANAGER_JTB) {
    return true;
  }
  return false;
};

/**
 * カード会社ユーザーかどうか
 */
export const isCardCompanyUser: () => boolean = () => {
  const userType = getUserType();
  if (
    userType === USER_TYPE_LIST.CARD_COMPANY ||
    userType === USER_TYPE_LIST.SYSAD_CARD_COMPANY
  ) {
    return true;
  }
  return false;
};

/**
 * SMCCユーザーかどうか（groupNameから判断）
 */
export const isSmccUser: () => boolean = () => {
  const groupName = getGroupName();
  if (groupName === "SMCC") {
    return true;
  }
  return false;
};

/**
 * 加盟店所属ユーザーかどうか
 * 加盟店管理者,加盟店一般(事務担当),加盟店一般(経理)であればtrue
 */
export const isCustomerStaffUser: () => boolean = () => {
  const userType = getUserType();
  if (
    userType === USER_TYPE_LIST.MANAGER ||
    userType === USER_TYPE_LIST.OFFICE_STAFF ||
    userType === USER_TYPE_LIST.ACCOUNTING
  ) {
    return true;
  }
  return false;
};

/**
 * 加盟店側ユーザー用、ログインユーザーの契約者ID取得メソッド
 */
export const getUserContractorId: () => string = () => {
  return getContractorId() || "";
};

/**
 * 加盟店側ユーザー用、ログインユーザーの所属加盟店D取得メソッド
 */
export const getUserCustomerId: () => string = () => {
  return getCustomerId() || "";
};

/**
 * 加盟店側ユーザー用、ログインユーザーの加盟店リスト
 * 本社スーパーユーザー、本社管理者のときのみリストがついてくる
 * 加盟店ユーザー追加で使用
 */
export const getUserCustomers: () => ICustomers[] | [] = () => {
  return getCustomers() || [];
};

export const getUserDisplayName: () => string = () => getDisplayName();

export const getUserPhoneNumber: () => string = () => getPhoneNumber();

export default { checkAuth };
